import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY, GI_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { SearchUser, SearchUserJson } from '@models/search-user';
import { User, UserJson } from '@models/user';

/**
 * User Service.
 */
@Injectable({
  providedIn: 'root'
})
export class UserApiService extends ApiCommonService<User> {
  readonly resourceUrl = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/users`;
  readonly headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/vnd.tiime.marital_status.v2+json'
  });

  fromJson(json: UserJson): User {
    return User.fromJson(json);
  }

  toJson(model: User): Partial<UserJson> {
    return User.toJson(model);
  }

  @ApiAlertError()
  getUsers(): Observable<User[]> {
    return super.getAll({ headers: this.headers });
  }

  @ApiAlertError()
  getUser(userId: number): Observable<User> {
    return super.getById(userId, `${this.resourceUrl}/${userId}`, { headers: this.headers });
  }

  @ApiAlertError()
  searchUsers(searchTerms: string = ''): Observable<User[]> {
    const params = HttpHelper.setParams({ q_only_users: searchTerms });

    return super.search({ params }, 'api/v1/expert/users/search');
  }

  @ApiAlertError()
  searchUsersFromBusinessUserPartner(searchTerms: { email: string; phone: string }): Observable<SearchUser[]> {
    const url = `${GI_API_ROUTE_BASE}/users/search`;
    const params = HttpHelper.setParams({
      email: searchTerms.email ?? undefined,
      phone: searchTerms.phone ?? undefined
    });

    return this.http
      .get<SearchUserJson[]>(url, { params })
      .pipe(map((jsonArray: SearchUserJson[]) => jsonArray.map((json: SearchUserJson) => SearchUser.fromJson(json))));
  }

  @ApiAlertError()
  createUser(user: User, companyId?: number): Observable<User> {
    const url = companyId ? `api/v1/expert/companies/${companyId}/users` : this.resourceUrl;
    return super.create(user, url);
  }

  @ApiAlertError()
  createRelation(userId: number, companyId?: number): Observable<any> {
    const url = companyId
      ? `api/v1/expert/companies/${companyId}/users/${userId}/relation`
      : `${this.resourceUrl}/${userId}/relation`;
    return this.http.post(url, {});
  }

  @ApiAlertError()
  updateUser(user: User, companyId?: number): Observable<User> {
    const url = companyId ? `api/v1/expert/companies/${companyId}/users/${user.id}` : `${this.resourceUrl}/${user.id}`;
    return this.http
      .patch(url, this.toJson(user), { headers: this.headers })
      .pipe(map((userJson: UserJson) => this.fromJson(userJson)));
  }

  @ApiAlertError()
  updateRelation(userId: number, body: unknown): Observable<unknown> {
    const url = `${this.resourceUrl}/${userId}/relation`;
    return this.http.patch(url, body);
  }

  @ApiAlertError()
  removeUser(userId: number): Observable<unknown> {
    const url = `${this.resourceUrl}/${userId}/relation`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  addUserToCompany(userId: number): Observable<User> {
    const url = `${this.resourceUrl}/${userId}/access/open`;
    return this.http.patch(url, null).pipe(map((json: any) => this.fromJson(json)));
  }

  @ApiAlertError()
  checkIfEmailOrPhoneExist(user: User): Observable<User[]> {
    const url = `api/v1/expert/check/users`;
    return this.http
      .post(url, {
        email: user.email,
        phone: user.phone?.trim().length ? user.phone : undefined
      })
      .pipe(map((usersJson: any) => usersJson.map(userJson => User.fromJson(userJson))));
  }
}
