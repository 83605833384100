import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_HEADERS } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { CompaniesApiFiltersEnum } from '@enums/companies-api-filters-enum';
import { HttpHelper } from '@helpers/http.helper';
import { AdminCompaniesFilters } from '@interfaces/admin-companies-filters';
import { AdminCompany, AdminCompanyJson } from '@models/admin-company';
import {
  AdminCompanyLegalStructureAndSignatoryLink,
  AdminCompanyLegalStructureAndSignatoryLinkJson
} from '@models/admin-company-legal-structure-and-signatory-link';
import { AnnualBooklet, AnnualBookletJson } from '@models/annual-booklet';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { ContributorJob, ContributorJobJson } from '@models/contributor-job';
import { CoverPage, CoverPageJson } from '@models/cover-page';
import {
  CoverPageBackgroundImage,
  CoverPageBackgroundImageJson,
  CoverPageBackgroundImageSerialized
} from '@models/cover-page-background-image';
import { LabelCategoryPlan, LabelCategoryPlanJson } from '@models/label-category-plan';
import { LabelCategoryPlanSetup, LabelCategoryPlanSetupJson } from '@models/label-category-plan-setup';
import { Logo, LogoJson } from '@models/logo';
import { Partner, PartnerJson } from '@models/partner';
import { RiskLevelConfig, RiskLevelConfigJson } from '@models/risk-level-config';
import {
  AdminCompanyContributorLink,
  AdminCompanyContributorLinkJson
} from '@modules/core/models/admin-company-contributor-link';

@Injectable({
  providedIn: 'root'
})
export class PartnerApiService extends ApiCommonService<Partner> {
  readonly resourceUrl: string = 'api/v1/expert/partners';

  fromJson(json: PartnerJson): Partner {
    return Partner.fromJson(json);
  }

  toJson(model: Partner): Partial<PartnerJson> {
    return Partner.toJson(model);
  }

  @ApiAlertError()
  getPartner(partnerId: number): Observable<Partner> {
    return super.getById(partnerId);
  }

  @ApiAlertError()
  updatePartner(partner: Partner): Observable<Partner> {
    return super.update(partner.id, partner);
  }

  @ApiAlertError()
  createBusinessUnit(partnerId: number, businessUnit: BusinessUnit): Observable<BusinessUnit> {
    const url = `${this.resourceUrl}/${partnerId}/business_units`;
    return this.http
      .post(url, BusinessUnit.toJson(businessUnit))
      .pipe(map((businessUnitJson: BusinessUnitJson) => BusinessUnit.fromJson(businessUnitJson)));
  }

  @ApiAlertError()
  deleteBusinessUnit(partnerId: number, businessUnitId: number): Observable<unknown> {
    const url = `${this.resourceUrl}/${partnerId}/business_units/${businessUnitId}`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  uploadLogo(partnerId: number, file: File): Observable<Logo> {
    const url = `${this.resourceUrl}/${partnerId}/logos`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(map((logoJson: LogoJson) => Logo.fromJson(logoJson)));
  }

  @ApiAlertError()
  getLogoPreview(partnerId: number, logoId: number): Observable<Blob> {
    const url = `${this.resourceUrl}/${partnerId}/logos/${logoId}/preview`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError()
  uploadCoverPageBackgroundImage(
    partnerId: number,
    file: File,
    imageName: string
  ): Observable<CoverPageBackgroundImage> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page_background_images`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', imageName);
    return this.http
      .post<CoverPageBackgroundImageJson>(url, formData)
      .pipe(
        map((coverBackgroundImageJson: CoverPageBackgroundImageJson) =>
          CoverPageBackgroundImage.fromJson(coverBackgroundImageJson)
        )
      );
  }

  @ApiAlertError()
  getCoverPageBackgroundImages(partnerId: number): Observable<CoverPageBackgroundImage[]> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page_background_images`;
    return this.http
      .get<CoverPageBackgroundImageJson[]>(url)
      .pipe(
        map((coverBackgroundImagesJson: CoverPageBackgroundImageJson[]) =>
          coverBackgroundImagesJson.map((coverBackgroundImageJson: CoverPageBackgroundImageJson) =>
            CoverPageBackgroundImage.fromJson(coverBackgroundImageJson)
          )
        )
      );
  }

  @ApiAlertError()
  deleteThenUploadCoverPageBackgroundImage(
    partnerId: number,
    coverBackgroundImage: CoverPageBackgroundImageSerialized
  ): Observable<CoverPageBackgroundImage> {
    return this.deleteCoverPageBackgroundImage(partnerId, coverBackgroundImage.id).pipe(
      switchMap(() =>
        this.uploadCoverPageBackgroundImage(partnerId, coverBackgroundImage.image, coverBackgroundImage.imageName)
      )
    );
  }

  @ApiAlertError()
  deleteCoverPageBackgroundImage(partnerId: number, coverBackgroundImageId: number): Observable<void> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page_background_images/${coverBackgroundImageId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  deleteLogo(partnerId: number, logoId: number): Observable<void> {
    const url = `${this.resourceUrl}/${partnerId}/logos/${logoId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  getCoverPage(partnerId: number): Observable<CoverPage> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page`;
    return this.http.get(url).pipe(map((covePageJson: CoverPageJson) => CoverPage.fromJson(covePageJson)));
  }

  @ApiAlertError()
  getCoverPagePreview(partnerId: number): Observable<Blob> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page/preview`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError([403])
  saveCoverPage(partnerId: number, coverPage: CoverPage): Observable<CoverPage> {
    const url = `${this.resourceUrl}/${partnerId}/cover_page/${coverPage.id}`;
    const body = CoverPage.toJson(coverPage);
    return this.http.patch(url, body).pipe(map((coverPageJson: CoverPageJson) => CoverPage.fromJson(coverPageJson)));
  }

  @ApiAlertError([403])
  getAnnualBooklet(partnerId: number): Observable<AnnualBooklet> {
    const url = `${this.resourceUrl}/${partnerId}/annual_booklet`;
    return this.http
      .get(url)
      .pipe(map((annualBookletJson: AnnualBookletJson) => AnnualBooklet.fromJson(annualBookletJson)));
  }

  @ApiAlertError([403])
  saveAnnualBooklet(partnerId: number, annualBooklet: AnnualBooklet): Observable<AnnualBooklet> {
    const url = `${this.resourceUrl}/${partnerId}/annual_booklet/${annualBooklet.id}`;
    const body = AnnualBooklet.toJson(annualBooklet);
    return this.http
      .patch(url, body)
      .pipe(map((annualBookletJson: AnnualBookletJson) => AnnualBooklet.fromJson(annualBookletJson)));
  }

  @ApiAlertError()
  getContributorJobs(partnerId: number): Observable<ContributorJob[]> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: API_HEADERS.contributorJobsV2
    });

    const url = `${this.resourceUrl}/${partnerId}/contributor_jobs`;
    return this.http
      .get(url, { headers })
      .pipe(
        map((contributorJobsJson: ContributorJobJson[]) =>
          contributorJobsJson.map((contributorJobJson: ContributorJobJson) =>
            ContributorJob.fromJson(contributorJobJson)
          )
        )
      );
  }

  @ApiAlertError()
  updateContributorJob(partnerId: number, contributorJob: ContributorJob): Observable<ContributorJob> {
    const url = `${this.resourceUrl}/${partnerId}/contributor_jobs/${contributorJob.id}`;
    return this.http
      .patch(url, ContributorJob.toJson(contributorJob))
      .pipe(map((contributorJobJson: ContributorJobJson) => ContributorJob.fromJson(contributorJobJson)));
  }

  @ApiAlertError()
  getLabelCategoryPlans(partnerId: number): Observable<LabelCategoryPlan[]> {
    const url = `${this.resourceUrl}/${partnerId}/category_setup_plans`;

    return this.http
      .get(url)
      .pipe(
        map((labelCategoryPlansJson: LabelCategoryPlanJson[]) =>
          labelCategoryPlansJson.map((labelCategoryPlanJson: LabelCategoryPlanJson) =>
            LabelCategoryPlan.fromJson(labelCategoryPlanJson)
          )
        )
      );
  }

  @ApiAlertError()
  getLabelCategoryPlanSetup(partnerId: number, categoryId: number): Observable<LabelCategoryPlanSetup[]> {
    const url = `${this.resourceUrl}/${partnerId}/category_setup_plans/${categoryId}/category_setups`;

    return this.http
      .get(url)
      .pipe(
        map((labelCategoryPlanSetupsJson: LabelCategoryPlanSetupJson[]) =>
          labelCategoryPlanSetupsJson.map((labelCategoryPlanSetupJson: LabelCategoryPlanSetupJson) =>
            LabelCategoryPlanSetup.fromJson(labelCategoryPlanSetupJson)
          )
        )
      );
  }

  @ApiAlertError()
  updateLabelCategoryPlanSetup(
    partnerId: number,
    categoryId: number,
    labelCategoryPlanSetup: LabelCategoryPlanSetup
  ): Observable<LabelCategoryPlanSetup> {
    const url = `${this.resourceUrl}/${partnerId}/category_setup_plans/${categoryId}/category_setups/${labelCategoryPlanSetup.id}`;

    return this.http
      .patch(url, LabelCategoryPlanSetup.toJson(labelCategoryPlanSetup))
      .pipe(
        map((labelCategoryPlanSetupJson: LabelCategoryPlanSetupJson) =>
          LabelCategoryPlanSetup.fromJson(labelCategoryPlanSetupJson)
        )
      );
  }

  @ApiAlertError()
  createLabelCategoryPlan(partnerId: number, label: string): Observable<LabelCategoryPlan> {
    const url = `${this.resourceUrl}/${partnerId}/category_setup_plans`;
    const body = {
      label
    };

    return this.http
      .post(url, body)
      .pipe(map((labelCategoryPlanJson: LabelCategoryPlanJson) => LabelCategoryPlan.fromJson(labelCategoryPlanJson)));
  }

  @ApiAlertError()
  updateLabelCategoryPlan(partnerId: number, labelCategoryPlan: LabelCategoryPlan): Observable<LabelCategoryPlan> {
    const url = `${this.resourceUrl}/${partnerId}/category_setup_plans/${labelCategoryPlan.id}`;

    return this.http
      .patch(url, LabelCategoryPlan.toJson(labelCategoryPlan))
      .pipe(map((labelCategoryPlanJson: LabelCategoryPlanJson) => LabelCategoryPlan.fromJson(labelCategoryPlanJson)));
  }

  @ApiAlertError()
  getCompanies(
    partnerId: number,
    range: PaginationRange,
    searchTerms?: string,
    sort?: Sort,
    filters?: AdminCompaniesFilters
  ): Observable<PaginationData<AdminCompany>> {
    const url = `${this.resourceUrl}/${partnerId}/companies`;

    let params: HttpParams = HttpHelper.setQParam(searchTerms);

    if (sort) {
      params = HttpHelper.setSortParam(sort, params);
    }

    if (filters) {
      const isWithoutContributors = filters?.contributors?.length === 1 && filters?.contributors[0].id === null;

      params = HttpHelper.setParams(
        {
          [CompaniesApiFiltersEnum.taxRegimeCode]: filters.taxRegimeCode?.length
            ? filters.taxRegimeCode.join('|')
            : undefined,
          [CompaniesApiFiltersEnum.withoutContributors]: isWithoutContributors ? true : undefined,
          [CompaniesApiFiltersEnum.contributors]: isWithoutContributors
            ? undefined
            : filters.contributors.length
            ? filters.contributors.map(contributor => contributor.id).join('|')
            : undefined,
          [CompaniesApiFiltersEnum.contributor_jobs]: filters.contributorJobId,
          [CompaniesApiFiltersEnum.businessUnits]: filters.businessUnits.length
            ? filters.businessUnits.map(businessUnit => businessUnit.id).join(',')
            : undefined
        },
        params
      );
    }

    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    const options = { params, headers };

    return this.http.get(url, { ...options, observe: 'response' }).pipe(
      filter((response: HttpResponse<AdminCompanyJson[]>) => response.status !== 204),
      HttpHelper.mapToPaginationData(range, json => AdminCompany.fromJson(json))
    );
  }

  @ApiAlertError()
  updateCompaniesContributorLink(partnerId: number, payload: AdminCompanyContributorLink): Observable<void> {
    const body = AdminCompanyContributorLink.toJson(payload);
    return this.updateCompanies(partnerId, body);
  }

  @ApiAlertError()
  updateCompaniesLegalStructureAndSignatoryLink(
    partnerId: number,
    payload: AdminCompanyLegalStructureAndSignatoryLink
  ): Observable<void> {
    const body = AdminCompanyLegalStructureAndSignatoryLink.toJson(payload);
    return this.updateCompanies(partnerId, body);
  }

  private updateCompanies(
    partnerId: number,
    body: AdminCompanyContributorLinkJson | AdminCompanyLegalStructureAndSignatoryLinkJson
  ): Observable<void> {
    const url = `${this.resourceUrl}/${partnerId}/companies`;

    return this.http.patch<void>(url, body);
  }

  @ApiAlertError()
  getRiskLevelConfig(partnerId: number): Observable<RiskLevelConfig> {
    const url = `${this.resourceUrl}/${partnerId}/risk_level_config`;

    return this.http
      .get<RiskLevelConfigJson>(url)
      .pipe(map((riskLevelConfigJson: RiskLevelConfigJson) => RiskLevelConfig.fromJson(riskLevelConfigJson)));
  }

  @ApiAlertError()
  createRiskLevelConfig(partnerId: number, riskLevelConfig: RiskLevelConfig): Observable<RiskLevelConfig> {
    const url = `${this.resourceUrl}/${partnerId}/risk_level_config`;

    return this.http
      .post<RiskLevelConfigJson>(url, RiskLevelConfig.toJson(riskLevelConfig))
      .pipe(map((riskLevelConfigJson: RiskLevelConfigJson) => RiskLevelConfig.fromJson(riskLevelConfigJson)));
  }

  @ApiAlertError()
  updateRiskLevelConfig(partnerId: number, riskLevelConfig: RiskLevelConfig): Observable<RiskLevelConfig> {
    const url = `${this.resourceUrl}/${partnerId}/risk_level_configs/${riskLevelConfig.id}`;

    return this.http
      .patch<RiskLevelConfigJson>(url, RiskLevelConfig.toJson(riskLevelConfig))
      .pipe(map((riskLevelConfigJson: RiskLevelConfigJson) => RiskLevelConfig.fromJson(riskLevelConfigJson)));
  }
}
