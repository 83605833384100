import { Directive } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { BehaviorSubject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class DialogBase<T, R = any> {
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoading(): boolean {
    return this.isLoading$.value;
  }

  set isLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  constructor(protected dialogRef: MatDialogRef<T>) {}

  cancel(): void {
    this.dialogRef.close();
  }

  close(data: R): void {
    this.dialogRef.close(data);
  }
}
