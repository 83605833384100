import { DeclarationStatusSource } from '@enums/declaration-status-source';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { FiscalDeclarationType } from '@enums/fiscal-declaration-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { Company, CompanyJson } from '@models/company';
import { DeclarationStatusError, DeclarationStatusErrorJson } from '@models/declaration-status-error';
import { FiscalDeclaration, FiscalDeclarationJson } from '@models/fiscal-declaration';
import { Thread, ThreadJson } from '@models/thread';
import { User } from '@models/user';

export interface CorporateTaxInstalmentJson extends FiscalDeclarationJson {
  company?: Partial<CompanyJson>;
  amount?: number;
  can_send_payment_email_to_customer?: boolean;
  threads: Partial<ThreadJson>[];
}

export type SerializedCorporateTaxInstalment = Pick<CorporateTaxInstalmentJson, 'id' | 'status' | 'amount'>;

export class CorporateTaxInstalment implements FiscalDeclaration {
  constructor(
    public id?: number,
    public startDate?: string,
    public endDate?: string,
    public type?: FiscalDeclarationType,
    public returnMessage?: string,
    public teledecId?: number,
    public status?: DeclarationStatus,
    public teledecStatus?: string,
    public deadlineDate?: string,
    public label?: string,
    public link?: string,
    public statusSource?: DeclarationStatusSource,
    public errors?: DeclarationStatusError[],
    public statusAt?: Date,
    public statusBy?: User,
    public company?: Company,
    public amount?: number,
    public canSendPaymentEmailToCustomer?: boolean,
    public threads?: Thread[]
  ) {}

  static fromJson(json: Partial<CorporateTaxInstalmentJson>): CorporateTaxInstalment {
    return new CorporateTaxInstalment(
      json.id,
      json.start_date,
      json.end_date,
      json.type,
      json.return_message,
      json.teledec_id,
      json.status,
      json.teledec_status,
      json.deadline_date,
      json.label,
      json.link,
      json.status_source,
      json.errors
        ? json.errors.map((errorJson: DeclarationStatusErrorJson) => DeclarationStatusError.fromJson(errorJson))
        : [],
      json.status_date ? DateHelper.fromUtc(json.status_date) : null,
      json.status_by ? User.fromJson(json.status_by) : null,
      json.company ? Company.fromJson(json.company) : null,
      json.amount,
      json.can_send_payment_email_to_customer,
      json.threads ? json.threads.map((threadJson: ThreadJson) => Thread.fromJson(threadJson)) : []
    );
  }

  static toJson(json: CorporateTaxInstalment): SerializedCorporateTaxInstalment {
    return {
      id: json.id,
      status: json.status,
      amount: json.amount
    };
  }
}
