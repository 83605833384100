import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  DatepickerHeaderComponent,
  TiimeDatepickerModule,
  TiimeFormModule,
  TiimeIconModule,
  TiimeInputContainerModule
} from 'tiime-material';

import { OwnershipForm } from '@forms/user/ownership-form';
import { DigitOnlyDirective } from '@shared-directives/digit-only/digit-only.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ownership-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeIconModule,
    TiimeDatepickerModule,
    DigitOnlyDirective
  ],
  templateUrl: './ownership-form.component.html',
  styleUrls: ['./ownership-form.component.scss']
})
export class OwnershipFormComponent {
  @Input() ownershipForm: OwnershipForm;
  @Input() tiimeFormExpanded: boolean;

  readonly tiimeDatepickerHeader = DatepickerHeaderComponent;
}
