import { AccountingRoute } from '@modules/core/navigation/accounting-route.enum';
import { AppRoute } from '@modules/core/navigation/app-route.enum';
import { CompanyRoute } from '@modules/core/navigation/company-route.enum';
import { DocumentsAndEditionsRoute } from '@modules/core/navigation/documents-and-editions-route.enum';
import { OpportunitiesRoute } from '@modules/core/navigation/opportunities-route.enum';
import { WorkbookRoute } from '@modules/core/navigation/workbook-route.enum';

const NOTIFICATION_ROUTE_PARTS = {
  COMPANIES: AppRoute.companies,
  EXPORTS: 'exports',
  BALANCES: AccountingRoute.balances,
  WORKBOOK: 'workbook',
  OPPORTUNITIES: AppRoute.opportunities,
  CONTRACTS: OpportunitiesRoute.contracts
};

export const NOTIFICATION_ROUTE_SEPARATOR = ':';

export const NOTIFICATION_ROUTE_ASSOCIATIONS = [
  {
    test: (link: string): boolean =>
      new RegExp(
        `^${NOTIFICATION_ROUTE_PARTS.COMPANIES}${NOTIFICATION_ROUTE_SEPARATOR}[0-9]*${NOTIFICATION_ROUTE_SEPARATOR}${NOTIFICATION_ROUTE_PARTS.EXPORTS}`
      ).test(link),
    routerLink: (splitedLink: string[]): string[] => [
      AppRoute.companies,
      splitedLink[1],
      CompanyRoute.documentsAndEditions,
      DocumentsAndEditionsRoute.editions
    ]
  },
  {
    test: (link: string): boolean =>
      new RegExp(
        `^${NOTIFICATION_ROUTE_PARTS.COMPANIES}${NOTIFICATION_ROUTE_SEPARATOR}[0-9]*${NOTIFICATION_ROUTE_SEPARATOR}${NOTIFICATION_ROUTE_PARTS.BALANCES}`
      ).test(link),
    routerLink: (splitedLink: string[]): string[] => [
      AppRoute.companies,
      splitedLink[1],
      CompanyRoute.accounting,
      AccountingRoute.balances
    ]
  },
  {
    test: (link: string): boolean =>
      new RegExp(
        `^${NOTIFICATION_ROUTE_PARTS.COMPANIES}${NOTIFICATION_ROUTE_SEPARATOR}[0-9]*${NOTIFICATION_ROUTE_SEPARATOR}${NOTIFICATION_ROUTE_PARTS.WORKBOOK}`
      ).test(link),
    routerLink: (splitedLink: string[]): string[] => [
      AppRoute.companies,
      splitedLink[1],
      'workbook',
      WorkbookRoute.dashboards
    ]
  },
  {
    test: (link: string): boolean =>
      new RegExp(
        `^${NOTIFICATION_ROUTE_PARTS.OPPORTUNITIES}${NOTIFICATION_ROUTE_SEPARATOR}${NOTIFICATION_ROUTE_PARTS.CONTRACTS}`
      ).test(link),
    routerLink: (): string[] => [AppRoute.opportunities, OpportunitiesRoute.contracts]
  }
];
