import { ConnectionProviderCredentialStatus } from '@enums/connection-provider-credential-status.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { ConnectionProvider, ConnectionProviderJson } from '@models/connection-provider';

export interface ConnectionProviderCredentialJson {
  id: number;
  login: string;
  password: string;
  status: ConnectionProviderCredentialStatus;
  last_connection_date: string;
  ebics_connection_provider: Partial<ConnectionProviderJson>;
  editable: boolean;
  siren: string;
}

export class ConnectionProviderCredential {
  constructor(
    public id?: number,
    public login?: string,
    public password?: string,
    public status?: ConnectionProviderCredentialStatus,
    public lastConnectionDate?: Date,
    public ebicsConnectionProvider?: ConnectionProvider,
    public editable?: boolean,
    public siren?: string
  ) {}

  static fromJson(json: Partial<ConnectionProviderCredentialJson>): ConnectionProviderCredential {
    return new ConnectionProviderCredential(
      json.id ? json.id : undefined,
      json.login,
      json.password,
      json.status,
      DateHelper.fromUtc(json.last_connection_date),
      json.ebics_connection_provider ? ConnectionProvider.fromJson(json.ebics_connection_provider) : null,
      json.editable,
      json.siren
    );
  }

  static toJson(
    connectionProviderCredentials: ConnectionProviderCredential
  ): Partial<ConnectionProviderCredentialJson> {
    return {
      id: connectionProviderCredentials.id ? connectionProviderCredentials.id : null,
      login: connectionProviderCredentials.login,
      password: connectionProviderCredentials.password,
      ebics_connection_provider: connectionProviderCredentials.ebicsConnectionProvider
        ? HttpHelper.toJsonId(connectionProviderCredentials.ebicsConnectionProvider)
        : null,
      editable: connectionProviderCredentials.editable,
      siren: connectionProviderCredentials.siren
    };
  }

  static toJsonWithoutProvider(
    connectionProviderCredentials: ConnectionProviderCredential
  ): Omit<
    ConnectionProviderCredentialJson,
    'status' | 'last_connection_date' | 'ebics_connection_provider' | 'editable'
  > {
    return {
      id: connectionProviderCredentials.id ? connectionProviderCredentials.id : null,
      login: connectionProviderCredentials.login,
      password: connectionProviderCredentials.password,
      siren: connectionProviderCredentials.siren
    };
  }
}
