import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

import { AutocompleteBaseComponent } from '@bases/autocomplete/autocomplete.base';
import { ThreadTag } from '@models/thread-tag';
import { SortByKeyPipe } from '@shared-pipes/sort-by-key/sort-by-key.pipe';

@Component({
  standalone: true,
  selector: 'app-thread-tag-autocomplete',
  templateUrl: './thread-tag-autocomplete.component.html',
  styleUrls: ['./thread-tag-autocomplete.component.scss'],
  exportAs: 'ThreadTagAutocomplete',
  imports: [CommonModule, SortByKeyPipe, MatAutocompleteModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadTagAutocompleteComponent extends AutocompleteBaseComponent<ThreadTag> {}
