import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Mapper } from 'tiime-expert-utils';

import { PaginationRange } from './pagination-range';

@Component({
  selector: 'tiime-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PaginatorComponent {
  @Input()
  disabled: boolean;
  @Input()
  range: PaginationRange;

  @Output()
  readonly rangeEvent: EventEmitter<PaginationRange> = new EventEmitter<PaginationRange>();

  readonly mapToDisabled: Mapper<PaginationRange, boolean> = (range: PaginationRange, disabled: boolean) =>
    disabled || (range && !this.hasPreviousPage(range) && !this.hasNextPage(range));
  readonly mapToHasNoNextPage: Mapper<PaginationRange, boolean> = (range: PaginationRange) => !this.hasNextPage(range);
  readonly mapToHasNoPreviousPage: Mapper<PaginationRange, boolean> = (range: PaginationRange) =>
    !this.hasPreviousPage(range);
  readonly mapToRangeLabel: Mapper<PaginationRange, string> = (range: PaginationRange) =>
    `${range.min + 1} à ${range.max + 1}`;

  previousPage(): void {
    if (!this.hasPreviousPage(this.range)) {
      return;
    }

    this.rangeEvent.emit(this.range.previous());
  }

  nextPage(): void {
    if (!this.hasNextPage(this.range)) {
      return;
    }

    this.rangeEvent.emit(this.range.next());
  }

  private hasNextPage(range: PaginationRange): boolean {
    return !!range.next();
  }

  private hasPreviousPage(range: PaginationRange): boolean {
    return !!range.previous();
  }
}
