<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <div role="button" class="mat-calendar-period-button" (click)="currentPeriodClicked()">
      {{ periodButtonText }}
      <mat-icon
        inline="true"
        class="tiime-calendar-arrow"
        fontIcon="icon-ic-arrow-bottom"
        [class.mat-calendar-invert]="calendar.currentView !== 'month'"
      ></mat-icon>
    </div>

    <div class="mat-calendar-spacer"></div>

    <mat-icon
      inline="true"
      role="button"
      class="tiime-calendar-previous-button"
      [ngClass]="{ disabled: !previousEnabled() }"
      (click)="previousClicked()"
      fontIcon="icon-chevron-left"
      tiimeTooltip
      [tooltipContent]="previousTooltipTemplate"
    ></mat-icon>
    <ng-template #previousTooltipTemplate>Précédent</ng-template>

    <mat-icon
      inline="true"
      role="button"
      class="tiime-calendar-next-button"
      [ngClass]="{ disabled: !nextEnabled() }"
      (click)="nextClicked()"
      fontIcon="icon-chevron-right"
      tiimeTooltip
      [tooltipContent]="nextTooltipTemplate"
    ></mat-icon>
    <ng-template #nextTooltipTemplate>Suivant</ng-template>
  </div>
</div>
