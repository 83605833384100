<form [formGroup]="companyForm">
  <tiime-form [tiimeFormExpanded]="expandedForms | includes: 'company'">
    <div tiimeFormTitle>Informations Entreprise</div>
    <div tiimeForm>
      <div tiimeFormColumn>
        <tiime-input-container
          tiimeFormInputContainer
          contentId="name"
          label="Nom"
          required="true"
          [errorMessage]="companyForm.nameErrorMessage"
        >
          <input
            id="name"
            type="text"
            placeholder="Nom"
            formControlName="name"
            appFormUpdateOnBlur
            (formChanges)="handleCompanyFormChange()"
            data-cy="company-form-name-input"
            autocomplete="off"
            #companyNameInput
          />
        </tiime-input-container>
        <div tiimeFormRow>
          <tiime-input-container
            tiimeFormInputContainer
            contentId="reference"
            label="Numéro de dossier"
            [errorMessage]="companyForm.referenceErrorMessage"
          >
            <input
              id="reference"
              type="text"
              placeholder="Numéro de dossier"
              formControlName="reference"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              appUppercaseInput
              data-cy="company-form-reference-input"
              autocomplete="off"
            />
          </tiime-input-container>
          <app-legal-form-select
            contentId="legalForm"
            tiimeFormInputContainer
            [selectData]="legalForms$ | async"
            [control]="companyForm.legalForm"
            (selectionChange)="handleCompanyFormChange()"
          ></app-legal-form-select>
        </div>
      </div>
      <div tiimeFormColumn>
        <app-business-unit-select
          tiimeFormInputContainer
          [required]="true"
          [selectData]="businessUnits"
          [control]="companyForm.businessUnit"
          [errorMessage]="companyForm.businessUnitErrorMessage"
          (selectionChange)="handleCompanyFormChange()"
        ></app-business-unit-select>
        <app-company-legal-structure-and-signatory-select
          *appHasRole
          [selectData]="legalStructuresAndSignatories()"
          [control]="companyForm.legalStructureAndSignatory"
          (selectionChange)="handleCompanyFormChange()"
        ></app-company-legal-structure-and-signatory-select>
      </div>
    </div>
  </tiime-form>
  <div tiimeFormVerticalSeparator></div>
  <tiime-form [tiimeFormExpanded]="expandedForms | includes: 'address'">
    <div tiimeFormTitle>Adresse</div>
    <div tiimeForm>
      <div tiimeFormColumn>
        <tiime-input-container tiimeFormInputContainer contentId="street" label="Numéro et nom de la voie">
          <input
            id="street"
            type="text"
            placeholder="Numéro et nom de la voie"
            formControlName="street"
            appFormUpdateOnBlur
            (formChanges)="handleCompanyFormChange()"
            data-cy="company-form-street-input"
            autocomplete="off"
          />
        </tiime-input-container>
        <div tiimeFormRow>
          <tiime-input-container tiimeFormInputContainer contentId="postalCode" label="Code postal">
            <input
              id="postalCode"
              type="text"
              placeholder="Code postal"
              formControlName="postalCode"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              data-cy="company-form-postal-code-input"
              autocomplete="off"
            />
          </tiime-input-container>
          <tiime-input-container tiimeFormInputContainer contentId="city" label="Ville">
            <input
              id="city"
              type="text"
              placeholder="Ville"
              formControlName="city"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              data-cy="company-form-city-input"
              autocomplete="off"
            />
          </tiime-input-container>
        </div>
      </div>
    </div>
  </tiime-form>
  <div tiimeFormVerticalSeparator></div>
  <tiime-form [tiimeFormExpanded]="expandedForms | includes: 'fiscal'">
    <div tiimeFormTitle>Informations Fiscales</div>
    <div tiimeForm>
      <div tiimeFormColumn>
        <app-vat-system-select
          [vatSystems]="vatSystems$ | async"
          [vatSystemsIdControl]="companyForm.vatSystem.id"
          (selectionChange)="handleCompanyFormChange()"
        ></app-vat-system-select>
        <div tiimeFormRow>
          <app-day-select
            label="Jour d'échéance de la TVA"
            [days]="VAT_DECLARATION_DAYS"
            [formControl]="companyForm.vatDeclarationDay"
            [allowEmptyOption]="true"
            (ngModelChange)="handleCompanyFormChange()"
            tiimeFormInputContainer
          ></app-day-select>
          <tiime-input-container contentId="codeRofVat" label="Code ROF TVA" tiimeFormInputContainer>
            <input
              id="codeRofVat"
              type="text"
              placeholder="Code ROF CVAE"
              formControlName="codeRofVat"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              autocomplete="off"
            />
          </tiime-input-container>
        </div>
      </div>
      <div tiimeFormColumn>
        <app-tax-regime-select
          [selectData]="taxRegimes$ | async"
          [control]="companyForm.taxRegime"
          (selectionChange)="handleCompanyFormChange()"
        ></app-tax-regime-select>
        <div tiimeFormRow>
          <tiime-input-container contentId="codeRofCvae" label="Code ROF CVAE" tiimeFormInputContainer>
            <input
              id="codeRofCvae"
              type="text"
              placeholder="Code ROF CVAE"
              formControlName="codeRofCvae"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              autocomplete="off"
            />
          </tiime-input-container>
          <tiime-input-container contentId="codeRofTaxRegime" label="Code ROF Liasse" tiimeFormInputContainer>
            <input
              id="codeRofTaxRegime"
              type="text"
              placeholder="Code ROF Liasse"
              formControlName="codeRofTaxRegime"
              appFormUpdateOnBlur
              (formChanges)="handleCompanyFormChange()"
              autocomplete="off"
            />
          </tiime-input-container>
        </div>
      </div>
    </div>
  </tiime-form>
  <div tiimeFormVerticalSeparator></div>
  <tiime-form [tiimeFormExpanded]="expandedForms | includes: 'legal'">
    <div tiimeFormTitle>Informations Juridiques</div>
    <div tiimeForm>
      <div tiimeFormColumn>
        <app-siret-input
          [errorMessage]="companyForm.siretErrorMessage"
          [siretFormControl]="companyForm.siret"
          (valueChanges)="handleCompanyFormChange()"
        ></app-siret-input>
        <app-date-input-cell
          placeholder="Date de début d'activité"
          [maxDate]="maxActivityStartDate"
          maxErrorMessage="La date ne doit pas être supérieure à un an"
          [data]="companyForm.activityStartDate.value"
          (save)="companyForm.activityStartDate.patchValue($event); handleCompanyFormChange()"
        ></app-date-input-cell>
        <tiime-input-container tiimeFormInputContainer contentId="shareCapital" label="Capital social">
          <input
            id="shareCapital"
            type="number"
            placeholder="Capital social"
            formControlName="shareCapital"
            (change)="handleCompanyFormChange()"
            data-cy="company-form-share-capital-input"
            autocomplete="off"
          />
        </tiime-input-container>
      </div>
      <div tiimeFormColumn>
        <app-ape-code-select
          [selectData]="apeCodes$ | async"
          [control]="companyForm.apeCode.id"
          (selectionChange)="handleCompanyFormChange()"
        ></app-ape-code-select>
        <app-company-registry-select
          [selectData]="cities()"
          [control]="companyForm.rcsCity"
          (selectionChange)="handleCompanyFormChange()"
        />
        <div tiimeFormRow>
          <tiime-input-container
            tiimeFormInputContainer
            contentId="shareValue"
            label="Valeur d'une part"
            [errorMessage]="companyForm.shareValueErrorMessage?.message"
          >
            <input
              id="shareValue"
              type="number"
              placeholder="Valeur d'une part"
              formControlName="shareValue"
              (change)="handleCompanyFormChange()"
              data-cy="company-form-share-value-input"
              autocomplete="off"
            />
          </tiime-input-container>
          <tiime-input-container
            tiimeFormInputContainer
            readonly
            contentId="sharesNumber"
            label="Nombre de parts"
            [errorMessage]="companyForm.sharesNumberErrorMessage?.message"
          >
            <input
              id="sharesNumber"
              type="number"
              placeholder="Nombre de parts"
              formControlName="sharesNumber"
              data-cy="company-form-shares-number-input"
              autocomplete="off"
              readonly
            />
          </tiime-input-container>
        </div>
      </div>
    </div>
  </tiime-form>
</form>
