import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [MatIconRegistry]
})
export class TiimeIconModule {
  constructor(
    public matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    matIconRegistry
      .setDefaultFontSetClass('tiime-fontastic')
      .addSvgIcon('icon-archived', this.setResourceUrl('archived'))
      .addSvgIcon('icon-unarchived', this.setResourceUrl('unarchived'))
      .addSvgIcon('icon-check', this.setResourceUrl('check'))
      .addSvgIcon('icon-uncheck', this.setResourceUrl('uncheck'))
      .addSvgIcon('icon-history-back', this.setResourceUrl('history-back'))
      .addSvgIcon('icon-receipt', this.setResourceUrl('receipt'))
      .addSvgIcon('icon-expense-report', this.setResourceUrl('expense-report'))
      .addSvgIcon('icon-advanced-expense', this.setResourceUrl('advanced-expense'))
      .addSvgIcon('icon-chat', this.setResourceUrl('chat'))
      .addSvgIcon('icon-arrow-right', this.setResourceUrl('arrow-right'))
      .addSvgIcon('icon-arrow-left', this.setResourceUrl('arrow-left'))
      .addSvgIcon('icon-double-arrow-right', this.setResourceUrl('double-arrow-right'))
      .addSvgIcon('icon-double-arrow-left', this.setResourceUrl('double-arrow-left'))
      .addSvgIcon('icon-invoice', this.setResourceUrl('invoice'))
      .addSvgIcon('icon-external-invoice', this.setResourceUrl('external-invoice'))
      .addSvgIcon('icon-calendar-today', this.setResourceUrl('calendar-today'))
      .addSvgIcon('icon-point', this.setResourceUrl('point'))
      .addSvgIcon('icon-eclair', this.setResourceUrl('eclair'))
      .addSvgIcon('icon-unpoint', this.setResourceUrl('unpoint'))
      .addSvgIcon('icon-update-clock', this.setResourceUrl('update-clock'))
      .addSvgIcon('icon-match', this.setResourceUrl('match'))
      .addSvgIcon('icon-file', this.setResourceUrl('file'))
      .addSvgIcon('icon-paper-plane', this.setResourceUrl('paper-plane'))
      .addSvgIcon('icon-more-time', this.setResourceUrl('more-time'))
      .addSvgIcon('icon-keyboard', this.setResourceUrl('keyboard'))
      .addSvgIcon('icon-unmatch', this.setResourceUrl('unmatch'))
      .addSvgIcon('icon-share', this.setResourceUrl('share'))
      .addSvgIcon('icon-magnify-list', this.setResourceUrl('magnify-list'))
      .addSvgIcon('icon-graphique', this.setResourceUrl('graphique'))
      .addSvgIcon('icon-phone', this.setResourceUrl('phone'))
      .addSvgIcon('icon-mail', this.setResourceUrl('mail'))
      .addSvgIcon('icon-mail-off', this.setResourceUrl('mail-off'))
      .addSvgIcon('icon-address', this.setResourceUrl('address'))
      .addSvgIcon('icon-settings-backup', this.setResourceUrl('settings-backup'))
      .addSvgIcon('icon-generate-document', this.setResourceUrl('generate-document'))
      .addSvgIcon('icon-unlock', this.setResourceUrl('unlock'))
      .addSvgIcon('icon-circle-arrow-left', this.setResourceUrl('circle-arrow-left'))
      .addSvgIcon('icon-circle-arrow-right', this.setResourceUrl('circle-arrow-right'))
      .addSvgIcon('icon-rotate', this.setResourceUrl('rotate'))
      .addSvgIcon('icon-warning', this.setResourceUrl('warning'))
      .addSvgIcon('icon-success', this.setResourceUrl('success'))
      .addSvgIcon('icon-note', this.setResourceUrl('note'))
      .addSvgIcon('icon-new-validate', this.setResourceUrl('new-validate'))
      .addSvgIcon('icon-do-not-disturb', this.setResourceUrl('do-not-disturb'))
      .addSvgIcon('icon-microsoft-word', this.setResourceUrl('microsoft-word'))
      .addSvgIcon('icon-pin', this.setResourceUrl('pin'))
      .addSvgIcon('icon-unpin', this.setResourceUrl('unpin'))
      .addSvgIcon('icon-company', this.setResourceUrl('company'))
      .addSvgIcon('icon-user', this.setResourceUrl('user'))
      .addSvgIcon('icon-download', this.setResourceUrl('download'))
      .addSvgIcon('icon-parameter', this.setResourceUrl('parameter'))
      .addSvgIcon('icon-duo', this.setResourceUrl('duo'))
      .addSvgIcon('icon-auto', this.setResourceUrl('auto'))
      .addSvgIcon('icon-calendar', this.setResourceUrl('calendar'))
      .addSvgIcon('icon-user-cravate', this.setResourceUrl('user-cravate'))
      .addSvgIcon('icon-percent', this.setResourceUrl('percent'))
      .addSvgIcon('icon-file-cross', this.setResourceUrl('file-cross'))
      .addSvgIcon('icon-file-info', this.setResourceUrl('file-info'))
      .addSvgIcon('icon-tune', this.setResourceUrl('tune'))
      .addSvgIcon('icon-id-card', this.setResourceUrl('id-card'))
      .addSvgIcon('icon-admin', this.setResourceUrl('admin'))
      .addSvgIcon('icon-contrats', this.setResourceUrl('contrats'))
      .addSvgIcon('icon-dossier', this.setResourceUrl('dossier'))
      .addSvgIcon('icon-notifications', this.setResourceUrl('notifications'))
      .addSvgIcon('icon-nouveautes', this.setResourceUrl('nouveautes'))
      .addSvgIcon('icon-planning', this.setResourceUrl('planning'))
      .addSvgIcon('icon-temps', this.setResourceUrl('temps'))
      .addSvgIcon('icon-prospect', this.setResourceUrl('prospect'))
      .addSvgIcon('icon-tag', this.setResourceUrl('tag'))
      .addSvgIcon('icon-done-all', this.setResourceUrl('done-all'))
      .addSvgIcon('icon-mail-sync', this.setResourceUrl('mail-sync'))
      .addSvgIcon('icon-google-calendar', this.setResourceUrl('google-calendar'));
  }

  private setResourceUrl(iconName: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`/dist/tiime-material/assets/icons/${iconName}.svg`);
  }
}
