import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { LegalStructure, LegalStructureJson, LegalStructureSerialized } from '@models/legal-structure';

@Injectable({
  providedIn: 'root'
})
export class LegalStructureApiService extends ApiCommonService<LegalStructure> {
  resourceUrl: string = `${API_ROUTE_BASE}/partners/{partnerId}/legal_structures`;
  buResourceUrl: string = `${API_ROUTE_BASE}/business_units/{businessUnitId}/legal_structures`;

  fromJson(json: LegalStructureJson): LegalStructure {
    return LegalStructure.fromJson(json);
  }

  toJson(model: LegalStructure): LegalStructureSerialized {
    return LegalStructure.toJson(model);
  }

  @ApiAlertError()
  getPartnerLegalStructures(partnerId: number, searchTerms?: string): Observable<LegalStructure[]> {
    const url: string = HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId);
    const params: HttpParams = HttpHelper.setQParam(searchTerms);
    return super.getAll({ params }, url);
  }

  @ApiAlertError()
  getBusinessUnitLegalStructures(businessUnitId: number, searchTerms?: string): Observable<LegalStructure[]> {
    const url: string = HttpHelper.replaceBusinessUnitUrl(this.buResourceUrl, businessUnitId);
    const params: HttpParams = HttpHelper.setQParam(searchTerms);
    return super.getAll({ params }, url);
  }

  @ApiAlertError()
  createLegalStructure(partnerId: number, legalStructure: LegalStructure): Observable<LegalStructure> {
    const url: string = HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId);
    const body: LegalStructureSerialized = this.toJson(legalStructure);
    return this.http
      .post(url, body)
      .pipe(map((legalStructureJson: LegalStructureJson) => this.fromJson(legalStructureJson)));
  }

  @ApiAlertError()
  updateLegalStructure(partnerId: number, legalStructure: LegalStructure): Observable<LegalStructure> {
    const url = `${HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId)}/${legalStructure.id}`;
    const body: LegalStructureSerialized = this.toJson(legalStructure);
    return this.http
      .patch(url, body)
      .pipe(map((legalStructureJson: LegalStructureJson) => this.fromJson(legalStructureJson)));
  }
}
