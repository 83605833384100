<tiime-input-container
  tiimeFormInputContainer
  contentId="legalStructureAndSignatory"
  [label]="label"
  [attr.small]="small"
>
  <tiime-select
    id="legalStructureAndSignatory"
    class="company-legal-structure-and-signatory-select"
    panelClass="company-legal-structure-and-signatory-select-panel-class"
    [formControl]="control"
    [customValue]="control.value ? (control.value | legalStructureAndSignatory) : ''"
    (selectionChange)="emitSelectionChange($event)"
  >
    <mat-icon
      *ngIf="control.value?.legalStructure?.archived || control.value?.signatory?.archived"
      tiimeSelectSuffix
      svgIcon="icon-archived"
      color="primary"
      tiimeTooltip="Archivée"
    ></mat-icon>
    <input
      tiimeSelectInput
      placeholder="Recherche"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <cdk-virtual-scroll-viewport
      appVirtualScrollViewportSize
      class="tiime-virtual-scroll-viewport"
      [itemSize]="virtualScrollItemSize"
      tiimeSelectOptions
    >
      <ng-container *ngIf="displayKeepAndRemoveOption">
        <mat-option [value]="keepLegalStructureAndSignatoryOption">Conserver</mat-option>
        <mat-option [value]="removeLegalStructureAndSignatoryOption">Retirer</mat-option>
      </ng-container>
      <mat-option *ngIf="allowEmptyOption" [value]="null"></mat-option>
      <mat-option
        *cdkVirtualFor="let legalStructureAndSignatory of filteredSelectorData; trackBy: trackById"
        [value]="legalStructureAndSignatory"
      >
        <div class="company-legal-structure-and-signatory-select-option flex row gap-5">
          <span class="legal-structure-label">{{ legalStructureAndSignatory?.legalStructure?.name }}</span>
          <ng-container *ngIf="legalStructureAndSignatory?.signatory">
            <span>•</span>
            <span class="signatory-label">{{ legalStructureAndSignatory.signatory.user?.fullName }}</span>
          </ng-container>
        </div>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </tiime-select>
</tiime-input-container>
