import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { EditCustomPeriodDialogData } from '@modules/shared/dialogs/edit-custom-period-dialog/edit-custom-period-dialog-data';

import { EditCustomPeriodDialogComponent } from './edit-custom-period-dialog.component';

@Injectable({ providedIn: 'root' })
export class EditCustomPeriodDialogService extends createDialogServiceBase<number, EditCustomPeriodDialogData>(
  EditCustomPeriodDialogComponent
) {}
