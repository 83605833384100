import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { CareContactDialogComponent } from './care-contact-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CareContactDialogService extends createDialogServiceBase<void, null>(CareContactDialogComponent) {}
