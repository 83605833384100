import { SocialRegimeCode } from '@enums/social-regime-code.enum';
import { TaxRegimeCode } from '@enums/tax-regime-code.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { AccountingPeriod } from '@models/accounting-period';
import { Company, CompanyJson } from '@models/company';
import { User, UserJson } from '@models/user';

export interface TnsJson {
  id: number;
  company: Partial<CompanyJson>;
  user: Partial<UserJson>;
  accounting_period: Partial<AccountingPeriod>;
  tax_regime: TaxRegimeCode;
  regime_social: SocialRegimeCode;
  cotisations_facultatives_retraite: number;
  cotisations_facultatives_per: number;
  cotisations_facultatives_prevention: number;
  remuneration_totale: number;
  enveloppe_disponible: number;
  charges: number;
  charges_hors_remuneration: number;
  chiffre_affaires: number;
  debut_mandat_date: string;
  exoneration_acre: boolean;
  remuneration_nette_comptabilite: number;
  remuneration_nette_saisie: number;
  cotisations_obligatoires_comptabilite: number;
  cotisations_obligatoires_saisies: number;
  montant_csg: number;
  cotisation_formation: number;
  total_cotisations_et_contributions: number;
  cotisations_facultatives_retraite_deductible: number;
  cotisations_facultatives_prevention_deductible: number;
  cotisations_sociales_et_contributions: number;
  base_sociale: number;
  taux_de_charges_sociales: number;
  impact_prime_remuneration: number;
  has_impact_prime_remuneration: boolean;
  impact_regulation_cotisations_obligatoires: number;
  has_impact_regulation_cotisations_obligatoires: boolean;
  impact_csg_deductible: number;
  has_impact_csg_deductible: boolean;
  impact_csg_non_deductible: number;
  has_impact_csg_non_deductible: boolean;
  impact_cotisations_facultatives_prevoyance_non_deductibles: number;
  has_impact_cotisations_facultatives_prevoyance_non_deductibles: boolean;
  impact_cotisations_facultatives_retraite_non_deductibles: number;
  has_impact_cotisations_facultatives_retraite_non_deductibles: boolean;
  impact_montant_cotisations_formation: number;
  has_impact_montant_cotisations_formation: boolean;
  created_at: string;
  updated_at: string;
}

export class Tns {
  constructor(
    public id?: number,
    public company?: Company,
    public user?: User,
    public accountingPeriod?: AccountingPeriod,
    public taxRegime?: TaxRegimeCode,
    public regimeSocial?: SocialRegimeCode,
    public cotisationsFacultativesRetraite?: number,
    public cotisationsFacultativesPer?: number,
    public cotisationsFacultativesPrevention?: number,
    public remunerationTotale?: number,
    public enveloppeDisponible?: number,
    public charges?: number,
    public chargesHorsRemuneration?: number,
    public chiffreAffaires?: number,
    public debutMandatDate?: string,
    public exonerationAcre?: boolean,
    public remunerationNetteComptabilite?: number,
    public remunerationNetteSaisie?: number,
    public cotisationsObligatoiresComptabilite?: number,
    public cotisationsObligatoiresSaisie?: number,
    public montantCsg?: number,
    public cotisationFormation?: number,
    public totalCotisationsEtContributions?: number,
    public cotisationsFacultativesRetraiteDeductible?: number,
    public cotisationsFacultativesPreventionDeductible?: number,
    public cotisationsSocialesEtContributions?: number,
    public baseSociale?: number,
    public tauxDeChargesSociales?: number,
    public impactPrimeRemuneration?: number,
    public hasImpactPrimeRemuneration?: boolean,
    public impactRegulationCotisationsObligatoires?: number,
    public hasImpactRegulationCotisationsObligatoires?: boolean,
    public impactCsgDeductible?: number,
    public hasImpactCsgDeductible?: boolean,
    public impactCsgNonDeductible?: number,
    public hasImpactCsgNonDeductible?: boolean,
    public impactCotisationsFacultativesPrevoyanceNonDeductibles?: number,
    public hasImpactCotisationsFacultativesPrevoyanceNonDeductibles?: boolean,
    public impactCotisationsFacultativesRetraiteNonDeductibles?: number,
    public hasImpactCotisationsFacultativesRetraiteNonDeductibles?: boolean,
    public impactMontantCotisationsFormation?: number,
    public hasImpactMontantCotisationsFormation?: boolean,
    public createdAt?: Date,
    public updatedAt?: Date
  ) {}

  static fromJson(json: Partial<TnsJson>): Tns {
    return new Tns(
      json.id,
      json.company ? Company.fromJson(json.company) : null,
      json.user ? User.fromJson(json.user) : null,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.tax_regime,
      json.regime_social,
      json.cotisations_facultatives_retraite,
      json.cotisations_facultatives_per,
      json.cotisations_facultatives_prevention,
      json.remuneration_totale,
      json.enveloppe_disponible,
      json.charges,
      json.charges_hors_remuneration,
      json.chiffre_affaires,
      json.debut_mandat_date,
      json.exoneration_acre,
      json.remuneration_nette_comptabilite,
      json.remuneration_nette_saisie,
      json.cotisations_obligatoires_comptabilite,
      json.cotisations_obligatoires_saisies,
      json.montant_csg,
      json.cotisation_formation,
      json.total_cotisations_et_contributions,
      json.cotisations_facultatives_retraite_deductible,
      json.cotisations_facultatives_prevention_deductible,
      json.cotisations_sociales_et_contributions,
      json.base_sociale,
      json.taux_de_charges_sociales,
      json.impact_prime_remuneration,
      json.has_impact_prime_remuneration,
      json.impact_regulation_cotisations_obligatoires,
      json.has_impact_regulation_cotisations_obligatoires,
      json.impact_csg_deductible,
      json.has_impact_csg_deductible,
      json.impact_csg_non_deductible,
      json.has_impact_csg_non_deductible,
      json.impact_cotisations_facultatives_prevoyance_non_deductibles,
      json.has_impact_cotisations_facultatives_prevoyance_non_deductibles,
      json.impact_cotisations_facultatives_retraite_non_deductibles,
      json.has_impact_cotisations_facultatives_retraite_non_deductibles,
      json.impact_montant_cotisations_formation,
      json.has_impact_montant_cotisations_formation,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.updated_at ? DateHelper.fromUtc(json.updated_at) : null
    );
  }

  static toJson(tns: Tns): Partial<TnsJson> {
    return {
      id: HttpHelper.toJsonProperty(tns.id),
      tax_regime: HttpHelper.toJsonProperty(tns.taxRegime),
      regime_social: HttpHelper.toJsonProperty(tns.regimeSocial),
      cotisations_facultatives_retraite: HttpHelper.toJsonProperty(tns.cotisationsFacultativesRetraite),
      cotisations_facultatives_per: HttpHelper.toJsonProperty(tns.cotisationsFacultativesPer),
      cotisations_facultatives_prevention: HttpHelper.toJsonProperty(tns.cotisationsFacultativesPrevention),
      remuneration_totale: HttpHelper.toJsonProperty(tns.remunerationTotale),
      enveloppe_disponible: HttpHelper.toJsonProperty(tns.enveloppeDisponible),
      charges: HttpHelper.toJsonProperty(tns.charges),
      charges_hors_remuneration: HttpHelper.toJsonProperty(tns.chargesHorsRemuneration),
      chiffre_affaires: HttpHelper.toJsonProperty(tns.chiffreAffaires),
      debut_mandat_date: HttpHelper.toJsonProperty(tns.debutMandatDate),
      exoneration_acre: HttpHelper.toJsonProperty(tns.exonerationAcre),
      remuneration_nette_comptabilite: HttpHelper.toJsonProperty(tns.remunerationNetteComptabilite),
      remuneration_nette_saisie: HttpHelper.toJsonProperty(tns.remunerationNetteSaisie),
      cotisations_obligatoires_comptabilite: HttpHelper.toJsonProperty(tns.cotisationsObligatoiresComptabilite),
      cotisations_obligatoires_saisies: HttpHelper.toJsonProperty(tns.cotisationsObligatoiresSaisie),
      montant_csg: HttpHelper.toJsonProperty(tns.montantCsg),
      cotisation_formation: HttpHelper.toJsonProperty(tns.cotisationFormation),
      total_cotisations_et_contributions: HttpHelper.toJsonProperty(tns.totalCotisationsEtContributions),
      cotisations_facultatives_retraite_deductible: HttpHelper.toJsonProperty(
        tns.cotisationsFacultativesRetraiteDeductible
      ),
      cotisations_facultatives_prevention_deductible: HttpHelper.toJsonProperty(
        tns.cotisationsFacultativesPreventionDeductible
      ),
      cotisations_sociales_et_contributions: HttpHelper.toJsonProperty(tns.cotisationsSocialesEtContributions),
      base_sociale: HttpHelper.toJsonProperty(tns.baseSociale),
      taux_de_charges_sociales: HttpHelper.toJsonProperty(tns.tauxDeChargesSociales),
      impact_prime_remuneration: HttpHelper.toJsonProperty(tns.impactPrimeRemuneration),
      has_impact_prime_remuneration: HttpHelper.toJsonProperty(tns.hasImpactPrimeRemuneration),
      impact_regulation_cotisations_obligatoires: HttpHelper.toJsonProperty(
        tns.impactRegulationCotisationsObligatoires
      ),
      has_impact_regulation_cotisations_obligatoires: HttpHelper.toJsonProperty(
        tns.hasImpactRegulationCotisationsObligatoires
      ),
      impact_csg_deductible: HttpHelper.toJsonProperty(tns.impactCsgDeductible),
      has_impact_csg_deductible: HttpHelper.toJsonProperty(tns.hasImpactCsgDeductible),
      impact_csg_non_deductible: HttpHelper.toJsonProperty(tns.impactCsgNonDeductible),
      has_impact_csg_non_deductible: HttpHelper.toJsonProperty(tns.hasImpactCsgNonDeductible),
      impact_cotisations_facultatives_prevoyance_non_deductibles: HttpHelper.toJsonProperty(
        tns.impactCotisationsFacultativesPrevoyanceNonDeductibles
      ),
      has_impact_cotisations_facultatives_prevoyance_non_deductibles: HttpHelper.toJsonProperty(
        tns.hasImpactCotisationsFacultativesPrevoyanceNonDeductibles
      ),
      impact_cotisations_facultatives_retraite_non_deductibles: HttpHelper.toJsonProperty(
        tns.impactCotisationsFacultativesRetraiteNonDeductibles
      ),
      has_impact_cotisations_facultatives_retraite_non_deductibles: HttpHelper.toJsonProperty(
        tns.hasImpactCotisationsFacultativesRetraiteNonDeductibles
      ),
      impact_montant_cotisations_formation: HttpHelper.toJsonProperty(tns.impactMontantCotisationsFormation),
      has_impact_montant_cotisations_formation: HttpHelper.toJsonProperty(tns.hasImpactMontantCotisationsFormation)
    };
  }
}
