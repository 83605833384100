<tiime-table-anchor></tiime-table-anchor>
<table
  aria-label="Tableau du paramétrage d'un plan de catégorie"
  tiime-table
  tiimeStickyTable
  [dataSource]="data"
  [trackBy]="trackByFormId"
  [scrollContainer]="scrollContainer"
>
  <ng-container cdkColumnDef="category">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef>Catégorie</th>
    <td cdk-cell *cdkCellDef="let labelCategoryPlanSetupForm">
      <app-simple-cell [value]="labelCategoryPlanSetupForm.controls.category.controls.label.value"></app-simple-cell>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="bankPcgEntry">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef [class.align-column-with-input-value]="mode === 'form'">
      Contrepartie de la banque
    </th>
    <td cdk-cell *cdkCellDef="let labelCategoryPlanSetupForm">
      <ng-container *ngIf="mode === 'form'; else textMode">
        <input
          [formControl]="labelCategoryPlanSetupForm.controls.bankAccountNumber"
          [matAutocomplete]="automaticBankPcgEntryModeTypeAutocomplete"
          appFormUpdateOnBlur
          (keyup.enter)="emitBankAccountNumberChanges(labelCategoryPlanSetupForm)"
          (formChanges)="emitBankAccountNumberChanges(labelCategoryPlanSetupForm)"
        />
        <mat-autocomplete
          class="tiime-autocomplete-panel"
          #automaticBankPcgEntryModeTypeAutocomplete="matAutocomplete"
          (optionSelected)="emitBankAccountNumberChanges(labelCategoryPlanSetupForm)"
        >
          <mat-option [value]="BANK_PCG_ENTRY_SALES_CUSTOM_OPTION.value">
            <span>{{ BANK_PCG_ENTRY_SALES_CUSTOM_OPTION.name }}</span>
          </mat-option>
          <mat-option [value]="BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION.value">
            <span>{{ BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION.name }}</span>
          </mat-option>
        </mat-autocomplete>
        <app-list-error
          *ngIf="labelCategoryPlanSetupForm.bankAccountNumberErrorMessage"
          [errorMessage]="labelCategoryPlanSetupForm.bankAccountNumberErrorMessage"
        ></app-list-error>
      </ng-container>
      <ng-template #textMode>
        <app-simple-cell [value]="labelCategoryPlanSetupForm.controls.bankAccountNumber.value"></app-simple-cell>
      </ng-template>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="piecePcgEntry">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef [class.align-column-with-input-value]="mode === 'form'">
      Compte de pièce
    </th>
    <td cdk-cell *cdkCellDef="let labelCategoryPlanSetupForm">
      <ng-container *ngIf="mode === 'form'; else textMode">
        <input
          [formControl]="labelCategoryPlanSetupForm.controls.pieceAccountNumber"
          [matAutocomplete]="withoutAccountingAutocomplete"
          appFormUpdateOnBlur
          (formChanges)="emitPieceAccountNumberChanges(labelCategoryPlanSetupForm)"
        />
        <mat-autocomplete
          class="tiime-autocomplete-panel"
          #withoutAccountingAutocomplete="matAutocomplete"
          autoActiveFirstOption
          (optionSelected)="emitPieceAccountNumberChanges(labelCategoryPlanSetupForm)"
        >
          <mat-option [value]="PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION.value">
            <span>{{ PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION.name }}</span>
          </mat-option>
        </mat-autocomplete>
        <app-list-error
          *ngIf="labelCategoryPlanSetupForm.pieceAccountNumberErrorMessage"
          [errorMessage]="labelCategoryPlanSetupForm.pieceAccountNumberErrorMessage"
        ></app-list-error>
      </ng-container>
      <ng-template #textMode>
        <app-simple-cell [value]="labelCategoryPlanSetupForm.controls.pieceAccountNumber.value"></app-simple-cell>
      </ng-template>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="vatType">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef [class.align-column-with-input-value]="mode === 'form'">TVA</th>
    <td cdk-cell *cdkCellDef="let labelCategoryPlanSetupForm">
      <ng-container *ngIf="mode === 'form'; else textMode">
        <app-vat-type-select
          [control]="labelCategoryPlanSetupForm.controls.vatTypeId"
          [selectData]="vatTypes"
          [small]="true"
          [errorMessage]="labelCategoryPlanSetupForm.vatTypeIdErrorMessage"
          errorMessageMode="list"
          label=""
          (selectionChange)="emitVaTypeIdChanges(labelCategoryPlanSetupForm)"
        ></app-vat-type-select>
        <app-vat-exoneration-type-select
          [control]="labelCategoryPlanSetupForm.controls.vatExonerationType"
          [selectData]="vatExonerationTypes"
          [small]="true"
          [errorMessage]="labelCategoryPlanSetupForm.vatExonerationTypeErrorMessage"
          errorMessageMode="list"
          label=""
          (selectionChange)="emitVatExonerationTypeChanges(labelCategoryPlanSetupForm)"
          *ngIf="
            labelCategoryPlanSetupForm.controls.vatTypeId.value
              | vatExonerationTypeRequired : vatTypeRequiringVatExonerationType
          "
        ></app-vat-exoneration-type-select>
      </ng-container>
      <ng-template #textMode>
        <app-simple-cell
          [value]="labelCategoryPlanSetupForm.controls.vatTypeId.value | vatTypeRate : vatTypes"
        ></app-simple-cell>
        <ng-container
          *ngIf="
            labelCategoryPlanSetupForm.controls.vatTypeId.value
              | vatExonerationTypeRequired : vatTypeRequiringVatExonerationType
          "
        >
          -
          <app-simple-cell
            [value]="
              labelCategoryPlanSetupForm.controls.vatExonerationType.value | vatExonerationType : vatExonerationTypes
            "
          ></app-simple-cell>
        </ng-container>
      </ng-template>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="autoSaveStatus">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef></th>
    <td cdk-cell *cdkCellDef="let labelCategoryPlanSetupForm">
      <tiime-auto-save-status [id]="labelCategoryPlanSetupForm.controls.id.value"></tiime-auto-save-status>
    </td>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
  <tr
    cdk-row
    *cdkRowDef="let labelCategoryPlanSetupForm; let e = even; columns: displayedColumns"
    [class.alternate]="e"
  ></tr>
</table>
