export interface KeyboardShortcut {
  type: string;
  shortcuts: {
    description: string;
    keys: string[];
    firefoxAvailable: boolean;
  }[];
}

export const KEYBOARD_SHORTCUTS: KeyboardShortcut[] = [
  {
    type: 'Tiime Expert',
    shortcuts: [
      {
        description: 'Accéder au planning',
        keys: ['SHIFT', 'P'],
        firefoxAvailable: false
      },
      {
        description: 'Rechercher un dossier',
        keys: ['SHIFT', 'F'],
        firefoxAvailable: true
      },
      {
        description: 'Ajouter un dossier',
        keys: ['SHIFT', 'A'],
        firefoxAvailable: true
      },
      {
        description: 'Aller à la pièce suivante',
        keys: ['SHIFT', '→'],
        firefoxAvailable: true
      },
      {
        description: 'Aller à la pièce précédente',
        keys: ['SHIFT', '←'],
        firefoxAvailable: true
      }
    ]
  },
  {
    type: 'Votre navigateur',
    shortcuts: [
      {
        description: 'Ouvrir un nouvel onglet',
        keys: ['T'],
        firefoxAvailable: true
      },
      {
        description: 'Ouvrir une nouvelle fenêtre',
        keys: ['N'],
        firefoxAvailable: true
      },
      {
        description: "Fermer l'onglet actif",
        keys: ['W'],
        firefoxAvailable: true
      },
      {
        description: "Aller à l'onglet suivant",
        keys: ['TAB'],
        firefoxAvailable: true
      },
      {
        description: "Aller à l'onglet précédent",
        keys: ['SHIFT', 'TAB'],
        firefoxAvailable: true
      }
    ]
  }
];
