export interface LegalFormJson {
  entity_duration_years?: number;
  legal_form?: string;
  management_type?: string;
  name?: string;
  short_name?: string;
  commercial_name?: string;
  website?: string;
  estimated_creation_date?: string;
}

export class LegalForm {
  constructor(
    public entityDurationYears?: number,
    public legalForm?: string,
    public managementType?: string,
    public name?: string,
    public shortName?: string,
    public commercialName?: string,
    public website?: string,
    public estimatedCreationDate?: string
  ) {}

  static fromJson(json: Partial<LegalFormJson>): LegalForm {
    return new LegalForm(
      json.entity_duration_years,
      json.legal_form,
      json.management_type,
      json.name,
      json.short_name,
      json.commercial_name,
      json.website,
      json.estimated_creation_date
    );
  }
  static toJson(legalForm: LegalForm): LegalFormJson {
    return {
      entity_duration_years: legalForm.entityDurationYears,
      legal_form: legalForm.legalForm,
      management_type: legalForm.managementType,
      name: legalForm.name,
      short_name: legalForm.shortName,
      commercial_name: legalForm.commercialName,
      website: legalForm.website,
      estimated_creation_date: legalForm.estimatedCreationDate
    };
  }
}
