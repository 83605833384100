import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ErrorMessage } from '@interfaces/error-message';
import { ExportDatepickerComponent } from '@modules/shared/components/export-datepicker/export-datepicker.component';
import { ListErrorComponent } from '@modules/shared/components/list-error/list-error.component';

@Component({
  selector: 'app-export-period',
  standalone: true,
  imports: [CommonModule, ExportDatepickerComponent, ListErrorComponent],
  templateUrl: './export-period.component.html',
  styleUrls: ['./export-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportPeriodComponent {
  @Input() endDate: FormControl<string | null | undefined>;
  @Input() maxEndDate: string;
  @Input() endDateErrorMessage: ErrorMessage;
  @Input() label = 'Écritures du';
  @Input() startDate: FormControl<string | null | undefined>;
  @Input() minStartDate: string;
  @Input() startDateErrorMessage: ErrorMessage;
}
