import { FormControl, FormGroup } from '@angular/forms';

export type AddressFormType = {
  mailingStreet: FormControl<string | null>;
  mailingPostalCode: FormControl<string | null>;
  mailingCity: FormControl<string | null>;
  mailingCountry: FormControl<string | null>;
};

export class AddressForm extends FormGroup<AddressFormType> {
  get mailingStreet(): FormControl<string | null> {
    return this.controls.mailingStreet;
  }

  get mailingPostalCode(): FormControl<string | null> {
    return this.controls.mailingPostalCode;
  }

  get mailingCity(): FormControl<string | null> {
    return this.controls.mailingCity;
  }

  get mailingCountry(): FormControl<string | null> {
    return this.controls.mailingCountry;
  }

  constructor() {
    super({
      mailingStreet: new FormControl<string | null>(null),
      mailingPostalCode: new FormControl<string | null>(null),
      mailingCity: new FormControl<string | null>(null),
      mailingCountry: new FormControl<string | null>(null)
    });
  }
}
