import { Beneficiary, BeneficiaryJson } from './beneficiary';
import { ShareCapitalTypeEnum } from '../enums/share-capital-type.enum';

export interface CapitalJson {
  share_capital?: number;
  shares_number?: number;
  share_value?: number;
  beneficiaries?: BeneficiaryJson[];
  notary?: string;
  notary_address?: string;
  capital_deposit_type?: ShareCapitalTypeEnum;
  tiime_business_release_capital?: boolean;
  tiime_business_refusal_reason?: string;
  tiime_business_refusal_comment?: string;
  tiime_business_proposal_user_id?: number;
  tiime_business_request_date?: string;
  tiime_business_requester_name?: string;
}

export class Capital {
  constructor(
    public shareCapital?: number,
    public sharesNumber?: number,
    public shareValue?: number,
    public beneficiaries?: Beneficiary[],
    public notary?: string,
    public notaryAddress?: string,
    public capitalDepositType?: ShareCapitalTypeEnum,
    public tiimeBusinessReleaseCapital?: boolean,
    public tiimeBusinessRefusalReason?: string,
    public tiimeBusinessRefusalComment?: string,
    public tiimeBusinessProposalUserId?: number,
    public tiimeBusinessRequestDate?: string,
    public tiimeBusinessRequesterName?: string
  ) {}

  static fromJson(json: Partial<CapitalJson>): Capital {
    return new Capital(
      json.share_capital,
      json.shares_number,
      json.share_value,
      json.beneficiaries
        ? json.beneficiaries.map((beneficiaryJson: BeneficiaryJson) => Beneficiary.fromJson(beneficiaryJson))
        : null,
      json.notary,
      json.notary_address,
      json.capital_deposit_type,
      json.tiime_business_release_capital,
      json.tiime_business_refusal_reason,
      json.tiime_business_refusal_comment,
      json.tiime_business_proposal_user_id,
      json.tiime_business_request_date,
      json.tiime_business_requester_name
    );
  }

  static toJson(capital: Capital): CapitalJson {
    return {
      share_capital: capital.shareCapital,
      shares_number: capital.sharesNumber,
      share_value: capital.shareValue,
      beneficiaries: capital.beneficiaries
        ? capital.beneficiaries.map((beneficiary: Beneficiary) => Beneficiary.toJson(beneficiary))
        : null,
      notary: capital.notary,
      notary_address: capital.notaryAddress,
      capital_deposit_type: capital.capitalDepositType,
      tiime_business_release_capital: capital.tiimeBusinessReleaseCapital,
      tiime_business_refusal_reason: capital.tiimeBusinessRefusalReason,
      tiime_business_refusal_comment: capital.tiimeBusinessRefusalComment,
      tiime_business_proposal_user_id: capital.tiimeBusinessProposalUserId,
      tiime_business_request_date: capital.tiimeBusinessRequestDate,
      tiime_business_requester_name: capital.tiimeBusinessRequesterName
    };
  }

  static toTiimeBusinessProposalUserIdJson(tiimeBusinessProposalUserId: number): Partial<CapitalJson> {
    return {
      tiime_business_proposal_user_id: tiimeBusinessProposalUserId
    };
  }

  static toTiimeBusinessJson(capital: Partial<Capital>): Partial<CapitalJson> {
    return {
      tiime_business_release_capital: capital.tiimeBusinessReleaseCapital,
      tiime_business_refusal_reason: capital.tiimeBusinessRefusalReason,
      tiime_business_refusal_comment: capital.tiimeBusinessRefusalComment
    };
  }
}
