export interface AnnualBookletExportTypeJson {
  export_type?: string;
  label?: string;
  activated?: boolean;
  position?: number;
}

export class AnnualBookletExportType {
  constructor(
    public exportType?: string,
    public label?: string,
    public activated?: boolean,
    public position?: number
  ) {}

  static fromJson(annualBookletExportTypeJson: AnnualBookletExportTypeJson): AnnualBookletExportType {
    return new AnnualBookletExportType(
      annualBookletExportTypeJson.export_type,
      annualBookletExportTypeJson.label,
      annualBookletExportTypeJson.activated,
      annualBookletExportTypeJson.position
    );
  }

  static toJson(annualBookletExportType: AnnualBookletExportType): AnnualBookletExportTypeJson {
    return {
      export_type: annualBookletExportType.exportType,
      activated: annualBookletExportType.activated,
      position: annualBookletExportType.position
    };
  }
}
