import { ChangeDetectionStrategy, Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';

import { NgUtils } from 'tiime-expert-utils';

import { TableComponent } from './table.component';
import { ContainerState } from '../container';

@Component({
  selector: 'tiime-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableContainerComponent {
  @Input('containerState')
  set setContainerState(containerState: ContainerState) {
    this.containerState = containerState;
    this.manageTableEmptyClass();
  }
  @ContentChild(TableComponent, { static: true }) table: TableComponent<any>;

  containerState: ContainerState;
  readonly contentPlaceholderRows = Array(15);
  readonly ContainerState = ContainerState;
  readonly trackByIndex = NgUtils.trackByIndex;

  private manageTableEmptyClass(): void {
    if (
      this.table &&
      (this.containerState === ContainerState.contentPlaceholder || this.containerState === ContainerState.noResult)
    ) {
      this.table._elementRef.nativeElement.classList.add('empty');
    } else {
      this.table._elementRef.nativeElement.classList.remove('empty');
    }
  }
}
