import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';

@Component({
  selector: 'tiime-progress-bar-loader',
  templateUrl: './progress-bar-loader.component.html',
  styleUrls: ['./progress-bar-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarLoaderComponent {
  @Input() mode: ProgressBarMode;
  @Input() color: string;
  @Input() value?: number;
  @Input() displayMode = 'column';
}
