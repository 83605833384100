import { LabelSource } from '@enums/label-source.enum';
import { SaleType } from '@enums/sale-type.enum';
import { VatArea } from '@enums/vat-area.enum';
import { HttpHelper } from '@helpers/http.helper';
import { LabelCategoryPlan, LabelCategoryPlanJson } from '@models/label-category-plan';
import { SubPcgEntry, SubPcgEntryJson } from '@models/sub-pcg-entry';
import { VatExonerationType } from '@models/vat-exoneration-type';
import { VatType, VatTypeJson } from '@models/vat-type';

export interface LabelJson {
  id: number;
  label: string;
  vat_type: Partial<VatTypeJson>;
  bank_pcg_entry: Partial<SubPcgEntryJson>;
  vat_exoneration_type: string;
  invoice_client: boolean;
  piece_pcg_entry: Partial<SubPcgEntryJson>;
  disabled: boolean;
  predictable: boolean;
  vat_exigibility: string;
  exigibility_vat_type: Partial<VatTypeJson>;
  category: LabelCategoryPlanJson;
  vat_area: VatArea;
  sale_type: SaleType;
  configuration_source: LabelSource;
  client?: { name: string };
}

export class Label {
  constructor(
    public id?: number,
    public label?: string,
    public vatType?: VatType,
    public bankPcgEntry?: SubPcgEntry,
    public vatExonerationType?: VatExonerationType,
    public invoiceClient?: boolean,
    public piecePcgEntry?: SubPcgEntry,
    public disabled?: boolean,
    public predictable?: boolean,
    public vatExigibility?: string,
    public exigibilityVatType?: VatType,
    public category?: LabelCategoryPlan,
    public vatArea?: VatArea,
    public saleType?: SaleType,
    public configurationSource?: LabelSource,
    public client?: { name: string }
  ) {}

  static fromJson(json: Partial<LabelJson>): Label {
    return new Label(
      json.id,
      json.label,
      json.vat_type ? VatType.fromJson(json.vat_type) : null,
      json.bank_pcg_entry ? SubPcgEntry.fromJson(json.bank_pcg_entry) : null,
      json.vat_exoneration_type
        ? VatExonerationType.fromJson({
            name: json.vat_exoneration_type,
            value: ''
          })
        : null,
      json.invoice_client,
      json.piece_pcg_entry ? SubPcgEntry.fromJson(json.piece_pcg_entry) : null,
      json.disabled,
      json.predictable,
      json.vat_exigibility,
      json.exigibility_vat_type ? VatType.fromJson(json.exigibility_vat_type) : null,
      json.category ? LabelCategoryPlan.fromJson(json.category) : null,
      json.vat_area,
      json.sale_type,
      json.configuration_source
    );
  }

  static toJson(label: Label): Partial<LabelJson> {
    return {
      id: label.id ?? undefined,
      label: label.label,
      vat_type: label.vatType && label.vatType.id ? HttpHelper.toJsonId(label.vatType) : null,
      piece_pcg_entry: label.piecePcgEntry && label.piecePcgEntry.id ? HttpHelper.toJsonId(label.piecePcgEntry) : null,
      bank_pcg_entry: label.bankPcgEntry && label.bankPcgEntry.id ? HttpHelper.toJsonId(label.bankPcgEntry) : null,
      vat_exoneration_type: label.vatExonerationType ? label.vatExonerationType.name : null,
      disabled: label.disabled,
      vat_exigibility: label.vatExigibility,
      exigibility_vat_type:
        label.exigibilityVatType && label.exigibilityVatType.id ? HttpHelper.toJsonId(label.exigibilityVatType) : null,
      vat_area: label.vatArea,
      sale_type: label.saleType,
      configuration_source: label.configurationSource,
      client: label.client
    };
  }

  static toJsonId(label: Label): Pick<LabelJson, 'id'> {
    return {
      id: label ? label.id : null
    };
  }
}
