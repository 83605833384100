import { ContractStatus } from '@enums/contract-status.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { Company, CompanyJson } from '@models/company';
import { ContractOwner, ContractOwnerJson } from '@models/contract-owner';
import { ContractReminder, ContractReminderJson } from '@models/contract-reminder';
import { GIFile, GIFileJson } from '@models/gi-file';
import { Prestation, PrestationJson } from '@models/prestation';
import { Prospect, ProspectJson } from '@models/prospect';
import { User, UserJson } from '@models/user';

export interface ContractJson {
  id: number;
  start_date: string;
  internal_comment: string;
  status: ContractStatus;
  contract_prestations: Partial<PrestationJson>[];
  contract_files: Partial<GIFileJson>[];
  offer_model_files: Partial<GIFileJson>[];
  contract_owners: Partial<ContractOwnerJson>[];
  company: Partial<CompanyJson>;
  business_unit: Partial<BusinessUnitJson>;
  contact: Partial<UserJson>;
  sent_at: string;
  signed_at: string;
  sent_by: Partial<BusinessUserJson>;
  signed_by: Partial<UserJson>;
  prospect: Partial<ProspectJson>;
  created_at: string;
  created_by: Partial<BusinessUserJson>;
  lost_at: string;
  lost_by: Partial<BusinessUserJson>;
  loss_reason: string;
  loss_comment: string;
  archived_at: string;
  archived_by: Partial<BusinessUserJson>;
  status_at: string;
  status_by: Partial<BusinessUserJson>;
  contract_reminders: Partial<ContractReminderJson>[];
}

export class Contract {
  constructor(
    public id?: number,
    public startDate?: string,
    public internalComment?: string,
    public status?: ContractStatus,
    public prestations?: Prestation[],
    public files?: GIFile[],
    public offerModelFiles?: GIFile[],
    public owners?: ContractOwner[],
    public company?: Company,
    public businessUnit?: BusinessUnit,
    public contact?: User,
    public sentAt?: Date,
    public signedAt?: Date,
    public sentBy?: BusinessUser,
    public signedBy?: User,
    public prospect?: Prospect,
    public createdAt?: Date,
    public createdBy?: BusinessUser,
    public lostAt?: Date,
    public lostBy?: BusinessUser,
    public lossReason?: string,
    public lossComment?: string,
    public archivedAt?: Date,
    public archivedBy?: BusinessUser,
    public statusAt?: Date,
    public statusBy?: BusinessUser,
    public reminders?: ContractReminder[]
  ) {}

  static fromJson(json: Partial<ContractJson>): Contract {
    return new Contract(
      json.id,
      json.start_date,
      json.internal_comment,
      json.status,
      json.contract_prestations
        ? json.contract_prestations.map((prestationJson: PrestationJson) => Prestation.fromJson(prestationJson))
        : [],
      json.contract_files ? json.contract_files.map((fileJson: GIFileJson) => GIFile.fromJson(fileJson)) : [],
      [],
      json.contract_owners
        ? json.contract_owners.map((ownerJson: ContractOwnerJson) => ContractOwner.fromJson(ownerJson))
        : [],
      json.company ? Company.fromJson(json.company) : null,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.contact ? User.fromJson(json.contact) : null,
      json.sent_at ? DateHelper.fromUtc(json.sent_at) : null,
      json.signed_at ? DateHelper.fromUtc(json.signed_at) : null,
      json.sent_by ? BusinessUser.fromJson(json.sent_by) : null,
      json.signed_by ? User.fromJson(json.signed_by) : null,
      json.prospect ? Prospect.fromJson(json.prospect) : null,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.created_by ? BusinessUser.fromJson(json.created_by) : null,
      json.lost_at ? DateHelper.fromUtc(json.lost_at) : null,
      json.lost_by ? BusinessUser.fromJson(json.lost_by) : null,
      json.loss_reason,
      json.loss_comment,
      json.archived_at ? DateHelper.fromUtc(json.archived_at) : null,
      json.archived_by ? BusinessUser.fromJson(json.archived_by) : null,
      json.status_at ? DateHelper.fromUtc(json.status_at) : null,
      json.status_by ? BusinessUser.fromJson(json.status_by) : null,
      json.contract_reminders
        ? json.contract_reminders.map((reminderJson: ContractReminderJson) => ContractReminder.fromJson(reminderJson))
        : []
    );
  }

  static toJson(
    model: Contract
  ): Omit<
    ContractJson,
    | 'status'
    | 'sent_at'
    | 'signed_at'
    | 'sent_by'
    | 'signed_by'
    | 'lost_at'
    | 'lost_by'
    | 'loss_reason'
    | 'loss_comment'
    | 'created_at'
    | 'created_by'
    | 'archived_at'
    | 'archived_by'
    | 'status_at'
    | 'status_by'
    | 'contract_reminders'
  > {
    return {
      id: HttpHelper.toJsonProperty(model.id),
      start_date: HttpHelper.toJsonProperty(model.startDate),
      internal_comment: HttpHelper.toJsonProperty(model.internalComment),
      contract_prestations: model.prestations?.length
        ? model.prestations.map((prestation: Prestation) => Prestation.toJson(prestation))
        : [],
      contract_owners: model.owners?.length
        ? model.owners
            .filter((owner: ContractOwner) => owner.user)
            .map((owner: ContractOwner) => ContractOwner.toJson(owner))
        : [],
      company: model.company ? HttpHelper.toJsonId(model.company) : null,
      business_unit: model.businessUnit ? HttpHelper.toJsonId(model.businessUnit) : null,
      contact: model.contact ? HttpHelper.toJsonId(model.contact) : null,
      contract_files: model.files?.length ? model.files.map((file: GIFile) => GIFile.toJson(file)) : [],
      offer_model_files: model.offerModelFiles?.length
        ? model.offerModelFiles.map((file: GIFile) => GIFile.toJson(file))
        : [],
      prospect: model.prospect ? HttpHelper.toJsonId(model.prospect) : null
    };
  }
}
