import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[appFormFocusObserve]',
  standalone: true
})
export class FormFocusObserveDirective {
  @Input() debounce = 250;

  @Output() readonly formChanges: EventEmitter<unknown> = new EventEmitter<unknown>();

  private controlSub: Subscription;

  constructor(private ngControl: NgControl) {}

  @HostListener('focus', ['$event.target'])
  onFocus(): void {
    if (this.controlSub && !this.controlSub.closed) {
      return;
    }

    this.controlSub = this.ngControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap((value: unknown) => this.formChanges.emit(value))
      )
      .subscribe();
  }

  @HostListener('blur', ['$event.target'])
  onBlur(): void {
    setTimeout(() => {
      if (this.controlSub) {
        this.controlSub.unsubscribe();
      }
    }, this.debounce);
  }
}
