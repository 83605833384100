import { Pipe, PipeTransform } from '@angular/core';

import { VatType } from '@models/vat-type';

@Pipe({
  name: 'vatExonerationTypeRequired',
  standalone: true
})
export class VatExonerationTypeRequiredPipe implements PipeTransform {
  transform(vatTypeId: number, vatType: VatType): boolean {
    return vatTypeId === vatType?.id;
  }
}
