import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { ApeCode } from '@models/ape-code';
import { FormFocusObserveDirective } from '@shared-directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-ape-code-select',
  templateUrl: './ape-code-select.component.html',
  styleUrls: ['./ape-code-select.component.scss'],
  imports: [
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApeCodeSelectComponent extends FilteredSelectBase<ApeCode> {
  trackByCode(_index: number, apeCode: ApeCode): string {
    return apeCode.code;
  }

  filterFunction(search: string): ApeCode[] {
    return this.selectorData.filter(
      (apeCode: ApeCode) =>
        StringUtils.normalizeToLowerCase(apeCode.label).includes(StringUtils.normalizeToLowerCase(search)) ||
        StringUtils.normalizeToLowerCase(apeCode.code).includes(StringUtils.normalizeToLowerCase(search))
    );
  }
}
