import { FormControl, FormGroup } from '@angular/forms';

import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';

import { EbicsDownloadDates, EbicsDownloadDatesForm } from '../bases/ebics-download-dates-form';

export type ConnectionProviderEbicsDownloadFormType = {
  connectionId: FormControl<number | null>;
  dates: EbicsDownloadDatesForm;
};

export class ConnectionProviderEbicsDownloadForm extends FormGroup<ConnectionProviderEbicsDownloadFormType> {
  get connectionId(): FormControl<number | null> {
    return this.controls.connectionId;
  }

  get dates(): EbicsDownloadDatesForm {
    return this.controls.dates;
  }

  get connectionIdErrorMessage(): ErrorMessage | null {
    return FormHelper.getEmptyErrorMessage(this.connectionId);
  }

  get startDate(): FormControl<string | null> {
    return this.controls.dates.startDate;
  }

  get endDate(): FormControl<string | null> {
    return this.controls.dates.endDate;
  }

  constructor() {
    super({
      connectionId: new FormControl<number | null>(null),
      dates: new EbicsDownloadDatesForm()
    });
  }

  toConnectionProviderEbicsDownload(): EbicsDownloadDates {
    return this.dates.toEbicsDownloadDates();
  }
}
