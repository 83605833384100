import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-sticky-table-preview',
  templateUrl: './sticky-table-preview.component.html',
  styleUrls: ['./sticky-table-preview.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTablePreviewComponent {}
