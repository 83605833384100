import { ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatLegacyAutocompleteModule as MatAutocompleteModule,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import {
  MatLegacyChipsModule as MatChipsModule,
  MatLegacyChipInputEvent as MatChipInputEvent
} from '@angular/material/legacy-chips';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { StringHelper, TrackByHelper } from 'tiime-expert-utils';
import { TiimeIconModule, TiimeInputContainerModule } from 'tiime-material';

import { ThreadTag } from '@models/thread-tag';
import { ThreadTagAutocompleteComponent } from '@shared-autocompletes/thread-tag-autocomplete/thread-tag-autocomplete.component';

@Component({
  selector: 'app-thread-tag-select-multiple',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeIconModule,
    MatAutocompleteModule,
    MatChipsModule,
    ThreadTagAutocompleteComponent
  ],
  templateUrl: './thread-tag-select-multiple.component.html',
  styleUrls: ['../../../core/bases/selector/select-chips.base.scss', './thread-tag-select-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadTagSelectMultipleComponent implements OnInit {
  @Input({ required: true }) control: FormControl<ThreadTag[] | undefined>;
  @Input({ required: true }) tags: ThreadTag[] = [];
  @Input() label = 'Tags';
  @Input() marginTop = true;
  @Input() allowCreate = true;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;

  filteredThreadTags$: Observable<ThreadTag[]>;

  readonly trackByIndex = TrackByHelper.trackByIndex;
  readonly searchTagsControl = new FormControl<string>(null);
  readonly separatorKeysCodes: number[] = [ENTER];

  ngOnInit(): void {
    this.filteredThreadTags$ = this.searchTagsControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string) => this.filteredThreadTags(value || ''))
    );
  }

  addNewTag(event: MatChipInputEvent): void {
    if (!this.allowCreate || this.autocompleteTrigger.activeOption) {
      return;
    }

    const tag = new ThreadTag();
    tag.name = event.value;
    this.addTag(tag);
  }

  addTag(tag: ThreadTag): void {
    this.control.setValue([...(this.control.value ?? []), tag]);
    this.resetSearch();
  }

  removeTag(index: number): void {
    const tags = this.control.value ?? [];
    tags.splice(index, 1);
    this.control.setValue(tags);

    this.resetSearch();
  }

  private filteredThreadTags(search: string): ThreadTag[] {
    if (!search || typeof search !== 'string') {
      return this.tags;
    }

    return this.tags.filter((tag: ThreadTag) =>
      StringHelper.normalizeToLowerCase(tag.name ?? '').includes(StringHelper.normalizeToLowerCase(search))
    );
  }

  private resetSearch(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }

    this.searchTagsControl?.setValue(null);
  }
}
