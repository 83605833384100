<div class="overlay-container" cdkScrollable>
  <mat-progress-spinner
    class="dialog-spinner"
    diameter="25"
    color="primary"
    mode="indeterminate"
    *ngIf="isLoading"
  ></mat-progress-spinner>
  <ng-content select="[overlay-header]"></ng-content>
  <ng-content select="[overlay-content]"></ng-content>
  <ng-content select="[overlay-footer]"></ng-content>
</div>
