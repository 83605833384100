import { ChangeDetectionStrategy, Component, computed, OnInit, signal } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

import { DialogBase } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { AppStoreState } from '@interfaces/app-store-state';
import { CreateCreationRequestDialogData } from '@legal/creation/creation-shared/dialogs/create-creation-request-dialog/create-creation-request-dialog-data';
import { CreateCreationRequestDialogService } from '@legal/creation/creation-shared/dialogs/create-creation-request-dialog/create-creation-request-dialog.service';
import { BusinessUnit } from '@models/business-unit';
import { BusinessUser } from '@models/business-user';
import { Company } from '@models/company';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

import { CreateCompanyDialogData } from '../create-company-dialog/create-company-dialog-data';
import { CreateCompanyDialogService } from '../create-company-dialog/create-company-dialog.service';

@Component({
  selector: 'app-choose-company-creation-type-dialog',
  templateUrl: './choose-company-creation-type-dialog.component.html',
  styleUrls: ['./choose-company-creation-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseCreationTypeDialogComponent extends DialogBase<ChooseCreationTypeDialogComponent> implements OnInit {
  readonly availableBusinessUnits = signal<BusinessUnit[]>([]);
  readonly canCreateCompany = computed(() => this.availableBusinessUnits()?.length > 0);

  constructor(
    dialogRef: MatDialogRef<ChooseCreationTypeDialogComponent>,
    private store: Store<AppStoreState>,
    private createCompanyDialogService: CreateCompanyDialogService,
    private createCreationRequestDialogService: CreateCreationRequestDialogService,
    private businessUserApiService: BusinessUserApiService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.initAvailableBusinessUnits();
  }

  openCreateCompanyDialog(): void {
    const data: CreateCompanyDialogData = {
      businessUnits: this.availableBusinessUnits()
    };

    this.createCompanyDialogService
      .open({ data })
      .beforeClosed()
      .pipe(tap((company: Company) => this.close(company)))
      .subscribe();
  }

  openCreateCreationRequestDialog(): void {
    const data: CreateCreationRequestDialogData = {
      businessUnits: this.availableBusinessUnits()
    };

    this.createCreationRequestDialogService
      .open({ data })
      .beforeClosed()
      .pipe(tap((company: Company) => this.close(company)))
      .subscribe();
  }

  private initAvailableBusinessUnits(): void {
    this.isLoading = true;
    combineLatest([this.getCurrentBusinessUser(), this.getAvailableBusinessUnits()])
      .pipe(
        tap(([businessUser, businessUnits]) => {
          this.availableBusinessUnits.set(businessUnits);

          if (this.canCreateCompany() && !businessUser.partner?.legalAccess) {
            this.openCreateCompanyDialog();
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private getAvailableBusinessUnits(): Observable<BusinessUnit[]> {
    return this.businessUserApiService.getBusinessUserBusinessUnits(true);
  }

  private getCurrentBusinessUser(): Observable<BusinessUser> {
    return this.store.pipe(select(businessUserSelector), take(1));
  }
}
