import { Directive, Input, Signal } from '@angular/core';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';

import { AutocompleteBaseComponent } from '@bases/autocomplete/autocomplete.base';
import { FavoriteServiceBase } from '@bases/favorite-service/favorite-service.base';
import { JsonId } from '@interfaces/json-id';

@Directive()
export abstract class AutocompleteWithFavoritesBase<
  T extends Partial<JsonId> = Partial<JsonId>
> extends AutocompleteBaseComponent<T> {
  @Input() withFavorites = false;

  readonly favorites: Signal<number[]>;
  readonly favoriteCount: Signal<number>;
  readonly hasFavorites: Signal<boolean>;

  constructor(protected favoriteService: FavoriteServiceBase<T>) {
    super();
    this.favorites = this.favoriteService.favorites;
    this.favoriteCount = this.favoriteService.favoritesCount;
    this.hasFavorites = this.favoriteService.hasFavorites;
  }

  addToFavs(value: T): void {
    this.favoriteService.addFavorite(value);
  }

  removeFromFavs(value: T): void {
    this.favoriteService.removeFavorite(value);
  }

  selectFavorites(event: MatOptionSelectionChange, favorites: number[]): void {
    this.emitSelectMultiple(
      event,
      this.data.filter((value: T) => favorites.includes(value.id))
    );
  }
}
