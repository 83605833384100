import { Pipe, PipeTransform } from '@angular/core';

import { RegexUtils } from 'tiime-expert-utils';

@Pipe({
  name: 'isMobilePhoneNumber',
  standalone: true
})
export class IsMobilePhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string | null): boolean {
    if (!phoneNumber) {
      return false;
    }
    return RegexUtils.mobilePhoneRegex.test(phoneNumber.replace(/\s/g, ''));
  }
}
