import { FormControl, FormGroup } from '@angular/forms';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadStatus } from '@enums/thread-status.enum';
import { ThreadFilters } from '@interfaces/thread-filters';
import { Contributor } from '@models/contributor';
import { ThreadTag } from '@models/thread-tag';
import { ThreadEntity } from '@modules/core/types/thread-entity.type';

export type ThreadFiltersFormType = {
  startDate: FormControl<string | undefined>;
  endDate: FormControl<string | undefined>;
  contributors: FormControl<Contributor[] | undefined>;
  tags: FormControl<ThreadTag[] | undefined>;
  type: FormControl<ThreadEntityType | undefined>;
  status: FormControl<ThreadStatus | undefined>;
  entity: FormControl<ThreadEntity | undefined>;
  category: FormControl<ThreadCategory | undefined>;
};

export class ThreadFiltersForm extends FormGroup<ThreadFiltersFormType> {
  constructor() {
    super({
      startDate: new FormControl<string | undefined>(undefined),
      endDate: new FormControl<string | undefined>(undefined),
      contributors: new FormControl<Contributor[] | undefined>(undefined),
      tags: new FormControl<ThreadTag[] | undefined>(undefined),
      type: new FormControl<ThreadEntityType | undefined>(undefined),
      status: new FormControl<ThreadStatus | undefined>(undefined),
      entity: new FormControl<ThreadEntity | undefined>(undefined),
      category: new FormControl<ThreadCategory | undefined>(undefined)
    });
  }

  toThreadFilters(): ThreadFilters {
    return {
      startDate: this.controls.startDate.value,
      endDate: this.controls.endDate.value,
      contributors: this.controls.contributors.value,
      tags: this.controls.tags.value,
      type: this.controls.type.value,
      status: this.controls.status.value,
      entity: this.controls.entity.value,
      category: this.controls.category.value
    };
  }
}
