export interface RemunerationJson {
  user_id: number;
  moral_person_id: number;
  firstname: string;
  lastname: string;
  moral_person_name?: string;
  capacity: string;
  tns_remuneration: number;
  tns_cotisation: number;
  gross_employee_remuneration: number;
  total_remuneration: number;
}

export class Remuneration {
  constructor(
    public userId?: number,
    public moralPersonId?: number,
    public firstname?: string,
    public lastname?: string,
    public moralPersonName?: string,
    public capacity?: string,
    public tnsRemuneration?: number,
    public tnsCotisation?: number,
    public grossEmployeeRemuneration?: number,
    public totalRemuneration?: number
  ) {}

  get id(): number {
    return this.userId || this.moralPersonId;
  }

  get fullName(): string {
    return this.userId ? `${this.firstname} ${this.lastname}` : this.moralPersonName;
  }

  static fromJson(json: RemunerationJson): Remuneration {
    return new Remuneration(
      json.user_id,
      json.moral_person_id,
      json.firstname,
      json.lastname,
      json.moral_person_name,
      json.capacity,
      json.tns_remuneration,
      json.tns_cotisation,
      json.gross_employee_remuneration,
      json.total_remuneration
    );
  }

  static toJson(remuneration: Remuneration): RemunerationJson {
    return {
      user_id: remuneration.userId,
      moral_person_id: remuneration.moralPersonId,
      firstname: remuneration.firstname,
      lastname: remuneration.lastname,
      moral_person_name: remuneration.moralPersonName,
      capacity: remuneration.capacity,
      tns_remuneration: remuneration.tnsRemuneration,
      tns_cotisation: remuneration.tnsCotisation,
      gross_employee_remuneration: remuneration.grossEmployeeRemuneration,
      total_remuneration: remuneration.totalRemuneration
    };
  }
}
