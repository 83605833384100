import { formatPercent } from '@angular/common';

import { Invoice } from '@models/invoice';
import { VatRate } from '@models/vat-rate';
import { VatType } from '@models/vat-type';

import { DateHelper } from './date.helper';

export class VatHelper {
  static firstTotalsPerVatTypeLabel(invoice: Invoice, vatTypes: VatType[]): string {
    const vatType = VatHelper.firstTotalsPerVatType(invoice, vatTypes);
    return vatType?.name;
  }

  static firstTotalsPerVatType(invoice: Invoice, vatTypes: VatType[]): VatType {
    const firstTotalsPerVatTypeCode = invoice.totalsPerVatType ? Object.keys(invoice.totalsPerVatType)[0] : null;
    return vatTypes.find((vatType: VatType) => vatType.code === firstTotalsPerVatTypeCode);
  }

  static isExonerated(invoice: Invoice, vatTypes: VatType[]): boolean {
    const vatType = VatHelper.firstTotalsPerVatType(invoice, vatTypes);
    return invoice.vatAmount === 0 && vatType?.hasName;
  }

  static vatRateFromEmissionDate(
    vatRates: VatRate[],
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    emissionDate?: any
  ): number {
    if (!emissionDate) {
      emissionDate = new Date();
    } else if (DateHelper.isMoment(emissionDate)) {
      emissionDate = DateHelper.toDate(emissionDate);
    } else if (typeof emissionDate === 'string') {
      emissionDate = DateHelper.toDate(emissionDate, ['YYYY-MM-DD']);
    }
    return !!vatRates?.length
      ? vatRates.find((vatRate: VatRate) => VatHelper.vatRateIsDateIn(vatRate, emissionDate)).rate
      : null;
  }

  static vatRateIsDateIn(vatRate: VatRate, date: Date = new Date()): boolean {
    const startDate = new Date(vatRate.startDate);
    const endDate = new Date(vatRate.endDate);
    endDate.setDate(endDate.getDate() + 1);

    if (!vatRate.startDate && date < endDate) {
      return true;
    }

    if (!vatRate.endDate && startDate <= date) {
      return true;
    }

    if (startDate <= date && date < endDate) {
      return true;
    }

    return false;
  }

  static vatTypeLabel(vatType: VatType, vatTypes: VatType[], vatApplicationDate: string): string {
    if (!vatType) {
      return 'Aucune TVA';
    }

    if (vatType.code === 'manual') {
      return 'Saisie manuelle';
    }

    const seletedVatType: VatType = vatTypes.find((vT: VatType) => vT.code === vatType.code);

    return seletedVatType.hasName
      ? seletedVatType.name
      : formatPercent(VatHelper.vatRateFromEmissionDate(seletedVatType.vatRates, vatApplicationDate), 'fr', '1.0-2');
  }

  static franceExonerationVatTypeId(vatTypes: VatType[]): number {
    return (vatTypes ?? []).find((vatType: VatType) => vatType.code === 'france_exoneration')?.id;
  }
}
