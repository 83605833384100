import { TimeTrackingsCategory, TimeTrackingsCategoryJson } from '@models/time-trackings-category';

export interface TimeTrackingsTaskJson {
  id: number;
  wording: string;
  time_tracking_category: TimeTrackingsCategoryJson;
  code: string;
  default_duration: number;
}

export class TimeTrackingsTask {
  constructor(
    public id?: number,
    public wording?: string,
    public timeTrackingCategory?: TimeTrackingsCategory,
    public code?: string,
    public defaultDuration?: string
  ) {}

  static fromJson(json: Partial<TimeTrackingsTaskJson>): TimeTrackingsTask {
    return new TimeTrackingsTask(
      json.id,
      json.wording,
      json.time_tracking_category ? TimeTrackingsCategory.fromJson(json.time_tracking_category) : null,
      json.code,
      json.default_duration ? new Date(json.default_duration * 60000).toISOString().slice(11, 16) : null
    );
  }
}
