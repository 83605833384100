import { HubElligibilityConnectionsCategories } from '@modules/core/types/hub-elligibility-connections-categories.type';
import { HubElligibilityConnectionsMetadata } from '@modules/core/types/hub-elligibility-connections-metadata.type';

export interface HubEligibilityConnectionJson {
  silae: boolean;
  cegid_loop: boolean;
  cegid_quadra: boolean;
  acd: boolean;
  inqom: boolean;
  sage: boolean;
  category_mapping: HubElligibilityConnectionsCategories;
  metadata: HubElligibilityConnectionsMetadata;
}

export class HubEligibilityConnection {
  constructor(
    public silae?: boolean,
    public cegidLoop?: boolean,
    public cegidQuadra?: boolean,
    public acd?: boolean,
    public inqom?: boolean,
    public sage?: boolean,
    public categories?: HubElligibilityConnectionsCategories,
    public metadata?: HubElligibilityConnectionsMetadata
  ) {}

  static fromJson(json: Partial<HubEligibilityConnectionJson>): HubEligibilityConnection {
    return new HubEligibilityConnection(
      json.silae,
      json.cegid_loop,
      json.cegid_quadra,
      json.acd,
      json.inqom,
      json.sage,
      json.category_mapping,
      json.metadata
    );
  }

  static toJson(hubEligibilityConnection: HubEligibilityConnection): Partial<HubEligibilityConnectionJson> {
    return {
      silae: hubEligibilityConnection.silae,
      cegid_loop: hubEligibilityConnection.cegidLoop,
      cegid_quadra: hubEligibilityConnection.cegidQuadra,
      acd: hubEligibilityConnection.acd,
      inqom: hubEligibilityConnection.inqom,
      sage: hubEligibilityConnection.sage
    };
  }
}
