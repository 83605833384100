export interface FiscalResultLabelJson {
  id: number;
  label: string;
  type: string;
  comment_needed: boolean;
  code: string;
}

export class FiscalResultLabel {
  constructor(
    public id: number,
    public label: string,
    public type: string,
    public commentNeeded: boolean,
    public code: string
  ) {}

  static fromJson(json: Partial<FiscalResultLabelJson>): FiscalResultLabel {
    return new FiscalResultLabel(json.id, json.label, json.type, json.comment_needed, json.code);
  }
}
