<ng-content select="[tiimeContainerHeader]"></ng-content>
<div class="tiime-container-content-container" [class.tiime-container-has-footer]="containerHasFooter">
  <div class="tiime-container-content-container__main-content">
    <ng-content
      select="[tiimeContainerContentPlaceholder]"
      *ngIf="containerState === ContainerState.contentPlaceholder"
    ></ng-content>
    <ng-content select="[tiimeContainerNoResult]" *ngIf="containerState === ContainerState.noResult"></ng-content>
    <ng-content select="[tiimeContainerContent]"></ng-content>
  </div>
  <ng-content select="[tiimeContainerSideContent]"></ng-content>
</div>
<ng-content select="[tiimeContainerFooter]"></ng-content>
<ng-content select="[tiimeContainerOnboard]" *ngIf="containerState === ContainerState.onboard"></ng-content>
