import { createSelector } from '@ngrx/store';

import { AccountingPeriodHelper } from '@helpers/accounting-period.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { AccountingConfig } from '@models/accounting-config';
import { AccountingPeriod } from '@models/accounting-period';

import { AccountPeriodsStateType } from './accounting-periods-reducer';

export const accountingPeriodsStateSelector = (state: AppStoreState): AccountPeriodsStateType =>
  state?.accountingPeriods || null;

export const accountingPeriodsSelector = (state: AppStoreState): AccountingPeriod[] | null =>
  state?.accountingPeriods?.accountingPeriods ? state.accountingPeriods.accountingPeriods : null;

export const selectedAccountingPeriodSelector = (state: AppStoreState): number =>
  state?.accountingPeriods?.selectedAccountingPeriod?.id || null;

export const selectedAccountingPeriodInstanceSelector = (state: AppStoreState): AccountingPeriod | null => {
  if (!state?.accountingPeriods?.accountingPeriods?.length) {
    return null;
  }

  return state?.accountingPeriods?.selectedAccountingPeriod || null;
};

export const selectedAccountingPeriodCustomEndDate = createSelector(
  selectedAccountingPeriodInstanceSelector,
  (accountingPeriod: AccountingPeriod | null): string | null => {
    if (!accountingPeriod?.custom) {
      return null;
    }
    return accountingPeriod.endDate;
  }
);

export const firstOpenedAccountingPeriodSelector = createSelector(accountingPeriodsSelector, state =>
  AccountingPeriodHelper.findFirstOpened(state)
);

export const openedAccountingPeriodSelector = createSelector(
  accountingPeriodsSelector,
  state => state?.filter((accountingPeriod: AccountingPeriod) => !accountingPeriod.closed)
);

export const closedAccountingPeriodSelector = createSelector(
  accountingPeriodsSelector,
  state => state?.filter((accountingPeriod: AccountingPeriod) => accountingPeriod.closed)
);

export const accountingPeriodsWithCustomSelector = (state: AppStoreState): AccountingPeriod[] | null => {
  if (!state?.accountingPeriods?.accountingPeriods || !state?.accountingConfig) {
    return null;
  }

  const config: AccountingConfig = state.accountingConfig;
  const periods: AccountingPeriod[] = state.accountingPeriods.accountingPeriods;

  // On n'a pas de période personnalisée, on peut renvoyer directement les exercices
  if (!config.customAccountingPeriodEndDate) {
    return periods;
  }

  const period: AccountingPeriod = AccountingPeriodHelper.findForDate(periods, config.customAccountingPeriodEndDate);
  if (!period) {
    return periods;
  }
  const customPeriod: AccountingPeriod = new AccountingPeriod(
    period.id,
    false,
    period.startDate,
    config.customAccountingPeriodEndDate
  );
  customPeriod.custom = true;

  return AccountingPeriodHelper.sort([...periods, customPeriod], true);
};

export const lastClosedAccountingPeriodSelector = createSelector(accountingPeriodsSelector, state =>
  AccountingPeriodHelper.findLastClosed(state)
);
