import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TiimeButtonModule, TiimeIconModule, TiimeTooltipModule } from 'tiime-material';

import { Notification } from '@models/notification';
import { StatusComponent } from '@shared-components/status/status.component';

@Component({
  selector: 'app-notification-read-action',
  standalone: true,
  imports: [CommonModule, TiimeButtonModule, TiimeTooltipModule, TiimeIconModule, StatusComponent],
  templateUrl: './notification-read-action.component.html',
  styleUrls: ['./notification-read-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(mouseenter)': 'hovered = true',
    '(mouseleave)': 'hovered = false'
  }
})
export class NotificationReadActionComponent {
  @Input() notification: Notification;

  @Output() readonly markNotificationAsRead: EventEmitter<void> = new EventEmitter<void>();

  hovered = false;

  emitMarkNotificationAsRead(): void {
    this.markNotificationAsRead.emit();
  }
}
