import { NgIf } from '@angular/common';
import { Directive, Input, OnInit, inject } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Role } from '@enums/role.enum';
import { AppStoreState } from '@interfaces/app-store-state';
import { businessUserRolesSelector } from '@modules/core/store/business-user/business-user-selector';

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[appHasRole]',
  standalone: true,
  hostDirectives: [NgIf]
})
export class HasRoleDirective implements OnInit {
  @Input('appHasRole') roles: Role[] = [Role.BUSINESS_AGENT, Role.ENGINE_ADMIN, Role.PARTNER_ADMIN];

  private store = inject(Store<AppStoreState>);
  private ngIf = inject(NgIf, { host: true });
  private subscription = new Subscription();

  ngOnInit(): void {
    this.observeHasRole();
  }

  private observeHasRole(): void {
    this.subscription.add(
      this.store
        .pipe(
          select(businessUserRolesSelector),
          map((currentBusinessUserRoles: Role[]) => {
            if (!currentBusinessUserRoles?.length || !this.roles?.length) {
              return false;
            }

            return this.roles.some(role => currentBusinessUserRoles.includes(role));
          }),
          tap(permit => (this.ngIf.ngIf = permit))
        )
        .subscribe()
    );
  }
}
