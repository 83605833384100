import { NgModule, Optional, SkipSelf } from '@angular/core';

import { provideBeamer } from '@third-parties/beamer/beamer.provider';

@NgModule({
  providers: [provideBeamer()]
})
export class BeamerModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: BeamerModule
  ) {
    if (parentModule) {
      throw new Error('Beamer is already loaded. Import it in the CodeModule only');
    }
  }
}
