import { FormControl, FormGroup } from '@angular/forms';

import { UserInvitationFeature } from '@enums/user-invitation-feature.enum';

export type UserInvitationFeatureFormType = {
  invoiceApp: FormControl<boolean>;
  receiptApp: FormControl<boolean>;
};

export class UserInvitationFeatureForm extends FormGroup<UserInvitationFeatureFormType> {
  constructor(required = true) {
    super(
      {
        invoiceApp: new FormControl<boolean>(false),
        receiptApp: new FormControl<boolean>(false)
      },
      (group: UserInvitationFeatureForm) => {
        if (!required) {
          return null;
        }
        const invoiceApp = group.get('invoiceApp');
        const receiptApp = group.get('receiptApp');
        const hasAtLeastOneFeatureSelected = invoiceApp.value || receiptApp.value;
        return hasAtLeastOneFeatureSelected ? null : { required: true };
      }
    );
  }

  toUserInvitationFeatures(): UserInvitationFeature[] {
    const rawValue = this.getRawValue();
    return [
      ...(rawValue.invoiceApp ? [UserInvitationFeature.invoiceApp] : []),
      ...(rawValue.receiptApp ? [UserInvitationFeature.receiptApp] : [])
    ];
  }
}
