export class MathHelper {
  /**
   * Modulo used for bigint calculation
   *
   * @param divident Defined as string to accept BigInt (> 2^64)
   * @param divisor
   * @returns modulo as number
   */
  static modulo(divident: string, divisor: number): number {
    const partLength = 10;
    while (divident.length > partLength) {
      const part = divident.substring(0, partLength);
      divident = (Number(part) % divisor).toString() + divident.substring(partLength);
    }
    return Number(divident) % divisor;
  }
}

/**
 * @deprecated Use `MathHelper` instead.
 */
export class MathUtils extends MathHelper {}
