import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'tiime-table-menu',
  templateUrl: './table-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent {
  @Input() tiimeTableMenuDisabled = false;
  @Input() tiimeTableMenuIcon = 'icon-more';
}
