import { InjectionToken, Provider } from '@angular/core';

export interface Beamer {
  init(): void;

  update(params: {
    user_firstname: string;
    user_lastname: string;
    user_email: string;
    user_id: string | number;
    filter: string;
  }): void;
}

export const BEAMER: InjectionToken<Beamer | null> = new InjectionToken<Beamer | null>('Beamer instance');

/**
 * Initialise [Beamer]{@link https://www.getbeamer.com/} avec les infos de l'utilisateur connecté
 *
 * @returns {Provider[]}
 */
export function provideBeamer(): Provider {
  return [{ provide: BEAMER, useValue: (window as any)?.Beamer }];
}
