import { MaritalStatusCode } from '@enums/marital-status-code.enum';

export interface MaritalStatusJson {
  code: MaritalStatusCode;
  label: string;
}

export class MaritalStatus {
  constructor(public code?: MaritalStatusCode, public label?: string) {}

  static fromJson(json: Partial<MaritalStatusJson>): MaritalStatus {
    return new MaritalStatus(json.code, json.label);
  }

  static toJson(maritalStatus: MaritalStatus): MaritalStatusJson {
    return {
      code: maritalStatus.code,
      label: maritalStatus.label
    };
  }
}
