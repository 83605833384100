export enum ApiRouteKeys {
  companies = 'companies',
  accountingPeriods = 'accounting_periods',
  vatFrameworks = 'vat_frameworks',
  pcgEntries = 'pcg_entries',
  caFrameworks = 'ca_frameworks'
}

export const API_ROUTE_REPLACE_COMPANY_KEY = '{companyId}';
export const API_ROUTE_REPLACE_PARTNER_KEY = '{partnerId}';
export const API_ROUTE_REPLACE_BUSINESS_UNIT_KEY = '{businessUnitId}';
export const API_ROUTE_REPLACE_ACCOUNTING_PERIOD_KEY = '{accountingPeriodId}';
export const API_ROUTE_BASE = 'api/v1/expert';
export const GI_API_ROUTE_BASE = 'api/v1/expert/gi';
export const LEGAL_API_ROUTE_BASE = 'api/v1/legal';
export const COMPANY_API_ROUTE_BASE =
  `${API_ROUTE_BASE}/${ApiRouteKeys.companies}/${API_ROUTE_REPLACE_COMPANY_KEY}` as const;
export const GI_COMPANY_API_ROUTE_BASE =
  `${GI_API_ROUTE_BASE}/${ApiRouteKeys.companies}/${API_ROUTE_REPLACE_COMPANY_KEY}` as const;
export const LEGAL_COMPANY_API_ROUTE_BASE =
  `${LEGAL_API_ROUTE_BASE}/${ApiRouteKeys.companies}/${API_ROUTE_REPLACE_COMPANY_KEY}` as const;
export const ACCOUNTING_PERIOD_API_ROUTE_BASE =
  `${COMPANY_API_ROUTE_BASE}/accounting_periods/${API_ROUTE_REPLACE_ACCOUNTING_PERIOD_KEY}` as const;

export const API_ROUTES = {
  companyAccountingPeriodVatFramework: (accountingPeriodId: number): string =>
    `${COMPANY_API_ROUTE_BASE}/${ApiRouteKeys.accountingPeriods}/${accountingPeriodId}/${ApiRouteKeys.vatFrameworks}`,
  companyAccountingPeriodCaFramework: (accountingPeriodId: number): string =>
    `${COMPANY_API_ROUTE_BASE}/${ApiRouteKeys.accountingPeriods}/${accountingPeriodId}/${ApiRouteKeys.caFrameworks}`
};

export const API_HEADERS = {
  workbookSheetV2: 'application/vnd.tiime.expert.workbook-sheet.summary.v2+json',
  partnerUsersV2: 'application/vnd.tiime.expert.partners.users.v2+json',
  contractStatusV2: 'application/vnd.tiime.expert.gi.contract.status.v2+json',
  walletInvitationV2: 'application/vnd.tiime.expert.invitation.v2+json',
  contributorJobsV2: 'application/vnd.tiime.contributor-jobs.v2+json'
};

export const LEGAL_API_HEADERS = {
  documentLegalV2: 'application/vnd.tiime.apps_document_legal.v2+json'
};
