import { AdminCompany } from '@models/admin-company';
import { BusinessUnit } from '@models/business-unit';

export interface AdminCompanyLinkBaseJson {
  selected_companies: Partial<AdminCompany>[];
  business_unit: Partial<BusinessUnit>;
}

export abstract class AdminCompanyLinkBase {
  constructor(
    public selectedCompanies: AdminCompany[],
    public businessUnit: BusinessUnit
  ) {}

  static toJson(data: AdminCompanyLinkBase): AdminCompanyLinkBaseJson {
    throw new Error('Method not implemented! Use derived class');
  }
}
