export enum ParametersRoute {
  empty = '',
  identification = 'identification',
  accountingPeriods = 'accounting-periods',
  journals = 'journals',
  users = 'users',
  automationRules = 'automation-rules',
  bankAccounts = 'bank-accounts',
  integrations = 'integrations',
  guests = 'guests',
  opportunities = 'opportunities',
  buildings = 'buildings'
}
