import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CardComponent,
  CardContentDirective,
  CardFooterDirective,
  CardHeaderActionsDirective,
  CardHeaderComponent,
  CardHeaderIconDirective,
  CardHeaderTitleDirective
} from './card';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CardHeaderIconDirective,
    CardHeaderTitleDirective,
    CardHeaderActionsDirective,
    CardHeaderComponent,
    CardContentDirective,
    CardFooterDirective,
    CardComponent
  ],
  exports: [
    CardHeaderIconDirective,
    CardHeaderTitleDirective,
    CardHeaderActionsDirective,
    CardHeaderComponent,
    CardContentDirective,
    CardFooterDirective,
    CardComponent
  ]
})
export class TiimeCardModule {}
