import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { Thread } from '@models/thread';

export class ThreadHelper {
  static isOpened(thread: Thread, entityType?: ThreadEntityType): boolean {
    return (
      // le thread n'est pas clos
      !thread.closedAt &&
      // le thread n'est pas archivé
      !thread.archivedAt &&
      // le thread n'est pas lu par l'utilisateur courant
      !thread.readByCurrentUser &&
      // l'entité correspond
      (entityType ? thread?.entity?.type === entityType : true)
    );
  }

  static concernsUser(thread: Thread, userId: number): boolean {
    return (
      // l'utilisateur courant est le créateur du thread
      thread.creator?.id === userId ||
      // l'utilisateur courant est un des interlocuteurs
      thread.interlocutors?.some(interlocutor => interlocutor.id === userId) ||
      // l'utilisateur courant a envoyé un message
      thread.messages?.some(message => message.createdBy.id === userId)
    );
  }
}
