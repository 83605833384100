import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-table-main-action',
  templateUrl: './table-main-action.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMainActionComponent {
  @Input() tiimeTableMainActionDisabled = false;
  @Input() tiimeTableMainActionTooltipContent: string;
  @Input() tiimeTableMainActionIcon: string;
  @Input() tiimeTableMainActionText: string;

  @Output() readonly tiimeTableMainAction: EventEmitter<void> = new EventEmitter<void>();
}
