import { NgModule } from '@angular/core';

import { TiimePipesModule } from 'tiime-expert-utils';

import { SharedModule } from '@modules/shared/shared.module';

import { EditCustomPeriodDialogComponent } from './edit-custom-period-dialog.component';

@NgModule({
  declarations: [EditCustomPeriodDialogComponent],
  exports: [EditCustomPeriodDialogComponent],
  imports: [SharedModule, TiimePipesModule]
})
export class EditCustomPeriodDialogModule {}
