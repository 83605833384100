import { AccountantDetailRequestType } from '@enums/accountant-detail-request-type.enum';
import { AccountantDetailRequest, AccountantDetailRequestJson } from '@models/accountant-detail-request';
import { Imputation, ImputationJson } from '@models/imputation';
import { TransactionBase } from '@models/transaction-base';

export interface LinkedTransactionJson {
  id: number;
  transaction_date: string;
  wording: string;
  journal_code: string;
  amount: number;
  currency: string;
  comment: string;
  imputations: Partial<ImputationJson>[];
  accountant_detail_requests: Partial<AccountantDetailRequestJson>[];
}

export class LinkedTransaction extends TransactionBase {
  constructor(
    public id?: number,
    public transactionDate?: string,
    public wording?: string,
    public journalCode?: string,
    public amount?: number,
    public currency?: string,
    public comment?: string,
    public imputations?: Imputation[],
    accountantDetailRequests?: AccountantDetailRequest[]
  ) {
    super(accountantDetailRequests);
  }

  static fromJson(json: Partial<LinkedTransactionJson>): LinkedTransaction {
    return new LinkedTransaction(
      json.id,
      json.transaction_date,
      json.wording,
      json.journal_code,
      json.amount,
      json.currency,
      json.comment,
      json.imputations ? json.imputations.map(i => Imputation.fromJson(i)) : [],
      json.accountant_detail_requests
        ? json.accountant_detail_requests
            // @ts-ignore On ne prend que les DQST de type receipt
            .filter(({ type }) => type === AccountantDetailRequestType.receipt)
            .map(accountantDetailRequest => AccountantDetailRequest.fromJson(accountantDetailRequest))
        : []
    );
  }
}
