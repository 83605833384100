import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiCommonService } from '@api-services/api-common.service';
import { LEGAL_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';

import { CreationRequest, CreationRequestJson } from '../models/creation-request';

/**
 * CreationRequest Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CreationRequestApiService extends ApiCommonService<CreationRequest> {
  resourceUrl = `${LEGAL_API_ROUTE_BASE}/company_creation_request`;

  @ApiAlertError()
  getCreationRequest(creationRequestId: number): Observable<CreationRequest> {
    return super.getById(creationRequestId);
  }

  @ApiAlertError()
  patchCreationRequest(creationRequest: CreationRequest, params?: HttpParams): Observable<CreationRequest> {
    return super.update(creationRequest.id, creationRequest);
  }

  @ApiAlertError()
  postCreationRequest(creationRequest: CreationRequest): Observable<CreationRequest> {
    return super.create(creationRequest);
  }

  @ApiAlertError()
  postAccoutingParams(
    creationRequestId: number,
    categorySetupPlanId: number,
    labelsPlanId: number
  ): Observable<unknown> {
    return this.http.patch(`${this.resourceUrl}/${creationRequestId}`, {
      category_setup_plan_id: categorySetupPlanId,
      labels_plan_id: labelsPlanId
    });
  }

  fromJson(json: CreationRequestJson): CreationRequest {
    return CreationRequest.fromJson(json);
  }

  toJson(model: CreationRequest): CreationRequestJson {
    return CreationRequest.toJson(model);
  }
}
