import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { tap } from 'rxjs/operators';

import { TIIME_OVERLAY_DATA, TiimeSnackbarService } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { BusinessUser } from '@models/business-user';
import { AuthService } from '@modules/core/auth/auth.service';
import { CareContactDialogService } from '@shared-dialogs/care-contact-dialog/care-contact-dialog.service';
import { KeyboardShortcutsDialogService } from '@shared-dialogs/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.service';
import { HelpScoutBeaconService } from '@third-parties/help-scout-beacon/help-scout-beacon.service';
import { SentryService } from '@third-parties/sentry/sentry.service';

@Component({
  selector: 'app-business-user-account-menu-overlay',
  templateUrl: './business-user-account-menu-overlay.component.html',
  styleUrls: ['./business-user-account-menu-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUserAccountMenuOverlayComponent {
  constructor(
    private authService: AuthService,
    private sentryService: SentryService,
    private snackbar: TiimeSnackbarService,
    private businessUserApiService: BusinessUserApiService,
    private careContactDialogService: CareContactDialogService,
    private helpScoutBeaconService: HelpScoutBeaconService,
    private keyboardShortcutsDialogService: KeyboardShortcutsDialogService,
    @Inject(TIIME_OVERLAY_DATA) public businessUser: BusinessUser
  ) {}

  openCareContactDialog(): void {
    this.careContactDialogService.open();
  }

  changePassword(): void {
    this.businessUserApiService
      .renewBusinessUserPassword()
      .pipe(tap(() => this.snackbar.openSuccess('Un email vient de vous être envoyé pour modifier votre mot de passe')))
      .subscribe();
  }

  openKeyboardShortcutsDialog(): void {
    this.keyboardShortcutsDialogService.open({
      width: '850px'
    });
  }

  logout(): void {
    this.authService.logout().subscribe();
    this.sentryService.cleanUser();
  }

  toggleHelpScoutBeacon(): void {
    this.helpScoutBeaconService.toggle();
  }
}
