import { Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { createDialogServiceBase } from 'tiime-material';

import { User } from '@models/user';

import { ExistingUserDialogComponent } from './existing-user-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ExistingUserDialogService extends createDialogServiceBase<User, User>(ExistingUserDialogComponent) {
  open(config: MatDialogConfig): this {
    this.dialogRef = this.dialog.open(ExistingUserDialogComponent, config);
    return this;
  }
}
