import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';

@Component({
  selector: 'tiime-table-content-loader',
  templateUrl: './table-content-loader.component.html',
  styleUrls: ['./table-content-loader.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableContentLoaderComponent {
  @Input() mode: ProgressBarMode;
  @Input() color: string;
  @Input() value: number;
}
