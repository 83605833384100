import { AccountantDetailRequestType } from '@enums/accountant-detail-request-type.enum';

export interface BaseAccountantDetailRequestJson {
  id: number;
  type: AccountantDetailRequestType;
}

export interface ReceiptAccountantDetailRequestJson extends BaseAccountantDetailRequestJson {
  type: AccountantDetailRequestType.receipt;
  created_at: string;
}

export type AccountantDetailRequestJson = ReceiptAccountantDetailRequestJson;

export abstract class AccountantDetailRequest {
  constructor(
    public id: number,
    public readonly type: AccountantDetailRequestType
  ) {}

  static fromJson(json: Partial<ReceiptAccountantDetailRequestJson>): ReceiptAccountantDetailRequest;
  static fromJson(json: Partial<AccountantDetailRequestJson>): AccountantDetailRequest;
  static fromJson(json: Partial<AccountantDetailRequestJson>): AccountantDetailRequest {
    switch (json.type) {
      case AccountantDetailRequestType.receipt:
        return new ReceiptAccountantDetailRequest(json.id, json.created_at);
    }
  }
}

export class ReceiptAccountantDetailRequest extends AccountantDetailRequest {
  constructor(
    public readonly id: number,
    public createdAt: string
  ) {
    super(id, AccountantDetailRequestType.receipt);
  }
}
