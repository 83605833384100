import { BehaviorSubject } from 'rxjs';

import { TiimeOverlayRef } from './overlay.ref';

export abstract class OverlayBase<Result = any> {
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoading(): boolean {
    return this.isLoading$.value;
  }

  set isLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  constructor(protected overlayRef: TiimeOverlayRef) {}

  close(value: Result): void {
    this.overlayRef.close(value);
  }

  cancel(): void {
    this.overlayRef.close();
  }
}
