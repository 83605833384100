import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[tiime-button]',
  host: {
    '[class.primary]': 'color === "primary"',
    '[class.accent]': 'color === "accent"',
    '[class.warn]': 'color === "warn"',
    '[class.strocked]': '_hasHostAttributes("strocked")',
    '[class.raised]': '_hasHostAttributes("raised")',
    '[class.large]': '_hasHostAttributes("large")',
    '[class.small]': '_hasHostAttributes("small")',
    '[class.icon]': '_hasHostAttributes("icon")',
    class: 'tiime-button'
  },
  templateUrl: './button.component.html',
  styleUrls: ['button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent extends HasAttributesBase {
  @Input() color: 'primary' | 'accent' | 'warn';

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
