import { CdkStepperNext, CdkStepperPrevious } from '@angular/cdk/stepper';
import { Directive } from '@angular/core';

/** Button that moves to the next step in a stepper workflow. */
@Directive({
  selector: 'button[tiimeStepperNext]',
  host: {
    class: 'tiime-stepper-next'
  }
})
export class TiimeStepperNextDirective extends CdkStepperNext {}

/** Button that moves to the previous step in a stepper workflow. */
@Directive({
  selector: 'button[tiimeStepperPrevious]',
  host: {
    class: 'tiime-stepper-previous'
  }
})
export class TiimeStepperPreviousDirective extends CdkStepperPrevious {}
