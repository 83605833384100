import { HttpHelper } from '@helpers/http.helper';

import { AccountingPeriod, AccountingPeriodJson } from './accounting-period';
import { PcgEntry } from './pcg-entry';
import { VatType, VatTypeJson } from './vat-type';

export interface InvoiceMaskJson {
  id: number;
  amount: number;
  accounting_period: Partial<AccountingPeriodJson>;
  vat_type: Partial<VatTypeJson>;
  pcg_entry: Partial<PcgEntry>;
  wording: string;
}

export class InvoiceMask {
  constructor(
    public id?: number,
    public amount?: number,
    public accountingPeriod?: AccountingPeriod,
    public vatType?: VatType,
    public pcgEntry?: PcgEntry,
    public wording?: string
  ) {}

  static fromJson(json: Partial<InvoiceMaskJson>): InvoiceMask {
    return new InvoiceMask(
      json.id,
      json.amount,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.vat_type ? VatType.fromJson(json.vat_type) : null,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.wording
    );
  }

  static toJson(invoiceMask: InvoiceMask): InvoiceMaskJson {
    return {
      id: invoiceMask.id,
      amount: invoiceMask.amount,
      accounting_period: AccountingPeriod.toJson(invoiceMask.accountingPeriod),
      vat_type: HttpHelper.toJsonId(invoiceMask.vatType),
      pcg_entry: HttpHelper.toJsonId(invoiceMask.pcgEntry),
      wording: invoiceMask.wording || undefined
    };
  }
}
