<form [formGroup]="userForm">
  <tiime-form [tiimeFormExpanded]="expandedForms | includes: 'user'">
    <div tiimeFormTitle>Informations contact</div>
    <div class="contact-form" [formGroup]="userForm">
      <tiime-input-container
        label="Nom"
        required="true"
        contentId="lastName"
        [errorMessage]="userForm.lastNameErrorMessage"
      >
        <input
          id="lastName"
          type="text"
          [formControl]="userForm.lastName"
          placeholder="Nom"
          autocomplete="off"
          [matAutocomplete]="userAutocomplete.autocomplete"
          [matAutocompleteDisabled]="mode === 'update'"
          #userLastNameInput
          data-cy="user-form-lastname-input"
        />
        <app-user-autocomplete
          #userAutocomplete="UserAutocomplete"
          [currentInputValue]="userForm.lastName.value"
          (selectRecord)="selectUserSuggestion($event)"
          [data]="users$ | async"
        ></app-user-autocomplete>
        <mat-icon
          class="remove-selected-user-icon"
          tiimeSuffix
          fontIcon="icon-t-grey-n3-20px-croix"
          (click)="deselectUserSuggestion()"
          [hidden]="!userForm.id.value"
          *ngIf="mode === 'create'"
        ></mat-icon>
      </tiime-input-container>
      <tiime-input-container
        label="Prénom"
        required="true"
        contentId="firstName"
        [errorMessage]="userForm.firstNameErrorMessage"
      >
        <input
          data-cy="user-form-firstname-input"
          id="firstName"
          type="text"
          [formControl]="userForm.firstName"
          placeholder="Prénom"
          autocomplete="off"
        />
      </tiime-input-container>
      <app-user-civility-select
        [control]="userForm.civility"
        [selectData]="civilities$ | async"
      ></app-user-civility-select>
      <app-user-capacity-select [control]="userForm.capacity"></app-user-capacity-select>
      <tiime-input-container
        label="Email"
        [required]="mode === 'create'"
        contentId="email"
        [errorMessage]="userForm.emailErrorMessage"
      >
        <input
          id="email"
          type="email"
          [formControl]="userForm.email"
          placeholder="email"
          data-cy="user-form-email-input"
          autocomplete="off"
        />
      </tiime-input-container>
      <tiime-input-container
        label="Téléphone"
        [required]="mode === 'create'"
        contentId="phone"
        [errorMessage]="userForm.phoneErrorMessage"
      >
        <input
          id="phone"
          type="text"
          [formControl]="userForm.phone"
          placeholder="Téléphone"
          data-cy="user-form-phone-input"
          autocomplete="off"
        />
      </tiime-input-container>
    </div>
  </tiime-form>
  <div tiimeFormVerticalSeparator *ngIf="withSeparators"></div>
  <app-address-form
    [addressForm]="userForm.address"
    [tiimeFormExpanded]="expandedForms | includes: 'address'"
  ></app-address-form>
  <div tiimeFormVerticalSeparator *ngIf="withSeparators"></div>
  <app-ownership-form
    [ownershipForm]="userForm.ownership"
    [tiimeFormExpanded]="expandedForms | includes: 'ownership'"
  ></app-ownership-form>
  <ng-container *ngIf="userForm.capacity.value | director">
    <div tiimeFormVerticalSeparator *ngIf="withSeparators"></div>
    <app-director-form
      [directorForm]="userForm.director"
      [tiimeFormExpanded]="expandedForms | includes: 'director'"
    ></app-director-form>
  </ng-container>
  <div tiimeFormVerticalSeparator *ngIf="withSeparators"></div>
  <app-legal-information-form
    [legalInformationForm]="userForm.legalInformation"
    [tiimeFormExpanded]="expandedForms | includes: 'legal'"
  ></app-legal-information-form>
</form>
