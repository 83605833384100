import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { BalanceSharedFilters } from '@interfaces/balance-shared-filters';
import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';
import { BalanceJson } from '@models/balance';
import { BalancesAndResult } from '@models/balances-and-result';
import { CloseAccountingPeriodConfig } from '@models/close-accounting-period-config';
import { FileSaverData } from '@services/file.service';

/**
 * Accounting period Service.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountingPeriodApiService extends ApiCommonService<AccountingPeriod> {
  resourceUrl = `${COMPANY_API_ROUTE_BASE}/accounting_periods`;

  /**
   * Cherche l'exercice le plus ancien ouvert dans une liste d'exercice
   */
  static findLastOpenedAccountingPeriod(accountingPeriods: AccountingPeriod[]): AccountingPeriod | null {
    return (
      AccountingPeriodApiService.sortAccountingPeriod(accountingPeriods).filter(period => !period.closed)?.[0] || null
    );
  }

  static sortAccountingPeriod(accountingPeriods: AccountingPeriod[], reverse: boolean = false): AccountingPeriod[] {
    const isBefore = reverse ? 1 : -1;
    const isAfter = reverse ? -1 : 1;

    return accountingPeriods.sort((a, b) => (DateHelper.isBefore(a.endDate, b.endDate) ? isBefore : isAfter));
  }

  constructor(http: HttpClient) {
    super(http);
  }

  fromJson(json: AccountingPeriodJson): AccountingPeriod {
    return AccountingPeriod.fromJson(json);
  }

  toJson(model: AccountingPeriod): AccountingPeriodJson {
    return AccountingPeriod.toJson(model);
  }

  @ApiAlertError()
  getAccountingPeriods(companyId?: number): Observable<AccountingPeriod[]> {
    let url = this.resourceUrl;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return super.getAll(undefined, url);
  }

  @ApiAlertError()
  createAccountingPeriod(accountingPeriod: AccountingPeriod): Observable<AccountingPeriod> {
    return super.create(accountingPeriod);
  }

  @ApiAlertError()
  updateAccountingPeriods(accountingPeriods: AccountingPeriod[]): Observable<AccountingPeriod[]> {
    return super.updateMultiple(accountingPeriods, undefined, 'PUT');
  }

  @ApiAlertError()
  openAccountingPeriod(accountingPeriodId: number): Observable<AccountingPeriod> {
    const url = `${this.resourceUrl}/${accountingPeriodId}/open`;
    return super.create(null, url);
  }

  @ApiAlertError()
  closeAccountingPeriod(
    accountingPeriodId: number,
    config: CloseAccountingPeriodConfig,
    companyId?: number
  ): Observable<AccountingPeriod> {
    const headers = new HttpHeaders({
      Accept: 'application/vnd.tiime.accounting_period_close.v2+json'
    });
    let url = `${this.resourceUrl}/${accountingPeriodId}/close`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    return this.http
      .post(url, CloseAccountingPeriodConfig.toJson(config), { headers })
      .pipe(map((json: AccountingPeriodJson) => this.fromJson(json)));
  }

  @ApiAlertError()
  getBalancesAndResults(
    range: PaginationRange,
    accountingPeriodId: number,
    sort: Sort,
    filters?: BalanceSharedFilters
  ): Observable<BalancesAndResult> {
    const url = `${this.resourceUrl}/${accountingPeriodId}/balances`;
    let params: HttpParams = HttpHelper.setSortParam(sort);
    if (filters) {
      params = HttpHelper.setParams(
        {
          pcg_entry_balanced: filters?.pcgEntryBalanced === 'all' ? undefined : filters.pcgEntryBalanced,
          pcg_entry_has_movement: filters?.pcgEntryHasMovement === 'all' ? undefined : filters.pcgEntryHasMovement,
          pcg_entry_validated: filters?.pcgEntryValidated === 'all' ? undefined : filters.pcgEntryValidated,
          expand: filters?.expand,
          date: filters?.date,
          pcg_entries: filters?.pcgEntryIds?.length ? filters.pcgEntryIds.join(',') : undefined,
          account_numbers: filters?.pcgEntryAccountNumbers?.length
            ? filters.pcgEntryAccountNumbers.join('|')
            : undefined
        },
        params
      );
    }

    const headers = HttpHelper.setRangeHeader(
      new HttpHeaders({
        Accept: 'application/vnd.tiime.balances.v2+json'
      }),
      range
    );

    return this.http
      .get<BalanceJson[]>(url, { params, headers, observe: 'response' })
      .pipe(map(response => BalancesAndResult.fromHttpResponse(response, range)));
  }

  @ApiAlertError()
  downloadEditions(accountingPeriod: AccountingPeriod): Observable<FileSaverData> {
    const url = `${this.resourceUrl}/${accountingPeriod.id}/editions`;
    const body = ['bilan', 'advanced_bilan', 'cdr', 'advanced_cdr'];
    let params: HttpParams;
    if (accountingPeriod.custom) {
      params = HttpHelper.setParams({
        date: `<=${accountingPeriod.endDate}`
      });
    }

    const blobObservable = this.http.post(url, body, {
      responseType: 'blob',
      observe: 'response',
      params
    });

    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  getResult(accountingPeriodId: number, endDate?: string, companyId?: number): Observable<number> {
    let url = `${this.resourceUrl}/${accountingPeriodId}/results`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    let params = HttpHelper.setParams({
      items: 'accounting_result'
    });
    if (endDate) {
      params = HttpHelper.setParam('date', `<=${endDate}`, params);
    }
    return this.http
      .get<{ accounting_result: number }>(url, {
        params
      })
      .pipe(map(({ accounting_result }) => accounting_result));
  }

  @ApiAlertError()
  validateVisas(accountingPeriodId: number): Observable<any> {
    const url = `${this.resourceUrl}/${accountingPeriodId}/visas/validate`;
    return this.http.post(url, null);
  }

  @ApiAlertError()
  unvalidateVisas(accountingPeriodId: number): Observable<any> {
    const url = `${this.resourceUrl}/${accountingPeriodId}/visas/unvalidate`;
    return this.http.post(url, null);
  }

  @ApiAlertError()
  checkWarningJournalSituation(accountingPeriodId: number): Observable<boolean> {
    const url = `${this.resourceUrl}/${accountingPeriodId}/check_warning_journal_situation`;
    return this.http.get<boolean>(url);
  }
}
