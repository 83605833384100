import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tiime-progress-spinner-loader',
  templateUrl: './progress-spinner-loader.component.html',
  styleUrls: ['./progress-spinner-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerLoaderComponent {
  @Input() color: string;
  @Input() diameter: number;
  @Input() mode: string;
  @Input() value: number;

  get containerSize(): { 'width.px': number; 'height.px': number } {
    return { 'width.px': this.diameter, 'height.px': this.diameter };
  }
}
