import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { NgUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { VatSystem } from '@models/vat-system';

@Component({
  standalone: true,
  selector: 'app-vat-system-select',
  templateUrl: './vat-system-select.component.html',
  styleUrls: ['./vat-system-select.component.scss'],
  imports: [
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatSystemSelectComponent {
  @Input() vatSystemsIdControl: UntypedFormControl;
  @Input() vatSystems: VatSystem[];
  @Input() required: boolean;
  @Input() errorMessage: string;

  @Output()
  readonly selectionChange: EventEmitter<any> = new EventEmitter<any>();

  readonly trackByIndex = NgUtils.trackByIndex;
}
