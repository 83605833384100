import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { DOWN_ARROW, ENTER, LEFT_ARROW, RIGHT_ARROW, SPACE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  Attribute,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { HasTabIndex } from '@angular/material/core';
import {
  _getLegacyOptionScrollPosition as _getOptionScrollPosition,
  MAT_LEGACY_OPTION_PARENT_COMPONENT as MAT_OPTION_PARENT_COMPONENT,
  MatLegacyOption as MatOption,
  MatLegacyOptionParentComponent as MatOptionParentComponent,
  MatLegacyOptionSelectionChange as MatOptionSelectionChange
} from '@angular/material/legacy-core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { defer, merge, Observable, Subscription } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { SelectCustomValueDirective } from './select-custom-value.directive';
import { SelectInputDirective } from './select-input.directive';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tiime-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-select',
    '[class.tiime-select-disabled]': 'disabled',
    '[class.selected]': '!!displayedValue',
    '[attr.tabindex]': 'tabIndex',
    '(keydown)': 'onKeyDown($event)',
    '(blur)': 'onBlur()'
  },
  providers: [{ provide: MAT_OPTION_PARENT_COMPONENT, useExisting: forwardRef(() => SelectComponent) }]
})
export class SelectComponent<T = any>
  implements OnInit, AfterContentInit, OnDestroy, ControlValueAccessor, HasTabIndex, MatOptionParentComponent
{
  @Input() placeholder: string;
  @Input() viewportMargin = 2;
  @Input() offsetX: number;
  @Input() offsetY = 5;
  @Input() customValue: string;
  @Input() firstOptionFocused = true;
  @Input() isLoading = false;
  @Input() panelClass: string | string[] | Set<string> | { [key: string]: any };
  @Input() multiple = false;
  @Input() customFindOriginValueFromOptions: (value: T, option: T | null) => boolean;
  @Input({ transform: booleanAttribute }) readonly = false;

  @Input()
  get value(): T | T[] {
    return this._value;
  }

  set value(newValue: T | T[]) {
    if (newValue !== this._value) {
      this.setSelectionByValue(newValue);

      this._value = newValue;
    }
  }

  @Output()
  readonly selectionChange: EventEmitter<T | T[]> = new EventEmitter<T | T[]>();

  @Output()
  readonly closeOptionsPanel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly openOptionsPanel: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(SelectInputDirective)
  input: SelectInputDirective;

  @ContentChild(SelectCustomValueDirective)
  customValueTemplate: SelectCustomValueDirective;

  @ContentChildren(MatOption, { descendants: true })
  options: QueryList<MatOption>;

  @ViewChild('panel') panel: ElementRef;
  @ViewChild('trigger') trigger: ElementRef;

  defaultTabIndex = 0;
  disabled: boolean;
  panelMinWidth: string;
  panelOpen = false;
  tabIndex: number;

  get displayedValue(): string {
    return this.customValue || this.selectionModel.selected.map(option => option.viewValue).join(', ');
  }

  get controlValue(): T | T[] {
    return this.ngControl ? this.ngControl.value : this._value;
  }

  get hasControlValue(): boolean {
    const controlValue = this.controlValue;

    if (!Array.isArray(controlValue)) {
      if (typeof controlValue === 'number') {
        return true;
      }

      return !!controlValue;
    }

    return controlValue?.length > 0;
  }

  private _value: T | T[];
  private selectionModel: SelectionModel<MatOption>;
  private keyManager: ActiveDescendantKeyManager<MatOption>;
  private keyManagerChangesSub: Subscription;
  private optionsChangesSub: Subscription;

  private readonly optionsChanges: Observable<QueryList<MatOption>> = defer(() => {
    const options = this.options;

    if (options) {
      return options.changes.pipe(startWith(options));
    }

    return this.ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.optionsChanges)
    );
  }) as Observable<QueryList<MatOption>>;

  constructor(
    public elementRef: ElementRef,
    @Self() @Optional() public ngControl: NgControl,
    @Attribute('tabindex') tabIndex: string,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.tabIndex = Number(tabIndex) || this.defaultTabIndex;
  }

  ngOnInit(): void {
    this.selectionModel = new SelectionModel<MatOption>(
      this.multiple,
      [],
      true,
      (item1: MatOption, item2: MatOption) => item1.id === item2.id
    );
  }

  ngAfterContentInit(): void {
    this.initKeyManager();
    this.observeKeyManagerChanges();
    this.observeOptionsChanges();
  }

  ngOnDestroy(): void {
    this.keyManager?.destroy();
  }

  closePanel(focus = true): void {
    this.panelOpen = false;
    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }
    if (focus) {
      this.focus();
    }
    this.markForCheck();
    this.closeOptionsPanel.emit();
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  focusFirstOption(): void {
    if (!this.firstOptionFocused) {
      return;
    }

    setTimeout(() => this.keyManager.setFirstItemActive());
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  onBlur(): void {
    if (!this.disabled && !this.panelOpen && !this.readonly) {
      this.onTouched();
      this.markForCheck();
    }
  }

  onChange = (value: T | T[]): void => {};
  onTouched = (): void => {};

  onKeyDown(event: KeyboardEvent): void {
    if (this.panelOpen) {
      this.openedKeyDown(event);
    } else {
      this.closedKeyDown(event);
    }
  }

  openPanel(): void {
    if (this.disabled || this.readonly) {
      return;
    }

    this.panelOpen = true;
    this.setPanelMinWidth();
    if (this.input) {
      this.selectInput();
    }
    this.focusFirstOption();
    this.markForCheck();
    this.openOptionsPanel.emit();
  }

  registerOnChange(fn: (value: T | T[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
  }

  writeValue(value: T): void {
    this.value = value;
  }

  protected scrollOptionIntoView(optionIndex: number): void {
    if (!this.panel) {
      return;
    }

    const optionHeight = 30;
    let optionOffset = optionIndex * optionHeight;
    const panelMaxHeight = 250;

    if (this.input) {
      optionOffset += optionHeight;
      if (optionOffset - this.panel.nativeElement.scrollTop < optionHeight) {
        optionOffset -= optionHeight;
      }
    }

    this.panel.nativeElement.scrollTop = _getOptionScrollPosition(
      optionOffset,
      optionHeight,
      this.panel.nativeElement.scrollTop,
      panelMaxHeight
    );
  }

  private closedKeyDown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    const openPanelAcceptedKeys =
      keyCode === DOWN_ARROW || keyCode === UP_ARROW || keyCode === ENTER || keyCode === SPACE;

    const navigateNextPreviousAcceptedKeys = keyCode === LEFT_ARROW || keyCode === RIGHT_ARROW;

    if (openPanelAcceptedKeys) {
      this.openPanel();
    } else if (navigateNextPreviousAcceptedKeys && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
      if (keyCode === LEFT_ARROW) {
        this.selectPreviousOption();
      } else {
        this.selectNextOption();
      }
    }
  }

  private currentOptionIndex(matOptions: MatOption[]): number {
    return matOptions.findIndex((option: MatOption) => option.value === this.controlValue);
  }

  private selectPreviousOption(): void {
    const matOptions = this.options.toArray();
    const currentOptionIndex = this.currentOptionIndex(matOptions);
    if (currentOptionIndex === 0) {
      return;
    }

    this.onSelectOption(matOptions[currentOptionIndex - 1], false);
  }

  private selectNextOption(): void {
    const matOptions = this.options.toArray();
    const currentOptionIndex = this.currentOptionIndex(matOptions);
    if (currentOptionIndex === matOptions.length - 1) {
      return;
    }

    this.onSelectOption(matOptions[currentOptionIndex + 1], false);
  }

  private openedKeyDown(event: KeyboardEvent): void {
    if (!this.keyManager.activeItem) {
      return;
    }

    switch (event.keyCode) {
      case TAB: {
        event.preventDefault();
        this.closePanel(false);
        break;
      }
      case DOWN_ARROW:
      case UP_ARROW: {
        this.keyManager.onKeydown(event);
        break;
      }
      case ENTER: {
        event.preventDefault();
        this.keyManager.activeItem._selectViaInteraction();
        break;
      }
    }
  }

  private initKeyManager(): void {
    this.keyManager = new ActiveDescendantKeyManager<MatOption>(this.options);
  }

  private observeKeyManagerChanges(): void {
    this.keyManagerChangesSub = this.keyManager.change
      .pipe(
        filter(() => this.panelOpen),
        tap((activeOptionIndex: number) => this.scrollOptionIntoView(activeOptionIndex))
      )
      .subscribe();
  }

  private observeOptionsChanges(): void {
    this.optionsChangesSub = this.optionsChanges
      .pipe(
        tap(() => this.setSelectionByValue(this.controlValue)),
        switchMap((options: QueryList<MatOption>) => merge(...options.map(option => option.onSelectionChange))),
        filter((event: MatOptionSelectionChange) => event.isUserInput && this.panelOpen),
        tap((event: MatOptionSelectionChange) => this.onSelectOption(event.source, true))
      )
      .subscribe();
  }

  private selectInput(): void {
    setTimeout(() => this.input.elementRef.nativeElement.select());
  }

  private onSelectOption(option: MatOption, isUserInput: boolean): void {
    const wasSelected = this.selectionModel.isSelected(option);

    if (wasSelected !== option.selected) {
      option.selected ? this.selectionModel.select(option) : this.selectionModel.deselect(option);
    }

    if (isUserInput) {
      this.keyManager.setActiveItem(option);
    }

    if (this.multiple) {
      if (isUserInput) {
        // Permet d'éviter les conflits avec les keyboard events
        this.focus();
      }
      this.propagateChanges(this.selectionModel.selected.map(model => model.value));
      this.markForCheck();
    } else {
      this.options.filter(opt => opt.value !== option.value).forEach(opt => opt.deselect());
      this.propagateChanges(option.value);
      this.closePanel();
    }
  }

  private propagateChanges(value: T | T[]): void {
    if (value === undefined) {
      return;
    }

    this._value = value;
    if (typeof this.onChange === 'function') {
      this.onChange(value);
    }
    this.selectionChange.emit(value);
  }

  private setSelectionByValue(value: T | T[]): void {
    if (!this.options) {
      return;
    }

    this.options.forEach(option => option.deselect());

    if (this.multiple && value) {
      if (!Array.isArray(value)) {
        throw Error('Value must be an array in multiple-selection mode.');
      }

      const previousSelectedOptions = this.selectionModel.selected;
      this.selectionModel.clear();

      value.forEach(val => this.selectOptionByValue([...this.options.toArray(), ...previousSelectedOptions], val));
    } else {
      this.selectOptionByValue(this.options.toArray(), value as T);
    }

    this.markForCheck();
  }

  private selectOptionByValue(options: MatOption[], value: T): void {
    const correspondingOption = options.find((option: MatOption) => {
      if (this.selectionModel.isSelected(option)) {
        return false;
      }

      if (typeof this.customFindOriginValueFromOptions === 'function') {
        return this.customFindOriginValueFromOptions(value, option.value);
      } else {
        return option.value === value;
      }
    });

    if (correspondingOption) {
      correspondingOption.select();
      this.selectionModel.select(correspondingOption);
    }
  }

  private setPanelMinWidth(): void {
    if (this.trigger) {
      this.panelMinWidth = `${this.trigger.nativeElement.getBoundingClientRect().width}px`;
    }
  }
}
