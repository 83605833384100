import { LoanScheduleModeEnum } from '@enums/loan-schedule-mode.enum';
import { PaymentTypeEnum } from '@enums/payment-type.enum';
import { PeriodicityEnum } from '@enums/periodicity.enum';
import { HttpHelper } from '@helpers/http.helper';
import { LoanAccountingPeriodData, LoanAccountingPeriodDataJson } from '@models/loan-accounting-period-data';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { Piece, PieceJson } from '@models/piece';

export interface LoanJson {
  id: number;
  description: string;
  date: string;
  amount: number;
  pcg_entry: Partial<PcgEntryJson>;
  loan_accounting_periods: Partial<LoanAccountingPeriodDataJson>[];
  piece?: Partial<PieceJson>;
  comment: string;
  first_schedule_date: string;
  last_schedule_date: string;
  period_count: number;
  periodicity: PeriodicityEnum;
  financial_organization: string;
  application_fee: number;
  interest_rate: number;
  schedule_mode: LoanScheduleModeEnum;
  insurance_amount: number;
  interest_term: PaymentTypeEnum;
}

export class Loan {
  constructor(
    public id?: number,
    public description?: string,
    public date?: string,
    public amount?: number,
    public pcgEntry?: PcgEntry,
    public loanAccountingPeriods?: LoanAccountingPeriodData[],
    public piece: Piece = new Piece(),
    public comment?: string,
    public firstScheduleDate?: string,
    public lastScheduleDate?: string,
    public periodCount?: number,
    public periodicity?: PeriodicityEnum,
    public financialOrganization?: string,
    public applicationFee?: number,
    public interestRate?: number,
    public scheduleMode?: LoanScheduleModeEnum,
    public insuranceAmount?: number,
    public interestTerm?: PaymentTypeEnum
  ) {}

  static fromJson(json: Partial<LoanJson>): Loan {
    return new Loan(
      json.id,
      json.description,
      json.date,
      json.amount,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.loan_accounting_periods
        ? json.loan_accounting_periods.map(data => LoanAccountingPeriodData.fromJson(data))
        : [],
      json.piece ? Piece.fromJson(json.piece) : new Piece(),
      json.comment,
      json.first_schedule_date,
      json.last_schedule_date,
      json.period_count,
      json.periodicity,
      json.financial_organization,
      json.application_fee,
      json.interest_rate,
      json.schedule_mode,
      json.insurance_amount,
      json.interest_term
    );
  }

  static toJson(loan: Loan): Omit<LoanJson, 'piece' | 'loan_accounting_periods'> {
    return {
      id: loan.id,
      description: loan.description,
      date: loan.date,
      amount: loan.amount,
      pcg_entry: loan.pcgEntry ? HttpHelper.toJsonId(loan.pcgEntry) : null,
      comment: loan.comment,
      first_schedule_date: loan.firstScheduleDate,
      last_schedule_date: loan.lastScheduleDate,
      period_count: loan.periodCount,
      periodicity: loan.periodicity,
      financial_organization: loan.financialOrganization,
      application_fee: loan.applicationFee,
      interest_rate: loan.interestRate,
      schedule_mode: loan.scheduleMode,
      insurance_amount: loan.insuranceAmount,
      interest_term: loan.interestTerm
    };
  }
}
