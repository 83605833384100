<tiime-input-container
  tiimeFormInputContainer
  contentId="vatSystem"
  label="Régime de TVA"
  [required]="required"
  [errorMessage]="errorMessage"
>
  <tiime-select
    id="vatSystem"
    [class.selected]="vatSystemsIdControl.value"
    [formControl]="vatSystemsIdControl"
    data-cy="company-form-vat-system-input"
    (selectionChange)="selectionChange.emit($event)"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let vatSystem of vatSystems; trackBy: trackByIndex" [value]="vatSystem.id">
        {{ vatSystem.code }} - {{ vatSystem.label }}
      </mat-option>
    </div>
    <ng-content tiimeSelectSuffix select="[tiimeSelectSuffix]"></ng-content>
  </tiime-select>
</tiime-input-container>
