import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SplitButtonComponent } from './split-button.component';
import { TiimeButtonModule } from '../button/button.module';
import { TiimeIconModule } from '../icon/icon.module';
import { TiimeMenuModule } from '../menu/menu.module';

@NgModule({
  imports: [CommonModule, TiimeButtonModule, TiimeIconModule, TiimeMenuModule],
  declarations: [SplitButtonComponent],
  exports: [SplitButtonComponent]
})
export class TiimeSplitButtonModule {}
