import { EmailAttachment } from '@models/email-attachment';

export abstract class EmailBase {
  constructor(
    public from?: string,
    public to?: string[],
    public cc?: string[],
    public cci?: string[],
    public subject?: string,
    public body?: string,
    public attachments?: EmailAttachment[]
  ) {}
}
