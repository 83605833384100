import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadEntityTypeLabelPipe } from '@shared-pipes/thread-entity-type-label/thread-entity-type-label.pipe';

@Component({
  standalone: true,
  selector: 'app-thread-entity-type-select',
  templateUrl: './thread-entity-type-select.component.html',
  styleUrls: ['./thread-entity-type-select.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    MatOptionModule,
    ThreadEntityTypeLabelPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadEntityTypeSelectComponent extends SelectBase<ThreadEntityType> {
  @Input() label = 'Objet';

  readonly ThreadEntityType: typeof ThreadEntityType = ThreadEntityType;
}
