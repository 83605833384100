import { AbstractControl } from '@angular/forms';

import { defer, merge, Observable, of } from 'rxjs';

import { ErrorCode, ErrorType } from '@constants/error.constant';
import { DateHelper } from '@helpers/date.helper';
import { ErrorMessage } from '@interfaces/error-message';

export interface FormHelperDatePickerErrorMessageInterface {
  checkMin?: boolean;
  minError?: string;
  minErrorMessage?: string;
  checkMax?: boolean;
  maxError?: string;
  maxErrorMessage?: string;
}

export interface FormHelperSimpleErrorMessageInterface {
  /**
   * Code d'erreur à chercher sur le AbstractControl
   */
  simpleErrorCode: string;
  simpleErrorMessage: string;
  simpleError?: string;
}

/**
 * Type représentant la liste des méthodes qu'on peut utiliser pour la composition d'erreur
 */
export type ComposableMethods = keyof Omit<
  typeof FormHelper,
  | 'prototype'
  | 'composeErrorMessages'
  | 'addErrorToControl'
  | 'removeErrorFromControl'
  | 'safeAssign'
  | 'getValueChange'
>;

/**
 * Type intermédiaire pour d'extraire tous les paramètres d'une méthode composable
 *
 * @example
 * FullParams<'getEmptyErrorMessage'> === [control: AbstractControl, message: string, error: string]
 */
type FullParams<T extends ComposableMethods> = Parameters<(typeof FormHelper)[T]>;

/**
 *  Type pour extraire la liste des paramètres d'une méthode composable en omettant le premier paramètre qui doit être l'AbstractControl
 *
 *  @example
 *  ComposableMethodParams<'getEmptyErrorMessage'> === [message: string, error: string]
 */
export type ComposableMethodParams<T extends ComposableMethods = ComposableMethods, P = FullParams<T>> = P extends [
  AbstractControl,
  ...infer R
]
  ? R
  : never;

/**
 * Objet qui permet de paramétrer la composition en étant type safe
 *
 * @link {FormHelper.composeErrorMessages}
 * @example
 * { method: 'getEmptyErrorMessage', args: ['Champ requis', ErrorCode.EMPTY] }
 */
export type Composition<
  T extends ComposableMethods,
  Params extends ComposableMethodParams<T> = ComposableMethodParams<T>
> = { method: T; args: Params };

export class FormHelper {
  static getEmptyErrorMessage(
    control: AbstractControl,
    message: string = 'Champ requis',
    error: string = ErrorCode.EMPTY
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (control.hasError(ErrorType.REQUIRED)) {
      return {
        error,
        message
      };
    }

    return null;
  }

  static simpleErrorMessage(
    control: AbstractControl,
    data: FormHelperSimpleErrorMessageInterface
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (control.hasError(data.simpleErrorCode)) {
      return {
        error: data.simpleError || ErrorCode.INVALID,
        message: data.simpleErrorMessage
      };
    }

    return null;
  }

  /**
   * Génère les messages relatifs aux erreurs sur les datepickers
   * Il est possible d'utiliser `#value#` dans le message pour utiliser la date remontée par l'erreur
   *
   * @param control
   * @param data
   */
  static getDatePickerErrorMessage(
    control: AbstractControl,
    data: FormHelperDatePickerErrorMessageInterface
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (data.checkMin) {
      if (control.hasError(ErrorType.MAT_DATEPICKER_MIN)) {
        return {
          error: data.minError || ErrorCode.EMPTY,
          message: data.minErrorMessage.replace(
            '#value#',
            DateHelper.format(control.getError(ErrorType.MAT_DATEPICKER_MIN).min, 'DD/MM/yyyy')
          )
        };
      }
    }

    if (data.checkMax) {
      if (control.hasError(ErrorType.MAT_DATEPICKER_MAX)) {
        return {
          error: data.maxError || ErrorCode.EMPTY,
          message: data.maxErrorMessage.replace(
            '#value#',
            DateHelper.format(control.getError(ErrorType.MAT_DATEPICKER_MAX).max, 'DD/MM/yyyy')
          )
        };
      }
    }

    return null;
  }

  /**
   * Génère le message d'erreur lorsque l'erreur 'min' est présente
   * Il est possible d'utiliser `#value#` dans le message pour utiliser la valeur minimum remontée par l'erreur
   *
   * @param control
   * @param message
   * @param error
   */
  static getMinErrorMessage(
    control: AbstractControl,
    message: string | null = null,
    error: string = ErrorCode.INVALID
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (control.hasError(ErrorType.MIN)) {
      const minAmount = (control.getError(ErrorType.MIN) as { min: number; actual: number }).min ?? 0;
      const errorMessage = message
        ? message.replace('#value#', minAmount.toString())
        : `La valeur doit être supérieure à ${minAmount}`;
      return {
        error,
        message: errorMessage
      };
    }

    return null;
  }

  /**
   * Génère le message d'erreur lorsque l'erreur 'max' est présente
   * Il est possible d'utiliser `#value#` dans le message pour utiliser la valeur maximum remontée par l'erreur
   *
   * @param control
   * @param message
   * @param error
   */
  static getMaxErrorMessage(
    control: AbstractControl,
    message: string | null = null,
    error: string = ErrorCode.INVALID
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (control.hasError('max')) {
      const maxAmount = (control.getError('max') as { max: number; actual: number }).max ?? 0;
      const errorMessage = message
        ? message.replace('#value#', maxAmount.toString())
        : `La valeur doit être inférieure à ${maxAmount}`;
      return {
        error,
        message: errorMessage
      };
    }

    return null;
  }

  static getMaxLengthErrorMessage(
    control: AbstractControl,
    message: string | null = null,
    error: string = ErrorCode.INVALID
  ): ErrorMessage | null {
    if (!control || !control.touched) {
      return null;
    }

    if (control.hasError('maxlength')) {
      const maxLength =
        (control.getError('maxlength') as { requiredLength: number; actualLength: number }).requiredLength ?? 0;
      const errorMessage = message
        ? message.replace('#value#', maxLength.toString())
        : `Le champ peut contenir au maximum ${maxLength} caractères`;
      return {
        error,
        message: errorMessage
      };
    }

    return null;
  }

  /**
   * Permet de chainer les méthodes pour générer les messages d'erreurs sur le même control
   *
   * @example
   * FormHelper.composeErrorMessages(control, [
   *   { method: 'getEmptyErrorMessage', args: ['Champ requis', ErrorCode.EMPTY] },
   *   { method: 'getMaxErrorMessage', args: ['La valeur doit être inférieure à #value#', ErrorCode.INVALID] }
   * ]);
   *
   * @param control
   * @param composition
   */
  static composeErrorMessages(
    control: AbstractControl,
    composition: Composition<ComposableMethods>[]
  ): ErrorMessage | null {
    if (!composition.length) {
      return null;
    }
    let index = 0;

    do {
      const { method, args } = composition[index];
      const result = FormHelper[method].apply(null, [control, ...args]) as ErrorMessage | null;
      if (result) {
        return result;
      }
      index++;
    } while (index < composition.length);

    return null;
  }

  static addErrorToControl(
    control: AbstractControl,
    error: string,
    errorValue: Record<string, unknown> | boolean = true
  ): void {
    let errors = control.errors;
    if (!errors) {
      errors = {};
    }
    errors[error] = errorValue;
    control.setErrors(errors);
  }

  static removeErrorFromControl(control: AbstractControl, error: string): void {
    if (control.hasError(error)) {
      delete control.errors[error];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  static safeAssign<T>(value: T): T | null {
    return value === undefined ? null : value;
  }

  /**
   * Retourne un observable qui combine la valeur initiale d'un AbstractControl et son valueChange
   * @param control {AbstractControl}
   */
  static getValueChange<T>(control: AbstractControl<T>): Observable<T> {
    const initialValue$ = defer(() => of(control.value));
    const valueChange$ = control.valueChanges;

    return merge(initialValue$, valueChange$);
  }
}
