import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-tag',
  host: {
    '[class.primary]': 'color === "primary"',
    '[class.accent]': 'color === "accent"',
    '[class.warn]': 'color === "warn"',
    '[class.grey-2]': 'color === "grey-2"',
    '[class.white]': 'color === "white"',
    '[class.foreground]': 'color === "foreground"',
    '[class.small]': '_hasHostAttributes("small")',
    '[class.large]': '_hasHostAttributes("large")',
    '[class.inverted]': '_hasHostAttributes("inverted")',
    class: 'tiime-tag'
  },
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent extends HasAttributesBase {
  @Input()
  color: '' | 'primary' | 'accent' | 'warn' | 'grey-2' | 'foreground' | 'white';
  @Input()
  tag: string;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
