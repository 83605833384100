import { Injectable } from '@angular/core';

import { FirebaseOptions, initializeApp } from 'firebase/app';
import { MessagePayload, Messaging, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { Observable, Subject, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AppConfigService } from '@services/app-config.service';

export type FirebaseMessagePayload = MessagePayload;

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  readonly token$ = new Subject<string>();
  readonly message$ = new Subject<MessagePayload>();

  private get firebaseOptions(): FirebaseOptions {
    const environment = this.appConfigService.environment;
    return {
      apiKey: environment.FIREBASE.apiKey,
      authDomain: environment.FIREBASE.authDomain,
      projectId: environment.FIREBASE.projectId,
      storageBucket: environment.FIREBASE.storageBucket,
      messagingSenderId: environment.FIREBASE.messagingSenderId,
      appId: environment.FIREBASE.appId
    };
  }

  private get firebaseVapidKey(): string {
    return this.appConfigService.environment.FIREBASE.vapidKey;
  }

  constructor(private appConfigService: AppConfigService) {}

  init(): void {
    if (!isSupported()) {
      return;
    }

    const app = initializeApp(this.firebaseOptions);
    const messaging = getMessaging(app);

    this.registerServiceWorker()
      .pipe(
        switchMap(serviceWorkerRegistration => this.getToken(messaging, serviceWorkerRegistration)),
        tap(token => this.token$.next(token))
      )
      .subscribe();

    this.listenMessage(messaging)
      .pipe(tap(message => this.message$.next(message)))
      .subscribe();
  }

  private getToken(messaging: Messaging, serviceWorkerRegistration: ServiceWorkerRegistration): Observable<string> {
    return from(
      getToken(messaging, {
        serviceWorkerRegistration,
        vapidKey: this.firebaseVapidKey
      })
    );
  }

  private listenMessage(messaging: Messaging): Observable<FirebaseMessagePayload> {
    return new Observable<FirebaseMessagePayload>(subscriber =>
      onMessage(messaging, message => subscriber.next(message))
    );
  }

  private registerServiceWorker(): Observable<ServiceWorkerRegistration> {
    return from(navigator.serviceWorker.register('firebase-messaging-sw.js', { scope: '__' }));
  }
}
