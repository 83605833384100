import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[tiimeContainerFooter]',
  host: {
    class: 'tiime-container-footer',
    '[class.with-selection]': 'hasSelection'
  }
})
export class ContainerFooterDirective {
  @Input() hasSelection = false;
}
