<tiime-input-container
  tiimeFormInputContainer
  contentId="legalForm"
  label="Forme juridique"
  [errorMessage]="errorMessage?.message"
  [required]="required"
>
  <tiime-select
    id="legalForm"
    [class.selected]="control.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
    data-cy="company-form-legal-form-input"
    (closeOptionsPanel)="closeOptionsPanel()"
  >
    <input tiimeSelectInput [formControl]="searchControl" appFormFocusObserve (formChanges)="searchControlChanges()" />
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let legalForm of filteredSelectorData; trackBy: trackByIndex" [value]="legalForm">
        {{ legalForm }}
      </mat-option>
    </div>
    <ng-content tiimeSelectSuffix select="[tiimeSelectSuffix]"></ng-content>
  </tiime-select>
</tiime-input-container>
