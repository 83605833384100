<tiime-input-container tiimeFormInputContainer [label]="label" [contentId]="selectorId">
  <tiime-select
    [id]="selectorId"
    [class.selected]="control.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
    data-cy="country-select"
  >
    <input
      tiimeSelectInput
      placeholder="Rechercher un pays"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions data-cy="country-select-tab">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let country of filteredSelectorData; trackBy: trackByCode" [value]="country.code">
        {{ showNationality | map : mapToCorrectField : country }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
