import { Sort } from '@angular/material/sort';

import { UntilDestroy } from '@ngneat/until-destroy';
import { OnStateInit } from '@ngrx/component-store';
import { combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AutoSaveStatus, ContainerState, PaginationData } from 'tiime-material';

import { ContainerStoreBase, ContainerStoreState } from '../container-store/container-store.base';

export interface TableDataStoreState<DataType extends { id?: number } = { id: number }, FiltersType = unknown>
  extends ContainerStoreState {
  data: DataType[];
  containerState: ContainerState;
  filters: FiltersType | null;
  search: string | null;
  sort: Sort | null;
  selected: DataType | null;
}

export const defaultState: TableDataStoreState<{ id: number }, Record<string, unknown>> = {
  data: [],
  containerState: ContainerState.contentPlaceholder,
  filters: {},
  search: null,
  sort: null,
  isLoading: false,
  selected: null
};

export interface AutoSave<T> {
  element: T;
  autoSaveStatus: AutoSaveStatus;
}

@UntilDestroy({ checkProperties: true })
export abstract class TableDataStoreBase<
    DataType extends { id?: number },
    FiltersType,
    State extends TableDataStoreState<DataType, FiltersType> = TableDataStoreState<DataType, FiltersType>
  >
  extends ContainerStoreBase<State>
  implements OnStateInit
{
  readonly data$: Observable<DataType[]> = this.select(({ data }) => data);
  readonly filters$: Observable<FiltersType> = this.select(({ filters }) => filters);
  readonly search$: Observable<string | null> = this.select(({ search }) => search);
  readonly sort$: Observable<Sort | null> = this.select(({ sort }) => sort);
  readonly selected$: Observable<DataType> = this.select(({ selected }) => selected);
  readonly autoSaveSubject: Subject<AutoSave<DataType>> = new Subject<AutoSave<DataType>>();
  readonly autoSave$: Observable<AutoSave<DataType>> = this.autoSaveSubject.asObservable();
  readonly addOneSubject: Subject<DataType> = new Subject<DataType>();
  readonly addOne$: Observable<DataType> = this.addOneSubject.asObservable();
  readonly sortDisabled$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done
  );
  readonly searchDisabled$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done && containerState !== ContainerState.noResult
  );
  readonly actionsDisabled$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done && containerState !== ContainerState.noResult
  );
  readonly hiddenFooter$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done
  );

  readonly setFilters = this.updater((state, filters: FiltersType) => ({
    ...state,
    filters
  }));

  readonly setPartialFilters = this.updater((state, filters: Partial<FiltersType>) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters
    }
  }));

  readonly setSort = this.updater((state, sort: Sort) => {
    if (!sort.active) {
      sort = {
        ...state.sort
      };
    }
    return {
      ...state,
      sort
    };
  });

  readonly setSearch = this.updater((state, search: string | null) => ({
    ...state,
    search
  }));

  readonly setSelected = this.updater((state, selected: DataType) => ({
    ...state,
    selected
  }));

  readonly updateOne = this.updater((state, element: DataType) => ({
    ...state,
    data: state.data.map(elt => {
      if (elt.id !== element.id) {
        return elt;
      }
      return element;
    })
  }));

  readonly addOne = this.updater((state, element: DataType) => {
    this.addOneSubject.next(element);

    return {
      ...state,
      data: [...state.data, element]
    };
  });

  readonly removeOne = this.updater((state, elementIndex: number) => ({
    ...state,
    data: [...state.data.slice(0, elementIndex), ...state.data.slice(elementIndex + 1)]
  }));

  protected readonly subscriptions: Subscription = new Subscription();
  protected readonly refreshSubject: ReplaySubject<number> = new ReplaySubject<number>();

  readonly refresh$: Observable<number> = this.refreshSubject.asObservable();

  constructor(initialState: State = defaultState as unknown as State) {
    super(initialState);
  }

  ngrxOnStateInit(): void {
    this.observeChanges();
    this.init();
  }

  init(): void {
    this.refreshSubject.next(Date.now());
  }

  forceRefresh(): void {
    this.refreshSubject.next(Date.now());
  }

  autoSaveSuccess(element: DataType): void {
    this.autoSaveSubject.next({ element, autoSaveStatus: AutoSaveStatus.SaveSuccess });
  }

  autoSaveError(element: DataType): void {
    this.autoSaveSubject.next({ element, autoSaveStatus: AutoSaveStatus.SaveError });
  }

  protected observeChanges(): void {
    this.subscriptions.add(
      combineLatest([this.getChangesObservable(), this.refresh$])
        .pipe(
          tap(() => this.patchState({ isLoading: true } as Partial<State>)),
          switchMap(() => this.getData()),
          tap(() => this.patchState({ isLoading: false } as Partial<State>))
        )
        .subscribe()
    );
  }

  /**
   * Méthode pour récupérer l'observable qui va déclencher le refresh des data
   *
   * @protected
   */
  protected abstract getChangesObservable(): Observable<unknown>;

  /**
   * Méthode qui s'occupe de récupérer et stocker les données
   *
   * @protected
   */
  protected abstract getData(): Observable<PaginationData<DataType> | DataType[]>;
}
