import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { UserInvitation, UserInvitationJson } from '@models/user-invitation';
import { UserInvitationConfiguration } from '@models/user-invitation-configuration';

@Injectable({
  providedIn: 'root'
})
export class UserInvitationApiService extends ApiCommonService<UserInvitation> {
  resourceUrl = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/users`;

  fromJson(json: UserInvitationJson): UserInvitation {
    return UserInvitation.fromJson(json);
  }

  toJson(model: UserInvitation): UserInvitationJson {
    return UserInvitation.toJson(model);
  }

  @ApiAlertError()
  createUserInvitation(
    userId: number,
    invitationConfiguration: UserInvitationConfiguration,
    companyId?: number
  ): Observable<UserInvitation | UserInvitation[]> {
    const url = companyId
      ? `api/v1/expert/companies/${companyId}/users/${userId}/invitations`
      : `${this.resourceUrl}/${userId}/invitations`;

    return this.create(invitationConfiguration, url);
  }

  @ApiAlertError()
  getUserInvitations(userId: number): Observable<UserInvitation[]> {
    const url = `${this.resourceUrl}/${userId}/invitations`;

    return super.getAll(null, url);
  }
}
