import { Injectable } from '@angular/core';

import { tapResponse } from '@ngrx/component-store';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { ContainerState, ContainerStateHelper, PaginationData, PaginationRange } from 'tiime-material';

import { CompanyApiService } from '@api-services/company-api.service';
import { AccountingPeriodApiService } from '@api-services/accounting-period-api.service';
import { FiscalDeclarationApiService } from '@api-services/fiscal-declaration-api.service';
import { TaskPlanningApiService } from '@api-services/task-planning-api.service';
import { ThreadApiService } from '@api-services/thread-api.service';
import { VatDeclarationApiService } from '@api-services/vat-declaration-api.service';
import { ApiTableDataStoreBase, ApiTableDataStoreState } from '@bases/table-data-store/api-table-data-store.base';
import { FiscalDeclarationType } from '@enums/fiscal-declaration-type.enum';
import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadStatus } from '@enums/thread-status.enum';
import { noop } from '@helpers/noop.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { ThreadFilters } from '@interfaces/thread-filters';
import { AccountingPeriod } from '@models/accounting-period';
import { BusinessUser } from '@models/business-user';
import { Company } from '@models/company';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { FiscalDeclaration } from '@models/fiscal-declaration';
import { Message } from '@models/message';
import { TaskPlanning } from '@models/task-planning';
import { Thread } from '@models/thread';
import { ThreadTag } from '@models/thread-tag';
import { ThreadsInfos } from '@models/threads-infos';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

export type Mode = 'List' | 'Creation' | 'Detail';

export type ThreadStoreState = ApiTableDataStoreState<Thread, ThreadFilters> & {
  opened: boolean;
  company: Company;
  tags: ThreadTag[];
  accountingPeriods: AccountingPeriod[];
  vatDeclarations: VatDeclarationConfiguration[];
  taskPlannings: TaskPlanning[];
  corporateTaxInstalments: CorporateTaxInstalment[];
  mode: Mode;
  infos: ThreadsInfos;
  infosLoading: boolean;
  companyContributors: CompanyContributor[];
};

export const initialState: ThreadStoreState = {
  data: [],
  containerState: ContainerState.contentPlaceholder,
  pagination: PaginationRange.withPageSize(10),
  filters: {
    startDate: null,
    endDate: null,
    contributors: [],
    tags: [],
    type: null,
    status: ThreadStatus.opened,
    entity: null,
    category: ThreadCategory.accountant
  },
  search: null,
  sort: null,
  isLoading: false,
  selected: null,
  opened: false,
  company: null,
  tags: [],
  accountingPeriods: [],
  vatDeclarations: [],
  taskPlannings: [],
  corporateTaxInstalments: [],
  mode: 'List',
  infos: {
    hasOpenedThreads: false,
    currentUserHasOpenedThreads: false
  },
  infosLoading: false,
  companyContributors: []
};

@Injectable()
export class CompanyThreadsStore extends ApiTableDataStoreBase<Thread, ThreadFilters, ThreadStoreState> {
  readonly opened$: Observable<boolean> = this.select(({ opened }) => opened);
  readonly accountingPeriods$: Observable<AccountingPeriod[]> = this.select(
    ({ accountingPeriods }) => accountingPeriods
  );
  readonly vatDeclarations$: Observable<VatDeclarationConfiguration[]> = this.select(
    ({ vatDeclarations }) => vatDeclarations
  );
  readonly taskPlannings$: Observable<TaskPlanning[]> = this.select(({ taskPlannings }) => taskPlannings);
  readonly corporateTaxInstalments$: Observable<CorporateTaxInstalment[]> = this.select(
    ({ corporateTaxInstalments }) => corporateTaxInstalments
  );
  readonly mode$: Observable<Mode> = this.select(({ mode }) => mode);
  readonly company$: Observable<Company> = this.select(({ company }) => company);
  readonly tags$: Observable<ThreadTag[]> = this.select(({ tags }) => tags);
  readonly infos$: Observable<ThreadsInfos> = this.select(({ infos }) => infos);
  readonly infosLoading$: Observable<boolean> = this.select(({ infosLoading }) => infosLoading);
  readonly companyContributors$: Observable<CompanyContributor[]> = this.select(
    ({ companyContributors }) => companyContributors
  );
  readonly updatedThreadSub: Subject<Thread> = new Subject<Thread>();
  readonly updatedThread$: Observable<Thread> = this.updatedThreadSub.asObservable();

  readonly setOpened = this.updater((state, opened: boolean) => ({
    ...state,
    opened
  }));

  readonly setMode = this.updater((state, mode: Mode) => ({
    ...state,
    mode
  }));

  readonly setInfosLoading = this.updater((state, infosLoading: boolean) => ({
    ...state,
    infosLoading
  }));

  readonly setInfos = this.updater((state, infos: ThreadsInfos) => ({
    ...state,
    infos
  }));

  readonly loadAccountingPeriods = this.effect((company$: Observable<Company>) =>
    company$.pipe(
      tap(() => this.patchState({ accountingPeriods: [] })),
      switchMap(company =>
        this.accountingPeriodApiService
          .getAccountingPeriods(company.id)
          .pipe(tapResponse(accountingPeriods => this.patchState({ accountingPeriods }), noop))
      )
    )
  );

  readonly loadVatDeclarations = this.effect(
    (data$: Observable<{ company: Company; extraVatDeclarations: VatDeclarationConfiguration[] }>) =>
      data$.pipe(
        tap(() => this.patchState({ vatDeclarations: [] })),
        switchMap(data =>
          this.vatDeclarationApiService.getVatDeclarations(data.company.id).pipe(
            tapResponse(vatDeclarations => {
              data.extraVatDeclarations.forEach(declaration => vatDeclarations.unshift(declaration));
              this.patchState({ vatDeclarations });
            }, noop)
          )
        )
      )
  );

  readonly loadTaskPlannings = this.effect((company$: Observable<Company>) =>
    company$.pipe(
      tap(() => this.patchState({ taskPlannings: [] })),
      switchMap(company =>
        this.taskPlanningApiService
          .getTaskPlanningsByCompany(company.id)
          .pipe(tapResponse(taskPlannings => this.patchState({ taskPlannings }), noop))
      )
    )
  );

  readonly loadCorporateTaxInstalments = this.effect((company$: Observable<Company>) =>
    company$.pipe(
      tap(() => this.patchState({ corporateTaxInstalments: [] })),
      switchMap((company: Company) =>
        this.fiscalDeclarationApiService
          .getFiscalDeclarations(null, FiscalDeclarationType.corporateTaxInstalment, null, company.id)
          .pipe(
            tapResponse(
              (corporateTaxInstalments: FiscalDeclaration[]) => this.patchState({ corporateTaxInstalments }),
              noop
            )
          )
      )
    )
  );

  readonly loadTags = this.effect((company$: Observable<Company>) =>
    company$.pipe(
      tap(() => this.patchState({ tags: [] })),
      switchMap(company =>
        this.threadApiService
          .getTags(company.id)
          .pipe(tapResponse((tags: ThreadTag[]) => this.patchState({ tags }), noop))
      )
    )
  );

  readonly loadInfos = this.effect(_$ =>
    _$.pipe(
      tap(() => this.setInfosLoading(true)),
      switchMap(() =>
        this.threadApiService.getThreadsInfos().pipe(
          tapResponse(
            infos => {
              this.setInfos(infos);
              this.setInfosLoading(false);
            },
            () => this.setInfosLoading(false)
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly loadCompanyContributors = this.effect((_$: Observable<number | undefined>) =>
    _$.pipe(
      switchMap(companyId =>
        this.companyApiService.getContributors(companyId).pipe(
          map(companyContributors => this.filterDuplicatesByUserId(companyContributors)),
          tapResponse(companyContributors => this.patchState({ companyContributors }), noop)
        )
      )
    )
  );

  readonly markAsRead = this.effect((thread$: Observable<Thread>) =>
    thread$.pipe(
      switchMap(thread =>
        this.threadApiService.markThreadAsRead(thread.id, this.get().company.id).pipe(
          tapResponse(() => {
            const updatedThread: Thread = { ...thread, readByCurrentUser: true };
            this.updateThread(updatedThread);
            this.loadInfos();
          }, noop)
        )
      )
    )
  );

  constructor(
    private threadApiService: ThreadApiService,
    private companyApiService: CompanyApiService,
    private readonly store: Store<AppStoreState>,
    private accountingPeriodApiService: AccountingPeriodApiService,
    private vatDeclarationApiService: VatDeclarationApiService,
    private taskPlanningApiService: TaskPlanningApiService,
    private fiscalDeclarationApiService: FiscalDeclarationApiService
  ) {
    super(initialState);
  }

  override ngrxOnStateInit(): void {
    super.observeChanges();
    this.loadCompanyContributors(
      this.opened$.pipe(
        filter(opened => opened),
        switchMap(() =>
          this.company$.pipe(
            filter(company => !!company),
            take(1),
            map(company => company.id)
          )
        )
      )
    );
  }

  open(
    company: Company,
    filters: ThreadFilters = initialState.filters,
    extraVatDeclarations: VatDeclarationConfiguration[] = []
  ): void {
    this.reset();
    this.patchState({
      opened: true,
      company,
      filters
    });
    this.loadAccountingPeriods(company);
    this.loadVatDeclarations({ company, extraVatDeclarations });
    this.loadTaskPlannings(company);
    this.loadCorporateTaxInstalments(company);
    this.loadTags(company);
  }

  createThread(thread: Thread): Observable<Thread> {
    const { company } = this.get();
    this.setLoading(true);

    return this.threadApiService.createThread(thread, company?.id).pipe(
      tap((thread: Thread) => {
        this.updatedThreadSub.next(thread);
        this.addNewTags(thread.tags);
        this.setMode('List');
        this.forceRefresh();
      }),
      tap(() => this.loadInfos()),
      finalize(() => this.setLoading(false))
    );
  }

  closeThread(thread: Thread, closed: boolean): void {
    const { company } = this.get();
    this.setLoading(true);

    this.threadApiService
      .closeThread(thread.id, closed, company?.id)
      .pipe(
        switchMap(() => this.getBusinessUser()),
        tap((businessUser: BusinessUser) => {
          const updatedThread: Thread = {
            ...thread,
            closedAt: closed ? new Date() : null,
            closedBy: closed ? businessUser : null
          };
          this.updateThread(updatedThread);
        }),
        tap(() => this.loadInfos()),
        finalize(() => this.setLoading(false))
      )
      .subscribe();
  }

  updateInterlocutors(thread: Thread, interlocutors: Contributor[]): void {
    const { company } = this.get();
    this.setLoading(true);

    this.threadApiService
      .updateThreadInterlocutors(thread.id, interlocutors, company?.id)
      .pipe(
        tap((updatedThread: Thread) => this.updateThread(updatedThread)),
        tap(() => this.loadInfos()),
        finalize(() => this.setLoading(false))
      )
      .subscribe();
  }

  updateTags(thread: Thread, tags: ThreadTag[]): void {
    const { company } = this.get();
    this.setLoading(true);

    this.threadApiService
      .updateThreadTags(thread.id, tags, company?.id)
      .pipe(
        tap((updatedThread: Thread) => this.updateThread(updatedThread)),
        tap(() => this.loadInfos()),
        finalize(() => this.setLoading(false))
      )
      .subscribe();
  }

  archiveThread(thread: Thread, archived: boolean): void {
    const { company } = this.get();
    this.setLoading(true);

    this.threadApiService
      .archiveThread(thread.id, archived, company.id)
      .pipe(
        switchMap(() => this.getBusinessUser()),
        tap((businessUser: BusinessUser) => {
          const updatedThread: Thread = {
            ...thread,
            closedAt: archived ? new Date() : thread.closedAt,
            closedBy: archived ? businessUser : thread.closedBy,
            archivedAt: archived ? new Date() : null,
            archivedBy: archived ? businessUser : null
          };
          this.updateThread(updatedThread);
        }),
        tap(() => this.loadInfos()),
        finalize(() => this.setLoading(false))
      )
      .subscribe();
  }

  createMessage(thread: Thread, content: string): Observable<Message> {
    const { company } = this.get();
    this.setLoading(true);
    return this.threadApiService.createMessage(thread.id, content, company.id).pipe(
      tap((message: Message) => {
        const updatedThread: Thread = { ...thread, messages: [...thread.messages, message], readByCurrentUser: true };
        this.updateThread(updatedThread);
      }),
      tap(() => this.loadInfos()),
      finalize(() => this.setLoading(false))
    );
  }

  updateMessageContent(thread: Thread, message: Message, content: string): void {
    const { company } = this.get();
    this.setLoading(true);
    this.threadApiService
      .updateMessage(thread.id, message.id, content, company.id)
      .pipe(
        tap((updatedMessage: Message) => {
          const updatedThread: Thread = {
            ...thread,
            messages: thread.messages.map(existingMessage =>
              existingMessage.id === updatedMessage.id ? updatedMessage : existingMessage
            )
          };
          this.updateThread(updatedThread);
        }),
        finalize(() => this.setLoading(false))
      )
      .subscribe();
  }

  protected override getData(): Observable<Thread[]> {
    const { pagination, search, filters, company } = this.get();

    const threadFilters = { ...filters };
    if (filters.status === 'all') {
      threadFilters.status = null;
    }

    return this.threadApiService.getThreads(pagination, search, threadFilters, company.id).pipe(
      tap((paginationData: PaginationData<Thread>) => {
        const shouldOnBoard =
          !search &&
          paginationData.paginationRange.min === 0 &&
          !filters.category &&
          !filters.endDate &&
          !filters.startDate &&
          !filters.status &&
          !filters.type &&
          !filters.contributors?.length &&
          !filters.tags?.length;

        this.patchState({
          data: paginationData.data,
          pagination: paginationData.paginationRange,
          containerState: ContainerStateHelper.getContainerState(paginationData.data.length, shouldOnBoard),
          isLoading: false
        });
      }),
      map(({ data }) => data),
      catchError(() => of([]))
    );
  }

  private filterDuplicatesByUserId(companyContributors: CompanyContributor[]): CompanyContributor[] {
    return companyContributors.filter(
      (item, index) => companyContributors.findIndex(i => i.user.id === item.user.id) === index
    );
  }
  private updateThread(thread: Thread): void {
    this.updateOne(thread);
    this.updatedThreadSub.next(thread);
    this.addNewTags(thread.tags);

    const selected = this.get().selected;

    if (selected) {
      this.setSelected(thread);
    }
  }

  private addNewTags(newTags: ThreadTag[]): void {
    const existingTags = this.get().tags;

    newTags.forEach((newTag: ThreadTag) => {
      if (!existingTags.find((existingTag: ThreadTag) => existingTag.id === newTag.id)) {
        existingTags.push(newTag);
      }
    });

    this.patchState({
      tags: existingTags
    });
  }

  private getBusinessUser(): Observable<BusinessUser> {
    return this.store.pipe(
      select(businessUserSelector),
      filter(businessUser => !!businessUser),
      take(1)
    );
  }
}
