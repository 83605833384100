import { ImmobilisationType } from '@enums/immobilisation-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { ImmobilisationExit, ImmobilisationExitJson } from '@models/immobilisation-exit';
import { PcgEntryJson } from '@models/pcg-entry';
import { Piece, PieceJson } from '@models/piece';
import { SubPcgEntry } from '@models/sub-pcg-entry';

export interface ImmobilisationJson {
  id: number;
  purchase_date: string;
  commissioning_date: string;
  description: string;
  pcg_entry: Partial<PcgEntryJson>;
  depreciation_type: ImmobilisationType;
  duration: number;
  purchase_amount: number;
  depreciable_amount: number;
  previous_depreciation_amount: number;
  initial_depreciation_amount: number;
  endowment: number;
  is_deletable: boolean;
  piece: Partial<PieceJson>;
  initial_depreciation_date: string | null;
  comment: string;
  depreciation_rate: number;
  tourism_vehicle_deduction: number | null;
  exits: ImmobilisationExitJson[];
  acquisition_exercice_start_date: string | null;
}

export class Immobilisation {
  constructor(
    public id?: number,
    public purchaseDate?: string,
    public commissioningDate?: string,
    public description?: string,
    public pcgEntry?: SubPcgEntry,
    public depreciationType?: ImmobilisationType,
    public duration?: number,
    public purchaseAmount?: number,
    public depreciableAmount?: number,
    public previousDepreciationAmount?: number,
    public initialDepreciationAmount?: number,
    public endowment?: number,
    public isDeletable?: boolean,
    public piece: Piece = new Piece(),
    public initialDepreciationDate?: string | null,
    public comment?: string,
    public depreciationRate?: number,
    public tourismVehicleDeduction?: number | null,
    public exits?: ImmobilisationExit[],
    public acquisitionExerciceStartDate?: string | null
  ) {}

  static fromJson(json: Partial<ImmobilisationJson>): Immobilisation {
    return new Immobilisation(
      json.id,
      json.purchase_date,
      json.commissioning_date,
      json.description,
      json.pcg_entry ? SubPcgEntry.fromJson(json.pcg_entry) : null,
      json.depreciation_type,
      json.duration,
      json.purchase_amount,
      json.depreciable_amount,
      json.previous_depreciation_amount,
      json.initial_depreciation_amount,
      json.endowment,
      json.is_deletable,
      json.piece ? Piece.fromJson(json.piece) : new Piece(),
      json.initial_depreciation_date,
      json.comment,
      json.depreciation_rate,
      json.tourism_vehicle_deduction,
      json.exits ? json.exits.map(exit => ImmobilisationExit.fromJson(exit)) : [],
      json.acquisition_exercice_start_date
    );
  }

  static toJson(
    immobilisation: Immobilisation
  ): Omit<ImmobilisationJson, 'previous_depreciation_amount' | 'endowment' | 'piece'> {
    return {
      id: immobilisation.id,
      purchase_date: immobilisation.purchaseDate ? DateHelper.format(immobilisation.purchaseDate) : undefined,
      commissioning_date: immobilisation.commissioningDate
        ? DateHelper.format(immobilisation.commissioningDate)
        : undefined,
      description: immobilisation.description,
      pcg_entry: immobilisation.pcgEntry ? HttpHelper.toJsonId(immobilisation.pcgEntry) : undefined,
      depreciation_type: immobilisation.depreciationType,
      duration: immobilisation.duration,
      purchase_amount: immobilisation.purchaseAmount,
      depreciable_amount: immobilisation.depreciableAmount,
      initial_depreciation_amount: immobilisation.initialDepreciationAmount ?? 0,
      is_deletable: undefined,
      initial_depreciation_date: immobilisation.initialDepreciationDate
        ? DateHelper.format(immobilisation.initialDepreciationDate)
        : null,
      comment: immobilisation.comment,
      depreciation_rate: immobilisation.depreciationRate,
      tourism_vehicle_deduction: immobilisation.tourismVehicleDeduction,
      exits: immobilisation.exits ? immobilisation.exits.map(exit => ImmobilisationExit.toJson(exit)) : undefined,
      acquisition_exercice_start_date: immobilisation.acquisitionExerciceStartDate
        ? DateHelper.format(immobilisation.acquisitionExerciceStartDate)
        : null
    };
  }

  getExitAmount(): number {
    return (this.exits ?? []).reduce((acc, exit) => acc + (exit.amount ?? 0), 0);
  }

  getPurchaseAmountAvailable(): number {
    if (!this.purchaseAmount) {
      return 0;
    }

    return this.purchaseAmount - this.getExitAmount();
  }
}
