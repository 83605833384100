import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthModule as Auth0Module, AuthClientConfig } from '@auth0/auth0-angular';

import { PageLoaderComponent } from '@modules/shared/components/page-loader/page-loader.component';
import { SharedModule } from '@modules/shared/shared.module';
import { AppConfigService } from '@services/app-config.service';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AUTH0_CACHE_LOCATION, AUTH0_REDIRECT_URI, AUTH0_SCOPE } from './auth.constant';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { SigninComponent } from './signin/signin.component';

@NgModule({
  imports: [SharedModule, PageLoaderComponent, Auth0Module.forRoot()],
  declarations: [AuthCallbackComponent, SigninComponent, AuthLayoutComponent, CreatePasswordComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService, authClientConfig: AuthClientConfig): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() =>
          authClientConfig.set({
            domain: appConfigService.environment.AUTH0_DOMAIN,
            clientId: appConfigService.environment.AUTH0_CLIENT_ID,
            cacheLocation: AUTH0_CACHE_LOCATION,
            authorizationParams: {
              scope: AUTH0_SCOPE,
              audience: appConfigService.environment.AUTH0_AUDIENCE,
              redirect_uri: AUTH0_REDIRECT_URI
            },
            httpInterceptor: {
              allowedList: [
                {
                  uri: `${appConfigService.environment.API_URL}/*`
                }
              ]
            }
          })
        ),
      deps: [AppConfigService, AuthClientConfig],
      multi: true
    }
  ]
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(`AuthModule is already loaded. It should only be imported in Core Module only.`);
    }
  }
}
