import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationExtras, Params, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, take, tap } from 'rxjs/operators';

import {
  NOTIFICATION_ROUTE_ASSOCIATIONS,
  NOTIFICATION_ROUTE_SEPARATOR
} from '@constants/notification-route-associations.constant';
import { HttpHelper } from '@helpers/http.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { Company } from '@models/company';
import { companySelector } from '@modules/company/company-core/store/company/company-selector';
import { DocumentsAndEditionsRoute } from '@modules/core/navigation/documents-and-editions-route.enum';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';
import { WINDOW } from '@modules/core/tokens/window.token';

import { AdminRoute } from './admin-route.enum';
import { AppRoute } from './app-route.enum';
import { CompanyRoute } from './company-route.enum';
import { DeclarationsRoute } from './declarations-route.enum';
import { LegalRoute } from './legal-route.enum';
import { ParametersRoute } from './parameters-route.enum';
import { PreAccountingRoute } from './pre-accounting-route.enum';
import { PurchasesRoute } from './purchases-route.enum';
import { SalesRoute } from './sales-route.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    public router: Router,
    private store: Store<AppStoreState>,
    @Inject(WINDOW) private window: Window
  ) {}

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  navigateToDefaultRoute(): Promise<boolean> {
    return this.navigate([AppRoute.empty]);
  }

  navigateToSignIn(): Promise<boolean> {
    return this.navigate([AppRoute.signin]);
  }

  navigateToUnauthorized(): Promise<boolean> {
    return this.navigate([AppRoute.unauthorized]);
  }

  navigateToCompanies(): Promise<boolean> {
    return this.navigate([AppRoute.companies]);
  }

  navigateToPlanning(): Promise<boolean> {
    return this.navigate([AppRoute.planning]);
  }

  navigateToHome(): Promise<boolean> {
    return this.navigate([AppRoute.home]);
  }

  navigateToCompany(companyId: number, openInNewTab: boolean = false): void {
    const companyPattern = /^\/companies\/\d+/;
    const endOfMissionPattern = /\/end-of-mission/;
    const replacedUrl = `/${AppRoute.companies}/${companyId}`;
    const sanitized = HttpHelper.sanitizeUrl(this.router.url);
    // Remplace l'id de la company dans l'URL
    let companyRoute = companyPattern.test(sanitized) ? sanitized.replace(companyPattern, replacedUrl) : replacedUrl;
    // Supprime /end-of-mission dans l'url
    companyRoute = endOfMissionPattern.test(companyRoute)
      ? companyRoute.replace(endOfMissionPattern, '')
      : companyRoute;

    if (!openInNewTab) {
      this.navigate([companyRoute]);
    } else {
      this.openInNewTab([companyRoute]);
    }
  }

  navigateToCompanyIdentification(companyId: number): Promise<boolean> {
    return this.navigate([AppRoute.companies, companyId, CompanyRoute.parameters, ParametersRoute.identification]);
  }

  navigateToCreationRequestIfNeeded(company: Company, callback: (company: Company) => void): void {
    const legalPattern = /\/legal\/(creation\/|formalities\/\d+)/;
    const companyRoute = HttpHelper.sanitizeUrl(this.router.url);

    this.store
      .pipe(
        select(businessUserSelector),
        take(1),
        tap((businessUser: BusinessUser) => {
          if (
            company?.creationRequestClosed === false &&
            company?.creationRequestId &&
            businessUser.partner.legalAccess
          ) {
            void this.navigateToCreationRequest(company.id, company.creationRequestId);
          } else if (legalPattern.test(companyRoute)) {
            void this.navigateToCompanyIdentification(company.id);
            return;
          } else {
            callback(company);
          }
        })
      )
      .subscribe();
  }

  navigateToCreationRequest(companyId: number, creationRequestId: number): Promise<boolean> {
    return this.navigate([AppRoute.companies, companyId, CompanyRoute.legal, LegalRoute.formalities], {
      queryParams: { creationRequestId }
    });
  }

  navigateToCompanyReceipts(companyId: number): Promise<boolean> {
    return this.navigate([
      AppRoute.companies,
      companyId,
      CompanyRoute.preAccounting,
      PreAccountingRoute.purchases,
      PurchasesRoute.receipts
    ]);
  }

  navigateToCompanyDocuments(companyId: number): Promise<boolean> {
    return this.navigate([
      AppRoute.companies,
      companyId,
      CompanyRoute.documentsAndEditions,
      DocumentsAndEditionsRoute.documents
    ]);
  }

  navigateToAdministration(): Promise<boolean> {
    return this.navigate([AppRoute.admin]);
  }

  navigateToTimeTrackings(): Promise<boolean> {
    return this.navigate([AppRoute.timeTrackings]);
  }

  navigateToOpportunities(): Promise<boolean> {
    return this.navigate([AppRoute.opportunities]);
  }

  navigateToAdministrationBusinessUnits(): Promise<boolean> {
    return this.navigate([AppRoute.admin, AdminRoute.businessUnits]);
  }

  navigateToCompanyTaxReportInNewTab(companyId: number, extras: NavigationExtras): void {
    const commands = [AppRoute.companies, companyId, CompanyRoute.declarations, DeclarationsRoute.taxReport];
    this.openInNewTab(commands, extras);
  }

  navigateToCompanyWorkbookInNewTab(companyId: number, extras: NavigationExtras): void {
    const commands = [AppRoute.companies, companyId, CompanyRoute.workbook];
    this.openInNewTab(commands, extras);
  }

  navigateToCompanyOtherDeclarationsInNewTab(companyId: number, extras: NavigationExtras): void {
    const commands = [AppRoute.companies, companyId, CompanyRoute.declarations, DeclarationsRoute.otherDeclarations];
    this.openInNewTab(commands, extras);
  }

  navigateToCompanyOpportunitiesInNewTab(companyId: number, extras?: NavigationExtras): void {
    const commands = [AppRoute.companies, companyId, CompanyRoute.parameters, ParametersRoute.opportunities];
    this.openInNewTab(commands, extras);
  }

  updateQueryParams(queryParams: Params, activatedRoute: ActivatedRoute): void {
    this.navigate([], {
      relativeTo: activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      replaceUrl: true
    });
  }

  isCompanyDocumentsRouteActive(): Observable<boolean> {
    return this.store.select(companySelector).pipe(
      take(1),
      map((company: Company) => {
        const url = `/${AppRoute.companies}/${company.id}/${CompanyRoute.documentsAndEditions}/${DocumentsAndEditionsRoute.documents}`;
        return this.router.url.startsWith(url);
      })
    );
  }

  isCompanyExternalInvoicesRouteActive(): Observable<boolean> {
    return this.store.select(companySelector).pipe(
      take(1),
      map((company: Company) => {
        const url = `/${AppRoute.companies}/${company.id}/${CompanyRoute.preAccounting}/${PreAccountingRoute.sales}/${SalesRoute.externalInvoices}`;
        return this.router.url.startsWith(url);
      })
    );
  }

  navigateFromNotificationLink(link: string): Promise<boolean> {
    const notificationRouteAssociation = NOTIFICATION_ROUTE_ASSOCIATIONS.find(
      notification => !!notification.test(link)
    );

    if (!notificationRouteAssociation) {
      return Promise.resolve(false);
    }

    const splitedLink = link.split(NOTIFICATION_ROUTE_SEPARATOR);

    const routeCmds = notificationRouteAssociation.routerLink(splitedLink);

    // On fait un navigateByUrl pour permettre de refresh la vue si on veut naviguer sur la page sur laquelle on est
    this.navigateByUrl('/', { skipLocationChange: true }).then(() => this.navigate(routeCmds));
  }

  /**
   * Créer une navigation à partir de la route /companies/:companyId où companyId est l'id de la company sélectionnée dans le store
   *
   * @throws {Error} Lorsque la company n'a pas été sélectionnée dans le store
   */
  async navigateFromCurrentCompany(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const currentCompanyId: number = await this.store
      .select(companySelector)
      .pipe(
        take(1),
        map(company => company?.id)
      )
      .toPromise();

    if (!currentCompanyId) {
      throw new Error('No company selected in the store');
    }

    const path = NavigationService.buildPath(commands, currentCompanyId);

    return this.navigate(path, extras);
  }

  /**
   * Retourne la route avec la company actuellement sélectionnée
   *
   * @param commands Commandes à passer en plus du /companies/:company
   */
  getRouteFromCurrentCompany(commands: any[]): Observable<any[]> {
    return this.store.select(companySelector).pipe(
      filter(company => !!company),
      distinctUntilKeyChanged('id'),
      map<Company, string[]>(company => NavigationService.buildPath(commands, company.id))
    );
  }

  openInNewTabFromCurrentCompany(commands: any[], extras?: NavigationExtras): Observable<unknown> {
    return this.getRouteFromCurrentCompany(commands).pipe(tap((route: any[]) => this.openInNewTab(route, extras)));
  }

  openInNewTab(commands: any[], extras?: NavigationExtras): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(commands, extras));
    this.window.open(url, '_blank', 'noopener,noreferrer');
  }

  /**
   * Créer la route depuis la racine
   *
   * @param commands Commandes à passer en plus du /companies/:companyId
   * @param companyId L'id de la company
   * @private
   */
  private static buildPath(commands: any[], companyId: number): any[] {
    return ['/', AppRoute.companies, companyId, ...commands];
  }
}
