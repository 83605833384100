import Big from 'big.js';

export class ArithmeticHelper {
  static minus(number1: number, number2: number, dp: number = 2): number {
    return Number(
      Big(ArithmeticHelper.preventEmpty(number1)).minus(ArithmeticHelper.preventEmpty(number2)).toFixed(dp)
    );
  }

  static plus(number1: number, number2: number, dp: number = 2): number {
    return Number(Big(ArithmeticHelper.preventEmpty(number1)).plus(ArithmeticHelper.preventEmpty(number2)).toFixed(dp));
  }

  static div(number1: number, number2: number, dp: number = 2): number {
    return Number(Big(ArithmeticHelper.preventEmpty(number1)).div(ArithmeticHelper.preventEmpty(number2)).toFixed(dp));
  }

  static mul(number1: number, number2: number, dp: number = 2): number {
    return Number(Big(ArithmeticHelper.preventEmpty(number1)).mul(ArithmeticHelper.preventEmpty(number2)).toFixed(dp));
  }

  static round(number1: number, dp: number = 2): number {
    return Number(Big(ArithmeticHelper.preventEmpty(number1)).round(dp));
  }

  static toFloat(value: number, forceNumeric?: boolean): number;
  static toFloat(value: string | number, forceNumeric: false): string | number;
  static toFloat(value: string | number, forceNumeric: true): number;
  static toFloat(value: string | number, forceNumeric?: boolean): string | number;
  /**
   * Transforme les nombres sous forme de string en nombre
   *
   * @param value La valeur à parser
   * @param forceNumeric Défini si on veut absolument une valeur numérique (renvoi 0 sur les cas où la valeur n'est pas parsable)
   * @example ArithmeticHelper.toFloat("1202,01") => 1202.01
   */
  static toFloat(value: string | number = 0, forceNumeric: boolean = false): number | string {
    if (typeof value === 'number') {
      return value;
    }
    try {
      const result = parseFloat(value?.replace(/\s/gim, '').replace(',', '.'));
      if (Number.isNaN(result)) {
        return forceNumeric ? 0 : value;
      }
      return result;
    } catch (e) {
      return forceNumeric ? 0 : value;
    }
  }

  /**
   * Cast les integer sous forme de string en integer en number.
   *
   * ⚠️ Renvoi 0 dans le cas où la valeur est falsy ou invalide
   *
   * @param {string | number} value
   * @returns {number}
   */
  static toInteger(value: string | number = 0): number {
    if (typeof value === 'number') {
      return ArithmeticHelper.preventNaN(value);
    }

    try {
      const result = parseInt(value, 10);
      return ArithmeticHelper.preventNaN(result);
    } catch {
      return 0;
    }
  }

  private static preventNaN(value: number): number {
    if (Number.isNaN(value)) {
      return 0;
    }
    return value;
  }

  private static preventEmpty(value: number): number {
    return value || 0;
  }
}
