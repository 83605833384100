<div class="duplicated-user-container">
  <div tiimeProperties *ngFor="let duplicatedUser of duplicatedUsers; trackBy: trackByIndex">
    <div>
      <div tiimeProperty>
        <span class="nowrap">Dossier</span>
        <span class="italic ellipsis bold" tiimeFallbackText>
          {{ duplicatedUser.company?.name }}
        </span>
      </div>
      <div tiimeProperty>
        <span class="nowrap">SIRET</span>
        <span class="italic ellipsis" tiimeFallbackText>
          {{ duplicatedUser.company?.siret | mask: siretMask }}
        </span>
      </div>
      <div tiimeProperty>
        <span class="nowrap">Groupe</span>
        <span class="italic ellipsis" tiimeFallbackText>
          {{ duplicatedUser.company?.businessUnit?.name }}
        </span>
      </div>
    </div>
    <div>
      <div class="name-property" tiimeIconProperty>
        <mat-icon svgIcon="icon-user"></mat-icon>
        <span class="italic ellipsis" tiimeFallbackText>
          {{ duplicatedUser.fullName }}
        </span>
      </div>
      <div tiimeIconProperty>
        <mat-icon svgIcon="icon-phone"></mat-icon>
        <span class="italic ellipsis" tiimeFallbackText>
          {{ duplicatedUser.phone | phone }}
        </span>
      </div>
      <div tiimeIconProperty>
        <mat-icon svgIcon="icon-mail"></mat-icon>
        <span class="italic ellipsis" tiimeFallbackText>
          {{ duplicatedUser.email }}
        </span>
      </div>
    </div>
    <button
      class="action-btn"
      tiime-button
      color="accent"
      raised
      (click)="emitActionClicked(duplicatedUser)"
      *ngIf="displayAction"
    >
      Sélectionner
    </button>
  </div>
</div>
