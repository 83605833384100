import { Directive, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SelectBase } from './select.base';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FilteredSelectBase<T> extends SelectBase<T> {
  @Input('selectData') override set setSelectorData(selectData: T[]) {
    if (selectData) {
      this.selectorData = selectData;
      this.filteredSelectorData = selectData;
    }
  }

  filteredSelectorData: T[] = [];

  readonly searchControl: FormControl<string | null> = new FormControl(null);

  closeOptionsPanel(): void {
    this.searchControl.setValue('');
    this.searchControlChanges();
  }

  searchControlChanges(): void {
    this.filteredSelectorData = this.filterFunction(this.searchControl.value);
    this.selectComponent.focusFirstOption();
  }

  abstract filterFunction(search: string): T[];
}
