import { Activity, ActivityJson } from './activity';

export interface ActivitiesJson {
  activity_description?: string;
  activities?: ActivityJson[];
  create_without_activity?: boolean;
  itinerant_activity?: boolean;
  seasonal_activity?: boolean;
}

export class Activities {
  constructor(
    public activityDescription?: string,
    public activities?: Activity[],
    public createWithoutActivity?: boolean,
    public itinerantActivity?: boolean,
    public seasonalActivity?: boolean
  ) {}

  static fromJson(json: Partial<ActivitiesJson>): Activities {
    return new Activities(
      json.activity_description,
      json.activities ? json.activities.map((activityJson: ActivityJson) => Activity.fromJson(activityJson)) : null,
      json.create_without_activity,
      json.itinerant_activity,
      json.seasonal_activity
    );
  }
  
  static toJson(activities: Activities): ActivitiesJson {
    return {
      activity_description: activities.activityDescription,
      activities: activities.activities
        ? activities.activities.map((activity: Activity) => Activity.toJson(activity))
        : null,
      create_without_activity: activities.createWithoutActivity,
      itinerant_activity: activities.itinerantActivity,
      seasonal_activity: activities.seasonalActivity
    };
  }
}
