<tiime-input-container
  tiimeFormInputContainer
  contentId="date-input-cell"
  [attr.small]="small"
  [label]="placeholder"
  [errorMessage]="errorDisplayMode === 'text' && dateErrorMessage?.message"
>
  <input
    id="date-input-cell"
    type="text"
    [placeholder]="placeholder"
    [formControl]="ctrl"
    [min]="minDate"
    [max]="maxDate"
    appFormUpdateOnBlur
    autocomplete="off"
    (formChanges)="emitSave()"
    [matDatepicker]="dateInputDatePicker"
  />
  <mat-datepicker #dateInputDatePicker color="primary"></mat-datepicker>
</tiime-input-container>
<app-list-error
  *ngIf="errorDisplayMode === 'icon' && dateErrorMessage"
  [errorMessage]="dateErrorMessage"
></app-list-error>
