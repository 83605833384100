import { TaxRegimeCode } from '@enums/tax-regime-code.enum';

export interface FiscalOptionsJson {
  estimated_creation_date?: string;
  first_closing_date?: string;
  accounting_period_start_date?: string;
  accounting_period_end_date?: string;
  vat_system?: number;
  tax_regime?: TaxRegimeCode;
  special_vat_regime_option?: string;
  special_taxation_option?: string;
}

export class FiscalOptions {
  constructor(
    public estimatedCreationDate?: string,
    public firstClosingDate?: string,
    public accountingPeriodStartDate?: string,
    public accountingPeriodEndDate?: string,
    public vatSystem?: number,
    public taxRegime?: TaxRegimeCode,
    public specialVatRegimeOption?: string,
    public specialTaxationOption?: string
  ) {}

  static fromJson(json: Partial<FiscalOptionsJson>): FiscalOptions {
    return new FiscalOptions(
      json.estimated_creation_date,
      json.first_closing_date,
      json.accounting_period_start_date,
      json.accounting_period_end_date,
      json.vat_system,
      json.tax_regime,
      json.special_vat_regime_option,
      json.special_taxation_option
    );
  }
  static toJson(fiscalOptions: FiscalOptions): FiscalOptionsJson {
    return {
      estimated_creation_date: fiscalOptions.estimatedCreationDate,
      first_closing_date: fiscalOptions.firstClosingDate,
      accounting_period_start_date: fiscalOptions.accountingPeriodStartDate,
      accounting_period_end_date: fiscalOptions.accountingPeriodEndDate,
      vat_system: fiscalOptions.vatSystem,
      tax_regime: fiscalOptions.taxRegime,
      special_vat_regime_option: fiscalOptions.specialVatRegimeOption,
      special_taxation_option: fiscalOptions.specialTaxationOption
    };
  }
}
