<span class="first-user-name" *ngIf="users?.length > 0">
  {{ users[0].fullName }}
  <ng-container *ngIf="displayPhone && users[0].phone">({{ users[0].phone | phone }})</ng-container>
</span>
<span class="other-users-name" tiimeTooltip [tooltipContent]="otherUsersNameTooltipTemplate" *ngIf="users?.length > 1">
  +{{ users.length - 1 }}
</span>
<ng-template #otherUsersNameTooltipTemplate>
  {{ users | map: mapToOtherUsersFullName:displayPhone }}
</ng-template>
