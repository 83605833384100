<div class="form-container" tiimeForm>
  <div class="form-container-column" tiimeFormColumn>
    <span class="description">Paramétrage des labels</span>

    <tiime-checkbox-card [formControl]="parametersStepForm.showLabelPlanSelect">
      <ng-container cardHeader>Plan de labels</ng-container>
      <ng-container cardDescription>
        Le plan de labels permet d'importer une liste de labels déjà paramétrés. Les plans de labels sont gérés par les
        administrateurs du cabinet. Si vous optez pour cette option sans passer par l'import d'un fichier FEC, le
        paramétrage sera automatique et votre dossier ainsi que toutes ses fonctionnalités seront disponibles
        immédiatement.
        <a
          (click)="$event.stopPropagation()"
          href="https://tiime-expert.helpscoutdocs.com/article/304-creer-un-dossier"
          target="_blank"
          rel="noopener"
        >
          En savoir plus
        </a>
        <app-label-plan-select
          *ngIf="parametersStepForm.showLabelPlanSelect.value"
          (click)="$event.stopPropagation()"
          [ctrl]="parametersStepForm.controls.labelPlan"
          [labelsPlans]="labelsPlans"
          label="Choisir un plan de labels"
        ></app-label-plan-select>
      </ng-container>
    </tiime-checkbox-card>

    <tiime-checkbox-card [formControl]="parametersStepForm.showFecFileImport">
      <ng-container cardHeader>Importer un fichier FEC</ng-container>
      <ng-container cardDescription>
        Le fichier FEC permet d’en extraire les labels et leur schéma d’écriture en asynchrone. Pendant la durée de ce
        paramétrage, certaines fonctionnalités ne seront pas disponibles et l’état de votre dossier sera indiqué en
        cours de paramétrage.
        <a
          (click)="$event.stopPropagation()"
          href="https://tiime-expert.helpscoutdocs.com/article/304-creer-un-dossier"
          target="_blank"
          rel="noopener"
        >
          En savoir plus
        </a>
        <tiime-input-container
          *ngIf="parametersStepForm.showFecFileImport.value"
          tiimeFormInputContainer
          contentId="fecFile"
          class="fec-file-input"
          label="Importer un fichier"
          (click)="$event.stopPropagation(); file.click()"
        >
          <input id="fecFile" placeholder="Choisir un fichier" [value]="parametersStepForm.fecFile?.value?.name" />
          <div class="flex suffix">
            <button
              tiime-button
              icon
              color="primary"
              (click)="$event.stopPropagation(); resetFecFile()"
              [hidden]="!parametersStepForm.fecFile?.value?.name"
            >
              <mat-icon fontIcon="icon-ic-remove" inline="true"></mat-icon>
            </button>
            <button *ngIf="!parametersStepForm.fecFile?.value?.name" tiime-button icon color="primary">
              <mat-icon inline="true" fontIcon="icon-download-24"></mat-icon>
            </button>
            <mat-icon
              tiimeTooltip
              [tooltipContent]="fecTooltipTemplate"
              class="initialization-content-option-info"
              inline="true"
              fontIcon="icon-ic-info"
            ></mat-icon>
            <ng-template #fecTooltipTemplate>
              Le fichier FEC (provisoire ou définitif) est utilisé pour personnaliser les paramétrages de votre dossier.
              Si vous n’avez aucun FEC, vous pouvez également proposer un plan comptable.
              <br />
              <br />
              {{ acceptedTypesLabel }}
            </ng-template>
          </div>
          <input
            hidden
            #file
            id="file"
            type="file"
            [accept]="acceptedTypes"
            (change)="uploadFEC(file.files)"
            (abort)="$event.stopPropagation()"
            (cancel)="$event.stopPropagation()"
          />
        </tiime-input-container>
      </ng-container>
    </tiime-checkbox-card>

    <span class="description">Paramètrage du plan de catégories (facultatif)</span>
    <tiime-checkbox-card [formControl]="parametersStepForm.showCategorySetupPlanSelect">
      <ng-container cardHeader>Plan de catégories</ng-container>
      <ng-container cardDescription>
        Le plan de catégorie permet de paramétrer automatiquement les labels Tiime dès leur création par l'entrepreneur,
        vos collaborateurs ou les moteurs de Tiime.
        <a (click)="$event.stopPropagation(); openLabelCategoryPlanSetupDialog()">
          Accéder aux paramétrages des plans de catégories
        </a>
        <app-label-category-plan-select
          *ngIf="parametersStepForm.showCategorySetupPlanSelect.value"
          (click)="$event.stopPropagation()"
          emptyOption="Aucun"
          [ctrl]="parametersStepForm.controls.categorySetupPlan"
          [labelCategoryPlans]="labelCategoryPlans"
          [createOption]="false"
          label="Choisir un plan de catégories"
        ></app-label-category-plan-select>
      </ng-container>
    </tiime-checkbox-card>
  </div>
</div>
