import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';
import { CorporateTaxInstalment, CorporateTaxInstalmentJson } from '@models/corporate-tax-instalment';
import { TaskPlanning, TaskPlanningJson } from '@models/task-planning';
import { Transaction, TransactionJson } from '@models/transaction';
import { VatDeclarationConfiguration, VatDeclarationConfigurationJson } from '@models/vat-declaration-configuration';

export interface ThreadEntityJson {
  type: ThreadEntityType;
  accounting_period: Partial<AccountingPeriodJson>;
  vat_declaration: Partial<VatDeclarationConfigurationJson>;
  task: Partial<TaskPlanningJson>;
  bank_transaction: Partial<TransactionJson>;
  '2571': Partial<CorporateTaxInstalmentJson>;
}

export class ThreadEntity {
  constructor(
    public type?: ThreadEntityType,
    public accountingPeriod?: AccountingPeriod,
    public vatDeclaration?: VatDeclarationConfiguration,
    public task?: TaskPlanning,
    public bankTransaction?: Transaction,
    public corporateTaxInstalment?: CorporateTaxInstalment
  ) {}

  static fromJson(json: Partial<ThreadEntityJson>): ThreadEntity {
    return new ThreadEntity(
      json.type,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.vat_declaration ? VatDeclarationConfiguration.fromJson(json.vat_declaration) : null,
      json.task ? TaskPlanning.fromJson(json.task) : null,
      json.bank_transaction ? Transaction.fromJson(json.bank_transaction) : null,
      json[2571] ? CorporateTaxInstalment.fromJson(json[2571]) : null
    );
  }

  static toJson(threadEntity: ThreadEntity): Partial<ThreadEntityJson> {
    return {
      type: threadEntity.type || undefined,
      accounting_period: threadEntity.accountingPeriod ? HttpHelper.toJsonId(threadEntity.accountingPeriod) : undefined,
      vat_declaration: threadEntity.vatDeclaration ? HttpHelper.toJsonId(threadEntity.vatDeclaration) : undefined,
      task: threadEntity.task ? HttpHelper.toJsonId(threadEntity.task) : undefined,
      bank_transaction: threadEntity.bankTransaction ? HttpHelper.toJsonId(threadEntity.bankTransaction) : undefined,
      '2571': threadEntity.corporateTaxInstalment ? HttpHelper.toJsonId(threadEntity.corporateTaxInstalment) : undefined
    };
  }
}
