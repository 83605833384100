import { HttpHelper } from '@helpers/http.helper';
import { Contributor, ContributorJson } from '@models/contributor';
import { ContributorJob, ContributorJobJson } from '@models/contributor-job';

export interface CompanyContributorJson {
  id: number;
  user: Partial<ContributorJson>;
  partner_contributor_job: Partial<ContributorJobJson>;
}

export class CompanyContributor {
  constructor(
    public id?: number,
    public user?: Contributor,
    public job?: ContributorJob
  ) {}

  static fromJson(json: CompanyContributorJson): CompanyContributor {
    return new CompanyContributor(
      json.id,
      json.user ? Contributor.fromJson(json.user) : null,
      json.partner_contributor_job ? ContributorJob.fromJson(json.partner_contributor_job) : null
    );
  }

  static toJson(companyContributor: CompanyContributor): CompanyContributorJson {
    return {
      id: companyContributor.id || undefined,
      user: companyContributor.user?.id ? HttpHelper.toJsonId(companyContributor.user) : null,
      partner_contributor_job: companyContributor.job ? HttpHelper.toJsonId(companyContributor.job) : null
    };
  }
}
