import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiCommonService } from '@api-services/api-common.service';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { ContributorsFilters } from '@interfaces/contributors-filters';
import { Contributor, ContributorJson } from '@models/contributor';

@Injectable({
  providedIn: 'root'
})
export class ContributorApiService extends ApiCommonService<Contributor> {
  resourceUrl = `api/v1/expert/contributors`;

  fromJson(json: ContributorJson): Contributor {
    return Contributor.fromJson(json);
  }

  toJson(): unknown {
    // Not usable
    return null;
  }

  @ApiAlertError()
  getContributors(filters?: ContributorsFilters): Observable<Contributor[]> {
    let params = new HttpParams();
    if (filters?.searchTerms) {
      params = HttpHelper.setQParam(filters.searchTerms, params);
    }
    if (filters?.partnerId) {
      params = HttpHelper.setParam('partner', filters.partnerId.toString(), params);
    }
    if (filters?.businessUnitId) {
      params = HttpHelper.setParam('business_unit', filters.businessUnitId.toString(), params);
    }
    if (filters?.companyId) {
      params = HttpHelper.setParam('company', filters.companyId.toString(), params);
    }
    if (filters?.includeCurrentUser) {
      params = HttpHelper.setParam('with_myself', filters.includeCurrentUser.toString(), params);
    }

    return this.getAll({ params });
  }
}
