import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TiimeIconModule } from 'tiime-material';

import { CareContactDialogModule } from '@modules/shared/dialogs/care-contact-dialog/care-contact-dialog.module';
import { MaterialModule } from '@modules/shared/material/material.module';
import { BusinessUserSlugComponent } from '@shared-components/business-user-slug/business-user-slug.component';
import { KeyboardShortcutsDialogModule } from '@shared-dialogs/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.module';
import { HasRoleDirective } from '@shared-directives/has-role/has-role.directive';

import { BusinessUserAccountMenuOverlayComponent } from './business-user-account-menu-overlay/business-user-account-menu-overlay.component';
import { BusinessUserAccountMenuComponent } from './business-user-account-menu.component';

@NgModule({
  declarations: [BusinessUserAccountMenuComponent, BusinessUserAccountMenuOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    BusinessUserSlugComponent,
    CareContactDialogModule,
    KeyboardShortcutsDialogModule,
    RouterModule,
    TiimeIconModule,
    HasRoleDirective
  ],
  exports: [BusinessUserAccountMenuComponent]
})
export class BusinessUserAccountMenuModule {}
