export interface AccountingPeriodConfigJson {
  generate_a_nouveau: boolean;
  a_nouveau_detailed: boolean;
  a_nouveau_account_numbers: string[];
  generate_overturn: boolean;
  close_message: boolean;
  close_receipt: boolean;
  with_payment_informations: boolean;
  generate_ei_result_allocation: boolean;
}

export class CloseAccountingPeriodConfig {
  constructor(
    public generateANouveau: boolean,
    public aNouveauDetailed: boolean,
    public aNouveauAccountNumbers: string[],
    public generateOverturn: boolean,
    public closeMessage: boolean,
    public closeReceipt: boolean,
    public withPaymentInformations: boolean,
    public generateEiResultAllocation: boolean
  ) {}

  static fromJson(json: AccountingPeriodConfigJson): CloseAccountingPeriodConfig {
    return new CloseAccountingPeriodConfig(
      json.generate_a_nouveau,
      json.a_nouveau_detailed,
      json.a_nouveau_account_numbers,
      json.generate_overturn,
      json.close_message,
      json.close_receipt,
      json.with_payment_informations,
      json.generate_ei_result_allocation
    );
  }

  static toJson(model: CloseAccountingPeriodConfig): AccountingPeriodConfigJson {
    return {
      generate_a_nouveau: model.generateANouveau,
      a_nouveau_detailed: model.aNouveauDetailed,
      a_nouveau_account_numbers: model.aNouveauAccountNumbers,
      generate_overturn: model.generateOverturn,
      close_message: model.closeMessage,
      close_receipt: model.closeReceipt,
      with_payment_informations: model.withPaymentInformations,
      generate_ei_result_allocation: model.generateEiResultAllocation
    };
  }
}
