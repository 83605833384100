export interface CompanyRegistryJson {
  id?: number;
  rcs_city?: string;
  address?: string;
  postal_code?: string;
  website?: string;
  email?: string;
  phone?: string;
}

export class CompanyRegistry {
  constructor(
    public id?: number,
    public rcsCity?: string,
    public address?: string,
    public postalCode?: string,
    public website?: string,
    public email?: string,
    public phone?: string
  ) {}

  static fromJson(json: Partial<CompanyRegistryJson>): CompanyRegistry {
    return new CompanyRegistry(
      json.id,
      json.rcs_city,
      json.address,
      json.postal_code,
      json.website,
      json.email,
      json.phone
    );
  }
  static toJson(companyRegistry: CompanyRegistry): CompanyRegistryJson {
    return {
      id: companyRegistry.id,
      rcs_city: companyRegistry.rcsCity,
      address: companyRegistry.address,
      postal_code: companyRegistry.postalCode,
      website: companyRegistry.website,
      email: companyRegistry.email,
      phone: companyRegistry.phone
    };
  }
}
