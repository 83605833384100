import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { TiimeSnackbarService } from 'tiime-material';

import { AppStoreState } from '@interfaces/app-store-state';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import { businessUserCanAccessToAdministrationSelector } from '@modules/core/store/business-user/business-user-selector';

import { BusinessUserGuard } from './business-user.guard';

@Injectable({
  providedIn: 'root'
})
export class AdministrationGuard {
  constructor(
    private store: Store<AppStoreState>,
    private navigationService: NavigationService,
    private snackbar: TiimeSnackbarService,
    private businessUserGuard: BusinessUserGuard
  ) {}

  canActivate(): Observable<boolean> {
    return this.businessUserGuard.canActivate().pipe(switchMap(() => this.canAccessToAdministration()));
  }

  canLoad(): Observable<boolean> {
    return this.businessUserGuard.canLoad().pipe(switchMap(() => this.canAccessToAdministration()));
  }

  private canAccessToAdministration(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserCanAccessToAdministrationSelector),
      take(1),
      map((canAccessToAdministration: boolean) => {
        if (!canAccessToAdministration) {
          this.snackbar.openError(`Accès refusé`);
          this.navigationService.navigateToUnauthorized();
        }
        return canAccessToAdministration;
      })
    );
  }
}
