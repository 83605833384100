import { DocumentCategoryIdentifier } from '@enums/document-category-identifier.enum';
import { DocumentCategoryOwner } from '@enums/document-category-owner.enum';

export interface DocumentCategoryJson {
  id: number;
  identifier: DocumentCategoryIdentifier;
  name: string;
  owner: DocumentCategoryOwner;
}

export class DocumentCategory {
  constructor(
    public id?: number,
    public identifier?: DocumentCategoryIdentifier,
    public name?: string,
    public owner?: DocumentCategoryOwner
  ) {}

  public static fromJson(json: DocumentCategoryJson): DocumentCategory {
    return new DocumentCategory(json.id, json.identifier, json.name, json.owner);
  }

  public static toJson(category: DocumentCategory): DocumentCategoryJson {
    return {
      id: category.id,
      identifier: category.identifier,
      name: category.name,
      owner: category.owner
    };
  }
}
