import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ListComponent,
  ListItemActionsDirective,
  ListItemComponent,
  ListItemIconDirective,
  ListItemTitleDirective
} from './list';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ListComponent,
    ListItemComponent,
    ListItemIconDirective,
    ListItemTitleDirective,
    ListItemActionsDirective
  ],
  exports: [ListComponent, ListItemComponent, ListItemIconDirective, ListItemTitleDirective, ListItemActionsDirective]
})
export class TiimeListModule {}
