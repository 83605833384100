import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  WritableSignal,
  signal
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { Mapper, MarkFormAsTouchedIfInvalid, NgUtils, TiimePipesModule } from 'tiime-expert-utils';
import {
  TableAnchorComponent,
  TiimeButtonModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeTableModule,
  TiimeTooltipModule
} from 'tiime-material';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadHelper } from '@helpers/thread.helper';
import { BusinessUser } from '@models/business-user';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { Message } from '@models/message';
import { Thread } from '@models/thread';
import { ThreadTag } from '@models/thread-tag';
import { CompanyContactsComponent } from '@shared-components/company-contacts/company-contacts.component';
import { MessageComponent } from '@shared-components/message/message.component';
import { ThreadBadgeComponent } from '@shared-components/thread-badge/thread-badge.component';
import { ThreadEntityTypeComponent } from '@shared-components/thread-entity-type/thread-entity-type.component';
import { ContributorSelectMultipleComponent } from '@shared-selectors/contributor-select-multiple/contributor-select-multiple.component';
import { ThreadTagSelectMultipleComponent } from '@shared-selectors/thread-tag-select-multiple/thread-tag-select-multiple.component';

export interface UpdateMessageOutput {
  message: Message;
  messageContent: string;
}

@Component({
  selector: 'app-thread',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeTableModule,
    TiimeInputContainerModule,
    TiimeButtonModule,
    TextFieldModule,
    MatMenuModule,
    TiimeIconModule,
    TiimePipesModule,
    CompanyContactsComponent,
    MessageComponent,
    ThreadBadgeComponent,
    TiimeTooltipModule,
    ThreadEntityTypeComponent,
    ContributorSelectMultipleComponent,
    ThreadTagSelectMultipleComponent
  ],
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadComponent implements AfterViewInit {
  @Input({ required: true }) thread: Thread;
  @Input({ required: true }) currentBusinessUser: BusinessUser;
  @Input({ required: true }) contributors: Contributor[];
  @Input({ required: true }) tags: ThreadTag[];
  @Input() companyContributors: CompanyContributor[];
  @Input() expanded = false;

  @Output() readonly createMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly closeThread: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly archiveThread: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly updateInterlocutors: EventEmitter<Contributor[]> = new EventEmitter<Contributor[]>();
  @Output() readonly updateTags: EventEmitter<ThreadTag[]> = new EventEmitter<ThreadTag[]>();
  @Output() readonly updateMessageContent: EventEmitter<UpdateMessageOutput> = new EventEmitter<UpdateMessageOutput>();
  @Output() readonly messagesClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(TableAnchorComponent) tableAnchorComponent: TableAnchorComponent;
  @ViewChild('textarea') textarea: ElementRef;

  readonly messageControl = new FormControl<string | undefined>(undefined, Validators.required);
  readonly trackById = NgUtils.trackById;
  readonly ThreadEntityType = ThreadEntityType;
  readonly ThreadCategory = ThreadCategory;
  readonly interlocutorsEditMode: WritableSignal<boolean> = signal(false);
  readonly interlocutorsHovered: WritableSignal<boolean> = signal(false);
  readonly interlocutorsControl: FormControl<Contributor[]> = new FormControl<Contributor[]>([]);
  readonly tagsEditMode: WritableSignal<boolean> = signal(false);
  readonly tagsHovered: WritableSignal<boolean> = signal(false);
  readonly tagsControl: FormControl<ThreadTag[]> = new FormControl<ThreadTag[]>([]);

  readonly mapToDisplayedMessages: Mapper<Message[], Message[]> = (messages: Message[], expanded: boolean) =>
    expanded || messages?.length === 1 ? messages : [messages[0], messages[messages.length - 1]];
  readonly mapToColorClass: Mapper<Thread, { opened: boolean; opened_and_concerns_user: boolean }> = (
    thread: Thread,
    currentBusinessUserId: number
  ) => ({
    opened: ThreadHelper.isOpened(thread),
    opened_and_concerns_user: ThreadHelper.isOpened(thread) && ThreadHelper.concernsUser(thread, currentBusinessUserId)
  });

  ngAfterViewInit(): void {
    if (this.expanded) {
      this.scrollToBottom();
      this.focusTextarea();
    }
  }

  @MarkFormAsTouchedIfInvalid('messageControl')
  emitCreateMessage(): void {
    this.createMessage.emit(this.messageControl.value);
  }

  emitCloseThread(closed: boolean): void {
    this.closeThread.emit(closed);
  }

  emitArchiveThread(archived: boolean): void {
    this.archiveThread.emit(archived);
  }

  emitUpdateMessageContent(message: Message, messageContent: string): void {
    this.updateMessageContent.emit({ message, messageContent });
  }

  emitUpdateInterlocutors(): void {
    this.updateInterlocutors.emit(this.interlocutorsControl.value);
    this.setInterlocutorsEditMode(false);
  }

  emitUpdateTags(): void {
    this.updateTags.emit(this.tagsControl.value);
    this.setTagsEditMode(false);
  }

  emitMessagesClicked(): void {
    this.messagesClicked.emit();
  }

  setInterlocutorsEditMode(nextValue: boolean): void {
    this.interlocutorsControl.reset();
    if (nextValue === true) {
      this.setInterlocutorsControl();
    }
    this.interlocutorsEditMode.set(nextValue);
  }

  setTagsEditMode(nextValue: boolean): void {
    this.tagsControl.reset();
    if (nextValue === true) {
      this.setTagsControl();
    }
    this.tagsEditMode.set(nextValue);
  }

  private scrollToBottom(): void {
    this.tableAnchorComponent?.scrollTo();
  }

  private focusTextarea(): void {
    this.textarea?.nativeElement.focus();
  }

  private setInterlocutorsControl(): void {
    this.interlocutorsControl.setValue([...this.thread.interlocutors]);
  }

  private setTagsControl(): void {
    this.tagsControl.setValue([...this.thread.tags]);
  }
}
