import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tiime-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TiimeSidenavLayoutComponent {
  @Input() disableToogle = false;
  @Input() opened = true;

  @Output() readonly toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  onToggleSidenav(): void {
    this.opened = !this.opened;
    this.toggle.emit(this.opened);
    this.cdr.markForCheck();
  }
}
