import { AccountantDetailRequestType } from '@enums/accountant-detail-request-type.enum';
import { AccountingStatus } from '@enums/accounting-status.enum';
import { OperationType } from '@enums/operation-type.enum';
import { TransactionOwner } from '@enums/transaction-owner.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { AccountantDetailRequest, AccountantDetailRequestJson } from '@models/accountant-detail-request';
import { BankAccount, BankAccountJson } from '@models/bank-account';
import { Document, DocumentJson } from '@models/document';
import { Imputation, ImputationJson } from '@models/imputation';
import { Invoice, InvoiceJson } from '@models/invoice';
import { Journal, JournalJson } from '@models/journal';
import { Thread, ThreadJson } from '@models/thread';
import { TransactionBase } from '@models/transaction-base';

export interface TransactionJson {
  id: number;
  transaction_date: string;
  realization_date: string;
  original_wording: string;
  wording: string;
  amount: number;
  currency: string;
  accounting_status: AccountingStatus;
  owner: TransactionOwner;
  operation_type: OperationType;
  journal: Partial<JournalJson>;
  vat_application_date: string;
  invoices: Partial<InvoiceJson>[];
  documents: Partial<DocumentJson>[];
  accountant_detail_requests: Partial<AccountantDetailRequestJson>[];
  bank_account: Partial<BankAccountJson>;
  comment: string;
  imputations: Partial<ImputationJson>[];
  threads: Partial<ThreadJson>[];
}

export class Transaction extends TransactionBase {
  constructor(
    public id?: number,
    public transactionDate?: string,
    public realizationDate?: string,
    public originalWording?: string,
    public wording?: string,
    public amount?: number,
    public currency?: string,
    public accountingStatus?: AccountingStatus,
    public owner?: TransactionOwner,
    public operationType?: OperationType,
    public journal?: Journal,
    public vatApplicationDate?: string,
    public invoices?: Invoice[],
    public documents?: Document[],
    accountantDetailRequests?: AccountantDetailRequest[],
    public bankAccount?: BankAccount,
    public comment?: string,
    public imputations?: Imputation[],
    public threads?: Thread[]
  ) {
    super(accountantDetailRequests);
  }

  static fromJson(json: Partial<TransactionJson>): Transaction {
    return new Transaction(
      json.id,
      json.transaction_date,
      json.realization_date,
      json.original_wording,
      json.wording,
      json.amount,
      json.currency,
      json.accounting_status,
      json.owner,
      json.operation_type,
      json.journal ? Journal.fromJson(json.journal) : null,
      json.vat_application_date,
      json.invoices ? json.invoices.map(invoiceJson => Invoice.fromJson(invoiceJson)) : [],
      json.documents ? json.documents.map(documentJson => Document.fromJson(documentJson)) : [],
      json.accountant_detail_requests
        ? json.accountant_detail_requests
            // @ts-ignore On ne prend que les DQST de type receipt
            .filter(({ type }) => type === AccountantDetailRequestType.receipt)
            .map(accountantDetailRequest => AccountantDetailRequest.fromJson(accountantDetailRequest))
        : [],
      json.bank_account ? BankAccount.fromJson(json.bank_account) : null,
      json.comment,
      json.imputations
        ? json.imputations.length === 0
          ? [new Imputation()]
          : json.imputations.map(imputationJson => Imputation.fromJson(imputationJson))
        : null,
      json.threads ? json.threads.map(threadJson => Thread.fromJson(threadJson)) : []
    );
  }

  static toJson(
    transaction: Transaction
  ): Pick<
    TransactionJson,
    | 'id'
    | 'transaction_date'
    | 'vat_application_date'
    | 'bank_account'
    | 'wording'
    | 'amount'
    | 'operation_type'
    | 'imputations'
  > {
    return {
      id: transaction.id || undefined,
      transaction_date: transaction.transactionDate ? DateHelper.format(transaction.transactionDate) : undefined,
      vat_application_date: transaction.vatApplicationDate
        ? DateHelper.format(transaction.vatApplicationDate)
        : undefined,
      bank_account: transaction.bankAccount ? HttpHelper.toJsonId(transaction.bankAccount) : undefined,
      wording: transaction.wording,
      amount: transaction.amount,
      operation_type: transaction.operationType || undefined,
      imputations:
        transaction.imputations && transaction.imputations.length > 0
          ? transaction.imputations
              .filter((imputation: Imputation) => !!imputation.label)
              .map((imputation: Imputation) => Imputation.toJson(imputation))
          : undefined
    };
  }
}
