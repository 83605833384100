import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

type ElementType = string | ElementRef | HTMLElement;

@Directive({
  selector: '[tiimeStickyTable]',
  exportAs: 'tiime-sticky-table'
})
export class StickyTableDirective implements AfterViewInit, OnDestroy {
  /**
   * It must be a top container of the sticky element and of the element that will trigger the custom class
   * on the sticky element.
   * This should be the element with the scroll
   * If an string is provided, it must be the ID of the element.
   */
  @Input()
  get scrollContainer(): ElementType {
    return this._scrollContainer;
  }
  set scrollContainer(value: ElementType) {
    this.setHTMLElement('_scrollContainer', value);
  }

  @Output() readonly bottomIntersecting: EventEmitter<void> = new EventEmitter<void>();

  private get nativeTableHeaderElementToStick(): HTMLElement {
    return this.tableElement.nativeElement.getElementsByClassName('cdk-header-row')[0];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _scrollContainer: HTMLElement;
  private topSentinel: HTMLElement;
  private topObserver: IntersectionObserver;
  private bottomSentinel: HTMLElement;
  private bottomObserver: IntersectionObserver;

  constructor(private tableElement: ElementRef) {}

  ngOnDestroy(): void {
    this.cleanObservers();
  }

  updateObserversAndSentinels(): void {
    this.makeStickyHeader();
    this.makeStickyFooter();
    this.putTopSentinel();
    this.setTopObserver();
    this.putBottomSentinel();
    this.setBottomObserver();
  }

  ngAfterViewInit(): void {
    this.updateObserversAndSentinels();
  }

  /**
   * Transform if needed into HTMLElement a value given to be set in a property
   */
  private setHTMLElement(prop: string, value: ElementType): void {
    if (typeof value === 'string') {
      this[prop] = document.getElementById(value);
    } else if (value instanceof ElementRef) {
      this[prop] = value.nativeElement;
    } else {
      this[prop] = value;
    }
  }

  private makeStickyHeader(): void {
    const nativeElement: HTMLElement = this.tableElement.nativeElement.getElementsByTagName('thead')[0];
    if (nativeElement) {
      nativeElement.classList.add('sticky');
    }
  }

  private makeStickyFooter(): void {
    const nativeElement: HTMLElement = this.tableElement.nativeElement.getElementsByTagName('tfoot')[0];
    if (nativeElement) {
      nativeElement.classList.add('sticky');
    }
  }

  /**
   * Start listening to the scroll event on the container element
   */
  private setTopObserver(): void {
    if (!this.topSentinel) {
      return;
    }

    const formerObserver = this.topObserver;
    const observer = new IntersectionObserver(records => this.onTopAppears(records), {
      threshold: [0],
      root: this.scrollContainer as HTMLElement
    });

    // Add the top sentinels to the table and observe interactions.
    observer.observe(this.topSentinel);

    this.topObserver = observer;
    if (formerObserver) {
      formerObserver.disconnect();
    }
  }

  /**
   * Start listening to the scroll event on the container element
   */
  private setBottomObserver(): void {
    setTimeout(() => {
      if (!this.bottomSentinel) {
        return;
      }

      const formerObserver = this.bottomObserver;
      const observer = new IntersectionObserver(records => this.onBottomAppears(records), {
        threshold: [0],
        root: this.scrollContainer as HTMLElement
      });

      // Add the bottom sentinels to each section and attach an observer.
      observer.observe(this.bottomSentinel);

      this.bottomObserver = observer;
      if (formerObserver) {
        formerObserver.disconnect();
      }
    }, 500);
  }

  /**
   * Add/Remove class to the target element when the sentinel element disappear/appear
   */
  private onTopAppears(records: IntersectionObserverEntry[]): void {
    for (const record of records) {
      const targetInfo = record.boundingClientRect;
      const rootBoundsInfo = record.rootBounds;
      if (!record.rootBounds) {
        return;
      }
      if (targetInfo.bottom < rootBoundsInfo.top) {
        this.nativeTableHeaderElementToStick.classList.add('bottom-shadow');
      }

      if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom) {
        this.nativeTableHeaderElementToStick.classList.remove('bottom-shadow');
      }
    }
  }

  /**
   * Add/Remove class to the target element when the sentinel element disappear/appear
   */
  private onBottomAppears(records: IntersectionObserverEntry[]): void {
    for (const record of records) {
      if (record.isIntersecting) {
        this.bottomIntersecting.emit();
      }
    }
  }

  /**
   * Generates the top sentinel element with the necessary styles
   */
  private generateTopSentinelElement(): HTMLElement {
    const sentinelEl = document.createElement('div');
    sentinelEl.style.height = '30px';
    sentinelEl.style.width = '100%';
    sentinelEl.style.top = '-30px';
    sentinelEl.style.position = 'absolute';
    sentinelEl.style.visibility = 'hidden';

    return sentinelEl;
  }

  /**
   * Generates the bottom sentinel element with the necessary styles
   */
  private generateBottomSentinelElement(): HTMLElement {
    const sentinelEl = document.createElement('div');
    sentinelEl.style.height = '30px';
    sentinelEl.style.width = '100%';
    sentinelEl.style.bottom = '0';
    sentinelEl.style.position = 'absolute';
    sentinelEl.style.visibility = 'hidden';

    return sentinelEl;
  }

  /**
   * Add the sentinel element as the first child of the table element
   */
  private putTopSentinel(): void {
    const formerTopSentinel = this.topSentinel;
    const sentinel = this.generateTopSentinelElement();
    this.topSentinel = this.tableElement.nativeElement
      .getElementsByTagName('tbody')[0]
      .insertAdjacentElement('afterbegin', sentinel) as HTMLElement;
    if (formerTopSentinel) {
      formerTopSentinel.remove();
    }
  }

  /**
   * Add the sentinel element as the last child of the table element
   */
  private putBottomSentinel(): void {
    const formerBottomSentinel = this.bottomSentinel;
    const sentinel = this.generateBottomSentinelElement();
    this.bottomSentinel = this.tableElement.nativeElement
      .getElementsByTagName('tbody')[0]
      .insertAdjacentElement('beforeend', sentinel) as HTMLElement;
    if (formerBottomSentinel) {
      formerBottomSentinel.remove();
    }
  }

  private cleanObservers(): void {
    if (this.bottomObserver) {
      this.bottomObserver.disconnect();
    }
    if (this.topObserver) {
      this.topObserver.disconnect();
    }
  }
}
