import { TaxReductionCreditType } from '@enums/tax-reduction-credit-type.enum';

export interface FiscalResultTaxReductionCreditTypeJson {
  id: number;
  code: string;
  label: string;
  type: TaxReductionCreditType;
}

export class FiscalResultTaxReductionCreditType {
  constructor(
    public id: number,
    public code: string,
    public label: string,
    public type: TaxReductionCreditType
  ) {}

  static fromJson(json: Partial<FiscalResultTaxReductionCreditTypeJson>): FiscalResultTaxReductionCreditType {
    return new FiscalResultTaxReductionCreditType(json.id, json.code, json.label, json.type);
  }
}
