export enum PieceType {
  stock = 'stock',
  bankTransaction = 'bank_transaction',
  receipt = 'receipt',
  invoice = 'invoice',
  immobilisation = 'immobilisation',
  loan = 'loan',
  manualEntry = 'manual_entry',
  resultAllocation = 'result_allocation',
  invoiceToEstablish = 'invoice_to_establish',
  invoiceNotReceived = 'invoice_not_received',
  prepaidExpense = 'prepaid_expense',
  prepaidIncome = 'prepaid_income',
  vatDeclaration = 'vat_declaration',
  expenseReport = 'expense_report',
  payPeriod = 'pay_period',
  cashRegister = 'cash_register_cash_flow',
  externalInvoice = 'external_invoice',
  tns = 'tns',
  corporateTax = 'corporate_tax',
  leaseContract = 'lease_contract',
  vatExigibility = 'vat_exigibility'
}
