import { Directive } from '@angular/core';

@Directive({
  selector: '[tiimeNav]',
  exportAs: 'tiime-nav',
  host: {
    class: 'tiime-nav'
  }
})
export class NavDirective {}
