import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ErrorMessage } from '@interfaces/error-message';
import { LabelCategoryPlan } from '@models/label-category-plan';

export type LabelCategoryPlanFormType = {
  id: FormControl<number | undefined>;
  label: FormControl<string | null>;
};

export class LabelCategoryPlanForm extends FormGroup<LabelCategoryPlanFormType> {
  get labelErrorMessage(): ErrorMessage {
    return this.controls.label.touched && this.controls.label.hasError('required')
      ? {
          error: 'Champ vide',
          message: `Le nom ne peut être vide.`
        }
      : null;
  }

  constructor() {
    super({
      id: new FormControl<number | undefined>(undefined),
      label: new FormControl<string | null>(null, Validators.required)
    });
  }

  fromLabelCategoryPlan(labelCategoryPlan: LabelCategoryPlan): void {
    this.patchValue(labelCategoryPlan);
  }

  toLabelCategoryPlan(): LabelCategoryPlan {
    return new LabelCategoryPlan(this.controls.id.value, this.controls.label.value);
  }
}
