import { AsyncProcessType } from '@enums/async-process-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { AccountingPeriod } from '@models/accounting-period';

type SortDirection = 1 | -1;

export class AccountingPeriodHelper {
  static findLast(accountingPeriods: AccountingPeriod[] = []): AccountingPeriod | undefined {
    return AccountingPeriodHelper.sort([...accountingPeriods])[accountingPeriods.length - 1];
  }

  static findFirstClosed(accountingPeriods: AccountingPeriod[] = []): AccountingPeriod | undefined {
    return AccountingPeriodHelper.sort([...accountingPeriods]).find(accountingPeriod => accountingPeriod.closed);
  }

  static findLastClosed(accountingPeriods: AccountingPeriod[] = []): AccountingPeriod | undefined {
    return AccountingPeriodHelper.sort([...accountingPeriods])
      .reverse()
      .find(accountingPeriod => accountingPeriod.closed);
  }

  static findFirstOpened(accountingPeriods: AccountingPeriod[] = []): AccountingPeriod | undefined {
    return AccountingPeriodHelper.sort([...(accountingPeriods ?? [])]).find(
      accountingPeriod => !accountingPeriod.closed || accountingPeriod.asyncProcessType === AsyncProcessType.closing
    );
  }

  /**
   * Trie les accountingPeriods sur la date de début
   */
  static sort(accountingPeriods: AccountingPeriod[], reverse: boolean = false): AccountingPeriod[] {
    const before: SortDirection = reverse ? 1 : -1;
    const after: SortDirection = reverse ? -1 : 1;
    return accountingPeriods.sort((a, b) => (DateHelper.isBefore(a.endDate, b.endDate) ? before : after));
  }

  static findForDate(accountingPeriods: AccountingPeriod[], date: string): AccountingPeriod | undefined {
    return accountingPeriods.find(period => DateHelper.isBetween(date, period.startDate, period.endDate));
  }

  static findLastMonthOpened(accountingPeriods: AccountingPeriod[]): AccountingPeriod | undefined {
    const lastMonthOpen = this.findForDate(accountingPeriods, DateHelper.format(DateHelper.firstDayOfLastMonth()));
    return lastMonthOpen && !lastMonthOpen.closed ? lastMonthOpen : undefined;
  }

  static findFirstBeforeCurrentDate(accountingPeriods: AccountingPeriod[]): AccountingPeriod | undefined {
    return accountingPeriods.find((accountingPeriod: AccountingPeriod) =>
      DateHelper.isBefore(accountingPeriod.startDate, DateHelper.currentDate())
    );
  }
}
