import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { NgUtils } from 'tiime-expert-utils';

@Component({
  selector: 'tiime-tab-title',
  template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TiimeTabTitleComponent {
  @ViewChild(TemplateRef) title: TemplateRef<unknown>;
}

@Component({
  selector: 'tiime-tab',
  template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TiimeTabComponent {
  @Input() label?: string;

  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: BooleanInput) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  @ViewChild(TemplateRef) content: TemplateRef<unknown>;
  @ContentChild(TiimeTabTitleComponent) titleComponent?: TiimeTabTitleComponent;

  get title(): TemplateRef<unknown> | undefined {
    return this.titleComponent?.title;
  }
}

@Component({
  selector: 'tiime-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-tabs'
  }
})
export class TiimeTabsComponent implements AfterViewInit {
  @ContentChildren(TiimeTabComponent) tabs: QueryList<TiimeTabComponent>;

  @Output() readonly selectedTabChange: EventEmitter<TiimeTabComponent> = new EventEmitter<TiimeTabComponent>();

  activeTab: TiimeTabComponent;

  readonly trackByIndex = NgUtils.trackByIndex;

  ngAfterViewInit(): void {
    if (!this.activeTab) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TiimeTabComponent): void {
    if (tab.disabled) {
      return;
    }
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      this.selectedTabChange.emit(tab);
    }
  }
}
