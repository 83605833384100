import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[tiimeListItemIcon]',
  exportAs: 'tiime-list-item-icon',
  host: {
    class: 'tiime-list-item-icon'
  }
})
export class ListItemIconDirective {}

@Directive({
  selector: '[tiimeListItemTitle]',
  exportAs: 'tiime-list-item-title',
  host: {
    class: 'tiime-list-item-title'
  }
})
export class ListItemTitleDirective {}

@Directive({
  selector: '[tiimeListItemActions]',
  exportAs: 'tiime-list-item-actions',
  host: {
    class: 'tiime-list-item-actions'
  }
})
export class ListItemActionsDirective {}

@Component({
  selector: 'tiime-list-item',
  templateUrl: './list-item.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-list-item'
  }
})
export class ListItemComponent {}

@Component({
  selector: 'tiime-list',
  templateUrl: './list.component.html',
  styleUrls: ['list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-list'
  }
})
export class ListComponent {}
