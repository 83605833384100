<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="cancel()" data-cy="legal-create-creation-request-dialog">
  <ng-container *ngIf="!submitLoading; else submitLoadingTemplate">
    <ng-container *ngIf="!showCreationSupervisor; else creationSupervisor">
      <span mat-dialog-title data-cy="legal-create-creation-request-dialog-title">Initier une création</span>
      <mat-dialog-content class="dialog-content" mat-dialog-content [formGroup]="createCreationRequestForm">
        <tiime-form data-cy="legal-create-creation-request-dialog-contact">
          <div tiimeFormTitle>Contact</div>
          <div tiimeForm>
            <div tiimeFormRow>
              <tiime-input-container
                contentId="userFirstName"
                label="Prénom"
                required="true"
                [errorMessage]="createCreationRequestForm.userFirstNameErrorMessage"
              >
                <input
                  autocomplete="off"
                  #userFirstNameInput
                  id="userFirstName"
                  placeholder="Prénom"
                  formControlName="userFirstName"
                  data-cy="legal-create-creation-request-dialog-user-first-name"
                  appCapitalizeInput
                />
              </tiime-input-container>
              <tiime-input-container
                contentId="userLastName"
                label="Nom"
                required="true"
                [errorMessage]="createCreationRequestForm.userLastNameErrorMessage"
              >
                <input
                  autocomplete="off"
                  id="userLastName"
                  placeholder="Nom"
                  formControlName="userLastName"
                  data-cy="legal-create-creation-request-dialog-user-last-name"
                  appUppercaseInput
                />
              </tiime-input-container>
            </div>
          </div>
        </tiime-form>
        <tiime-form data-cy="legal-create-creation-request-dialog-company-informations">
          <div tiimeFormTitle>Informations Entreprise</div>
          <div tiimeForm>
            <div tiimeFormColumn class="show-error">
              <div tiimeFormRow>
                <tiime-input-container
                  contentId="companyName"
                  label="Dénomination sociale"
                  required="true"
                  [errorMessage]="createCreationRequestForm.companyNameErrorMessage"
                >
                  <input
                    autocomplete="off"
                    id="companyName"
                    placeholder="Dénomination sociale"
                    formControlName="companyName"
                    data-cy="legal-create-creation-request-dialog-company-name"
                  />
                </tiime-input-container>
                <div class="generate-button">
                  <button
                    tiime-button
                    color="primary"
                    [disabled]="
                      createCreationRequestForm.userLastName.value
                        | map: mapToDisableGenerateButton : createCreationRequestForm.userFirstName.value
                    "
                    strocked
                    (click)="generateCompanyName()"
                    data-cy="legal-create-creation-request-dialog-generate-button"
                  >
                    Générer
                  </button>
                </div>
              </div>
              <div tiimeFormRow>
                <tiime-input-container
                  contentId="companyReference"
                  label="Numéro de dossier"
                  [required]="companyReferenceMandatory"
                  [errorMessage]="createCreationRequestForm.companyReferenceErrorMessage"
                >
                  <input
                    autocomplete="off"
                    id="companyReference"
                    placeholder="Numéro de dossier"
                    formControlName="companyReference"
                    appUppercaseInput
                  />
                </tiime-input-container>
                <app-business-unit-select
                  tiimeFormInputContainer
                  [required]="true"
                  [selectData]="data.businessUnits"
                  [control]="createCreationRequestForm.businessUnit"
                  [errorMessage]="createCreationRequestForm.businessUnitErrorMessage"
                ></app-business-unit-select>
                <app-legal-form-select
                  tiimeFormInputContainer
                  [selectData]="supportedLegalForms"
                  [required]="true"
                  [control]="createCreationRequestForm.legalForm"
                  [errorMessage]="createCreationRequestForm.legalFormErrorMessage"
                ></app-legal-form-select>
              </div>
              <tiime-input-container
                *ngIf="userPartnerId | map: mapToUserIsNotNumbr"
                tiimeFormInputContainer
                contentId="hasLegalPerson"
                label="Personne morale dirigeante ou associée"
                required="true"
                [errorMessage]="createCreationRequestForm.hasLegalPersonErrorMessage"
              >
                <tiime-select
                  id="hasLegalPerson"
                  [class.selected]="createCreationRequestForm.hasLegalPerson.value !== null"
                  [formControl]="createCreationRequestForm.hasLegalPerson"
                >
                  <div tiimeSelectOptions>
                    <mat-option [value]="true">Oui</mat-option>
                    <mat-option [value]="false">Non</mat-option>
                  </div>
                </tiime-select>
              </tiime-input-container>
            </div>
          </div>
        </tiime-form>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button
          type="button"
          tiime-button
          color="accent"
          large
          raised
          (click)="submitForm()"
          data-cy="legal-create-creation-request-dialog-add-button"
        >
          Suivant
        </button>
      </mat-dialog-actions>
    </ng-container>
    <ng-template #creationSupervisor>
      <span mat-dialog-title data-cy="legal-create-creation-request-dialog-title">Choix du prestataire</span>
      <mat-dialog-content class="dialog-content" mat-dialog-content>
        <div class="supervisors">
          <div
            [class.disabled]="
              createCreationRequestForm.legalForm.value
                | map: mapToDisableMonFormaliste : createCreationRequestForm.hasLegalPerson.value
            "
            tiimeTooltip
            [tooltipContent]="disabledChoiceTooltipTemplate"
            [tooltipDisabled]="
              !(
                createCreationRequestForm.legalForm.value
                | map: mapToDisableMonFormaliste : createCreationRequestForm.hasLegalPerson.value
              )
            "
          >
            <div
              class="choice-card"
              [class.disabled]="
                createCreationRequestForm.legalForm.value
                  | map: mapToDisableMonFormaliste : createCreationRequestForm.hasLegalPerson.value
              "
              (click)="selectFormalitySupervisor(this.formalitySupervisorEnum.monFormaliste)"
              [ngClass]="{ active: formalitySupervisor === formalitySupervisorEnum.monFormaliste }"
            >
              <img
                draggable="false"
                class="mon-formaliste"
                src="assets/logo_mon_formaliste.svg"
                alt="logo tiime legal"
              />
            </div>
            <tiime-alert
              warn
              *ngIf="
                createCreationRequestForm.legalForm.value
                  | map: mapToDisableMonFormaliste : createCreationRequestForm.hasLegalPerson.value
              "
            >
              Indisponible
            </tiime-alert>
          </div>
          <ng-template #disabledChoiceTooltipTemplate>
            Pour le moment, mon-formaliste.fr ne permet pas de créer des SCI ou des sociétés comportant des associés ou
            des dirigeants "personne morale"
          </ng-template>
          <div
            class="choice-card"
            (click)="selectFormalitySupervisor(this.formalitySupervisorEnum.alexLegal)"
            [ngClass]="{ active: formalitySupervisor === formalitySupervisorEnum.alexLegal }"
          >
            <img draggable="false" src="assets/logo_alex_legal.svg" alt="logo tiime expert" />
          </div>
        </div>
        <mat-dialog-actions class="action-box">
          <button
            type="button"
            tiime-button
            color="accent"
            strocked
            large
            (click)="this.showCreationSupervisor = false"
            data-cy="legal-create-creation-request-dialog-previous-button"
          >
            Précédent
          </button>
          <button
            type="button"
            tiime-button
            color="accent"
            large
            raised
            (click)="createCompanyCreation(this.formalitySupervisor)"
            data-cy="legal-create-creation-request-dialog-add-button"
          >
            Ajouter le dossier
          </button>
        </mat-dialog-actions>
      </mat-dialog-content>
    </ng-template>
  </ng-container>
  <ng-template #submitLoadingTemplate>
    <mat-dialog-content class="dialog-content" mat-dialog-content>
      <div class="submit-loader">
        <span>Création du dossier en cours...</span>
        <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </mat-dialog-content>
  </ng-template>
</tiime-dialog>
