import { Sort } from '@angular/material/sort';

import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { ContainerState, PaginationRange } from 'tiime-material';

import { TableDataStoreBase, TableDataStoreState } from '@bases/table-data-store/table-data-store.base';

export interface ApiTableDataStoreState<DataType, FiltersType = unknown>
  extends TableDataStoreState<DataType, FiltersType> {
  pagination: PaginationRange;
}

export const defaultState: ApiTableDataStoreState<{ id: number }, Record<string, unknown>> = {
  data: [],
  containerState: ContainerState.contentPlaceholder,
  pagination: PaginationRange.withPageSize(100),
  filters: null,
  search: null,
  sort: null,
  isLoading: false,
  selected: null
};

@UntilDestroy({ checkProperties: true })
export abstract class ApiTableDataStoreBase<
  DataType,
  FiltersType,
  State extends ApiTableDataStoreState<DataType, FiltersType> = ApiTableDataStoreState<DataType, FiltersType>
> extends TableDataStoreBase<DataType, FiltersType, State> {
  readonly pagination$: Observable<PaginationRange> = this.select(({ pagination }) => pagination);
  readonly scrollToTop$: Observable<unknown> = combineLatest([this.filters$, this.sort$, this.pagination$]);
  readonly paginationDisabled$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done
  );

  readonly setPagination = this.updater((state, pagination: PaginationRange) => ({
    ...state,
    pagination
  }));

  override readonly setFilters = this.updater((state, filters: FiltersType) => ({
    ...state,
    filters,
    pagination: this.getDefaultPagination()
  }));

  override readonly setPartialFilters = this.updater((state, filters: Partial<FiltersType>) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters
    },
    pagination: this.getDefaultPagination()
  }));

  override readonly setSort = this.updater((state, sort: Sort) => {
    if (!sort?.active) {
      sort = {
        ...state.sort
      };
    }

    return {
      ...state,
      sort: {
        active: sort.active,
        direction: sort.direction
      },
      pagination: this.getDefaultPagination()
    };
  });

  override readonly setSearch = this.updater((state, search: string | null) => ({
    ...state,
    search,
    pagination: this.getDefaultPagination()
  }));

  override forceRefresh(): void {
    this.setContainerState(ContainerState.contentPlaceholder);
    const pagination = this.get().pagination;
    pagination.resetMaxAndCount();
    this.setPagination(pagination);
    super.forceRefresh();
  }

  constructor(initialState: State = defaultState as unknown as State) {
    super(initialState);
  }

  protected getDefaultPagination(): PaginationRange {
    return PaginationRange.withPageSize(100);
  }

  protected override getChangesObservable(): Observable<unknown> {
    const pagination$: Observable<number> = this.pagination$.pipe(map(pagination => pagination?.min));
    const search$: Observable<string | null> = this.search$.pipe(debounceTime(400), startWith(this.get().search));

    return combineLatest([this.filters$, this.sort$, pagination$, search$]).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      debounceTime(100)
    );
  }
}
