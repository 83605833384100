import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeInputContainerModule, TiimeFormModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { VatType } from '@models/vat-type';
import { ListErrorComponent } from '@modules/shared/components/list-error/list-error.component';
import { VatTypeRatePipe } from '@modules/shared/pipes/vat-type-rate/vat-type-rate.pipe';

@Component({
  selector: 'app-vat-type-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    MatOptionModule,
    ListErrorComponent,
    VatTypeRatePipe
  ],
  templateUrl: './vat-type-select.component.html',
  styleUrls: ['./vat-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatTypeSelectComponent extends SelectBase<VatType> {
  @Input() label = 'TVA';
  @Input() allowInvoiceOption = false;
}
