import { DateHelper } from '@helpers/date.helper';
import { User, UserJson } from '@models/user';

export interface CommentaryJson {
  id: number;
  type: string;
  content: string;
  updated_at: string;
  updated_by: Partial<UserJson>;
}

export class Commentary {
  constructor(
    public id?: number,
    public type?: string,
    public content?: string,
    public updatedAt?: Date,
    public updatedBy?: User
  ) {}

  static fromJson(json: Partial<CommentaryJson>): Commentary {
    return new Commentary(
      json.id,
      json.type,
      json.content,
      json.updated_at ? DateHelper.fromUtc(json.updated_at) : null,
      json.updated_by ? User.fromJson(json.updated_by) : null
    );
  }

  static toJson(data: Commentary): Omit<CommentaryJson, 'updated_at' | 'updated_by'> {
    return {
      id: data.id,
      content: data.content,
      type: data.type
    };
  }

  static toJsonWithoutId(data: Commentary): Omit<CommentaryJson, 'updated_at' | 'updated_by' | 'id'> {
    return {
      content: data.content,
      type: data.type
    };
  }
}
