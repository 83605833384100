<ng-container *ngIf="favorite; else addFavTemplate">
  <button class="favorite-icon-button" tiime-button color="primary" icon (click)="emitRemoveFromFavorites($event)">
    <mat-icon class="favorite-icon is-favorite" fontIcon="icon-ic-star"></mat-icon>
  </button>
</ng-container>
<ng-template #addFavTemplate>
  <button class="favorite-icon-button" tiime-button color="primary" icon (click)="emitAddToFavorites($event)">
    <mat-icon class="favorite-icon" fontIcon="icon-ic-star"></mat-icon>
  </button>
</ng-template>
