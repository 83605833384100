import { FormControl, FormGroup, Validators } from '@angular/forms';

import type { MomentInput } from 'moment';

import { ErrorType } from '@constants/error.constant';
import { DateHelper, DateInput } from '@helpers/date.helper';

export type CustomAccountingPeriodFormType = {
  accountingPeriodId: FormControl<number | null>;
  endDate: FormControl<string | Date | MomentInput | null>;
};

export class CustomAccountingPeriodForm extends FormGroup<CustomAccountingPeriodFormType> {
  get accountingPeriodId(): FormControl<number | null> {
    return this.controls.accountingPeriodId;
  }
  get accountingPeriodIdErrorMessage(): string | null {
    if (!this.accountingPeriodId.touched) {
      return null;
    }

    if (this.accountingPeriodId.hasError(ErrorType.REQUIRED)) {
      return `La date de début de période est requise`;
    }

    return null;
  }

  get endDate(): FormControl<string | Date | MomentInput | null> {
    return this.controls.endDate;
  }
  get endDateErrorMessage(): string | null {
    if (!this.endDate.touched) {
      return null;
    }

    if (this.endDate.hasError(ErrorType.REQUIRED)) {
      return `La date de fin de période est requise`;
    }

    if (this.endDate.hasError(ErrorType.MAT_DATEPICKER_MIN)) {
      const date = this.endDate.getError(ErrorType.MAT_DATEPICKER_MIN).min;
      return `La date doit être supérieure au ${this.subtractOneDay(date)}.`;
    }

    if (this.endDate.hasError(ErrorType.MAT_DATEPICKER_MAX)) {
      const date = this.endDate.getError(ErrorType.MAT_DATEPICKER_MAX).max;
      return `La date doit être inférieure au ${this.addOneDay(date)}.`;
    }

    return null;
  }

  constructor() {
    super({
      accountingPeriodId: new FormControl<number>(null, [Validators.required]),
      endDate: new FormControl<string | Date | MomentInput | null>(null, [Validators.required])
    });
  }

  private formatDate(date: string | Date | MomentInput): string {
    return DateHelper.format(date, 'DD/MM/YYYY');
  }

  private addOneDay(date: DateInput): string {
    return this.formatDate(DateHelper.add(date, 1, 'day'));
  }

  private subtractOneDay(date: DateInput): string {
    return this.formatDate(DateHelper.add(date, -1, 'day'));
  }
}
