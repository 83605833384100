import { DEFAULT_DEBIT_BANK_ACCOUNT_NOT_CONFIGURED } from '@constants/bank-accounts-consistency-check.constant';
import { AUXILIARY_ACCOUNT_MUST_HAVE_CENTRALIZER } from '@constants/pcg-entry-consistency-check.constant';
import { TNS_CONSISTENCY_CHECKS_CODES } from '@constants/tns-consistency-check.constant';
import { TRANSACTIONS_VAT_DIFFERENCE } from '@constants/transaction-consistency-check.constant';
import { UPDATED_MOVEMENTS_TO_DECLARE } from '@constants/vat-declaration-consistency-check.constant';

export type VerboseConsistencyCheckStatus = 'error' | 'warning' | 'ok';

export interface VerboseConsistencyCheckJson {
  code: string;
  description: string;
  status: VerboseConsistencyCheckStatus;
  order?: number;
  data?: any;
}

export class VerboseConsistencyCheck {
  hasAction = false;

  constructor(
    public code?: string,
    public description?: string,
    public status?: VerboseConsistencyCheckStatus,
    public order?: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public data?: any
  ) {
    this.hasAction = VerboseConsistencyCheck.verboseConsistencyCheckHasAction(code);
  }

  static fromJson(json: VerboseConsistencyCheckJson): VerboseConsistencyCheck {
    return new VerboseConsistencyCheck(json.code, json.description, json.status, json.order, json.data);
  }

  static verboseConsistencyCheckHasAction(code: string): boolean {
    return [
      ...TNS_CONSISTENCY_CHECKS_CODES,
      UPDATED_MOVEMENTS_TO_DECLARE,
      AUXILIARY_ACCOUNT_MUST_HAVE_CENTRALIZER,
      TRANSACTIONS_VAT_DIFFERENCE,
      DEFAULT_DEBIT_BANK_ACCOUNT_NOT_CONFIGURED
    ].includes(code);
  }
}
