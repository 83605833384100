import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TiimeTooltipModule } from 'tiime-material';

@Component({
  standalone: true,
  selector: 'app-simple-cell',
  templateUrl: './simple-cell.component.html',
  styleUrls: ['./simple-cell.component.scss'],
  imports: [CommonModule, TiimeTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleCellComponent {
  @Input() value: string | number;
}
