import { Journal, JournalJson } from '@models/journal';

export interface CashRegisterJson {
  id: number;
  name: string;
  balance: number;
  initialization_date: string;
  last_record_date: string;
  first_error_record_date: string;
  journal: Partial<JournalJson>;
}

export class CashRegister {
  constructor(
    public id?: number,
    public name?: string,
    public balance?: number,
    public initializationDate?: string,
    public lastRecordDate?: string,
    public firstErrorRecordDate?: string,
    public journal?: Journal
  ) {}

  static fromJson(json: Partial<CashRegisterJson>): CashRegister {
    return new CashRegister(
      json.id,
      json.name,
      json.balance,
      json.initialization_date,
      json.last_record_date,
      json.first_error_record_date,
      json.journal ? Journal.fromJson(json.journal) : null
    );
  }

  static toJson(cashRegister: CashRegister): Pick<CashRegisterJson, 'id' | 'journal'> {
    return {
      id: cashRegister.id,
      journal: cashRegister.journal ? Journal.toJson(cashRegister.journal) : null
    };
  }
}
