import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import {
  TiimeAutoSaveStatusModule,
  TiimeBadgeModule,
  TiimeButtonModule,
  TiimeButtonToggleModule,
  TiimeCheckboxModule,
  TiimeColumnsSelectorModule,
  TiimeDatepickerModule,
  TiimeDialogModule,
  TiimeEmptyStateModule,
  TiimeFileModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeLoaderModule,
  TiimeMenuModule,
  TiimeOverlayModule,
  TiimePaginatorModule,
  TiimeProgressBarModule,
  TiimeRadioModule,
  TiimeSearchBarModule,
  TiimeSecondaryFiltersModule,
  TiimeSelectModule,
  TiimeSidenavLayoutModule,
  TiimeSlideToggleModule,
  TiimeSnackbarModule,
  TiimeSortModule,
  TiimeSplitButtonModule,
  TiimeSubheaderModule,
  TiimeTableModule,
  TiimeTagModule,
  TiimeTooltipModule,
  TiimeFormModule,
  TiimeTwocolorsIconModule,
  TiimeContainerModule,
  TiimeStepperModule,
  TiimeMultilineEllipsisModule,
  TiimeComplexSearchBarModule,
  TiimeCardModule,
  TiimeAlertModule
} from 'tiime-material';

/**
 * @deprecated Load only required Modules / Components / Pipes / Directives ...
 */
@NgModule({
  exports: [
    TextFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    TiimeButtonModule,
    TiimeSplitButtonModule,
    TiimeTableModule,
    TiimeTagModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeSubheaderModule,
    TiimeEmptyStateModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeTwocolorsIconModule,
    TiimeTooltipModule,
    TiimeOverlayModule,
    TiimeCheckboxModule,
    TiimeDatepickerModule,
    TiimeProgressBarModule,
    TiimeLoaderModule,
    TiimeSearchBarModule,
    TiimeSortModule,
    TiimeSlideToggleModule,
    TiimePaginatorModule,
    TiimeRadioModule,
    TiimeSidenavLayoutModule,
    TiimeButtonToggleModule,
    TiimeMenuModule,
    TiimeBadgeModule,
    TiimeSelectModule,
    TiimeFileModule,
    TiimeSecondaryFiltersModule,
    TiimeColumnsSelectorModule,
    TiimeSecondaryFiltersModule,
    TiimeFormModule,
    TiimeAutoSaveStatusModule,
    TiimeContainerModule,
    TiimeStepperModule,
    TiimeMultilineEllipsisModule,
    TiimeComplexSearchBarModule,
    TiimeCardModule,
    TiimeAlertModule
  ]
})
export class MaterialModule {}
