import { HttpHelper } from '@helpers/http.helper';
import { Contributor, ContributorJson } from '@models/contributor';

export interface ContractOwnerJson {
  id: number;
  user: Partial<ContributorJson>;
}

export class ContractOwner {
  constructor(
    public id?: number,
    public user?: Contributor
  ) {}

  static fromJson(json: Partial<ContractOwnerJson>): ContractOwner {
    return new ContractOwner(json.id, json.user ? Contributor.fromJson(json.user) : null);
  }

  static toJson(model: ContractOwner): ContractOwnerJson {
    return {
      id: model.id ? HttpHelper.toJsonProperty(model.id) : undefined,
      user: model.user ? HttpHelper.toJsonId(model.user) : null
    };
  }
}
