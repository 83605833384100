<tiime-secondary-filters [badge]="form.value | map: mapToBadgeValue" [badgeHidden]="form.value | map: mapToHideBadge">
  <div class="secondary-filters-line">
    <span class="secondary-filters-line-label">Etat</span>
    <div class="filters-type-container">
      <mat-button-toggle-group [formControl]="form.controls.status">
        <mat-button-toggle [value]="ThreadStatus.all">Tous</mat-button-toggle>
        <mat-button-toggle [value]="ThreadStatus.opened">En cours</mat-button-toggle>
        <mat-button-toggle [value]="ThreadStatus.closed">Clôturés</mat-button-toggle>
        <mat-button-toggle [value]="ThreadStatus.archived">Archivés</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="secondary-filters-line">
    <span class="secondary-filters-line-label">Objet</span>
    <div class="filters-type-container">
      <app-thread-entity-type-select
        [control]="form.controls.type"
        [selectData]="form.controls.category.value | threadEntityTypesByCategory"
        [small]="true"
        label=""
        emptyOptionLabel="Tous"
      ></app-thread-entity-type-select>
      <ng-container [ngSwitch]="form.controls.type.value">
        <app-accounting-period-select
          [accountingPeriodFilter]="form.controls.entity"
          [accountingPeriods]="accountingPeriods"
          [small]="true"
          [endDateOnly]="true"
          *ngSwitchCase="ThreadEntityType.accountingPeriod"
        ></app-accounting-period-select>
        <app-vat-declaration-select
          [control]="form.controls.entity"
          [selectData]="vatDeclarations"
          [small]="true"
          label=""
          *ngSwitchCase="ThreadEntityType.vatDeclaration"
        ></app-vat-declaration-select>
        <app-transaction-select
          [control]="form.controls.entity"
          [company]="company"
          [small]="true"
          label=""
          *ngSwitchCase="ThreadEntityType.bankTransaction"
        ></app-transaction-select>
        <app-corporate-tax-instalment-select
          [control]="form.controls.entity"
          [selectData]="corporateTaxInstalments"
          [small]="true"
          label=""
          *ngSwitchCase="ThreadEntityType.corporateTaxInstalment"
        ></app-corporate-tax-instalment-select>
      </ng-container>
    </div>
  </div>
  <div
    class="secondary-filters-line"
    *ngIf="contributors?.length && form.controls.category.value === ThreadCategory.accountant"
  >
    <span class="secondary-filters-line-label interlocutor-label">Interlocuteurs</span>
    <div class="filters-type-container">
      <app-contributor-select-multiple
        [contributors]="contributors"
        [contributorFormControl]="form.controls.contributors"
        label=""
        [marginTop]="false"
      ></app-contributor-select-multiple>
    </div>
  </div>
  <div
    class="secondary-filters-line"
    *ngIf="tags?.length && form.controls.category.value === ThreadCategory.accountant"
  >
    <span class="secondary-filters-line-label interlocutor-label">Tags</span>
    <div class="filters-type-container">
      <app-thread-tag-select-multiple
        [control]="form.controls.tags"
        [tags]="tags"
        label=""
        [marginTop]="false"
        [allowCreate]="false"
      ></app-thread-tag-select-multiple>
    </div>
  </div>
</tiime-secondary-filters>
