import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeButtonModule, TiimeContainerModule, TiimeDialogModule, TiimeTableModule } from 'tiime-material';

import { LabelCategoryPlanSelectComponent } from '@modules/shared/selectors/label-category-plan-select/label-category-plan-select.component';
import { LabelCategoryPlanSetupTableComponent } from '@modules/shared/tables/label-category-plan-setup-table/label-category-plan-setup-table.component';

import { LabelCategoryPlanSetupDialogComponent } from './label-category-plan-setup-dialog.component';

@NgModule({
  declarations: [LabelCategoryPlanSetupDialogComponent],
  imports: [
    CommonModule,
    TiimeContainerModule,
    TiimeTableModule,
    TiimeDialogModule,
    TiimeButtonModule,
    LabelCategoryPlanSelectComponent,
    LabelCategoryPlanSetupTableComponent
  ]
})
export class LabelCategoryPlanSetupDialogModule {}
