export interface VatExonerationTypeJson {
  name: string;
  value: string;
}

export class VatExonerationType {
  constructor(public name?: string, public value?: string) {}

  static fromJson(json: VatExonerationTypeJson): VatExonerationType {
    return new VatExonerationType(json.name, json.value);
  }
}
