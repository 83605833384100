import { Role } from '@enums/role.enum';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { BusinessUnitUser, BusinessUnitUserJson } from '@models/business-unit-user';
import { Partner, PartnerJson } from '@models/partner';
import { UserBase } from '@models/user-base';

export interface BusinessUserJson {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  acronym: string;
  business_units: BusinessUnitJson[];
  roles: Role[];
  business_unit_users?: BusinessUnitUserJson[];
  partner?: PartnerJson;
  is_partner_admin?: boolean;
  must_renew_password?: boolean;
}

export class BusinessUser extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    acronym?: string,
    public businessUnits?: BusinessUnit[],
    public roles?: Role[],
    public businessUnitUsers?: BusinessUnitUser[],
    public partner?: Partner,
    public isPartnerAdmin?: boolean,
    public mustRenewPassword?: boolean
  ) {
    super(id, firstName, lastName, email, phone, acronym);
  }

  static fromJson(json: Partial<BusinessUserJson>): BusinessUser {
    return new BusinessUser(
      json.id,
      json.firstname,
      json.lastname,
      json.email,
      json.phone,
      json.acronym,
      json.business_units ? json.business_units.map(businessUnit => BusinessUnit.fromJson(businessUnit)) : [],
      json.roles,
      json.business_unit_users
        ? json.business_unit_users.map((businessUnitUserJson: BusinessUnitUserJson) =>
            BusinessUnitUser.fromJson(businessUnitUserJson)
          )
        : [],
      json.partner ? Partner.fromJson(json.partner) : null,
      json.is_partner_admin,
      json.must_renew_password
    );
  }

  static toJson(businessUser: BusinessUser): Partial<BusinessUserJson> {
    return {
      id: businessUser.id,
      firstname: businessUser.firstName,
      lastname: businessUser.lastName,
      email: businessUser.email,
      acronym: businessUser.acronym,
      phone: HttpHelper.toJsonProperty(businessUser.phone),
      is_partner_admin: businessUser.isPartnerAdmin,
      business_unit_users: businessUser.businessUnitUsers
        ? businessUser.businessUnitUsers.map((businessUnitUser: BusinessUnitUser) =>
            BusinessUnitUser.toJson(businessUnitUser)
          )
        : []
    };
  }
}
