import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeIconModule } from '../icon';
import { AlertComponent } from './alert.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [AlertComponent],
  exports: [AlertComponent]
})
export class TiimeAlertModule {}
