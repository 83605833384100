import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { Mapper, StringUtils, TiimePipesModule } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { Country } from '@models/country';
import { FormFocusObserveDirective } from '@modules/shared/directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormFocusObserveDirective,
    TiimePipesModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    MatOptionModule,
    TiimeFormModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectComponent extends FilteredSelectBase<Country> {
  @Input() label = 'Pays';
  @Input() selectorId = 'country-selector';
  @Input() showNationality: boolean;

  readonly mapToCorrectField: Mapper<boolean, string> = (shouldShowNationality: boolean, country: Country): string =>
    shouldShowNationality ? country.nationality : country.name;

  trackByCode(_index: number, country: Country): string {
    return country.code;
  }

  filterFunction(search: string): Country[] {
    const searchLowerCase = StringUtils.normalizeToLowerCase(search);
    return this.selectorData.filter(
      country =>
        StringUtils.normalizeToLowerCase(country.code).includes(searchLowerCase) ||
        StringUtils.normalizeToLowerCase(country.name).includes(searchLowerCase)
    );
  }
}
