import { TaskPlanningInvoicingStatusCode } from '@enums/task-planning-invoicing-status-code.enum';
import { HttpHelper } from '@helpers/http.helper';
import { Company, CompanyJson } from '@models/company';
import { Contributor, ContributorJson } from '@models/contributor';
import { TimeTrackingsInvoicingType, TimeTrackingsInvoicingTypeJson } from '@models/time-trackings-invoicing-type';
import { TimeTrackingsTask, TimeTrackingsTaskJson } from '@models/time-trackings-task';

export interface TaskSchedulerJson {
  id: number;
  company: Partial<CompanyJson>;
  interval: number;
  day: number;
  wording: string;
  first_deadline_date: string;
  last_deadline_date: string;
  occurence: number;
  responsable: Partial<ContributorJson>;
  supervisor: Partial<ContributorJson>;
  time_tracking_task: Partial<TimeTrackingsTaskJson>;
  time_tracking_invoicing_type: Partial<TimeTrackingsInvoicingTypeJson>;
  invoicing_status: TaskPlanningInvoicingStatusCode;
  amount: number;
  discount: number;
}

export class TaskScheduler {
  constructor(
    public id?: number,
    public company?: Company,
    public interval?: number,
    public day?: number,
    public wording?: string,
    public firstDeadlineDate?: string,
    public lastDeadlineDate?: string,
    public occurence?: number,
    public responsable?: Contributor,
    public supervisor?: Contributor,
    public timeTrackingTask?: TimeTrackingsTask,
    public timeTrackingInvoicingType?: TimeTrackingsInvoicingType,
    public invoicingStatus?: TaskPlanningInvoicingStatusCode,
    public amount?: number,
    public discount?: number
  ) {}

  static fromJson(json: Partial<TaskSchedulerJson>): TaskScheduler {
    return new TaskScheduler(
      json.id,
      json.company ? Company.fromJson(json.company) : null,
      json.interval,
      json.day,
      json.wording,
      json.first_deadline_date,
      json.last_deadline_date,
      json.occurence,
      json.responsable ? Contributor.fromJson(json.responsable) : null,
      json.supervisor ? Contributor.fromJson(json.supervisor) : null,
      json.time_tracking_task ? TimeTrackingsTask.fromJson(json.time_tracking_task) : null,
      json.time_tracking_invoicing_type ? TimeTrackingsInvoicingType.fromJson(json.time_tracking_invoicing_type) : null,
      json.invoicing_status,
      json.amount,
      json.discount
    );
  }

  static toJson(json: TaskScheduler): TaskSchedulerJson {
    return {
      id: json.id,
      company: json.company ? Company.toJson(json.company) : null,
      interval: json.interval,
      day: json.day,
      wording: json.wording,
      first_deadline_date: json.firstDeadlineDate,
      last_deadline_date: json.lastDeadlineDate,
      occurence: json.occurence,
      responsable: json.responsable ? Contributor.toJson(json.responsable) : null,
      supervisor: json.supervisor ? Contributor.toJson(json.supervisor) : null,
      time_tracking_task: json.timeTrackingTask ? HttpHelper.toJsonId(json.timeTrackingTask) : null,
      time_tracking_invoicing_type: json.timeTrackingInvoicingType
        ? HttpHelper.toJsonId(json.timeTrackingInvoicingType)
        : null,
      invoicing_status: json.invoicingStatus,
      amount: json.amount,
      discount: json.discount
    };
  }
}
