import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from 'tiime-expert-utils';
import { TiimeButtonModule, TiimeDialogModule } from 'tiime-material';

import { ShortcutContainerComponent } from '@shared-components/shortcut-container/shortcut-container.component';

import { KeyboardShortcutsDialogComponent } from './keyboard-shortcuts-dialog.component';

@NgModule({
  declarations: [KeyboardShortcutsDialogComponent],
  imports: [CommonModule, TiimeDialogModule, ShortcutContainerComponent, TiimeButtonModule, TiimePipesModule]
})
export class KeyboardShortcutsDialogModule {}
