export interface CerfaVersionJson {
  cerfa: string;
  version: string;
}

export class CerfaVersion {
  constructor(public cerfa: string, public version: string) {}

  static fromJson(json: CerfaVersionJson): CerfaVersion {
    return new CerfaVersion(json.cerfa, json.version);
  }
}
