import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tiime-twocolors-icon',
  templateUrl: './twocolors-icon.component.html',
  styleUrls: ['./twocolors-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwocolorsIconComponent {
  @Input()
  icon: string;
  @Input()
  size: string;
  @Input()
  top: string;
  @Input()
  darkColor: string;
  @Input()
  lightColor: string;

  get iconStyle(): { width: string; height: string; 'margin-top': string; fill: string; color: string } {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      'margin-top': `${this.top}px`,
      fill: this.darkColor,
      color: this.lightColor
    };
  }
}
