import { VatDeclarationForm } from '@forms/vat-declaration-form';
import { VatDeclarationValueForm } from '@forms/vat-declaration-value-form';

import { VatDeclarationContent } from './vat-declaration-content';
import { VatDeclarationSection, VatDeclarationSectionJson } from './vat-declaration-section';
import { VatDeclarationValue, VatDeclarationValueJson } from './vat-declaration-value';

export interface VatDeclarationConfigurationDataJson {
  sections: Partial<VatDeclarationSectionJson>[];
  synthesis: Partial<VatDeclarationSectionJson>[];
  values: Partial<VatDeclarationValueJson>[];
}

export class VatDeclarationConfigurationData {
  constructor(
    public sections?: VatDeclarationSection[],
    public synthesis?: VatDeclarationSection[],
    public values?: VatDeclarationValue[],
    public form?: VatDeclarationForm
  ) {}

  static initVatDeclarationForm(vatDeclarationValues: VatDeclarationValue[]): VatDeclarationForm {
    const vatDeclarationValueForms: {
      [key: string]: VatDeclarationValueForm;
    } = {};

    vatDeclarationValues.forEach((vatDeclarationValue: VatDeclarationValue) => {
      vatDeclarationValueForms[vatDeclarationValue.code] = new VatDeclarationValueForm();

      vatDeclarationValueForms[vatDeclarationValue.code].fromVatDeclarationValue(vatDeclarationValue);

      if (vatDeclarationValue.disabled) {
        vatDeclarationValueForms[vatDeclarationValue.code].formValue.disable();
      }
    });

    return new VatDeclarationForm(vatDeclarationValueForms);
  }

  static fromJson(json: Partial<VatDeclarationConfigurationDataJson>): VatDeclarationConfigurationData {
    return new VatDeclarationConfigurationData(
      json.sections ? json.sections.map(sectionsJson => VatDeclarationSection.fromJson(sectionsJson)) : [],
      json.synthesis ? json.synthesis.map(synthesisJson => VatDeclarationSection.fromJson(synthesisJson)) : [],
      json.values ? json.values.map(valuesJson => VatDeclarationValue.fromJson(valuesJson)) : [],
      this.initVatDeclarationForm(json.values)
    );
  }

  static toJson(vatDeclarationConfiguration: VatDeclarationConfigurationData): VatDeclarationConfigurationDataJson {
    return {
      sections: vatDeclarationConfiguration.sections.map((section: VatDeclarationSection) =>
        VatDeclarationSection.toJson(section)
      ),
      synthesis: vatDeclarationConfiguration.synthesis.map((section: VatDeclarationSection) =>
        VatDeclarationSection.toJson(section)
      ),
      values: vatDeclarationConfiguration.values.map((value: VatDeclarationValue) => VatDeclarationValue.toJson(value))
    };
  }

  getContentByChildCode(code: string): VatDeclarationContent {
    let vatDeclarationContent = this.searchContentInSections(this.sections, code);

    if (!vatDeclarationContent) {
      vatDeclarationContent = this.searchContentInSections(this.synthesis, code);
    }

    return vatDeclarationContent;
  }

  private searchContentInSections(
    vatDeclarationSections: VatDeclarationSection[],
    code: string
  ): VatDeclarationContent {
    let vatDeclarationContent: VatDeclarationContent;

    vatDeclarationSections.forEach((section: VatDeclarationSection) => {
      section.content.forEach((content: VatDeclarationContent) => {
        const currentLine = content.lines.some((line: VatDeclarationContent) => line.code === code);

        if (currentLine) {
          vatDeclarationContent = content;
        }
      });
    });

    return vatDeclarationContent;
  }
}
