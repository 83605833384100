import { TaxRegimeCode } from '@enums/tax-regime-code.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { AccountingConfig } from '@models/accounting-config';
import { ApeCode, ApeCodeJson } from '@models/ape-code';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { Commentary, CommentaryJson } from '@models/commentary';
import { CompanyConfig, CompanyConfigJson } from '@models/company-config';
import { Contributor, ContributorJson } from '@models/contributor';
import { HubEligibilityConnection, HubEligibilityConnectionJson } from '@models/hub-eligibility-connection';
import { LegalStructure } from '@models/legal-structure';
import { Mission, MissionJson } from '@models/mission';
import { Signatory } from '@models/signatory';
import { ThreadsInfos, ThreadsInfosJson } from '@models/threads-infos';
import { User, UserJson } from '@models/user';
import { VatSystem, VatSystemJson } from '@models/vat-system';

export interface CompanyJson {
  ape_code: Partial<ApeCodeJson>;
  business_unit: Partial<BusinessUnitJson>;
  cfonb_import_email: string;
  city: string;
  code_rof_cvae: string;
  code_rof_tax_regime: string;
  code_rof_vat: string;
  ebics_card_compatible: boolean;
  eligible_tiime_business_display: boolean;
  id: number;
  is_account_configured: boolean;
  legal_form: string;
  name: string;
  postal_code: string;
  purchases_account_number_prefix: string;
  rcs_city: string;
  receipt_email: string;
  reference: string;
  sales_account_number_prefix: string;
  share_capital: number;
  siret: string;
  street: string;
  tax_regime: TaxRegimeCode;
  users: Partial<UserJson>[];
  vat_declaration_day: number;
  vat_system: Partial<VatSystemJson>;
  initialization_in_progress: boolean;
  last_tiime_business_eligibility_sent_at: string;
  contributors: Partial<ContributorJson>[];
  commentaries: Partial<CommentaryJson>[];
  cash_registers_available: boolean;
  hub_eligibility_connections: Partial<HubEligibilityConnectionJson>;
  is_tiime_business: boolean;
  company_creation_request_id: number;
  mission: Partial<MissionJson>;
  threads_informations: ThreadsInfosJson;
  is_copy: boolean;
  company_creation_request_closed: boolean;
  accounting_config: Partial<AccountingConfig>;
  shares_number: number;
  share_value: number;
  activity_start_date: string;
  config?: Partial<CompanyConfigJson>;
  legal_structure?: Partial<LegalStructure>;
  signatory?: Partial<Signatory>;
  is_demo?: boolean;
  acronym?: string;
  color?: string;
  siren?: string;
  insee_code?: string;
}

export type CompanySerialized = Omit<
  CompanyJson,
  | 'users'
  | 'business_unit'
  | 'is_account_configured'
  | 'eligible_tiime_business_display'
  | 'purchases_account_number_prefix'
  | 'sales_account_number_prefix'
  | 'initialization_in_progress'
  | 'contributors'
  | 'commentaries'
  | 'cash_registers_available'
  | 'hub_eligibility_connections'
  | 'is_tiime_business'
  | 'company_creation_request_id'
  | 'mission'
  | 'threads_informations'
  | 'is_copy'
  | 'company_creation_request_closed'
  | 'accounting_config'
  | 'config'
  | 'acronym'
  | 'color'
  | 'is_demo'
> & { business_unit: { id: number }; users: any };

export class Company {
  constructor(
    public id?: number,
    public name?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public siret?: string,
    public taxRegime?: TaxRegimeCode,
    public apeCode?: ApeCode,
    public vatSystem?: VatSystem,
    public legalForm?: string,
    public shareCapital?: number,
    public businessUnit?: BusinessUnit,
    public users?: User[],
    public receiptEmail?: string,
    public cfonbImportEmail?: string,
    public ebicsCardCompatible?: boolean,
    public rcsCity?: string,
    public reference?: string,
    public purchasesAccountNumberPrefix?: string,
    public salesAccountNumberPrefix?: string,
    public vatDeclarationDay?: number,
    public codeRofTaxRegime?: string,
    public codeRofCvae?: string,
    public eligibleTiimeBusinessDisplay?: boolean,
    public codeRofVat?: string,
    public isAccountConfigured?: boolean,
    public initializationInProgress?: boolean,
    public lastTiimeBusinessEligibilitySentAt?: Date,
    public contributors?: Contributor[],
    public commentaries?: Commentary[],
    public cashRegistersAvailable?: boolean,
    public hubEligibilityConnections?: HubEligibilityConnection,
    public isTiimeBusiness?: boolean,
    public creationRequestId?: number,
    public mission?: Mission,
    public threadsInformations?: ThreadsInfos,
    public isCopy?: boolean,
    public creationRequestClosed?: boolean,
    public accountingConfig?: AccountingConfig,
    public sharesNumber?: number,
    public shareValue?: number,
    public activityStartDate?: Date,
    public config?: CompanyConfig,
    public legalStructure?: LegalStructure,
    public signatory?: Signatory,
    public isDemo?: boolean,
    public acronym?: string,
    public color?: string,
    public siren?: string,
    public inseeCode?: string
  ) {}

  static fromJson(json: Partial<CompanyJson>): Company {
    const company = new Company(
      json.id,
      json.name,
      json.street,
      json.postal_code,
      json.city,
      json.siret,
      json.tax_regime,
      json.ape_code ? ApeCode.fromJson(json.ape_code) : null,
      json.vat_system ? VatSystem.fromJson(json.vat_system) : null,
      json.legal_form,
      json.share_capital,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.users ? json.users.map((userJson: any) => User.fromJson(userJson)) : [],
      json.receipt_email,
      json.cfonb_import_email,
      json.ebics_card_compatible,
      json.rcs_city ?? null,
      json.reference,
      json.purchases_account_number_prefix,
      json.sales_account_number_prefix,
      json.vat_declaration_day,
      json.code_rof_tax_regime,
      json.code_rof_cvae,
      json.eligible_tiime_business_display,
      json.code_rof_vat,
      json.is_account_configured,
      json.initialization_in_progress,
      json.last_tiime_business_eligibility_sent_at
        ? DateHelper.fromUtc(json.last_tiime_business_eligibility_sent_at)
        : null,
      json.contributors ? json.contributors.map(contributorJson => Contributor.fromJson(contributorJson)) : [],
      json.commentaries ? json.commentaries.map(commentaryJson => Commentary.fromJson(commentaryJson)) : [],
      json.cash_registers_available,
      json.hub_eligibility_connections ? HubEligibilityConnection.fromJson(json.hub_eligibility_connections) : null,
      json.is_tiime_business,
      json.company_creation_request_id,
      json.mission ? Mission.fromJson(json.mission) : null,
      json.threads_informations ? ThreadsInfos.fromJson(json.threads_informations) : null,
      json.is_copy,
      json.company_creation_request_closed,
      json.accounting_config ? AccountingConfig.fromJson(json.accounting_config) : null,
      json.shares_number,
      json.share_value,
      json.activity_start_date ? DateHelper.fromUtc(json.activity_start_date) : null
    );

    company.legalStructure = json.legal_structure ? LegalStructure.fromJson(json.legal_structure) : null;
    company.signatory = json.signatory ? Signatory.fromJson(json.signatory) : null;
    company.isDemo = json.is_demo;
    company.acronym = json.acronym;
    company.color = json.color;
    company.siren = json.siren;
    company.inseeCode = json.insee_code;

    return company;
  }

  static toJson(company: Company): CompanySerialized {
    return {
      ape_code: company.apeCode && company.apeCode.id ? ApeCode.toJson(company.apeCode) : null,
      business_unit: company.businessUnit && company.businessUnit.id ? HttpHelper.toJsonId(company.businessUnit) : null,
      cfonb_import_email: HttpHelper.toJsonProperty(company.cfonbImportEmail),
      city: HttpHelper.toJsonProperty(company.city),
      code_rof_cvae: HttpHelper.toJsonProperty(company.codeRofCvae),
      code_rof_tax_regime: HttpHelper.toJsonProperty(company.codeRofTaxRegime),
      code_rof_vat: HttpHelper.toJsonProperty(company.codeRofVat),
      ebics_card_compatible: HttpHelper.toJsonProperty(company.ebicsCardCompatible),
      id: HttpHelper.toJsonProperty(company.id),
      legal_form: HttpHelper.toJsonProperty(company.legalForm),
      name: HttpHelper.toJsonProperty(company.name),
      postal_code: HttpHelper.toJsonProperty(company.postalCode),
      rcs_city: HttpHelper.toJsonProperty(company.rcsCity),
      receipt_email: HttpHelper.toJsonProperty(company.receiptEmail),
      reference: HttpHelper.toJsonProperty(company.reference),
      share_capital: HttpHelper.toJsonProperty(company.shareCapital),
      siret: HttpHelper.toJsonProperty(company.siret),
      street: HttpHelper.toJsonProperty(company.street),
      tax_regime: HttpHelper.toJsonProperty(company.taxRegime),
      users: company.users,
      vat_declaration_day: HttpHelper.toJsonProperty(company.vatDeclarationDay),
      vat_system: company.vatSystem && company.vatSystem.id ? VatSystem.toJson(company.vatSystem) : null,
      last_tiime_business_eligibility_sent_at: company.lastTiimeBusinessEligibilitySentAt
        ? DateHelper.format(company.lastTiimeBusinessEligibilitySentAt)
        : undefined,
      shares_number: company.sharesNumber || null,
      share_value: company.shareValue || null,
      activity_start_date: company.activityStartDate ? DateHelper.format(company.activityStartDate) : null,
      legal_structure: company.legalStructure ? HttpHelper.toJsonId(company.legalStructure) : null,
      signatory: company.signatory ? HttpHelper.toJsonId(company.signatory) : null,
      siren: company.siren ? HttpHelper.toJsonProperty(company.siren) : null,
      insee_code: company.inseeCode ? HttpHelper.toJsonProperty(company.inseeCode) : null
    };
  }
}
