export interface BusinessUserPartnerJson {
  id: number;
  name: string;
}

export class BusinessUserPartner {
  constructor(public id: number, public name: string) {}

  static fromJson(json: Partial<BusinessUserPartnerJson>): BusinessUserPartner {
    return new BusinessUserPartner(json.id, json.name);
  }
}
