<tiime-input-container
  tiimeFormInputContainer
  contentId="vatExonerationType"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessageMode === 'label' && errorMessage?.message"
  [attr.small]="small"
>
  <tiime-select
    id="vatExonerationType"
    [class.selected]="control?.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option
        [value]="vatExonerationType.name"
        *ngFor="let vatExonerationType of selectorData; trackBy: trackByName"
      >
        {{ vatExonerationType.value }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
<app-list-error *ngIf="errorMessageMode === 'list' && errorMessage" [errorMessage]="errorMessage"></app-list-error>
