export enum TaxRegimeCode {
  ISRS = 'ISRS',
  ISRN = 'ISRN',
  BICRS = 'BICRS',
  BICRN = 'BICRN',
  BNC = 'BNC',
  SCIS = 'SCIS',
  SCIC = 'SCIC',
  SCMS = 'SCMS',
  SCMR = 'SCMR',
  BARS = 'BARS',
  BARN = 'BARN',
  MICRO = 'MICRO'
}
