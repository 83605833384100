import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

import { CheckboxCardComponent } from './checkbox-card.component';

@NgModule({
  declarations: [CheckboxCardComponent],
  imports: [FormsModule, MatCheckboxModule],
  exports: [CheckboxCardComponent]
})
export class TiimeCheckboxCardModule {}
