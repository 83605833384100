<tiime-input-container
  [attr.small]="small"
  contentId="accountingPeriodSelect"
  [label]="label"
  [errorMessage]="errorMessage?.message"
  [required]="required"
>
  <tiime-select
    data-cy="accounting-period-select"
    id="accountingPeriodSelect"
    [placeholder]="placeholder"
    [formControl]="accountingPeriodFilter"
    tiimeTooltip
    [tooltipContent]="selectedTooltipTemplate"
    tooltipClass="tiime-tooltip"
    [tooltipDisabled]="!(accountingPeriodFilter.value | map: mapToShowTooltip : withCustom)"
    [customFindOriginValueFromOptions]="findAccountingPeriodInSelect"
  >
    <mat-icon
      tiimeSelectSuffix
      class="locked-icon"
      *ngIf="accountingPeriodFilter.value | map: mapToClosedIcon as icon"
      [fontIcon]="icon"
      inline="true"
    ></mat-icon>
    <mat-icon
      tiimeSelectSuffix
      class="locked-icon"
      *ngIf="accountingPeriodFilter.value | map: mapToIsCustom : withCustom"
      fontIcon="icon-calendar"
      inline="true"
    ></mat-icon>
    <app-loading-icon tiimeSelectSuffix *ngIf="accountingPeriodFilter.value | map: mapToIsClosing">
      <mat-icon loading-icon inline="true" fontIcon="icon-ic-lock-1" />
    </app-loading-icon>
    <div tiimeSelectOptions>
      <ng-container *ngIf="withCustom">
        <div class="divider custom-accounting-period-divider">
          Période personnalisée
          <button tiime-button color="primary" icon (click)="openEditCustomAccountingPeriodDialog()">
            <mat-icon
              fontIcon="icon-ic-add-1"
              title="Ajouter une période personnalisée"
              *ngIf="!(accountingPeriods | map: mapToCustomAccountingPeriod); else updateIcon"
            ></mat-icon>
            <ng-template #updateIcon>
              <mat-icon fontIcon="icon-header-grey-n3-24px-edit" title="Modifier la période personnalisée"></mat-icon>
            </ng-template>
          </button>
        </div>
        <mat-option
          [value]="customAccountingPeriod"
          *ngIf="accountingPeriods | map: mapToCustomAccountingPeriod as customAccountingPeriod"
        >
          <div *ngIf="endDateOnly; else fullPeriod">
            {{ customAccountingPeriod.endDate | date: 'shortDate' }}
          </div>
          <ng-template #fullPeriod>
            Du {{ customAccountingPeriod.startDate | date: 'shortDate' }} au
            {{ customAccountingPeriod.endDate | date: 'shortDate' }}
          </ng-template>
          <mat-icon fontIcon="icon-calendar" inline="true"></mat-icon>
        </mat-option>
      </ng-container>
      <div class="divider">Exercices</div>
      <mat-option *ngIf="allowEmpty" [value]="null"></mat-option>
      <ng-container *ngFor="let accountingPeriod of accountingPeriods; trackBy: trackByIndex">
        <mat-option [value]="accountingPeriod" *ngIf="!accountingPeriod.custom">
          <div *ngIf="endDateOnly; else fullPeriod">
            {{ accountingPeriod.endDate | date: 'shortDate' }}
          </div>
          <ng-template #fullPeriod>
            Du {{ accountingPeriod.startDate | date: 'shortDate' }} au
            {{ accountingPeriod.endDate | date: 'shortDate' }}
          </ng-template>
          <mat-icon
            *ngIf="accountingPeriod.closed && accountingPeriod.asyncProcessType !== AsyncProcessType.closing"
            inline="true"
            fontIcon="icon-ic-lock-1"
          ></mat-icon>
          <app-loading-icon *ngIf="accountingPeriod.asyncProcessType === AsyncProcessType.closing">
            <mat-icon loading-icon inline="true" fontIcon="icon-ic-lock-1" />
          </app-loading-icon>
        </mat-option>
      </ng-container>
    </div>
  </tiime-select>
  <ng-template #selectedTooltipTemplate>
    <ng-container *ngIf="accountingPeriodFilter.value?.closed">L'exercice est clôturé</ng-container>
    <ng-container *ngIf="accountingPeriodFilter.value | map: mapToIsCustom : withCustom">
      Vous visualisez une période personnalisée
    </ng-container>
  </ng-template>
</tiime-input-container>
