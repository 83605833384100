import { NumberUtils } from 'tiime-expert-utils';

import { OcrStatus } from '@enums/ocr-status.enum';
import { DocumentMetadata, DocumentMetadataJson } from '@models/document-metadata';
import { Label } from '@models/label';
import { LinkedTransaction, LinkedTransactionJson } from '@models/linked-transaction';
import { PcgEntry } from '@models/pcg-entry';

export interface ReceiptJson {
  id: number;
  label: Partial<Label>;
  name: string;
  ocr_status: OcrStatus;
  comment: string;
  date: string;
  amount: number;
  vat_amount: number;
  source: string;
  tags: { name: string }[];
  upload_date: string;
  bank_transactions: Partial<LinkedTransactionJson>[];
  wording: string;
  accountable: boolean;
  mime_type: string;
  metadata: DocumentMetadataJson[];
}

export class Receipt {
  get hasManualOcr(): boolean {
    return this.ocrStatus === OcrStatus.manual;
  }
  get hasPendingOcr(): boolean {
    return this.ocrStatus === OcrStatus.inProgress;
  }

  get isEmpty(): boolean {
    return (
      !this.date &&
      !this.label?.label &&
      this.tags.length === 0 &&
      !NumberUtils.isValidNumber(this.amount) &&
      !NumberUtils.isValidNumber(this.vatAmount)
    );
  }

  constructor(
    public id?: number,
    public label?: PcgEntry,
    public name?: string,
    public ocrStatus?: OcrStatus,
    public comment?: string,
    public date?: string,
    public amount?: number,
    public vatAmount?: number,
    public source?: string,
    public tags?: string[],
    public uploadDate?: string,
    public bankTransactions?: LinkedTransaction[],
    public wording?: string,
    public accountable?: boolean,
    public mimeType?: string,
    public metadata?: DocumentMetadata[]
  ) {}

  public static fromJson(json: Partial<ReceiptJson>): Receipt {
    return new Receipt(
      json.id,
      json.label ? Label.fromJson(json.label) : null,
      json.name,
      json.ocr_status,
      json.comment,
      json.date,
      json.amount,
      json.vat_amount,
      json.source,
      json.tags ? json.tags.map((tagJson: any) => (tagJson.name ? `#${tagJson.name}` : '')) : [],
      json.upload_date,
      json.bank_transactions?.length
        ? json.bank_transactions.map(bankTransactionJson => LinkedTransaction.fromJson(bankTransactionJson))
        : [],
      json.wording,
      json.accountable,
      json.mime_type,
      json.metadata?.length
        ? json.metadata.map((metadataJson: DocumentMetadataJson) => DocumentMetadata.fromJson(metadataJson))
        : []
    );
  }

  public static toJson(
    receipt: Receipt
  ): Pick<ReceiptJson, 'id' | 'label' | 'date' | 'amount' | 'vat_amount' | 'wording' | 'accountable' | 'metadata'> {
    return {
      id: receipt.id,
      label: receipt.label && receipt.label.id ? Label.toJsonId(receipt.label) : null,
      date: receipt.date,
      amount: receipt.amount,
      vat_amount: receipt.vatAmount,
      wording: receipt.wording,
      accountable: receipt.accountable,
      metadata: receipt.metadata ? receipt.metadata.map(metadata => DocumentMetadata.toJson(metadata)) : undefined
    };
  }
}
