import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';
import { BusinessUnit } from '@models/business-unit';

import { CreationRequest } from '../models/creation-request';

export class CreateCreationRequestForm extends FormGroup {
  get companyName(): FormControl<string> {
    return this.get('companyName') as FormControl<string>;
  }
  get companyNameErrorMessage(): string {
    return this.companyName.touched && this.companyName.hasError('required') ? 'Dénomination sociale requise' : null;
  }
  get userLastName(): FormControl<string> {
    return this.get('userLastName') as FormControl<string>;
  }
  get userLastNameErrorMessage(): string {
    return this.userLastName.touched && this.userLastName.hasError('required') ? 'Nom requis' : null;
  }
  get userFirstName(): FormControl<string> {
    return this.get('userFirstName') as FormControl<string>;
  }
  get userFirstNameErrorMessage(): string {
    return this.userFirstName.touched && this.userFirstName.hasError('required') ? 'Prénom requis' : null;
  }
  get businessUnit(): FormControl<BusinessUnit | null> {
    return this.get('businessUnit') as FormControl<BusinessUnit | null>;
  }
  get businessUnitErrorMessage(): ErrorMessage | null {
    return FormHelper.getEmptyErrorMessage(this.businessUnit, 'Groupe requis');
  }
  get legalForm(): FormControl<string> {
    return this.get('legalForm') as FormControl<string>;
  }
  get legalFormErrorMessage(): ErrorMessage {
    return this.legalForm.touched && this.legalForm.hasError('required')
      ? {
          error: 'required',
          message: 'Forme juridique requise'
        }
      : null;
  }
  get hasLegalPerson(): FormControl<boolean> {
    return this.get('hasLegalPerson') as FormControl<boolean>;
  }
  get hasLegalPersonErrorMessage(): string {
    return this.hasLegalPerson.touched && this.hasLegalPerson.hasError('required') ? 'Réponse requise' : null;
  }
  get companyReference(): FormControl<string> {
    return this.get('companyReference') as FormControl<string>;
  }
  get companyReferenceErrorMessage(): string {
    if (!this.companyReference.touched) {
      return null;
    }

    if (this.companyReference.hasError('required')) {
      return 'Numéro de dossier requis';
    }

    if (this.companyReference.hasError('maxlength')) {
      return 'Le numéro doit avoir 20 caractères maximum';
    }

    if (this.companyReference.hasError('pattern')) {
      return 'Le numéro ne doit pas contenir de caractères spéciaux';
    }

    return null;
  }

  constructor() {
    super({
      companyName: new FormControl<string>(null, [Validators.required]),
      userLastName: new FormControl<string>(null, [Validators.required]),
      userFirstName: new FormControl<string>(null, [Validators.required]),
      businessUnit: new FormControl<BusinessUnit | null>(null, Validators.required),
      legalForm: new FormControl<string>(null),
      hasLegalPerson: new FormControl<boolean>(null),
      companyReference: new FormControl<string>(null, [Validators.maxLength(20), Validators.pattern(/^[A-Z0-9]*$/)])
    });
  }

  toCreationRequest(): CreationRequest {
    return new CreationRequest(
      null,
      this.companyName.value,
      this.userLastName.value,
      this.userFirstName.value,
      null,
      this.businessUnit.value.id,
      this.legalForm.value,
      this.hasLegalPerson.value,
      null,
      null,
      null,
      null,
      null,
      this.companyReference.value
    );
  }
}
