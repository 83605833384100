import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';

export interface LoanAccountingPeriodDataJson {
  id: number;
  interest: number;
  accounting_period: Partial<AccountingPeriodJson>;
  outstanding_capital: number;
  repayment: number;
  insurance: number;
  capital_unlocked_amount: number;
  updated_by_accountant: boolean;
  accountable: boolean;
  accrued_interest: number;
  prepaid_interest: number;
  accrued_insurance: number;
  prepaid_insurance: number;
}

export type SerializedLoanAccountingPeriodDataJson = Omit<
  LoanAccountingPeriodDataJson,
  'accounting_period' | 'interest'
>;

export type LoanAccountingPeriodDataInterestTermValuesJson = Pick<
  LoanAccountingPeriodDataJson,
  'accrued_interest' | 'prepaid_interest' | 'accrued_insurance' | 'prepaid_insurance'
>;

export type LoanAccountingPeriodDataInterestTermValues = Pick<
  LoanAccountingPeriodData,
  'accruedInterest' | 'prepaidInterest' | 'accruedInsurance' | 'prepaidInsurance'
>;

export class LoanAccountingPeriodData {
  constructor(
    public id?: number,
    public interest?: number,
    public accountingPeriod?: AccountingPeriod,
    public outstandingCapital?: number,
    public repayment?: number,
    public insurance?: number,
    public capitalUnlockedAmount?: number,
    public updatedByAccountant?: boolean,
    public accountable?: boolean,
    public accruedInterest?: number,
    public prepaidInterest?: number,
    public accruedInsurance?: number,
    public prepaidInsurance?: number
  ) {}

  static fromJson(json: Partial<LoanAccountingPeriodDataJson>): LoanAccountingPeriodData {
    return new LoanAccountingPeriodData(
      json.id,
      json.interest,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.outstanding_capital,
      json.repayment,
      json.insurance,
      json.capital_unlocked_amount,
      json.updated_by_accountant,
      json.accountable,
      json.accrued_interest,
      json.prepaid_interest,
      json.accrued_insurance,
      json.prepaid_insurance
    );
  }

  static toJson(data: LoanAccountingPeriodData): SerializedLoanAccountingPeriodDataJson {
    return {
      id: data.id,
      outstanding_capital: data.outstandingCapital,
      repayment: data.repayment,
      insurance: data.insurance,
      capital_unlocked_amount: data.capitalUnlockedAmount,
      updated_by_accountant: data.updatedByAccountant,
      accountable: data.accountable,
      accrued_interest: data.accruedInterest,
      prepaid_interest: data.prepaidInterest,
      accrued_insurance: data.accruedInsurance,
      prepaid_insurance: data.prepaidInsurance
    };
  }
}
