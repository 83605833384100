<tiime-input-container
  contentId="task-select"
  label="Mission complémentaire"
  [attr.small]="small"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="task-select"
    [class.selected]="control.value"
    [customFindOriginValueFromOptions]="findOriginValueFromOptionsById"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
  >
    <div tiimeSelectOptions>
      <mat-option *ngFor="let task of selectorData; trackBy: trackById" [value]="task">
        {{ task.deadlineDate | date: 'dd/MM/yyyy' }}
        -
        <ng-container *ngIf="task.timeTrackingTask?.code">{{ task.timeTrackingTask?.code }} &#8226;</ng-container>
        {{ task.timeTrackingTask?.wording }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
