import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { UserInvitationChannelType } from '@enums/user-invitation-channel-type.enum';
import { PhoneHelper } from '@helpers/phone.helper';
import { ValidatorHelper } from '@helpers/validators.helper';

import { CompanyForm } from './company-form';
import { UserForm } from './user/user-form';
import { UserInvitationConfigurationForm } from './user-invitation-configuration-form';

function parametersStepValidator(): ValidatorFn {
  return (parametersStepForm: ParametersStepForm): ValidationErrors => {
    const fecFile = parametersStepForm.fecFile;
    const labelPlan = parametersStepForm.labelPlan;

    if ((labelPlan.value && !fecFile.value) || (!labelPlan.value && fecFile.value)) {
      return null;
    } else {
      return { parametersFormInvalid: 'parametersFormInvalid' };
    }
  };
}

export type CompanyStepFormType = {
  company: CompanyForm;
};

export class CompanyStepForm extends FormGroup<CompanyStepFormType> {
  get company(): CompanyForm {
    return this.controls.company;
  }

  constructor() {
    super({
      company: new CompanyForm()
    });
  }
}

export type UserStepFormType = {
  user: UserForm;
  userDoesntExist: FormControl<boolean>;
};

export class UserStepForm extends FormGroup<UserStepFormType> {
  get user(): UserForm {
    return this.controls.user;
  }

  get userDoesntExist(): FormControl<boolean> {
    return this.controls.userDoesntExist;
  }

  constructor() {
    super({
      user: new UserForm(
        [Validators.required, ValidatorHelper.emailValidator()],
        [Validators.required, PhoneHelper.phoneNumberValidator()]
      ),
      userDoesntExist: new FormControl<boolean>(false, Validators.requiredTrue)
    });
  }
}

export type InvitationStepFormType = {
  invitation: UserInvitationConfigurationForm;
};

export class InvitationStepForm extends FormGroup<InvitationStepFormType> {
  get invitation(): UserInvitationConfigurationForm {
    return this.controls.invitation;
  }

  constructor() {
    super({
      invitation: new UserInvitationConfigurationForm(false)
    });
  }
}

export type ParametersStepFormType = {
  fecFile: FormControl<File | undefined>;
  labelPlan: FormControl<number | undefined>;
  categorySetupPlan: FormControl<number | null>;
  showFecFileImport: FormControl<boolean>;
  showLabelPlanSelect: FormControl<boolean>;
  showCategorySetupPlanSelect: FormControl<boolean>;
};

export class ParametersStepForm extends FormGroup<ParametersStepFormType> {
  get labelPlan(): FormControl<number | undefined> {
    return this.controls.labelPlan;
  }

  get fecFile(): FormControl<File | undefined> {
    return this.controls.fecFile;
  }

  get categorySetupPlan(): FormControl<number | null> {
    return this.controls.categorySetupPlan;
  }

  get showFecFileImport(): FormControl<boolean> {
    return this.controls.showFecFileImport;
  }

  get showLabelPlanSelect(): FormControl<boolean> {
    return this.controls.showLabelPlanSelect;
  }

  get showCategorySetupPlanSelect(): FormControl<boolean> {
    return this.controls.showCategorySetupPlanSelect;
  }

  constructor() {
    super(
      {
        fecFile: new FormControl<File | undefined>(undefined),
        labelPlan: new FormControl<number | undefined>(undefined),
        categorySetupPlan: new FormControl<number | undefined>(null),
        showFecFileImport: new FormControl<boolean>(false),
        showLabelPlanSelect: new FormControl<boolean>(false),
        showCategorySetupPlanSelect: new FormControl<boolean>(false)
      },
      parametersStepValidator()
    );
  }
}

export type CreateCompanyFormType = {
  companyStep: CompanyStepForm;
  userStep: UserStepForm;
  invitationStep: InvitationStepForm;
  parametersStep: ParametersStepForm;
};

export class CreateCompanyForm extends FormGroup<CreateCompanyFormType> {
  get companyStep(): CompanyStepForm {
    return this.controls.companyStep;
  }

  get userStep(): UserStepForm {
    return this.controls.userStep;
  }

  get invitationStep(): InvitationStepForm {
    return this.controls.invitationStep;
  }

  get parametersStep(): ParametersStepForm {
    return this.controls.parametersStep;
  }

  constructor() {
    super({
      companyStep: new CompanyStepForm(),
      userStep: new UserStepForm(),
      invitationStep: new InvitationStepForm(),
      parametersStep: new ParametersStepForm()
    });
    this.invitationStep.invitation.channel.patchValue(UserInvitationChannelType.email);
  }
}
