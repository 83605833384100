import { IBAN_ACCEPTED_COUNTRY_CODES } from '../../constants';
import { PHONE_DOMTOM_INDICATIFS } from '../../constants/phone-domTom-indicatifs.constant';

export class RegexHelper {
  public static apeRegex = new RegExp('^[0-9]{4}[A-Z]$');
  public static frIBANRegex = new RegExp('^FR\\d{12}[A-Z\\d]{11}\\d{2}$', 'i');
  public static ibanRegex = new RegExp(`^(${IBAN_ACCEPTED_COUNTRY_CODES.join('|')})\\d{2}[A-Z\\d]{10,30}$`, 'i');
  public static mailRegex = new RegExp(
    [
      '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)',
      '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
      '[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+',
      '[a-zA-Z]{2,}))$'
    ].join('')
  );
  public static passwordRegex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$');
  public static phoneRegex = new RegExp('^(\\+([0-9]\\s?){11,12})$|^(0[1-9]([\\s-./]?[0-9]{2}){4})$');
  public static mobilePhoneRegex = new RegExp(
    `^(((\\+)(33|${PHONE_DOMTOM_INDICATIFS.map(({ indicatif }) => indicatif).join('|')})|0)[6-7](\\d{2}){4})$`
  );
  public static postcodeRegex = new RegExp('^[0-9]{2}[ ]?[0-9]{3}$|[0-9]{3}( [0-9]{2})$');
  public static siretRegex = new RegExp('^([0-9]{3} ){3}[0-9]{5}$');
}

/**
 * @deprecated use RegexHelper instead
 */
export class RegexUtils extends RegexHelper {}
