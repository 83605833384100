import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-alert',
  host: {
    '[class.primary]': '_hasHostAttributes("primary")',
    '[class.accent]': '_hasHostAttributes("accent")',
    '[class.warn]': '_hasHostAttributes("warn")',
    class: 'tiime-alert'
  },
  templateUrl: './alert.component.html',
  styleUrls: ['alert.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent extends HasAttributesBase {
  @Input() displayIcon = true;

  get alertIcon(): string {
    if (this._hasHostAttributes('accent')) {
      return 'icon-ic-select';
    } else if (this._hasHostAttributes('warn')) {
      return 'icon-alerte-input';
    }

    return 'icon-ic-info';
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
