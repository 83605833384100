import { Directive, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Mapper } from 'tiime-expert-utils';

import { StickyTableDirective } from './sticky-table.directive';
import { TableAnchorComponent } from './table-anchor.component';
import { ContainerState } from '../container';
import { PaginationData } from '../paginator/pagination-data';
import { PaginationRange } from '../paginator/pagination-range';
import { PaginatorComponent } from '../paginator/paginator.component';
import { SearchBarComponent } from '../searchbar/search-bar.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TableBase<T> {
  @ViewChild(MatSort)
  set setSort(sort: MatSort) {
    if (sort) {
      this.sort = sort;
      this.observeSort();
    }
  }
  @ViewChild(PaginatorComponent)
  set setPaginator(paginator: PaginatorComponent) {
    if (paginator) {
      this.paginator = paginator;
      this.observePaginator();
    }
  }
  @ViewChild(SearchBarComponent)
  set setSearchBar(searchbar: SearchBarComponent) {
    if (searchbar) {
      this.searchbar = searchbar;
      this.observeSearch();
    }
  }
  @ViewChild(StickyTableDirective)
  set setStickyTable(stickyTable: StickyTableDirective) {
    if (stickyTable) {
      stickyTable.updateObserversAndSentinels();
    }
  }
  @ViewChild(TableAnchorComponent)
  tableAnchorComponent: TableAnchorComponent;

  currentState: ContainerState;
  isLoading: boolean;
  paginationData: PaginationData<T> = new PaginationData<T>([], undefined);
  readonly ContainerState = ContainerState;

  protected paginator: PaginatorComponent;
  protected searchbar: SearchBarComponent;
  protected sort: MatSort;
  protected paginatorSub: Subscription;
  protected searchBarSub: Subscription;
  protected sortSub: Subscription;
  protected onPaginateInTableSub: Subscription;
  protected onSearchInTableSub: Subscription;
  protected onSortInTableSub: Subscription;

  protected get searchValue(): string {
    return this.searchbar ? this.searchbar.searchbarForm.search.value : null;
  }
  protected get sortValue(): Sort {
    return this.sort
      ? {
          active: this.sort.active,
          direction: this.sort.direction
        }
      : null;
  }

  readonly mapToDisabledPaginator: Mapper<ContainerState, boolean> = (currentState: ContainerState) =>
    currentState !== ContainerState.done;
  readonly mapToDisabledSearchBar: Mapper<ContainerState, boolean> = (currentState: ContainerState) =>
    currentState !== ContainerState.done && currentState !== ContainerState.noResult;
  readonly mapToDisabledSort: Mapper<ContainerState, boolean> = (currentState: ContainerState) =>
    currentState !== ContainerState.done;

  resetPaginationData(): void {
    this.paginationData = new PaginationData<T>([], undefined);
  }

  resetSort(): void {
    if (this.sort) {
      this.sort.active = null;
      this.sort.direction = null;
    }
  }

  setState(newState: ContainerState): void {
    this.currentState = newState;
  }

  scrollToAnchor(): void {
    if (!this.tableAnchorComponent) {
      return;
    }
    this.tableAnchorComponent.scrollTo();
  }

  protected observePaginator(): void {
    if (this.paginatorSub) {
      this.paginatorSub.unsubscribe();
    }
    this.paginatorSub = this.paginator.rangeEvent
      .pipe(tap((range: PaginationRange) => this.paginateInTable(range)))
      .subscribe();
  }

  protected observeSearch(): void {
    if (this.searchBarSub) {
      this.searchBarSub.unsubscribe();
    }
    this.searchBarSub = this.searchbar.search
      .pipe(tap((searchTerms: string) => this.searchInTable(searchTerms)))
      .subscribe();
  }

  protected observeSort(): void {
    if (this.sortSub) {
      this.sortSub.unsubscribe();
    }
    this.sortSub = this.sort.sortChange.pipe(tap((sort: Sort) => this.sortInTable(sort))).subscribe();
  }

  abstract paginateInTable(range: PaginationRange): void;

  abstract searchInTable(searchTerms: string): void;

  abstract sortInTable(sort: Sort): void;
}
