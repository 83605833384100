<div tiimeForm class="thread-form">
  <div tiimeFormColumn>
    <mat-button-toggle-group [formControl]="threadForm.controls.category" (change)="categoryChange($event)">
      <mat-button-toggle [value]="ThreadCategory.accountant">Comptable</mat-button-toggle>
      <mat-button-toggle [value]="ThreadCategory.customer">Client</mat-button-toggle>
    </mat-button-toggle-group>
    <tiime-alert primary>
      <ng-container
        *ngIf="threadForm.controls.category.value === ThreadCategory.accountant; else customerAlertTemplate"
      >
        Vous allez créer une discussion interne. Votre client ne pourra pas la consulter et interagir avec. Il n’est pas
        possible de passer une discussion interne en discussion client.
      </ng-container>
      <ng-template #customerAlertTemplate>
        Vous allez créer une discussion avec votre client, votre client pourra donc la consulter et interagir avec
        depuis ses applications. Il n’est pas possible de passer une discussion client en discussion interne.
      </ng-template>
    </tiime-alert>
    <app-thread-entity-type-select
      [control]="threadForm.controls.entityType"
      [selectData]="threadForm.controls.category.value | threadEntityTypesByCategory"
      [errorMessage]="threadForm.entityTypeErrorMessage"
      emptyOptionLabel=""
      (selectionChange)="entityTypeChange()"
    ></app-thread-entity-type-select>
    <ng-container [ngSwitch]="threadForm.controls.entityType.value">
      <app-accounting-period-select
        [accountingPeriodFilter]="threadForm.controls.entity"
        [accountingPeriods]="accountingPeriods"
        [errorMessage]="threadForm.entityErrorMessage"
        label="Exercice"
        placeholder="Exercice"
        *ngSwitchCase="ThreadEntityType.accountingPeriod"
      ></app-accounting-period-select>
      <app-vat-declaration-select
        [control]="threadForm.controls.entity"
        [errorMessage]="threadForm.entityErrorMessage"
        [selectData]="vatDeclarations"
        *ngSwitchCase="ThreadEntityType.vatDeclaration"
      ></app-vat-declaration-select>
      <app-task-select
        [control]="threadForm.controls.entity"
        [errorMessage]="threadForm.entityErrorMessage"
        [selectData]="taskPlannings"
        *ngSwitchCase="ThreadEntityType.task"
      ></app-task-select>
      <app-transaction-select
        [company]="company"
        [control]="threadForm.controls.entity"
        [errorMessage]="threadForm.entityErrorMessage"
        *ngSwitchCase="ThreadEntityType.bankTransaction"
      ></app-transaction-select>
      <app-corporate-tax-instalment-select
        [control]="threadForm.controls.entity"
        [errorMessage]="threadForm.entityErrorMessage"
        [selectData]="corporateTaxInstalments"
        *ngSwitchCase="ThreadEntityType.corporateTaxInstalment"
      ></app-corporate-tax-instalment-select>
    </ng-container>
    <app-contributor-select-multiple
      [contributors]="contributors"
      [contributorFormControl]="threadForm.controls.interlocutors"
      [interlocutors]="companyContributors"
      label="Interlocuteurs"
      *ngIf="threadForm.controls.category.value === ThreadCategory.accountant"
    ></app-contributor-select-multiple>
    <tiime-input-container contentId="message" label="Message" [errorMessage]="threadForm.messageErrorMessage?.message">
      <textarea
        id="message"
        placeholder="Message"
        [formControl]="threadForm.controls.message"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        #messageInput
      ></textarea>
    </tiime-input-container>
    <app-thread-tag-select-multiple
      [control]="threadForm.controls.tags"
      [tags]="tags"
      *ngIf="threadForm.controls.category.value === ThreadCategory.accountant"
    ></app-thread-tag-select-multiple>
  </div>
</div>
