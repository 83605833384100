import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ContentChildren, Directive, Input, QueryList, Renderer2 } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

@Directive({
  selector: '[appVirtualScrollViewportSize]',
  standalone: true
})
export class VirtualScrollViewportSizeDirective {
  @ContentChildren(MatOption) set setVirtualScrollHeight(options: QueryList<MatOption>) {
    if (!options) {
      return;
    }

    this.setCdkVirtualScrollViewport(options.toArray());
  }

  @Input() itemSize = 35;
  @Input() maxDisplayedOptions = 6;

  constructor(private renderer: Renderer2, private cdkVirtualScrollViewport: CdkVirtualScrollViewport) {}

  private setCdkVirtualScrollViewport(options: MatOption[]): void {
    if (!this.cdkVirtualScrollViewport) {
      return;
    }

    const optionsToDisplay = options.length >= this.maxDisplayedOptions ? this.maxDisplayedOptions : options.length;
    this.renderer.setStyle(
      this.cdkVirtualScrollViewport.elementRef.nativeElement,
      'height',
      `${optionsToDisplay * this.itemSize}px`
    );
    this.cdkVirtualScrollViewport.checkViewportSize();
  }
}
