<tiime-input-container
  tiimeFormInputContainer
  contentId="businessUnit"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="businessUnit"
    [formControl]="control"
    [customValue]="control.value ? control.value.name : ''"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
    data-cy="business-unit-select"
  >
    <input
      tiimeSelectInput
      [placeholder]="searchPlaceholder"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions data-cy="business-unit-select-options">
      <mat-option *ngIf="allowEmptyOption" [value]="null"></mat-option>
      <mat-option
        [value]="businessUnit"
        *ngFor="let businessUnit of filteredSelectorData | sortByKey: 'name'; trackBy: trackByIndex"
      >
        {{ businessUnit.name }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
