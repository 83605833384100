import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';

import { EMPTY, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import {
  API_ROUTE_REPLACE_ACCOUNTING_PERIOD_KEY,
  API_ROUTE_REPLACE_BUSINESS_UNIT_KEY,
  API_ROUTE_REPLACE_COMPANY_KEY,
  API_ROUTE_REPLACE_PARTNER_KEY
} from '@constants/api-routes.constant';
import { DateHelper } from '@helpers/date.helper';
import { FileSaverData } from '@services/file.service';

import { TemporaryEncoder } from '../temporary-encoder';

export type FiltersParams = Record<string, string | boolean | number>;

export class HttpHelper {
  static extractFilenameFromHeaders(httpHeaders: HttpHeaders): string {
    const contentDisposition = httpHeaders.get('Content-Disposition');
    const filenameRegex = /filename=/gm;
    const filenameStarRegex = /filename\*=/gm;
    if (filenameStarRegex.test(contentDisposition)) {
      return contentDisposition
        .substring(contentDisposition.indexOf('filename*='), contentDisposition.length)
        .split('=')[1]
        .replace(`utf-8''`, '');
    } else if (filenameRegex.test(contentDisposition)) {
      return contentDisposition
        .substring(contentDisposition.indexOf('filename='), contentDisposition.length)
        .split('=')[1];
    }
    return '';
  }

  static getFileSaverData(blobObservable: Observable<HttpResponse<Blob>>): Observable<FileSaverData> {
    return blobObservable.pipe(
      switchMap((response: HttpResponse<Blob>) => {
        if (response.status === 200) {
          if (response.body.size === 0) {
            return EMPTY;
          }

          const filename = HttpHelper.extractFilenameFromHeaders(response.headers);
          return of({ file: response.body, filename });
        } else if (response.status === 204) {
          return throwError('no-content');
        }
      })
    );
  }

  static getListBody<T>(range: PaginationRange, body: T[]): T[] {
    if (body.length > range.max - range.min + 1) {
      body.pop();
    }
    return body;
  }

  static getRangeFromResponseHeaders(headers: HttpHeaders, pageSize: number): PaginationRange {
    const contentRange = headers.get('Content-Range');
    const range = PaginationRange.fromHttpResponseHeaders(contentRange, pageSize);
    if (contentRange && range.count === '*') {
      range.max -= 1;
    }
    return range;
  }

  static mapToPaginationData<T, R = any>(
    range: PaginationRange,
    mapJsonToEntity: (_: R) => T
  ): OperatorFunction<HttpResponse<R[]>, PaginationData<T>> {
    return map((response: HttpResponse<R[]>) => {
      const responseRange: PaginationRange = HttpHelper.getRangeFromResponseHeaders(response.headers, range.pageSize);
      const items: T[] = HttpHelper.getListBody(range, response.body ?? []).map(mapJsonToEntity);
      return new PaginationData(items, responseRange);
    });
  }

  static setQParam(searchTerms: string, params: HttpParams = HttpHelper.createHttpParams()): HttpParams {
    if (searchTerms) {
      params = params.set('q', searchTerms);
    }
    return params;
  }

  static setExpandParam(expand: string, params: HttpParams = HttpHelper.createHttpParams()): HttpParams {
    if (expand?.length > 0) {
      params = params.set('expand', expand);
    }
    return params;
  }

  static setRangeHeader(headers: HttpHeaders, range: PaginationRange): HttpHeaders {
    const maxRangePlusOne = new PaginationRange(range.min, range.max);
    maxRangePlusOne.max += 1;
    headers = headers.set('Range', maxRangePlusOne.toHttpRequestHeader());
    return headers;
  }

  static setSortParam(sort: Sort, params: HttpParams = HttpHelper.createHttpParams()): HttpParams {
    params = sort && sort.active && sort.direction ? params.set('sorts', HttpHelper.sortToString(sort)) : params;
    return params;
  }

  static setParam(key: string, value: string, params: HttpParams = HttpHelper.createHttpParams()): HttpParams {
    return params.set(key, value);
  }

  static setParams(
    jsonParams: Record<string, any>,
    params: HttpParams = HttpHelper.createHttpParams(),
    excludeNull: boolean = true
  ): HttpParams {
    if (jsonParams) {
      Object.keys(jsonParams).forEach(key => {
        // we don't use if(jsonParams[key]) to avoid that if the jsonParams[key] is a boolean and its value is false
        if ((jsonParams[key] !== null || (!excludeNull && jsonParams[key] === null)) && jsonParams[key] !== undefined) {
          switch (key) {
            case 'sort':
            case 'sorts':
              params = params.set('sorts', HttpHelper.sortToString(jsonParams[key]));
              break;
            default:
              params = params.set(key, HttpHelper.paramToString(jsonParams[key]));
          }
        }
      });
    }
    return params;
  }

  static createHttpParams(): HttpParams {
    return new HttpParams({ encoder: new TemporaryEncoder() });
  }

  static idsFilter(model: { id?: number }[], joinChar: '|' | ',' = '|'): string {
    return model.map(({ id }) => id).join(joinChar);
  }

  static dateFilter(startDate: string, endDate: string): string {
    return `${startDate ? `>=${DateHelper.format(startDate)}` : ''}${startDate && endDate ? ',' : ''}${
      endDate ? `<=${DateHelper.format(endDate)}` : ''
    }`;
  }

  static replaceCompanyUrl(url: string, companyId: number): string {
    return url.replace(API_ROUTE_REPLACE_COMPANY_KEY, companyId.toString());
  }

  static replacePartnerUrl(url: string, partnerId: number): string {
    return url.replace(API_ROUTE_REPLACE_PARTNER_KEY, partnerId.toString());
  }

  static replaceBusinessUnitUrl(url: string, businessUnitId: number): string {
    return url.replace(API_ROUTE_REPLACE_BUSINESS_UNIT_KEY, businessUnitId.toString());
  }

  static replaceAccountingPeriodUrl(url: string, accountingPeriodId: number): string {
    return url.replace(API_ROUTE_REPLACE_ACCOUNTING_PERIOD_KEY, accountingPeriodId.toString());
  }

  static sanitizeUrl(url: string): string {
    return url.replace(/\?.*$/gi, '');
  }

  static toJsonProperty<T extends string | number | boolean | null | undefined>(value: T): T | null {
    if (typeof value === 'string' && value === '') {
      return null;
    }

    return value;
  }

  static toJsonId<T extends { id?: number }>(value: T): { id: number } {
    return { id: value?.id };
  }

  static sortToString(sort: Sort | string): string {
    if (typeof sort !== 'string') {
      return `${sort.active}:${sort.direction}`;
    }
    return sort;
  }

  private static paramToString(param: string | boolean): string {
    if (typeof param === 'boolean') {
      return String(param);
    }
    return param;
  }
}
