import { DateHelper } from '@helpers/date.helper';

import { CompanyRelatedAccountEnum } from '../enums/company-related-account.enum';

export interface ScopeJson {
  convention_beneficiary_account_founded: boolean;
  convention_beneficiary_account_abandoned: boolean;
  convention_beneficiary_account_repossessed: boolean;
  bsa: boolean;
  other_conventions: boolean;
  result_capital_loss: boolean;
  result_restored_capital: boolean;
  dividends_to_be_expected: boolean;
  accounts_approval_by_hand: boolean;
  dividends_distribution_date: string;
  dividends_amount: number;
  company_related_account: CompanyRelatedAccountEnum;
  dispensation_additional_deposit: boolean;
}

export class Scope {
  constructor(
    public conventionBeneficiaryAccountFounded: boolean,
    public conventionBeneficiaryAccountAbandoned: boolean,
    public conventionBeneficiaryAccountRepossessed: boolean,
    public bsa: boolean,
    public otherConventions: boolean,
    public resultCapitalLoss: boolean,
    public resultRestoredCapital: boolean,
    public dividendsToBeExpected: boolean,
    public accountsApprovalByHand: boolean,
    public dividendsDistributionDate?: Date,
    public dividendsAmount?: number,
    public companyRelatedAccount?: CompanyRelatedAccountEnum,
    public dispensationAdditionalDeposit?: boolean
  ) {}

  static fromJson(json: ScopeJson): Scope {
    return new Scope(
      json.convention_beneficiary_account_founded,
      json.convention_beneficiary_account_abandoned,
      json.convention_beneficiary_account_repossessed,
      json.bsa,
      json.other_conventions,
      json.result_capital_loss,
      json.result_restored_capital,
      json.dividends_to_be_expected,
      json.accounts_approval_by_hand,
      json.dividends_distribution_date ? new Date(json.dividends_distribution_date) : null,
      json.dividends_amount,
      json.company_related_account,
      json.dispensation_additional_deposit
    );
  }

  static toJson(scope: Scope): ScopeJson {
    return {
      convention_beneficiary_account_founded: scope.conventionBeneficiaryAccountFounded,
      convention_beneficiary_account_abandoned: scope.conventionBeneficiaryAccountAbandoned,
      convention_beneficiary_account_repossessed: scope.conventionBeneficiaryAccountRepossessed,
      bsa: scope.bsa,
      other_conventions: scope.otherConventions,
      result_capital_loss: scope.resultCapitalLoss,
      result_restored_capital: scope.resultRestoredCapital,
      dividends_to_be_expected: scope.dividendsToBeExpected,
      accounts_approval_by_hand: scope.accountsApprovalByHand,
      dividends_distribution_date: scope.dividendsDistributionDate
        ? DateHelper.format(scope.dividendsDistributionDate)
        : null,
      dividends_amount: scope.dividendsAmount,
      company_related_account: scope.companyRelatedAccount,
      dispensation_additional_deposit: scope.dispensationAdditionalDeposit
    };
  }
}
