import { Directive, EventEmitter, Output } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class DialogDirectiveBase {
  @Output() readonly dialogLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly dialogAfterClose: EventEmitter<void> = new EventEmitter<void>();

  isLoading = false;

  protected afterClose(): void {
    this.dialogAfterClose.emit();
  }

  protected beginLoading(): void {
    this.isLoading = true;
    this.dialogLoading.emit(true);
  }

  protected stopLoading(): void {
    this.isLoading = false;
    this.dialogLoading.emit(false);
  }
}
