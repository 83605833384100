import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { TiimeButtonModule, TiimeDialogModule, TiimeInputContainerModule } from 'tiime-material';

import { CreateOrUpdateLabelsCategoryPlanDialogComponent } from './create-or-update-labels-category-plan-dialog.component';

@NgModule({
  declarations: [CreateOrUpdateLabelsCategoryPlanDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeButtonModule,
    TiimeDialogModule,
    MatDialogModule
  ]
})
export class CreateOrUpdateLabelsCategoryPlanDialogModule {}
