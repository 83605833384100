import { LegalRegionalBranch } from '@enums/legal-regional-branch.enum';
import { HttpHelper } from '@helpers/http.helper';
import { ApeCode, ApeCodeJson } from '@models/ape-code';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { CompanyRegistry, CompanyRegistryJson } from '@models/company-registry';
import { Signatory, SignatoryJson } from '@models/signatory';

import { LegalStructureAndSignatory } from '../interfaces/legal-structure-and-signatory';

export interface LegalStructureJson {
  id?: number;
  legal_form?: string;
  name?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  siret?: string;
  intracom_vat_number?: string;
  share_capital?: number;
  ape_code?: ApeCodeJson;
  legal_regional_branch?: LegalRegionalBranch;
  phone?: string;
  email?: string;
  website?: string;
  business_units?: Partial<BusinessUnitJson>[];
  signatories?: Partial<SignatoryJson>[];
  archived?: boolean;
  archive_status_at?: string;
  archive_status_by?: BusinessUserJson;
  default_signatory?: Partial<SignatoryJson>;
  company_registry?: CompanyRegistryJson;
}

export type LegalStructureSerialized = Omit<LegalStructureJson, 'archive_status_at' | 'archive_status_by'>;

export class LegalStructure {
  constructor(
    public id?: number,
    public legalForm?: string,
    public name?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public siret?: string,
    public intracomVatNumber?: string,
    public shareCapital?: number,
    public apeCode?: ApeCode,
    public legalRegionalBranch?: LegalRegionalBranch,
    public phone?: string,
    public email?: string,
    public website?: string,
    public businessUnits?: BusinessUnit[],
    public signatories?: Signatory[],
    public archived?: boolean,
    public archivedAt?: string,
    public archivedBy?: BusinessUser,
    public defaultSignatory?: Signatory,
    public companyRegistry?: CompanyRegistry
  ) {}

  static fromJson(json: LegalStructureJson): LegalStructure {
    return new LegalStructure(
      json.id,
      json.legal_form,
      json.name,
      json.street,
      json.postal_code,
      json.city,
      json.siret,
      json.intracom_vat_number,
      json.share_capital,
      json.ape_code ? ApeCode.fromJson(json.ape_code) : undefined,
      json.legal_regional_branch,
      json.phone,
      json.email,
      json.website,
      json.business_units
        ? json.business_units.map((businessUnitJson: BusinessUnitJson) => BusinessUnit.fromJson(businessUnitJson))
        : null,
      json.signatories
        ? json.signatories.map((signatoryJson: SignatoryJson) => Signatory.fromJson(signatoryJson))
        : null,
      json.archived,
      json.archive_status_at,
      json.archive_status_by ? BusinessUser.fromJson(json.archive_status_by) : null,
      json.default_signatory ? Signatory.fromJson(json.default_signatory) : null,
      json.company_registry ? CompanyRegistry.fromJson(json.company_registry) : undefined
    );
  }

  static toJson(legalStructure: LegalStructure): LegalStructureSerialized {
    return {
      id: legalStructure.id,
      legal_form: legalStructure.legalForm,
      name: legalStructure.name,
      street: legalStructure.street,
      postal_code: legalStructure.postalCode,
      city: legalStructure.city,
      siret: legalStructure.siret,
      intracom_vat_number: legalStructure.intracomVatNumber,
      share_capital: legalStructure.shareCapital,
      ape_code: legalStructure.apeCode?.id ? ApeCode.toJson(legalStructure.apeCode) : undefined,
      legal_regional_branch: legalStructure.legalRegionalBranch,
      phone: legalStructure.phone,
      email: legalStructure.email,
      website: legalStructure.website,
      business_units: legalStructure.businessUnits
        ? legalStructure.businessUnits.map((businessUnit: BusinessUnit) => HttpHelper.toJsonId(businessUnit))
        : null,
      signatories: legalStructure.signatories
        ? legalStructure.signatories.map((signatory: Signatory) => HttpHelper.toJsonId(signatory))
        : null,
      archived: legalStructure.archived,
      default_signatory: legalStructure.defaultSignatory
        ? HttpHelper.toJsonId(legalStructure.defaultSignatory)
        : undefined,
      company_registry: legalStructure.companyRegistry?.id
        ? CompanyRegistry.toJson(legalStructure.companyRegistry)
        : undefined
    };
  }

  static toLegalStructureAndSignatories(legalStructure: LegalStructure): LegalStructureAndSignatory[] {
    return legalStructure.signatories
      .filter((signatory: Signatory) => !signatory.archived)
      .reduce(
        (acc: LegalStructureAndSignatory[], signatory: Signatory) => [...acc, { legalStructure, signatory }],
        [] as LegalStructureAndSignatory[]
      );
  }
}
