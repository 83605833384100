<tiime-form [tiimeFormExpanded]="tiimeFormExpanded">
  <div tiimeFormTitle>Adresse</div>
  <div class="address-form" [formGroup]="addressForm">
    <tiime-input-container class="address-input" label="Adresse" contentId="mailingStreet">
      <input
        id="mailingStreet"
        type="text"
        [formControl]="addressForm.mailingStreet"
        placeholder="Adresse"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container label="Code postal" contentId="mailingPostalCode">
      <input
        id="mailingPostalCode"
        type="text"
        [formControl]="addressForm.mailingPostalCode"
        placeholder="Code postal"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container label="Ville" contentId="mailingCity">
      <input
        id="mailingCity"
        type="text"
        [formControl]="addressForm.mailingCity"
        placeholder="Ville"
        autocomplete="off"
      />
    </tiime-input-container>
    <app-country-select
      label="Pays"
      [control]="addressForm.mailingCountry"
      [selectData]="countries$ | async"
    ></app-country-select>
  </div>
</tiime-form>
