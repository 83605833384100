<div class="complex-search-bar-wrapper" matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin">
  <div class="complex-search-bar-icn" #origin>
    <mat-icon inline="true" fontIcon="icon-ic-search"></mat-icon>
  </div>
  <mat-chip-list #chipList>
    <mat-basic-chip
      *ngFor="let filter of filters; let i = index; trackBy: trackByIndex"
      [selectable]="false"
      (removed)="removeFilter(i)"
    >
      {{ filter.displayedValue }}
      <span matChipRemove data-icon="&#xe009;"></span>
    </mat-basic-chip>
    <input
      autocomplete="off"
      class="complex-search-bar"
      [placeholder]="placeholder"
      [formControl]="filterControl"
      [matAutocomplete]="complexSearchBarAutocomplete.autocomplete"
      [matAutocompleteConnectedTo]="autocompleteOrigin"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="matChipInputTokenEnd($event)"
    />
    <tiime-complex-search-bar-autocomplete
      #complexSearchBarAutocomplete="ComplexSearchBarAutocomplete"
      [sections]="filteredComplexSearchBarAutocompleteSections$ | async"
      (selectFilter)="onSelectAutocompleteFilter($event)"
    ></tiime-complex-search-bar-autocomplete>
  </mat-chip-list>
  <div class="complex-search-bar-icn clear" *ngIf="filters.length > 0" (click)="clearFilters()">
    <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
  </div>
</div>
