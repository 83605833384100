import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { noop } from '@helpers/noop.helper';

type OnChangeFn<T> = (value: T) => void;

type OnTouchFn = () => void;

@Component({
  standalone: true,
  selector: 'app-day-select',
  templateUrl: './day-select.component.html',
  styleUrls: ['./day-select.component.scss'],
  imports: [TiimeInputContainerModule, TiimeFormModule, TiimeSelectModule, MatOptionModule, CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DaySelectComponent, multi: true }]
})
export class DaySelectComponent implements ControlValueAccessor {
  @Input() required: boolean;
  @Input() errorMessage: string;
  @Input() contentId = 'daySelect';
  @Input() label = 'Jour';
  @Input() days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  @Input() allowEmptyOption = false;

  value: number | null = null;
  disabled = false;

  onChangeFn: OnChangeFn<number> = noop;
  onTouchFn: OnTouchFn = noop;

  registerOnChange(fn: OnChangeFn<number>): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  trackByValue(_index: number, day: number): number {
    return day;
  }
}
