import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';

import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { SecondaryFiltersComponent } from './secondary-filters.component';

@NgModule({
  imports: [CommonModule, TiimeButtonModule, TiimeIconModule, MatBadgeModule, OverlayModule],
  declarations: [SecondaryFiltersComponent],
  exports: [SecondaryFiltersComponent]
})
export class TiimeSecondaryFiltersModule {}
