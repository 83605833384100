export const PHONE_DOMTOM_INDICATIFS = [
  { name: 'Guadeloupe', indicatif: 590 },
  { name: 'Guyane', indicatif: 594 },
  { name: 'La Réunion', indicatif: 262 },
  { name: 'Martinique', indicatif: 596 },
  { name: 'Mayotte', indicatif: 269 },
  { name: 'Nouvelle-Calédonie', indicatif: 687 },
  { name: 'Polynésie Française', indicatif: 689 },
  { name: 'Saint-Barthélemy', indicatif: 590 },
  { name: 'Saint-Martin', indicatif: 590 },
  { name: 'Saint-Pierre-et-Miquelon', indicatif: 508 },
  { name: 'Wallis-et-Futuna', indicatif: 681 }
] as const;
