<div class="tiime-stepper-header">
    <ng-container *ngFor="let step of steps; trackBy: trackByIndex; let i = index; let isLast = last">
        <div class="tiime-step-header" [attr.aria-disabled]="stepIsNavigable(i, step) ? null : true"
            [class.selected]="selectedIndex === i" [class.done]="stepIsDone(i, step)" (click)="selectStep(i, step)">
            <div class="tiime-step-icon">
                {{ i | map: mapToStepNumber }}
            </div>
            <span class="tiime-step-label" *ngIf="step.label">
                {{ step.label }}
            </span>
        </div>
        <div class="tiime-step-divider" *ngIf="!isLast"></div>
    </ng-container>
</div>
<div class="tiime-stepper-content">
    <div [ngTemplateOutlet]="selected.content"></div>
</div>