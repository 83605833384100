import { AccountantDetailRequestType } from '@enums/accountant-detail-request-type.enum';
import { AccountantDetailRequest, ReceiptAccountantDetailRequest } from '@models/accountant-detail-request';

export abstract class TransactionBase {
  get receiptAccountantDetailRequests(): AccountantDetailRequest[] {
    const receiptAccountantDetailRequests = this.accountantDetailRequests?.filter(
      (accountantDetailRequest: AccountantDetailRequest) =>
        accountantDetailRequest.type === AccountantDetailRequestType.receipt
    );
    return receiptAccountantDetailRequests || [];
  }

  constructor(public accountantDetailRequests?: AccountantDetailRequest[]) {}

  getAccountantDetailRequest(
    accountantDetailRequestType: AccountantDetailRequestType.receipt
  ): ReceiptAccountantDetailRequest;
  getAccountantDetailRequest(accountantDetailRequestType: AccountantDetailRequestType): AccountantDetailRequest;
  getAccountantDetailRequest(accountantDetailRequestType: AccountantDetailRequestType): AccountantDetailRequest {
    return this.accountantDetailRequests?.find(
      accountantDetailRequest => accountantDetailRequest.type === accountantDetailRequestType
    );
  }
}
