import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-business-user-slug',
  standalone: true,
  templateUrl: './business-user-slug.component.html',
  styleUrls: ['./business-user-slug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.min-width]': 'businessUserAcronym?.length > 2 ? undefined : size',
    '[style.height]': 'size',
    '[style.line-height]': 'size',
    '[style.border-radius]': 'size',
    '[style.font-size]': 'fontSize',
    '[style.padding-left]': `businessUserAcronym?.length > 2 ? '5px' : 0`,
    '[style.padding-right]': `businessUserAcronym?.length > 2 ? '5px' : 0`
  }
})
export class BusinessUserSlugComponent {
  @Input() businessUserAcronym: string;
  @Input() businessUserColor: string;
  @Input() fontSize = '9px';
  @Input() size = '20px';
}
