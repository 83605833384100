import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { FormUtils } from 'tiime-expert-utils';

import { SearchBarForm } from './search-bar-form';
import { HasAttributesBase } from '../core/index';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tiime-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent extends HasAttributesBase implements OnInit {
  @Input()
  debounceTime = 500;
  @Input()
  placeholder: string;

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.searchbarForm.disable(FormUtils.shouldNotEmitEvent);
    } else {
      this.searchbarForm.enable(FormUtils.shouldNotEmitEvent);
    }
  }

  @Input() searchbarForm: SearchBarForm = new SearchBarForm();

  @Output() readonly search: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  focused = false;
  showClearButton = false;

  private searchSub: Subscription;

  get searchBarClass(): { active: boolean; disabled: boolean; dark: boolean } {
    return {
      active: this.focused || !!this.searchbarForm.search.value,
      disabled: this.searchbarForm.disabled,
      dark: this._hasHostAttributes('dark')
    };
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit(): void {
    this.observeSearch();
  }

  clearSearch(): void {
    this.searchbarForm.reset();
    this.searchInput.nativeElement.focus();
  }

  setFocused(focus: boolean): void {
    this.focused = focus;
  }

  private observeSearch(): void {
    this.searchSub = this.searchbarForm.search.valueChanges
      .pipe(
        tap(searchTerms => (this.showClearButton = !!searchTerms)),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe((searchTerms: string) => this.search.emit(searchTerms));
  }
}
