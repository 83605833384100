import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeInputContainerModule, TiimeFormModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { VatExonerationType } from '@models/vat-exoneration-type';
import { ListErrorComponent } from '@modules/shared/components/list-error/list-error.component';

@Component({
  selector: 'app-vat-exoneration-type-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    MatOptionModule,
    ListErrorComponent
  ],
  templateUrl: './vat-exoneration-type-select.component.html',
  styleUrls: ['./vat-exoneration-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatExonerationTypeSelectComponent extends SelectBase<VatExonerationType> {
  @Input() label = `Motif d'exonération`;

  trackByName(_index: number, value: VatExonerationType): string {
    return value.name;
  }
}
