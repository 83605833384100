import { DateHelper } from '@helpers/date.helper';

export interface HubConnectionTokenJson {
  token?: string;
  expires_at?: string;
}

export class HubConnectionToken {
  constructor(public token?: string, public expiresAt?: string) {}

  static fromJson(json: HubConnectionTokenJson): HubConnectionToken {
    return new HubConnectionToken(json.token, DateHelper.fromUtc(json.expires_at).toString());
  }
}
