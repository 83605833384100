import { createAction, props } from '@ngrx/store';

import { AccountingConfig } from '@models/accounting-config';

export const LOAD = '[AccountingConfig] Load';
export const UPDATE = '[AccountingConfig] Update';
export const UPDATE_PARTIAL = '[AccountingConfig] Update partial';
export const RESET = '[AccountingConfig] Reset';
export const REMOVE_CUSTOM_END_DATE = '[AccountingConfig] Remove custom end date';

export const load = createAction(LOAD);
export const update = createAction(UPDATE, props<{ accountingConfig: AccountingConfig }>());
export const updatePartial = createAction(UPDATE_PARTIAL, props<{ accountingConfig: Partial<AccountingConfig> }>());
export const reset = createAction(RESET);
export const removeCustomEndDate = createAction(REMOVE_CUSTOM_END_DATE);
