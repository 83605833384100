import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { NgUtils } from 'tiime-expert-utils';
import {
  AutoSaveStatusComponent,
  TableAnchorComponent,
  TiimeAutoSaveStatusModule,
  TiimeTableModule
} from 'tiime-material';

import {
  BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION,
  BANK_PCG_ENTRY_SALES_CUSTOM_OPTION,
  PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION
} from '@constants/piece-pcg-entry.constant';
import { LabelCategoryPlanSetupForm } from '@forms/label-category-plan-setup-form';
import { VatExonerationType } from '@models/vat-exoneration-type';
import { VatType } from '@models/vat-type';
import { SimpleCellComponent } from '@modules/shared/cells/simple-cell/simple-cell.component';
import { ListErrorComponent } from '@modules/shared/components/list-error/list-error.component';
import { FormUpdateOnBlurDirective } from '@modules/shared/directives/form-update-on-blur/form-update-on-blur.directive';
import { VatExonerationTypePipe } from '@modules/shared/pipes/vat-exoneration-type/vat-exoneration-type.pipe';
import { VatExonerationTypeRequiredPipe } from '@modules/shared/pipes/vat-exoneration-type-required/vat-exoneration-type-required.pipe';
import { VatTypeRatePipe } from '@modules/shared/pipes/vat-type-rate/vat-type-rate.pipe';
import { VatExonerationTypeSelectComponent } from '@modules/shared/selectors/vat-exoneration-type-select/vat-exoneration-type-select.component';
import { VatTypeSelectComponent } from '@modules/shared/selectors/vat-type-select/vat-type-select.component';

@Component({
  selector: 'app-label-category-plan-setup-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeTableModule,
    TiimeAutoSaveStatusModule,
    MatAutocompleteModule,
    MatOptionModule,
    SimpleCellComponent,
    ListErrorComponent,
    VatTypeSelectComponent,
    VatExonerationTypeSelectComponent,
    FormUpdateOnBlurDirective,
    VatExonerationTypeRequiredPipe,
    VatTypeRatePipe,
    VatExonerationTypePipe
  ],
  templateUrl: './label-category-plan-setup-table.component.html',
  styleUrls: ['./label-category-plan-setup-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelCategoryPlanSetupTableComponent {
  @Input() displayedColumns: string[];
  @Input() data: LabelCategoryPlanSetupForm[];
  @Input() vatTypeRequiringVatExonerationType: VatType;
  @Input() vatTypes: VatType[];
  @Input() vatExonerationTypes: VatExonerationType[];
  @Input() mode: 'form' | 'text' = 'form';

  @Output() readonly bankAccountNumberChanges: EventEmitter<LabelCategoryPlanSetupForm> =
    new EventEmitter<LabelCategoryPlanSetupForm>();
  @Output() readonly pieceAccountNumberChanges: EventEmitter<LabelCategoryPlanSetupForm> =
    new EventEmitter<LabelCategoryPlanSetupForm>();
  @Output() readonly vatTypeIdChanges: EventEmitter<LabelCategoryPlanSetupForm> =
    new EventEmitter<LabelCategoryPlanSetupForm>();
  @Output() readonly vatExonerationTypeChanges: EventEmitter<LabelCategoryPlanSetupForm> =
    new EventEmitter<LabelCategoryPlanSetupForm>();

  @ViewChild(TableAnchorComponent) tableAnchorComponent: TableAnchorComponent;

  @ViewChildren(AutoSaveStatusComponent) autoSaveStatusComponent: QueryList<AutoSaveStatusComponent>;

  readonly scrollContainer: HTMLElement;
  readonly trackByFormId = NgUtils.trackByFormId;
  readonly PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION = PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION;
  readonly BANK_PCG_ENTRY_SALES_CUSTOM_OPTION = BANK_PCG_ENTRY_SALES_CUSTOM_OPTION;
  readonly BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION = BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION;

  constructor(elementRef: ElementRef) {
    this.scrollContainer = elementRef.nativeElement;
  }

  emitBankAccountNumberChanges(labelCategoryPlanSetupForm: LabelCategoryPlanSetupForm): void {
    this.bankAccountNumberChanges.emit(labelCategoryPlanSetupForm);
  }

  emitPieceAccountNumberChanges(labelCategoryPlanSetupForm: LabelCategoryPlanSetupForm): void {
    this.pieceAccountNumberChanges.emit(labelCategoryPlanSetupForm);
  }

  emitVaTypeIdChanges(labelCategoryPlanSetupForm: LabelCategoryPlanSetupForm): void {
    this.vatTypeIdChanges.emit(labelCategoryPlanSetupForm);
  }

  emitVatExonerationTypeChanges(labelCategoryPlanSetupForm: LabelCategoryPlanSetupForm): void {
    this.vatExonerationTypeChanges.emit(labelCategoryPlanSetupForm);
  }
}
