<tiime-form [tiimeFormExpanded]="tiimeFormExpanded">
  <div tiimeFormTitle>État civil</div>
  <div class="legal-information-form" [formGroup]="legalInformationForm">
    <tiime-input-container
      contentId="birthDate"
      label="Date de naissance"
      [errorMessage]="legalInformationForm.birthDateErrorMessage?.message"
    >
      <input
        id="birthDate"
        placeholder="Date de naissance"
        type="text"
        [formControl]="legalInformationForm.birthDate"
        [max]="currentDate"
        [matDatepicker]="$any(birthDatePicker)"
        autocomplete="off"
      />
      <mat-icon
        color="primary"
        class="calendar-icon"
        fontIcon="icon-calendar"
        tiimeSuffix
        (click)="birthDatePicker.open()"
      ></mat-icon>
      <mat-datepicker
        panelClass="tiime-material-datepicker"
        [calendarHeaderComponent]="tiimeDatepickerHeader"
        #birthDatePicker
      ></mat-datepicker>
    </tiime-input-container>
    <app-country-select
      id="birthCountry"
      label="Pays de naissance"
      [control]="legalInformationForm.birthCountry"
      [selectData]="countries$ | async"
    ></app-country-select>
    <app-country-select
      label="Nationalité"
      id="nationality"
      [showNationality]="true"
      [control]="legalInformationForm.nationality"
      [selectData]="countries$ | async"
    ></app-country-select>
    <tiime-input-container contentId="birthPlace" label="Commune de naissance">
      <input
        id="birthPlace"
        type="text"
        [formControl]="legalInformationForm.birthPlace"
        placeholder="Commune de naissance"
        autocomplete="off"
      />
    </tiime-input-container>
    <app-department-select
      class="department-select"
      [control]="legalInformationForm.birthDepartment"
      [selectData]="departments$ | async"
    ></app-department-select>
    <app-marital-status-select
      [control]="legalInformationForm.maritalStatus"
      [selectData]="maritalStatuses$ | async"
    ></app-marital-status-select>
    <tiime-input-container class="social-security-input" label="N° sécurité sociale" contentId="socialSecurityNumber">
      <input
        id="socialSecurityNumber"
        type="text"
        placeholder="N° sécurité sociale"
        [formControl]="legalInformationForm.socialSecurityNumber"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container label="N° fiscal" contentId="taxNumber">
      <input
        id="taxNumber"
        type="text"
        placeholder="N° fiscal"
        [formControl]="legalInformationForm.taxNumber"
        autocomplete="off"
      />
    </tiime-input-container>
  </div>
</tiime-form>
