import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { CompanyContributor, CompanyContributorJson } from '@models/company-contributor';
import { LegalStructure, LegalStructureJson } from '@models/legal-structure';
import { Signatory, SignatoryJson } from '@models/signatory';

export interface AdminCompanyJson {
  id: number;
  name: string;
  legal_form: string;
  tax_regime: string;
  business_unit: Partial<BusinessUnitJson>;
  contributors: Partial<CompanyContributorJson>[];
  legal_structure: Partial<LegalStructureJson>;
  signatory: Partial<SignatoryJson>;
  is_demo: boolean;
}

export class AdminCompany {
  constructor(
    public id?: number,
    public name?: string,
    public legalForm?: string,
    public taxRegime?: string,
    public businessUnit?: Partial<BusinessUnit>,
    public contributors?: Partial<CompanyContributor>[],
    public legalStructure?: Partial<LegalStructure>,
    public signatory?: Partial<Signatory>,
    public isDemo?: boolean
  ) {}

  static fromJson(json: Partial<AdminCompanyJson>): AdminCompany {
    return new AdminCompany(
      json.id,
      json.name,
      json.legal_form,
      json.tax_regime,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.contributors
        ? json.contributors.map((contributorJson: CompanyContributorJson) =>
            CompanyContributor.fromJson(contributorJson)
          )
        : [],
      json.legal_structure ? LegalStructure.fromJson(json.legal_structure) : null,
      json.signatory ? Signatory.fromJson(json.signatory) : null,
      json.is_demo
    );
  }
}
