import { Directive, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';

import { Mapper } from 'tiime-expert-utils';

import { AutoSaveStatus, AutoSaveStatusComponent } from '../auto-save-status';
import { ContainerBase, ContainerState } from '../container';
import { PaginationRange } from '../paginator';
import { SearchBarComponent } from '../searchbar';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TableDataSourceBase<T> extends ContainerBase {
  @ViewChildren(AutoSaveStatusComponent)
  autoSaveStatusComponent: QueryList<AutoSaveStatusComponent>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  filterValue: string = null;
  rangeValue: PaginationRange = new PaginationRange();
  sortValue: Sort = null;
  /**
   * Tri par défaut qui sera utilisé dans le {@link resetTable}
   */
  resetTableDefaultSort: Sort = { active: null, direction: 'asc' };

  readonly mapToDisabledPaginator: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;
  readonly mapToDisabledSearchBar: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done && containerState !== ContainerState.noResult;
  readonly mapToDisabledSort: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;
  readonly mapToHiddenFooter: Mapper<ContainerState, boolean> = (containerState: ContainerState) =>
    containerState !== ContainerState.done;

  resetTable(): void {
    this.filterValue = null;
    this.rangeValue = new PaginationRange();
    this.sortValue = this.resetTableDefaultSort ? { ...this.resetTableDefaultSort } : null;
    this.dataSource.data = [];
    if (this.sort) {
      if (this.sort.active !== this.sortValue?.active || this.sort.direction !== this.sortValue?.direction) {
        this.sort.sort(this.defaultSortToMatSortable());
      }
    }
    this.searchBar?.clearSearch();
    this.reset();
  }

  /**
   * Déclenche l'affichage de l'icône de succès pour l'élément fourni
   */
  protected saveSuccess(element: T, timeout = 2000): void {
    const autoSaveStatusComponent = this.findAutoSave(element);
    if (autoSaveStatusComponent) {
      autoSaveStatusComponent.updateAutoSaveStatus(AutoSaveStatus.SaveSuccess, timeout);
    }
  }

  /**
   * Déclenche l'affichage de l'icône d'erreur pour l'élément fourni
   */
  protected saveError(element: T, timeout = null): void {
    const autoSaveStatusComponent = this.findAutoSave(element);
    if (autoSaveStatusComponent) {
      autoSaveStatusComponent.updateAutoSaveStatus(AutoSaveStatus.SaveError, timeout);
    }
  }

  /**
   * Retourne l'AutoSaveStatusComponent correspondant à l'élément fourni
   */
  private findAutoSave(element: T): AutoSaveStatusComponent | undefined {
    const index = this.dataSource.filteredData.findIndex(e => this.getId(e) === this.getId(element));
    if (index === -1) {
      return;
    }
    return this.autoSaveStatusComponent.get(index);
  }

  /**
   * Retourne l'id d'un élément, que ce soit un objet de la DB ou un formulaire
   */
  private getId(element: any): number {
    if (!(element instanceof FormGroup)) {
      // eslint-disable-next-line no-prototype-builtins
      if (!element.hasOwnProperty('id')) {
        throw new Error(`Element does not have property id`);
      }
      return element.id;
    }
    const control = element.get('id');
    if (!control) {
      throw new Error('FormGroup does not have control id');
    }
    const value = element.get('id').value;
    if (!value) {
      throw new Error('FormGroup.id value is not set');
    }

    return value;
  }

  private defaultSortToMatSortable(): MatSortable {
    return { start: this.resetTableDefaultSort.direction, id: this.resetTableDefaultSort.active, disableClear: false };
  }
}
