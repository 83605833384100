<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="cancel()">
  <span mat-dialog-title>Un contact correspond à l'email fourni</span>
  <mat-dialog-content class="dialog-content" mat-dialog-content>
    <div class="existing-user">
      <div class="existing-user-part">
        <mat-icon inline="true" fontIcon="icon-ic-clients2"></mat-icon>
        <span>{{ user.fullName }}</span>
      </div>
      <div class="existing-user-part" *ngIf="user.email">
        <mat-icon inline="true" fontIcon="icon-ic-email"></mat-icon>
        <span>{{ user.email }}</span>
      </div>
      <div class="existing-user-part" *ngIf="user.phone">
        <mat-icon inline="true" fontIcon="icon-ic-phone"></mat-icon>
        <span>{{ user.phone | phone }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button data-cy="dialog-cancel-button" tiime-button color="accent" strocked large (click)="cancel()">
      Annuler
    </button>
    <button data-cy="dialog-validate-button" tiime-button color="accent" raised large (click)="selectExistingUser()">
      Sélectionner
    </button>
  </mat-dialog-actions>
</tiime-dialog>
