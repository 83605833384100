import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { ApeCode, ApeCodeJson } from '@models/ape-code';

import { CompanyRegistry, CompanyRegistryJson } from '@modules/core/models/company-registry';
import { LegalPersonBase } from './legal-person-base';

export interface LegalMoralPersonJson {
  id: number;
  capacity: UserCapacity;
  capital: number;
  beneficiary: boolean;
  capital_contribution: number;
  director: boolean;
  start_job_date: string;
  end_job_date: string;
  entry_date: string;
  exit_date: string;
  legal_form: string;
  legal_representative_email: string;
  legal_representative_first_name: string;
  legal_representative_last_name: string;
  legal_representative_phone: string;
  mailing_city: string;
  mailing_country: string;
  mailing_postal_code: string;
  mailing_street: string;
  name: string;
  rcs_city: string;
  shares_bare_ownership_number: number;
  shares_number: number;
  shares_percentage: number;
  shares_usufruct_number: number;
  siret: string;
  ape_code: ApeCodeJson;
  company_registry: CompanyRegistryJson;
}

export class LegalMoralPerson extends LegalPersonBase {
  constructor(
    public id: number,
    public capacity: UserCapacity,
    public director: boolean,
    public beneficiary: boolean,
    public capitalContribution: number,
    public sharesNumber: number,
    public sharesPercentage: number,
    public capital: number,
    public startJobDate: Date,
    public endJobDate: Date,
    public entryDate: Date,
    public exitDate: Date,
    public legalForm: string,
    public legalRepresentativeEmail: string,
    public legalRepresentativeFirstName: string,
    public legalRepresentativeLastName: string,
    public legalRepresentativePhone: string,
    public mailingCity: string,
    public mailingCountry: string,
    public mailingPostalCode: string,
    public mailingStreet: string,
    public name: string,
    public rcsCity: string,
    public sharesBareOwnershipNumber: number,
    public sharesUsufructNumber: number,
    public siret: string,
    public apeCode: ApeCode,
    public companyRegistry?: CompanyRegistry
  ) {
    super(id, capacity, director, beneficiary, capitalContribution, sharesNumber, sharesPercentage);

    this.type = UserType.moral;
  }

  get fullName(): string {
    return this.legalForm ? `${this.name} - ${this.legalForm}` : this.name;
  }

  get email(): string {
    return this.legalRepresentativeEmail;
  }

  get phone(): string {
    return this.legalRepresentativePhone;
  }

  static fromJson(json: Partial<LegalMoralPersonJson>): LegalMoralPerson {
    return new LegalMoralPerson(
      json.id,
      json.capacity,
      json.director,
      json.beneficiary,
      json.capital_contribution,
      json.shares_number,
      json.shares_percentage,
      json.capital,
      json.start_job_date ? DateHelper.fromUtc(json.start_job_date) : null,
      json.end_job_date ? DateHelper.fromUtc(json.end_job_date) : null,
      json.entry_date ? DateHelper.fromUtc(json.entry_date) : null,
      json.exit_date ? DateHelper.fromUtc(json.exit_date) : null,
      json.legal_form,
      json.legal_representative_email,
      json.legal_representative_first_name,
      json.legal_representative_last_name,
      json.legal_representative_phone,
      json.mailing_city,
      json.mailing_country,
      json.mailing_postal_code,
      json.mailing_street,
      json.name,
      json.rcs_city,
      json.shares_bare_ownership_number,
      json.shares_usufruct_number,
      json.siret,
      json.ape_code ? ApeCode.fromJson(json.ape_code) : null,
      json.company_registry ? CompanyRegistry.fromJson(json.company_registry) : null
    );
  }
  static toJson(moralPerson: LegalMoralPerson): LegalMoralPersonJson {
    return {
      id: HttpHelper.toJsonProperty(moralPerson.id),
      capacity: HttpHelper.toJsonProperty(moralPerson.capacity),
      capital: HttpHelper.toJsonProperty(moralPerson.capital),
      beneficiary: moralPerson.beneficiary,
      capital_contribution: HttpHelper.toJsonProperty(moralPerson.capitalContribution),
      director: moralPerson.director,
      start_job_date: moralPerson.startJobDate ? DateHelper.format(moralPerson.startJobDate) : null,
      end_job_date: moralPerson.endJobDate ? DateHelper.format(moralPerson.endJobDate) : null,
      entry_date: moralPerson.entryDate ? DateHelper.format(moralPerson.entryDate) : null,
      exit_date: moralPerson.exitDate ? DateHelper.format(moralPerson.exitDate) : null,
      legal_form: HttpHelper.toJsonProperty(moralPerson.legalForm),
      legal_representative_email: HttpHelper.toJsonProperty(moralPerson.legalRepresentativeEmail),
      legal_representative_first_name: HttpHelper.toJsonProperty(moralPerson.legalRepresentativeFirstName),
      legal_representative_last_name: HttpHelper.toJsonProperty(moralPerson.legalRepresentativeLastName),
      legal_representative_phone: HttpHelper.toJsonProperty(moralPerson.legalRepresentativePhone),
      mailing_city: HttpHelper.toJsonProperty(moralPerson.mailingCity),
      mailing_country: HttpHelper.toJsonProperty(moralPerson.mailingCountry),
      mailing_postal_code: HttpHelper.toJsonProperty(moralPerson.mailingPostalCode),
      mailing_street: HttpHelper.toJsonProperty(moralPerson.mailingStreet),
      name: HttpHelper.toJsonProperty(moralPerson.name),
      rcs_city: HttpHelper.toJsonProperty(moralPerson.rcsCity),
      shares_bare_ownership_number: HttpHelper.toJsonProperty(moralPerson.sharesBareOwnershipNumber),
      shares_number: HttpHelper.toJsonProperty(moralPerson.sharesNumber),
      shares_percentage: HttpHelper.toJsonProperty(moralPerson.sharesPercentage),
      shares_usufruct_number: HttpHelper.toJsonProperty(moralPerson.sharesUsufructNumber),
      siret: HttpHelper.toJsonProperty(moralPerson.siret),
      ape_code: moralPerson.apeCode ? ApeCode.toJson(moralPerson.apeCode) : null,
      company_registry: moralPerson.companyRegistry ? CompanyRegistry.toJson(moralPerson.companyRegistry) : null
    };
  }
}
