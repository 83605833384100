import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { TiimeInputContainerModule } from 'tiime-material';

import { InputCellBase } from '@bases/input-cell/input-cell.base';
import { ErrorCode } from '@constants/error.constant';
import { FormHelper, FormHelperDatePickerErrorMessageInterface } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';
import { ListErrorComponent } from '@shared-components/list-error/list-error.component';
import { FormUpdateOnBlurDirective } from '@shared-directives/form-update-on-blur/form-update-on-blur.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeInputContainerModule,
    ReactiveFormsModule,
    FormUpdateOnBlurDirective,
    MatDatepickerModule,
    ListErrorComponent
  ],
  selector: 'app-date-input-cell',
  templateUrl: './date-input-cell.component.html',
  styleUrls: ['./date-input-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputCellComponent extends InputCellBase<string> {
  @Input() small?: boolean;
  @Input() errorMessage: ErrorMessage;
  @Input() minDate: Date;
  @Input() minErrorMessage: string;
  @Input() maxDate: Date;
  @Input() maxErrorMessage: string;
  @Input() errorDisplayMode: 'icon' | 'text' = 'text';

  get dateErrorMessage(): ErrorMessage | null {
    if (!!this.minDate || !!this.maxDate) {
      const formHelperDatePickerErrorMessageInterface: FormHelperDatePickerErrorMessageInterface = {
        checkMin: !!this.minDate,
        minError: ErrorCode.INVALID_DATE,
        minErrorMessage: this.minErrorMessage,
        checkMax: !!this.maxDate,
        maxError: ErrorCode.INVALID_DATE,
        maxErrorMessage: this.maxErrorMessage
      };
      return FormHelper.composeErrorMessages(this.ctrl, [
        { method: 'getDatePickerErrorMessage', args: [formHelperDatePickerErrorMessageInterface] }
      ]);
    }
    return null;
  }
}
