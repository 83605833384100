import { HttpResponse } from '@angular/common/http';

import { PaginationData, PaginationRange } from 'tiime-material';

import { HttpHelper } from '@helpers/http.helper';
import { Balance, BalanceJson } from '@models/balance';

export interface BalanceResults {
  solde: number;
  previousSolde: number;
  amountEvolution: number;
  percentEvolution: number;
}

export class BalancesAndResult extends PaginationData<Balance> {
  constructor(public data: Balance[], public paginationRange: PaginationRange, public results: BalanceResults) {
    super(data, paginationRange);
  }

  static fromHttpResponse(response: HttpResponse<BalanceJson[]>, range: PaginationRange): BalancesAndResult {
    const balances = response.body.map(json => Balance.fromJson(json));
    const solde = parseFloat(response.headers.get('X-Solde-Result'));
    const previousSolde = parseFloat(response.headers.get('X-Previous-Solde-Result'));
    const amountEvolution = parseFloat(response.headers.get('X-Amount-Evolution-Result'));
    const percentEvolution = parseFloat(response.headers.get('X-Percent-Evolution-Result'));

    const responseRange: PaginationRange = HttpHelper.getRangeFromResponseHeaders(response.headers, range.pageSize);

    return new BalancesAndResult(balances, responseRange, {
      solde,
      previousSolde,
      amountEvolution,
      percentEvolution
    });
  }
}
