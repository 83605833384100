import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/legacy-dialog';

import { DialogComponent } from './dialog.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeLoaderModule } from '../loader/index';

@NgModule({
  imports: [CommonModule, MatDialogModule, TiimeIconModule, TiimeLoaderModule, TiimeButtonModule, DragDropModule],
  declarations: [DialogComponent],
  exports: [MatDialogModule, DialogComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'tiime-dialog-panel',
        hasBackdrop: true,
        closeOnNavigation: true,
        disableClose: true,
        autoFocus: 'dialog',
        restoreFocus: true
      }
    }
  ]
})
export class TiimeDialogModule {}
