<tiime-input-container
  tiimeFormInputContainer
  contentId="city"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="city"
    [formControl]="control"
    [customValue]="control.value ? control.value.name : ''"
    (selectionChange)="emitSelectionChange($event)"
    data-cy="rcs-city"
  >
    <input
      autocomplete="off"
      tiimeSelectInput
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions data-cy="rcs-city">
      <mat-option *ngIf="allowEmptyOption" [value]="null"></mat-option>
      <mat-option
        [value]="valueType === 'city' ? companyRegistry.rcsCity : companyRegistry.id"
        *ngFor="let companyRegistry of filteredSelectorData; trackBy: trackByIndex"
      >
        {{ companyRegistry.rcsCity }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
