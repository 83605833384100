import { type HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

export class BlobHelper {
  public static parseErrorBlob<T extends any = any>(error: HttpErrorResponse): Observable<T> {
    const reader: FileReader = new FileReader();

    const obs = new Observable<T>((observer: any) => {
      reader.onloadend = () => {
        const parsedError = JSON.parse(reader.result as string);

        if (parsedError.violations && parsedError.violations.length > 0) {
          observer.next(parsedError.violations);
        } else {
          observer.error(parsedError);
        }

        observer.complete();
      };
    });
    reader.readAsText(error.error);
    return obs;
  }
}

/**
 * @deprecated Use `BlobHelper` instead.
 */
export class BlobUtils extends BlobHelper {}
