import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EditorConfig } from '@ckeditor/ckeditor5-core';
import Editor from 'ckeditor5-decoupled-build-with-html-support';
import { Converter } from 'showdown';

type OnChangeFn = (value: string) => void;
type OnTouchedFn = () => void;

const HTML_CONFIG: EditorConfig = {
  toolbar: [
    'heading',
    '|',
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent',
    '|',
    'link',
    'blockQuote',
    '|',
    'undo',
    'redo'
  ],
  language: 'fr'
};
const MARKDOWN_CONFIG: EditorConfig = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    '|',
    'outdent',
    'indent',
    '|',
    'undo',
    'redo'
  ],
  language: 'fr'
};

@Component({
  selector: 'tiime-rich-text-editor',
  host: {
    class: 'tiime-rich-text-editor'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RichTextEditorComponent,
      multi: true
    }
  ],
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextEditorComponent implements ControlValueAccessor, OnInit {
  /**
   * ⚠ This must be available at component creation since it's used to generate configuration
   */
  @Input() placeholder?: string;
  /**
   * ⚠ This must be available at component creation since it's used to generate configuration
   */
  @Input() isMarkdown = true;

  @Output() readonly formChanges: EventEmitter<void> = new EventEmitter<void>();

  protected editor = Editor as unknown as {
    create: any;
  };
  protected config: EditorConfig = { ...HTML_CONFIG };

  protected value = '';
  protected disabled = false;

  private converter: Converter = new Converter();
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.config = this.isMarkdown ? { ...MARKDOWN_CONFIG } : { ...HTML_CONFIG };
    if (this.placeholder) {
      this.config.placeholder = this.placeholder;
    }
  }

  public onReady(editor: Editor): void {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
  }

  onChange: OnChangeFn = _ => {};
  onTouched: OnTouchedFn = () => {};

  onBlur(): void {
    this.formChanges.emit();
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = (value: string) => (this.isMarkdown ? fn(this.toMarkdown(value)) : fn(value));
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(obj: string): void {
    this.value = this.isMarkdown ? this.toHtml(obj ?? '') : obj;
  }

  private toMarkdown(html: string): string {
    return this.converter.makeMarkdown(html);
  }

  private toHtml(markdown: string): string {
    return this.converter.makeHtml(markdown);
  }
}
