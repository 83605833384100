import { type AbstractControl } from '@angular/forms';

export class TrackByHelper {
  public static trackByFormId(index: number, form: AbstractControl): number | undefined {
    const id = form.get('id');
    return id?.value;
  }

  public static trackById<T extends { id?: number }>(index: number, item: T): number | undefined {
    return item.id;
  }

  public static trackByIndex(index: number): number {
    return index;
  }
}

/**
 * @deprecated Use `TrackByHelper` instead.
 */
export class NgUtils extends TrackByHelper {}
