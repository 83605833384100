import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TiimeButtonModule } from 'tiime-material';

@Component({
  selector: 'app-favorite-icon-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, TiimeButtonModule],
  templateUrl: './favorite-icon-button.component.html',
  styleUrls: ['./favorite-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteIconButtonComponent {
  @Input({ required: true }) favorite = false;

  @Output() readonly addToFavorites: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly removeFromFavorites: EventEmitter<void> = new EventEmitter<void>();

  emitRemoveFromFavorites(event: Event): void {
    event.stopPropagation();
    this.removeFromFavorites.emit();
  }

  emitAddToFavorites(event: Event): void {
    event.stopPropagation();
    this.addToFavorites.emit();
  }
}
