import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

import { TiimePipesModule } from 'tiime-expert-utils';

import { ComplexSearchBarAutocompleteComponent } from './complex-search-bar-autocomplete.component';
import { ComplexSearchBarComponent } from './complex-search-bar.component';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';

@NgModule({
  declarations: [ComplexSearchBarAutocompleteComponent, ComplexSearchBarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    TiimeIconModule,
    TiimePipesModule,
    TiimeTooltipModule,
    MatAutocompleteModule
  ],
  exports: [ComplexSearchBarComponent]
})
export class TiimeComplexSearchBarModule {}
