<button
  tiime-button
  color="primary"
  icon
  strocked
  tiimeTooltip
  [tooltipContent]="tiimeTableMenuTooltip"
  tooltipClass="tiime-tooltip"
  [matMenuTriggerFor]="tiimeTableMenu"
  [disabled]="tiimeTableMenuDisabled"
  data-cy="table-menu"
>
  <mat-icon inline="true" [fontIcon]="tiimeTableMenuIcon"></mat-icon>
</button>
<mat-menu #tiimeTableMenu="matMenu">
  <div data-cy="table-menu-container">
    <ng-content></ng-content>
  </div>
</mat-menu>
<ng-template #tiimeTableMenuTooltip>
  <div class="tiime-tooltip-content">Autres actions</div>
</ng-template>
