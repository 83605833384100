import { Pipe, PipeTransform } from '@angular/core';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';

@Pipe({
  name: 'threadEntityTypesByCategory',
  standalone: true
})
export class ThreadEntityTypesByCategoryPipe implements PipeTransform {
  transform(category: ThreadCategory): ThreadEntityType[] {
    return category === ThreadCategory.customer
      ? [ThreadEntityType.bankTransaction]
      : [
          ThreadEntityType.accountingPeriod,
          ThreadEntityType.vatDeclaration,
          ThreadEntityType.task,
          ThreadEntityType.corporateTaxInstalment
        ];
  }
}
