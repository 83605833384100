import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_HEADERS, API_ROUTE_REPLACE_COMPANY_KEY, COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { CompaniesFiltersHelper } from '@helpers/companies-filters.helper';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { CompaniesFilters } from '@interfaces/companies-filters';
import { CompanyThirdPartyAccountingPlan } from '@interfaces/company-third-party-accounting-plan-map';
import { CashRegister, CashRegisterJson } from '@models/cash-register';
import { Commentary, CommentaryJson } from '@models/commentary';
import { Company, CompanyJson, CompanySerialized } from '@models/company';
import { CompanyContributor, CompanyContributorJson } from '@models/company-contributor';
import { CompanyMetrics, CompanyMetricsJson } from '@models/company-metrics';
import { ContributorJob, ContributorJobJson } from '@models/contributor-job';
import { LabelsPlan } from '@models/labels-plan';
import { TiimeBusinessInvitation } from '@models/tiime-business-invitation';
import { VariousAccountParameters, VariousAccountParametersJson } from '@models/various-account-parameters';

/**
 * Company Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CompanyApiService extends ApiCommonService<Company> {
  resourceUrl = 'api/v1/expert/companies';

  fromJson(json: CompanyJson): Company {
    return Company.fromJson(json);
  }

  toJson(model: Company): CompanySerialized {
    return Company.toJson(model);
  }

  @ApiAlertError()
  getCompany(companyId: number): Observable<Company> {
    return super.getById(companyId);
  }

  @ApiAlertError()
  getCompanies(
    range: PaginationRange,
    searchTerms?: string,
    sort?: Sort,
    filters?: CompaniesFilters
  ): Observable<PaginationData<Company>> {
    let params: HttpParams = HttpHelper.setQParam(searchTerms);
    if (sort) {
      params = HttpHelper.setSortParam(sort, params);
    }
    if (filters) {
      params = HttpHelper.setParams(CompaniesFiltersHelper.toParams(filters), params);
    }
    return super.getPaginated(range, params);
  }

  @ApiAlertError()
  searchCompanies(searchTerms: string, expand?: string): Observable<Company[]> {
    let params = HttpHelper.setQParam(searchTerms);

    if (expand?.length > 0) {
      params = HttpHelper.setExpandParam(expand, params);
    }

    return super.search({ params });
  }

  @ApiAlertError()
  createCompany(businessUnitId: number, company: Company): Observable<Company> {
    return super.create(company, `api/v1/expert/business_unit/${businessUnitId}/companies`);
  }

  @ApiAlertError()
  updateCompany(company: Company): Observable<Company> {
    return super.update(company.id, company);
  }

  @ApiAlertError()
  flagCompanyAsDemo(companyId: number, isDemo: boolean): Observable<Company> {
    const url = `${this.resourceUrl}/${companyId}`;
    return this.http
      .patch<CompanyJson>(url, { is_demo: isDemo })
      .pipe(map((companyJson: CompanyJson) => this.fromJson(companyJson)));
  }

  @ApiAlertError()
  validateCompany(companyId: number): Observable<Company> {
    const url = `${this.resourceUrl}/${companyId}`;
    const data = { initialization_in_progress: false };
    return this.http.patch(url, data);
  }

  @ApiAlertError()
  updateCompanyThirdPartyAccountingPlan(
    companyThirdPartyAccountingPlan: CompanyThirdPartyAccountingPlan
  ): Observable<Company> {
    return super.updatePartial(companyThirdPartyAccountingPlan.id, companyThirdPartyAccountingPlan);
  }

  @ApiAlertError()
  checkIfDirectorHasSignature(): Observable<boolean> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/director_has_signature`;
    return this.http.get<{ has_signature: boolean }>(url).pipe(map(({ has_signature }) => has_signature));
  }

  @ApiAlertError()
  sendSmsJSignEbicsMandate(userId: number): Observable<void> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/users/${userId}/send_sms_jsign_ebics_mandate`;
    return this.http.put<void>(url, {});
  }

  @ApiAlertError()
  sendTiimeBusinessInvitation(tiimeBusinessInvitation: TiimeBusinessInvitation): Observable<string> {
    const headers = new HttpHeaders({
      Accept: API_HEADERS.walletInvitationV2
    });

    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/wallet_eligible`;
    return this.http
      .post<CompanyJson>(url, TiimeBusinessInvitation.toJson(tiimeBusinessInvitation), { headers })
      .pipe(map(response => response.last_tiime_business_eligibility_sent_at));
  }

  @ApiAlertError()
  updateCashRegistersAvailable(cashRegistersAvailable: boolean): Observable<Company> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}`;
    const body = {
      cash_registers_available: cashRegistersAvailable
    };
    return this.http.patch(url, body).pipe(map((companyJson: CompanyJson) => Company.fromJson(companyJson)));
  }

  @ApiAlertError()
  uploadFEC(file: File, companyId: number): Observable<any> {
    const url = `api/v1/expert/companies/${companyId}/initialization_fec/imports`;
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData);
  }

  @ApiAlertError()
  importLabelsPlans(labelPlanId: number, companyId?: number): Observable<LabelsPlan> {
    const url = `${this.resourceUrl}/${
      companyId ? companyId : API_ROUTE_REPLACE_COMPANY_KEY
    }/labels_plans/${labelPlanId}/imports`;
    return this.http.post(url, null);
  }

  @ApiAlertError()
  addCommentary(companyId: number, commentary: Commentary): Observable<Commentary> {
    const url = `${this.resourceUrl}/${companyId}/commentaries`;
    return this.http
      .post<CommentaryJson>(url, Commentary.toJsonWithoutId(commentary))
      .pipe(map(json => Commentary.fromJson(json)));
  }

  @ApiAlertError()
  updateCommentary(companyId: number, commentary: Commentary): Observable<Commentary> {
    const url = `${this.resourceUrl}/${companyId}/commentaries/${commentary.id}`;

    return this.http
      .patch<CommentaryJson>(url, Commentary.toJsonWithoutId(commentary))
      .pipe(map(json => Commentary.fromJson(json)));
  }

  @ApiAlertError()
  deleteCommentary(companyId: number, commentaryId: number): Observable<void> {
    const url = `${this.resourceUrl}/${companyId}/commentaries/${commentaryId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  getMetrics(metricsFilter: string): Observable<CompanyMetrics> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/metrics`;
    let params = new HttpParams();
    params = HttpHelper.setParam('metrics', metricsFilter, params);

    return this.http
      .get(url, { params })
      .pipe(map((companyMetricsJson: CompanyMetricsJson) => CompanyMetrics.fromJson(companyMetricsJson)));
  }

  @ApiAlertError()
  clientAccess(companyId?: number): Observable<any> {
    let url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/client_access`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post(url, null);
  }

  @ApiAlertError([403])
  getCashRegisters(): Observable<CashRegister[]> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/cash_registers`;
    return this.http
      .get(url)
      .pipe(
        map((cashRegistersJson: CashRegisterJson[]) =>
          cashRegistersJson.map((cashRegisterJson: CashRegisterJson) => CashRegister.fromJson(cashRegisterJson))
        )
      );
  }

  @ApiAlertError([403])
  updateCashRegister(cashRegister: CashRegister): Observable<CashRegister> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/cash_registers/${cashRegister.id}`;
    return this.http
      .patch<CashRegisterJson>(url, CashRegister.toJson(cashRegister))
      .pipe(map(json => CashRegister.fromJson(json)));
  }

  @ApiAlertError([403])
  importPaymentInitiations(startDate: string, endDate: string): Observable<null> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/salaries/import_payment_initiations`;
    const body = {
      start_date: DateHelper.format(startDate),
      end_date: DateHelper.format(endDate)
    };
    return this.http.post(url, body).pipe(map((): null => null));
  }

  @ApiAlertError()
  getContributors(companyId?: number): Observable<CompanyContributor[]> {
    let url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/contributors`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .get(url)
      .pipe(
        map((companyContributorsJson: CompanyContributorJson[]) =>
          companyContributorsJson.map((companyContributorJson: CompanyContributorJson) =>
            CompanyContributor.fromJson(companyContributorJson)
          )
        )
      );
  }

  @ApiAlertError()
  getContributorJobs(companyId?: number): Observable<ContributorJob[]> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: API_HEADERS.contributorJobsV2
    });

    let url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/contributor_jobs`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .get(url, { headers })
      .pipe(
        map((contributorJobsJson: ContributorJobJson[]) =>
          contributorJobsJson.map((contributorJobJson: ContributorJobJson) =>
            ContributorJob.fromJson(contributorJobJson)
          )
        )
      );
  }

  @ApiAlertError()
  deleteContributor(contributorId: number): Observable<unknown> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/contributors/${contributorId}`;

    return this.http.delete(url);
  }

  @ApiAlertError()
  createContributor(contributor: CompanyContributor): Observable<CompanyContributor> {
    const url = `${this.resourceUrl}/${API_ROUTE_REPLACE_COMPANY_KEY}/contributors`;

    return this.http
      .post(url, CompanyContributor.toJson(contributor))
      .pipe(
        map((companyContributorJson: CompanyContributorJson) => CompanyContributor.fromJson(companyContributorJson))
      );
  }

  @ApiAlertError()
  getVariousAccountParams(): Observable<VariousAccountParameters[]> {
    const url = `${COMPANY_API_ROUTE_BASE}/various_account_parameters`;

    return this.http
      .get<VariousAccountParametersJson[]>(url)
      .pipe(
        map((variousAccountParametersJson: VariousAccountParametersJson[]) =>
          variousAccountParametersJson.map((variousAccountParameterJson: VariousAccountParametersJson) =>
            VariousAccountParameters.fromJson(variousAccountParameterJson)
          )
        )
      );
  }

  @ApiAlertError()
  updateVariousAccountParams(
    variousAccountParamsId: number,
    accountNumber: string
  ): Observable<VariousAccountParameters> {
    const url = `${COMPANY_API_ROUTE_BASE}/various_account_parameters/${variousAccountParamsId}`;
    const body = {
      id: variousAccountParamsId,
      account_number: accountNumber
    };

    return this.http
      .patch<any>(url, body)
      .pipe(map((json: VariousAccountParametersJson) => VariousAccountParameters.fromJson(json)));
  }
}
