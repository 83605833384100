<button
  tiime-button
  color="primary"
  [attr.icon]="!!tiimeTableMainActionIcon"
  raised
  tiimeTooltip
  [tooltipContent]="tiimeTableMainActionTooltip"
  tooltipClass="tiime-tooltip"
  [tooltipDisabled]="!tiimeTableMainActionTooltipContent"
  [disabled]="tiimeTableMainActionDisabled"
  (click)="tiimeTableMainAction.emit()"
  data-cy="table-main-action"
>
  <mat-icon inline="true" [fontIcon]="tiimeTableMainActionIcon"></mat-icon>
  {{tiimeTableMainActionText}}
</button>
<ng-template #tiimeTableMainActionTooltip>
  <div class="tiime-tooltip-content">{{ tiimeTableMainActionTooltipContent }}</div>
</ng-template>
