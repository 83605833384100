import { DateHelper } from '@helpers/date.helper';
import { BusinessUser, BusinessUserJson } from '@models/business-user';

export interface MessageJson {
  id: number;
  content: string;
  created_at: string;
  created_by: Partial<BusinessUserJson>;
  content_updated_at: string;
  content_updated_by: Partial<BusinessUserJson>;
  content_deleted_at: string;
}

export class Message {
  constructor(
    public id?: number,
    public content?: string,
    public createdAt?: Date,
    public createdBy?: BusinessUser,
    public contentUpdatedAt?: Date,
    public contentUpdatedBy?: BusinessUser,
    public contentDeletedAt?: Date
  ) {}

  static fromJson(json: Partial<MessageJson>): Message {
    return new Message(
      json.id,
      json.content,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.created_by ? BusinessUser.fromJson(json.created_by) : null,
      json.content_updated_at ? DateHelper.fromUtc(json.content_updated_at) : null,
      json.content_updated_by ? BusinessUser.fromJson(json.content_updated_by) : null,
      json.content_deleted_at ? DateHelper.fromUtc(json.content_deleted_at) : null
    );
  }

  static toJson(message: Message): Partial<MessageJson> {
    return {
      content: message.content
    };
  }
}
