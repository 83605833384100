import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef
} from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResizeGrabberComponent } from './resize-grabber.component';

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[tiimeContainerSideContent]',
  host: {
    class: 'tiime-container-side-content'
  }
})
export class ContainerSideContentDirective implements OnInit {
  @Output() readonly containerResized: EventEmitter<void> = new EventEmitter<void>();

  newElementWidthOperation: 'positive' | 'negative' = 'negative';

  get containerWidth(): number {
    return this.elementRef.nativeElement.getBoundingClientRect().width;
  }

  private componentInstance: ComponentRef<ResizeGrabberComponent> = null;
  private elementWidth: number;
  private subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.setElementWidth();
    this.addGrabber();
  }

  private addGrabber(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ResizeGrabberComponent);
    this.componentInstance = this.viewContainerRef.createComponent(componentFactory);
    this.renderer.appendChild(this.elementRef.nativeElement, this.componentInstance.location.nativeElement);
    this.observeGrabberEvents();
  }

  private observeGrabberEvents(): void {
    this.subscription.add(
      this.componentInstance.instance.dragStarted.pipe(tap(() => this.setElementWidth())).subscribe()
    );
    this.subscription.add(
      this.componentInstance.instance.dragMoved
        .pipe(
          tap((move: number) => {
            const newElementWidth =
              this.newElementWidthOperation === 'negative' ? this.elementWidth - move : this.elementWidth + move;
            const newElementCssWidth = `${newElementWidth}px`;
            if (newElementWidth > 150) {
              this.renderer.setStyle(this.elementRef.nativeElement, 'min-width', newElementCssWidth);
              this.renderer.setStyle(this.elementRef.nativeElement, 'max-width', newElementCssWidth);
              this.containerResized.emit();
            }
          })
        )
        .subscribe()
    );
    this.subscription.add(
      this.componentInstance.instance.dragEnded.pipe(tap(() => this.setElementWidth())).subscribe()
    );
  }

  private setElementWidth(): void {
    this.elementWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
  }
}
