export interface EmailAttachmentJson {
  id: number;
  type: string;
  name: string;
  content: string;
  file: Blob;
  title: string;
}

export type EmailAttachmentJsonSerialized = Omit<EmailAttachmentJson, 'file' | 'id'> | { id: number };

export class EmailAttachment {
  constructor(
    public id?: number,
    public type?: string,
    public name?: string,
    public content?: string,
    public file?: Blob,
    public title?: string
  ) {}

  static fromJson(json: Partial<EmailAttachmentJson>): EmailAttachment {
    return new EmailAttachment(json.id, json.type, json.name, json.content, json.file, json.title);
  }

  static toJson(emailAttachment: EmailAttachment): EmailAttachmentJsonSerialized {
    return emailAttachment.id
      ? { id: emailAttachment.id }
      : {
          type: emailAttachment.type,
          name: emailAttachment.name,
          content: emailAttachment.content,
          title: emailAttachment.title
        };
  }
}
