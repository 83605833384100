import { SocialRegimeCode } from '@enums/social-regime-code.enum';

export interface SocialRegimeJson {
  code: SocialRegimeCode;
  label: string;
}

export class SocialRegime {
  constructor(public code?: SocialRegimeCode, public label?: string) {}

  static fromJson(json: SocialRegimeJson): SocialRegime {
    return new SocialRegime(json.code, json.label);
  }
}
