import { Label, LabelJson } from '@models/label';
import { LinkedTransaction, LinkedTransactionJson } from '@models/linked-transaction';

export interface ExpenseReportJson {
  id: number;
  date: string;
  name: string;
  amount: number;
  vat_amount: number;
  label: Partial<LabelJson>;
  bank_transactions: Partial<LinkedTransactionJson>[];
}

export class ExpenseReport {
  constructor(
    public id?: number,
    public date?: string,
    public name?: string,
    public amount?: number,
    public vatAmount?: number,
    public label?: Label,
    public bankTransactions?: LinkedTransaction[]
  ) {}

  static fromJson(json: Partial<ExpenseReportJson>): ExpenseReport {
    return new ExpenseReport(
      json.id,
      json.date,
      json.name,
      json.amount,
      json.vat_amount,
      json.label ? Label.fromJson(json.label) : null,
      json.bank_transactions?.length
        ? json.bank_transactions.map(bankTransactionJson => LinkedTransaction.fromJson(bankTransactionJson))
        : []
    );
  }
}
