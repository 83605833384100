<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="cancel()">
  <span mat-dialog-title>Raccourcis clavier</span>
  <mat-dialog-content mat-dialog-content>
    <div class="sets-container">
      <div class="shortcut-set" *ngFor="let set of shortcutsSets; trackBy: trackByIndex">
        <div class="set-type-container">
          <span class="set-type">{{ set.type | titlecase }}</span>
        </div>
        <div class="shortcuts-container">
          <ng-container *ngFor="let shortcut of set.shortcuts; trackBy: trackByIndex">
            <div class="shortcut-container" *ngIf="shortcut.firefoxAvailable | map : mapToIsAvailableOnFirefox">
              <span>{{ shortcut.description }}</span>
              <app-shortcut-container [shortcuts]="shortcut.keys"></app-shortcut-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button data-cy="dialog-validate-button" type="button" tiime-button color="accent" raised large mat-dialog-close>
      Fermer
    </button>
  </mat-dialog-actions>
</tiime-dialog>
