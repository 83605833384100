import { Pipe, PipeTransform } from '@angular/core';

import { PhoneHelper } from '@helpers/phone.helper';

@Pipe({
  name: 'phone',
  standalone: true
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return null;

    return PhoneHelper.format(value);
  }
}
