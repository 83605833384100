import {
  ApplicationRef,
  enableProdMode,
  isDevMode,
  NgModuleRef
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env';

function enableDebugTools(moduleRef: NgModuleRef<AppModule>): void {
  import('@angular/platform-browser').then(platformBrowser => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    platformBrowser.enableDebugTools(componentRef);
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef: NgModuleRef<AppModule>) => {
    if (isDevMode()) {
      enableDebugTools(moduleRef);
    }
  })
  .catch(err => console.log(err));
