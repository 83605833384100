import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HubConnectionToken } from '@models/hub-connection-token';
import { AppConfigService } from '@services/app-config.service';
import { HubAuthService } from '@third-parties/hub/hub-auth.service';

@Injectable()
export class HubAuthInterceptor implements HttpInterceptor {
  private allowedDomains: string[];
  private version: string;

  constructor(
    private hubAuthService: HubAuthService,
    appConfigService: AppConfigService
  ) {
    this.allowedDomains = appConfigService.environment.HUB_WHITE_DOMAINS;
    this.version = appConfigService.environment.HUB_VERSION;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.hubAuthService.isAllowedDomain(request, this.allowedDomains)) {
      return next.handle(request);
    }

    return iif(
      () => this.hubAuthService.isTokenExpired(),
      this.hubAuthService.retrieveToken(),
      of(this.hubAuthService.token)
    ).pipe(
      tap(
        (hubConnectionToken: HubConnectionToken) =>
          (request = request.clone({
            setHeaders: {
              'hub-version': this.version,
              Authorization: `Bearer ${hubConnectionToken.token}`
            }
          }))
      ),
      switchMap(() => next.handle(request))
    );
  }
}
