import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { TiimeButtonModule } from '../button/index';
import { TiimeColumnsSelectorModule } from '../columns-selector/index';
import { TiimeContainerModule } from '../container/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeMenuModule } from '../menu/index';
import { TiimeTooltipModule } from '../tooltip/index';
import { StickyTableContainerComponent } from './sticky-table-container.component';
import { StickyTableContentPlaceholderComponent } from './sticky-table-content-placeholder.component';
import { StickyTableNoResultsComponent } from './sticky-table-no-results.component';
import { StickyTablePreviewComponent } from './sticky-table-preview.component';
import { StickyTableDirective } from './sticky-table.directive';
import { TableAnchorComponent } from './table-anchor.component';
import { TableCellDirective } from './table-cell.directive';
import { TableContainerComponent } from './table-container.component';
import { TableContentPlaceholderComponent } from './table-content-placeholder.component';
import { TableFooterCellDirective } from './table-footer-cell.directive';
import { TableFooterRowDirective } from './table-footer-row.directive';
import { TableHeaderCellDirective } from './table-header-cell.directive';
import { TableHeaderRowDirective } from './table-header-row.directive';
import { TableLoaderComponent } from './table-loader.component';
import { TableMainActionComponent } from './table-main-action.component';
import { TableMenuComponent } from './table-menu.component';
import { TableRowDirective } from './table-row.directive';
import { TableComponent } from './table.component';
import { VirtualTableComponent } from './virtual-table.component';

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    TiimeMenuModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimeTooltipModule,
    TiimeContainerModule,
    MatProgressSpinnerModule,
    TiimeColumnsSelectorModule
  ],
  declarations: [
    TableComponent,
    TableContentPlaceholderComponent,
    TableAnchorComponent,
    TableContainerComponent,
    TableMenuComponent,
    TableLoaderComponent,
    TableMainActionComponent,
    TableHeaderCellDirective,
    TableCellDirective,
    TableHeaderRowDirective,
    TableRowDirective,
    TableFooterRowDirective,
    TableFooterCellDirective,
    VirtualTableComponent,
    StickyTableContainerComponent,
    StickyTableContentPlaceholderComponent,
    StickyTableNoResultsComponent,
    StickyTableDirective,
    StickyTablePreviewComponent
  ],
  exports: [
    CdkTableModule,
    TableComponent,
    TableContentPlaceholderComponent,
    TableAnchorComponent,
    TableContainerComponent,
    TableMenuComponent,
    TableLoaderComponent,
    TableMainActionComponent,
    TableHeaderCellDirective,
    TableCellDirective,
    TableHeaderRowDirective,
    TableRowDirective,
    TableFooterRowDirective,
    TableFooterCellDirective,
    VirtualTableComponent,
    StickyTableContainerComponent,
    StickyTableContentPlaceholderComponent,
    StickyTableNoResultsComponent,
    StickyTableDirective,
    StickyTablePreviewComponent
  ]
})
export class TiimeTableModule {}
