import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { Environment } from '@interfaces/environment';
import { WINDOW, WindowWithBeacon } from '@modules/core/tokens/window.token';
import { AppConfigService } from '@services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class HelpScoutBeaconService {
  private subscription: Subscription;

  get environment(): Environment {
    return this.appConfigService.environment;
  }

  constructor(
    @Inject(WINDOW) private window: WindowWithBeacon,
    private appConfigService: AppConfigService,
    private router: Router
  ) {}

  init(): void {
    if (this.environment.HELP_SCOUT_BEACON_ID) {
      this.beacon('init', this.environment.HELP_SCOUT_BEACON_ID);
      this.beacon('config', {
        display: {
          style: 'manual'
        }
      });
      if (!this.subscription) {
        this.subscription = this.observeRouteChange().subscribe();
      }
    }
  }

  open(): void {
    this.beacon('open');
  }

  close(): void {
    this.beacon('close');
  }

  toggle(): void {
    this.beacon('toggle');
  }

  private beacon(...args): void {
    if (this.environment.HELP_SCOUT_BEACON_ID && this.window.Beacon) {
      this.window.Beacon(...args);
    }
  }

  private refresh(): void {
    this.close();
    this.beacon('destroy');
    this.init();
  }

  private observeRouteChange(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      debounceTime(300),
      tap(() => this.refresh())
    );
  }
}
