import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, defer, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { MaskUtils, NgUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule } from 'tiime-material';

import { CommonDataApiService } from '@api-services/common-data-api.service';
import { LegalStructureApiService } from '@api-services/legal-structure-api.service';
import { VAT_DECLARATION_DAYS } from '@constants/vat-declaration-days.constant';
import { CompanyForm } from '@forms/company-form';
import { DateHelper } from '@helpers/date.helper';
import { FormHelper } from '@helpers/form.helper';
import { LegalStructureAndSignatory } from '@interfaces/legal-structure-and-signatory';
import { BusinessUnit } from '@models/business-unit';
import { CompanyRegistry } from '@models/company-registry';
import { LegalStructure } from '@models/legal-structure';
import { Signatory } from '@models/signatory';
import { DateInputCellComponent } from '@shared-cells/date-input-cell/date-input-cell.component';
import { FormUpdateOnBlurDirective } from '@shared-directives/form-update-on-blur/form-update-on-blur.directive';
import { HasRoleDirective } from '@shared-directives/has-role/has-role.directive';
import { UppercaseInputDirective } from '@shared-directives/uppercase-input/uppercase-input.directive';
import { SiretInputComponent } from '@shared-inputs/siret-input/siret-input.component';
import { IncludesPipe } from '@shared-pipes/includes/includes.pipe';
import { ApeCodeSelectComponent } from '@shared-selectors/ape-code-select/ape-code-select.component';
import { BusinessUnitSelectComponent } from '@shared-selectors/business-unit-select/business-unit-select.component';
import { CompanyLegalStructureAndSignatorySelectComponent } from '@shared-selectors/company-legal-structure-and-signatory-select/company-legal-structure-and-signatory-select.component';
import { CompanyRegistrySelectComponent } from '@shared-selectors/company-registry-select/company-registry-select.component';
import { DaySelectComponent } from '@shared-selectors/day-select/day-select.component';
import { LegalFormSelectComponent } from '@shared-selectors/legal-form-select/legal-form-select.component';
import { TaxRegimeSelectComponent } from '@shared-selectors/tax-regime-select/tax-regime-select.component';
import { VatSystemSelectComponent } from '@shared-selectors/vat-system-select/vat-system-select.component';

@UntilDestroy({ checkProperties: true })
@Component({
  standalone: true,
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  imports: [
    BusinessUnitSelectComponent,
    LegalFormSelectComponent,
    VatSystemSelectComponent,
    DaySelectComponent,
    TaxRegimeSelectComponent,
    ApeCodeSelectComponent,
    SiretInputComponent,
    FormUpdateOnBlurDirective,
    UppercaseInputDirective,
    IncludesPipe,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    CommonModule,
    DateInputCellComponent,
    CompanyLegalStructureAndSignatorySelectComponent,
    HasRoleDirective,
    CompanyRegistrySelectComponent
  ]
})
export class CompanyFormComponent implements OnInit, OnChanges {
  @Input() companyForm: CompanyForm;
  @Input() businessUnits: BusinessUnit[];
  @Input() expandedForms = ['company', 'address', 'fiscal', 'legal'];
  @Input({ transform: booleanAttribute }) legalStructureAndSignatoryDisabled = false;

  @Output()
  readonly companyFormChange: EventEmitter<CompanyForm> = new EventEmitter<CompanyForm>();

  @ViewChild('companyNameInput') companyNameInput: ElementRef;

  readonly apeCodes$ = this.commonDataApiService.getApeCodes();
  readonly legalForms$ = this.commonDataApiService.getLegalForms();
  readonly taxRegimes$ = this.commonDataApiService.getTaxRegimes();
  readonly vatSystems$ = this.commonDataApiService.getVatSystems();
  readonly legalStructuresAndSignatories: WritableSignal<LegalStructureAndSignatory[]> = signal<
    LegalStructureAndSignatory[]
  >([]);
  readonly cities: WritableSignal<CompanyRegistry[]> = signal<CompanyRegistry[]>([]);
  readonly siretMask = {
    mask: MaskUtils.siretMask,
    guide: false
  };
  readonly trackByIndex = NgUtils.trackByIndex;
  readonly VAT_DECLARATION_DAYS = VAT_DECLARATION_DAYS;

  protected readonly maxActivityStartDate: moment.Moment = DateHelper.add(new Date(), 1, 'year');

  private subscription: Subscription = new Subscription();
  private computeSharesNumberSubscription: Subscription | null = null;
  private businessUnitLegalStructuresSubscription: Subscription | null = null;
  private resetLegalStructureAndSignatorySubscription: Subscription | null = null;

  constructor(
    private commonDataApiService: CommonDataApiService,
    private legalStructureApiService: LegalStructureApiService
  ) {}

  ngOnInit(): void {
    this.getCities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyForm?.currentValue) {
      this.computeSharesNumber();
      this.getBusinessUnitLegalStructures();
      this.observeResetLegalStructureAndSignatory();
    }
  }

  handleCompanyFormChange(): void {
    this.companyFormChange.emit();
    this.updateCities();
  }

  focusCompanyName(): void {
    setTimeout(() => this.companyNameInput.nativeElement.focus());
  }

  computeSharesNumber(): void {
    if (this.computeSharesNumberSubscription) {
      this.computeSharesNumberSubscription.unsubscribe();
    }

    const shareCapitalChange$: Observable<number> = merge(
      defer(() => of(this.companyForm.controls.shareCapital.value)),
      this.companyForm.controls.shareCapital.valueChanges
    );
    const shareValueChange$: Observable<number> = merge(
      defer(() => of(this.companyForm.controls.shareValue.value)),
      this.companyForm.controls.shareValue.valueChanges
    );

    this.computeSharesNumberSubscription = combineLatest([shareCapitalChange$, shareValueChange$])
      .pipe(tap(() => this.companyForm.computeSharesNumber()))
      .subscribe();
  }

  private observeResetLegalStructureAndSignatory(): void {
    if (this.resetLegalStructureAndSignatorySubscription) {
      this.resetLegalStructureAndSignatorySubscription.unsubscribe();
    }
    this.resetLegalStructureAndSignatorySubscription = this.companyForm.businessUnit.valueChanges
      .pipe(tap(() => this.companyForm.legalStructureAndSignatory.reset()))
      .subscribe();
  }

  private getBusinessUnitLegalStructures(): void {
    if (this.businessUnitLegalStructuresSubscription) {
      this.businessUnitLegalStructuresSubscription.unsubscribe();
    }
    this.businessUnitLegalStructuresSubscription = FormHelper.getValueChange(this.companyForm.businessUnit)
      .pipe(
        filter((bu: BusinessUnit) => !!bu),
        switchMap((bu: BusinessUnit) => this.legalStructureApiService.getBusinessUnitLegalStructures(bu.id)),
        map((legalStructures: LegalStructure[]) =>
          legalStructures.filter((legalStructure: LegalStructure) => {
            const unarchivedSignatoriesCount = legalStructure.signatories.filter(
              (signatory: Signatory) => !signatory.archived
            ).length;
            return !legalStructure.archived && unarchivedSignatoriesCount;
          })
        ),
        map((legalStructures: LegalStructure[]) =>
          legalStructures.reduce(
            (acc: LegalStructureAndSignatory[], legalStructure: LegalStructure) => [
              ...acc,
              ...LegalStructure.toLegalStructureAndSignatories(legalStructure)
            ],
            [] as LegalStructureAndSignatory[]
          )
        ),
        tap((legalStructuresAndSignatories: LegalStructureAndSignatory[]) =>
          this.legalStructuresAndSignatories.set(legalStructuresAndSignatories)
        ),
        tap(() => {
          if (this.companyForm.legalStructureAndSignatory.disabled && !this.legalStructureAndSignatoryDisabled) {
            this.companyForm.legalStructureAndSignatory.enable();
          }
        })
      )
      .subscribe();
  }

  private getCities(): void {
    this.commonDataApiService
      .getCompanyRegistries()
      .pipe(
        tap((cities: CompanyRegistry[]) => {
          this.cities.set(cities);
          this.updateCities();
        })
      )
      .subscribe();
  }

  private updateCities(): void {
    const newArray: CompanyRegistry[] = [...this.cities().filter((city: CompanyRegistry) => city.id)];
    if (
      this.companyForm.rcsCity.value &&
      !newArray.find((city: CompanyRegistry) => city.rcsCity === this.companyForm.rcsCity.value)
    ) {
      const newCompanyRegistry = new CompanyRegistry(null, this.companyForm.city.value);
      newArray.unshift(newCompanyRegistry);
    }
    this.cities.set(newArray);
  }
}
