import { DeclarationStatusSource } from '@enums/declaration-status-source';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { FiscalDeclarationType } from '@enums/fiscal-declaration-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { DeclarationStatusError, DeclarationStatusErrorJson } from '@models/declaration-status-error';
import { User } from '@models/user';
import { VerboseConsistencyCheck, VerboseConsistencyCheckJson } from '@models/verbose-consistency-check';

export interface FiscalDeclarationJson {
  id: number;
  start_date: string;
  end_date: string;
  type: FiscalDeclarationType;
  return_message: string;
  teledec_id: number;
  status: DeclarationStatus;
  teledec_status: string;
  deadline_date: string;
  label: string;
  link: string;
  status_source: DeclarationStatusSource;
  errors: Partial<DeclarationStatusErrorJson>[];
  status_date: string;
  status_by: {
    firstname: string;
    lastname: string;
  };
  consistency_checks?: Partial<VerboseConsistencyCheckJson>[];
}

export class FiscalDeclaration {
  constructor(
    public id?: number,
    public startDate?: string,
    public endDate?: string,
    public type?: FiscalDeclarationType,
    public returnMessage?: string,
    public teledecId?: number,
    public status?: DeclarationStatus,
    public teledecStatus?: string,
    public deadlineDate?: string,
    public label?: string,
    public link?: string,
    public statusSource?: DeclarationStatusSource,
    public errors?: DeclarationStatusError[],
    public statusAt?: Date,
    public statusBy?: User,
    public consistencyChecks?: VerboseConsistencyCheck[]
  ) {}

  static fromJson(json: Partial<FiscalDeclarationJson>): FiscalDeclaration {
    return new FiscalDeclaration(
      json.id,
      json.start_date,
      json.end_date,
      json.type,
      json.return_message,
      json.teledec_id,
      json.status,
      json.teledec_status,
      json.deadline_date,
      json.label,
      json.link,
      json.status_source,
      json.errors
        ? json.errors.map((errorJson: DeclarationStatusErrorJson) => DeclarationStatusError.fromJson(errorJson))
        : [],
      json.status_date ? DateHelper.fromUtc(json.status_date) : null,
      json.status_by ? User.fromJson(json.status_by) : null,
      (json.consistency_checks ?? []).map((j: VerboseConsistencyCheckJson) => VerboseConsistencyCheck.fromJson(j))
    );
  }
}
