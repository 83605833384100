import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';

import { NgUtils } from 'tiime-expert-utils';

import { ComplexSearchBarAutocompleteFilter } from './complex-search-bar-autocomplete-filter';
import { ComplexSearchBarAutocompleteSection } from './complex-search-bar-autocomplete-section';

@Component({
  selector: 'tiime-complex-search-bar-autocomplete',
  templateUrl: './complex-search-bar-autocomplete.component.html',
  styleUrls: ['./complex-search-bar-autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'ComplexSearchBarAutocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplexSearchBarAutocompleteComponent {
  @Input() sections: ComplexSearchBarAutocompleteSection[];

  @Output() readonly selectFilter: EventEmitter<ComplexSearchBarAutocompleteFilter> =
    new EventEmitter<ComplexSearchBarAutocompleteFilter>();

  @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;

  readonly trackByIndex = NgUtils.trackByIndex;

  protected onSelectFilter(event: MatOptionSelectionChange, filter: ComplexSearchBarAutocompleteFilter): void {
    if (!event.isUserInput) {
      return;
    }

    this.selectFilter.emit(filter);
  }
}
