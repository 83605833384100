import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-table-content-placeholder',
  templateUrl: './table-content-placeholder.component.html',
  styleUrls: ['./table-content-placeholder.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableContentPlaceholderComponent {}
