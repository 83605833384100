export enum AppRoute {
  empty = '',
  signin = 'signin',
  home = 'home',
  createPassword = 'create-password',
  authCallback = 'auth-callback',
  companies = 'companies',
  planning = 'planning',
  unauthorized = 'unauthorized',
  notFound = 'not-found',
  ebicsBanks = 'ebics-banks',
  admin = 'admin',
  timeTrackings = 'time-trackings',
  opportunities = 'opportunities'
}
