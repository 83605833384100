import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-empty-state',
  host: {
    class: 'tiime-empty-state'
  },
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TiimeEmptyStateComponent {}
