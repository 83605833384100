import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, ViewEncapsulation } from '@angular/core';
import {
  MatLegacySlideToggle as MatSlideToggle,
  MatLegacySlideToggleChange
} from '@angular/material/legacy-slide-toggle';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-slide-toggle-container',
  host: {
    class: 'tiime-slide-toggle-container',
    '[class.small]': '_hasHostAttributes("small")',
    '[class.large]': '_hasHostAttributes("large")',
    '[class.selected]': '_hasHostAttributes("selected")',
    '(click)': 'onClick($event)'
  },
  templateUrl: './slide-toggle-container.component.html',
  styleUrls: ['slide-toggle-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SlideToggleContainerComponent extends HasAttributesBase {
  @ContentChild(MatSlideToggle) toggle: MatSlideToggle;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  onClick(): void {
    if (!this.toggle?.disabled) {
      this.toggle?.toggle();
      this.toggle?.change.emit(new MatLegacySlideToggleChange(this.toggle, this.toggle.checked));
    }
  }
}
