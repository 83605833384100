export class StringHelper {
  static normalizeToLowerCase(text: string): string {
    const NORMALIZED_FORM = 'NFD';
    const UNICODS_FROM_0300_TO_036F_REGEX = /[\u0300-\u036f]/g;
    return text.normalize(NORMALIZED_FORM).replace(UNICODS_FROM_0300_TO_036F_REGEX, '').toLowerCase();
  }

  /**
   * Retire tous les espaces d'une chaîne de caractères
   * @param {string} text Le texte à formater
   * @return {string} Le texte formaté
   */
  static removeSpaces(text: string): string {
    return text.replace(/\s/g, '');
  }
}

/**
 * @deprecated Use `StringHelper` instead.
 */
export class StringUtils extends StringHelper {}
