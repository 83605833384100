import { FormControl, ValidatorFn } from '@angular/forms';

export class AccountNumberFormControl extends FormControl<string | null | undefined> {
  constructor(formState?: string | null | undefined, validators?: ValidatorFn | ValidatorFn[]) {
    super(formState, validators);
  }

  completeAccountNumber(limit = 8): void {
    if (
      this.valid &&
      this.value !== '' &&
      this.value !== null &&
      !isNaN(parseInt(this.value, 10)) &&
      this.value?.length < limit &&
      !this.value?.toLowerCase()?.match(/[a-z]/gi)
    ) {
      this.setValue(this.value.padEnd(limit, '0'));
    }
  }
}
