<tiime-input-container
  contentId="corpotate-tax-instalment-select"
  [label]="label"
  [attr.small]="small"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="corpotate-tax-instalment-select"
    [placeholder]="label"
    [formControl]="control"
    [customValue]="control.value | map: mapToCorporateTaxInstalment"
    (selectionChange)="emitSelectionChange($event)"
  >
    <cdk-virtual-scroll-viewport
      appVirtualScrollViewportSize
      class="tiime-virtual-scroll-viewport corpotate-tax-instalment-virtual-scroll-viewport"
      [itemSize]="virtualScrollItemSize"
      tiimeSelectOptions
    >
      <mat-option
        *cdkVirtualFor="let corporateTaxInstalment of selectorData; trackBy: trackById"
        [value]="corporateTaxInstalment"
      >
        du {{ corporateTaxInstalment.deadlineDate | date: 'shortDate' }} | {{ corporateTaxInstalment.label }}
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </tiime-select>
</tiime-input-container>
