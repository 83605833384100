export interface StockTypeJson {
  code: string;
  description: string;
}

export class StockType {
  constructor(public code?: string, public description?: string) {}

  static fromJson(json: StockTypeJson): StockType {
    return new StockType(json.code, json.description);
  }
}
