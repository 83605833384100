import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { OperationType } from '@enums/operation-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { TransactionsFilters } from '@interfaces/transactions-filters';
import { Document } from '@models/document';
import { Imputation } from '@models/imputation';
import { Invoice } from '@models/invoice';
import { SelectionDetail } from '@models/selection-detail';
import { Transaction, TransactionJson } from '@models/transaction';
import { TransactionForVatOverride } from '@models/transaction-for-vat-override';
import { TransactionsTotal, TransactionsTotalJson } from '@models/transactions-total';
import { TemporaryEncoder } from '@modules/core/temporary-encoder';

export type OverrideVatAction = 'override' | 'ignore';

/**
 * Transaction Service.
 */
@Injectable({
  providedIn: 'root'
})
export class TransactionApiService extends ApiCommonService<Transaction> {
  readonly resourceUrl = `${COMPANY_API_ROUTE_BASE}/bank_transactions`;

  fromJson(json: TransactionJson): Transaction {
    return Transaction.fromJson(json);
  }

  toJson(
    model: Transaction
  ): Pick<
    TransactionJson,
    'id' | 'transaction_date' | 'vat_application_date' | 'bank_account' | 'wording' | 'amount' | 'operation_type'
  > {
    return Transaction.toJson(model);
  }

  @ApiAlertError()
  getTransactions(
    range: PaginationRange,
    searchTerms: string,
    sort: Sort,
    filters?: TransactionsFilters,
    expand?: string
  ): Observable<PaginationData<Transaction>> {
    let params = HttpHelper.createHttpParams();
    if (searchTerms) {
      params = HttpHelper.setQParam(searchTerms, params);
    }
    if (sort) {
      params = HttpHelper.setSortParam(sort, params);
    }
    if (filters) {
      params = HttpHelper.setParams(filters, params);
    }
    if (expand) {
      params = HttpHelper.setExpandParam(expand, params);
    }

    let headers = new HttpHeaders({
      Accept: 'application/vnd.tiime.bank_transactions.v2+json'
    });
    headers = HttpHelper.setRangeHeader(headers, range);
    const options = { params, headers };

    return this.http.get<TransactionJson[]>(this.resourceUrl, { ...options, observe: 'response' }).pipe(
      filter((response: HttpResponse<TransactionJson[]>) => response.status !== 204),
      HttpHelper.mapToPaginationData(range, json => this.fromJson(json))
    );
  }

  @ApiAlertError()
  getTransactionsTotal(
    range: PaginationRange,
    searchTerms: string,
    sort: Sort,
    filters?: TransactionsFilters,
    expand?: string
  ): Observable<TransactionsTotal> {
    const url = `${this.resourceUrl}/totals`;

    let params = HttpHelper.createHttpParams();
    if (searchTerms) {
      params = HttpHelper.setQParam(searchTerms, params);
    }
    if (sort) {
      params = HttpHelper.setSortParam(sort, params);
    }
    if (filters) {
      params = HttpHelper.setParams(filters, params);
    }
    if (expand) {
      params = HttpHelper.setExpandParam(expand, params);
    }

    let headers = new HttpHeaders();
    headers = HttpHelper.setRangeHeader(headers, range);
    const options = { params, headers };

    return this.http
      .get<TransactionsTotalJson>(url, options)
      .pipe(map((json: TransactionsTotalJson) => TransactionsTotal.fromJson(json)));
  }

  @ApiAlertError()
  getBankAccountToValidateTransactions(
    bankAccountId: number,
    companyId: number,
    range: PaginationRange
  ): Observable<PaginationData<Transaction>> {
    const params = HttpHelper.setParams({
      bank_account: bankAccountId
    });
    const url = companyId ? HttpHelper.replaceCompanyUrl(this.resourceUrl, companyId) : this.resourceUrl;
    return this.getPaginated(range, params, url);
  }

  @ApiAlertError()
  getImportTransactions(importId: number, range: PaginationRange): Observable<PaginationData<Transaction>> {
    return this.getPaginated(range, HttpHelper.setParams({ import: String(importId) }));
  }

  @ApiAlertError()
  addTransactions(transactions: Transaction[]): Observable<Transaction[]> {
    return super.updateMultiple(transactions, this.resourceUrl, 'POST');
  }

  @ApiAlertError()
  getInitialSoldeTransaction(bankAccountId: number): Observable<Transaction> {
    let params: HttpParams = new HttpParams({
      encoder: new TemporaryEncoder()
    });
    params = params.set('bank_account', String(bankAccountId));
    params = params.set('operation_type', OperationType.aNouveau);

    return this.http
      .get<TransactionJson[]>(this.resourceUrl, { params })
      .pipe(
        map(transactionsJson => (transactionsJson.length === 1 ? Transaction.fromJson(transactionsJson[0]) : null))
      );
  }

  @ApiAlertError()
  updateTransaction(transaction: Transaction): Observable<Transaction> {
    const url = `${this.resourceUrl}/${transaction.id}`;
    const headers = new HttpHeaders({
      Accept: 'application/vnd.tiime.imputations.v2+json'
    });

    return this.http
      .patch(url, this.toJson(transaction), { headers })
      .pipe(map((json: TransactionJson) => this.fromJson(json)));
  }

  @ApiAlertError()
  removeTransaction(id: number): Observable<any> {
    return super.delete(id);
  }

  @ApiAlertError()
  restoreTransaction(id: number): Observable<any> {
    const url = `${this.resourceUrl}/${id}/undelete`;
    return this.http.post(url, null);
  }

  @ApiAlertError()
  override getPaginated(
    range: PaginationRange,
    params: HttpParams,
    url = this.resourceUrl
  ): Observable<PaginationData<Transaction>> {
    return super.getPaginated(range, params, url);
  }

  @ApiAlertError()
  updateImputations(transactionId: number, imputations: Imputation[]): Observable<Transaction> {
    const url = `${this.resourceUrl}/${transactionId}`;
    const body = {
      id: transactionId,
      imputations: imputations.map((imputation: Imputation) => Imputation.toJson(imputation))
    };
    const headers = new HttpHeaders({
      Accept: 'application/vnd.tiime.imputations.v2+json'
    });

    return this.http.patch(url, body, { headers }).pipe(map(transactionJson => Transaction.fromJson(transactionJson)));
  }

  @ApiAlertError()
  getTransactionWithOverridableVat(
    paginationRange: PaginationRange
  ): Observable<PaginationData<TransactionForVatOverride>> {
    const url = `${this.resourceUrl}/override_vat`;
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), paginationRange);
    return this.http.get(url, { headers, observe: 'response' }).pipe(
      filter((response: HttpResponse<unknown>) => response.status !== 204),
      HttpHelper.mapToPaginationData(paginationRange, json => TransactionForVatOverride.fromJson(json))
    );
  }

  @ApiAlertError()
  selectTransactionToOverrideVat(selection: SelectionDetail, action: OverrideVatAction): Observable<unknown> {
    const url = `${this.resourceUrl}/override_vats/${action}`;
    return this.http.post(url, SelectionDetail.toJson(selection));
  }

  @ApiAlertError()
  matchTransactionWithDocuments(transactionId: number, documents: Document[]): Observable<unknown> {
    const url = `${this.resourceUrl}/${transactionId}/documents_matching`;
    const body = {
      documents: documents.map(document => ({ id: document.id }))
    };

    return this.http.put(url, body);
  }

  @ApiAlertError()
  matchTransactionWithInvoices(transactionId: number, invoices: Invoice[]): Observable<unknown> {
    const url = `${this.resourceUrl}/${transactionId}/invoices_matching`;

    const body = invoices.map(invoice => ({ id: invoice.id }));

    return this.http.put(url, body);
  }

  @ApiAlertError()
  searchTransactions(searchTerms: string, max?: number, companyId?: number): Observable<Transaction[]> {
    let url = this.resourceUrl;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    let params = HttpHelper.createHttpParams();
    if (searchTerms) {
      params = HttpHelper.setQParam(searchTerms, params);
    }

    let headers = new HttpHeaders({
      Accept: 'application/vnd.tiime.bank_transactions.v2+json'
    });
    headers = HttpHelper.setRangeHeader(headers, new PaginationRange(0, max - 1));

    return super.search({ headers, params }, url);
  }
}
