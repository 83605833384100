import { createSelector } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { TaxRegimeCode } from '@enums/tax-regime-code.enum';
import { VatSystemCode } from '@enums/vat-system-code.enum';
import { AppStoreState } from '@interfaces/app-store-state';
import { Company } from '@models/company';
import { CompanyState } from '@modules/company/company-core/store/company/company-reducer';

export const companySelector = (state: AppStoreState): CompanyState =>
  state && state.company ? cloneDeep(state.company) : null;

export const companyWithoutVatSelector = createSelector(
  companySelector,
  (company: Company) => company?.vatSystem?.code === VatSystemCode.ss
);

export const companyInitializationInProgressSelector = createSelector(
  companySelector,
  (company: Company) => company?.initializationInProgress
);

export const companyBusinessUnitIdSelector = createSelector(
  companySelector,
  (company: Company) => company?.businessUnit?.id
);

export const companyIsMicroEnterpriseSelector = createSelector(
  companySelector,
  (company: Company) => company?.taxRegime === TaxRegimeCode.MICRO
);

export const companyIsCopiedSelector = createSelector(companySelector, (company: Company) => company?.isCopy);

export const companyHubEligibilityConnectionsSelector = createSelector(
  companySelector,
  (company: Company) => company?.hubEligibilityConnections
);

export const companyConfigSelector = createSelector(companySelector, (company: Company) => company?.config);

export const companyCanAccessBuildingsSelector = createSelector(
  companySelector,
  (company: Company) =>
    company?.legalForm === 'SCI' && [TaxRegimeCode.SCIS, TaxRegimeCode.SCIC].includes(company?.taxRegime)
);
