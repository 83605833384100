import { VatDeclarationContentType } from '@enums/vat-declaration-content-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { VatDeclarationContent, VatDeclarationContentJson } from '@models/vat-declaration-content';

export interface VatDeclarationContentValueJson {
  label: string;
  placeholder: string;
  hidden: boolean;
  code: string;
  class: string;
  type: VatDeclarationContentType;
  deletable: boolean;
  created_at: Date;
  created_by: string;
  created_source: string;
  selectable: boolean;
  sub_matrix: Partial<VatDeclarationContentJson>[];
}

export class VatDeclarationContentValue {
  constructor(
    public label?: string,
    public placeholder?: string,
    public hidden?: boolean,
    public code?: string,
    public contentClass?: string,
    public type?: VatDeclarationContentType,
    public deletable?: boolean,
    public createdAt?: Date,
    public createdBy?: string,
    public createdSource?: string,
    public selectable?: boolean,
    public subMatrix?: VatDeclarationContent[]
  ) {}

  static fromJson(json: Partial<VatDeclarationContentValueJson>): VatDeclarationContentValue {
    return new VatDeclarationContentValue(
      json.label,
      json.placeholder,
      json.hidden,
      json.code,
      json.class,
      json.type,
      json.deletable,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.created_by,
      json.created_source,
      json.selectable,
      json.sub_matrix ? json.sub_matrix.map(matrix => VatDeclarationContent.fromJson(matrix)) : null
    );
  }

  static toJson(vatDeclarationContentValue: VatDeclarationContentValue): Partial<VatDeclarationContentValueJson> {
    return {
      label: vatDeclarationContentValue.label,
      placeholder: vatDeclarationContentValue.placeholder,
      hidden: vatDeclarationContentValue.hidden,
      code: vatDeclarationContentValue.code,
      class: vatDeclarationContentValue.contentClass,
      type: vatDeclarationContentValue.type,
      deletable: vatDeclarationContentValue.deletable,
      created_at: vatDeclarationContentValue.createdAt,
      created_by: vatDeclarationContentValue.createdBy,
      created_source: vatDeclarationContentValue.createdSource,
      selectable: vatDeclarationContentValue.selectable,
      sub_matrix: vatDeclarationContentValue.subMatrix?.length
        ? vatDeclarationContentValue.subMatrix.map(matrix => VatDeclarationContent.toJson(matrix))
        : null
    };
  }
}
