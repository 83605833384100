import { TotalPerVatType, TotalPerVatTypeJson } from './total-per-vat-type';

export interface TotalsPerVatTypeJson {
  [key: string]: Partial<TotalPerVatTypeJson>;
}

export class TotalsPerVatType {
  [key: string]: TotalPerVatType;

  static fromJson(json: Partial<TotalsPerVatTypeJson>): TotalsPerVatType {
    const totalsPerVatType: TotalsPerVatType = {};
    Object.keys(json).forEach(key => (totalsPerVatType[key] = TotalPerVatType.fromJson(json[key])));
    return totalsPerVatType;
  }
}
