import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin, Observable } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';

import { DialogBase } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { APP_CODE, APP_PLATFORM } from '@constants/app.constant';
import { AppStoreState } from '@interfaces/app-store-state';
import { CareFormFields } from '@interfaces/care-form-fields';
import { Environment } from '@interfaces/environment';
import { BusinessUser } from '@models/business-user';
import { BusinessUserPartner } from '@models/business-user-parner';
import { Company } from '@models/company';
import { companySelector } from '@modules/company/company-core/store/company/company-selector';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';
import { SafePipe } from '@modules/shared/pipes/safe/safe.pipe';
import { AppConfigService } from '@services/app-config.service';

@Component({
  selector: 'app-care-contact-dialog',
  templateUrl: './care-contact-dialog.component.html',
  styleUrls: ['./care-contact-dialog.component.scss']
})
export class CareContactDialogComponent extends DialogBase<CareContactDialogComponent> implements OnInit {
  businessUser: BusinessUser;
  businessUserPartners: BusinessUserPartner[];
  company: Company;
  safePipe: SafePipe;
  formUrl: SafeResourceUrl;

  get env(): Environment {
    return this.appConfigService.environment;
  }

  get version(): string {
    return this.appConfigService.version;
  }

  constructor(
    private appConfigService: AppConfigService,
    private deviceService: DeviceDetectorService,
    private businessUserApiService: BusinessUserApiService,
    private store: Store<AppStoreState>,
    protected sanitizer: DomSanitizer,
    dialogRef: MatDialogRef<CareContactDialogComponent>
  ) {
    super(dialogRef);
    this.safePipe = new SafePipe(sanitizer);
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.isLoading = true;
    forkJoin([this.getBusinessUser(), this.getCompany()])
      .pipe(
        tap(([businessUser, company]: [BusinessUser, Company]) => {
          this.businessUser = businessUser;
          this.company = company;
        }),
        switchMap(() => this.businessUserApiService.getBusinessUserPartner(this.company?.id)),
        tap(businessUserPartners => (this.businessUserPartners = businessUserPartners)),
        tap(() => this.buildFormUrl(this.getCareFormFields())),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private getBusinessUser(): Observable<BusinessUser> {
    return this.store.pipe(select(businessUserSelector), take(1));
  }

  private getCompany(): Observable<Company> {
    return this.store.pipe(select(companySelector), take(1));
  }

  private getCareFormFields(): CareFormFields {
    const deviceInfo = this.deviceService.getDeviceInfo();
    return {
      device: `${deviceInfo.os} ${deviceInfo.os_version}`,
      device_version: `${deviceInfo.browser} ${deviceInfo.browser_version}`,
      app_platform: APP_PLATFORM,
      app_used: APP_CODE,
      app_version: this.version,
      lastname: this.businessUser.lastName,
      firstname: this.businessUser.firstName,
      email: this.businessUser.email,
      company_id: this.company?.id.toString() || null,
      company_reference: this.company?.reference || null,
      partner_id: this.businessUserPartners[0]?.id?.toString() || null,
      multiple_partner: this.businessUserPartners?.length > 1 || false
    };
  }

  private buildFormUrl(careFormFields: CareFormFields): void {
    const params = new HttpParams({
      fromObject: { ...careFormFields }
    });
    const url = `${this.env.CARE_FORM_URL}?${params.toString()}`;
    this.formUrl = this.safePipe.transform(url, 'resourceUrl');
  }
}
