<button
  tiime-button
  color="primary"
  icon
  tiimeTooltip
  [tooltipContent]="markAsReadTooltipTemplate"
  (click)="$event.stopPropagation(); emitMarkNotificationAsRead()"
  *ngIf="!notification.read && hovered"
>
  <mat-icon fontIcon="icon-ic-check"></mat-icon>
</button>
<ng-template #markAsReadTooltipTemplate>Marquer comme lu</ng-template>
<app-status color="warn" *ngIf="!notification.read && !hovered"></app-status>
