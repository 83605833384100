<mat-progress-spinner
  class="dialog-spinner"
  [class.align-right]="loaderPosition === 'end'"
  diameter="24"
  color="primary"
  mode="indeterminate"
  *ngIf="isLoading"
></mat-progress-spinner>
<button
  type="button"
  title="Déplacer"
  class="drag-button"
  tiime-button
  color="primary"
  icon
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".body"
  data-cy="dialog-drag-button"
>
  <mat-icon inline="true" fontIcon="icon-ic-drag-and-drop"></mat-icon>
</button>
<button
  type="button"
  title="Fermer"
  class="close-button"
  tiime-button
  color="primary"
  icon
  data-cy="dialog-close-btn"
  (click)="emitCancel()"
>
  <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
</button>
<ng-content></ng-content>
