import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { Mapper, MapperPipe } from 'tiime-expert-utils';
import { TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { VirtualScrollViewportSizeDirective } from '@shared-directives/virtual-scroll-viewport-size/virtual-scroll-viewport-size.directive';

@Component({
  selector: 'app-corporate-tax-instalment-select',
  standalone: true,
  imports: [
    CommonModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    ScrollingModule,
    VirtualScrollViewportSizeDirective,
    MatOptionModule,
    MapperPipe
  ],
  templateUrl: './corporate-tax-instalment-select.component.html',
  styleUrl: './corporate-tax-instalment-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorporateTaxInstalmentSelectComponent extends SelectBase<CorporateTaxInstalment> {
  @Input() label: string = "Déclaration de l'acompte d'IS";

  readonly virtualScrollItemSize: number = 30;

  private readonly datePipe: DatePipe = new DatePipe('fr-FR');

  readonly mapToCorporateTaxInstalment: Mapper<CorporateTaxInstalment, string> = (
    corporateTaxInstalment: CorporateTaxInstalment
  ) =>
    corporateTaxInstalment
      ? `du ${this.datePipe.transform(corporateTaxInstalment.deadlineDate, 'dd/MM/YYYY')} | ${
          corporateTaxInstalment.label
        }`
      : '';
}
