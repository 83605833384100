import { Action, createReducer, on } from '@ngrx/store';

import { BusinessUnit } from '@models/business-unit';
import { BusinessUnitUser } from '@models/business-unit-user';
import { BusinessUser } from '@models/business-user';

import * as BusinessUserActions from './business-user-actions';

export type BusinessUserState = BusinessUser | null;
export const initialState: BusinessUserState = null;

const userReducer = createReducer(
  initialState,
  on(BusinessUserActions.update, (_, { businessUser }) => newBusinessUser(businessUser)),
  on(BusinessUserActions.updatePartial, (state, { businessUser }) => newBusinessUser({ ...state, ...businessUser })),
  on(BusinessUserActions.updateBusinessUnit, (state, { businessUnit }) =>
    newBusinessUser({
      ...state,
      businessUnits: state.businessUnits.map((bU: BusinessUnit) => (bU.id === businessUnit.id ? businessUnit : bU)),
      businessUnitUsers: state.businessUnitUsers.map((bUU: BusinessUnitUser) => {
        if (bUU.businessUnit.id === businessUnit.id) {
          bUU = { ...bUU, businessUnit };
          return bUU;
        } else {
          return bUU;
        }
      })
    })
  ),
  on(BusinessUserActions.updatePartner, (state, { partner }) => newBusinessUser({ ...state, partner })),
  on(BusinessUserActions.updatePartnerConfig, (state, { config }) => 
    newBusinessUser({ ...state, partner: { ...state.partner, config } })
  )
);

function newBusinessUser(businessUser: Partial<BusinessUser>): BusinessUser {
  return new BusinessUser(
    businessUser.id,
    businessUser.firstName,
    businessUser.lastName,
    businessUser.email,
    businessUser.phone,
    businessUser.acronym,
    businessUser.businessUnits,
    businessUser.roles,
    businessUser.businessUnitUsers,
    businessUser.partner,
    businessUser.isPartnerAdmin,
    businessUser.mustRenewPassword
  );
}

export function reducer(state: BusinessUser | undefined, action: Action): BusinessUserState {
  return userReducer(state, action);
}
