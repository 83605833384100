import { SelectionType } from '@enums/selection-type.enum';

export interface SelectionDetailJson {
  type: SelectionType;
  ids: number[];
}

export class SelectionDetail {
  constructor(public type?: SelectionType, public ids?: number[]) {}

  static toJson(selectionDetail: SelectionDetail): SelectionDetailJson {
    return {
      type: selectionDetail.type,
      ids: selectionDetail.ids ? selectionDetail.ids : null
    };
  }

  static isEmpty(selection: SelectionDetail): boolean {
    return selection.type === 'included' && !selection.ids?.length;
  }
}
