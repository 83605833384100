import { HttpHelper } from '@helpers/http.helper';

export interface OfferJson {
  id: number;
  tag: string;
  label: string;
}

export class Offer {
  constructor(
    public id?: number,
    public tag?: string,
    public label?: string
  ) {}

  static fromJson(json: Partial<OfferJson>): Offer {
    return new Offer(json.id, json.tag, json.label);
  }

  static toJson(model: Offer): OfferJson {
    return {
      id: HttpHelper.toJsonProperty(model.id),
      tag: HttpHelper.toJsonProperty(model.tag),
      label: HttpHelper.toJsonProperty(model.label)
    };
  }
}
