import { PieceType } from '@enums/piece-type.enum';
import { CashRegisterCashFlow, CashRegisterCashFlowJson } from '@models/cash-register-cash-flow';
import { Document, DocumentJson } from '@models/document';
import { ExpenseReport, ExpenseReportJson } from '@models/expense-report';
import { Immobilisation, ImmobilisationJson } from '@models/immobilisation';
import { Invoice, InvoiceJson } from '@models/invoice';
import { InvoiceMask, InvoiceMaskJson } from '@models/invoice-mask';
import { LeaseContract, LeaseContractJson } from '@models/lease-contract';
import { Loan, LoanJson } from '@models/loan';
import { Movement, MovementJson } from '@models/movement';
import { PayPeriod, PayPeriodJson } from '@models/pay-period';
import { PieceFile, PieceFileJson } from '@models/piece-file';
import { PrepaidMask, PrepaidMaskJson } from '@models/prepaid-mask';
import { Receipt, ReceiptJson } from '@models/receipt';
import { ResultAllocation, ResultAllocationJson } from '@models/result-allocation';
import { Stock, StockJson } from '@models/stock';
import { Tns, TnsJson } from '@models/tns';
import { Transaction, TransactionJson } from '@models/transaction';
import { VatDeclarationConfiguration, VatDeclarationConfigurationJson } from '@models/vat-declaration-configuration';

export interface PieceJson {
  id: number;
  amount: number;
  type: PieceType;
  wording: string;
  date: string;
  stock: Partial<StockJson>;
  bank_transaction: Partial<TransactionJson>;
  invoice: Partial<InvoiceJson>;
  receipt: Partial<ReceiptJson>;
  immobilisation: Partial<ImmobilisationJson>;
  result_allocation: Partial<ResultAllocationJson>;
  loan: Partial<LoanJson>;
  invoice_to_establish: Partial<InvoiceMaskJson>;
  invoice_not_received: Partial<InvoiceMaskJson>;
  prepaid_expense: Partial<PrepaidMaskJson>;
  prepaid_income: Partial<PrepaidMaskJson>;
  expense_report: Partial<ExpenseReportJson>;
  movements: Partial<MovementJson>[];
  pay_period: Partial<PayPeriodJson>;
  vat_declaration: Partial<VatDeclarationConfigurationJson>;
  cash_flow: Partial<CashRegisterCashFlowJson>;
  piece_files: PieceFileJson[];
  document: Partial<DocumentJson>;
  tns: Partial<TnsJson>;
  lease_contract: Partial<LeaseContractJson>;
  pointed: boolean;
}

export class Piece {
  constructor(
    public id?: number,
    public amount?: number,
    public type?: PieceType,
    public wording?: string,
    public date?: string,
    public stock?: Stock,
    public transaction?: Transaction,
    public invoice?: Invoice,
    public receipt?: Receipt,
    public immobilisation?: Immobilisation,
    public resultAllocation?: ResultAllocation,
    public loan?: Loan,
    public invoiceToEstablish?: InvoiceMask,
    public invoiceNotReceived?: InvoiceMask,
    public prepaidExpense?: PrepaidMask,
    public prepaidIncome?: PrepaidMask,
    public expenseReport?: ExpenseReport,
    public movements?: Movement[],
    public payPeriod?: PayPeriod,
    public vatDeclaration?: VatDeclarationConfiguration,
    public cashFlow?: CashRegisterCashFlow,
    public files?: PieceFile[],
    public document?: Document,
    public tns?: Tns,
    public leaseContract?: LeaseContract,
    public pointed?: boolean
  ) {}

  static fromJson(json: Partial<PieceJson>): Piece {
    return new Piece(
      json.id,
      json.amount,
      json.type,
      json.wording,
      json.date,
      json.stock ? Stock.fromJson(json.stock) : null,
      json.bank_transaction ? Transaction.fromJson(json.bank_transaction) : null,
      json.invoice ? Invoice.fromJson(json.invoice) : null,
      json.receipt ? Receipt.fromJson(json.receipt) : null,
      json.immobilisation ? Immobilisation.fromJson(json.immobilisation) : null,
      json.result_allocation ? ResultAllocation.fromJson(json.result_allocation) : null,
      json.loan ? Loan.fromJson(json.loan) : null,
      json.invoice_to_establish ? InvoiceMask.fromJson(json.invoice_to_establish) : null,
      json.invoice_not_received ? InvoiceMask.fromJson(json.invoice_not_received) : null,
      json.prepaid_expense ? PrepaidMask.fromJson(json.prepaid_expense) : null,
      json.prepaid_income ? PrepaidMask.fromJson(json.prepaid_income) : null,
      json.expense_report ? ExpenseReport.fromJson(json.expense_report) : null,
      json.movements ? json.movements.map(data => Movement.fromJson(data)) : [],
      json.pay_period ? PayPeriod.fromJson(json.pay_period) : null,
      json.vat_declaration ? VatDeclarationConfiguration.fromJson(json.vat_declaration) : null,
      json.cash_flow ? CashRegisterCashFlow.fromJson(json.cash_flow) : null,
      json.piece_files ? json.piece_files.map(fileJson => PieceFile.fromJson(fileJson)) : [],
      json.document ? Document.fromJson(json.document) : null,
      json.tns ? Tns.fromJson(json.tns) : null,
      json.lease_contract ? LeaseContract.fromJson(json.lease_contract) : null,
      json.pointed
    );
  }

  static toJson(
    piece: Piece
  ): Omit<PieceJson, 'invoice' | 'loan' | 'immobilisation' | 'expense_report' | 'cash_flow' | 'piece_files'> {
    return {
      id: piece.id,
      amount: piece.amount,
      type: piece.type,
      wording: piece.wording,
      date: piece.date,
      stock: piece.stock ? Stock.toJson(piece.stock) : null,
      receipt: piece.receipt ? Receipt.toJson(piece.receipt) : null,
      bank_transaction: piece.transaction ? Transaction.toJson(piece.transaction) : null,
      result_allocation: piece.resultAllocation ? ResultAllocation.toJson(piece.resultAllocation) : null,
      invoice_to_establish: piece.invoiceToEstablish ? InvoiceMask.toJson(piece.invoiceToEstablish) : null,
      invoice_not_received: piece.invoiceNotReceived ? InvoiceMask.toJson(piece.invoiceNotReceived) : null,
      prepaid_expense: piece.prepaidExpense ? InvoiceMask.toJson(piece.prepaidExpense) : null,
      prepaid_income: piece.prepaidIncome ? InvoiceMask.toJson(piece.prepaidIncome) : null,
      movements: piece.movements ? piece.movements.map((movement: Movement) => Movement.toJson(movement)) : null,
      pay_period: piece.payPeriod ? PayPeriod.toJson(piece.payPeriod) : null,
      vat_declaration: piece.vatDeclaration ? VatDeclarationConfiguration.toJson(piece.vatDeclaration) : null,
      document:
        piece.document && [PieceType.externalInvoice, PieceType.expenseReport, PieceType.receipt].includes(piece.type)
          ? Document.toJson(piece.document)
          : null,
      tns: piece.tns ? Tns.toJson(piece.tns) : null,
      lease_contract: piece.leaseContract ? LeaseContract.toJson(piece.leaseContract) : null,
      pointed: piece.pointed
    };
  }
}
