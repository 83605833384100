import { DateHelper } from '@helpers/date.helper';
import { ApeCode } from '@models/ape-code';

export interface InpiDepositJson {
  siret?: string;
  registration_date?: string;
  ape_code?: ApeCode;
  deposit_inpi_status_by: string;
  deposit_inpi_status_update_date: Date;
  deposit_inpi_status: string;
}

export class InpiDeposit {
  constructor(
    public siret?: string,
    public registrationDate?: string,
    public apeCode?: ApeCode,
    public depositInpiStatusBy?: string,
    public depositInpiStatusUpdateDate?: Date,
    public depositInpiStatus?: string
  ) {}

  static fromJson(json: InpiDepositJson): InpiDeposit {
    return new InpiDeposit(
      json.siret,
      json.registration_date,
      ApeCode.fromJson(json.ape_code),
      json.deposit_inpi_status_by,
      DateHelper.fromUtc(json.deposit_inpi_status_update_date),
      json.deposit_inpi_status
    );
  }

  static toJson(inpiDeposit: InpiDeposit): InpiDepositJson {
    return {
      siret: inpiDeposit.siret,
      registration_date: inpiDeposit.registrationDate ? DateHelper.format(inpiDeposit.registrationDate) : null,
      ape_code: ApeCode.toJson(inpiDeposit.apeCode),
      deposit_inpi_status_by: inpiDeposit.depositInpiStatusBy,
      deposit_inpi_status_update_date: inpiDeposit.depositInpiStatusUpdateDate,
      deposit_inpi_status: inpiDeposit.depositInpiStatus
    };
  }

  static toPatchStatus(status: string): Pick<InpiDepositJson, 'deposit_inpi_status'> {
    return {
      deposit_inpi_status: status
    };
  }
}
