export const QUERY_PARAMS = {
  accountingPeriod: 'accounting_period',
  fiscalDeclaration: 'fiscal_declaration',
  pcgEntry: 'pcg_entry',
  documentCategory: 'document_category',
  journalCode: 'journal_code',
  user: 'user',
  loginHint: 'login_hint',
  openWorkbookSheet: 'open_workbook_sheet',
  customAccountingPeriodEnd: 'custom_period_end'
};
