import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SlideToggleContainerComponent } from './slide-toggle-container.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SlideToggleContainerComponent],
  exports: [SlideToggleContainerComponent]
})
export class TiimeSlideToggleContainerModule {}
