import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AppRoute } from '../navigation/app-route.enum';
import { businessUserSelector } from '../store/business-user/business-user-selector';

@Injectable({
  providedIn: 'root'
})
export class PasswordCreatedGuard  {
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  canActivate(): Observable<boolean | UrlTree> {
    return this.isPasswordCreated();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.isPasswordCreated();
  }

  private isPasswordCreated(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(businessUserSelector),
      filter(user => !!user),
      take(1),
      map(({ mustRenewPassword }) => {
        if (mustRenewPassword) {
          return this.router.createUrlTree([`${AppRoute.createPassword}`], {
            queryParamsHandling: 'preserve'
          });
        }
        return true;
      })
    );
  }
}
