export interface LogoJson {
  id: number;
}

export class Logo {
  constructor(public id?: number) {}

  static fromJson(json: LogoJson): Logo {
    return new Logo(json.id);
  }

  static toJson(logo: Logo): LogoJson {
    return {
      id: logo.id
    };
  }
}
