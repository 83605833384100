import { DateHelper } from '@helpers/date.helper';

export interface BeneficiaryAccountJson {
  user_id: number;
  moral_person_id: number;
  firstname: string;
  lastname: string;
  capacity: string;
  interest_amount: number;
  abandoned_amount: number;
  balance: number;
  repossessed_amount: number;
  effective_date: string;
  moral_person_name?: string;
}

export class BeneficiaryAccount {
  constructor(
    public userId?: number,
    public moralPersonId?: number,
    public firstname?: string,
    public lastname?: string,
    public capacity?: string,
    public interestAmount?: number,
    public abandonedAmount?: number,
    public balance?: number,
    public repossessedAmount?: number,
    public effectiveDate?: Date,
    public moralPersonName?: string
  ) {}

  get id(): number {
    return this.userId || this.moralPersonId;
  }

  get fullName(): string {
    return this.userId ? `${this.firstname} ${this.lastname}` : this.moralPersonName;
  }

  static fromJson(json: BeneficiaryAccountJson): BeneficiaryAccount {
    return new BeneficiaryAccount(
      json.user_id,
      json.moral_person_id,
      json.firstname,
      json.lastname,
      json.capacity,
      json.interest_amount,
      json.abandoned_amount,
      json.balance,
      json.repossessed_amount,
      json.effective_date ? DateHelper.fromUtc(json.effective_date) : null,
      json.moral_person_name
    );
  }

  static toJson(beneficiaryAccount: BeneficiaryAccount): BeneficiaryAccountJson {
    return {
      user_id: beneficiaryAccount.userId,
      moral_person_id: beneficiaryAccount.moralPersonId,
      firstname: beneficiaryAccount.firstname,
      lastname: beneficiaryAccount.lastname,
      capacity: beneficiaryAccount.capacity,
      interest_amount: beneficiaryAccount.interestAmount,
      abandoned_amount: beneficiaryAccount.abandonedAmount,
      balance: beneficiaryAccount.balance,
      repossessed_amount: beneficiaryAccount.repossessedAmount,
      effective_date: beneficiaryAccount.effectiveDate ? DateHelper.format(beneficiaryAccount.effectiveDate) : null,
      moral_person_name: beneficiaryAccount.moralPersonName
    };
  }
}
