import { AutoProcessStatus } from '@enums/auto-process-status.enum';
import { ConsistencyCheckStatus } from '@enums/consistency-check-status.enum';
import { DeclarationStatusSource } from '@enums/declaration-status-source';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { DateHelper } from '@helpers/date.helper';
import { AutoProcessMetadata, AutoProcessMetadataJson } from '@models/auto-process-metadata';
import { Company, CompanyJson } from '@models/company';
import { DeclarationStatusError, DeclarationStatusErrorJson } from '@models/declaration-status-error';
import { Thread, ThreadJson } from '@models/thread';
import { User } from '@models/user';
import {
  VatDeclarationConfigurationData,
  VatDeclarationConfigurationDataJson
} from '@models/vat-declaration-configuration-data';
import { VatSystem, VatSystemJson } from '@models/vat-system';
import { VerboseConsistencyCheck, VerboseConsistencyCheckJson } from '@models/verbose-consistency-check';

export interface VatDeclarationConfigurationJson {
  id: number;
  status: DeclarationStatus;
  version: DeclarationStatus;
  start_date: string;
  end_date: string;
  content: Partial<VatDeclarationConfigurationDataJson>;
  cerfa: string;
  deadline_date: string;
  company: Partial<CompanyJson>;
  status_source: DeclarationStatusSource;
  errors: Partial<DeclarationStatusErrorJson>[];
  label: string;
  vat_system: Partial<VatSystemJson>;
  vat_declaration: Partial<VatDeclarationConfigurationJson>;
  global_status: DeclarationStatus;
  auto_process_at: string;
  auto_process_status: AutoProcessStatus;
  auto_process_meta_data: Record<string, AutoProcessMetadataJson[]>;
  consistency_check_status: ConsistencyCheckStatus;
  consistency_check_at: string;
  consistency_check_meta_data: VerboseConsistencyCheckJson[];
  status_date: string;
  status_by: {
    firstname: string;
    lastname: string;
  };
  has_fec_file: boolean;
  with_telepayment: boolean;
  threads: Partial<ThreadJson>[];
  validation_client_at: string;
}

export type VatDeclarationConfigurationSerialized = Omit<
  VatDeclarationConfigurationJson,
  | 'cerfa'
  | 'deadline_date'
  | 'company'
  | 'status_source'
  | 'errors'
  | 'vat_system'
  | 'vat_declaration'
  | 'global_status'
  | 'auto_process_at'
  | 'auto_process_status'
  | 'auto_process_meta_data'
  | 'consistency_check_status'
  | 'consistency_check_at'
  | 'consistency_check_meta_data'
  | 'status_date'
  | 'status_by'
  | 'has_fec_file'
  | 'threads'
  | 'validation_client_at'
>;

export class VatDeclarationConfiguration {
  constructor(
    public id?: number,
    public status?: DeclarationStatus,
    public version?: DeclarationStatus,
    public startDate?: string,
    public endDate?: string,
    public content?: VatDeclarationConfigurationData,
    public cerfa?: string,
    public deadlineDate?: string,
    public company?: Company,
    public statusSource?: DeclarationStatusSource,
    public errors?: DeclarationStatusError[],
    public label?: string,
    public vatSystem?: VatSystem,
    public parentVatDeclaration?: VatDeclarationConfiguration,
    public globalStatus?: DeclarationStatus,
    public autoProcessAt?: string,
    public autoProcessStatus?: AutoProcessStatus,
    public autoProcessMetaData?: AutoProcessMetadata[],
    public consistencyCheckStatus?: ConsistencyCheckStatus,
    public consistencyCheckAt?: Date,
    public consistencyCheckMetaData?: VerboseConsistencyCheck[],
    public statusAt?: Date,
    public statusBy?: User,
    public hasFecFile?: boolean,
    public withTelepayment?: boolean,
    public threads?: Thread[],
    public validationClientAt?: Date
  ) {}

  static fromJson(json: Partial<VatDeclarationConfigurationJson>): VatDeclarationConfiguration {
    return new VatDeclarationConfiguration(
      json.id,
      json.status,
      json.version,
      json.start_date,
      json.end_date,
      json.content ? VatDeclarationConfigurationData.fromJson(json.content) : null,
      json.cerfa,
      json.deadline_date,
      json.company ? Company.fromJson(json.company) : null,
      json.status_source,
      json.errors ? json.errors.map((errorJson: any) => DeclarationStatusError.fromJson(errorJson)) : [],
      json.label,
      json.vat_system ? VatSystem.fromJson(json.vat_system) : null,
      json.vat_declaration ? this.fromJson(json.vat_declaration) : null,
      json.global_status,
      json.auto_process_at,
      json.auto_process_status,
      json.auto_process_meta_data
        ? Object.values(json.auto_process_meta_data)
            .flat()
            .map(autoProcessMetaDataJson => AutoProcessMetadata.fromJson(autoProcessMetaDataJson))
        : [],
      json.consistency_check_status,
      json.consistency_check_at ? DateHelper.fromUtc(json.consistency_check_at) : null,
      json.consistency_check_meta_data
        ? json.consistency_check_meta_data.map(consistencyCheckJson =>
            VerboseConsistencyCheck.fromJson(consistencyCheckJson)
          )
        : [],
      json.status_date ? DateHelper.fromUtc(json.status_date) : null,
      json.status_by ? User.fromJson(json.status_by) : null,
      json.has_fec_file,
      json.with_telepayment,
      json.threads ? json.threads.map(threadJson => Thread.fromJson(threadJson)) : [],
      json.validation_client_at ? DateHelper.fromUtc(json.validation_client_at) : null
    );
  }

  static toJson(vatDeclarationConfiguration: VatDeclarationConfiguration): VatDeclarationConfigurationSerialized {
    return {
      id: vatDeclarationConfiguration.id,
      status: vatDeclarationConfiguration.status,
      version: vatDeclarationConfiguration.version,
      start_date: vatDeclarationConfiguration.startDate,
      end_date: vatDeclarationConfiguration.endDate,
      content: vatDeclarationConfiguration.content
        ? VatDeclarationConfigurationData.toJson(vatDeclarationConfiguration.content)
        : null,
      label: vatDeclarationConfiguration.label,
      with_telepayment: vatDeclarationConfiguration.withTelepayment
    };
  }
}
