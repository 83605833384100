import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

import { SnackbarConfig } from './snackbar-config';
import { SnackBarData } from './snackbar-data';
import { SnackbarComponent } from './snackbar.component';
import { TiimeSnackbarModule } from './snackbar.module';

@Injectable({
  providedIn: TiimeSnackbarModule
})
export class TiimeSnackbarService {
  constructor(private matSnackbar: MatSnackBar) {}

  open(data: string | SnackBarData, config: MatSnackBarConfig): MatSnackBarRef<SnackbarComponent> {
    return this.matSnackbar.openFromComponent(SnackbarComponent, {
      ...config,
      data
    });
  }

  openSuccess(successMessage: string | SnackBarData): MatSnackBarRef<SnackbarComponent> {
    return this.open(successMessage, SnackbarConfig.success);
  }

  openError(errorMessage: string | SnackBarData): MatSnackBarRef<SnackbarComponent> {
    return this.open(errorMessage, SnackbarConfig.error);
  }

  openPrimary(message: string | SnackBarData): MatSnackBarRef<SnackbarComponent> {
    return this.open(message, SnackbarConfig.primary);
  }
}
