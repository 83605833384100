<mat-autocomplete class="tiime-pro-autocomplete-panel" autoActiveFirstOption>
  <mat-option
    *ngFor="let user of data; trackBy: trackById"
    (onSelectionChange)="emitSelect($event, user)"
    [value]="user"
  >
    <div class="user-option">
      <span class="user-option-name">
        {{ user.fullName }}
      </span>
      <span class="user-option-email">
        {{ user.email }}
      </span>
    </div>
  </mat-option>
</mat-autocomplete>
