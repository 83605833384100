import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable, map } from 'rxjs';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { ApiAlertError } from '@decorators/api-alert-error';
import { FiltersParams, HttpHelper } from '@helpers/http.helper';
import { TaskPlanning, TaskPlanningJson, TaskPlanningSerialized } from '@models/task-planning';
import { TaskPlanningTotal, TaskPlanningTotalJson } from '@models/task-planning-total';
import { TaskScheduler, TaskSchedulerJson } from '@models/task-scheduler';

@Injectable({
  providedIn: 'root'
})
export class TaskPlanningApiService extends ApiCommonService<TaskPlanning> {
  resourceUrl = 'api/v1/expert/users/me/tasks';

  fromJson(json: TaskPlanningJson): TaskPlanning {
    return TaskPlanning.fromJson(json);
  }

  toJson(model: TaskPlanning): TaskPlanningSerialized {
    return TaskPlanning.toJson(model);
  }

  @ApiAlertError([403])
  getTaskPlanningsByCompany(companyId: number): Observable<TaskPlanning[]> {
    const params = HttpHelper.setParam('company', companyId.toString());
    return this.getAll({ params });
  }

  @ApiAlertError([403])
  getTaskPlannings(
    range: PaginationRange,
    searchTerms: string,
    sort: Sort,
    filters: FiltersParams
  ): Observable<PaginationData<TaskPlanning>> {
    let params = HttpHelper.createHttpParams();
    params = HttpHelper.setQParam(searchTerms, params);
    params = HttpHelper.setSortParam(sort, params);

    if (filters) {
      params = HttpHelper.setParams(filters, params);
    }
    return this.getPaginated(range, params);
  }

  @ApiAlertError([403])
  getTaskPlanningsTotalAmount(searchTerms: string, filters: FiltersParams): Observable<TaskPlanningTotal> {
    const url = `${this.resourceUrl}/totals`;

    let params = HttpHelper.createHttpParams();
    params = HttpHelper.setQParam(searchTerms, params);

    if (filters) {
      params = HttpHelper.setParams(filters, params);
    }

    return this.http
      .get<TaskPlanningTotalJson>(url, { params })
      .pipe(map((taskPlanningTotal: TaskPlanningTotalJson) => TaskPlanningTotal.fromJson(taskPlanningTotal)));
  }

  @ApiAlertError([403])
  createTaskPlanning(taskPlanning: TaskPlanning): Observable<TaskPlanning> {
    const url = `api/v1/expert/tasks`;
    const body = this.toJson(taskPlanning);
    return this.http.post<TaskPlanningJson>(url, body).pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError([403])
  updateTaskPlanning(companyId: number, taskPlanning: TaskPlanning, applyNexts?: string): Observable<TaskPlanning> {
    const url = `api/v1/expert/companies/${companyId}/tasks/${taskPlanning.id}`;
    let params = HttpHelper.createHttpParams();
    if (applyNexts) {
      params = HttpHelper.setParam('apply_nexts', applyNexts, params);
    }
    return this.http
      .patch(url, this.toJson(taskPlanning), { params })
      .pipe(map((json: TaskPlanningJson) => this.fromJson(json)));
  }

  @ApiAlertError([403])
  deleteTaskPlanning(companyId: number, taskPlanningId: number, applyNexts?: string): Observable<unknown> {
    const url = `api/v1/expert/companies/${companyId}/tasks/${taskPlanningId}`;
    let params = HttpHelper.createHttpParams();
    if (applyNexts) {
      params = HttpHelper.setParam('apply_nexts', applyNexts, params);
    }
    return this.http.delete(url, { params });
  }

  @ApiAlertError()
  getTaskScheduler(companyId: number, taskSchedulerId: number): Observable<TaskScheduler> {
    const url = `api/v1/expert/companies/${companyId}/task_schedulers/${taskSchedulerId}`;
    return this.http.get(url).pipe(map((json: TaskSchedulerJson) => TaskScheduler.fromJson(json)));
  }

  @ApiAlertError()
  updateTaskScheduler(companyId: number, taskScheduler: TaskScheduler, applyNexts?: string): Observable<TaskScheduler> {
    const url = `api/v1/expert/companies/${companyId}/task_schedulers/${taskScheduler.id}`;
    let params = HttpHelper.createHttpParams();
    if (applyNexts) {
      params = HttpHelper.setParam('apply_nexts', applyNexts, params);
    }
    return this.http
      .patch(url, TaskScheduler.toJson(taskScheduler), { params })
      .pipe(map((json: TaskSchedulerJson) => TaskScheduler.fromJson(json)));
  }
}
