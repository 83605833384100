import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ROUTE_REPLACE_COMPANY_KEY, GI_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { LABEL_VAT_TYPE_REQUIRING_VAT_EXONERATION_TYPE } from '@constants/label-vat-type-requiring-vat-exoneration-type.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { ProspectAcquisitionChannel } from '@enums/prospect-acquisition-channel.enum';
import { HttpHelper } from '@helpers/http.helper';
import { shareReplay1 } from '@helpers/rxjs.helper';
import { BalanceSharedFilters } from '@interfaces/balance-shared-filters';
import { ApeCode } from '@models/ape-code';
import { Balance } from '@models/balance';
import { BankAccountType } from '@models/bank-account-type';
import { Civility, CivilityJson } from '@models/civility';
import { CodeAndLabel } from '@models/code-and-label';
import { CompanyRegistry, CompanyRegistryJson } from '@models/company-registry';
import { ConnectionProvider, ConnectionProviderJson } from '@models/connection-provider';
import { Country, CountryJson } from '@models/country';
import { Department, DepartmentJson } from '@models/department';
import { FiscalResultLabel, FiscalResultLabelJson } from '@models/fiscal-result-label';
import {
  FiscalResultTaxReductionCreditType,
  FiscalResultTaxReductionCreditTypeJson
} from '@models/fiscal-result-tax-reduction-credit-type';
import { FoundsOrigin, FoundsOriginJson } from '@models/founds-origin';
import { JournalType } from '@models/journal-type';
import { Label, LabelJson } from '@models/label';
import { MaritalStatus, MaritalStatusJson } from '@models/marital-status';
import { MissionMotif } from '@models/mission-motif';
import { SocialRegime, SocialRegimeJson } from '@models/social-regime';
import { StockType, StockTypeJson } from '@models/stock-type';
import { TaxRegime } from '@models/tax-regime';
import { UserRole, UserRoleJson } from '@models/user-role';
import { VatExonerationType } from '@models/vat-exoneration-type';
import { VatSystem } from '@models/vat-system';
import { VatType } from '@models/vat-type';

/**
 * Common Data Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonDataApiService {
  private journalTypesCache: Observable<JournalType[]>;
  private apeCodesCache: Observable<ApeCode[]>;
  private bankAccountTypesCache: Observable<BankAccountType[]>;
  private invoicingVatTypesCache: Observable<VatType[]>;
  private legaFormsCache: Observable<string[]>;
  private vatSystemsCache: Observable<VatSystem[]>;
  private vatTypesCache: Observable<VatType[]>;
  private ebicsBanksCache: Observable<string[]>;
  private taxRegimesCache: Observable<TaxRegime[]>;
  private fiscalResultLabelCache: Observable<FiscalResultLabel[]>;
  private vatExonerationTypesCache: Observable<VatExonerationType[]>;
  private departmentsCache: Observable<Department[]>;
  private countriesCache: Observable<Country[]>;
  private businessUnitUserRolesCache: Observable<UserRole[]>;
  private standardLabelsCache: Observable<Label[]>;
  private partnerEbicsconnectionProviderCache: Observable<ConnectionProvider[]>;
  private stockTypesCache: Observable<StockType[]>;
  private currenciesCache: Observable<string[]>;
  private missionMotifsCache: Observable<MissionMotif[]>;
  private foundsOriginsCache: Observable<FoundsOrigin[]>;
  private socialRegimesCache: Observable<SocialRegime[]>;
  private civilitiesCache: Observable<Civility[]>;
  private maritalStatusesCache: Observable<MaritalStatus[]>;
  private taxReductionCreditTypesCache: Observable<FiscalResultTaxReductionCreditType[]>;
  private buildingSpecificDeductionsCache: Observable<CodeAndLabel<string>[]>;
  private buildingDepreciationDeductionsCache: Observable<CodeAndLabel<string>[]>;
  private buildingKindsACache: Observable<CodeAndLabel<string>[]>;
  private buildingKindsBCache: Observable<CodeAndLabel<string>[]>;
  private companyRegistriesCache: Observable<CompanyRegistry[]>;
  private bilanCoverReasonsCache: Observable<CodeAndLabel<string>[]>;
  private contractLossReasonsCache: Observable<CodeAndLabel<string>[]>;
  private prospectAcquisitionChannelsCache: Observable<CodeAndLabel<ProspectAcquisitionChannel>[]>;
  private immobilisationCategories: Observable<CodeAndLabel<string>[]>;

  constructor(private http: HttpClient) {}

  @ApiAlertError()
  getJournalTypes(): Observable<JournalType[]> {
    if (!this.journalTypesCache) {
      const url = `api/v1/expert/journal_types`;

      this.journalTypesCache = this.http.get(url).pipe(
        map((journalTypesJson: any) =>
          journalTypesJson.map((journalTypeJson: any) => JournalType.fromJson(journalTypeJson))
        ),
        shareReplay1()
      );
    }

    return this.journalTypesCache;
  }

  @ApiAlertError()
  getLegalForms(): Observable<string[]> {
    if (!this.legaFormsCache) {
      const url = 'api/v1/legal_forms';

      this.legaFormsCache = this.http.get<string[]>(url).pipe(shareReplay1());
    }
    return this.legaFormsCache;
  }

  @ApiAlertError()
  getApeCodes(): Observable<ApeCode[]> {
    if (!this.apeCodesCache) {
      const url = 'api/v1/ape_codes';

      this.apeCodesCache = this.http.get(url).pipe(
        map((apeCodesJson: any) => apeCodesJson.map((apeCodeJson: any) => ApeCode.fromJson(apeCodeJson))),
        shareReplay1()
      );
    }
    return this.apeCodesCache;
  }

  @ApiAlertError()
  getVatSystems(): Observable<VatSystem[]> {
    if (!this.vatSystemsCache) {
      const url = 'api/v1/expert/vat_systems';

      this.vatSystemsCache = this.http.get(url).pipe(
        map((vatSystemsJson: any) => vatSystemsJson.map((vatSystemJson: any) => VatSystem.fromJson(vatSystemJson))),
        shareReplay1()
      );
    }
    return this.vatSystemsCache;
  }

  @ApiAlertError()
  getInvoicingVatTypes(): Observable<VatType[]> {
    if (!this.invoicingVatTypesCache) {
      const url = `api/v1/expert/invoicing_config/vat_types`;

      this.invoicingVatTypesCache = this.http.get(url).pipe(
        map((vatTypesJson: any) => vatTypesJson.map((vatTypeJson: any) => VatType.fromJson(vatTypeJson))),
        shareReplay1()
      );
    }
    return this.invoicingVatTypesCache;
  }

  @ApiAlertError()
  getBankAccountTypes(): Observable<BankAccountType[]> {
    if (!this.bankAccountTypesCache) {
      const url = 'api/v1/expert/bank_account_types';

      this.bankAccountTypesCache = this.http.get(url).pipe(
        map((bankAccountTypesJson: any) =>
          bankAccountTypesJson.map((bankAccountTypeJson: any) => BankAccountType.fromJson(bankAccountTypeJson))
        ),
        shareReplay1()
      );
    }
    return this.bankAccountTypesCache;
  }

  @ApiAlertError()
  getVatTypes(): Observable<VatType[]> {
    if (!this.vatTypesCache) {
      const url = `api/v1/expert/vat_types`;

      this.vatTypesCache = this.http.get(url).pipe(
        map((vatTypesJson: any) => vatTypesJson.map((vatTypeJson: any) => VatType.fromJson(vatTypeJson))),
        shareReplay1()
      );
    }

    return this.vatTypesCache;
  }

  getLabelVatTypeRequiringVatExonerationType(): Observable<VatType> {
    return this.getVatTypes().pipe(
      map(types => types.find(({ code }) => code === LABEL_VAT_TYPE_REQUIRING_VAT_EXONERATION_TYPE))
    );
  }

  @ApiAlertError()
  getEbicsBanks(): Observable<string[]> {
    if (!this.ebicsBanksCache) {
      const url = `api/v1/expert/ebics_banks`;

      this.ebicsBanksCache = this.http.get<string[]>(url).pipe(shareReplay1());
    }

    return this.ebicsBanksCache;
  }

  @ApiAlertError()
  getTaxRegimes(): Observable<TaxRegime[]> {
    if (!this.taxRegimesCache) {
      const url = `api/v1/tax_regimes`;

      this.taxRegimesCache = this.http.get(url).pipe(
        map((taxRegimesJson: any) => taxRegimesJson.map((taxRegimeJson: any) => TaxRegime.fromJson(taxRegimeJson))),
        shareReplay1()
      );
    }

    return this.taxRegimesCache;
  }

  @ApiAlertError()
  getVatExonerationTypes(): Observable<VatExonerationType[]> {
    if (!this.vatExonerationTypesCache) {
      const url = `api/v1/expert/vat_exoneration_types`;

      this.vatExonerationTypesCache = this.http.get(url).pipe(
        map((json: Record<string, string>) =>
          Object.entries(json).map(([key, value]) =>
            VatExonerationType.fromJson({
              name: key,
              value
            })
          )
        ),
        shareReplay1()
      );
    }
    return this.vatExonerationTypesCache;
  }

  @ApiAlertError()
  getPcgEntryBalances(pcgEntryId: number, filters?: BalanceSharedFilters): Observable<Balance[]> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/pcg_entries/${pcgEntryId}/balances`;
    const headers = new HttpHeaders({
      Accept: 'application/vnd.balances.v2+json'
    });
    let params: HttpParams;
    if (filters) {
      params = HttpHelper.setParams({
        pcg_entry_balanced: filters?.pcgEntryBalanced === 'all' ? undefined : filters.pcgEntryBalanced,
        pcg_entry_has_movement: filters?.pcgEntryHasMovement === 'all' ? undefined : filters.pcgEntryHasMovement,
        pcg_entry_validated: filters?.pcgEntryValidated === 'all' ? undefined : filters.pcgEntryValidated,
        expand: filters?.expand,
        date: filters?.date
      });
    }
    return this.http
      .get(url, { headers, params })
      .pipe(map((balancesJson: any) => balancesJson.map((balanceJson: any) => Balance.fromJson(balanceJson))));
  }

  @ApiAlertError()
  getFiscalResultLabels(): Observable<FiscalResultLabel[]> {
    if (!this.fiscalResultLabelCache) {
      const url = `api/v1/expert/fiscal_result_labels`;

      this.fiscalResultLabelCache = this.http.get<FiscalResultLabelJson[]>(url).pipe(
        map(fiscalResultsLabelsJson =>
          fiscalResultsLabelsJson.map(fiscalResultsLabelJson => FiscalResultLabel.fromJson(fiscalResultsLabelJson))
        ),
        shareReplay1()
      );
    }

    return this.fiscalResultLabelCache;
  }

  @ApiAlertError()
  getDepartments(): Observable<Department[]> {
    if (!this.departmentsCache) {
      const url = `api/v1/departments`;

      this.departmentsCache = this.http.get<DepartmentJson[]>(url).pipe(
        map(jsons => jsons.map(json => Department.fromJson(json))),
        shareReplay1()
      );
    }

    return this.departmentsCache;
  }

  @ApiAlertError()
  getCountries(): Observable<Country[]> {
    if (!this.countriesCache) {
      const url = `api/v1/countries`;

      this.countriesCache = this.http.get<CountryJson[]>(url).pipe(
        map(jsons => jsons.map(json => Country.fromJson(json))),
        shareReplay1()
      );
    }

    return this.countriesCache;
  }

  @ApiAlertError()
  getBusinessUnitUserRoles(): Observable<UserRole[]> {
    if (!this.businessUnitUserRolesCache) {
      const url = `api/v1/expert/roles?type=business_unit_user`;

      this.businessUnitUserRolesCache = this.http.get(url).pipe(
        map((businessUnitUserRolesJson: UserRoleJson[]) =>
          businessUnitUserRolesJson.map((businessUnitUserRoleJson: UserRoleJson) =>
            UserRole.fromJson(businessUnitUserRoleJson)
          )
        ),
        shareReplay1()
      );
    }

    return this.businessUnitUserRolesCache;
  }

  @ApiAlertError([403])
  getStandardLabels(): Observable<Label[]> {
    if (!this.standardLabelsCache) {
      const url = `api/v1/expert/standard_labels`;
      this.standardLabelsCache = this.http.get<LabelJson[]>(url).pipe(
        map(jsons => jsons.map(json => Label.fromJson(json))),
        shareReplay1()
      );
    }
    return this.standardLabelsCache;
  }

  @ApiAlertError([403])
  getParnerEbicsConnectionProvider(): Observable<ConnectionProvider[]> {
    if (!this.partnerEbicsconnectionProviderCache) {
      const url = `api/v1/expert/ebics_connection_providers?scope=partner`;
      this.partnerEbicsconnectionProviderCache = this.http.get(url).pipe(
        map((ebicsConnectionProvidersJson: ConnectionProviderJson[]) =>
          ebicsConnectionProvidersJson.map((ebicsConnectionProviderJson: ConnectionProviderJson) =>
            ConnectionProvider.fromJson(ebicsConnectionProviderJson)
          )
        ),
        shareReplay1()
      );
    }
    return this.partnerEbicsconnectionProviderCache;
  }

  @ApiAlertError()
  getStockTypes(): Observable<StockType[]> {
    if (!this.stockTypesCache) {
      const url = `api/v1/expert/stock_types`;

      this.stockTypesCache = this.http.get<StockTypeJson[]>(url).pipe(
        map(jsons => jsons.map(json => StockType.fromJson(json))),
        shareReplay1()
      );
    }
    return this.stockTypesCache;
  }

  @ApiAlertError()
  getCurrencies(): Observable<string[]> {
    if (!this.currenciesCache) {
      const url = `api/v1/currencies`;
      this.currenciesCache = this.http.get<string[]>(url).pipe(shareReplay1());
    }
    return this.currenciesCache;
  }

  @ApiAlertError()
  getMissionMotifs(): Observable<MissionMotif[]> {
    if (!this.missionMotifsCache) {
      const url = `api/v1/expert/mission_exit_motifs`;
      this.missionMotifsCache = this.http.get<MissionMotif[]>(url).pipe(
        map(missionMotifsJson => missionMotifsJson.map(missionMotifJson => MissionMotif.fromJson(missionMotifJson))),
        shareReplay1()
      );
    }
    return this.missionMotifsCache;
  }

  @ApiAlertError()
  getFoundsOrigins(): Observable<FoundsOrigin[]> {
    if (!this.foundsOriginsCache) {
      const url = `api/v1/founds_origin`;

      this.foundsOriginsCache = this.http.get(url).pipe(
        map((jsons: FoundsOriginJson[]) => jsons.map((json: FoundsOriginJson) => FoundsOrigin.fromJson(json))),
        shareReplay1()
      );
    }

    return this.foundsOriginsCache;
  }

  @ApiAlertError()
  getSocialRegimes(): Observable<SocialRegime[]> {
    if (!this.socialRegimesCache) {
      const url = `api/v1/social_regimes`;

      this.socialRegimesCache = this.http.get(url).pipe(
        map((jsons: SocialRegimeJson[]) => jsons.map((json: SocialRegimeJson) => SocialRegime.fromJson(json))),
        shareReplay1()
      );
    }

    return this.socialRegimesCache;
  }

  @ApiAlertError()
  getCivilities(): Observable<Civility[]> {
    if (!this.civilitiesCache) {
      const url = `api/v1/civilities`;

      this.civilitiesCache = this.http.get(url).pipe(
        map((jsons: CivilityJson[]) => jsons.map((json: CivilityJson) => Civility.fromJson(json))),
        shareReplay1()
      );
    }

    return this.civilitiesCache;
  }

  @ApiAlertError()
  getMaritalStatuses(): Observable<MaritalStatus[]> {
    if (!this.maritalStatusesCache) {
      const url = `api/v1/marital_statuses`;

      this.maritalStatusesCache = this.http.get(url).pipe(
        map((jsons: MaritalStatusJson[]) => jsons.map((json: MaritalStatusJson) => MaritalStatus.fromJson(json))),
        shareReplay1()
      );
    }

    return this.maritalStatusesCache;
  }

  @ApiAlertError()
  getTaxReductionCreditTypes(): Observable<FiscalResultTaxReductionCreditType[]> {
    if (!this.taxReductionCreditTypesCache) {
      const url = `api/v1/expert/tax_reduction_credit_types`;

      this.taxReductionCreditTypesCache = this.http.get(url).pipe(
        map((jsons: FiscalResultTaxReductionCreditTypeJson[]) =>
          jsons.map((json: FiscalResultTaxReductionCreditTypeJson) => FiscalResultTaxReductionCreditType.fromJson(json))
        ),
        shareReplay1()
      );
    }

    return this.taxReductionCreditTypesCache;
  }

  @ApiAlertError()
  getBuildingSpecificDeductions(): Observable<CodeAndLabel<string>[]> {
    if (!this.buildingSpecificDeductionsCache) {
      const url = `api/v1/expert/building_specific_deductions`;

      this.buildingSpecificDeductionsCache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.buildingSpecificDeductionsCache;
  }

  @ApiAlertError()
  getBuildingDepreciationDeductions(): Observable<CodeAndLabel<string>[]> {
    if (!this.buildingDepreciationDeductionsCache) {
      const url = `api/v1/expert/building_depreciation_deductions`;

      this.buildingDepreciationDeductionsCache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.buildingDepreciationDeductionsCache;
  }

  @ApiAlertError()
  getBuildingKindsA(): Observable<CodeAndLabel<string>[]> {
    if (!this.buildingKindsACache) {
      const url = `api/v1/expert/building_kind_a`;

      this.buildingKindsACache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.buildingKindsACache;
  }

  @ApiAlertError()
  getBuildingKindsB(): Observable<CodeAndLabel<string>[]> {
    if (!this.buildingKindsBCache) {
      const url = `api/v1/expert/building_kind_b`;

      this.buildingKindsBCache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.buildingKindsBCache;
  }

  @ApiAlertError()
  getCompanyRegistries(): Observable<CompanyRegistry[]> {
    if (!this.companyRegistriesCache) {
      const url = `api/v1/expert/company_registries`;

      this.companyRegistriesCache = this.http.get<CompanyRegistryJson[]>(url).pipe(
        map((companyRegistriesJson: CompanyRegistryJson[]) =>
          companyRegistriesJson.map((companyRegistryJson: CompanyRegistryJson) =>
            CompanyRegistry.fromJson(companyRegistryJson)
          )
        ),
        shareReplay1()
      );
    }

    return this.companyRegistriesCache;
  }

  @ApiAlertError()
  getBilanCoverReasons(): Observable<CodeAndLabel<string>[]> {
    if (!this.bilanCoverReasonsCache) {
      const url = `api/v1/expert/bilan_cover_reasons`;

      this.bilanCoverReasonsCache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.bilanCoverReasonsCache;
  }

  @ApiAlertError()
  getContractLossReasons(): Observable<CodeAndLabel<string>[]> {
    if (!this.contractLossReasonsCache) {
      const url = `api/v1/expert/gi/contract_loss_reasons`;

      this.contractLossReasonsCache = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.contractLossReasonsCache;
  }

  @ApiAlertError()
  getProspectAcquisitionChannels(): Observable<CodeAndLabel<ProspectAcquisitionChannel>[]> {
    if (!this.prospectAcquisitionChannelsCache) {
      const url = `${GI_API_ROUTE_BASE}/prospect_acquisition_channels`;

      this.prospectAcquisitionChannelsCache = this.http
        .get<CodeAndLabel<ProspectAcquisitionChannel>[]>(url)
        .pipe(shareReplay1());
    }

    return this.prospectAcquisitionChannelsCache;
  }

  @ApiAlertError()
  getImmobilisationCategories(): Observable<CodeAndLabel<string>[]> {
    if (!this.immobilisationCategories) {
      const url = `api/v1/expert/immobilisation_categories`;

      this.immobilisationCategories = this.http.get<CodeAndLabel<string>[]>(url).pipe(shareReplay1());
    }

    return this.immobilisationCategories;
  }
}
