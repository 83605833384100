import { FormControl, FormGroup, Validators } from '@angular/forms';

import { VatDeclarationValueType } from '@enums/vat-declaration-value-type.enum';
import { VatDeclarationValue } from '@models/vat-declaration-value';

export type VatDeclarationValueFormType = {
  type: FormControl<VatDeclarationValueType | undefined>;
  code: FormControl<string | undefined>;
  calc: FormControl<string | undefined>;
  hidden: FormControl<boolean | undefined>;
  formValidator: FormControl<string | undefined>;
  readonly: FormControl<boolean | undefined>;
  condition: FormControl<string | undefined>;
  maxlength: FormControl<number | undefined>;
  formValue: FormControl<number | string | boolean | undefined>;
  highlighted: FormControl<boolean | null | undefined>;
  metadata: FormControl<Record<string, unknown> | undefined>;
};

export class VatDeclarationValueForm extends FormGroup<VatDeclarationValueFormType> {
  get type(): FormControl<VatDeclarationValueType | undefined> {
    return this.controls.type;
  }
  get code(): FormControl<string | undefined> {
    return this.controls.code;
  }
  get calc(): FormControl<string | undefined> {
    return this.controls.calc;
  }
  get hidden(): FormControl<boolean | undefined> {
    return this.controls.hidden;
  }
  get formValidator(): FormControl<string | undefined> {
    return this.controls.formValidator;
  }
  get readonly(): FormControl<boolean | undefined> {
    return this.controls.readonly;
  }
  get condition(): FormControl<string | undefined> {
    return this.controls.condition;
  }
  get maxlength(): FormControl<number | undefined> {
    return this.controls.maxlength;
  }
  get formValue(): FormControl<number | string | boolean | undefined> {
    return this.controls.formValue;
  }
  get highlighted(): FormControl<boolean | null | undefined> {
    return this.controls.highlighted;
  }
  get metadata(): FormControl<Record<string, unknown> | undefined> {
    return this.controls.metadata;
  }

  constructor() {
    super({
      type: new FormControl<VatDeclarationValueType | undefined>(undefined),
      code: new FormControl<string | undefined>(undefined),
      calc: new FormControl<string | undefined>(undefined),
      hidden: new FormControl<boolean | undefined>(undefined),
      formValidator: new FormControl<string | undefined>(undefined),
      readonly: new FormControl<boolean | undefined>(undefined),
      condition: new FormControl<string | undefined>(undefined),
      maxlength: new FormControl<number | undefined>(undefined),
      formValue: new FormControl<number | string | boolean | undefined>(undefined),
      highlighted: new FormControl<boolean | null | undefined>(undefined),
      metadata: new FormControl<Record<string, unknown> | undefined>(undefined)
    });
  }

  fromVatDeclarationValue(vatDeclarationValue: VatDeclarationValue): void {
    this.patchValue({
      type: vatDeclarationValue.type,
      code: vatDeclarationValue.code,
      calc: vatDeclarationValue.calc,
      hidden: false,
      formValidator: vatDeclarationValue.validator,
      readonly: vatDeclarationValue.disabled,
      condition: vatDeclarationValue.condition,
      maxlength: vatDeclarationValue.maxlength,
      formValue: vatDeclarationValue.value,
      metadata: vatDeclarationValue.metadata
    });

    if (vatDeclarationValue.required) {
      this.formValue.addValidators(Validators.required);
    }
  }
}
