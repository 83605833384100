import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { TiimeSnackbarService } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { PartnerConfigApiService } from '@api-services/partner-config-api.service';
import { RedirectIfResourceNotAvailable } from '@decorators/redirect-if-resource-not-available';
import { Role } from '@enums/role.enum';
import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { PartnerConfig } from '@models/partner-config';
import * as BusinessUserActions from '@modules/core/store/business-user/business-user-actions';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessUserGuard {
  constructor(
    private store: Store<AppStoreState>,
    private businessUserApiService: BusinessUserApiService,
    private navigationService: NavigationService,
    private snackbar: TiimeSnackbarService,
    private partnerConfigApiService: PartnerConfigApiService
  ) {}

  canActivate(): Observable<boolean> {
    return this.hasBusinessUserAndHasBusinessUserAcceptedRole();
  }

  canLoad(): Observable<boolean> {
    return this.hasBusinessUserAndHasBusinessUserAcceptedRole();
  }

  private hasBusinessUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserSelector),
      map((businessUser: BusinessUser) => !!businessUser),
      take(1)
    );
  }

  private hasBusinessUserInApi(): Observable<boolean> {
    return this.getBusinessUser().pipe(
      switchMap((businessUser: BusinessUser) =>
        businessUser.partner?.id
          ? this.partnerConfigApiService.getConfig(businessUser.partner?.id).pipe(
              map((config: PartnerConfig) => {
                businessUser.partner.config = config;
                return businessUser;
              })
            )
          : of(businessUser)
      ),
      tap((businessUser: BusinessUser) => this.store.dispatch(BusinessUserActions.update({ businessUser }))),
      catchError(() => of(false)),
      switchMap(() => this.hasBusinessUserInStore())
    );
  }

  private hasBusinessUserAndHasBusinessUserAcceptedRole(): Observable<boolean> {
    return this.hasBusinessUserInStore().pipe(
      switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasBusinessUserInApi())),
      switchMap((hasBusinessUser: boolean) => (hasBusinessUser ? this.hasBusinessUserAcceptedRole() : of(false)))
    );
  }

  private hasBusinessUserAcceptedRole(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserSelector),
      map((businessUser: BusinessUser) => {
        const isAccepted = businessUser.roles?.some(role =>
          [Role.BUSINESS_AGENT, Role.ENGINE_ADMIN, Role.PARTNER_ADMIN, Role.GUEST, Role.SALES].includes(role)
        );
        if (!isAccepted) {
          this.snackbar.openError(`Accès refusé`);
          this.navigationService.navigateToUnauthorized();
        }
        return isAccepted;
      }),
      take(1)
    );
  }

  @RedirectIfResourceNotAvailable()
  private getBusinessUser(): Observable<BusinessUser> {
    return this.businessUserApiService.getBusinessUser();
  }
}
