export interface AccountsApprovalDepositJson {
  amended: boolean;
  liasse_number?: string;
  depot_id?: string;
  reason?: string;
}

export class AccountsApprovalDeposit {
  constructor(
    public amended: boolean,
    public liasseNumber?: string,
    public depotId?: string,
    public reason?: string
  ) {}

  static fromJson(json: AccountsApprovalDepositJson): AccountsApprovalDeposit {
    return new AccountsApprovalDeposit(json.amended, json.liasse_number, json.depot_id, json.reason);
  }

  static toJson(accountsApprovalDeposit: AccountsApprovalDeposit): AccountsApprovalDepositJson {
    return {
      amended: accountsApprovalDeposit.amended,
      liasse_number: accountsApprovalDeposit.liasseNumber,
      depot_id: accountsApprovalDeposit.depotId,
      reason: accountsApprovalDeposit.reason
    };
  }
}
