<tiime-input-container
  class="export-date-input-container"
  contentId="exportDatePicker"
  small
  [errorMessage]="errorMessage?.message"
>
  <input
    id="exportDatePicker"
    placeholder="Date"
    [matDatepicker]="datepicker"
    [min]="min"
    [max]="max"
    [formControl]="dateControl"
    (dateChange)="emitDateChange($event)"
  />
  <mat-icon
    color="primary"
    class="calendar-icon"
    fontIcon="icon-calendar"
    tiimeSuffix
    (click)="datepicker.open()"
  ></mat-icon>
  <mat-datepicker
    panelClass="tiime-material-datepicker"
    [calendarHeaderComponent]="tiimeDatepickerHeader"
    #datepicker
  ></mat-datepicker>
</tiime-input-container>
