<div class="paginator-wrapper" [class.disabled]="range | map: mapToDisabled:disabled">
  <button
    title="Précédent"
    tiime-button
    icon
    color="primary"
    [disabled]="range | map: mapToHasNoPreviousPage"
    (click)="previousPage()"
  >
    <mat-icon inline="true" fontIcon="icon-chevron-left"></mat-icon>
  </button>
  <span class="range">{{ range | map: mapToRangeLabel }}</span>
  <button
    title="Suivant"
    tiime-button
    icon
    color="primary"
    [disabled]="range | map: mapToHasNoNextPage"
    (click)="nextPage()"
  >
    <mat-icon inline="true" fontIcon="icon-chevron-right"></mat-icon>
  </button>
</div>
