import { CompanyRegistry, CompanyRegistryJson } from './company-registry';

export interface HeadOfficeJson {
  host_user?: number;
  domiciliation_siren?: string;
  domiciliation_name?: string;
  domiciliation_start_date?: string;
  head_office_type?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  sign?: string;
  company_registry?: CompanyRegistryJson;
}

export class HeadOffice {
  constructor(
    public hostUser?: number,
    public domiciliationSiren?: string,
    public domiciliationName?: string,
    public domiciliationStartDate?: string,
    public headOfficeType?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public country?: string,
    public sign?: string,
    public companyRegistry?: CompanyRegistry
  ) {}

  static fromJson(json: Partial<HeadOfficeJson>): HeadOffice {
    return new HeadOffice(
      json.host_user,
      json.domiciliation_siren,
      json.domiciliation_name,
      json.domiciliation_start_date,
      json.head_office_type,
      json.street,
      json.postal_code,
      json.city,
      json.country,
      json.sign,
      json.company_registry ? CompanyRegistry.fromJson(json.company_registry) : null
    );
  }
  static toJson(activityOptions: HeadOffice): HeadOfficeJson {
    return {
      host_user: activityOptions.hostUser,
      domiciliation_siren: activityOptions.domiciliationSiren,
      domiciliation_name: activityOptions.domiciliationName,
      domiciliation_start_date: activityOptions.domiciliationStartDate,
      head_office_type: activityOptions.headOfficeType,
      street: activityOptions.street,
      postal_code: activityOptions.postalCode,
      city: activityOptions.city,
      country: activityOptions.country,
      sign: activityOptions.sign,
      company_registry: activityOptions.companyRegistry ? CompanyRegistry.toJson(activityOptions.companyRegistry) : null
    };
  }
}
