import { HttpHelper } from '@helpers/http.helper';
import { Offer, OfferJson } from '@models/offer';

export interface ContractOfferJson extends OfferJson {
  offer_model: Partial<OfferJson>;
}

export class ContractOffer extends Offer {
  constructor(
    id?: number,
    tag?: string,
    label?: string,
    public offer?: Offer
  ) {
    super(id, tag, label);
  }

  static fromJson(json: Partial<ContractOfferJson>): ContractOffer {
    return new ContractOffer(json.id, json.tag, json.label, json.offer_model ? Offer.fromJson(json.offer_model) : null);
  }

  static toJson(model: ContractOffer): ContractOfferJson {
    return {
      id: model.id ? HttpHelper.toJsonProperty(model.id) : undefined,
      tag: HttpHelper.toJsonProperty(model.tag),
      label: HttpHelper.toJsonProperty(model.label),
      offer_model: model.offer ? HttpHelper.toJsonId(model.offer) : null
    };
  }
}
