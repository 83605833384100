import { Injectable } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { noop, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TiimeSnackbarService } from 'tiime-material';

import { CompanyApiService } from '@api-services/company-api.service';
import { ContributorApiService } from '@api-services/contributor-api.service';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { ContributorJob } from '@models/contributor-job';

export interface IdentificationStoreState {
  contributorJobs: ContributorJob[];
  companyContributors: CompanyContributor[];
  businessUnitContributors: Contributor[];
}

export const initialState: IdentificationStoreState = {
  contributorJobs: [],
  companyContributors: [],
  businessUnitContributors: []
};

@Injectable()
export class IdentificationStore extends ComponentStore<IdentificationStoreState> {
  readonly contributorJobs$: Observable<ContributorJob[]> = this.select(({ contributorJobs }) => contributorJobs);
  readonly companyContributors$: Observable<CompanyContributor[]> = this.select(
    ({ companyContributors }) => companyContributors
  );
  readonly businessUnitContributors$: Observable<Contributor[]> = this.select(
    ({ businessUnitContributors }) => businessUnitContributors
  );

  loadContributorJobs = this.effect(source$ =>
    source$.pipe(
      switchMap(() =>
        this.companyApiService
          .getContributorJobs()
          .pipe(tapResponse(contributorJobs => this.patchState({ contributorJobs }), noop))
      )
    )
  );

  loadCompanyContributors = this.effect(source$ =>
    source$.pipe(
      switchMap(() =>
        this.companyApiService
          .getContributors()
          .pipe(tapResponse(companyContributors => this.patchState({ companyContributors }), noop))
      )
    )
  );

  loadBusinessUnitContributors = this.effect((businessUnitId$: Observable<number>) =>
    businessUnitId$.pipe(
      switchMap(businessUnitId =>
        this.contributorApiService
          .getContributors({ businessUnitId })
          .pipe(tapResponse(businessUnitContributors => this.patchState({ businessUnitContributors }), noop))
      )
    )
  );

  createCompanyContributor = this.effect((companyContributor$: Observable<CompanyContributor>) =>
    companyContributor$.pipe(
      switchMap(companyContributorToCreate =>
        this.companyApiService.createContributor(companyContributorToCreate).pipe(
          tapResponse(createdCompanyContributor => {
            this.patchState(state => ({
              ...state,
              companyContributors: [...state.companyContributors, createdCompanyContributor]
            }));

            this.snackbar.openSuccess(`L'intervenant a bien été ajouté`);
          }, noop)
        )
      )
    )
  );

  deleteCompanyContributor = this.effect((companyContributor$: Observable<CompanyContributor>) =>
    companyContributor$.pipe(
      switchMap(companyContributorToDelete =>
        this.companyApiService.deleteContributor(companyContributorToDelete.id).pipe(
          tapResponse(() => {
            const { companyContributors } = this.get();
            const deletedCompanyContributorIndex = companyContributors.findIndex(
              companyContributor => companyContributorToDelete.id === companyContributor.id
            );

            if (deletedCompanyContributorIndex !== -1) {
              this.patchState(state => ({
                ...state,
                companyContributors: [
                  ...state.companyContributors.slice(0, deletedCompanyContributorIndex),
                  ...state.companyContributors.slice(deletedCompanyContributorIndex + 1)
                ]
              }));
            }

            this.snackbar.openSuccess(`L'intervenant a bien été retiré`);
          }, noop)
        )
      )
    )
  );

  constructor(
    private companyApiService: CompanyApiService,
    private snackbar: TiimeSnackbarService,
    private contributorApiService: ContributorApiService
  ) {
    super(initialState);
  }
}
