export interface ThreadsInfosJson {
  has_opened_threads: boolean;
  current_user_has_opened_threads: boolean;
}

export class ThreadsInfos {
  constructor(public hasOpenedThreads?: boolean, public currentUserHasOpenedThreads?: boolean) {}

  static fromJson(json: ThreadsInfosJson): ThreadsInfos {
    return new ThreadsInfos(json.has_opened_threads, json.current_user_has_opened_threads);
  }
}
