import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUser, BusinessUserJson } from '@models/business-user';

export interface ThreadTagJson {
  id: number;
  name: string;
  archived_at: string;
  archived_by: BusinessUserJson;
}

export class ThreadTag {
  constructor(
    public id?: number,
    public name?: string,
    public archivedAt?: Date,
    public archivedBy?: BusinessUser
  ) {}

  static fromJson(json: Partial<ThreadTagJson>): ThreadTag {
    return new ThreadTag(
      json.id,
      json.name,
      json.archived_at ? DateHelper.fromUtc(json.archived_at) : null,
      json.archived_by ? BusinessUser.fromJson(json.archived_by) : null
    );
  }

  static toJson(model: ThreadTag): Partial<ThreadTagJson> {
    return {
      id: HttpHelper.toJsonProperty(model.id),
      name: HttpHelper.toJsonProperty(model.name)
    };
  }
}
