import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { Company } from '@models/company';

import { CreateCreationRequestDialogData } from './create-creation-request-dialog-data';
import { CreateCreationRequestDialogComponent } from './create-creation-request-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CreateCreationRequestDialogService extends createDialogServiceBase<
  Company,
  CreateCreationRequestDialogData
>(CreateCreationRequestDialogComponent) {}
