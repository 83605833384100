<tiime-input-container
  [label]="label"
  tiimeFormInputContainer
  contentId="civility"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select id="civility" [formControl]="control" data-cy="user-civility-select">
    <div tiimeSelectOptions data-cy="user-civility-select-tab">
      <mat-option [value]="null"></mat-option>
      <mat-option [value]="civility.code" *ngFor="let civility of selectorData; trackBy: trackByCode">
        {{ civility.label }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
