<div class="feature" [class.disabled]="disabled" [class.selected]="value" (click)="select($event)">
  <mat-checkbox
    class="tiime-checkbox"
    color="primary"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
  ></mat-checkbox>
  <div class="feature-content">
    <div class="feature-header">
      <ng-content select="[cardHeader]"></ng-content>
    </div>
    <div class="feature-description">
      <ng-content select="[cardDescription]"></ng-content>
    </div>
  </div>
</div>
