import { computed, effect, inject, Signal, signal, WritableSignal } from '@angular/core';

import { JsonId } from '@interfaces/json-id';
import { WINDOW } from '@modules/core/tokens/window.token';

export abstract class FavoriteServiceBase<T extends Partial<JsonId> = Partial<JsonId>> {
  readonly favoritesCount: Signal<number>;
  readonly hasFavorites: Signal<boolean>;
  readonly favorites: Signal<number[]>;

  protected readonly localStorage: Storage = inject(WINDOW).localStorage;
  protected readonly favoriteSignal: WritableSignal<number[]>;

  constructor(protected key: string) {
    if (!key) {
      throw new Error('Key is missing');
    }

    this.favoriteSignal = signal<number[]>(this.getValueFromLocalStorage());
    this.favoritesCount = computed(() => this.favoriteSignal().length);
    this.hasFavorites = computed(() => !!this.favoritesCount());
    this.favorites = this.favoriteSignal.asReadonly();

    // Sauvegarde automatique en localStorage
    effect(() => {
      this.localStorage.setItem(this.key, JSON.stringify(this.favoriteSignal()));
    });
  }

  addFavorite(value: T): void {
    if (!this.hasId(value) || this.isFavorite(value)) {
      return;
    }
    this.favoriteSignal.update(favorites => [...favorites, value.id]);
  }

  removeFavorite(value: T): void {
    if (!this.hasId(value)) {
      return;
    }
    this.favoriteSignal.update(favorites => favorites.filter(id => id !== value.id));
  }

  isFavorite(value: T): boolean {
    if (!this.hasId(value)) {
      return false;
    }
    return this.favorites().includes(value.id);
  }

  protected getValueFromLocalStorage(): number[] {
    try {
      return JSON.parse(this.localStorage.getItem(this.key) ?? '[]');
    } catch {
      return [];
    }
  }

  protected save(): void {
    this.localStorage.setItem(this.key, JSON.stringify(this.favorites()));
  }

  private hasId(value: T): boolean {
    return ![null, undefined].includes(value?.id);
  }
}
