import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { Journal, JournalJson } from '@models/journal';
import { Movement, MovementJson } from '@models/movement';
import { Piece, PieceJson } from '@models/piece';

export interface AccountingEntryJson {
  id: number;
  counterpart_account_number: string;
  journal: Partial<JournalJson>;
  date: string;
  piece: Partial<PieceJson>;
  vat_amount: string;
  has_attachment: boolean;
  vat_overridded: boolean;
  undefined_vat_encasement: boolean;
  movements?: Partial<MovementJson>[];
  locked: boolean;
  locked_at: string;
  updated_by_accountant: boolean;
  updated_by_accountant_at: string;
  exported_at: string;
  invalid: boolean;
  files: { id: number }[];
}

export class AccountingEntry {
  constructor(
    public id?: number,
    public counterpartAccountNumber?: string,
    public journal?: Journal,
    public date?: string,
    public piece?: Piece,
    public vatAmount?: string,
    public hasAttachment?: boolean,
    public vatOverridded?: boolean,
    public undefinedVatEncasement?: boolean,
    public movements?: Movement[],
    public locked?: boolean,
    public lockedAt?: Date,
    public updatedByAccountant?: boolean,
    public updatedByAccountantAt?: Date,
    public exportedAt?: Date,
    public invalid?: boolean,
    public files?: { id: number }[]
  ) {}

  static fromJson(json: Partial<AccountingEntryJson>): AccountingEntry {
    const result = new AccountingEntry(
      json.id,
      json.counterpart_account_number,
      json.journal ? Journal.fromJson(json.journal) : null,
      json.date,
      json.piece ? Piece.fromJson(json.piece) : null,
      json.vat_amount,
      json.has_attachment,
      json.vat_overridded,
      json.undefined_vat_encasement,
      json.movements ? json.movements.map(movementJson => Movement.fromJson(movementJson)) : null,
      json.locked,
      json.locked_at ? DateHelper.fromUtc(json.locked_at) : null,
      json.updated_by_accountant,
      json.updated_by_accountant_at ? DateHelper.fromUtc(json.updated_by_accountant_at) : null,
      json.exported_at ? DateHelper.fromUtc(json.exported_at) : null,
      json.invalid,
      json.files
    );

    if (result.movements?.length) {
      result.movements.forEach(movement => {
        if (!movement.accountingEntry) {
          movement.accountingEntry = result;
        }
      });
    }

    return result;
  }

  static toJson(accountingEntry: AccountingEntry): Partial<AccountingEntryJson> {
    return {
      id: accountingEntry.id,
      counterpart_account_number: accountingEntry.counterpartAccountNumber,
      journal: accountingEntry.journal?.id ? HttpHelper.toJsonId(accountingEntry.journal) : null,
      date: accountingEntry.date,
      vat_amount: accountingEntry.vatAmount
    };
  }
}
