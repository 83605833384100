export interface TotalPerVatTypeJson {
  total_excluding_taxes: number;
  vat_amount: number;
}

export class TotalPerVatType {
  constructor(public totalExcludingTaxes?: number, public vatAmount?: number) {}

  static fromJson(json: Partial<TotalPerVatTypeJson>): TotalPerVatType {
    return new TotalPerVatType(json.total_excluding_taxes, json.vat_amount);
  }
}
