import { Role } from '@enums/role.enum';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';

export interface BusinessUnitUserJson {
  id: number;
  business_unit: Partial<BusinessUnitJson>;
  role: Role;
  is_admin: boolean;
  company_access_count?: number;
}

export class BusinessUnitUser {
  constructor(
    public id?: number,
    public businessUnit?: BusinessUnit,
    public role?: Role,
    public isAdmin?: boolean,
    public companyAccessCount?: number
  ) {}

  static fromJson(json: BusinessUnitUserJson): BusinessUnitUser {
    return new BusinessUnitUser(
      json.id,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.role ? json.role : null,
      json.is_admin,
      json.company_access_count
    );
  }

  static toJson(userBusinessUnitRole: BusinessUnitUser): BusinessUnitUserJson {
    return {
      id: userBusinessUnitRole.id,
      business_unit: HttpHelper.toJsonId(userBusinessUnitRole.businessUnit),
      role: userBusinessUnitRole.role,
      is_admin: userBusinessUnitRole.isAdmin
    };
  }
}
