<tiime-dialog (cancel)="cancel()">
  <mat-dialog-content class="dialog-content" mat-dialog-content data-cy="confirm-dialog-content">
    <span class="title" data-cy="confirm-dialog-title">
      {{ data.confirmTitle }}
    </span>
    <span class="message" data-cy="confirm-dialog-message">
      {{ data.confirmMessage }}
    </span>
    <em class="sub-message" data-cy="config-dialog-sub-message" *ngIf="data.confirmSubMessage">
      {{ data.confirmSubMessage }}
    </em>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button tiime-button color="accent" strocked large (click)="cancelAction()" data-cy="confirm-dialog-cancel-btn">
      {{ data.cancelAction }}
    </button>
    <button
      tiime-button
      [color]="data.actionButtonColor ?? 'accent'"
      large
      raised
      (click)="confirm()"
      data-cy="confirm-dialog-confirm-btn"
    >
      {{ data.confirmAction }}
    </button>
  </mat-dialog-actions>
</tiime-dialog>
