import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_BASE, COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { PartnerConfig, PartnerConfigJson } from '@models/partner-config';

@Injectable({
  providedIn: 'root'
})
export class PartnerConfigApiService extends ApiCommonService<PartnerConfig> {
  readonly resourceUrl = `${API_ROUTE_BASE}/partners/{partnerId}/partner_config`;
  readonly companyResourceUrl = `${COMPANY_API_ROUTE_BASE}/partner_config`;

  fromJson(json: PartnerConfigJson): PartnerConfig {
    return PartnerConfig.fromJson(json);
  }

  toJson(model: PartnerConfig): Partial<PartnerConfigJson> {
    return PartnerConfig.toJson(model);
  }

  @ApiAlertError()
  getConfig(partnerId: number): Observable<PartnerConfig> {
    const url = HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId);
    return this.http.get<PartnerConfigJson>(url).pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError()
  getConfigForCurrentCompany(): Observable<PartnerConfig> {
    const url = this.companyResourceUrl;
    return this.http.get<PartnerConfigJson>(url).pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError()
  updateConfig(partnerId: number, config: PartnerConfig): Observable<PartnerConfig> {
    const url = `${HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId)}/${config.id}`;
    return this.http.patch<PartnerConfigJson>(url, this.toJson(config)).pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError()
  updatePartialConfig(
    partnerId: number,
    config: Partial<PartnerConfigJson> & { id: number }
  ): Observable<PartnerConfig> {
    const url = `${HttpHelper.replacePartnerUrl(this.resourceUrl, partnerId)}/${config.id}`;
    return this.http.patch<PartnerConfigJson>(url, config).pipe(map(json => this.fromJson(json)));
  }
}
