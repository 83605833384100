export interface CoverPageBackgroundImageJson {
  id: number;
  label: string;
  is_deletable: boolean;
}

export interface CoverPageBackgroundImageSerialized {
  imageName: string;
  image: File;
  id?: number;
}

export class CoverPageBackgroundImage {
  constructor(
    public id?: number,
    public label?: string,
    public isDeletable?: boolean
  ) {}

  static fromJson(json: Partial<CoverPageBackgroundImageJson>): CoverPageBackgroundImage {
    return new CoverPageBackgroundImage(json.id, json.label, json.is_deletable);
  }
}
