import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TiimeTooltipModule } from 'tiime-material';

import { ErrorMessage } from '@interfaces/error-message';

@Component({
  selector: 'app-list-error',
  standalone: true,
  imports: [TiimeTooltipModule, MatIconModule],
  templateUrl: './list-error.component.html',
  styleUrls: ['./list-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListErrorComponent {
  @Input() errorMessage: ErrorMessage;
}
