import { UserInvitationChannelType } from '@enums/user-invitation-channel-type.enum';
import { UserInvitationFeature } from '@enums/user-invitation-feature.enum';

export class UserInvitationConfiguration {
  constructor(
    public features?: UserInvitationFeature[],
    public channel?: UserInvitationChannelType,
    public withFormation?: boolean
  ) {}

  static toJson(invitationConfiguration: UserInvitationConfiguration): any {
    return {
      features: invitationConfiguration.features,
      channel: invitationConfiguration.channel,
      with_formation: invitationConfiguration.withFormation ?? false
    };
  }
}
