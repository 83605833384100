import { Pipe, PipeTransform } from '@angular/core';

import { UserCapacity } from '@enums/user-capacity.enum';

@Pipe({
  name: 'director',
  standalone: true
})
export class DirectorPipe implements PipeTransform {
  transform(capacity: UserCapacity): boolean {
    return [
      UserCapacity.Manager,
      UserCapacity.President,
      UserCapacity.GeneralDirector,
      UserCapacity.Independent
    ].includes(capacity);
  }
}
