import { DateHelper } from '@helpers/date.helper';

import { ShareCapitalTypeEnum } from '../../creation-core/enums/share-capital-type.enum';
import {
  CapitalDepositMoralBeneficiary,
  CapitalDepositMoralBeneficiaryJson
} from './capital-deposit-moral-beneficiary';
import {
  CapitalDepositPhysicalBeneficiary,
  CapitalDepositPhysicalBeneficiaryJson
} from './capital-deposit-physical-beneficiary';

export interface CapitalDepositJson {
  capital_deposit_status_update_by: string;
  capital_deposit_status: string;
  capital_deposit_status_update_date: string;
  beneficiaries: CapitalDepositPhysicalBeneficiaryJson[];
  moral_beneficiaries: CapitalDepositMoralBeneficiaryJson[];
  capital_deposit_type: ShareCapitalTypeEnum;
  message_disabled: string;
  capital_deposit_notary_mail_date: string;
}

export class CapitalDeposit {
  constructor(
    public beneficiaries: CapitalDepositPhysicalBeneficiary[],
    public moralBeneficiaries?: CapitalDepositMoralBeneficiary[],
    public capitalDepositStatusUpdateBy?: string,
    public capitalDepositStatus?: string,
    public capitalDepositStatusUpdateDate?: Date,
    public capitalDepositType?: ShareCapitalTypeEnum,
    public messageDisabled?: string,
    public capitalDepositNotaryMailDate?: Date
  ) {}

  static fromJson(capitalDepositJson: CapitalDepositJson): CapitalDeposit {
    return new CapitalDeposit(
      capitalDepositJson.beneficiaries?.map((capitalBeneficiaryJson: CapitalDepositPhysicalBeneficiaryJson) =>
        CapitalDepositPhysicalBeneficiary.fromJson(capitalBeneficiaryJson)
      ),
      capitalDepositJson.moral_beneficiaries?.map((moralBeneficiaryJson: CapitalDepositMoralBeneficiaryJson) =>
        CapitalDepositMoralBeneficiary.fromJson(moralBeneficiaryJson)
      ),
      capitalDepositJson.capital_deposit_status_update_by,
      capitalDepositJson.capital_deposit_status,
      capitalDepositJson.capital_deposit_status_update_date
        ? DateHelper.fromUtc(capitalDepositJson.capital_deposit_status_update_date)
        : null,
      capitalDepositJson.capital_deposit_type,
      capitalDepositJson.message_disabled,
      capitalDepositJson.capital_deposit_notary_mail_date
        ? DateHelper.fromUtc(capitalDepositJson.capital_deposit_notary_mail_date)
        : null
    );
  }

  static toJson(capitalDeposit: CapitalDeposit): CapitalDepositJson {
    return {
      beneficiaries: capitalDeposit.beneficiaries.map((capitalDepositBeneficiary: CapitalDepositPhysicalBeneficiary) =>
        CapitalDepositPhysicalBeneficiary.toJson(capitalDepositBeneficiary)
      ),
      moral_beneficiaries: capitalDeposit.moralBeneficiaries.map(
        (capitalDepositBeneficiary: CapitalDepositMoralBeneficiary) =>
          CapitalDepositMoralBeneficiary.toJson(capitalDepositBeneficiary)
      ),
      capital_deposit_status_update_by: capitalDeposit.capitalDepositStatusUpdateBy,
      capital_deposit_status: capitalDeposit.capitalDepositStatus,
      capital_deposit_status_update_date: capitalDeposit.capitalDepositStatusUpdateDate
        ? DateHelper.format(capitalDeposit.capitalDepositStatusUpdateDate)
        : undefined,
      capital_deposit_type: capitalDeposit.capitalDepositType,
      message_disabled: capitalDeposit.messageDisabled,
      capital_deposit_notary_mail_date: capitalDeposit.capitalDepositNotaryMailDate
        ? DateHelper.format(capitalDeposit.capitalDepositNotaryMailDate)
        : undefined
    };
  }

  static toPatchStatus(status: string): Pick<CapitalDepositJson, 'capital_deposit_status'> {
    return {
      capital_deposit_status: status
    };
  }
}
