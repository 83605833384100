import { AccountingPeriod, AccountingPeriodJson } from './accounting-period';

export interface ResultAllocationJson {
  id: number;
  amount: number;
  accounting_period: Partial<AccountingPeriodJson>;
  ago_date: string;
  creditor_anouveau_postponement_amount: number;
  debtor_anouveau_postponement_amount: number;
  earning_distribution_amount: number;
  legal_reserve_amount: number;
  optional_reserve_amount: number;
}

export class ResultAllocation {
  constructor(
    public id?: number,
    public amount?: number,
    public accountingPeriod?: AccountingPeriod,
    public agoDate?: string,
    public creditorAnouveauPostponementAmount?: number,
    public debtorAnouveauPostponementAmount?: number,
    public earningDistributionAmount?: number,
    public legalReserveAmount?: number,
    public optionalReserveAmount?: number
  ) {}

  static fromJson(json: Partial<ResultAllocationJson>): ResultAllocation {
    return new ResultAllocation(
      json.id,
      json.amount,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.ago_date,
      json.creditor_anouveau_postponement_amount,
      json.debtor_anouveau_postponement_amount,
      json.earning_distribution_amount,
      json.legal_reserve_amount,
      json.optional_reserve_amount
    );
  }

  static toJson(resultAllocation: ResultAllocation): ResultAllocationJson {
    return {
      id: resultAllocation.id,
      amount: resultAllocation.amount,
      accounting_period: AccountingPeriod.toJson(resultAllocation.accountingPeriod),
      ago_date: resultAllocation.agoDate,
      creditor_anouveau_postponement_amount: resultAllocation.creditorAnouveauPostponementAmount,
      debtor_anouveau_postponement_amount: resultAllocation.debtorAnouveauPostponementAmount,
      earning_distribution_amount: resultAllocation.earningDistributionAmount,
      legal_reserve_amount: resultAllocation.legalReserveAmount,
      optional_reserve_amount: resultAllocation.optionalReserveAmount
    };
  }
}
