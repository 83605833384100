import { Company, CompanyJson } from '@models/company';
import { UserBase } from '@models/user-base';

export interface SearchUserJson {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  company: Partial<CompanyJson>;
}

export class SearchUser extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    public company?: Company
  ) {
    super(id, firstName, lastName, email, phone);
  }

  static fromJson(json: Partial<SearchUserJson>): SearchUser {
    return new SearchUser(
      json.id,
      json.firstname,
      json.lastname,
      json.email,
      json.phone,
      json.company ? Company.fromJson(json.company) : null
    );
  }
}
