import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

export type WindowWithBeacon = Window &
  typeof globalThis & {
    Beacon: (...args) => unknown;
  };

export const WINDOW = new InjectionToken<WindowWithBeacon>('An abstraction over global window object', {
  factory: () => {
    const { defaultView } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window is not available');
    }
    return defaultView as WindowWithBeacon;
  }
});
