import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'tiime-subheader-content-placeholder',
  templateUrl: './subheader-content-placeholder.component.html',
  styleUrls: ['./subheader-content-placeholder.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubheaderContentPlaceholderComponent {}
