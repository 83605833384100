import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

import { fromEvent, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type Options = {
  element: any;
  keys: string;
};

@Injectable({ providedIn: 'root' })
export class HotKeysService {
  defaults: Partial<Options> = {
    element: this.document
  };

  constructor(@Inject(DOCUMENT) private document: Document, private eventManager: EventManager) {
    fromEvent(this.document, 'keydown')
      .pipe(
        tap((e: KeyboardEvent) => {
          if (e.metaKey && e.shiftKey) {
            e.preventDefault();
          }
        })
      )
      .subscribe();
  }

  addShortcut(options: Partial<Options>): Observable<unknown> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    return new Observable(observer => {
      const handler = (e): void => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
      };
    });
  }
}
