import { HttpHelper } from '@helpers/http.helper';

import { BankAccountBalance, BankAccountBalanceJson } from './bank-account-balance';
import { BankAccountType, BankAccountTypeJson } from './bank-account-type';
import { BankConnectionStatus, BankConnectionStatusJson } from './bank-connection-status';
import { ConnectionProvider, ConnectionProviderJson } from './connection-provider';
import { Journal } from './journal';

export interface BankAccountJson {
  id: number;
  bank_name: string;
  journal: any; // À remplacer par JournalJson quand ça sera fait
  card_identifier: string;
  is_manual: boolean;
  is_wallet: boolean;
  created_at: string;
  bank_account_type: Partial<BankAccountTypeJson>;
  iban: string;
  name: string;
  balance_amount: number;
  balance_currency: string;
  balance_date: Date;
  original_ebics_mandate_needed: boolean;
  accounting_enabled: boolean;
  bank_connection_status: Partial<BankConnectionStatusJson>;
  closed: boolean;
  validated_bank_account_balance: Partial<BankAccountBalanceJson>;
  ebics_connection_provider: Partial<ConnectionProviderJson>;
  budgea_enable_chronos_synchronization: boolean;
  is_default_bank_direct_debit: boolean;
}

export class BankAccount {
  constructor(
    public id?: number,
    public bankName?: string,
    public journal?: Journal,
    public cardIdentifier?: string,
    public isWallet?: boolean,
    public createdAt?: string,
    public bankAccountType?: BankAccountType,
    public iban?: string,
    public name?: string,
    public balanceAmount?: number,
    public balanceCurrency?: string,
    public balanceDate?: Date,
    public originalEbicsMandateNeeded?: boolean,
    public accountingEnabled?: boolean,
    public bankConnectionStatus?: BankConnectionStatus,
    public closed?: boolean,
    public validatedBalance?: BankAccountBalance,
    public isManual?: boolean,
    public connectionProvider?: ConnectionProvider,
    public budgeaEnableChronosSynchronization?: boolean,
    public isDefaultBankDirectDebit?: boolean
  ) {}

  public static fromJson(json: Partial<BankAccountJson>): BankAccount {
    return new BankAccount(
      json.id,
      json.bank_name,
      json.journal ? Journal.fromJson(json.journal) : null,
      json.card_identifier,
      json.is_wallet,
      json.created_at,
      json.bank_account_type ? BankAccountType.fromJson(json.bank_account_type) : null,
      json.iban,
      json.name,
      json.balance_amount,
      json.balance_currency,
      json.balance_date,
      json.original_ebics_mandate_needed,
      json.accounting_enabled,
      json.bank_connection_status ? BankConnectionStatus.fromJson(json.bank_connection_status) : null,
      json.closed,
      json.validated_bank_account_balance ? BankAccountBalance.fromJson(json.validated_bank_account_balance) : null,
      json.is_manual,
      json.ebics_connection_provider ? ConnectionProvider.fromJson(json.ebics_connection_provider) : null,
      json.budgea_enable_chronos_synchronization,
      json.is_default_bank_direct_debit
    );
  }

  public static toJson(
    bankAccount: BankAccount
  ): Pick<
    BankAccountJson,
    | 'id'
    | 'name'
    | 'journal'
    | 'bank_account_type'
    | 'iban'
    | 'accounting_enabled'
    | 'closed'
    | 'ebics_connection_provider'
    | 'is_manual'
    | 'budgea_enable_chronos_synchronization'
    | 'is_default_bank_direct_debit'
  > {
    return {
      id: bankAccount.id || undefined,
      name: bankAccount.name,
      journal: bankAccount.journal?.id ? HttpHelper.toJsonId(bankAccount.journal) : null,
      bank_account_type: bankAccount.bankAccountType?.id ? HttpHelper.toJsonId(bankAccount.bankAccountType) : null,
      iban: bankAccount.iban ? bankAccount.iban.replace(/\s/g, '').toUpperCase() : null,
      accounting_enabled: bankAccount.accountingEnabled,
      closed: bankAccount.closed,
      ebics_connection_provider: bankAccount.connectionProvider?.id
        ? HttpHelper.toJsonId(bankAccount.connectionProvider)
        : null,
      is_manual: bankAccount.isManual,
      budgea_enable_chronos_synchronization: bankAccount.budgeaEnableChronosSynchronization,
      is_default_bank_direct_debit: bankAccount.isDefaultBankDirectDebit
    };
  }
}
