<mat-autocomplete class="tiime-autocomplete-panel" autoActiveFirstOption>
  <ng-container *ngIf="!displayInterlocutors; else interlocutorsSelector">
    <mat-option
    class="favs-option"
    (onSelectionChange)="selectFavorites($event, favorites())"
    *ngIf="withFavorites && hasFavorites()"
  >
    <app-apply-all-favorite-option [favoriteCount]="favoriteCount()" />
  </mat-option>
  <mat-option
    *ngFor="let contributor of data; trackBy: trackById"
    (onSelectionChange)="emitSelect($event, contributor)"
    [value]="contributor"
  >
    <app-contributor-option
      *ngIf="contributor.id; else noContributorTemplate"
      [contributor]="contributor"
      [withFavorites]="withFavorites"
      [favorite]="withFavorites && (favorites() | includes: contributor.id)"
      (addToFavorites)="addToFavs(contributor)"
      (removeFromFavorites)="removeFromFavs(contributor)"
    />
    <ng-template #noContributorTemplate>{{ nullOptionLabel }}</ng-template>
  </mat-option>
  </ng-container>

  <ng-template #interlocutorsSelector>
    <div class="interlocutors-container">
      <mat-optgroup label="Intervenants du dossier">
        <mat-option
          *ngFor="let interlocutor of interlocutors; trackBy: trackById"
          [value]="interlocutor"
          (onSelectionChange)="emitSelect($event, interlocutor.user)"
        >
          <app-contributor-option [contributor]="interlocutor.user" />
        </mat-option>
      </mat-optgroup>
      <div *ngIf="!interlocutors.length" class="text-container no-results">
        <span>Aucun intervenant affecté à ce dossier</span>
      </div>
      <mat-optgroup label="Tous les intervenants">
        <mat-option
          *ngFor="let contributor of data; trackBy: trackById"
          [value]="contributor"
          (onSelectionChange)="emitSelect($event, contributor)"
        >
          <app-contributor-option [contributor]="contributor" />
        </mat-option>
      </mat-optgroup>
    </div>
  </ng-template>
</mat-autocomplete>
