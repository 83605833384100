import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import parsePhoneNumberFromString, { isValidPhoneNumber, PhoneNumber } from 'libphonenumber-js';

import { RegexUtils } from 'tiime-expert-utils';

export class PhoneHelper {
  /**
   * Parse un numéro de téléphone
   */
  static parse(value: string): PhoneNumber | null {
    const parsed: string = this.sanitize(value);
    if (!this.isValid(parsed)) {
      return null;
    }
    return parsePhoneNumberFromString(parsed, 'FR');
  }

  /**
   * Vérifie la validité d'un numéro de téléphone
   */
  static isValid(value: string): boolean {
    if (!value?.length) {
      return false;
    }

    const parsed = this.sanitize(value);

    return isValidPhoneNumber(parsed, 'FR');
  }

  static isFrenchMobileNumber(value: string): boolean {
    const parsed = this.parse(value);
    if (parsed === null) {
      return false;
    }
    return RegexUtils.mobilePhoneRegex.test(this.sanitize(parsed.formatInternational()));
  }

  /**
   * Supprime tous les caractères invalides qu'il est possible de rencontrer
   */
  static sanitize(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber.replace(/[^\d+]/g, '');
  }

  /**
   * Format le numéro de téléphone au format 01 23 45 67 89
   * Si le numéro n'est pas valide, la valeur sera simplement renvoyée
   */
  static format(value: string): string {
    if (!this.isValid(value)) {
      return value;
    }
    const phoneNumber = PhoneHelper.parse(value);
    if (phoneNumber.country === 'FR') {
      return phoneNumber.formatNational();
    }
    return phoneNumber.formatInternational();
  }

  /**
   * Génère un Validator pour les formulaire
   */
  static phoneNumberValidator(errorName: string = 'invalidPhoneNumber'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isValid(control.value) || !control.value?.length) {
        return null;
      }
      return { [errorName]: true };
    };
  }

  static mobilePhoneNumberValidator(errorName: string = 'invalidMobilePhoneNumber'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isFrenchMobileNumber(control.value) || !control.value?.length) {
        return null;
      }
      return { [errorName]: true };
    };
  }
}
