import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'momentFromNow',
  pure: false,
  standalone: true
})
export class MomentFromNowPipe implements PipeTransform {
  transform(value: moment.MomentInput, hideSuffix: boolean = false): string {
    return moment(value).fromNow(hideSuffix);
  }
}
