import { ChangeDetectionStrategy, Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';

import { ContainerSideContentDirective } from './container-side-content.directive';
import { ContainerState } from './container-state.enum';

@Component({
  selector: 'tiime-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-container',
    '[class.tiime-container-side-content-start-position]': "containerSideContentPosition === 'start'"
  }
})
export class ContainerComponent {
  @Input() containerState: ContainerState;
  @Input() containerSideContentPosition: 'start' | 'end' = 'end';
  @Input() containerHasFooter = false;

  @ContentChild(ContainerSideContentDirective) set setContainerSideContentDirective(
    containerSideContentDirective: ContainerSideContentDirective
  ) {
    if (containerSideContentDirective && this.containerSideContentPosition === 'start') {
      containerSideContentDirective.newElementWidthOperation = 'positive';
    }
  }

  readonly ContainerState = ContainerState;
}
