import { Injectable } from '@angular/core';

import { AppState, AuthService as Auth0Service, GenericError, RedirectLoginOptions } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppRoute } from '@modules/core/navigation/app-route.enum';
import { AppConfigService } from '@services/app-config.service';

import { AUTH0_LOCAL_STORAGE_IDENTIFIER, AUTH0_SCOPE } from './auth.constant';

export type AuthRedirectLoginOptions = RedirectLoginOptions<AppState>;
export const AUTH_LOGIN_REQUIRED_ERROR = 'login_required';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get isAuthenticated$(): Observable<boolean> {
    return this.auth0Service.isAuthenticated$;
  }

  get isLoading$(): Observable<boolean> {
    return this.auth0Service.isLoading$;
  }

  get errors$(): Observable<Error> {
    return this.auth0Service.error$;
  }

  get localStorageKey(): string {
    return `${AUTH0_LOCAL_STORAGE_IDENTIFIER}::${this.appConfigService.environment.AUTH0_CLIENT_ID}::${this.appConfigService.environment.AUTH0_AUDIENCE}::${AUTH0_SCOPE}`;
  }

  get accessToken(): string {
    const localStorageData = localStorage.getItem(this.localStorageKey);

    return JSON.parse(localStorageData)?.body?.access_token;
  }

  constructor(
    private readonly auth0Service: Auth0Service,
    private readonly appConfigService: AppConfigService
  ) {}

  login(options: AuthRedirectLoginOptions = undefined): Observable<void> {
    return this.auth0Service.loginWithRedirect(options);
  }

  loginRequired(): Observable<Error> {
    return this.errors$.pipe(filter(e => e instanceof GenericError && e.error === AUTH_LOGIN_REQUIRED_ERROR));
  }

  logout(): Observable<void> {
    return this.auth0Service.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/${AppRoute.signin}`
      }
    });
  }
}
