import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';

@Component({
  selector: 'app-vat-declaration-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TiimeInputContainerModule, TiimeSelectModule, MatOptionModule],
  templateUrl: './vat-declaration-select.component.html',
  styleUrls: ['./vat-declaration-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatDeclarationSelectComponent extends SelectBase<VatDeclarationConfiguration> {
  @Input() label = 'Déclaration de TVA';
}
