import { HttpHelper } from '@helpers/http.helper';
import { JsonId } from '@interfaces/json-id';
import { AdminCompany } from '@models/admin-company';
import { AdminCompanyLinkBase, AdminCompanyLinkBaseJson } from '@models/admin-company-link.base';
import { BusinessUnit } from '@models/business-unit';
import { BusinessUser } from '@models/business-user';
import { ContributorJob } from '@models/contributor-job';

interface ContributorLinkJson {
  partner_contributor_job: JsonId;
  contributor: JsonId | null;
}

interface ContributorUnlinkJson {
  company_contributor: JsonId;
}

export interface ContributorLink {
  partnerContributorJob: ContributorJob;
  contributor: BusinessUser;
}

export interface AdminCompanyContributorLinkJson extends AdminCompanyLinkBaseJson {
  links: ContributorLinkJson[];
  unlinks: ContributorUnlinkJson[];
}

export class AdminCompanyContributorLink extends AdminCompanyLinkBase {
  constructor(
    public selectedCompanies: AdminCompany[] = [],
    public businessUnit: BusinessUnit = null,
    public links: ContributorLink[] = []
  ) {
    super(selectedCompanies, businessUnit);
  }

  static override toJson(data: AdminCompanyContributorLink): AdminCompanyContributorLinkJson {
    const unlinks: ContributorUnlinkJson[] = (data.links ?? [])
      .filter(({ contributor }) => contributor?.id !== undefined)
      .reduce((acc, { partnerContributorJob, contributor }) => {
        const currentContributorForJob: ContributorUnlinkJson[] = data.selectedCompanies.flatMap(
          ({ contributors, id }) =>
            (contributors ?? [])
              .filter(c => c.job.id === partnerContributorJob.id && c.user.id !== contributor.id)
              .map(({ id }) => ({
                company_contributor: { id }
              }))
        );

        return [...acc, ...currentContributorForJob];
      }, [] as ContributorUnlinkJson[]);

    return {
      selected_companies: (data.selectedCompanies ?? []).map(c => HttpHelper.toJsonId(c)),
      business_unit: HttpHelper.toJsonId(data.businessUnit),
      links: (data.links ?? [])
        .filter(({ contributor }) => contributor?.id)
        .map((link: ContributorLink) => ({
          partner_contributor_job: HttpHelper.toJsonId(link.partnerContributorJob),
          contributor: HttpHelper.toJsonId(link.contributor)
        })),
      unlinks
    };
  }
}
