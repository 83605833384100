import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgUtils } from 'tiime-expert-utils';

import { HotKeysHelper } from '@modules/core/helpers/hotkeys';

@Component({
  selector: 'app-shortcut-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shortcut-container.component.html',
  styleUrls: ['./shortcut-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortcutContainerComponent {
  @Input() shortcuts: string[];

  readonly trackByIndex = NgUtils.trackByIndex;
  readonly cmd = HotKeysHelper.getCmd();
}
