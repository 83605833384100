import { Directive, Input } from '@angular/core';

import { TableCellBase } from './table-cell.base';

@Directive({
  selector: '[tiimeTableHeaderCell]',
  host: {
    class: 'cdk-header-cell',
    scope: 'col'
  }
})
export class TableHeaderCellDirective extends TableCellBase {
  @Input('tiimeTableHeaderCell')
  set name(name: string) {
    this.setColumnClass(name);
  }
}
