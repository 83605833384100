import { NotificationAction } from '@interfaces/notification-action';

export interface NotificationDataJson {
  actions: string | NotificationAction[];
  notification_uuid: string;
}

export class NotificationData {
  constructor(
    public actions?: NotificationAction[],
    public notificationUuid?: string
  ) {}

  static fromJson(json: NotificationDataJson): NotificationData {
    const actions =
      typeof json.actions === 'string' ? (JSON.parse(decodeURI(json.actions)) as NotificationAction[]) : json.actions;

    return new NotificationData(actions, json.notification_uuid);
  }
}
