export interface CountryJson {
  id: number;
  name: string;
  code: string;
  nationality: string;
}

export class Country {
  constructor(public id?: number, public name?: string, public code?: string, public nationality?: string) {}

  static fromJson(json: Partial<CountryJson>): Country {
    return new Country(json.id, json.name, json.code, json.nationality);
  }
}
