import { ImmobilisationExitType } from '@enums/immobilisation-status.enum';

export interface ImmobilisationExitJson {
  id?: number;
  date?: string;
  amount?: number;
  sale_amount?: number;
  type: ImmobilisationExitType;
}

export class ImmobilisationExit {
  constructor(
    public id?: number,
    public date?: string,
    public amount?: number,
    public saleAmount?: number,
    public type?: ImmobilisationExitType
  ) {}

  static fromJson(json: ImmobilisationExitJson): ImmobilisationExit {
    return new ImmobilisationExit(json.id, json.date, json.amount, json.sale_amount, json.type);
  }

  static toJson(data: ImmobilisationExit): ImmobilisationExitJson {
    return {
      id: data.id,
      date: data.date,
      amount: data.amount,
      sale_amount: data.saleAmount,
      type: data.type
    };
  }
}
