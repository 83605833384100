import { createSelector } from '@ngrx/store';

import { Role } from '@enums/role.enum';
import { BusinessUnitUser } from '@models/business-unit-user';
import { BusinessUserState } from '@modules/core/store/business-user/business-user-reducer';

export const businessUserSelector = (state: { businessUser?: BusinessUserState }): BusinessUserState =>
  state && state.businessUser ? state.businessUser : null;
export const businessUserBusinessUnitAdminSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) =>
    businessUser.businessUnitUsers.some((businessUnitUser: BusinessUnitUser) => businessUnitUser.isAdmin)
);
export const businessUserHasRoleEngineAdminSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.roles.includes(Role.ENGINE_ADMIN)
);
export const businessUserRolesSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.roles
);
export const businessUserPartnerSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.partner
);
export const businessUserPartnerAdminSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.isPartnerAdmin
);
export const businessUserCanAccessToAdministrationSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) =>
    businessUser.isPartnerAdmin ||
    businessUser.businessUnitUsers.some((businessUnitUser: BusinessUnitUser) => businessUnitUser.isAdmin)
);
export const businessUserBusinessUnitsSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.businessUnits
);
export const businessUserCanAccessToTimeTrackings = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.partner?.config?.timeTrackings ?? false
);

/**
 * Retourne la liste des business unit où l'utilisateur est admin
 */
export const adminBusinessUnitsSelector = createSelector(businessUserSelector, businessUser =>
  businessUser.businessUnitUsers.filter(({ isAdmin }) => isAdmin).map(({ businessUnit }) => businessUnit)
);
export const businessUserCanAccessToContracts = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.partner?.config?.contractModuleAllowed ?? false
);
export const businessUserPartnerConfigSelector = createSelector(
  businessUserSelector,
  (businessUser: BusinessUserState) => businessUser.partner?.config ?? null
);
