import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  TiimeCheckboxCardModule,
  TiimeButtonModule,
  TiimeFormModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeSnackbarService,
  TiimeTooltipModule
} from 'tiime-material';

import { BusinessUnitApiService } from '@api-services/business-unit-api.service';
import { TIIME_LABEL_PLAN_ID } from '@constants/tiime-label-plan.constant';
import { ParametersStepForm } from '@forms/create-company-form';
import { LabelCategoryPlan } from '@models/label-category-plan';
import { LabelsPlan } from '@models/labels-plan';
import { LabelCategoryPlanSetupDialogModule } from '@modules/shared/dialogs/label-category-plan-setup-dialog/label-category-plan-setup-dialog.module';
import { LabelCategoryPlanSetupDialogService } from '@modules/shared/dialogs/label-category-plan-setup-dialog/label-category-plan-setup-dialog.service';
import { LabelCategoryPlanSelectComponent } from '@modules/shared/selectors/label-category-plan-select/label-category-plan-select.component';
import { LabelPlanSelectComponent } from '@modules/shared/selectors/label-plan-select/label-plan-select.component';

@UntilDestroy({ checkProperties: true })
@Component({
  standalone: true,
  selector: 'app-company-initialization-form',
  templateUrl: './company-initialization-form.component.html',
  styleUrls: ['./company-initialization-form.component.scss'],
  imports: [
    CommonModule,
    TiimeIconModule,
    TiimeTooltipModule,
    TiimeButtonModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    LabelCategoryPlanSelectComponent,
    LabelPlanSelectComponent,
    LabelCategoryPlanSetupDialogModule,
    TiimeCheckboxCardModule,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyInitializationFormComponent implements OnInit {
  @Input() parametersStepForm: ParametersStepForm;
  @Input() labelCategoryPlans: LabelCategoryPlan[];

  @Input() set businessUnitId(id: number) {
    if (id) {
      this.initLabelsPlans(id);
    }
  }

  labelsPlans: LabelsPlan[] = [];

  private subscription = new Subscription();

  readonly acceptedTypes = [
    'text/plain',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/pdf'
  ];
  readonly acceptedTypesLabel = 'Format accepté : txt, csv, xls, xslx, ods, pdf';

  constructor(
    private businessUnitApiService: BusinessUnitApiService,
    private tiimeSnackbarService: TiimeSnackbarService,
    private labelCategoryPlanSetupDialogService: LabelCategoryPlanSetupDialogService
  ) {}

  ngOnInit(): void {
    this.observeForm();
  }

  uploadFEC(files: FileList): void {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (!this.acceptedTypes.includes(file.type)) {
      this.tiimeSnackbarService.openError(`Fichier invalide. ${this.acceptedTypesLabel}`);
      return;
    }

    this.parametersStepForm.fecFile.setValue(file);
  }

  resetFecFile(): void {
    this.parametersStepForm.fecFile.reset();
  }

  resetCategorySetupPlan(): void {
    this.parametersStepForm.categorySetupPlan.reset();
  }

  resetLabelPlan(): void {
    this.parametersStepForm.labelPlan.reset();
  }

  openLabelCategoryPlanSetupDialog(): void {
    this.labelCategoryPlanSetupDialogService
      .open({ data: this.parametersStepForm.controls.categorySetupPlan.value || null })
      .afterClosed()
      .subscribe();
  }

  private initLabelsPlans(businessUnitId: number): void {
    this.businessUnitApiService
      .getLabelsPlans(businessUnitId)
      .pipe(
        tap((labelsPlans: LabelsPlan[]) => {
          this.labelsPlans = labelsPlans?.length ? labelsPlans : [new LabelsPlan(TIIME_LABEL_PLAN_ID, 'Tiime')];
        })
      )
      .subscribe();
  }

  private observeForm(): void {
    this.subscription.add(
      this.parametersStepForm.controls.showFecFileImport.valueChanges
        .pipe(
          tap((showFecFileImport: boolean) => {
            if (!showFecFileImport) {
              this.resetFecFile();
            } else {
              this.parametersStepForm.controls.showLabelPlanSelect.patchValue(false);
              this.resetLabelPlan();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.parametersStepForm.controls.showCategorySetupPlanSelect.valueChanges
        .pipe(
          tap((showCategorySetupPlan: boolean) => {
            if (!showCategorySetupPlan) {
              this.resetCategorySetupPlan();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.parametersStepForm.controls.showLabelPlanSelect.valueChanges
        .pipe(
          tap((showLabelPlanSelect: boolean) => {
            if (!showLabelPlanSelect) {
              this.resetLabelPlan();
            } else {
              this.parametersStepForm.controls.showFecFileImport.patchValue(false);
              this.resetFecFile();
            }
          })
        )
        .subscribe()
    );
  }
}
