import { EmailAttachment, EmailAttachmentJson } from '@models/email-attachment';
import { EmailBase } from '@models/email-base';

export interface VatEmailJson {
  from: string;
  to: string[];
  cc: string[];
  cci: string[];
  subject: string;
  body: string;
  attachments: Partial<EmailAttachmentJson>[];
  from_name: string;
  reply_to: string;
}

export class VatEmail extends EmailBase {
  constructor(
    from?: string,
    to?: string[],
    cc?: string[],
    cci?: string[],
    subject?: string,
    body?: string,
    attachments?: EmailAttachment[],
    public fromName?: string,
    public replyTo?: string
  ) {
    super(from, to, cc, cci, subject, body, attachments);
  }

  static fromJson(json: Partial<VatEmailJson>): VatEmail {
    return new VatEmail(
      json.from,
      json.to ? json.to : [],
      json.cc ? json.cc : [],
      json.cci ? json.cci : [],
      json.subject,
      json.body,
      json.attachments ? json.attachments.map((attachmentJson: any) => EmailAttachment.fromJson(attachmentJson)) : [],
      json.from_name,
      json.reply_to
    );
  }

  static toJson(vatEmail: VatEmail): Omit<VatEmailJson, 'attachments'> {
    return {
      from: vatEmail.from,
      to: vatEmail.to,
      cc: vatEmail.cc,
      cci: vatEmail.cci,
      subject: vatEmail.subject,
      body: vatEmail.body,
      from_name: vatEmail.fromName,
      reply_to: vatEmail.replyTo
    };
  }
}
