import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { ContainerState } from 'tiime-material';

export interface ContainerStoreState {
  containerState: ContainerState;
  isLoading: boolean;
}

export const defaultState: ContainerStoreState = {
  containerState: ContainerState.contentPlaceholder,
  isLoading: false
};

export abstract class ContainerStoreBase<
  State extends ContainerStoreState = ContainerStoreState
> extends ComponentStore<State> {
  readonly initialState: State;
  readonly containerState$: Observable<ContainerState> = this.select(({ containerState }) => containerState);
  readonly isLoading$: Observable<boolean> = this.select(({ isLoading }) => isLoading);
  readonly disabledActions$: Observable<boolean> = this.select(
    ({ containerState }) => containerState !== ContainerState.done && containerState !== ContainerState.noResult
  );

  readonly setContainerState = this.updater((state, containerState: ContainerState) => ({
    ...state,
    containerState
  }));

  readonly setLoading = this.updater((state, isLoading: boolean) => ({
    ...state,
    isLoading
  }));

  readonly setOnbardState = this.updater(state => ({
    ...state,
    isLoading: false,
    containerState: ContainerState.onboard
  }));

  readonly reset = this.updater(_ => this.initialState);

  constructor(initialState: State = defaultState as unknown as State) {
    super(initialState);
    this.initialState = initialState;
  }
}
