import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { MarkFormAsTouchedIfInvalid } from 'tiime-expert-utils';
import { TiimeSnackbarService } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { AppStoreState } from '@interfaces/app-store-state';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import * as BusinessUserActions from '@modules/core/store/business-user/business-user-actions';

import { CreatePasswordForm } from './create-password-form';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['../auth-base.scss', './create-password.component.scss']
})
export class CreatePasswordComponent {
  createPasswordForm: CreatePasswordForm = new CreatePasswordForm();

  constructor(
    private readonly navigationService: NavigationService,
    private readonly businessUserApiService: BusinessUserApiService,
    private readonly snackbar: TiimeSnackbarService,
    private readonly store: Store<AppStoreState>
  ) {}

  @MarkFormAsTouchedIfInvalid('createPasswordForm')
  submitCreatePasswordForm(): void {
    this.businessUserApiService
      .updateBusinessUserPassword(this.createPasswordForm.toPassword())
      .pipe(
        tap(() => this.snackbar.openSuccess('Le mot de passe a bien été défini')),
        tap(() =>
          this.store.dispatch(
            BusinessUserActions.updatePartial({
              businessUser: {
                mustRenewPassword: false
              }
            })
          )
        ),
        switchMap(() => this.navigateToDefaultRoute())
      )
      .subscribe();
  }

  private navigateToDefaultRoute(): Observable<boolean> {
    return from(this.navigationService.navigateToDefaultRoute());
  }
}
