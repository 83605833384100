import { CustomOption } from '@interfaces/custom-option';
import { PcgEntry } from '@models/pcg-entry';

export const PIECE_PCG_ENTY_STARTS_WITH_REGEX = '^(2|6|7|47)';
export const PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING = new PcgEntry(null, 'Sans comptabilisation');
export const PIECE_PCG_ENTRY_INVOICE = new PcgEntry(null, 'Invoice');
export const BANK_PCG_ENTRY_SALES = new PcgEntry(null, 'Client engagement');
export const BANK_PCG_ENTRY_PURCHASES = new PcgEntry(null, 'Fournisseur engagement');
export const PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING_CUSTOM_OPTION: CustomOption = {
  name: PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING.accountNumber,
  value: PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING.accountNumber
};
export const PIECE_PCG_ENTRY_INVOICE_CUSTOM_OPTION: CustomOption = {
  name: PIECE_PCG_ENTRY_INVOICE.accountNumber,
  value: PIECE_PCG_ENTRY_INVOICE.accountNumber
};
export const BANK_PCG_ENTRY_SALES_CUSTOM_OPTION: CustomOption = {
  name: BANK_PCG_ENTRY_SALES.accountNumber,
  value: BANK_PCG_ENTRY_SALES.accountNumber
};
export const BANK_PCG_ENTRY_PURCHASES_CUSTOM_OPTION: CustomOption = {
  name: BANK_PCG_ENTRY_PURCHASES.accountNumber,
  value: BANK_PCG_ENTRY_PURCHASES.accountNumber
};
