import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { ProgressBarLoaderComponent } from './progress-bar-loader.component';
import { ProgressSpinnerLoaderComponent } from './progress-spinner-loader.component';
import { TableContentLoaderComponent } from './table-content-loader.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule],
  declarations: [
    ProgressBarLoaderComponent,
    ProgressSpinnerLoaderComponent,
    TableContentLoaderComponent
  ],
  exports: [
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ProgressBarLoaderComponent,
    ProgressSpinnerLoaderComponent,
    TableContentLoaderComponent
  ]
})
export class TiimeLoaderModule {}
