import { AnnualBookletExportType, AnnualBookletExportTypeJson } from '@models/annual-booklet-export-type';

export interface AnnualBookletJson {
  id?: number;
  content?: AnnualBookletExportTypeJson[];
}

export class AnnualBooklet {
  constructor(public id?: number, public content?: AnnualBookletExportType[]) {}

  static fromJson(json: AnnualBookletJson): AnnualBooklet {
    // order annual booklet export type by position
    json.content?.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });

    return new AnnualBooklet(
      json.id,
      json.content
        ? json.content.map(annualBookletExportTypeJson => AnnualBookletExportType.fromJson(annualBookletExportTypeJson))
        : null
    );
  }

  static toJson(annualBooklet: AnnualBooklet): Pick<AnnualBookletJson, 'content'> {
    return {
      content: annualBooklet.content
        ? annualBooklet.content.map(annualBookletExportType => AnnualBookletExportType.toJson(annualBookletExportType))
        : undefined
    };
  }
}
