<tiime-input-container
  tiimeFormInputContainer
  contentId="transaction-select"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessage?.message"
  [attr.small]="small"
>
  <tiime-select
    id="transaction-select"
    [customValue]="control.value | map: mapToTransactionCustomValue"
    [formControl]="control"
    [isLoading]="isLoading$ | async"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
    panelClass="transaction-select-panel"
  >
    <input
      tiimeSelectInput
      [placeholder]="searchPlaceholder"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <cdk-virtual-scroll-viewport
      appVirtualScrollViewportSize
      class="tiime-virtual-scroll-viewport transaction-virtual-scroll-viewport"
      [itemSize]="virtualScrollItemSize"
      tiimeSelectOptions
    >
      <mat-option
        [tiimeTooltip]="emptyValueTooltipContent"
        [tooltipDisabled]="!disableEmptyValue || !emptyValueTooltipContent"
        [disabled]="disableEmptyValue"
        *ngIf="allowEmptyOption"
        [value]="null"
      >
        {{ emptyOptionLabel }}
      </mat-option>
      <mat-option
        *cdkVirtualFor="let transaction of filteredSelectorData$ | async; trackBy: trackById"
        [value]="transaction"
      >
        <app-transaction-option [transaction]="transaction"></app-transaction-option>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </tiime-select>
</tiime-input-container>
