import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { NgUtils } from 'tiime-expert-utils';

@Component({
  selector: 'tiime-sticky-table-content-placeholder',
  templateUrl: './sticky-table-content-placeholder.component.html',
  styleUrls: ['./sticky-table-content-placeholder.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyTableContentPlaceholderComponent {
  @Input() contentPlaceholderRows = Array(25);

  @HostBinding('class') class = 'tiime-sticky-table-content-placeholder';

  readonly trackByIndex = NgUtils.trackByIndex;
}
