import { DateHelper } from '@helpers/date.helper';
import { NotificationAction } from '@interfaces/notification-action';
import { NotificationData, NotificationDataJson } from '@models/notification-data';
import { FirebaseMessagePayload } from '@third-parties/firebase/firebase.service';

export interface NotificationJson {
  id: number;
  read: boolean;
  title: string;
  subject: string;
  click_action: string;
  created_at: string;
  data: NotificationDataJson;
}

export class Notification {
  constructor(
    public id?: number,
    public read?: boolean,
    public title?: string,
    public subject?: string,
    public clickAction?: string,
    public createdAt?: Date,
    public data?: NotificationData
  ) {}

  static fromJson(json: NotificationJson): Notification {
    return new Notification(
      json.id,
      json.read,
      json.title,
      json.subject,
      json.click_action,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.data ? NotificationData.fromJson(json.data) : null
    );
  }

  static fromFirebaseNotificationMessage(firebaseNotificationMessage: FirebaseMessagePayload): Notification {
    const notification = new Notification();
    notification.id = firebaseNotificationMessage.data?.notification_id
      ? Number(firebaseNotificationMessage.data.notification_id)
      : null;
    notification.read = false;
    notification.title = firebaseNotificationMessage.notification?.title;
    notification.subject = firebaseNotificationMessage.notification?.body;
    notification.clickAction = firebaseNotificationMessage.fcmOptions?.link;
    const actions = firebaseNotificationMessage.data?.actions
      ? (JSON.parse(decodeURI(firebaseNotificationMessage.data.actions)) as NotificationAction[])
      : null;
    notification.data = new NotificationData(actions, firebaseNotificationMessage.data?.notification_uuid);

    return notification;
  }
}
