import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TiimeTwocolorsIconModule } from 'tiime-material';

import { UserInvitationChannelType } from '@enums/user-invitation-channel-type.enum';
import { noop } from '@helpers/noop.helper';
import { IsMobilePhoneNumberPipe } from '@shared-pipes/is-mobile-phone-number.pipe';

type OnChangeFn = (value?: UserInvitationChannelType) => void;
type OnTouchedFn = () => void;

@Component({
  selector: 'app-invitation-channel-select',
  standalone: true,
  imports: [CommonModule, IsMobilePhoneNumberPipe, TiimeTwocolorsIconModule],
  templateUrl: './invitation-channel-select.component.html',
  styleUrls: ['./invitation-channel-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InvitationChannelSelectComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationChannelSelectComponent implements ControlValueAccessor {
  @Input() userPhone: string | null;

  value: UserInvitationChannelType | null = null;
  disabled = false;

  onChange = noop;
  onTouched = noop;

  readonly UserInvitationChannelType = UserInvitationChannelType;

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: UserInvitationChannelType): void {
    this.value = obj;
  }

  selectChannel(channel: UserInvitationChannelType): void {
    this.value = channel;
    this.onChange(this.value);
    this.onTouched();
  }
}
