import { HttpHelper } from '@helpers/http.helper';
import { Balance, BalanceJson } from '@models/balance';
import { SubPcgEntry, SubPcgEntryJson } from '@models/sub-pcg-entry';

export interface PcgEntryJson {
  id?: number;
  account_number: string;
  label: string;
  centralizer: boolean;
  centralizer_pcg_entry: Partial<SubPcgEntryJson>;
  archived: boolean;
  auxiliary_balances?: Partial<BalanceJson>[];
}

export class PcgEntry {
  constructor(
    public id?: number,
    public accountNumber?: string,
    public label?: string,
    public centralizer?: boolean,
    public centralizerPcgEntry?: SubPcgEntry,
    public auxiliaryBalances?: Balance[],
    public archived?: boolean
  ) {}

  static fromJson(json: Partial<PcgEntryJson>): PcgEntry {
    return new PcgEntry(
      json.id,
      json.account_number,
      json.label,
      json.centralizer,
      json.centralizer_pcg_entry ? SubPcgEntry.fromJson(json.centralizer_pcg_entry) : null,
      json.auxiliary_balances ? json.auxiliary_balances.map(b => Balance.fromJson(b)) : undefined,
      json.archived
    );
  }

  static toJson(pcgEntry: PcgEntry): Partial<PcgEntryJson> {
    return {
      id: pcgEntry.id,
      account_number: pcgEntry.accountNumber || null,
      label: pcgEntry.label,
      centralizer: pcgEntry.centralizer,
      centralizer_pcg_entry:
        pcgEntry.centralizerPcgEntry && pcgEntry.centralizerPcgEntry.id
          ? HttpHelper.toJsonId(pcgEntry.centralizerPcgEntry)
          : null
    };
  }
}
