<form class="search-bar-wrapper" [ngClass]="searchBarClass" [formGroup]="searchbarForm">
  <div class="searchbar-icn">
    <mat-icon inline="true" fontIcon="icon-ic-search"></mat-icon>
  </div>
  <input
    #searchInput
    class="search-bar"
    (focus)="setFocused(true)"
    (blur)="setFocused(false)"
    placeholder="{{ placeholder }}"
    formControlName="search"
    data-cy="searchBar-input"
  />
  <div class="searchbar-icn clear" *ngIf="showClearButton" (click)="clearSearch()">
    <mat-icon inline="true" fontIcon="icon-ic-remove"></mat-icon>
  </div>
</form>
