<div class="suffix" *ngIf="displaySuffix">
  <ng-content select="[tiimeSuffix]"></ng-content>
</div>
<div class="prefix" *ngIf="displayPrefix">
  <ng-content select="[tiimePrefix]"></ng-content>
</div>
<ng-content></ng-content>
<label [attr.for]="contentId" *ngIf="label">{{ placeholder }}</label>
<span
  class="error-label"
  tiimeTooltip
  [tooltipContent]="errorTooltipTemplate"
  tooltipClass="tiime-tooltip warn"
  tooltipHideWithoutEllipsis
  *ngIf="errorMessage"
>
  {{ errorMessage }}
</span>
<ng-template #errorTooltipTemplate>
  <div class="tiime-tooltip-content">
    {{ errorMessage }}
  </div>
</ng-template>
