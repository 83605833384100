import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { CompanyRegistry } from '@models/company-registry';
import { FormFocusObserveDirective } from '@shared-directives/form-focus-observe/form-focus-observe.directive';
import { SortByKeyPipe } from '@shared-pipes/sort-by-key/sort-by-key.pipe';

@Component({
  selector: 'app-company-registry-select',
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyOptionModule,
    SortByKeyPipe,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    FormFocusObserveDirective
  ],
  templateUrl: './company-registry-select.component.html',
  styleUrls: ['./company-registry-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyRegistrySelectComponent extends FilteredSelectBase<CompanyRegistry> {
  @Input() label: string = 'Ville RCS';
  @Input() valueType: 'id' | 'city' = 'city';

  filterFunction(search: string): CompanyRegistry[] {
    return this.selectorData.filter((companyRegistry: CompanyRegistry) =>
      StringUtils.normalizeToLowerCase(companyRegistry.rcsCity).includes(StringUtils.normalizeToLowerCase(search))
    );
  }
}
