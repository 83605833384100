import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MaskPipe, TrackByHelper } from 'tiime-expert-utils';
import {
  FallbackTextDirective,
  TiimeButtonModule,
  TiimeIconModule,
  TiimeIconPropertyDirective,
  TiimeLargePropertyDirective,
  TiimePropertiesDirective,
  TiimePropertyDirective
} from 'tiime-material';

import { SIRET_MASK } from '@constants/mask.constant';
import { SearchUser } from '@models/search-user';
import { PhonePipe } from '@shared-pipes/phone/phone.pipe';

@Component({
  selector: 'app-duplicated-users-container',
  standalone: true,
  imports: [
    CommonModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimePropertiesDirective,
    TiimePropertyDirective,
    TiimeLargePropertyDirective,
    TiimeIconPropertyDirective,
    FallbackTextDirective,
    PhonePipe,
    MaskPipe
  ],
  templateUrl: './duplicated-users-container.component.html',
  styleUrls: ['./duplicated-users-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicatedUsersContainerComponent {
  @Input({ required: true }) duplicatedUsers: SearchUser[];
  @Input() displayAction = false;

  @Output() readonly actionClicked: EventEmitter<SearchUser> = new EventEmitter<SearchUser>();

  readonly trackByIndex = TrackByHelper.trackByIndex;
  readonly siretMask = SIRET_MASK;

  emitActionClicked(searchUser: SearchUser): void {
    if (!this.displayAction) {
      return;
    }

    this.actionClicked.emit(searchUser);
  }
}
