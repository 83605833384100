import { StepStatusEnum } from '../enums/step-status.enum';

export interface CreationRequestJson {
  id?: number;
  company_name?: string;
  user_last_name?: string;
  user_first_name?: string;
  user_id?: number;
  business_unit_id?: number;
  legal_form?: string;
  has_legal_person?: boolean;
  company_id?: number;
  note?: string;
  step_status?: StepStatusEnum;
  step_status_date_updated?: string;
  formality_id?: number;
  company_reference?: string;
  category_setup_plan_id?: number;
  labels_plan_id?: number;
}

export class CreationRequest {
  constructor(
    public id?: number,
    public companyName?: string,
    public userLastName?: string,
    public userFirstName?: string,
    public userId?: number,
    public businessUnitId?: number,
    public legalForm?: string,
    public hasLegalPerson?: boolean,
    public companyId?: number,
    public note?: string,
    public stepStatus?: StepStatusEnum,
    public stepStatusDateUpdated?: string,
    public formalityId?: number,
    public companyReference?: string,
    public categorySetupPlanId?: number,
    public labelsPlanId?: number
  ) {}

  static fromJson(json: Partial<CreationRequestJson>): CreationRequest {
    return new CreationRequest(
      json.id,
      json.company_name,
      json.user_last_name,
      json.user_first_name,
      json.user_id,
      json.business_unit_id,
      json.legal_form,
      json.has_legal_person,
      json.company_id,
      json.note,
      json.step_status,
      json.step_status_date_updated,
      json.formality_id,
      json.company_reference,
      json.category_setup_plan_id,
      json.labels_plan_id
    );
  }

  static toJson(createCreationRequest: CreationRequest): Partial<CreationRequestJson> {
    return {
      id: createCreationRequest.id,
      company_name: createCreationRequest.companyName,
      user_last_name: createCreationRequest.userLastName,
      user_first_name: createCreationRequest.userFirstName,
      user_id: createCreationRequest.userId,
      business_unit_id: createCreationRequest.businessUnitId,
      legal_form: createCreationRequest.legalForm,
      has_legal_person: createCreationRequest.hasLegalPerson,
      company_id: createCreationRequest.companyId,
      note: createCreationRequest.note,
      step_status: createCreationRequest.stepStatus,
      step_status_date_updated: createCreationRequest.stepStatusDateUpdated,
      formality_id: createCreationRequest.formalityId,
      company_reference: createCreationRequest.companyReference
    };
  }
}
