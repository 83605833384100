import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeEmptyStateComponent } from './empty-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TiimeEmptyStateComponent],
  exports: [TiimeEmptyStateComponent]
})
export class TiimeEmptyStateModule {}
