import { Pipe, PipeTransform } from '@angular/core';

import { StringUtils } from 'tiime-expert-utils';

// Extrait les clés qui ont pour valeur le type string ou number d'un objet
type StringKeys<T> = {
  [Prop in keyof T]: T[Prop] extends string | number ? Prop : never;
}[keyof T];

@Pipe({
  standalone: true,
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {
  transform<T extends Record<string, any>>(value: T[], key: StringKeys<T>): T[] {
    // On ré-crée un tableau ici pour éviter les soucis de mutation
    return [...(value ?? [])].sort((a, b) => {
      if (typeof a[key] === 'number' || typeof b[key] === 'number') {
        return a[key] - b[key];
      }
      return StringUtils.normalizeToLowerCase(a[key] ?? '').localeCompare(
        StringUtils.normalizeToLowerCase(b[key] ?? '')
      );
    });
  }
}
