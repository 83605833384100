import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';

import { AppConfigService } from '@services/app-config.service';
import { SentryService } from '@third-parties/sentry/sentry.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService, sentryService: SentryService): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => sentryService.init()),
      deps: [AppConfigService, SentryService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: Sentry.TraceService, deps: [Router] }
  ]
})
export class SentryModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SentryModule
  ) {
    if (parentModule) {
      throw new Error('SentryModule is already loaded. Import it in the CodeModule only');
    }
  }
}
