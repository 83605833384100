import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent {
  @Input() isLoading = false;
  @Input() loaderPosition: 'start' | 'end' = 'start';

  @Output() readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  emitCancel(): void {
    this.cancel.emit();
  }
}
