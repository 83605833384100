export enum FiscalDeclarationType {
  corporateTaxInstalment = '2571',
  corporateTaxBalance = '2572',
  corporateTaxRefund = '2573',
  cvaeInstalment = '1329AC',
  cvaeBalance = '1329DEF',
  decloyer = 'DECLOYER',
  cvae = '1330CVAE',
  vatRefund = '3519',
  vatDeclaration = 'vat_declaration',
  vatDeposit = '3514',
  taxReport = 'liasse',
  das2 = 'DAS2'
}
