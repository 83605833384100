import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[tiimeTableRow]',
  host: {
    class: 'cdk-row',
    '[class.alternate]': 'tiimeTableRowAlternate'
  }
})
export class TableRowDirective {
  @Input() tiimeTableRowAlternate: boolean;
}
