import { AfterViewChecked, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

/**
 * Affiche un texte lorsque le contenu de l'élément est vide
 */
@Directive({
  selector: '[tiimeFallbackText]',
  standalone: true
})
export class FallbackTextDirective implements OnChanges, AfterViewChecked {
  @Input() set fallbackTextClass(value: string) {
    this.emptyElementClass = value || this.defaults.className;
  }

  get fallbackTextClass(): string {
    return this.emptyElementClass;
  }

  @Input() set tiimeFallbackText(value: string) {
    this.fallbackText = value || this.defaults.text;
  }

  get tiimeFallbackText(): string {
    return this.fallbackText;
  }

  protected fallbackText: string;
  protected emptyElementClass: string;
  protected element: HTMLSpanElement | null = null;

  private readonly defaults: { className: string; text: string };

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.defaults = {
      className: 'tiime-fallback-text',
      text: 'n.c.'
    };
    this.emptyElementClass = this.defaults.className;
    this.fallbackText = this.defaults.text;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tiimeFallbackText?.currentValue) {
      if (this.element) {
        this.renderer.setProperty(this.element, 'innerText', this.fallbackText);
      }
    }
    if (changes.emptyElementClass?.currentValue) {
      if (this.element) {
        this.renderer.removeClass(this.element, changes.emptyElementClass.previousValue);
        this.renderer.addClass(this.element, this.emptyElementClass);
      }
    }
  }

  ngAfterViewChecked(): void {
    if (!this.el.nativeElement.innerText) {
      const element: HTMLSpanElement = this.renderer.createElement('span');
      this.renderer.addClass(element, this.emptyElementClass);
      this.renderer.setProperty(element, 'innerText', this.fallbackText);
      this.renderer.appendChild(this.el.nativeElement, element);
      this.element = element;
      return;
    }
    if (this.el.nativeElement.innerText === this.fallbackText) {
      return;
    }
    if (this.element) {
      this.element.remove();
      this.element = null;
    }
  }
}
