export enum Role {
  BUSINESS_ADMIN = 'ROLE_BUSINESS_ADMIN',
  BUSINESS_AGENT = 'ROLE_BUSINESS_AGENT',
  ACCOUNTANT = 'ROLE_ACCOUNTANT',
  DATA_MANAGER = 'ROLE_DATA_MANAGER',
  TECH = 'ROLE_TECH',
  CARE = 'ROLE_CARE',
  ENGINE_ADMIN = 'ROLE_ENGINE_ADMIN',
  PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  GUEST = 'ROLE_GUEST',
  SALES = 'ROLE_SALES'
}
