<tiime-form [tiimeFormExpanded]="tiimeFormExpanded">
  <div tiimeFormTitle>Informations Dirigeant</div>
  <div class="director-form" [formGroup]="directorForm">
    <app-social-regime-select
      [control]="directorForm.socialRegime"
      [selectData]="socialRegimes$ | async"
      *ngIf="displayedProperties | includes: 'socialRegime'"
    ></app-social-regime-select>
    <tiime-input-container
      contentId="startJobDate"
      label="Date de début de fonction"
      *ngIf="displayedProperties | includes: 'startJobDate'"
    >
      <input
        id="startJobDate"
        placeholder="Date de début de fonction"
        type="text"
        [matDatepicker]="startJobDatePicker"
        [formControl]="directorForm.startJobDate"
        autocomplete="off"
      />
      <mat-icon
        color="primary"
        class="calendar-icon"
        fontIcon="icon-calendar"
        tiimeSuffix
        (click)="startJobDatePicker.open()"
      ></mat-icon>
      <mat-datepicker
        panelClass="tiime-material-datepicker"
        [calendarHeaderComponent]="tiimeDatepickerHeader"
        #startJobDatePicker
      ></mat-datepicker>
    </tiime-input-container>
    <tiime-input-container
      contentId="endJobDate"
      label="Date de fin de fonction"
      [errorMessage]="directorForm.endJobDateErrorMessage?.message"
      *ngIf="displayedProperties | includes: 'endJobDate'"
    >
      <input
        id="endJobDate"
        placeholder="Date de fin de fonction"
        type="text"
        [formControl]="directorForm.endJobDate"
        [matDatepicker]="endJobDatePicker"
        [min]="directorForm.startJobDate.value"
        autocomplete="off"
      />
      <mat-icon
        color="primary"
        class="calendar-icon"
        fontIcon="icon-calendar"
        tiimeSuffix
        (click)="endJobDatePicker.open()"
      ></mat-icon>
      <mat-datepicker
        panelClass="tiime-material-datepicker"
        [calendarHeaderComponent]="tiimeDatepickerHeader"
        #endJobDatePicker
      ></mat-datepicker>
    </tiime-input-container>
    <tiime-slide-toggle-container
      class="acre-toggle-container"
      [attr.selected]="directorForm.acre.value"
      *ngIf="displayedProperties | includes: 'acre'"
    >
      <mat-slide-toggle
        color="primary"
        [formControl]="directorForm.acre"
        slideToggleContainerPrefix
        (click)="$event.stopPropagation()"
      ></mat-slide-toggle>
      <div slideToggleContainerContent>Peut/veut bénéficier de l'ACRE</div>
    </tiime-slide-toggle-container>
  </div>
</tiime-form>
