import { CdkStep, CdkStepper, StepperOptions, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  Inject,
  Optional,
  QueryList,
  ViewEncapsulation
} from '@angular/core';

import { Mapper, NgUtils } from 'tiime-expert-utils';

@Component({
  selector: 'tiime-step',
  templateUrl: './step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStep, useExisting: TiimeStepComponent }],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-step'
  }
})
export class TiimeStepComponent extends CdkStep {
  constructor(
    @Inject(forwardRef(() => TiimeStepperComponent)) stepper: TiimeStepperComponent,
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(stepper, stepperOptions);
  }
}

@Component({
  selector: 'tiime-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: TiimeStepperComponent }],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-stepper'
  }
})
export class TiimeStepperComponent extends CdkStepper {
  @ContentChildren(TiimeStepComponent, { descendants: true }) override _steps: QueryList<TiimeStepComponent>;

  override readonly steps: QueryList<TiimeStepComponent> = new QueryList<TiimeStepComponent>();

  readonly trackByIndex = NgUtils.trackByIndex;

  readonly mapToStepNumber: Mapper<number, number> = (stepIndex: number) => stepIndex + 1;

  selectStep(index: number, step: TiimeStepComponent): void {
    if (!this.stepIsNavigable(index, step)) {
      return;
    }

    step.select();
  }

  stepIsDone(index: number, step: TiimeStepComponent): boolean {
    switch (this._getIndicatorType(index, step.state)) {
      case 'edit':
      case 'done':
        return true;
      default:
        return false;
    }
  }

  stepIsNavigable(index: number, step: TiimeStepComponent): boolean {
    return step.completed || this.selectedIndex === index || !this.linear;
  }
}
