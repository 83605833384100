export interface CoverPageJson {
  id: number;
  is_personalized: boolean;
  background_image_id: number;
  display_logo: boolean;
  display_edition_name: boolean;
  display_legal_form: boolean;
  display_company_name: boolean;
  display_company_address: boolean;
  display_company_siret: boolean;
  display_accounting_period: boolean;
  information: string;
}

export class CoverPage {
  constructor(
    public id: number,
    public isPersonalized?: boolean,
    public backgroundImageId?: number,
    public displayLogo?: boolean,
    public displayEditionName?: boolean,
    public displayLegalForm?: boolean,
    public displayCompanyName?: boolean,
    public displayCompanyAddress?: boolean,
    public displayCompanySiret?: boolean,
    public displayAccountingPeriod?: boolean,
    public information?: string
  ) {}

  static fromJson(json: Partial<CoverPageJson>): CoverPage {
    return new CoverPage(
      json.id,
      json.is_personalized,
      json.background_image_id,
      json.display_logo,
      json.display_edition_name,
      json.display_legal_form,
      json.display_company_name,
      json.display_company_address,
      json.display_company_siret,
      json.display_accounting_period,
      json.information
    );
  }

  static toJson(covePage: CoverPage): Omit<CoverPageJson, 'id'> {
    return {
      is_personalized: covePage.isPersonalized,
      background_image_id: covePage.backgroundImageId,
      display_logo: covePage.displayLogo,
      display_edition_name: covePage.displayEditionName,
      display_legal_form: covePage.displayLegalForm,
      display_company_name: covePage.displayCompanyName,
      display_company_address: covePage.displayCompanyAddress,
      display_company_siret: covePage.displayCompanySiret,
      display_accounting_period: covePage.displayAccountingPeriod,
      information: covePage.information
    };
  }
}
