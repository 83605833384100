<ng-container [ngSwitch]="mode$ | async">
  <tiime-container class="list-container" [containerState]="containerState$ | async" *ngSwitchCase="'List'">
    <div class="company-threads-header" tiimeContainerHeader>
      <div class="company-name" tiimeContainerHeaderLeft>
        Fils de discussion dossier : {{ (company$ | async)?.name }}
      </div>
      <div tiimeContainerHeaderRight>
        <tiime-table-loader [isLoading]="isLoading$ | async"></tiime-table-loader>
        <button tiime-button icon color="primary" (click)="emitCloseCompanyThreads()">
          <mat-icon fontIcon="icon-ic-remove"></mat-icon>
        </button>
      </div>
    </div>
    <div tiimeContainerHeader class="company-threads-export-header">
      <mat-button-toggle-group [formControl]="threadFilters.controls.category">
        <mat-button-toggle [value]="ThreadCategory.accountant">Comptable</mat-button-toggle>
        <mat-button-toggle [value]="ThreadCategory.customer">Client</mat-button-toggle>
      </mat-button-toggle-group>
      <app-export-period
        label=""
        [startDate]="threadFilters.controls.startDate"
        [endDate]="threadFilters.controls.endDate"
      ></app-export-period>
    </div>
    <div tiimeContainerHeader>
      <div tiimeContainerHeaderLeft>
        <tiime-search-bar
          dark
          placeholder="Rechercher..."
          [debounceTime]="0"
          [disabled]="disabledActions$ | async"
          (search)="applyFilter($event)"
        ></tiime-search-bar>
        <app-company-threads-filters-button
          [form]="threadFilters"
          [company]="company$ | async"
          [accountingPeriods]="accountingPeriods$ | async"
          [vatDeclarations]="vatDeclarations$ | async"
          [corporateTaxInstalments]="corporateTaxInstalments$ | async"
          [contributors]="contributors$ | async"
          [tags]="tags$ | async"
        ></app-company-threads-filters-button>
      </div>
      <div tiimeContainerHeaderRight>
        <tiime-paginator
          [range]="range$ | async"
          [disabled]="(containerState$ | async) !== ContainerState.done"
          (rangeEvent)="applyRange($event)"
        ></tiime-paginator>
        <app-refresh-button [disabled]="isLoading$ | async" (refresh)="refreshList()"></app-refresh-button>
      </div>
    </div>
    <div tiimeContainerContent>
      <app-thread
        [thread]="thread"
        [contributors]="contributors$ | async"
        [tags]="tags$ | async"
        [currentBusinessUser]="currentBusinessUser$ | async"
        [companyContributors]="companyContributors$ | async"
        (createMessage)="createMessage(thread, $event)"
        (closeThread)="closeThread(thread, $event)"
        (archiveThread)="archiveThread(thread, $event)"
        (updateInterlocutors)="updateInterlocutors(thread, $event)"
        (updateTags)="updateTags(thread, $event)"
        (updateMessageContent)="updateMessageContent(thread, $event)"
        (messagesClicked)="detailsThreadMode(thread)"
        *ngFor="let thread of data$ | async; trackBy: trackById"
      ></app-thread>
    </div>
    <tiime-sticky-table-content-placeholder tiimeContainerContentPlaceholder></tiime-sticky-table-content-placeholder>
    <tiime-sticky-table-no-results tiimeContainerNoResult></tiime-sticky-table-no-results>
    <tiime-empty-state tiimeContainerOnboard>
      <img illu src="assets/illu_404_grey.svg" alt="empty threads" />
      <span label>Aucun fil de discussion</span>
      <button tiime-button color="primary" large raised action (click)="createThreadMode()">
        Ajouter un fil de discussion
      </button>
    </tiime-empty-state>
    <div tiimeContainerFooter>
      <button tiime-button color="primary" raised (click)="createThreadMode()">Ajouter un fil de discussion</button>
    </div>
  </tiime-container>
  <tiime-container *ngSwitchCase="'Creation'">
    <div class="company-threads-header" tiimeContainerHeader>
      <div tiimeContainerHeaderLeft>
        <button tiime-button icon color="primary" (click)="listThreadMode()">
          <mat-icon fontIcon="icon-ic-back"></mat-icon>
        </button>
        Ajouter un fil de discussion
      </div>
      <tiime-table-loader [isLoading]="isLoading$ | async"></tiime-table-loader>
    </div>
    <div class="create-thread-content" tiimeContainerContent>
      <app-thread-form
        [threadForm]="threadForm"
        [accountingPeriods]="accountingPeriods$ | async"
        [vatDeclarations]="vatDeclarations$ | async"
        [taskPlannings]="taskPlannings$ | async"
        [corporateTaxInstalments]="corporateTaxInstalments$ | async"
        [contributors]="contributors$ | async"
        [companyContributors]="companyContributors$ | async"
        [company]="company$ | async"
        [tags]="tags$ | async"
      ></app-thread-form>
      <button class="create-thread-button" tiime-button color="primary" raised (click)="createThread()">
        Ajouter le fil de discussion
      </button>
    </div>
  </tiime-container>
  <tiime-container class="detail-container" *ngSwitchCase="'Detail'">
    <div class="company-threads-header" tiimeContainerHeader>
      <div tiimeContainerHeaderLeft>
        <button tiime-button icon color="primary" (click)="listThreadMode()">
          <mat-icon fontIcon="icon-ic-back"></mat-icon>
        </button>
        Détail fil de discussion
      </div>
      <tiime-table-loader [isLoading]="isLoading$ | async"></tiime-table-loader>
    </div>
    <div tiimeContainerContent>
      <app-thread
        [thread]="selectedThread"
        [contributors]="contributors$ | async"
        [tags]="tags$ | async"
        [expanded]="true"
        [currentBusinessUser]="currentBusinessUser$ | async"
        (createMessage)="createMessage(selectedThread, $event)"
        (closeThread)="closeThread(selectedThread, $event)"
        (archiveThread)="archiveThread(selectedThread, $event)"
        (updateInterlocutors)="updateInterlocutors(selectedThread, $event)"
        (updateTags)="updateTags(selectedThread, $event)"
        (updateMessageContent)="updateMessageContent(selectedThread, $event)"
        *ngIf="selectedThread$ | async as selectedThread"
      ></app-thread>
    </div>
  </tiime-container>
</ng-container>
