<div class="auth-container">
  <div class="auth-left-container"><ng-content></ng-content></div>
  <div class="auth-right-container">
    <div class="auth-right-container-content">
      <img class="content-img" src="assets/illu_parrainage.png" alt="illu parrainage" />
      <span class="content-title">Parrainez, c'est gagné !</span>
      <span class="content-description">
        Recevez un bon cadeau d'une valeur de 200€ en parrainant
        <br />
        un collaborateur ou un expert-comptable d'un autre cabinet.
      </span>
      <div class="content-action">
        <a class="referral-action" target="_blank" rel="noopener" href="https://form.typeform.com/to/bys72mrI">
          Je parraine
        </a>
      </div>
    </div>
  </div>
</div>
