import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { DialogBase } from 'tiime-material';

import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { CommonDataApiService } from '@api-services/common-data-api.service';
import { LabelCategoryPlanSetupForm } from '@forms/label-category-plan-setup-form';
import { remainSubscribedOnError } from '@helpers/rxjs.helper';
import { VatHelper } from '@helpers/vat.helper';
import { LabelCategoryPlan } from '@models/label-category-plan';
import { LabelCategoryPlanSetup } from '@models/label-category-plan-setup';
import { VatExonerationType } from '@models/vat-exoneration-type';
import { VatType } from '@models/vat-type';
import { LabelCategoryPlanSetupTableComponent } from '@modules/shared/tables/label-category-plan-setup-table/label-category-plan-setup-table.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-label-category-plan-setup-dialog',
  templateUrl: './label-category-plan-setup-dialog.component.html',
  styleUrls: ['./label-category-plan-setup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelCategoryPlanSetupDialogComponent
  extends DialogBase<LabelCategoryPlanSetupDialogComponent, void>
  implements OnInit
{
  @ViewChild(LabelCategoryPlanSetupTableComponent) tableComponent: LabelCategoryPlanSetupTableComponent;

  labelCategoryPlans: LabelCategoryPlan[];
  labelCategoryPlanSetupForms: LabelCategoryPlanSetupForm[];

  readonly categoryIdFilter = new FormControl<number | null>(null);
  readonly displayedColumns = ['category', 'bankPcgEntry', 'piecePcgEntry', 'vatType'];
  readonly vatTypes$: Observable<VatType[]> = this.commonDataApiService.getVatTypes();
  readonly vatTypeRequiringVatExonerationType$: Observable<VatType> =
    this.commonDataApiService.getLabelVatTypeRequiringVatExonerationType();
  readonly vatExonerationTypes$: Observable<VatExonerationType[]> = this.commonDataApiService.getVatExonerationTypes();

  private readonly subscription = new Subscription();

  constructor(
    protected dialogRef: MatDialogRef<LabelCategoryPlanSetupDialogComponent>,
    private businessUserApiService: BusinessUserApiService,
    private commonDataApiService: CommonDataApiService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private categoryId: number
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.initLabelCategoryPlans();
  }

  private initLabelCategoryPlans(): void {
    this.isLoading = true;
    this.businessUserApiService
      .getBusinessUserLabelCategoryPlans()
      .pipe(
        tap(() => this.observeCategoryIdFilter()),
        tap(labelCategoryPlans => (this.labelCategoryPlans = labelCategoryPlans)),
        tap(labelCategoryPlans => this.categoryIdFilter.setValue(this.categoryId || labelCategoryPlans[0]?.id)),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private observeCategoryIdFilter(): void {
    this.subscription.add(
      this.categoryIdFilter.valueChanges
        .pipe(
          tap(() => (this.isLoading = true)),
          switchMap(categoryId =>
            combineLatest([
              this.businessUserApiService.getBusinessUserLabelCategoryPlanSetup(categoryId),
              this.vatTypes$
            ])
          ),
          tap(([labelCategoryPlanSetups, vatTypes]: [LabelCategoryPlanSetup[], VatType[]]) => {
            this.labelCategoryPlanSetupForms = labelCategoryPlanSetups.map(
              (labelCategoryPlanSetup: LabelCategoryPlanSetup) => {
                const form = new LabelCategoryPlanSetupForm();
                form.fromLabelCategoryPlanSetup(labelCategoryPlanSetup);
                form.autocomplete(VatHelper.franceExonerationVatTypeId(vatTypes), true);
                form.disable();
                return form;
              }
            );
          }),
          tap(() => {
            this.tableComponent?.tableAnchorComponent?.scrollTo();
            this.isLoading = false;
            this.cdr.markForCheck();
          }),
          catchError(err => {
            this.isLoading = false;
            this.cdr.markForCheck();
            return throwError(err);
          }),
          remainSubscribedOnError()
        )
        .subscribe()
    );
  }
}
