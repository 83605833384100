import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from 'tiime-expert-utils';

import { PaginatorComponent } from './paginator.component';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimePipesModule, TiimeButtonModule],
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent]
})
export class TiimePaginatorModule {}
