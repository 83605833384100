import { HttpHelper } from '@helpers/http.helper';
import { SubPcgEntry, SubPcgEntryJson } from '@models/sub-pcg-entry';

export interface JournalJson {
  id: number;
  code: string;
  type: string;
  label: string;
  pcg_entry: Partial<SubPcgEntryJson>;
}

export class Journal {
  constructor(
    public id?: number,
    public code?: string,
    public type?: string,
    public label?: string,
    public pcgEntry?: SubPcgEntry
  ) {}

  static fromJson(json: Partial<JournalJson>): Journal {
    return new Journal(
      json.id,
      json.code,
      json.type,
      json.label,
      json.pcg_entry ? SubPcgEntry.fromJson(json.pcg_entry) : null
    );
  }

  static toJson(journal: Journal): Omit<JournalJson, 'pcg_entry'> & { pcg_entry: { id: number } } {
    return {
      id: journal.id,
      code: journal.code?.trim(),
      type: journal.type,
      label: journal.label,
      pcg_entry: journal.pcgEntry ? HttpHelper.toJsonId(journal.pcgEntry) : null
    };
  }
}
