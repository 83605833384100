export interface LabelCategoryPlanJson {
  id?: number;
  label?: string;
}

export class LabelCategoryPlan {
  constructor(public id?: number, public label?: string) {}

  static fromJson(json: LabelCategoryPlanJson): LabelCategoryPlan {
    return new LabelCategoryPlan(json.id, json.label);
  }

  static toJson(labelCategoryPlan: LabelCategoryPlan): LabelCategoryPlanJson {
    return {
      id: labelCategoryPlan.id || undefined,
      label: labelCategoryPlan.label || undefined
    };
  }
}
