import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

import { TiimeButtonModule, TiimeIconModule } from 'tiime-material';

import { AutocompleteWithFavoritesBase } from '@bases/autocomplete/autocomplete-with-favorites.base';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { BusinessUserSlugComponent } from '@modules/shared/components/business-user-slug/business-user-slug.component';
import { ApplyAllFavoriteOptionComponent } from '@modules/shared/options/apply-all-favorite-option/apply-all-favorite-option.component';
import { ContributorOptionComponent } from '@modules/shared/options/contributor-option/contributor-option.component';
import { FavoriteContributorsService } from '@services/favorite-contributors.service';
import { IncludesPipe } from '@shared-pipes/includes/includes.pipe';

@Component({
  standalone: true,
  selector: 'app-contributor-autocomplete',
  templateUrl: './contributor-autocomplete.component.html',
  styleUrls: ['./contributor-autocomplete.component.scss'],
  exportAs: 'ContributorAutocomplete',
  imports: [
    BusinessUserSlugComponent,
    MatAutocompleteModule,
    CommonModule,
    IncludesPipe,
    TiimeButtonModule,
    TiimeIconModule,
    ContributorOptionComponent,
    ApplyAllFavoriteOptionComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributorAutocompleteComponent extends AutocompleteWithFavoritesBase<Contributor> {
  @Input() interlocutors: CompanyContributor[] = [];
  @Input() displayInterlocutors = false;

  constructor(favoriteContributorsService: FavoriteContributorsService) {
    super(favoriteContributorsService);
  }
}
