import { CivilityCode } from '@enums/civility-code.enum';

export interface CivilityJson {
  code: CivilityCode;
  label: string;
}

export class Civility {
  constructor(public code?: CivilityCode, public label?: string) {}

  static fromJson(json: CivilityJson): Civility {
    return new Civility(json.code, json.label);
  }
}
