import { trigger, transition, style, animate } from '@angular/animations';

export const openCloseCompanyThreads = trigger('openCloseThreads', [
  transition(':enter', [
    style({
      width: 0,
      opacity: 0
    }),
    animate(
      '200ms ease-in',
      style({
        width: '*',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({
      width: '*',
      opacity: 1
    }),
    animate(
      '200ms ease-out',
      style({
        width: 0,
        opacity: 0
      })
    )
  ])
]);
