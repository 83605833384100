import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FormUtils, Mapper, TiimePipesModule } from 'tiime-expert-utils';
import { TiimeSecondaryFiltersModule } from 'tiime-material';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadStatus } from '@enums/thread-status.enum';
import { ThreadFiltersForm } from '@forms/thread-filters-form';
import { FormHelper } from '@helpers/form.helper';
import { ThreadFilters } from '@interfaces/thread-filters';
import { AccountingPeriod } from '@models/accounting-period';
import { Company } from '@models/company';
import { Contributor } from '@models/contributor';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { ThreadTag } from '@models/thread-tag';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';
import { ThreadEntityTypesByCategoryPipe } from '@shared-pipes/thread-entity-types-by-category/thread-entity-types-by-category.pipe';
import { AccountingPeriodSelectComponent } from '@shared-selectors/accounting-period-select/accounting-period-select.component';
import { ContributorSelectMultipleComponent } from '@shared-selectors/contributor-select-multiple/contributor-select-multiple.component';
import { CorporateTaxInstalmentSelectComponent } from '@shared-selectors/corporate-tax-instalment-select/corporate-tax-instalment-select.component';
import { ThreadEntityTypeSelectComponent } from '@shared-selectors/thread-entity-type-select/thread-entity-type-select.component';
import { ThreadTagSelectMultipleComponent } from '@shared-selectors/thread-tag-select-multiple/thread-tag-select-multiple.component';
import { TransactionSelectComponent } from '@shared-selectors/transaction-select/transaction-select.component';
import { VatDeclarationSelectComponent } from '@shared-selectors/vat-declaration-select/vat-declaration-select.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-company-threads-filters-button',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeSecondaryFiltersModule,
    MatButtonToggleModule,
    TiimePipesModule,
    ContributorSelectMultipleComponent,
    AccountingPeriodSelectComponent,
    ThreadEntityTypeSelectComponent,
    VatDeclarationSelectComponent,
    ThreadEntityTypesByCategoryPipe,
    TransactionSelectComponent,
    ThreadTagSelectMultipleComponent,
    CorporateTaxInstalmentSelectComponent
  ],
  templateUrl: './company-threads-filters-button.component.html',
  styleUrls: ['./company-threads-filters-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyThreadsFiltersButtonComponent implements OnInit {
  @Input({ required: true }) form: ThreadFiltersForm;
  @Input({ required: true }) company: Company;
  @Input({ required: true }) accountingPeriods: AccountingPeriod[];
  @Input({ required: true }) vatDeclarations: VatDeclarationConfiguration[];
  @Input({ required: true }) corporateTaxInstalments: CorporateTaxInstalment[];
  @Input({ required: true }) contributors: Contributor[];
  @Input({ required: true }) tags: ThreadTag[];

  readonly ThreadStatus = ThreadStatus;
  readonly ThreadEntityType = ThreadEntityType;
  readonly ThreadCategory = ThreadCategory;

  private subscription = new Subscription();

  readonly mapToHideBadge: Mapper<ThreadFilters, boolean> = (filters: ThreadFilters) =>
    this.mapToBadgeValue(filters) === 0;

  readonly mapToBadgeValue: Mapper<ThreadFilters, number> = (filters: ThreadFilters) => {
    if (!filters) {
      return 0;
    }

    let total = 0;

    if (filters.status !== ThreadStatus.all) {
      ++total;
    }

    if (filters.type) {
      ++total;
    }

    if (filters.contributors?.length) {
      ++total;
    }

    if (filters.tags?.length) {
      ++total;
    }

    return total;
  };

  ngOnInit(): void {
    this.observeCategoryValueChanges();
    this.observeTypeValueChanges();
  }

  private observeCategoryValueChanges(): void {
    this.subscription.add(
      this.form.controls.category.valueChanges
        .pipe(
          tap(() =>
            this.form.patchValue(
              {
                type: null,
                entity: null,
                contributors: [],
                tags: []
              },
              FormUtils.shouldNotEmitEvent
            )
          )
        )
        .subscribe()
    );
  }

  private observeTypeValueChanges(): void {
    this.subscription.add(
      FormHelper.getValueChange(this.form.controls.type)
        .pipe(tap(() => this.form.controls.entity.setValue(null, FormUtils.shouldNotEmitEvent)))
        .subscribe()
    );
  }
}
