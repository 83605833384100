import type { AbstractControl, ValidatorFn } from '@angular/forms';

import { IbanHelper } from '../iban/iban.helper';
import { MathHelper } from '../math/math.helper';
import { RegexHelper } from '../regex/regex.helper';

export class ValidatorsHelper {
  public static ibanValidator(): ValidatorFn {
    return (control: AbstractControl) =>
      IbanHelper.isValid(control.value) === false
        ? {
            validateIban: {
              valid: false
            }
          }
        : null;
  }

  public static siretValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (RegexHelper.siretRegex.test(control.value)) {
        const value = control.value.replace(/\s+/g, '');
        let somme = 0;
        let tmp;
        for (let cpt = 0; cpt < value.length; cpt++) {
          if (cpt % 2 === 0) {
            tmp = value.charAt(cpt) * 2;
            if (tmp > 9) {
              tmp -= 9;
            }
          } else {
            tmp = value.charAt(cpt);
          }
          somme += parseInt(tmp, 10);
        }

        return MathHelper.modulo(somme.toString(), 10) === 0
          ? null
          : {
              validateSiret: {
                valid: false
              }
            };
      }
      return null;
    };
  }

  public static passwordValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const matches = RegexHelper.passwordRegex.test(control.value);
      return matches
        ? null
        : {
            validatePassword: {
              valid: false
            }
          };
    };
  }

  public static controlComparisonValidator(
    firstControlName: string,
    secondControlName: string,
    isValid: (firstControlValue: any, secondControlValue: any) => boolean,
    errorName: string
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const firstControl = control.get(firstControlName);
      const secondControl = control.get(secondControlName);
      const isInvalid = firstControl && secondControl && !isValid(firstControl.value, secondControl.value);
      return isInvalid ? { [errorName]: true } : null;
    };
  }
}

/**
 * @deprecated use `ValidatorsHelper` instead
 */
export class ValidatorsUtils extends ValidatorsHelper {}
