<div class="tiime-tabs-header">
  <div
    class="tiime-tab-label"
    [class.disabled]="tab.disabled"
    [class.selected]="activeTab === tab"
    (click)="selectTab(tab)"
    *ngFor="let tab of tabs; trackBy: trackByIndex"
  >
    <ng-container *ngIf="tab.title; else labelTemplate" [ngTemplateOutlet]="tab.title"/>
    <ng-template #labelTemplate>{{ tab.label }}</ng-template>
  </div>
</div>
<div class="tiime-tabs-content">
  <div [ngTemplateOutlet]="activeTab?.content"></div>
</div>
