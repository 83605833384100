<tiime-input-container tiimeFormInputContainer [label]="label" contentId="socialRegimeSelector">
  <tiime-select
    id="socialRegimeSelector"
    [class.selected]="control.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
  >
    <input
      tiimeSelectInput
      placeholder="Rechercher un régime social"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let socialRegime of filteredSelectorData; trackBy: trackByCode" [value]="socialRegime.code">
        {{ socialRegime.label }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
