import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputMaskModule } from '@ngneat/input-mask';

import { TiimeFormModule, TiimeInputContainerModule } from 'tiime-material';

import { SiretFormControl } from '@forms/siret-form-control';
import { MaskHelper } from '@helpers/mask.helper';
import { FormUpdateOnBlurDirective } from '@modules/shared/directives/form-update-on-blur/form-update-on-blur.directive';

@Component({
  standalone: true,
  selector: 'app-siret-input',
  templateUrl: './siret-input.component.html',
  styleUrls: ['./siret-input.component.scss'],
  imports: [
    FormUpdateOnBlurDirective,
    InputMaskModule,
    TiimeInputContainerModule,
    CommonModule,
    TiimeFormModule,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiretInputComponent {
  @Input() errorMessage: string;
  @Input() required: boolean;
  @Input() siretFormControl: SiretFormControl;

  @Output() valueChanges = new EventEmitter<string>();

  readonly siretMask = MaskHelper.getSiretMask();

  emit(): void {
    this.valueChanges.emit(this.siretFormControl.value);
  }
}
