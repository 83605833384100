import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { LocalStorageService } from 'tiime-expert-utils';

import { ColumnData } from './columns-selector.component';

@Injectable({
  providedIn: 'root'
})
export class ColumnsSelectorService<T> extends LocalStorageService {
  private defaultColumns: string[] = [];
  private filterColumnsSubject: BehaviorSubject<ColumnData[]> = new BehaviorSubject<ColumnData[]>([]);
  private displayedColumnSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private columnsChangedSubject: Subject<void> = new Subject<void>();

  constructor() {
    super('');
  }

  initColumnsLocalStorage(
    columnsKey: string,
    defaultColumns: string[],
    defaultFilterColumns: ColumnData[]
  ): Observable<string[]> {
    this.key = columnsKey;
    this.defaultColumns = defaultColumns;

    const localStorageColumns = this.retrieve() ?? defaultFilterColumns;
    this.updateColumnList(localStorageColumns, defaultFilterColumns);

    return this.getDisplayedColumns();
  }

  updateColumn(column: ColumnData): void {
    const localStorageColumns: ColumnData[] = this.retrieve();
    const index = localStorageColumns.findIndex(
      (localStorageColumn: ColumnData) => localStorageColumn?.code === column.code
    );
    if (index > -1) {
      localStorageColumns[index] = column;
    }
    this.storeColumns(localStorageColumns);
    this.columnsChangedSubject.next();
  }

  getCurrentColumnsKey(): string {
    return this.key;
  }

  getDisplayedColumns(): Observable<string[]> {
    return this.displayedColumnSubject.asObservable().pipe(startWith(this.displayedColumnSubject.value));
  }

  getFilterColumns(): Observable<ColumnData[]> {
    return this.filterColumnsSubject.asObservable().pipe(startWith(this.filterColumnsSubject.value));
  }

  getColumnChanged(): Observable<void> {
    return this.columnsChangedSubject.asObservable();
  }

  resetCurrentKey(): void {
    this.key = null;
  }

  override retrieve(): ColumnData[] | undefined {
    return super.retrieve() as ColumnData[] | undefined;
  }

  private storeColumns(columns: ColumnData[]): void {
    this.store(columns);
    this.filterColumnsSubject.next(columns.filter((column: ColumnData) => column?.code));
    this.displayedColumnSubject.next(this.generateDisplayedColumns());
  }

  private updateColumnList(currentValue: ColumnData[], defaultValue: ColumnData[]): void {
    const result = defaultValue.map(column => {
      const found = currentValue.find(col => col?.code === column.code);
      if (found) {
        return found;
      }
      return {
        ...column
      };
    });
    this.storeColumns(result);
  }

  private generateDisplayedColumns(): string[] {
    const localStorageColumns: ColumnData[] = this.retrieve() ?? [];
    return this.defaultColumns.filter((displayedColumn: string) => {
      const localStorageColumn = localStorageColumns.find(column => column?.code === displayedColumn);
      return !localStorageColumn || !localStorageColumn.hidden;
    });
  }
}
