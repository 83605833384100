import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Mapper, NgUtils } from 'tiime-expert-utils';
import { DialogBase } from 'tiime-material';

import { KEYBOARD_SHORTCUTS, KeyboardShortcut } from '@constants/keyboard-shortcuts.constant';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-keyboard-shortcuts-dialog',
  templateUrl: './keyboard-shortcuts-dialog.component.html',
  styleUrls: ['./keyboard-shortcuts-dialog.component.scss']
})
export class KeyboardShortcutsDialogComponent extends DialogBase<KeyboardShortcutsDialogComponent> {
  trackByIndex = NgUtils.trackByIndex;

  protected shortcutsSets: KeyboardShortcut[] = KEYBOARD_SHORTCUTS;

  readonly mapToIsAvailableOnFirefox: Mapper<boolean, boolean> = (firefoxAvailable: boolean) =>
    firefoxAvailable || !this.platform.FIREFOX;

  constructor(
    public dialogRef: MatDialogRef<KeyboardShortcutsDialogComponent>,
    private platform: Platform
  ) {
    super(dialogRef);
  }
}
