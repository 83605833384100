import { Inject, Injectable } from '@angular/core';

import { saveAs } from 'file-saver';

import { WINDOW } from '../tokens/window.token';

export interface FileSaverData {
  file: Blob;
  filename: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(@Inject(WINDOW) private windowRef: Window) {}

  openInNewTab(file: Blob): void {
    const documentBlobUrl: string = URL.createObjectURL(file);
    this.windowRef.open(documentBlobUrl, '_blank');
  }

  save(fileSaverData: FileSaverData): void {
    saveAs(fileSaverData.file, fileSaverData.filename);
  }
}
