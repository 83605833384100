import { ConfidentialitiesEnum } from '../enums/confidentialities.enum';
import { NumberOfEmployeesEnum } from '../enums/number-of-employees.enum';

export interface ResultJson {
  exploitation: number;
  current_before_tax: number;
  exceptional: number;
  turnover: number;
  net: number;
  non_tax_deductible_expenses: number;
  amount_result: number;
  legal_reserve_amount: number;
  optional_reserve_amount: number;
  report_debit: number;
  report_credit: number;
  share_capital: number;
  own_capital: number;
  amount_affect: number;
  legal_reserve_amount_affect: number;
  optional_reserve_amount_affect: number;
  report_debit_affect: number;
  report_credit_affect: number;
  dividend_distribution: number;
  account_confidentiality: ConfidentialitiesEnum;
  number_of_employees: NumberOfEmployeesEnum;
  bilan_total: number;
}

export class Result {
  constructor(
    public exploitation?: number,
    public currentBeforeTax?: number,
    public exceptional?: number,
    public turnover?: number,
    public net?: number,
    public nonTaxDeductibleExpenses?: number,
    public amountResult?: number,
    public legalReserveAmount?: number,
    public optionalReserveAmount?: number,
    public reportDebit?: number,
    public reportCredit?: number,
    public shareCapital?: number,
    public ownCapital?: number,
    public amountAffect?: number,
    public legalReserveAmountAffect?: number,
    public optionalReserveAmountAffect?: number,
    public reportDebitAffect?: number,
    public reportCreditAffect?: number,
    public dividendDistribution?: number,
    public accountConfidentiality?: ConfidentialitiesEnum,
    public numberOfEmployees?: NumberOfEmployeesEnum,
    public bilanTotal?: number
  ) {}

  static fromJson(json: Partial<ResultJson>): Result {
    return new Result(
      json.exploitation,
      json.current_before_tax,
      json.exceptional,
      json.turnover,
      json.net,
      json.non_tax_deductible_expenses,
      json.amount_result,
      json.legal_reserve_amount,
      json.optional_reserve_amount,
      json.report_debit,
      json.report_credit,
      json.share_capital,
      json.own_capital,
      json.amount_affect,
      json.legal_reserve_amount_affect,
      json.optional_reserve_amount_affect,
      json.report_debit_affect,
      json.report_credit_affect,
      json.dividend_distribution,
      json.account_confidentiality,
      json.number_of_employees,
      json.bilan_total
    );
  }
  static toJson(result: Result): ResultJson {
    return {
      exploitation: result.exploitation,
      current_before_tax: result.currentBeforeTax,
      exceptional: result.exceptional,
      turnover: result.turnover,
      net: result.net,
      non_tax_deductible_expenses: result.nonTaxDeductibleExpenses,
      amount_result: result.amountResult,
      legal_reserve_amount: result.legalReserveAmount,
      optional_reserve_amount: result.optionalReserveAmount,
      report_debit: result.reportDebit,
      report_credit: result.reportCredit,
      share_capital: result.shareCapital,
      own_capital: result.ownCapital,
      amount_affect: result.amountAffect,
      legal_reserve_amount_affect: result.legalReserveAmountAffect,
      optional_reserve_amount_affect: result.optionalReserveAmountAffect,
      report_debit_affect: result.reportDebitAffect,
      report_credit_affect: result.reportCreditAffect,
      dividend_distribution: result.dividendDistribution,
      account_confidentiality: result.accountConfidentiality,
      number_of_employees: result.numberOfEmployees,
      bilan_total: result.bilanTotal
    };
  }
}
