import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[tiimeCardHeaderIcon]',
  exportAs: 'tiime-card-header-icon',
  host: {
    class: 'tiime-card-header-icon'
  }
})
export class CardHeaderIconDirective {}

@Directive({
  selector: '[tiimeCardHeaderTitle]',
  exportAs: 'tiime-card-header-title',
  host: {
    class: 'tiime-card-header-title'
  }
})
export class CardHeaderTitleDirective {}

@Directive({
  selector: '[tiimeCardHeaderActions]',
  exportAs: 'tiime-card-header-actions',
  host: {
    class: 'tiime-card-header-actions'
  }
})
export class CardHeaderActionsDirective {}

@Component({
  selector: 'tiime-card-header',
  templateUrl: 'card-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-card-header'
  }
})
export class CardHeaderComponent {}

@Directive({
  selector: '[tiimeCardContent]',
  exportAs: 'tiime-card-content',
  host: {
    class: 'tiime-card-content'
  }
})
export class CardContentDirective {}

@Directive({
  selector: '[tiimeCardFooter]',
  exportAs: 'tiime-card-footer',
  host: {
    class: 'tiime-card-footer'
  }
})
export class CardFooterDirective {}

@Component({
  selector: 'tiime-card',
  templateUrl: './card.component.html',
  styleUrls: ['card.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-card'
  }
})
export class CardComponent {}
