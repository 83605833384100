export interface CompanyRegistryJson {
  id: number;
  rcs_city: string;
}

export class CompanyRegistry {
  constructor(
    public id?: number,
    public rcsCity?: string
  ) {}

  static fromJson(json: Partial<CompanyRegistryJson>): CompanyRegistry {
    return new CompanyRegistry(json.id, json.rcs_city);
  }

  static toJson(companyRegistry: CompanyRegistry): CompanyRegistryJson {
    return {
      id: companyRegistry.id,
      rcs_city: companyRegistry.rcsCity
    };
  }
}
