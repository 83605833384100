import { InvoiceStatus } from '@enums/invoice-status.enum';
import { InvoiceType } from '@enums/invoice-type.enum';
import { ArithmeticHelper } from '@helpers/arithmetic.helper';
import { Label, LabelJson } from '@models/label';
import { LinkedTransaction, LinkedTransactionJson } from '@models/linked-transaction';

import { TotalsPerVatType, TotalsPerVatTypeJson } from './totals-per-vat-type';

export interface InvoiceJson {
  id: number;
  status: InvoiceStatus;
  // eslint-disable-next-line id-blacklist
  number: number;
  emission_date: string;
  client_name: string;
  client_country: string;
  total_including_taxes: number;
  total_excluding_taxes: number;
  vat_amount: number;
  vat: number;
  totals_per_vat_type: Partial<TotalsPerVatTypeJson>;
  bank_transactions: Partial<LinkedTransactionJson>[];
  label: Partial<LabelJson>;
  type: InvoiceType;
}

export class Invoice {
  get isPaid(): boolean {
    return this.status === InvoiceStatus.paid;
  }
  get isSaved(): boolean {
    return this.status === InvoiceStatus.saved;
  }

  constructor(
    public id?: number,
    public status?: InvoiceStatus,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    public number?: number,
    public emissionDate?: string,
    public clientName?: string,
    public clientCountry?: string,
    public totalIncludingTaxes?: number,
    public totalExcludingTaxes?: number,
    public vatAmount?: number,
    public vat?: number,
    public totalsPerVatType?: TotalsPerVatType,
    public bankTransactions?: LinkedTransaction[],
    public label?: Label,
    public type?: InvoiceType
  ) {}

  public static fromJson(json: Partial<InvoiceJson>): Invoice {
    return new Invoice(
      json.id,
      json.status,
      json.number,
      json.emission_date,
      json.client_name,
      json.client_country,
      json.total_including_taxes,
      json.total_excluding_taxes,
      Invoice.vatAmount(json.total_including_taxes, json.total_excluding_taxes),
      Invoice.vat(json.total_including_taxes, json.total_excluding_taxes),
      json.totals_per_vat_type ? TotalsPerVatType.fromJson(json.totals_per_vat_type) : null,
      json.bank_transactions?.length
        ? json.bank_transactions.map(bankTransactionJson => LinkedTransaction.fromJson(bankTransactionJson))
        : [],
      json.label ? Label.fromJson(json.label) : null,
      json.type
    );
  }

  static vat(totalIncludingTaxes: number, totalExcludingTaxes: number): number {
    return totalIncludingTaxes === 0 ||
      totalExcludingTaxes === 0 ||
      typeof totalIncludingTaxes !== 'number' ||
      typeof totalExcludingTaxes !== 'number'
      ? 0
      : Math.abs(
          ArithmeticHelper.div(Invoice.vatAmount(totalIncludingTaxes, totalExcludingTaxes), totalExcludingTaxes, 4)
        );
  }

  static vatAmount(totalIncludingTaxes: number, totalExcludingTaxes: number): number {
    if (typeof totalIncludingTaxes !== 'number' && typeof totalExcludingTaxes !== 'number') {
      return 0;
    }
    return ArithmeticHelper.minus(totalIncludingTaxes ?? 0, totalExcludingTaxes ?? 0);
  }
}
