export class NumberHelper {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static isValidNumber(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }
}

/**
 * @deprecated Use `NumberHelper` instead.
 */
export class NumberUtils extends NumberHelper {}
