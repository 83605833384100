import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimePipesModule } from 'tiime-expert-utils';

import { TiimeStepperActionsDirective } from './stepper-actions';
import { TiimeStepperNextDirective, TiimeStepperPreviousDirective } from './stepper-button';
import { TiimeStepComponent, TiimeStepperComponent } from './stepper.component';

@NgModule({
  imports: [CommonModule, CdkStepperModule, TiimePipesModule],
  declarations: [
    TiimeStepComponent,
    TiimeStepperNextDirective,
    TiimeStepperPreviousDirective,
    TiimeStepperActionsDirective,
    TiimeStepperComponent
  ],
  exports: [
    TiimeStepComponent,
    TiimeStepperNextDirective,
    TiimeStepperPreviousDirective,
    TiimeStepperActionsDirective,
    TiimeStepperComponent
  ]
})
export class TiimeStepperModule {}
