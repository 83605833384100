import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { TitleStrategy } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from '@env';
import { AppConfigService } from '@services/app-config.service';
import { BeamerModule } from '@third-parties/beamer/beamer.module';
import { FirebaseModule } from '@third-parties/firebase/firebase.module';
import { HelpScoutBeaconService } from '@third-parties/help-scout-beacon/help-scout-beacon.service';
import { HubAuthModule } from '@third-parties/hub/hub-auth.module';
import { HubAuthService } from '@third-parties/hub/hub-auth.service';
import { SentryModule } from '@third-parties/sentry/sentry.module';

import { ApiInterceptor } from './api.interceptor';
import { AuthModule } from './auth/auth.module';
import { AppEffects } from './effects/app.effects';
import { GlobalErrorHandler } from './global-error-handler';
import { PageTitleService } from './services/page-title.service';
import { BusinessUserEffects } from './store/business-user/business-user-effect';
import * as businessUserReducer from './store/business-user/business-user-reducer';
import { NotificationsEffects } from './store/notifications/notifications-effects';
import * as notificationsReducer from './store/notifications/notifications-reducer';
import { provideBootstrapEffects } from './store/provide-bootstrap-effects';

export function getHubToken(): string {
  return localStorage.getItem(HubAuthService.HUB_TOKEN_KEY);
}

const devModules = environment.production
  ? []
  : [
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
      , connectInZone: true
    })
  ];

@NgModule({
  imports: [
    FirebaseModule,
    SentryModule,
    AuthModule,
    HttpClientModule,
    StoreModule.forRoot({
      businessUser: businessUserReducer.reducer,
      notifications: notificationsReducer.reducer
    }),
    EffectsModule.forRoot([AppEffects, BusinessUserEffects]),
    HubAuthModule.forRoot(),
    BeamerModule,
    ...devModules
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        appConfigService: AppConfigService,
        helpScoutBeaconService: HelpScoutBeaconService
      ): (() => Promise<void>) => appConfigService.appConfigFactory<void>(() => helpScoutBeaconService.init()),
      deps: [AppConfigService, HelpScoutBeaconService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: TitleStrategy, useClass: PageTitleService },
    // https://github.com/ngrx/platform/issues/931
    provideBootstrapEffects([NotificationsEffects]),
    DeviceDetectorService
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
