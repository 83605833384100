<tiime-input-container tiimeFormInputContainer contentId="maritalStatusSelector" [label]="label">
  <tiime-select
    id="maritalStatusSelector"
    [class.selected]="control.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option [value]="maritalStatus.code" *ngFor="let maritalStatus of selectorData; trackBy: trackByIndex">
        {{ maritalStatus.label }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
