import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  DatepickerHeaderComponent,
  TiimeDatepickerModule,
  TiimeFormModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeSlideToggleContainerModule,
  TiimeSlideToggleModule
} from 'tiime-material';

import { CommonDataApiService } from '@api-services/common-data-api.service';
import { DirectorForm } from '@forms/user/director-form';
import { IncludesPipe } from '@shared-pipes/includes/includes.pipe';
import { SocialRegimeSelectComponent } from '@shared-selectors/social-regime-select/social-regime-select.component';

@Component({
  standalone: true,
  selector: 'app-director-form',
  templateUrl: './director-form.component.html',
  styleUrls: ['./director-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeIconModule,
    TiimeDatepickerModule,
    TiimeSlideToggleContainerModule,
    TiimeSlideToggleModule,
    SocialRegimeSelectComponent,
    IncludesPipe
  ]
})
export class DirectorFormComponent {
  @Input() directorForm: DirectorForm;
  @Input() tiimeFormExpanded: boolean;
  @Input() displayedProperties = ['socialRegime', 'startJobDate', 'endJobDate', 'acre'];

  readonly socialRegimes$ = this.commonDataApiService.getSocialRegimes();
  readonly tiimeDatepickerHeader = DatepickerHeaderComponent;

  constructor(private commonDataApiService: CommonDataApiService) {}
}
