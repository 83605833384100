import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { TiimeButtonModule } from '../button/button.module';
import { TiimeIconModule } from '../icon/icon.module';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    TiimeIconModule,
    TiimeButtonModule
  ],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent]
})
export class TiimeSnackbarModule {}
