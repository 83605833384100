import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TiimeButtonModule, TiimeIconModule } from 'tiime-material';

import { Contributor } from '@models/contributor';
import { BusinessUserSlugComponent } from '@shared-components/business-user-slug/business-user-slug.component';
import { FavoriteIconButtonComponent } from '@shared-components/favorite-icon-button/favorite-icon-button.component';

@Component({
  selector: 'app-contributor-option',
  standalone: true,
  imports: [CommonModule, BusinessUserSlugComponent, TiimeIconModule, TiimeButtonModule, FavoriteIconButtonComponent],
  templateUrl: './contributor-option.component.html',
  styleUrls: ['./contributor-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributorOptionComponent {
  @Input({ required: true }) contributor: Contributor;
  @Input() favorite: boolean = false;
  @Input() withFavorites: boolean = false;

  @Output() readonly removeFromFavorites: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly addToFavorites: EventEmitter<void> = new EventEmitter<void>();

  emitRemoveFromFavorites(): void {
    this.removeFromFavorites.emit();
  }

  emitAddToFavorites(): void {
    this.addToFavorites.emit();
  }
}
