import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';
import { AddressForm } from '@forms/user/address-form';
import { DirectorForm } from '@forms/user/director-form';
import { LegalInformationForm } from '@forms/user/legal-information-form';
import { OwnershipForm } from '@forms/user/ownership-form';
import { DateHelper } from '@helpers/date.helper';
import { PhoneHelper } from '@helpers/phone.helper';
import { ValidatorHelper } from '@helpers/validators.helper';
import { User } from '@models/user';

export type UserFormType = {
  id: FormControl<number | undefined>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
  capacity: FormControl<UserCapacity | undefined>;
  civility: FormControl<string | undefined>;
  address: AddressForm;
  ownership: OwnershipForm;
  legalInformation: LegalInformationForm;
  director: DirectorForm;
};

export class UserForm extends FormGroup<UserFormType> {
  get id(): FormControl<number | undefined> {
    return this.controls.id;
  }

  get firstName(): FormControl<string | null> {
    return this.controls.firstName;
  }

  get firstNameErrorMessage(): string {
    return this.firstName.touched && this.firstName.hasError('required') ? 'Prénom requis' : null;
  }

  get lastName(): FormControl<string | null> {
    return this.controls.lastName;
  }

  get lastNameErrorMessage(): string {
    return this.lastName.touched && this.lastName.hasError('required') ? 'Nom requis' : null;
  }

  get email(): FormControl<string | null> {
    return this.controls.email;
  }

  get emailErrorMessage(): string {
    if (!this.email.touched) {
      return null;
    }

    if (this.email.hasError('required')) {
      return 'Email requis';
    }

    if (this.email.hasError('email')) {
      return 'Email invalide';
    }

    return null;
  }

  get phone(): FormControl<string | null> {
    return this.controls.phone;
  }

  get phoneErrorMessage(): string {
    if (!this.phone.touched) {
      return null;
    }

    if (this.phone.hasError('required')) {
      return 'Téléphone requis';
    }

    if (this.phone.hasError('invalidPhoneNumber')) {
      return 'Téléphone invalide';
    }

    return null;
  }

  get capacity(): FormControl<UserCapacity | undefined> {
    return this.controls.capacity;
  }

  get civility(): FormControl<string | undefined> {
    return this.controls.civility;
  }

  get address(): AddressForm {
    return this.controls.address;
  }

  get ownership(): OwnershipForm {
    return this.controls.ownership;
  }

  get legalInformation(): LegalInformationForm {
    return this.controls.legalInformation;
  }

  get director(): DirectorForm {
    return this.controls.director;
  }

  constructor(
    emailValidators = [ValidatorHelper.emailValidator()],
    phoneValidators = [PhoneHelper.phoneNumberValidator()]
  ) {
    super({
      id: new FormControl<number | undefined>(undefined),
      firstName: new FormControl<string | null>(null, Validators.required),
      lastName: new FormControl<string | null>(null, Validators.required),
      email: new FormControl<string | null>(null, emailValidators),
      phone: new FormControl<string | null>(null, phoneValidators),
      capacity: new FormControl<UserCapacity | undefined>(undefined),
      civility: new FormControl<string | undefined>(undefined),
      address: new AddressForm(),
      ownership: new OwnershipForm(),
      legalInformation: new LegalInformationForm(),
      director: new DirectorForm()
    });
  }

  fromUser(user: User): void {
    this.patchValue({
      id: user.id,
      capacity: user.capacity,
      civility: user.civility,
      lastName: user.lastName,
      firstName: user.firstName,
      phone: user.phone && PhoneHelper.isValid(user.phone) ? PhoneHelper.format(user.phone) : user.phone,
      email: user.email,
      address: {
        mailingStreet: user.mailingStreet,
        mailingCity: user.mailingCity,
        mailingPostalCode: user.mailingPostalCode,
        mailingCountry: user.mailingCountry
      },
      ownership: {
        capitalContribution: user.capitalContribution,
        sharesNumber: user.sharesNumber,
        entryDate: user.entryDate,
        exitDate: user.exitDate,
        sharesBareOwnershipNumber: user.sharesBareOwnershipNumber,
        sharesUsufructNumber: user.sharesUsufructNumber
      },
      legalInformation: {
        birthDate: user.birthDate,
        birthPlace: user.birthPlace,
        birthDepartment: user.birthDepartment,
        birthCountry: user.birthCountry,
        nationality: user.nationality,
        maritalStatus: user.maritalStatus,
        taxNumber: user.taxNumber,
        socialSecurityNumber: user.socialSecurityNumber
      },
      director: {
        socialRegime: user.socialRegime,
        startJobDate: user.startJobDate,
        endJobDate: user.endJobDate,
        acre: user.acre
      }
    });
  }

  toUser(): User {
    const user = new User();
    user.type = UserType.user;
    user.id = this.id.value;
    user.firstName = this.firstName.value;
    user.lastName = this.lastName.value;
    user.email = this.email.value;
    user.phone = this.phone.value;
    user.civility = this.civility.value;
    user.capacity = this.capacity.value;
    user.capitalContribution = this.ownership.capitalContribution.value;
    user.sharesNumber = this.ownership.sharesNumber.value;
    user.sharesBareOwnershipNumber = this.ownership.sharesBareOwnershipNumber.value;
    user.sharesUsufructNumber = this.ownership.sharesUsufructNumber.value;
    user.entryDate = this.ownership.entryDate.value ? DateHelper.format(this.ownership.entryDate.value) : null;
    user.exitDate = this.ownership.exitDate.value ? DateHelper.format(this.ownership.exitDate.value) : null;
    user.mailingStreet = this.address.mailingStreet.value;
    user.mailingCity = this.address.mailingCity.value;
    user.mailingPostalCode = this.address.mailingPostalCode.value;
    user.mailingCountry = this.address.mailingCountry.value;
    user.birthDate = this.legalInformation.birthDate.value
      ? DateHelper.format(this.legalInformation.birthDate.value)
      : null;
    user.birthPlace = this.legalInformation.birthPlace.value;
    user.birthDepartment = this.legalInformation.birthDepartment.value;
    user.birthCountry = this.legalInformation.birthCountry.value;
    user.nationality = this.legalInformation.nationality.value;
    user.maritalStatus = this.legalInformation.maritalStatus.value;
    user.taxNumber = this.legalInformation.taxNumber.value;
    user.socialSecurityNumber = this.legalInformation.socialSecurityNumber.value;
    user.socialRegime = this.director.socialRegime.value;
    user.startJobDate = this.director.startJobDate.value ? DateHelper.format(this.director.startJobDate.value) : null;
    user.endJobDate = this.director.endJobDate.value ? DateHelper.format(this.director.endJobDate.value) : null;
    user.acre = this.director.acre.value;
    return user;
  }
}
