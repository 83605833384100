import { DateHelper } from '@helpers/date.helper';
import { User, UserJson } from '@models/user';

export interface MissionJson {
  id: number;
  start_date: string;
  end_date_updated_at: string;
  end_date_updated_by: Partial<UserJson>;
  end_date: string;
  last_bilan_due_date: string;
  last_vat_due_date: string;
  exit_motif: string;
  exit_motif_comment: string;
  works_stop: boolean;
  works_stop_updated_at: string;
  works_stop_updated_by: Partial<UserJson>;
  works_stop_comment: string;
  ecf: boolean;
  ecf_date: string;
  ecf_updated_at: string;
  ecf_updated_by: Partial<UserJson>;
}

export type SerializedMission = Omit<
  MissionJson,
  | 'id'
  | 'end_date_updated_at'
  | 'end_date_updated_by'
  | 'works_stop_updated_at'
  | 'works_stop_updated_by'
  | 'ecf_updated_at'
  | 'ecf_updated_by'
>;

export class Mission {
  constructor(
    public id?: number,
    public endDateUpdatedAt?: Date,
    public endDateUpdatedBy?: User,
    public endDate?: Date,
    public lastBilanDueDate?: Date,
    public lastVatDueDate?: Date,
    public exitMotif?: string,
    public exitMotifComment?: string,
    public worksStopUpdatedAt?: Date,
    public worksStopUpdatedBy?: User,
    public worksStop?: boolean,
    public worksStopComment?: string,
    public startDate?: Date,
    public ecf?: boolean,
    public ecfDate?: Date,
    public ecfUpdatedAt?: Date,
    public ecfUpdatedBy?: User
  ) {}

  static fromJson(json: Partial<MissionJson>): Mission {
    return new Mission(
      json.id,
      json.end_date_updated_at ? DateHelper.fromUtc(json.end_date_updated_at) : null,
      json.end_date_updated_by ? User.fromJson(json.end_date_updated_by) : null,
      json.end_date ? DateHelper.fromUtc(json.end_date) : null,
      json.last_bilan_due_date ? DateHelper.fromUtc(json.last_bilan_due_date) : null,
      json.last_vat_due_date ? DateHelper.fromUtc(json.last_vat_due_date) : null,
      json.exit_motif,
      json.exit_motif_comment,
      json.works_stop_updated_at ? DateHelper.fromUtc(json.works_stop_updated_at) : null,
      json.works_stop_updated_by ? User.fromJson(json.works_stop_updated_by) : null,
      json.works_stop,
      json.works_stop_comment,
      json.start_date ? DateHelper.fromUtc(json.start_date) : null,
      json.ecf,
      json.ecf_date ? DateHelper.fromUtc(json.ecf_date) : null,
      json.ecf_updated_at ? DateHelper.fromUtc(json.ecf_updated_at) : null,
      json.ecf_updated_by ? User.fromJson(json.ecf_updated_by) : null
    );
  }

  static toJson(mission: Mission): SerializedMission {
    return {
      start_date: mission.startDate ? DateHelper.format(mission.startDate) : null,
      end_date: mission.endDate ? DateHelper.format(mission.endDate) : null,
      last_bilan_due_date: mission.lastBilanDueDate ? DateHelper.format(mission.lastBilanDueDate) : null,
      last_vat_due_date: mission.lastVatDueDate ? DateHelper.format(mission.lastVatDueDate) : null,
      exit_motif: mission.exitMotif,
      exit_motif_comment: mission.exitMotifComment,
      works_stop: mission.worksStop,
      works_stop_comment: mission.worksStopComment,
      ecf: mission.ecf,
      ecf_date: mission.ecfDate ? DateHelper.format(mission.ecfDate) : null
    };
  }
}
