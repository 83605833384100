import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { LabelCategoryPlan } from '@models/label-category-plan';

import { CreateOrUpdateLabelsCategoryPlanDialogData } from './create-or-update-labels-category-plan-dialog-data';
import { CreateOrUpdateLabelsCategoryPlanDialogComponent } from './create-or-update-labels-category-plan-dialog.component';

@Injectable({ providedIn: 'root' })
export class CreateOrUpdateLabelsCategoryPlanDialogService extends createDialogServiceBase<
  LabelCategoryPlan,
  CreateOrUpdateLabelsCategoryPlanDialogData
>(CreateOrUpdateLabelsCategoryPlanDialogComponent) {}
