import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { FormUtils } from 'tiime-expert-utils';
import { TiimeAlertModule, TiimeButtonToggleModule, TiimeFormModule, TiimeInputContainerModule } from 'tiime-material';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadForm } from '@forms/thread-form';
import { AccountingPeriod } from '@models/accounting-period';
import { CompanyContributor } from '@models/company-contributor';
import { Company } from '@models/company';
import { Contributor } from '@models/contributor';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { TaskPlanning } from '@models/task-planning';
import { ThreadTag } from '@models/thread-tag';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';
import { ThreadEntityTypesByCategoryPipe } from '@shared-pipes/thread-entity-types-by-category/thread-entity-types-by-category.pipe';
import { AccountingPeriodSelectComponent } from '@shared-selectors/accounting-period-select/accounting-period-select.component';
import { ContributorSelectMultipleComponent } from '@shared-selectors/contributor-select-multiple/contributor-select-multiple.component';
import { CorporateTaxInstalmentSelectComponent } from '@shared-selectors/corporate-tax-instalment-select/corporate-tax-instalment-select.component';
import { TaskSelectComponent } from '@shared-selectors/task-select/task-select.component';
import { ThreadEntityTypeSelectComponent } from '@shared-selectors/thread-entity-type-select/thread-entity-type-select.component';
import { ThreadTagSelectMultipleComponent } from '@shared-selectors/thread-tag-select-multiple/thread-tag-select-multiple.component';
import { TransactionSelectComponent } from '@shared-selectors/transaction-select/transaction-select.component';
import { VatDeclarationSelectComponent } from '@shared-selectors/vat-declaration-select/vat-declaration-select.component';

@Component({
  selector: 'app-thread-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeButtonToggleModule,
    TiimeAlertModule,
    TextFieldModule,
    ContributorSelectMultipleComponent,
    AccountingPeriodSelectComponent,
    ThreadEntityTypeSelectComponent,
    VatDeclarationSelectComponent,
    TaskSelectComponent,
    TransactionSelectComponent,
    ThreadEntityTypesByCategoryPipe,
    ThreadTagSelectMultipleComponent,
    CorporateTaxInstalmentSelectComponent
  ],
  templateUrl: './thread-form.component.html',
  styleUrls: ['./thread-form.component.scss']
})
export class ThreadFormComponent implements AfterViewInit {
  @Input() threadForm: ThreadForm;
  @Input() contributors: Contributor[];
  @Input() companyContributors: CompanyContributor[];
  @Input() accountingPeriods: AccountingPeriod[];
  @Input() vatDeclarations: VatDeclarationConfiguration[];
  @Input() taskPlannings: TaskPlanning[];
  @Input() corporateTaxInstalments: CorporateTaxInstalment[];
  @Input({ required: true }) company: Company;
  @Input({ required: true }) tags: ThreadTag[];

  @ViewChild('messageInput') messageInput: ElementRef;

  readonly ThreadEntityType: typeof ThreadEntityType = ThreadEntityType;
  readonly ThreadCategory: typeof ThreadCategory = ThreadCategory;

  ngAfterViewInit(): void {
    this.messageInput?.nativeElement.focus();
  }

  categoryChange(change: MatButtonToggleChange): void {
    this.threadForm.reset(
      {
        category: change.value
      },
      FormUtils.shouldNotEmitEvent
    );
  }

  entityTypeChange(): void {
    this.threadForm.controls.entity.setValue(null, FormUtils.shouldNotEmitEvent);
  }
}
