import { Observable, Subscriber } from 'rxjs';

export class ResizeObservable extends Observable<ResizeObserverEntry[]> {
  constructor(element: HTMLElement, options?: ResizeObserverOptions) {
    super((subscriber: Subscriber<ResizeObserverEntry[]>) => {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => subscriber.next(entries));

      resizeObserver.observe(element, options);

      return function unsubscribe() {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      };
    });
  }
}
