import { HttpHelper } from '@helpers/http.helper';

import { AccountingPeriod, AccountingPeriodJson } from './accounting-period';
import { PcgEntry, PcgEntryJson } from './pcg-entry';

export interface PrepaidMaskJson {
  id: number;
  amount: number;
  provision_amount: number;
  accounting_period: Partial<AccountingPeriodJson>;
  pcg_entry: Partial<PcgEntryJson>;
  start_date: string;
  end_date: string;
  wording: string;
}

export class PrepaidMask {
  constructor(
    public id?: number,
    public amount?: number,
    public provisionAmount?: number,
    public accountingPeriod?: AccountingPeriod,
    public pcgEntry?: PcgEntry,
    public startDate?: string,
    public endDate?: string,
    public wording?: string
  ) {}

  static fromJson(json: Partial<PrepaidMaskJson>): PrepaidMask {
    return new PrepaidMask(
      json.id,
      json.amount,
      json.provision_amount,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.start_date,
      json.end_date,
      json.wording
    );
  }

  static toJson(prepaidMask: PrepaidMask): Omit<PrepaidMaskJson, 'provision_amount'> {
    return {
      id: prepaidMask.id,
      amount: prepaidMask.amount,
      accounting_period: AccountingPeriod.toJson(prepaidMask.accountingPeriod),
      pcg_entry: HttpHelper.toJsonId(prepaidMask.pcgEntry),
      start_date: prepaidMask.startDate,
      end_date: prepaidMask.endDate,
      wording: prepaidMask.wording || undefined
    };
  }
}
