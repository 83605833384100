import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BehaviorSubject, Observable, finalize, tap } from 'rxjs';

import { SelectBase } from './select.base';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ApiFilteredSelectBase<T> extends SelectBase<T> {
  readonly filteredSelectorData$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly searchControl = new FormControl<string | null>(null);

  closeOptionsPanel(): void {
    this.searchControl.setValue(null);
    this.cleanFilteredSelectorData();
  }

  searchControlChanges(): void {
    if (!this.searchControl.value) {
      this.cleanFilteredSelectorData();
      return;
    }

    this.isLoading$.next(true);

    this.filterFunction(this.searchControl.value)
      .pipe(
        tap(data => this.filteredSelectorData$.next(data)),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe();
  }

  cleanFilteredSelectorData(): void {
    this.filteredSelectorData$.next([]);
  }

  abstract filterFunction(search: string): Observable<T[]>;
}
