<tiime-input-container
  tiimeFormInputContainer
  [label]="label"
  contentId="labels-plan"
  [required]="required"
  [errorMessage]="errorMessage"
>
  <tiime-select id="labels-plan" [placeholder]="label" [class.selected]="ctrl?.value" [formControl]="ctrl">
    <div tiimeSelectOptions>
      <mat-option [value]="null" [disabled]="emptyOptionDisabled" *ngIf="emptyOption">
        {{ emptyOptionLabel }}
      </mat-option>
      <mat-option *ngFor="let labelsPlan of labelsPlans; trackBy: trackById" [value]="labelsPlan.id">
        {{ labelsPlan.name }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
