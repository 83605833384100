import { AutomaticBankPcgEntryModeType } from '@enums/automatic-bank-pcg-entry-mode-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { LabelCategoryPlan, LabelCategoryPlanJson } from '@models/label-category-plan';
import { VatType, VatTypeJson } from '@models/vat-type';

export interface LabelCategoryPlanSetupJson {
  id?: number;
  category?: LabelCategoryPlanJson;
  bank_account_number?: string;
  piece_account_number?: string;
  vat_type?: Partial<VatTypeJson>;
  vat_exoneration_type?: string;
  automatic_bank_pcg_entry_mode_type?: AutomaticBankPcgEntryModeType;
}

export class LabelCategoryPlanSetup {
  constructor(
    public id?: number,
    public category?: LabelCategoryPlan,
    public bankAccountNumber?: string,
    public pieceAccountNumber?: string,
    public vatType?: VatType,
    public vatExonerationType?: string,
    public automaticBankPcgEntryModeType?: AutomaticBankPcgEntryModeType
  ) {}

  static fromJson(json: LabelCategoryPlanSetupJson): LabelCategoryPlanSetup {
    return new LabelCategoryPlanSetup(
      json.id,
      json.category ? LabelCategoryPlan.fromJson(json.category) : null,
      json.bank_account_number,
      json.piece_account_number,
      json.vat_type ? VatType.fromJson(json.vat_type) : null,
      json.vat_exoneration_type,
      json.automatic_bank_pcg_entry_mode_type
    );
  }

  static toJson(labelCategoryPlanSetup: LabelCategoryPlanSetup): LabelCategoryPlanSetupJson {
    return {
      id: HttpHelper.toJsonProperty(labelCategoryPlanSetup.id),
      bank_account_number: HttpHelper.toJsonProperty(labelCategoryPlanSetup.bankAccountNumber),
      piece_account_number: HttpHelper.toJsonProperty(labelCategoryPlanSetup.pieceAccountNumber),
      vat_type: labelCategoryPlanSetup.vatType?.id ? HttpHelper.toJsonId(labelCategoryPlanSetup.vatType) : null,
      vat_exoneration_type: HttpHelper.toJsonProperty(labelCategoryPlanSetup.vatExonerationType),
      automatic_bank_pcg_entry_mode_type: HttpHelper.toJsonProperty(
        labelCategoryPlanSetup.automaticBankPcgEntryModeType
      )
    };
  }
}
