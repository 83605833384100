import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeTabComponent, TiimeTabsComponent, TiimeTabTitleComponent } from './tabs.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TiimeTabComponent, TiimeTabsComponent, TiimeTabTitleComponent],
  exports: [TiimeTabComponent, TiimeTabsComponent, TiimeTabTitleComponent]
})
export class TiimeTabsModule {}
