export interface BankAccountBalanceJson {
  id: number;
  balance_amount: number;
  balance_date: string;
}

export class BankAccountBalance {
  constructor(public id?: number, public balanceAmount?: number, public balanceDate?: string) {}

  public static fromJson(json: Partial<BankAccountBalanceJson>): BankAccountBalance {
    return new BankAccountBalance(json.id, json.balance_amount, json.balance_date);
  }
}
