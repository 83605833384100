import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { TiimeOverlayConfig } from './overlay.config';
import { TiimeOverlayRef } from './overlay.ref';
import { TiimeOverlayService } from './overlay.service';

@Injectable()
export abstract class OverlayServiceBase<TResult = any, TDataInput = any> {
  overlayRef: TiimeOverlayRef;

  constructor(protected overlayService: TiimeOverlayService) {}

  afterClosed(): Observable<TResult | void> {
    return this.overlayRef.afterClosed().pipe(take(1)) as Observable<TResult | void>;
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.close(null);
    }
  }

  abstract open(data?: TDataInput, config?: TiimeOverlayConfig): this;
}
