import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputContainerComponent } from './input-container.component';
import { InputPrefixDirective } from './input-prefix';
import { InputSuffixDirective } from './input-suffix';
import { TiimeTooltipModule } from '../tooltip';

@NgModule({
  imports: [CommonModule, TiimeTooltipModule],
  declarations: [InputContainerComponent, InputSuffixDirective, InputPrefixDirective],
  exports: [InputContainerComponent, InputSuffixDirective, InputPrefixDirective]
})
export class TiimeInputContainerModule {}
