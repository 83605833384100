import { formatPercent } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { VatHelper } from '@helpers/vat.helper';
import { VatType } from '@models/vat-type';

@Pipe({
  name: 'vatTypeRate',
  standalone: true
})
export class VatTypeRatePipe implements PipeTransform {
  transform(vatTypeId: number, vatTypes: VatType[]): string {
    if (!vatTypeId || !vatTypes?.length) {
      return null;
    }

    const selectedVatType = vatTypes.find(vatType => vatType.id === vatTypeId);

    if (!selectedVatType) {
      return null;
    }

    if (selectedVatType.hasName) {
      return selectedVatType.name;
    }

    const vatRates = selectedVatType.vatRates;

    if (!vatRates?.length) {
      return null;
    }

    return formatPercent(VatHelper.vatRateFromEmissionDate(vatRates), 'fr', '1.0-2');
  }
}
