export interface CompanyMetricsJson {
  label_predictability_percent: number;
}

export class CompanyMetrics {
  constructor(public labelPredictabilityPercent?: number) {}

  static fromJson(json: CompanyMetricsJson): CompanyMetrics {
    return new CompanyMetrics(json.label_predictability_percent);
  }
}
