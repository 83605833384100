<div class="message-container" (mouseenter)="hovered = true" (mouseleave)="hovered = false">
  <div class="slug-container" *ngIf="displaySlug && message.createdBy">
    <app-business-user-slug [businessUserAcronym]="message.createdBy?.acronym"></app-business-user-slug>
  </div>
  <div class="message-content">
    <div class="message-header">
      <span class="message-created-by-me" *ngIf="displayMe">Vous</span>
      <span class="message-created-by">{{ message.createdBy?.fullName }}</span>
      <span class="message-created-at" tiimeTooltip="Crée le {{ message.createdAt | date: 'dd/MM/YYYY à HH:mm' }}">
        {{ message.createdAt | momentFromNow: true }}
      </span>
      <button
        class="edit-action"
        tiime-button
        icon
        color="primary"
        title="Modifier"
        (click)="$event.stopImmediatePropagation(); setEditMode()"
        *ngIf="hovered && !editDisabled && !message.contentDeletedAt"
      >
        <mat-icon fontIcon="icon-header-grey-n3-24px-edit"></mat-icon>
      </button>
    </div>
    <ng-container *ngIf="editMode; else messsageTemplate">
      <tiime-input-container
        class="message-textarea"
        contentId="message"
        label="Modifier le message"
        (click)="$event.stopImmediatePropagation()"
      >
        <textarea
          id="message"
          placeholder="Modifier le message"
          [formControl]="messageControl"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
        ></textarea>
      </tiime-input-container>
      <div class="edit-mode-actions">
        <button tiime-button color="accent" strocked (click)="$event.stopImmediatePropagation(); cancelEditMode()">
          Annuler
        </button>
        <button
          tiime-button
          color="accent"
          raised
          (click)="$event.stopImmediatePropagation(); emitUpdateMessageContent()"
        >
          Enregistrer
        </button>
      </div>
    </ng-container>
    <ng-template #messsageTemplate>
      <div class="message" *ngIf="!message.contentDeletedAt">{{ message.content }}</div>
      <span class="message-status" *ngIf="message.contentDeletedAt">
        Message supprimé le {{ message.contentDeletedAt | date: 'dd/MM/YYYY à HH:mm' }}
      </span>
      <span class="message-status" *ngIf="message.contentUpdatedAt && !message.contentDeletedAt">
        Message modifié le {{ message.contentUpdatedAt | date: 'dd/MM/YYYY à HH:mm' }}
      </span>
    </ng-template>
  </div>
</div>
