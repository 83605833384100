import { MaritalStatusCode } from '@enums/marital-status-code.enum';
import { SocialRegimeCode } from '@enums/social-regime-code.enum';
import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { ApeCode, ApeCodeJson } from '@models/ape-code';
import { UserBase } from '@models/user-base';

export interface UserJson {
  id: number;
  type: UserType;
  birth_country: string;
  birth_date: string;
  birth_department: string;
  birth_place: string;
  civility: string;
  can_access_company: boolean;
  email: string;
  entry_date: string;
  exit_date: string;
  firstname: string;
  has_account: boolean;
  lastname: string;
  mailing_city: string;
  mailing_postal_code: string;
  mailing_street: string;
  mailing_country: string;
  marital_status: MaritalStatusCode;
  nationality: string;
  password_updated_at: string;
  phone: string;
  acronym: string;
  shares_number: number;
  shares_percentage: number;
  shares_bare_ownership_number: number;
  shares_usufruct_number: number;
  social_security_number: number;
  capacity: UserCapacity;
  tax_number: number;
  director: boolean;
  capital_contribution: number;
  last_app_invitation_at: string;
  social_regime: SocialRegimeCode;
  start_job_date: string;
  end_job_date: string;
  acre: boolean;
  legal_form: string;
  name: string;
  siret: string;
  rcs_city: string;
  ape_code: Partial<ApeCodeJson>;
  capital: number;
  legal_representative_last_name: string;
  legal_representative_first_name: string;
  legal_representative_phone: string;
  legal_representative_email: string;
  signed_terms_of_service_date?: string;
}

export class User extends UserBase {
  type: UserType;
  birthCountry: string;
  birthDate: string;
  birthDepartment: string;
  birthPlace: string;
  civility: string;
  canAccessCompany: boolean;
  director: boolean;
  entryDate: string;
  exitDate: string;
  hasAccount: boolean;
  mailingCity: string;
  mailingPostalCode: string;
  mailingStreet: string;
  mailingCountry: string;
  maritalStatus: MaritalStatusCode;
  nationality: string;
  passwordUpdatedAt: string;
  sharesNumber: number;
  sharesPercentage: number;
  sharesBareOwnershipNumber: number;
  sharesUsufructNumber: number;
  socialSecurityNumber: number;
  capacity: UserCapacity;
  taxNumber: number;
  capitalContribution: number;
  lastAppInvitationAt: string;
  socialRegime: SocialRegimeCode;
  startJobDate: string;
  endJobDate: string;
  acre: boolean;
  legalForm: string;
  name: string;
  siret: string;
  rcsCity: string;
  apeCode: ApeCode;
  capital: number;
  legalRepresentativeLastName: string;
  legalRepresentativeFirstName: string;
  legalRepresentativePhone: string;
  legalRepresentativeEmail: string;
  signedTermsOfServiceDate: Date;

  get isMoral(): boolean {
    return this.type === UserType.moral;
  }

  get isOutsideCapital(): boolean {
    return (
      this.sharesPercentage === 0 && DateHelper.isBefore(this.exitDate, DateHelper.format(DateHelper.currentDate()))
    );
  }

  override get fullName(): string {
    return this.type && this.isMoral ? `${this.name}${this.legalForm ? ` - ${this.legalForm}` : ``}` : super.fullName;
  }

  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    acronym?: string,
    hasAccount?: boolean,
    canAccessCompany?: boolean,
    passwordUpdatedAt?: string
  ) {
    super(id, firstName, lastName, email, phone, acronym);
    this.hasAccount = hasAccount;
    this.canAccessCompany = canAccessCompany;
    this.passwordUpdatedAt = passwordUpdatedAt;
  }

  static fromJson(json: Partial<UserJson>): User {
    const user = new User();
    user.id = json.id;
    user.type = json.type;
    user.passwordUpdatedAt = json.password_updated_at;
    user.canAccessCompany = json.can_access_company;
    user.hasAccount = json.has_account;
    user.phone = json.phone;
    user.acronym = json.acronym;
    user.firstName = json.firstname;
    user.lastName = json.lastname;
    user.civility = json.civility;
    user.capacity = json.capacity;
    user.email = json.email;
    user.entryDate = json.entry_date;
    user.exitDate = json.exit_date;
    user.sharesPercentage = json.shares_percentage;
    user.sharesNumber = json.shares_number;
    user.sharesBareOwnershipNumber = json.shares_bare_ownership_number;
    user.sharesUsufructNumber = json.shares_usufruct_number;
    user.mailingPostalCode = json.mailing_postal_code;
    user.mailingStreet = json.mailing_street;
    user.mailingCity = json.mailing_city;
    user.mailingCountry = json.mailing_country;
    user.socialSecurityNumber = json.social_security_number;
    user.taxNumber = json.tax_number;
    user.birthCountry = json.birth_country;
    user.birthDate = json.birth_date;
    user.birthDepartment = json.birth_department;
    user.birthPlace = json.birth_place;
    user.nationality = json.nationality;
    user.maritalStatus = json.marital_status;
    user.director = json.director;
    user.capitalContribution = json.capital_contribution;
    user.lastAppInvitationAt = json.last_app_invitation_at;
    user.socialRegime = json.social_regime;
    user.startJobDate = json.start_job_date;
    user.endJobDate = json.end_job_date;
    user.acre = json.acre;
    user.legalForm = json.legal_form;
    user.name = json.name;
    user.siret = json.siret;
    user.rcsCity = json.rcs_city;
    user.apeCode = json.ape_code ? ApeCode.fromJson(json.ape_code) : null;
    user.capital = json.capital;
    user.legalRepresentativeLastName = json.legal_representative_last_name;
    user.legalRepresentativeFirstName = json.legal_representative_first_name;
    user.legalRepresentativePhone = json.legal_representative_phone;
    user.legalRepresentativeEmail = json.legal_representative_email;
    user.signedTermsOfServiceDate = json.signed_terms_of_service_date
      ? DateHelper.fromUtc(json.signed_terms_of_service_date)
      : null;

    return user;
  }

  static toJson(user: User): Partial<UserJson> {
    return {
      id: HttpHelper.toJsonProperty(user.id),
      birth_country: HttpHelper.toJsonProperty(user.birthCountry),
      birth_date: HttpHelper.toJsonProperty(user.birthDate),
      birth_department: HttpHelper.toJsonProperty(user.birthDepartment),
      birth_place: HttpHelper.toJsonProperty(user.birthPlace),
      civility: HttpHelper.toJsonProperty(user.civility),
      email: HttpHelper.toJsonProperty(user.email),
      entry_date: HttpHelper.toJsonProperty(user.entryDate),
      exit_date: HttpHelper.toJsonProperty(user.exitDate),
      firstname: HttpHelper.toJsonProperty(user.firstName),
      lastname: HttpHelper.toJsonProperty(user.lastName),
      mailing_city: HttpHelper.toJsonProperty(user.mailingCity),
      mailing_postal_code: HttpHelper.toJsonProperty(user.mailingPostalCode),
      mailing_street: HttpHelper.toJsonProperty(user.mailingStreet),
      mailing_country: HttpHelper.toJsonProperty(user.mailingCountry),
      marital_status: HttpHelper.toJsonProperty(user.maritalStatus),
      nationality: HttpHelper.toJsonProperty(user.nationality),
      phone: HttpHelper.toJsonProperty(user.phone),
      shares_number: HttpHelper.toJsonProperty(user.sharesNumber),
      shares_bare_ownership_number: HttpHelper.toJsonProperty(user.sharesBareOwnershipNumber),
      shares_usufruct_number: HttpHelper.toJsonProperty(user.sharesUsufructNumber),
      social_security_number: HttpHelper.toJsonProperty(user.socialSecurityNumber),
      capacity: HttpHelper.toJsonProperty(user.capacity),
      tax_number: HttpHelper.toJsonProperty(user.taxNumber),
      capital_contribution: HttpHelper.toJsonProperty(user.capitalContribution),
      last_app_invitation_at: HttpHelper.toJsonProperty(user.lastAppInvitationAt),
      social_regime: HttpHelper.toJsonProperty(user.socialRegime),
      start_job_date: HttpHelper.toJsonProperty(user.startJobDate),
      end_job_date: HttpHelper.toJsonProperty(user.endJobDate),
      acre: HttpHelper.toJsonProperty(user.acre),
      legal_form: HttpHelper.toJsonProperty(user.legalForm),
      name: HttpHelper.toJsonProperty(user.name),
      siret: HttpHelper.toJsonProperty(user.siret),
      rcs_city: HttpHelper.toJsonProperty(user.rcsCity),
      ape_code: user.apeCode ? ApeCode.toJson(user.apeCode) : null,
      capital: HttpHelper.toJsonProperty(user.capital),
      legal_representative_last_name: HttpHelper.toJsonProperty(user.legalRepresentativeLastName),
      legal_representative_first_name: HttpHelper.toJsonProperty(user.legalRepresentativeFirstName),
      legal_representative_phone: HttpHelper.toJsonProperty(user.legalRepresentativePhone),
      legal_representative_email: HttpHelper.toJsonProperty(user.legalRepresentativeEmail),
      type: HttpHelper.toJsonProperty(user.type)
    };
  }
}
