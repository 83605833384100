import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { UntilDestroy } from '@ngneat/until-destroy';
import { provideComponentStore } from '@ngrx/component-store';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, take, tap } from 'rxjs/operators';

import { FormUtils, MarkFormAsTouchedIfInvalid, NgUtils } from 'tiime-expert-utils';
import {
  ContainerState,
  PaginationRange,
  TiimeButtonModule,
  TiimeButtonToggleModule,
  TiimeContainerModule,
  TiimeEmptyStateModule,
  TiimePaginatorModule,
  TiimeSearchBarModule,
  TiimeTableModule
} from 'tiime-material';

import { ContributorApiService } from '@api-services/contributor-api.service';
import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadFiltersForm } from '@forms/thread-filters-form';
import { ThreadForm } from '@forms/thread-form';
import { shareReplay1 } from '@helpers/rxjs.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { ThreadFilters } from '@interfaces/thread-filters';
import { AccountingPeriod } from '@models/accounting-period';
import { Company } from '@models/company';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { TaskPlanning } from '@models/task-planning';
import { Thread } from '@models/thread';
import { ThreadTag } from '@models/thread-tag';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';
import { IdentificationStore } from '@modules/company/parameters/identification/identification.store';
import { CompanyThreadsStore, Mode } from '@modules/core/component-stores/company-threads.store';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';
import { ExportPeriodComponent } from '@shared-components/export-period/export-period.component';
import { RefreshButtonComponent } from '@shared-components/refresh-button/refresh-button.component';
import { ThreadComponent, UpdateMessageOutput } from '@shared-components/thread/thread.component';
import { ThreadFormComponent } from '@shared-forms/thread-form/thread-form.component';

import { CompanyThreadsFiltersButtonComponent } from './company-threads-filters-button/company-threads-filters-button.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-company-threads',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeContainerModule,
    TiimeTableModule,
    TiimeButtonModule,
    TiimeSearchBarModule,
    TiimePaginatorModule,
    TiimeEmptyStateModule,
    TiimeButtonToggleModule,
    MatIconModule,
    ExportPeriodComponent,
    ThreadComponent,
    ThreadFormComponent,
    CompanyThreadsFiltersButtonComponent,
    RefreshButtonComponent
  ],
  templateUrl: './company-threads.component.html',
  styleUrls: ['./company-threads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(IdentificationStore)]
})
export class CompanyThreadsComponent implements OnInit {
  @ViewChildren(ThreadComponent) threadComponentList: QueryList<ThreadComponent>;

  threadFilters = new ThreadFiltersForm();
  threadForm = new ThreadForm();

  readonly currentBusinessUser$ = this.store.pipe(select(businessUserSelector)).pipe(shareReplay1());
  readonly contributors$: Observable<Contributor[]> = this.contributorApiService.getContributors().pipe(shareReplay1());
  readonly companyContributors$: Observable<CompanyContributor[]> = this.companyThreadsStore.companyContributors$.pipe(
    shareReplay1()
  );
  readonly accountingPeriods$: Observable<AccountingPeriod[]> = this.companyThreadsStore.accountingPeriods$.pipe(
    shareReplay1()
  );
  readonly vatDeclarations$: Observable<VatDeclarationConfiguration[]> = this.companyThreadsStore.vatDeclarations$.pipe(
    shareReplay1()
  );
  readonly taskPlannings$: Observable<TaskPlanning[]> = this.companyThreadsStore.taskPlannings$.pipe(shareReplay1());
  readonly corporateTaxInstalments$: Observable<CorporateTaxInstalment[]> =
    this.companyThreadsStore.corporateTaxInstalments$.pipe(shareReplay1());
  readonly isLoading$: Observable<boolean> = this.companyThreadsStore.isLoading$.pipe(shareReplay1());
  readonly containerState$: Observable<ContainerState> = this.companyThreadsStore.containerState$.pipe(shareReplay1());
  readonly disabledActions$: Observable<boolean> = this.companyThreadsStore.disabledActions$.pipe(shareReplay1());
  readonly range$: Observable<PaginationRange> = this.companyThreadsStore.pagination$;
  readonly data$: Observable<Thread[]> = this.companyThreadsStore.data$;
  readonly filters$: Observable<ThreadFilters> = this.companyThreadsStore.filters$;
  readonly mode$: Observable<Mode> = this.companyThreadsStore.mode$;
  readonly company$: Observable<Company> = this.companyThreadsStore.company$;
  readonly tags$: Observable<ThreadTag[]> = this.companyThreadsStore.tags$;
  readonly selectedThread$: Observable<Company> = this.companyThreadsStore.selected$;
  readonly trackById = NgUtils.trackById;
  readonly ContainerState = ContainerState;
  readonly ThreadCategory = ThreadCategory;

  private subscription = new Subscription();

  constructor(
    private companyThreadsStore: CompanyThreadsStore,
    private contributorApiService: ContributorApiService,
    private store: Store<AppStoreState>
  ) {}

  ngOnInit(): void {
    this.observePlanningFilters();
    this.observeOpen();
    this.initThreadForm();
  }

  emitCloseCompanyThreads(): void {
    this.companyThreadsStore.setOpened(false);
    this.companyThreadsStore.loadInfos();
  }

  applyFilter(filterValue: string): void {
    this.companyThreadsStore.setSearch(filterValue);
  }

  applyRange(range: PaginationRange): void {
    this.companyThreadsStore.setPagination(range);
  }

  createThreadMode(): void {
    this.companyThreadsStore.setMode('Creation');
  }

  listThreadMode(): void {
    this.resetThreadForm();
    this.companyThreadsStore.setSelected(null);
    this.companyThreadsStore.setMode('List');
  }

  detailsThreadMode(thread: Thread): void {
    this.companyThreadsStore.setSelected(thread);
    this.companyThreadsStore.setMode('Detail');
    if (!thread.readByCurrentUser) {
      this.companyThreadsStore.markAsRead(thread);
    }
  }

  createMessage(thread: Thread, message: string): void {
    this.disableThreadMessageControl(thread);
    this.companyThreadsStore
      .createMessage(thread, message)
      .pipe(
        tap(() => this.resetThreadMessageControl(thread)),
        finalize(() => this.enableThreadMessageControl(thread))
      )
      .subscribe();
  }

  closeThread(thread: Thread, closed: boolean): void {
    this.companyThreadsStore.closeThread(thread, closed);
  }

  updateInterlocutors(thread: Thread, interlocutors: Contributor[]): void {
    this.companyThreadsStore.updateInterlocutors(thread, interlocutors);
  }

  updateTags(thread: Thread, tags: ThreadTag[]): void {
    this.companyThreadsStore.updateTags(thread, tags);
  }

  archiveThread(thread: Thread, archived: boolean): void {
    this.companyThreadsStore.archiveThread(thread, archived);
  }

  updateMessageContent(thread: Thread, updateMessageOutput: UpdateMessageOutput): void {
    this.companyThreadsStore.updateMessageContent(
      thread,
      updateMessageOutput.message,
      updateMessageOutput.messageContent
    );
  }

  @MarkFormAsTouchedIfInvalid('threadForm')
  createThread(): void {
    this.threadForm.disable();
    this.companyThreadsStore
      .createThread(this.threadForm.toThread())
      .pipe(
        tap(() => this.resetThreadForm()),
        finalize(() => this.threadForm.enable())
      )
      .subscribe();
  }

  refreshList(): void {
    this.companyThreadsStore.forceRefresh();
    this.companyThreadsStore.loadInfos();
  }

  private observePlanningFilters(): void {
    this.subscription.add(
      this.threadFilters.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(() => this.threadFilters.valid),
          tap(() => this.companyThreadsStore.setFilters(this.threadFilters.toThreadFilters()))
        )
        .subscribe()
    );
  }

  private observeOpen(): void {
    this.subscription.add(
      this.companyThreadsStore.opened$
        .pipe(
          distinctUntilChanged(),
          filter((opened: boolean) => opened),
          tap(() => {
            this.initThreadsStore();
            this.setThreadFiltersFromStoreFilters();
          })
        )
        .subscribe()
    );
  }

  private getStoreFilters(): Observable<ThreadFilters> {
    return this.companyThreadsStore.filters$;
  }

  private initThreadForm(): void {
    this.subscription.add(
      this.getStoreFilters()
        .pipe(
          distinctUntilChanged(),
          tap(filters => {
            this.threadForm.controls.entityType.setValue(filters.type);
            this.threadForm.controls.entity.setValue(filters.entity);
            this.threadForm.controls.category.setValue(filters.category);
          })
        )
        .subscribe()
    );
  }

  private setThreadFiltersFromStoreFilters(): void {
    this.subscription.add(
      this.filters$
        .pipe(tap(filters => this.threadFilters.patchValue(filters, FormUtils.shouldNotEmitEvent)))
        .subscribe()
    );
  }

  private initThreadsStore(): void {
    this.companyThreadsStore.init();
  }

  private disableThreadMessageControl(thread: Thread): void {
    this.threadComponent(thread)?.messageControl.disable();
  }

  private enableThreadMessageControl(thread: Thread): void {
    this.threadComponent(thread)?.messageControl.enable();
  }

  private resetThreadMessageControl(thread: Thread): void {
    this.threadComponent(thread)?.messageControl.reset();
  }

  private threadComponent(thread: Thread): ThreadComponent {
    return this.threadComponentList?.find(comp => comp.thread.id === thread.id);
  }

  private resetThreadForm(): void {
    this.subscription.add(
      this.getStoreFilters()
        .pipe(
          take(1),
          tap((filters: ThreadFilters) =>
            this.threadForm.reset(
              {
                entityType: filters.type ?? null,
                entity: filters.entity ?? null,
                category: filters.category ?? null
              },
              FormUtils.shouldNotEmitEvent
            )
          )
        )
        .subscribe()
    );
  }
}
