export class FormHelper {
  static shouldNotEmitEvent = {
    onlySelf: true,
    emitEvent: false
  };
}

/**
 * @deprecated Use `FormHelper` instead.
 */
export class FormUtils extends FormHelper {}
