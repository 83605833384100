import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormUpdateOnBlur]',
  standalone: true
})
export class FormUpdateOnBlurDirective {
  @Output() readonly formChanges: EventEmitter<any> = new EventEmitter<any>();

  private tempControlValue: any;

  constructor(private ngControl: NgControl) {}

  @HostListener('focus', ['$event.target'])
  onFocus(): void {
    this.tempControlValue = this.ngControl.value;
  }

  @HostListener('blur', ['$event.target'])
  onBlur(): void {
    if (JSON.stringify(this.tempControlValue) === JSON.stringify(this.ngControl.value)) {
      return;
    }

    this.formChanges.emit(this.ngControl.value);
  }
}
