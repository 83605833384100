import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';

import { Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';

import { QUERY_PARAMS } from '@constants/query-params.constant';
import { AuthRedirectLoginOptions, AuthService } from '@modules/core/auth/auth.service';
import { QUERY_PARAMS_MAP } from '@modules/core/tokens/query-params-map.token';
import { SelectedCompanyHistoryService } from '@services/selected-company-history.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit {
  constructor(
    private selectedCompanyHistoryService: SelectedCompanyHistoryService,
    private authService: AuthService,
    @Inject(QUERY_PARAMS_MAP) private queryParamsMap$: Observable<ParamMap>
  ) {}

  ngOnInit(): void {
    this.selectedCompanyHistoryService.clear();
    this.login();
  }

  private login(): void {
    this.getLoginHintQueryParams()
      .pipe(
        switchMap((loginHintParam: string) => {
          let options: AuthRedirectLoginOptions = undefined;
          if (loginHintParam) {
            options = {
              authorizationParams: {
                login_hint: loginHintParam
              }
            };
          }

          return this.authService.login(options);
        })
      )
      .subscribe();
  }

  private getLoginHintQueryParams(): Observable<string> {
    return this.queryParamsMap$.pipe(
      take(1),
      map((queryParams: ParamMap) => queryParams.get(QUERY_PARAMS.loginHint))
    );
  }
}
