import { DateHelper } from '@helpers/date.helper';
import { User, UserJson } from '@models/user';

export interface RiskLevelConfigJson {
  id: number;
  activated: boolean;
  ape_code: boolean;
  ca: boolean;
  legal_form: boolean;
  anti_money_laundering: boolean;
  manual_risk: boolean;
  activated_updated_at: string;
  activated_updated_by: Partial<UserJson>;
}

export type RiskLevelConfigSerializedJson = Omit<
  RiskLevelConfigJson,
  'activated_updated_at' | 'activated_updated_by' | 'id'
>;

export class RiskLevelConfig {
  constructor(
    public id?: number,
    public activated?: boolean,
    public apeCode?: boolean,
    public ca?: boolean,
    public legalForm?: boolean,
    public antiMoneyLaundering?: boolean,
    public manualRisk?: boolean,
    public activatedUpdatedAt?: Date,
    public activatedUpdatedBy?: User
  ) {}

  static fromJson(json: RiskLevelConfigJson): RiskLevelConfig {
    return new RiskLevelConfig(
      json.id,
      json.activated,
      json.ape_code,
      json.ca,
      json.legal_form,
      json.anti_money_laundering,
      json.manual_risk,
      json.activated_updated_at ? DateHelper.fromUtc(json.activated_updated_at) : null,
      json.activated_updated_by ? User.fromJson(json.activated_updated_by) : null
    );
  }

  static toJson(model: RiskLevelConfig): RiskLevelConfigSerializedJson {
    return {
      activated: model.activated,
      ape_code: model.apeCode,
      ca: model.ca,
      legal_form: model.legalForm,
      anti_money_laundering: model.antiMoneyLaundering,
      manual_risk: model.manualRisk
    };
  }
}
