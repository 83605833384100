import { FormControl, FormGroup } from '@angular/forms';

import { VatSystem } from '@models/vat-system';

export type VatSystemFormType = {
  id: FormControl<number | undefined>;
};

export class VatSystemForm extends FormGroup<VatSystemFormType> {
  get id(): FormControl<number | undefined> {
    return this.controls.id;
  }

  constructor() {
    super({
      id: new FormControl<number | undefined>(undefined)
    });
  }

  fromVatSystemForm(vatSystem: VatSystem): void {
    this.patchValue(vatSystem);
  }

  toVatSystemForm(): VatSystem {
    return this.getRawValue() as VatSystem;
  }
}
