import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { BusinessUnit } from '@models/business-unit';
import { FormFocusObserveDirective } from '@shared-directives/form-focus-observe/form-focus-observe.directive';
import { SortByKeyPipe } from '@shared-pipes/sort-by-key/sort-by-key.pipe';

@Component({
  standalone: true,
  selector: 'app-business-unit-select',
  templateUrl: './business-unit-select.component.html',
  imports: [
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    FormFocusObserveDirective,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
    SortByKeyPipe
  ],
  styleUrls: ['./business-unit-select.component.scss']
})
export class BusinessUnitSelectComponent extends FilteredSelectBase<BusinessUnit> {
  @Input() label = 'Groupe';
  @Input() searchPlaceholder = 'Rechercher un groupe';

  filterFunction(search: string): BusinessUnit[] {
    const searchLowerCase = StringUtils.normalizeToLowerCase(search);
    return this.selectorData.filter((businessUnit: BusinessUnit) =>
      StringUtils.normalizeToLowerCase(businessUnit.name).includes(searchLowerCase)
    );
  }
}
