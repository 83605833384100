import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Mapper, NgUtils } from 'tiime-expert-utils';

import { ColumnsSelectorService } from './columns-selector.service';
import { TIIME_OVERLAY_DATA } from '../overlay';

export interface ColumnData {
  code: string;
  label: string;
  hidden: boolean;
  disabled: boolean;
}

export interface ColumnsSelectorData {
  minSelected?: number | null;
}

@Component({
  selector: 'tiime-columns-selector',
  templateUrl: './columns-selector.component.html',
  styleUrls: ['./columns-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsSelectorComponent {
  readonly columns$: Observable<ColumnData[]>;
  readonly minSelected: number;
  readonly trackByIndex = NgUtils.trackByIndex;

  readonly mapToDisable: Mapper<ColumnData[], boolean> = (columns, minSelected, column: ColumnData) =>
    columns.filter(c => !c.hidden).length <= minSelected && !column.hidden && !column.disabled;

  constructor(
    private columnsSelectorService: ColumnsSelectorService<ColumnData>,
    @Inject(TIIME_OVERLAY_DATA)
    data: ColumnsSelectorData
  ) {
    this.minSelected = data.minSelected ?? 1;
    this.columns$ = this.columnsSelectorService.getFilterColumns();
  }

  onColumnChanged(column: ColumnData): void {
    column.hidden = !column.hidden;
    this.columnsSelectorService.updateColumn(column);
  }
}
