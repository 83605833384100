import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TwocolorsIconComponent } from './twocolors-icon.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TwocolorsIconComponent],
  exports: [TwocolorsIconComponent]
})
export class TiimeTwocolorsIconModule { }
