import { ThemePalette } from '@angular/material/core';

export class ConfirmDialogData {
  constructor(
    public confirmTitle: string = 'Attention',
    public confirmMessage: string = 'Êtes-vous sûr?',
    public confirmAction: string = 'Valider',
    public cancelAction: string = 'Annuler',
    public confirmSubMessage: string = null,
    public actionButtonColor: ThemePalette = 'accent',
    public emitOnCancel: boolean = false
  ) {}
}
