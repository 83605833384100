import { Pipe, PipeTransform } from '@angular/core';

import { VatExonerationType } from '@models/vat-exoneration-type';

@Pipe({
  name: 'vatExonerationType',
  standalone: true
})
export class VatExonerationTypePipe implements PipeTransform {
  transform(vatExonerationTypeName: string, vatExonerationTypes: VatExonerationType[]): string {
    if (!vatExonerationTypeName || !vatExonerationTypes?.length) {
      return null;
    }

    const selectedVatExonerationType = vatExonerationTypes.find(
      vatExonerationType => vatExonerationType.name === vatExonerationTypeName
    );

    if (!selectedVatExonerationType) {
      return null;
    }

    return selectedVatExonerationType.value;
  }
}
