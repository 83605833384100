import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-badge, a[tiime-badge]',
  templateUrl: './badge.component.html',
  styleUrls: ['badge.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent extends HasAttributesBase {
  @Input() badge: string;
  @Input() badgeCounter: number;

  get badgeClass(): { active: boolean; small: boolean } {
    return {
      active: this._hasHostAttributes('active'),
      small: this._hasHostAttributes('small')
    };
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
