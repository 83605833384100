import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { TiimeOverlayService } from 'tiime-material';

import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

import { BusinessUserAccountMenuOverlayComponent } from './business-user-account-menu-overlay/business-user-account-menu-overlay.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-business-user-account-menu',
  templateUrl: './business-user-account-menu.component.html',
  styleUrls: ['./business-user-account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUserAccountMenuComponent implements OnInit {
  @ViewChild('businessUserAccountMenu')
  businessUserAccountMenuRef: ElementRef;

  businessUser: BusinessUser;

  private businessUserSub: Subscription;

  constructor(private store: Store<AppStoreState>, private overlayService: TiimeOverlayService) {}

  ngOnInit(): void {
    this.initCurrentBusinessUser();
  }

  openBusinessUserAccountMenuOverlay(): void {
    this.overlayService
      .open(BusinessUserAccountMenuOverlayComponent, this.businessUser, {
        height: 'auto',
        width: '270px',
        hasBackdrop: true,
        backdropClose: true,
        connectTo: {
          origin: this.businessUserAccountMenuRef,
          positions: [
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'bottom',
              offsetX: 10
            }
          ]
        }
      })
      .afterClosed()
      .subscribe();
  }

  private initCurrentBusinessUser(): void {
    this.businessUserSub = this.store
      .pipe(
        select(businessUserSelector),
        take(1),
        tap((businessUser: BusinessUser) => (this.businessUser = businessUser))
      )
      .subscribe();
  }
}
