import { Platform } from '@angular/cdk/platform';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';

import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { ResizeObservable } from '../core/resize-observable';
import { TableFooterRowDirective } from './table-footer-row.directive';
import { TableHeaderRowDirective } from './table-header-row.directive';

@Component({
  selector: 'tiime-virtual-table, table[tiime-virtual-table]',
  template: `
    <div class="columns-selector-container" *ngIf="columnsSelector">
      <tiime-columns-selector-button [minSelected]="minSelectedColumns"></tiime-columns-selector-button>
    </div>
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-table tiime-virtual-table'
  }
})
export class VirtualTableComponent implements AfterViewInit, OnDestroy {
  @Input() columnsSelector: boolean;
  @Input() minSelectedColumns = 1;
  @Input() resizeDebounce = 250;

  @ContentChild(CdkVirtualScrollViewport) private virtualScrollViewport: CdkVirtualScrollViewport;
  @ContentChild(TableHeaderRowDirective) private tableHeaderRow: TableHeaderRowDirective;
  @ContentChild(TableFooterRowDirective) private tableFooterRow: TableFooterRowDirective;

  private elementRef: ElementRef<HTMLElement>;
  private resizeSubscription: Subscription;

  constructor(elementRef: ElementRef<HTMLElement>, private renderer: Renderer2, public platform: Platform) {
    this.elementRef = elementRef;
  }

  ngAfterViewInit(): void {
    this.observeResize();
  }

  ngOnDestroy(): void {
    this.unobserveResize();
  }

  scrollToIndex(index: number = 0, behavior?: ScrollBehavior): void {
    if (!this.virtualScrollViewport) {
      return;
    }

    this.virtualScrollViewport?.scrollToIndex(index, behavior);
  }

  private observeResize(): void {
    this.resizeSubscription = new ResizeObservable(this.elementRef.nativeElement.parentElement)
      .pipe(
        debounceTime(this.resizeDebounce),
        tap(entries => {
          this.updateVirtualScrollViewportHeight(entries[0]?.contentRect.height);
          this.checkVirtualScrollViewportSize();
        })
      )
      .subscribe();
  }

  private unobserveResize(): void {
    this.resizeSubscription.unsubscribe();
  }

  private updateVirtualScrollViewportHeight(containerHeight: number): void {
    if (!this.virtualScrollViewport || !this.platform.FIREFOX) {
      return;
    }

    const hasHeaderRow = !!this.tableHeaderRow;
    const hasFooterRow = !!this.tableFooterRow;

    let virtualScrollViewportMargin = 0;

    if (hasHeaderRow) {
      virtualScrollViewportMargin += 30;
    }

    if (hasFooterRow) {
      virtualScrollViewportMargin += 31;
    }

    const virtualScrollViewportHeight = `${containerHeight - virtualScrollViewportMargin}px`;

    this.renderer.setStyle(this.virtualScrollViewport.elementRef.nativeElement, 'height', virtualScrollViewportHeight);
  }

  private checkVirtualScrollViewportSize(): void {
    if (!this.virtualScrollViewport) {
      return;
    }

    this.virtualScrollViewport.checkViewportSize();
  }
}
