import { DateHelper } from '@helpers/date.helper';

import { BeneficiaryCapitalDepositStatusEnum } from '../enums/beneficiary-capital-deposit-status.enum';
import { CapitalDepositBeneficiaryBase } from './capital-deposit-beneficiary-base';
import { UserType } from '@modules/core/enums/user-type.enum';

export interface CapitalDepositPhysicalBeneficiaryJson {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  capital_contribution: number;
  capital_deposit_date: string;
  capital_deposit: boolean;
  capital_deposit_status: BeneficiaryCapitalDepositStatusEnum;
  beneficiary_name: string;
  amount: number;
}

export class CapitalDepositPhysicalBeneficiary extends CapitalDepositBeneficiaryBase {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public capitalContribution: number,
    public capitalDepositDate: string,
    public capitalDeposit: boolean,
    public capitalDepositStatus: BeneficiaryCapitalDepositStatusEnum,
    public beneficiaryName: string,
    public amount: number
  ) {
    super();

    this.type = UserType.user;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static fromJson(json: CapitalDepositPhysicalBeneficiaryJson): CapitalDepositPhysicalBeneficiary {
    return new CapitalDepositPhysicalBeneficiary(
      json.id,
      json.first_name,
      json.last_name,
      json.email,
      json.capital_contribution,
      json.capital_deposit_date ? DateHelper.format(json.capital_deposit_date) : null,
      json.capital_deposit,
      json.capital_deposit_status,
      json.beneficiary_name,
      json.amount
    );
  }
  static toJson(beneficiary: CapitalDepositPhysicalBeneficiary): CapitalDepositPhysicalBeneficiaryJson {
    return {
      id: beneficiary.id,
      first_name: beneficiary.firstName,
      last_name: beneficiary.lastName,
      email: beneficiary.email,
      capital_contribution: beneficiary.capitalContribution,
      capital_deposit_date: beneficiary.capitalDepositDate ? DateHelper.format(beneficiary.capitalDepositDate) : null,
      capital_deposit: beneficiary.capitalDeposit,
      capital_deposit_status: beneficiary.capitalDepositStatus,
      beneficiary_name: beneficiary.beneficiaryName,
      amount: beneficiary.amount
    };
  }
}
