import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY, LEGAL_COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';

import { FormalityStatusEnum } from '../enums/formality-status.enum';
import { Formality, FormalityJson } from '../models/formality';

/**
 * Formality Service.
 */
@Injectable({
  providedIn: 'root'
})
export class FormalityApiService extends ApiCommonService<Formality> {
  resourceUrl = `${LEGAL_COMPANY_API_ROUTE_BASE}/formalities`;
  singleResourceUrl = `${LEGAL_COMPANY_API_ROUTE_BASE}/formality`;

  @ApiAlertError()
  getFormalities(): Observable<Formality[]> {
    return this.getAll();
  }

  @ApiAlertError()
  getFormality(formalityId: number): Observable<Formality> {
    return this.http
      .get(`${this.singleResourceUrl}/${formalityId}`)
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  refreshFormality(formalityId: number): Observable<Formality> {
    return this.http
      .get(`${this.singleResourceUrl}/${formalityId}?action=refresh`)
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  patchFormality(formality: Formality, companyId?: number, params?: HttpParams): Observable<Formality> {
    return this.sendPatchFormality(this.toJson(formality), companyId, params);
  }

  @ApiAlertError()
  freezeFormality(formalityId: number): Observable<Formality> {
    return this.http
      .post(`${this.singleResourceUrl}/${formalityId}/freeze_data`, {})
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  reopenFormality(formalityId: number): Observable<Formality> {
    return this.http
      .post(`${this.singleResourceUrl}/${formalityId}/reopen`, {})
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  patchFormalityDeliveryDate(formality: Formality, companyId?: number, params?: HttpParams): Observable<Formality> {
    return this.sendPatchFormality(Formality.toDeliveryDateFormalityJson(formality), companyId, params);
  }

  @ApiAlertError()
  patchFormalityProduction(
    formality: Partial<Formality>,
    companyId?: number,
    params?: HttpParams
  ): Observable<Formality> {
    return this.sendPatchFormality(Formality.toProductionFormalityJson(formality), companyId, params);
  }

  @ApiAlertError()
  postFormality(formality: Formality): Observable<Formality> {
    return this.http
      .post(this.singleResourceUrl, Formality.toCreateFormalityJson(formality))
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  changeFormalityStatus(formalityId: number, status: FormalityStatusEnum): Observable<Formality> {
    return this.http
      .post(`${this.singleResourceUrl}/${formalityId}/status`, { status })
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  synchronizeFormality(formalityId: number): Observable<Formality> {
    return this.http
      .post(`${this.singleResourceUrl}/${formalityId}/synchronize`, {})
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  profitAllocation(formalityId: number): Observable<Formality> {
    return this.http
      .post(`${this.singleResourceUrl}/${formalityId}/profit_allocation`, {})
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  @ApiAlertError()
  postFinalization(formalityId: number): Observable<unknown> {
    return this.http.post(`${this.singleResourceUrl}/${formalityId}/finalization`, {});
  }

  @ApiAlertError()
  postDeposit(formality: Formality): Observable<unknown> {
    return this.http.post(`${this.singleResourceUrl}/${formality.id}/deposit`, this.toJson(formality));
  }

  private sendPatchFormality(
    formalityJson: Partial<FormalityJson>,
    companyId?: number,
    params?: HttpParams
  ): Observable<Formality> {
    const url = companyId
      ? this.singleResourceUrl.replace(API_ROUTE_REPLACE_COMPANY_KEY, companyId.toString())
      : this.singleResourceUrl;

    return this.http
      .patch(`${url}/${formalityJson.id}`, formalityJson, {
        params
      })
      .pipe(map((formalityJson: FormalityJson) => Formality.fromJson(formalityJson)));
  }

  fromJson(json: FormalityJson): Formality {
    return Formality.fromJson(json);
  }

  toJson(model: Formality): FormalityJson {
    return Formality.toJson(model);
  }
}
