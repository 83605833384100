import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material/material.module';
import { setSharedInjector } from './shared-injector';

/**
 * @deprecated Load only required Modules / Components / Pipes / Directives ...
 */
@NgModule({
  exports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, MaterialModule]
})
export class SharedModule {
  constructor(injector: Injector) {
    setSharedInjector(injector);
  }
}
