import { ConnectionProviderScope } from '@enums/connection-provider-scope.enum';
import { ConnectionProviderType } from '@enums/connection-provider-type.enum';

export interface ConnectionProviderJson {
  id?: number;
  name?: string;
  type?: ConnectionProviderType;
  authentication_needed?: boolean;
  scope?: ConnectionProviderScope;
}

export class ConnectionProvider {
  constructor(
    public id?: number,
    public name?: string,
    public type?: ConnectionProviderType,
    public authenticationNeeded?: boolean,
    public scope?: ConnectionProviderScope
  ) {}

  static fromJson(json: Partial<ConnectionProviderJson>): ConnectionProvider {
    return new ConnectionProvider(json.id, json.name, json.type, json.authentication_needed, json.scope);
  }
}
