import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask',
  standalone: true
})
export class MaskPipe implements PipeTransform {
  transform(value: string, mask: string): string {
    if (!value || !mask) {
      return value;
    }

    let result = '';
    for (let im = 0, is = 0; im < mask.length && is < value.length; im++) {
      result += mask.charAt(im) === 'X' ? value.charAt(is++) : mask.charAt(im);
    }
    return result;
  }
}

/**
 * @deprecated Use `mask` instead.
 */
@Pipe({
  name: 't2UtilsMask',
  standalone: true
})
export class OldMaskPipe extends MaskPipe {}
