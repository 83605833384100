export enum CompaniesApiFiltersEnum {
  missionEnded = 'mission_ended',
  worksStopped = 'mission_works_stopped',
  inCreationProcess = 'in_creation_process',
  initializationInProgress = 'initialization_in_progress',
  businessUnits = 'business_units',
  contributors = 'contributor_ids',
  withoutContributors = 'without_contributors',
  contributor_jobs = 'contributor_job_ids',
  taxRegimeCode = 'tax_regime_code',
  missionExitMotifs = 'mission_exit_motifs'
}
