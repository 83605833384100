export const ErrorType = {
  REQUIRED: 'required',
  MAT_DATEPICKER_MIN: 'matDatepickerMin',
  MAT_DATEPICKER_MAX: 'matDatepickerMax',
  EMAIL: 'email',
  INVALID_PHONE_NUMBER: 'invalidPhoneNumber',
  PATTERN: 'pattern',
  MAT_DATEPICKER_PARSE: 'matDatepickerParse',
  MIN: 'min',
  MAX: 'max',
  INVALID: 'invalid'
};

export const ErrorCode = {
  EMPTY: 'Champ vide',
  INVALID: 'Champ invalide',
  INVALID_DATE: 'Date incorrecte',
  FORMAT: 'Erreur de format'
};
