import { DeclarationStatusSource } from '@enums/declaration-status-source';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { TaskPlanningInvoicingStatusCode } from '@enums/task-planning-invoicing-status-code.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { Company, CompanyJson } from '@models/company';
import { Contributor, ContributorJson } from '@models/contributor';
import { TaskScheduler, TaskSchedulerJson } from '@models/task-scheduler';
import { Thread, ThreadJson } from '@models/thread';
import { TimeTrackingsInvoicingType, TimeTrackingsInvoicingTypeJson } from '@models/time-trackings-invoicing-type';
import { TimeTrackingsTask, TimeTrackingsTaskJson } from '@models/time-trackings-task';
import { User, UserJson } from '@models/user';

export interface TaskPlanningJson {
  id: number;
  wording: string;
  deadline_date: string;
  status: DeclarationStatus;
  company: Partial<CompanyJson>;
  task_scheduler: Partial<TaskSchedulerJson>;
  time_tracking_task: Partial<TimeTrackingsTaskJson>;
  responsable: Partial<ContributorJson>;
  supervisor: Partial<ContributorJson>;
  time_tracking_invoicing_type: Partial<TimeTrackingsInvoicingTypeJson>;
  invoicing_status: TaskPlanningInvoicingStatusCode;
  amount: number;
  threads: Partial<ThreadJson>[];
  status_date: string;
  status_by: Partial<UserJson>;
  discount: number;
  invoicing_status_date: string;
  invoicing_status_by: Partial<UserJson>;
}

export type TaskPlanningSerialized = Omit<
  TaskPlanningJson,
  'threads' | 'status_date' | 'status_by' | 'invoicing_status_date' | 'invoicing_status_by'
>;

export class TaskPlanning {
  constructor(
    public id?: number,
    public wording?: string,
    public deadlineDate?: string,
    public status?: DeclarationStatus,
    public company?: Company,
    public taskScheduler?: TaskScheduler,
    public timeTrackingTask?: TimeTrackingsTask,
    public responsable?: Contributor,
    public supervisor?: Contributor,
    public timeTrackingInvoicingType?: TimeTrackingsInvoicingType,
    public invoicingStatus?: TaskPlanningInvoicingStatusCode,
    public amount?: number,
    public threads?: Thread[],
    public statusDate?: Date,
    public statusBy?: User,
    public statusSource?: DeclarationStatusSource,
    public discount?: number,
    public invoicingStatusDate?: Date,
    public invoicingStatusBy?: User
  ) {}

  static fromJson(json: Partial<TaskPlanningJson>): TaskPlanning {
    return new TaskPlanning(
      json.id,
      json.wording,
      json.deadline_date,
      json.status,
      json.company ? Company.fromJson(json.company) : null,
      json.task_scheduler ? TaskScheduler.fromJson(json.task_scheduler) : null,
      json.time_tracking_task ? TimeTrackingsTask.fromJson(json.time_tracking_task) : null,
      json.responsable ? Contributor.fromJson(json.responsable) : null,
      json.supervisor ? Contributor.fromJson(json.supervisor) : null,
      json.time_tracking_invoicing_type ? TimeTrackingsInvoicingType.fromJson(json.time_tracking_invoicing_type) : null,
      json.invoicing_status,
      json.amount,
      json.threads ? json.threads.map((threadJson: ThreadJson) => Thread.fromJson(threadJson)) : [],
      json.status_date ? DateHelper.fromUtc(json.status_date) : null,
      json.status_by ? User.fromJson(json.status_by) : null,
      json.status_by ? DeclarationStatusSource.accountant : DeclarationStatusSource.engine,
      json.discount,
      json.invoicing_status_date ? DateHelper.fromUtc(json.invoicing_status_date) : null,
      json.invoicing_status_by ? User.fromJson(json.invoicing_status_by) : null
    );
  }

  static toJson(model: TaskPlanning): TaskPlanningSerialized {
    return {
      id: model.id,
      wording: model.wording,
      deadline_date: model.deadlineDate,
      status: model.status,
      company: model.company ? Company.toJson(model.company) : null,
      task_scheduler: model.taskScheduler ? TaskScheduler.toJson(model.taskScheduler) : null,
      time_tracking_task: model.timeTrackingTask ? HttpHelper.toJsonId(model.timeTrackingTask) : null,
      responsable: model.responsable ? Contributor.toJson(model.responsable) : null,
      supervisor: model.supervisor ? Contributor.toJson(model.supervisor) : null,
      time_tracking_invoicing_type: model.timeTrackingInvoicingType
        ? HttpHelper.toJsonId(model.timeTrackingInvoicingType)
        : null,
      invoicing_status: model.invoicingStatus,
      amount: model.amount,
      discount: model.discount
    };
  }
}
