import { Transaction, TransactionJson } from '@models/transaction';

export interface TransactionForVatOverrideJson extends TransactionJson {
  receipt_vat_amount: number;
  vat_amount_difference: number;
  vat_amount: number;
}

export class TransactionForVatOverride extends Transaction {
  receiptVatAmount: number;
  vatAmountDifference: number;
  vatAmount: number;

  static fromJson(json: TransactionForVatOverrideJson): TransactionForVatOverride {
    const transaction = super.fromJson(json);
    const result: TransactionForVatOverride = new TransactionForVatOverride();
    Object.keys(transaction).forEach(key => {
      result[key] = transaction[key];
    });

    result.receiptVatAmount = json.receipt_vat_amount;
    result.vatAmountDifference = json.vat_amount_difference;
    result.vatAmount = json.vat_amount;
    return result;
  }
}
