import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';

@Component({
  selector: 'app-apply-all-favorite-option',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatLegacyOptionModule],
  templateUrl: './apply-all-favorite-option.component.html',
  styleUrls: ['./apply-all-favorite-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyAllFavoriteOptionComponent {
  @Input() favoriteCount: number = 0;
}
