import { LOCALE_ID, NgModule } from '@angular/core';

import { IbanPipe, OldIbanPipe } from './iban/iban.pipe';
import { MapperPipe } from './mapper/mapper.pipe';
import { MaskPipe, OldMaskPipe } from './mask/mask.pipe';
import { MomentFromNowPipe } from './moment-from-now/moment-from-now.pipe';

/**
 * @deprecated Import each pipes separately
 */
@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  imports: [MomentFromNowPipe, MaskPipe, OldMaskPipe, IbanPipe, OldIbanPipe, MapperPipe],
  exports: [MomentFromNowPipe, MaskPipe, OldMaskPipe, IbanPipe, OldIbanPipe, MapperPipe]
})
export class TiimePipesModule {}
