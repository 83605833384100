import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import isEmail from 'validator/lib/isEmail';

import { AccountingPeriodHelper } from '@helpers/accounting-period.helper';
import { AccountingPeriod } from '@models/accounting-period';

import { RegexHelper } from './regex.helper';

export class ValidatorHelper {
  static readonly closedAccountPeriodError = 'closedAccountingPeriod';

  static blanklessPatternValidator(blanklessPattern: RegExp, errorName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const blanklessValue = control.value.replace(/\s/g, '');
      const isValid = blanklessPattern.test(blanklessValue);
      return isValid ? null : { [errorName]: true };
    };
  }

  static emailValidator(errorName: string = 'email'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const isValid = isEmail(control.value);
      return isValid ? null : { [errorName]: true };
    };
  }

  /**
   * Vérifie si la date du FormControl n'est pas sur un exercice clos
   *
   * ⚠ Ce validateur n'est pas exécuté si le champ `id` du formulaire parent est vide
   *
   * @param accountingPeriods
   */
  static dateIsOnOpenedAccountingPeriodValidator(accountingPeriods: AccountingPeriod[]): ValidatorFn {
    return (control: FormControl<string | null | undefined>): ValidationErrors | null => {
      if (!control.value || !control.parent?.get('id')?.value) {
        return null;
      }
      const period = AccountingPeriodHelper.findForDate(accountingPeriods ?? [], control.value);
      if (!period?.closed) {
        return null;
      }
      return {
        [ValidatorHelper.closedAccountPeriodError]: {
          accountingPeriod: period
        }
      };
    };
  }

  public static passwordValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const matches = RegexHelper.passwordRegex.test(control.value);
      return matches
        ? null
        : {
            validatePassword: {
              valid: false
            }
          };
    };
  }
}
