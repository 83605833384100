import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { filter, tap } from 'rxjs/operators';

import { Mapper, NgUtils, TiimePipesModule } from 'tiime-expert-utils';
import {
  TiimeButtonModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeSelectModule,
  TiimeTooltipModule
} from 'tiime-material';

import { AsyncProcessType } from '@enums/async-process-type.enum';
import { ErrorMessage } from '@interfaces/error-message';
import { AccountingPeriod } from '@models/accounting-period';
import { EditCustomPeriodDialogModule } from '@modules/shared/dialogs/edit-custom-period-dialog/edit-custom-period-dialog.module';
import { EditCustomPeriodDialogService } from '@modules/shared/dialogs/edit-custom-period-dialog/edit-custom-period-dialog.service';
import { LoadingIconComponent } from '@shared-components/loading-icon/loading-icon.component';

@Component({
  standalone: true,
  selector: 'app-accounting-period-select',
  templateUrl: './accounting-period-select.component.html',
  styleUrls: ['./accounting-period-select.component.scss'],
  imports: [
    ReactiveFormsModule,
    TiimePipesModule,
    EditCustomPeriodDialogModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    TiimeTooltipModule,
    TiimeIconModule,
    CommonModule,
    TiimeButtonModule,
    MatOptionModule,
    LoadingIconComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingPeriodSelectComponent {
  @Input() accountingPeriodFilter: UntypedFormControl | FormControl<AccountingPeriod | null>;
  @Input() accountingPeriods: AccountingPeriod[];
  @Input() endDateOnly? = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() small?: boolean;
  @Input() required = false;
  @Input() allowEmpty = false;
  @Input() withCustom = false;
  @Input() errorMessage: ErrorMessage;

  @Output() readonly customPeriodChange: EventEmitter<number> = new EventEmitter<number>();

  readonly mapToIsCustom: Mapper<AccountingPeriod, boolean> = (
    accountingPeriod: AccountingPeriod,
    withCustom: boolean
  ): boolean => !!accountingPeriod?.custom && withCustom;

  readonly mapToIsClosing: Mapper<AccountingPeriod, boolean> = (accountingPeriod: AccountingPeriod) =>
    accountingPeriod?.asyncProcessType === AsyncProcessType.closing;

  readonly mapToShowTooltip: Mapper<AccountingPeriod, boolean> = (
    accountingPeriod: AccountingPeriod,
    withCustom: boolean
  ) => !!accountingPeriod?.closed || this.mapToIsCustom(accountingPeriod, withCustom);

  readonly mapToClosed: Mapper<AccountingPeriod, boolean> = (accountingPeriod: AccountingPeriod) =>
    !!accountingPeriod?.closed && !this.mapToIsClosing(accountingPeriod);

  readonly mapToClosedIcon: Mapper<AccountingPeriod, string> = (accountingPeriod: AccountingPeriod) =>
    this.mapToClosed(accountingPeriod) ? 'icon-ic-lock-1' : null;

  readonly mapToCustomAccountingPeriod: Mapper<AccountingPeriod[], AccountingPeriod> = (
    accountingPeriods: AccountingPeriod[]
  ) => accountingPeriods?.find((accountingPeriod: AccountingPeriod) => accountingPeriod.custom);

  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;
  protected readonly AsyncProcessType = AsyncProcessType;

  // On utilise ici une arrow function pour garder la bonne référence du `this`
  readonly findAccountingPeriodInSelect = (
    accountingPeriod: Partial<AccountingPeriod>,
    option: Partial<AccountingPeriod> | null
  ): boolean => {
    if (!accountingPeriod) {
      return !option;
    }
    if (accountingPeriod?.endDate && this.withCustom) {
      return accountingPeriod?.id === option.id && accountingPeriod.endDate === option?.endDate;
    }

    return accountingPeriod?.id === option?.id;
  };

  constructor(private editCustomAccountingPeriodDialogService: EditCustomPeriodDialogService) {}

  openEditCustomAccountingPeriodDialog(): void {
    this.editCustomAccountingPeriodDialogService
      .open({
        data: { accountingPeriod: this.mapToCustomAccountingPeriod(this.accountingPeriods) }
      })
      .afterClosed()
      .pipe(
        filter(customAccountingPeriodId => !!customAccountingPeriodId),
        tap(customAccountingPeriodId => this.customPeriodChange.emit(customAccountingPeriodId))
      )
      .subscribe();
  }
}
