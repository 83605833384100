export class PaginationRange {
  get isFirstPage(): boolean {
    return this.max <= this.pageSize - 1;
  }

  private static DEFAULT_PAGE_SIZE = 100;

  constructor(
    public min?: number,
    public max?: number,
    public count?: string | number,
    public pageSize: number = PaginationRange.DEFAULT_PAGE_SIZE
  ) {
    if (this.min === undefined || this.max === undefined) {
      this.setDefaultValues();
    }
  }

  static withPageSize(pageSize?: number): PaginationRange {
    return new PaginationRange(undefined, undefined, undefined, pageSize);
  }

  static fromHttpResponseHeaders(contentRangerHeader: string, pageSize?: number): PaginationRange {
    const rangeRegex = /(^[a-zA-Z][\w]*)\s+(\d+)\s?-\s?(\d+)?\s?\/?\s?(\d+|\*)?/;
    const matches = rangeRegex.exec(contentRangerHeader);
    let min: number;
    let max: number;
    let count: string | number;
    if (matches !== null) {
      min = Number(matches[2]);
      max = Number(matches[3]);
      count = isNaN(Number(matches[4])) ? matches[4] : Number(matches[4]);
    }
    return new PaginationRange(min, max, count, pageSize);
  }

  static fromQueryParams(queryParams: string, pageSize: number = PaginationRange.DEFAULT_PAGE_SIZE): PaginationRange {
    const pageNumber = Number(queryParams);
    if (isNaN(pageNumber)) {
      return new PaginationRange();
    }

    const paginationRange = new PaginationRange();
    paginationRange.min = (pageNumber - 1) * pageSize;
    paginationRange.max = paginationRange.min + pageSize - 1;
    paginationRange.pageSize = pageSize;
    return paginationRange;
  }

  static toQueryParams(range: PaginationRange): string {
    return `${range.min / range.pageSize + 1}`;
  }

  next(): PaginationRange {
    if (!isNaN(Number(this.count)) && this.min + this.pageSize >= (this.count as number)) {
      return undefined;
    }

    const min = this.min + this.pageSize;
    const max = min + this.pageSize - 1;
    return new PaginationRange(min, max, undefined, this.pageSize);
  }

  previous(): PaginationRange {
    if (this.min === 0) {
      return undefined;
    }

    const min = this.min - this.pageSize < 0 ? 0 : this.min - this.pageSize;
    const max = min + this.pageSize - 1;
    return new PaginationRange(min, max, undefined, this.pageSize);
  }

  toHttpRequestHeader(): string {
    return `items=${this.min}-${this.max}`;
  }

  resetMaxAndCount(pageSize = this.pageSize ?? PaginationRange.DEFAULT_PAGE_SIZE): void {
    this.max = (this.min ?? 0) + pageSize - 1;
    this.count = '*';
  }

  private setDefaultValues(): void {
    this.min = 0;
    this.max = 0 + this.pageSize - 1;
    this.count = '*';
  }
}
