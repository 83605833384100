import { FormControl, FormGroup } from '@angular/forms';

import { FormUtils } from 'tiime-expert-utils';

import { ErrorCode, ErrorType } from '@constants/error.constant';
import {
  BANK_PCG_ENTRY_PURCHASES,
  BANK_PCG_ENTRY_SALES,
  PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING
} from '@constants/piece-pcg-entry.constant';
import { FRANCE_VAT_EXONERATION_TYPE } from '@constants/vat-exoneration-types.constant';
import { AutomaticBankPcgEntryModeType } from '@enums/automatic-bank-pcg-entry-mode-type.enum';
import { AccountNumberFormControl } from '@forms/account-number-form-control';
import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';
import { LabelCategoryPlanSetup } from '@models/label-category-plan-setup';
import { VatType } from '@models/vat-type';

export type CategoryFormType = {
  id: FormControl<number>;
  label: FormControl<string>;
};

export type LabelCategoryPlanSetupFormType = {
  id: FormControl<number>;
  category: FormGroup<CategoryFormType>;
  bankAccountNumber: AccountNumberFormControl;
  pieceAccountNumber: AccountNumberFormControl;
  vatTypeId: FormControl<number>;
  vatExonerationType: FormControl<string>;
  automaticBankPcgEntryModeType: FormControl<AutomaticBankPcgEntryModeType>;
};

export class LabelCategoryPlanSetupForm extends FormGroup<LabelCategoryPlanSetupFormType> {
  get bankAccountNumberErrorMessage(): ErrorMessage {
    if (!this.controls.bankAccountNumber.touched) {
      return null;
    }

    if (this.controls.bankAccountNumber.hasError(ErrorType.REQUIRED)) {
      return {
        error: ErrorCode.EMPTY,
        message: `La contrepartie de la banque est requise.`
      };
    }

    return null;
  }

  get pieceAccountNumberErrorMessage(): ErrorMessage {
    if (!this.controls.pieceAccountNumber.touched) {
      return null;
    }

    if (this.controls.pieceAccountNumber.hasError(ErrorType.REQUIRED)) {
      return {
        error: ErrorCode.EMPTY,
        message: `Le compte de pièce est requis.`
      };
    }

    if (this.controls.pieceAccountNumber.hasError('invalid')) {
      return {
        error: ErrorCode.INVALID,
        message: `Le compte de pièce doit commencer par 2, 6, 7 ou 47 ou être sans comptabilisation.`
      };
    }

    return null;
  }

  get vatTypeIdErrorMessage(): ErrorMessage {
    if (!this.controls.vatTypeId.touched) {
      return null;
    }

    if (this.controls.vatTypeId.hasError(ErrorType.REQUIRED)) {
      return {
        error: ErrorCode.EMPTY,
        message: `La TVA est requise.`
      };
    }

    return null;
  }

  get vatExonerationTypeErrorMessage(): ErrorMessage {
    if (!this.controls.vatExonerationType.touched) {
      return null;
    }

    if (this.controls.vatExonerationType.hasError(ErrorType.REQUIRED)) {
      return {
        error: ErrorCode.EMPTY,
        message: `Le motif d'exonération est requis.`
      };
    }

    return null;
  }

  constructor() {
    super({
      id: new FormControl<number>(undefined),
      category: new FormGroup({
        id: new FormControl<number>(undefined),
        label: new FormControl<string>(undefined)
      }),
      bankAccountNumber: new AccountNumberFormControl(undefined),
      pieceAccountNumber: new AccountNumberFormControl(undefined),
      vatTypeId: new FormControl<number>(undefined),
      vatExonerationType: new FormControl<string>(undefined),
      automaticBankPcgEntryModeType: new FormControl<AutomaticBankPcgEntryModeType>(undefined)
    });
  }

  fromLabelCategoryPlanSetup(labelCategoryPlanSetup: LabelCategoryPlanSetup): void {
    this.patchValue({
      id: labelCategoryPlanSetup.id,
      category: {
        id: labelCategoryPlanSetup.category?.id,
        label: labelCategoryPlanSetup.category?.label
      },
      bankAccountNumber: labelCategoryPlanSetup.bankAccountNumber,
      pieceAccountNumber: labelCategoryPlanSetup.pieceAccountNumber,
      vatTypeId: labelCategoryPlanSetup.vatType?.id,
      vatExonerationType: labelCategoryPlanSetup.vatExonerationType,
      automaticBankPcgEntryModeType: labelCategoryPlanSetup.automaticBankPcgEntryModeType
    });
  }

  toLabelCategoryPlanSetup(): LabelCategoryPlanSetup {
    const labelCategoryPlanSetup = new LabelCategoryPlanSetup();
    labelCategoryPlanSetup.id = this.controls.id.value;
    labelCategoryPlanSetup.bankAccountNumber =
      this.isBankAccountNumberSales() || this.isBankAccountNumberPurchases()
        ? null
        : this.controls.bankAccountNumber.value;
    labelCategoryPlanSetup.pieceAccountNumber = this.isPieceAccountNumberWithoutAccounting()
      ? null
      : this.controls.pieceAccountNumber.value;
    labelCategoryPlanSetup.vatType = new VatType(this.controls.vatTypeId.value);
    labelCategoryPlanSetup.vatExonerationType = this.controls.vatExonerationType.value;
    labelCategoryPlanSetup.automaticBankPcgEntryModeType = this.isBankAccountNumberSales()
      ? AutomaticBankPcgEntryModeType.saleEngagement
      : this.isBankAccountNumberPurchases()
      ? AutomaticBankPcgEntryModeType.purchaseEngagement
      : null;

    return labelCategoryPlanSetup;
  }

  manageErrors(vatType: VatType): void {
    if (!this.value) {
      return null;
    }

    const bankAccountNumberIsRequired =
      !this.hasBankAccountNumber() && (this.hasPieceAccountNumber() || this.hasVatTypeId());
    const pieceAccountNumberIsRequired =
      !this.hasPieceAccountNumber() && (this.hasBankAccountNumber() || this.hasVatTypeId());
    const vatTypeIdIsRequired = !this.hasVatTypeId() && (this.hasBankAccountNumber() || this.hasPieceAccountNumber());
    const vatExonerationTypeIsRequired = !this.hasVatExonerationType() && vatType?.id === this.controls.vatTypeId.value;
    const pieceAccountNumberIsInvalid =
      this.hasBankAccountNumber() &&
      (this.testBankAccountNumberValue('^(0|8|9|40|41|46)[0-9]*$') || this.testBankAccountNumberValue('^[a-zA-Z]*$')) &&
      !this.testPieceAccountNumberValue('^(2|6|7|47)[0-9]*$') &&
      !this.isPieceAccountNumberWithoutAccounting();

    FormHelper.removeErrorFromControl(this.controls.bankAccountNumber, ErrorType.REQUIRED);
    FormHelper.removeErrorFromControl(this.controls.pieceAccountNumber, ErrorType.REQUIRED);
    FormHelper.removeErrorFromControl(this.controls.vatTypeId, ErrorType.REQUIRED);
    FormHelper.removeErrorFromControl(this.controls.vatExonerationType, ErrorType.REQUIRED);
    FormHelper.removeErrorFromControl(this.controls.pieceAccountNumber, 'invalid');

    if (bankAccountNumberIsRequired) {
      FormHelper.addErrorToControl(this.controls.bankAccountNumber, ErrorType.REQUIRED);
    }

    if (pieceAccountNumberIsRequired) {
      FormHelper.addErrorToControl(this.controls.pieceAccountNumber, ErrorType.REQUIRED);
    }

    if (vatTypeIdIsRequired) {
      FormHelper.addErrorToControl(this.controls.vatTypeId, ErrorType.REQUIRED);
    }

    if (vatExonerationTypeIsRequired) {
      FormHelper.addErrorToControl(this.controls.vatExonerationType, ErrorType.REQUIRED);
    }

    if (pieceAccountNumberIsInvalid) {
      FormHelper.addErrorToControl(this.controls.pieceAccountNumber, 'invalid');
    }
  }

  autocomplete(franceExonerationVatTypeId: number, keepHistory = false): void {
    this.enable(FormUtils.shouldNotEmitEvent);

    if (!this.hasBankAccountNumber() && !this.hasAutomaticBankPcgEntryModeType()) {
      return;
    }

    if (
      this.testBankAccountNumberValue(
        '^(10|11|12|14|15|16|17|18|19|42|43|44|45|47|48|49|5|616|640|641|642|643|644|645|646|66|67|68|69|71|73|78)[0-9]*$'
      )
    ) {
      if (keepHistory) {
        if (!this.hasPieceAccountNumber()) {
          this.disablePieceAccountNumber();
          this.setPieceAccountNumberWithoutAccounting();
        }

        if (this.isVatTypeFranceExoneration(franceExonerationVatTypeId)) {
          this.disableVatType();
        }

        if (this.isVatExonerationTypeFranceExoneration()) {
          this.disableVatExonerationType();
        }
      } else {
        this.disablePieceAccountNumber();
        this.setPieceAccountNumberWithoutAccounting();
        this.disableVatType();
        this.setVatTypeFranceExoneration(franceExonerationVatTypeId);
        this.disableVatExonerationType();
        this.setVatExonerationTypeFranceExoneration();
      }
    }

    if (
      this.testBankAccountNumberValue(
        '^(2|3|13|60|610|611|612|613|614|615|617|618|619|62|63|647|648|649|65|70|72|74|75|76|77|79)[0-9]*$'
      )
    ) {
      if (keepHistory) {
        if (!this.hasPieceAccountNumber()) {
          this.disablePieceAccountNumber();
          this.setPieceAccountNumberWithoutAccounting();
        }
      } else {
        this.disablePieceAccountNumber();
        this.setPieceAccountNumberWithoutAccounting();
      }
    }

    if (this.testBankAccountNumberValue('^(0|8|9|40|41|46)[0-9]*$') || this.testBankAccountNumberValue('^[a-zA-Z]*$')) {
      if (keepHistory) {
        if (!this.hasPieceAccountNumber()) {
          this.setPieceAccountNumberWithoutAccounting();
        }
      }
    }

    if (this.hasAutomaticBankPcgEntryModeType()) {
      if (keepHistory) {
        this.setBankAccountNumberFromAutomaticBankPcgEntryModeType();
      }
    }
  }

  clearVatExoneration(): void {
    this.controls.vatExonerationType.setValue(null, FormUtils.shouldNotEmitEvent);
  }

  isPieceAccountNumberWithoutAccounting(): boolean {
    return this.controls.pieceAccountNumber.value === PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING.accountNumber;
  }

  isBankAccountNumberSales(): boolean {
    return this.controls.bankAccountNumber.value === BANK_PCG_ENTRY_SALES.accountNumber;
  }

  isBankAccountNumberPurchases(): boolean {
    return this.controls.bankAccountNumber.value === BANK_PCG_ENTRY_PURCHASES.accountNumber;
  }

  setVatExonerationTypeFranceExoneration(): void {
    this.controls.vatExonerationType.setValue(FRANCE_VAT_EXONERATION_TYPE, FormUtils.shouldNotEmitEvent);
  }

  setVatTypeFranceExoneration(franceExonerationVatTypeId: number): void {
    this.controls.vatTypeId.setValue(franceExonerationVatTypeId, FormUtils.shouldNotEmitEvent);
  }

  setPieceAccountNumberWithoutAccounting(): void {
    this.controls.pieceAccountNumber.patchValue(
      PIECE_PCG_ENTRY_WITHOUT_ACCOUNTING.accountNumber,
      FormUtils.shouldNotEmitEvent
    );
  }

  setBankAccountNumberFromAutomaticBankPcgEntryModeType(): void {
    const value =
      this.controls.automaticBankPcgEntryModeType.value === AutomaticBankPcgEntryModeType.purchaseEngagement
        ? BANK_PCG_ENTRY_PURCHASES.accountNumber
        : BANK_PCG_ENTRY_SALES.accountNumber;

    this.controls.bankAccountNumber.patchValue(value, FormUtils.shouldNotEmitEvent);
  }

  hasPieceAccountNumber(): boolean {
    return !!this.controls.pieceAccountNumber.value;
  }

  hasBankAccountNumber(): boolean {
    return !!this.controls.bankAccountNumber.value;
  }

  hasVatTypeId(): boolean {
    return !!this.controls.vatTypeId.value;
  }

  hasAutomaticBankPcgEntryModeType(): boolean {
    return !!this.controls.automaticBankPcgEntryModeType.value;
  }

  hasVatExonerationType(): boolean {
    return !!this.controls.vatExonerationType.value;
  }

  testBankAccountNumberValue(regex: string): boolean {
    return new RegExp(regex).test(this.controls.bankAccountNumber.value);
  }

  testPieceAccountNumberValue(regex: string): boolean {
    return new RegExp(regex).test(this.controls.pieceAccountNumber.value);
  }

  private disablePieceAccountNumber(): void {
    this.controls.pieceAccountNumber.disable(FormUtils.shouldNotEmitEvent);
  }

  private disableVatExonerationType(): void {
    this.controls.vatExonerationType.disable(FormUtils.shouldNotEmitEvent);
  }

  private disableVatType(): void {
    this.controls.vatTypeId.disable(FormUtils.shouldNotEmitEvent);
  }

  private isVatExonerationTypeFranceExoneration(): boolean {
    return this.controls.vatExonerationType.value === FRANCE_VAT_EXONERATION_TYPE;
  }

  private isVatTypeFranceExoneration(franceExonerationVatTypeId: number): boolean {
    return this.controls.vatTypeId.value === franceExonerationVatTypeId;
  }
}
