import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ValidatorsUtils } from 'tiime-expert-utils';

import { ErrorCode } from '@constants/error.constant';
import { FormHelper } from '@helpers/form.helper';
import { ValidatorHelper } from '@helpers/validators.helper';
import { ErrorMessage } from '@interfaces/error-message';

export type CreatePasswordFormType = {
  password: FormControl<string>;
  passwordConfirmation: FormControl<string>;
};

export class CreatePasswordForm extends FormGroup<CreatePasswordFormType> {
  get differentPasswordsErrorMessage(): ErrorMessage | null {
    if (!this.touched) {
      return null;
    }

    if (this.hasError('matchingPassword')) {
      return {
        error: '',
        message: 'Les mots de passe ne sont pas identiques'
      };
    }

    return null;
  }

  get passwordErrorMessage(): ErrorMessage | null {
    if (!this.controls.password.touched) {
      return null;
    }

    if (this.controls.password.hasError('required')) {
      return {
        error: ErrorCode.EMPTY,
        message: 'Mot de passe requis'
      };
    }

    if (this.controls.password.hasError('validatePassword')) {
      return {
        error: '',
        message:
          'Doit contenir au minimum 12 caractères dont une minuscule, une majuscule, un chiffre et un caractère spécial (!@#$%^&*)'
      };
    }

    return null;
  }

  get passwordConfirmationErrorMessage(): ErrorMessage {
    return FormHelper.getEmptyErrorMessage(this.controls.passwordConfirmation, 'Confirmation du mot de passe requise');
  }

  constructor() {
    super(
      {
        password: new FormControl('', [Validators.required, ValidatorHelper.passwordValidator()]),
        passwordConfirmation: new FormControl('', Validators.required)
      },
      {
        validators: ValidatorsUtils.controlComparisonValidator(
          'password',
          'passwordConfirmation',
          (password: string, passwordConfirmation: string) => password === passwordConfirmation,
          'matchingPassword'
        )
      }
    );
  }

  toPassword(): string {
    return btoa(this.controls.password.value);
  }
}
