<div class="thread" [class]="thread | map: mapToColorClass : currentBusinessUser?.id">
  <div class="thread-header">
    <div class="flex gap-10">
      <div class="thread-entity">
        <app-thread-entity-type [type]="thread.entity?.type"></app-thread-entity-type>
        <ng-container [ngSwitch]="thread.entity?.type">
          <span *ngSwitchCase="ThreadEntityType.accountingPeriod">
            au {{ thread.entity?.accountingPeriod?.endDate | date: 'shortDate' }}
          </span>
          <span *ngSwitchCase="ThreadEntityType.vatDeclaration">
            au {{ thread.entity?.vatDeclaration?.endDate | date: 'shortDate' }}
          </span>
          <span *ngSwitchCase="ThreadEntityType.task">
            au {{ thread.entity?.task.deadlineDate | date: 'dd/MM/yyyy' }}
          </span>
          <span *ngSwitchCase="ThreadEntityType.bankTransaction">
            {{ thread.entity?.bankTransaction?.transactionDate | date: 'shortDate' }} &#8226;
            {{ thread.entity?.bankTransaction?.wording }}
          </span>
          <span *ngSwitchCase="ThreadEntityType.corporateTaxInstalment">
            au {{ thread.entity?.corporateTaxInstalment.deadlineDate | date: 'dd/MM/yyyy' }}
          </span>
        </ng-container>
      </div>
      <tiime-table-menu>
        <div mat-menu-item (click)="emitCloseThread(true)" *ngIf="!thread.archivedAt && !thread.closedAt">Clôturer</div>
        <div mat-menu-item (click)="emitCloseThread(false)" *ngIf="!thread.archivedAt && thread.closedAt">
          Déclôturer
        </div>
        <div mat-menu-item (click)="emitArchiveThread(true)" *ngIf="!thread.archivedAt">Archiver</div>
        <div mat-menu-item (click)="emitArchiveThread(false)" *ngIf="thread.archivedAt">Désarchiver</div>
      </tiime-table-menu>
    </div>
    <div
      class="thread-tags"
      [class.empty]="!thread.tags.length"
      (mouseenter)="tagsHovered.set(true)"
      (mouseleave)="tagsHovered.set(false)"
      *ngIf="!tagsEditMode()"
    >
      <div
        class="tags-list"
        tiimeTooltip
        [tooltipContent]="tagsTooltipTemplate"
        *ngIf="thread.tags.length; else withoutTagsTemplate"
      >
        <ng-container *ngFor="let tag of thread.tags; trackBy: trackById; let last = last">
          #{{ tag.name }}
        </ng-container>
      </div>
      <ng-template #tagsTooltipTemplate>
        <div class="tiime-tooltip-content">
          <ng-container *ngFor="let tag of thread.tags; trackBy: trackById; let last = last">
            #{{ tag.name }}
            <br />
          </ng-container>
        </div>
      </ng-template>
      <ng-template #withoutTagsTemplate>#Aucun Tag</ng-template>
      <button
        tiime-button
        icon
        color="primary"
        title="Modifier"
        tiimeTooltip="Modifier les tags"
        (click)="setTagsEditMode(true)"
        *ngIf="
          currentBusinessUser?.id === thread.creator?.id &&
          !thread.closedAt &&
          !thread.archivedAt &&
          thread.category === ThreadCategory.accountant &&
          tagsHovered() &&
          !tagsEditMode() &&
          !interlocutorsEditMode()
        "
      >
        <mat-icon fontIcon="icon-header-grey-n3-24px-edit"></mat-icon>
      </button>
    </div>
    <div class="thread-update-properties-container" *ngIf="tagsEditMode()">
      <app-thread-tag-select-multiple
        [tags]="tags"
        [control]="tagsControl"
        label="Modifier les tags"
      ></app-thread-tag-select-multiple>
      <div class="interlocutors-edit-mode-actions">
        <button tiime-button color="accent" strocked (click)="setTagsEditMode(false)">Annuler</button>
        <button tiime-button color="accent" raised (click)="emitUpdateTags()">Enregistrer</button>
      </div>
    </div>
    <div
      class="thread-interlocutors"
      (mouseenter)="interlocutorsHovered.set(true)"
      (mouseleave)="interlocutorsHovered.set(false)"
    >
      <mat-icon
        class="interlocutor-icon"
        svgIcon="icon-duo"
        color="primary"
        tiimeTooltip="Interlocuteurs"
        tooltipPosition="top"
      ></mat-icon>
      <app-company-contacts
        [users]="[thread.creator]"
        tiimeTooltip="Créateur du fil de discussion"
        tooltipPosition="top"
      ></app-company-contacts>
      <ng-container *ngIf="thread.interlocutors?.length && !interlocutorsEditMode()">
        <mat-icon class="interlocutor-icon" inline="true" fontIcon="icon-arrow-2-2c-r-24"></mat-icon>
        <app-company-contacts
          [users]="thread.interlocutors"
          tiimeTooltip="Interlocuteurs du fil de discussion"
          tooltipPosition="top"
        ></app-company-contacts>
      </ng-container>
      <button
        tiime-button
        icon
        color="primary"
        title="Modifier"
        tiimeTooltip="Modifier les interlocuteurs"
        (click)="setInterlocutorsEditMode(true)"
        *ngIf="
          currentBusinessUser?.id === thread.creator?.id &&
          !thread.closedAt &&
          !thread.archivedAt &&
          thread.category === ThreadCategory.accountant &&
          interlocutorsHovered() &&
          !interlocutorsEditMode() &&
          !tagsEditMode()
        "
      >
        <mat-icon fontIcon="icon-header-grey-n3-24px-edit"></mat-icon>
      </button>
    </div>
    <div class="thread-update-properties-container" *ngIf="interlocutorsEditMode()">
      <app-contributor-select-multiple
        [interlocutors]="companyContributors"
        [contributors]="contributors"
        [contributorFormControl]="interlocutorsControl"
        label="Modifier les Interlocuteurs"
      ></app-contributor-select-multiple>
      <div class="interlocutors-edit-mode-actions">
        <button tiime-button color="accent" strocked (click)="setInterlocutorsEditMode(false)">Annuler</button>
        <button tiime-button color="accent" raised (click)="emitUpdateInterlocutors()">Enregistrer</button>
      </div>
    </div>
    <span class="thread-status" *ngIf="thread.closedAt && !thread.archivedAt">
      Clôturé le {{ thread.closedAt | date: 'dd/MM/YYYY à HH:mm' }} par {{ thread.closedBy?.fullName }}
    </span>
    <span class="thread-status" *ngIf="thread.archivedAt">
      Archivé le {{ thread.archivedAt | date: 'dd/MM/YYYY à HH:mm' }} par {{ thread.archivedBy?.fullName }}
    </span>
  </div>

  <div class="thread-messages" (click)="emitMessagesClicked()">
    <ng-container
      *ngFor="
        let message of thread.messages | map: mapToDisplayedMessages : expanded;
        let i = index;
        let even = even;
        trackBy: trackById
      "
    >
      <div class="expander alternate" *ngIf="thread.messages?.length > 2 && i === 1 && !expanded">
        <div class="expander-line"></div>
        <div class="expander-number">
          <span>+{{ thread.messages.length - 2 }}&nbsp;</span>
          <span [ngPlural]="thread.messages.length - 2">
            <ng-template ngPluralCase="=1">réponse</ng-template>
            <ng-template ngPluralCase="other">réponses</ng-template>
          </span>
        </div>
      </div>
      <app-message
        [class.alternate]="!expanded || even"
        [message]="message"
        [editDisabled]="thread.closedAt || thread.archivedAt || currentBusinessUser?.id !== message.createdBy?.id"
        [displaySlug]="expanded"
        [displayMe]="currentBusinessUser?.id === message.createdBy?.id"
        (updateMessageContent)="emitUpdateMessageContent(message, $event)"
      ></app-message>
    </ng-container>
  </div>

  <div class="thread-footer">
    <div class="thread-new-message" *ngIf="!thread.closedAt">
      <tiime-input-container class="message-textarea" contentId="message" label="Répondre">
        <textarea
          #textarea
          id="message"
          placeholder="Répondre"
          [formControl]="messageControl"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
        ></textarea>
      </tiime-input-container>
      <button class="message-action" tiime-button icon color="primary" raised (click)="emitCreateMessage()">
        <mat-icon fontIcon="icon-ic-invoice-send"></mat-icon>
      </button>
    </div>
  </div>
  <tiime-table-anchor></tiime-table-anchor>
</div>
