import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { SocialRegime } from '@models/social-regime';
import { FormFocusObserveDirective } from '@modules/shared/directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-social-regime-select',
  templateUrl: './social-regime-select.component.html',
  styleUrls: ['./social-regime-select.component.scss'],
  imports: [
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialRegimeSelectComponent extends FilteredSelectBase<SocialRegime> {
  @Input() label = 'Régime social';

  trackByCode(_index: number, socialRegime: SocialRegime): string {
    return socialRegime.code;
  }

  filterFunction(search: string): SocialRegime[] {
    return this.selectorData.filter(socialRegime =>
      StringUtils.normalizeToLowerCase(socialRegime.label).includes(StringUtils.normalizeToLowerCase(search))
    );
  }
}
