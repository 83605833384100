import { AsyncProcessType } from '@enums/async-process-type.enum';

export interface AccountingPeriodJson {
  id: number;
  closed: boolean;
  start_date: string;
  end_date: string;
  async_process_type: AsyncProcessType;
}

export class AccountingPeriod {
  public custom?: boolean;

  constructor(
    public id?: number,
    public closed?: boolean,
    public startDate?: string,
    public endDate?: string,
    public asyncProcessType?: AsyncProcessType
  ) {}

  static fromJson(json: Partial<AccountingPeriodJson>): AccountingPeriod {
    return new AccountingPeriod(json.id, json.closed, json.start_date, json.end_date, json.async_process_type);
  }

  static toJson(accountingPeriod: AccountingPeriod): AccountingPeriodJson {
    return {
      id: accountingPeriod.id,
      closed: accountingPeriod.closed,
      start_date: accountingPeriod.startDate,
      end_date: accountingPeriod.endDate,
      async_process_type: accountingPeriod.asyncProcessType
    };
  }
}
