import { FormRecord } from '@angular/forms';

import { VatDeclarationValueForm } from '@forms/vat-declaration-value-form';

export class VatDeclarationForm extends FormRecord<VatDeclarationValueForm> {
  constructor(controls: { [key: string]: VatDeclarationValueForm }) {
    super(controls);
  }

  getFormByCode(code: string): VatDeclarationValueForm {
    return this.get(code) as VatDeclarationValueForm;
  }

  resetControlsByColumn(columnCode: string): void {
    Object.entries(this.controls).forEach(([key, control]) => {
      if (key.endsWith(columnCode) && !control.formValue.disabled) {
        control.formValue.reset();
      }
    });
  }

  hasControlWithValueByColumn(columnCode: string): boolean {
    return Object.entries(this.controls).some(
      ([key, control]) =>
        key.endsWith(columnCode) && control.formValue.value !== null && control.formValue.value !== undefined
    );
  }
}
