import { MissionExitMotifFilter } from '@enums/mission-exit-motif-code.enum';
import { BusinessUnit } from '@models/business-unit';
import { Contributor } from '@models/contributor';

export enum CompaniesStatusFilterEnum {
  inCreationProcess = 'inCreationProcess',
  initializationInProgress = 'initializationInProgress',
  missionEnded = 'missionEnded',
  worksStopped = 'workStopped',
  active = 'active'
}

export type CompaniesStatusFilter = CompaniesStatusFilterEnum | 'all' | null;

export interface CompaniesFilters {
  taxRegimeCode: string[] | null;
  contributors: Contributor[];
  businessUnits: BusinessUnit[];
  status: CompaniesStatusFilter;
  missionExitMotif: MissionExitMotifFilter;
}
