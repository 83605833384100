import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY, COMPANY_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { DeclarationDownloadType } from '@enums/declaration-download-type.enum';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { HttpHelper } from '@helpers/http.helper';
import { FirstVatDeclarationJson } from '@interfaces/first-vat-declaration-json';
import { CerfaVersion, CerfaVersionJson } from '@models/cerfa-version';
import { Movement, MovementJson } from '@models/movement';
import {
  VatDeclarationConfiguration,
  VatDeclarationConfigurationJson,
  VatDeclarationConfigurationSerialized
} from '@models/vat-declaration-configuration';
import { VatDeclarationValue } from '@models/vat-declaration-value';
import { VatEmail } from '@models/vat-email';
import { FileSaverData } from '@services/file.service';

/**
 * Accounting Service.
 */
@Injectable({
  providedIn: 'root'
})
export class VatDeclarationApiService extends ApiCommonService<VatDeclarationConfiguration> {
  resourceUrl = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/vat_declarations`;

  fromJson(vatDeclarationJson: VatDeclarationConfigurationJson): VatDeclarationConfiguration {
    return VatDeclarationConfiguration.fromJson(vatDeclarationJson);
  }

  toJson(vatDeclaration: VatDeclarationConfiguration): VatDeclarationConfigurationSerialized {
    return VatDeclarationConfiguration.toJson(vatDeclaration);
  }

  @ApiAlertError()
  createVatDeclaration(): Observable<VatDeclarationConfiguration> {
    return super.create(null);
  }

  @ApiAlertError()
  getVatDeclarations(companyId?: number): Observable<VatDeclarationConfiguration[]> {
    let url = this.resourceUrl;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return super.getAll(null, url);
  }

  @ApiAlertError()
  getVatDeclaration(vatDeclarationId: number): Observable<VatDeclarationConfiguration> {
    return super.getById(vatDeclarationId);
  }

  @ApiAlertError()
  getVatDeposit(vatDeclarationId: number): Observable<VatDeclarationConfiguration[]> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/3514`;
    return this.http
      .get(url)
      .pipe(
        map((jsonArray: VatDeclarationConfigurationJson[]) =>
          jsonArray.map((json: VatDeclarationConfigurationJson) => this.fromJson(json))
        )
      );
  }

  @ApiAlertError()
  getAutocompletedVatDeclaration(vatDeclarationId: number): Observable<VatDeclarationValue[]> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/autocomplete`;
    return this.http
      .get(url)
      .pipe(
        map((valuesJson: any) =>
          valuesJson && valuesJson.length > 0
            ? valuesJson.map((valueJson: any) => VatDeclarationValue.fromJson(valueJson))
            : []
        )
      );
  }

  @ApiAlertError()
  updateOtherDeclaration(
    vatDeclarationId: number,
    otherDeclaration: VatDeclarationConfiguration
  ): Observable<VatDeclarationConfiguration> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/${otherDeclaration.cerfa}/${otherDeclaration.id}`;

    return this.http.put(url, this.toJson(otherDeclaration)).pipe(map((json: any) => this.fromJson(json)));
  }

  @ApiAlertError()
  updateVatDeclaration(
    vatDeclarationConfiguration: VatDeclarationConfiguration
  ): Observable<VatDeclarationConfiguration> {
    const url = `${this.resourceUrl}/${vatDeclarationConfiguration.id}`;

    return this.http.put(url, this.toJson(vatDeclarationConfiguration)).pipe(map((json: any) => this.fromJson(json)));
  }

  @ApiAlertError()
  updateVatDeclarationStatus(
    vatDeclarationId: number,
    vatDeclarationStatus: DeclarationStatus,
    companyId?: number
  ): Observable<VatDeclarationConfiguration> {
    let url = `${this.resourceUrl}/${vatDeclarationId}`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    const body = {
      status: vatDeclarationStatus
    };

    return this.http.patch(url, body).pipe(map((json: any) => this.fromJson(json)));
  }

  @ApiAlertError()
  updateVatDeclarationStatusWithCerfa3514(
    vatDeclarationId: number,
    parentDeclarationId: number,
    vatDeclarationStatus: DeclarationStatus,
    companyId?: number
  ): Observable<VatDeclarationConfiguration> {
    let url = `${this.resourceUrl}/${parentDeclarationId}/3514/${vatDeclarationId}`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    const body = {
      id: vatDeclarationId,
      status: vatDeclarationStatus
    };

    return this.http.patch<VatDeclarationConfigurationJson>(url, body).pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError()
  ignoreNextVatDeclarations(
    vatDeclarationConfiguration: VatDeclarationConfiguration
  ): Observable<VatDeclarationConfiguration> {
    const url = `${this.resourceUrl}/${vatDeclarationConfiguration.id}/ignore_next_vats`;

    return this.http.post(url, this.toJson(vatDeclarationConfiguration)).pipe(map((json: any) => this.fromJson(json)));
  }

  @ApiAlertError()
  getVatDeclarationFile(vatDeclarationId: number): Observable<Blob> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/pdf`;
    return this.http.post(url, { type: DeclarationDownloadType.vatDeclaration }, { responseType: 'blob' });
  }

  @ApiAlertError([403])
  downloadVatDeclaration(vatDeclarationId: number, type: DeclarationDownloadType): Observable<FileSaverData> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/pdf`;
    const blobObservable = this.http.post(
      url,
      { type },
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  downloadOtherVatDeclaration(
    vatDeclarationId: number,
    vatDeclarationType: string,
    otherDeclarationId: number,
    type: DeclarationDownloadType
  ): Observable<FileSaverData> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/${vatDeclarationType}/${otherDeclarationId}/pdf`;
    const blobObservable = this.http.post(
      url,
      { type },
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError([403])
  dowloadVatDeposit(vatDeclarationId: number): Observable<FileSaverData> {
    const url = `${COMPANY_API_ROUTE_BASE}/vat_deposits/${vatDeclarationId}/pdf`;
    const blobObservable = this.http.post(
      url,
      {},
      {
        responseType: 'blob',
        observe: 'response'
      }
    );

    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError([403])
  downloadVatLinkedMovements(vatDeclarationId: number): Observable<FileSaverData> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/fec`;
    const body = {
      type: 'fec_vat_declaration'
    };
    const blobObservable = this.http.post(url, body, {
      responseType: 'blob',
      observe: 'response'
    });
    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  configureFirstVatDeclaration(data: FirstVatDeclarationJson): Observable<VatDeclarationConfiguration | null> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/first_vat_declaration`;
    return this.http.put<VatDeclarationConfigurationJson>(url, data).pipe(
      map(json => {
        if (json) {
          return this.fromJson(json);
        }
        return null;
      })
    );
  }

  @ApiAlertError([403])
  getVatDeclarationEmailTemplate(vatDeclarationId: number): Observable<VatEmail> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/fiscal_declarations/${vatDeclarationId}/email_template`;
    return this.http.get(url).pipe(map((json: any) => VatEmail.fromJson(json)));
  }

  @ApiAlertError()
  sendVatDeclarationMail(vatDeclarationId: number, vatEmail: VatEmail): Observable<any> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/fiscal_declarations/${vatDeclarationId}/send`;
    return this.http.post(url, VatEmail.toJson(vatEmail));
  }

  // Pas besoin du décorateur @ApiAlertError, la gestion de l'erreur est faite côté composant
  getVatDeclarationEntriesPreview(vatDeclarationId: number, balanceDeviation: boolean): Observable<Movement[]> {
    const url = this.getGenerateEntriesUrl(vatDeclarationId);
    const params = HttpHelper.setParam('preview', 'true');

    return this.http
      .put<MovementJson[]>(url, { balance_deviation: balanceDeviation }, { params })
      .pipe(
        map((movementsJson: MovementJson[]) =>
          movementsJson && movementsJson.length > 0
            ? movementsJson.map((movementJson: MovementJson) => Movement.fromJson(movementJson))
            : []
        )
      );
  }

  @ApiAlertError()
  saveVatDeclarationEntries(vatDeclarationId: number, movements: Movement[]): Observable<Movement[]> {
    const url = this.getGenerateEntriesUrl(vatDeclarationId);
    const body: Partial<MovementJson>[] = movements.map((movement: Movement) => Movement.toJson(movement));

    return this.http
      .put<MovementJson[]>(url, body)
      .pipe(
        map((movementsJson: MovementJson[]) =>
          movementsJson && movementsJson.length > 0
            ? movementsJson.map((movementJson: MovementJson) => Movement.fromJson(movementJson))
            : []
        )
      );
  }

  @ApiAlertError()
  downloadMovementsToDeclareExport(vatDeclarationId: number, companyId?: number): Observable<FileSaverData> {
    let url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/vat_declarations/${vatDeclarationId}/movements_to_declare/export`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    const blobObservable = this.http.post(
      url,
      {},
      {
        responseType: 'blob',
        observe: 'response'
      }
    );

    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  reset(vatDeclarationId: number): Observable<VatDeclarationConfiguration> {
    const url = `${this.resourceUrl}/${vatDeclarationId}/reset`;
    return this.http.post<VatDeclarationConfigurationJson>(url, {}).pipe(map(result => this.fromJson(result)));
  }

  @ApiAlertError()
  getCerfaCurrentVersions(): Observable<CerfaVersion[]> {
    const url = `api/v1/expert/cerfa_versions`;
    return this.http.get<CerfaVersionJson[]>(url).pipe(map(jsons => jsons.map(json => CerfaVersion.fromJson(json))));
  }

  @ApiAlertError()
  updateVatPeriod(
    vatDeclarationId: number,
    startDate: string,
    endDate: string
  ): Observable<VatDeclarationConfiguration> {
    const url = `${this.resourceUrl}/${vatDeclarationId}`;

    return this.http
      .patch<VatDeclarationConfigurationJson>(url, {
        start_date: startDate,
        end_date: endDate
      })
      .pipe(map(json => this.fromJson(json)));
  }

  @ApiAlertError()
  updateConsistencyChecks(vatDeclarationId: number, companyId?: number): Observable<VatDeclarationConfiguration> {
    let url = `${this.resourceUrl}/${vatDeclarationId}/update_consistency_check`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<VatDeclarationConfigurationJson>(url, null).pipe(map(json => this.fromJson(json)));
  }

  private getGenerateEntriesUrl(vatDeclarationId: number): string {
    return `${this.resourceUrl}/${vatDeclarationId}/generate_entries`;
  }
}
