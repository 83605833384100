import { VatDeclarationValueCondition } from '@enums/vat-declaration-value-condition.enum';
import { VatDeclarationValueType } from '@enums/vat-declaration-value-type.enum';

export interface VatDeclarationValueJson {
  type: VatDeclarationValueType;
  code: string;
  calc: string;
  hidden: string;
  validator: string;
  disable: string;
  disabled: boolean;
  condition: VatDeclarationValueCondition;
  maxlength: number;
  value: number | string | boolean;
  metadata?: Record<string, unknown>;
  required?: boolean;
  source_id?: number;
  source_type?: string;
}

export class VatDeclarationValue {
  constructor(
    public type?: VatDeclarationValueType,
    public code?: string,
    public calc?: string,
    public hidden?: string,
    public validator?: string,
    public disable?: string,
    public disabled?: boolean,
    public condition?: VatDeclarationValueCondition,
    public maxlength?: number,
    public value?: number | string | boolean,
    public metadata?: Record<string, unknown>,
    public required?: boolean,
    public sourceId?: number,
    public sourceType?: string
  ) {}

  static fromJson(json: Partial<VatDeclarationValueJson>): VatDeclarationValue {
    return new VatDeclarationValue(
      json.type,
      json.code,
      json.calc,
      json.hidden,
      json.validator,
      json.disable,
      json.disabled,
      json.condition,
      json.maxlength,
      json.value,
      json.metadata,
      json.required,
      json.source_id,
      json.source_type
    );
  }

  static toJson(vatDeclarationValue: VatDeclarationValue): VatDeclarationValueJson {
    return {
      type: vatDeclarationValue.type,
      code: vatDeclarationValue.code,
      calc: vatDeclarationValue.calc,
      hidden: vatDeclarationValue.hidden,
      validator: vatDeclarationValue.validator,
      disable: vatDeclarationValue.disable,
      disabled: vatDeclarationValue.disabled,
      condition: vatDeclarationValue.condition,
      maxlength: vatDeclarationValue.maxlength,
      value: vatDeclarationValue.value,
      metadata: vatDeclarationValue.metadata,
      required: vatDeclarationValue.required,
      source_id: vatDeclarationValue.sourceId,
      source_type: vatDeclarationValue.sourceType
    };
  }
}
