import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

import { LocalStorageService } from 'tiime-expert-utils';

import { Company } from '@models/company';

@Injectable({
  providedIn: 'root'
})
export class SelectedCompanyHistoryService extends LocalStorageService {
  private selectedCompanyHistorySubject: ReplaySubject<Company[]> = new ReplaySubject(1);

  private readonly size = 3;

  constructor() {
    super('selected-company-history');
    this.selectedCompanyHistorySubject.next(this.retrieve());
  }

  getSelectedCompanyHistory(): Observable<Company[]> {
    return this.selectedCompanyHistorySubject.asObservable();
  }

  addToSelection(company: Company): void {
    const state = this.retrieve();
    if (!state) {
      return this.store([company]);
    }
    // La company est déjà dans l'historique, on doit simplement la remettre au début du tableau
    if (!!state.find(({ id }) => id === company.id)) {
      return this.store([company, ...state.filter(({ id }) => id !== company.id)]);
    }

    if (state.length < this.size) {
      return this.store([company, ...state]);
    }
    return this.store([company, ...state.slice(0, this.size - 1)]);
  }

  clear(): void {
    this.store([]);
  }

  updateExistingCompany(company: Company): void {
    const existing = this.retrieve();
    const index = existing.findIndex(({ id }) => id === company.id);
    if (index === -1) {
      return;
    }
    existing[index] = company;
    this.store(existing);
  }

  override store(value: Company[]): void {
    super.store(value);
    this.selectedCompanyHistorySubject.next(value);
  }

  override retrieve(): Company[] {
    return (super.retrieve() as Company[]) ?? [];
  }
}
