import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { Observable } from 'rxjs';

import {
  DatepickerHeaderComponent,
  TiimeDatepickerModule,
  TiimeFormModule,
  TiimeIconModule,
  TiimeInputContainerModule
} from 'tiime-material';

import { CommonDataApiService } from '@api-services/common-data-api.service';
import { LegalInformationForm } from '@forms/user/legal-information-form';
import { Country } from '@models/country';
import { Department } from '@models/department';
import { MaritalStatus } from '@models/marital-status';
import { CountrySelectComponent } from '@shared-selectors/country-select/country-select.component';
import { DepartmentSelectComponent } from '@shared-selectors/department-select/department-select.component';
import { MaritalStatusSelectComponent } from '@shared-selectors/marital-status-select/marital-status-select.component';

@Component({
  selector: 'app-legal-information-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeDatepickerModule,
    TiimeIconModule,
    MatOptionModule,
    CountrySelectComponent,
    DepartmentSelectComponent,
    MaritalStatusSelectComponent
  ],
  templateUrl: './legal-information-form.component.html',
  styleUrls: ['./legal-information-form.component.scss']
})
export class LegalInformationFormComponent {
  @Input() legalInformationForm: LegalInformationForm;
  @Input() tiimeFormExpanded: boolean;

  readonly countries$: Observable<Country[]> = this.commonDataApiService.getCountries();
  readonly currentDate = new Date();
  readonly departments$: Observable<Department[]> = this.commonDataApiService.getDepartments();
  readonly maritalStatuses$: Observable<MaritalStatus[]> = this.commonDataApiService.getMaritalStatuses();
  readonly tiimeDatepickerHeader = DatepickerHeaderComponent;

  constructor(private commonDataApiService: CommonDataApiService) {}
}
