import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Mapper, TiimePipesModule } from 'tiime-expert-utils';
import { TiimeTooltipModule } from 'tiime-material';

import { User } from '@models/user';
import { PhonePipe } from '@modules/shared/pipes/phone/phone.pipe';

@Component({
  selector: 'app-company-contacts',
  standalone: true,
  imports: [CommonModule, TiimeTooltipModule, TiimePipesModule, PhonePipe],
  templateUrl: './company-contacts.component.html',
  styleUrls: ['./company-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyContactsComponent {
  @Input() users: User[];
  @Input() displayPhone = false;

  readonly mapToOtherUsersFullName: Mapper<User[], string> = (users: User[], displayPhone: boolean) => {
    const otherUsers: User[] = [...users];
    otherUsers.shift();

    return otherUsers
      .map(user => `${user.fullName}${displayPhone && user.phone ? ` (${new PhonePipe().transform(user.phone)})` : ''}`)
      .join(', ');
  };
}
