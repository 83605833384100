import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable } from 'rxjs';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY, GI_API_ROUTE_BASE } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { FiltersParams, HttpHelper } from '@helpers/http.helper';
import { Prospect, ProspectJson } from '@models/prospect';

/**
 * Prospect Api Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ProspectApiService extends ApiCommonService<Prospect> {
  resourceUrl = `${GI_API_ROUTE_BASE}/prospects`;
  companyResourceUrl = `${GI_API_ROUTE_BASE}/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/prospects`;

  fromJson(json: Partial<ProspectJson>): Prospect {
    return Prospect.fromJson(json);
  }

  toJson(model: Prospect): Partial<ProspectJson> {
    return Prospect.toJson(model);
  }

  @ApiAlertError()
  getProspects(
    range: PaginationRange,
    searchTerms: string,
    sort: Sort,
    filters: FiltersParams
  ): Observable<PaginationData<Prospect>> {
    let params = HttpHelper.setQParam(searchTerms);
    params = HttpHelper.setSortParam(sort, params);

    if (filters) {
      params = HttpHelper.setParams(filters, params, false);
    }

    return this.getPaginated(range, params);
  }

  @ApiAlertError()
  createProspect(prospect: Prospect): Observable<Prospect> {
    return this.create(prospect);
  }

  @ApiAlertError()
  updateProspect(prospect: Prospect): Observable<Prospect> {
    return this.update(prospect.id, prospect);
  }

  @ApiAlertError()
  updateProspectInternalComment(prospectId: number, comment: string): Observable<Prospect> {
    const body: Partial<ProspectJson> = {
      id: prospectId,
      internal_comment: comment || null
    };

    return this.updatePartial(prospectId, body);
  }

  @ApiAlertError()
  linkToCompany(prospectId: number, companyId: number, contactId: number): Observable<void> {
    const url = `${this.resourceUrl}/${prospectId}/companies/${companyId}/link`;
    const body = {
      contact_id: contactId
    };

    return this.http.post<void>(url, body);
  }

  @ApiAlertError()
  getCompanyProspects(range: PaginationRange, searchTerms: string, sort: Sort): Observable<PaginationData<Prospect>> {
    let params = HttpHelper.setQParam(searchTerms);
    params = HttpHelper.setSortParam(sort, params);

    return this.getPaginated(range, params, this.companyResourceUrl);
  }
}
