<tiime-input-container tiimeFormInputContainer [label]="label" contentId="departmentSelector">
  <tiime-select
    id="departmentSelector"
    [class.selected]="control.value"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
  >
    <input
      tiimeSelectInput
      placeholder="Rechercher un département"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let department of filteredSelectorData; trackBy: trackByCode" [value]="department.code">
        {{ department.code }} - {{ department.name }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
