import { ValidationSource } from '@enums/validation-source';
import { DateHelper } from '@helpers/date.helper';
import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { PieceFile, PieceFileJson } from '@models/piece-file';
import { User, UserJson } from '@models/user';

export interface BalanceJson {
  id: number;
  debit: number;
  credit: number;
  accounting_period: Partial<AccountingPeriodJson>;
  pcg_entry: Partial<PcgEntryJson>;
  comment: string;
  count: number;
  previous_debit: number;
  previous_credit: number;
  validated: boolean;
  validated_at: string;
  validated_by: Partial<UserJson>;
  validated_source: ValidationSource;
  amount_evolution: number;
  percent_evolution: number;
  balance_files?: PieceFileJson[];
}

export class Balance {
  constructor(
    public id?: number,
    public debit?: number,
    public credit?: number,
    public accountingPeriod?: AccountingPeriod,
    public pcgEntry?: PcgEntry,
    public comment?: string,
    public countMovements?: number,
    public previousDebit?: number,
    public previousCredit?: number,
    public validated?: boolean,
    public validatedAt?: Date,
    public validatedBy?: User,
    public validatedSource?: ValidationSource,
    public amountEvolution?: number,
    public percentEvolution?: number,
    public files?: PieceFile[]
  ) {}

  static fromJson(json: Partial<BalanceJson>): Balance {
    return new Balance(
      json.id,
      json.debit,
      json.credit,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.comment,
      json.count,
      json.previous_debit,
      json.previous_credit,
      json.validated,
      json.validated_at ? DateHelper.fromUtc(json.validated_at) : null,
      json.validated_by ? User.fromJson(json.validated_by) : null,
      json.validated_source,
      json.amount_evolution,
      json.percent_evolution,
      json.balance_files ? json.balance_files.map((file: PieceFileJson) => PieceFile.fromJson(file)) : []
    );
  }

  static toJson(balance: Balance): Pick<BalanceJson, 'id' | 'comment' | 'validated'> {
    return {
      id: balance.id,
      comment: balance.comment || null,
      validated: balance.validated
    };
  }
}
