import { Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { InputMaskModule } from '@ngneat/input-mask';
import { Observable } from 'rxjs';

import { TIIME_DATE_FORMATS } from 'tiime-material';

import { DATE_RELATIVE_TIME_LABEL, INVALID_DATE_LABEL } from '@constants/date-label.constant';
import { DateHelper } from '@helpers/date.helper';
import { Environment } from '@interfaces/environment';
import { AppConfigService } from '@services/app-config.service';
import { CompanyThreadsComponent } from '@shared-components/company-threads/company-threads.component';
import { PageLoaderComponent } from '@shared-components/page-loader/page-loader.component';
import { SidebarComponent } from '@shared-components/sidebar/sidebar.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

registerLocaleData(localeFr, localeFrExtra);
DateHelper.updateLocale('fr', {
  relativeTime: DATE_RELATIVE_TIME_LABEL,
  invalidDate: INVALID_DATE_LABEL
});

function scrollFactory(overlay: Overlay): () => RepositionScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MomentDateModule,
    CoreModule,
    AppRoutingModule,
    InputMaskModule,
    CompanyThreadsComponent,
    PageLoaderComponent,
    SidebarComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => (): Observable<Environment> =>
        appConfigService.loadEnvironment(),
      deps: [AppConfigService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
