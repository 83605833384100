import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { NgUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { LabelsPlan } from '@models/labels-plan';

@Component({
  standalone: true,
  selector: 'app-label-plan-select',
  templateUrl: './label-plan-select.component.html',
  styleUrls: ['./label-plan-select.component.scss'],
  imports: [
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelPlanSelectComponent {
  @Input() ctrl: FormControl<number | null>;
  @Input() labelsPlans: LabelsPlan[];
  @Input() emptyOption = true;
  @Input() emptyOptionDisabled = false;
  @Input() emptyOptionLabel = '';
  @Input() label = 'Plan de labels';
  @Input() errorMessage: string;
  @Input() required: boolean;

  readonly trackById = NgUtils.trackById;
}
