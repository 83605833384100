import { Injectable } from '@angular/core';

import { LocalStorageService } from 'tiime-expert-utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationsTokenService extends LocalStorageService {
  constructor() {
    super('notifications-token');
  }
}
