import type { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// https://github.com/sindresorhus/type-fest/blob/f45033c5449652143261cbeaba6f4eff3f73c2a0/source/basic.d.ts
/**
 Matches a [`class`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes).
 *
 */
type Class<T, Arguments extends unknown[] = any[]> = Constructor<T, Arguments> & { prototype: T };

/**
 Matches a [`class` constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes).
 *
 */
type Constructor<T, Arguments extends unknown[] = any[]> = new (...arguments_: Arguments) => T;

interface DialogServiceBaseInterface<T, R> {
  dialog: MatDialog;
  dialogRef?: MatDialogRef<T, R>;
  afterClosed(): Observable<R>;
  beforeClosed(): Observable<R>;
  open(config?: MatDialogConfig): this;
}

@Injectable()
export abstract class DialogServiceBase<T, R = any> implements DialogServiceBaseInterface<T, R> {
  dialogRef: MatDialogRef<T, R>;

  constructor(public dialog: MatDialog) {}

  afterClosed(): Observable<R> {
    return this.dialogRef.afterClosed().pipe(take(1));
  }
  beforeClosed(): Observable<R> {
    return this.dialogRef.beforeClosed().pipe(take(1));
  }

  abstract open(config?: MatDialogConfig): this;
}

/**
 * Mixin pour créer rapidement un DialogService
 *
 * @example
 * class MyDialogService extends createDialogServiceBase<MyResultType, MyConfigType, MyComponent>(MyComponent) {}
 */
export function createDialogServiceBase<TResult = any, TConfig = any, TComponent = any>(
  component: ComponentType<TComponent>
): Class<DialogServiceBaseInterface<TComponent, TResult>, [MatDialog]> {
  return class extends DialogServiceBase<TComponent, TResult> {
    override open(config?: MatDialogConfig<TConfig>): this {
      this.dialogRef = this.dialog.open(component, config);
      return this;
    }
  };
}
