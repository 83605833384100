<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="cancel()">
  <span mat-dialog-title>Période personnalisée</span>
  <mat-dialog-content mat-dialog-content>
    <p>
      La période personnalisée vous permet de visualiser la balance, le grand livre, et les journaux à une date de fin
      de vision spécifique, autre que la date de fin de l’exercice comptable.
      <br />
      <br />
      Vous avez également la possibilité d’effectuer les éditions comptables sur une période personnalisée.
    </p>
    <div tiimeFormColumn>
      <tiime-input-container
        contentId="startDate"
        label="Date de début de la période personnalisée"
        [errorMessage]="customPeriodForm.accountingPeriodIdErrorMessage"
      >
        <tiime-select id="startDate" class="selected" [formControl]="customPeriodForm.accountingPeriodId">
          <div tiimeSelectOptions>
            <mat-option
              *ngFor="let accountingPeriod of accountingPeriods$ | async; trackBy: trackById"
              [value]="accountingPeriod.id"
            >
              {{ accountingPeriod.startDate | date: 'shortDate' }}
            </mat-option>
          </div>
        </tiime-select>
      </tiime-input-container>
      <tiime-input-container
        contentId="endDate"
        label="Date de fin de la période personnalisée"
        [errorMessage]="customPeriodForm.endDateErrorMessage"
        *ngIf="customPeriodForm.accountingPeriodId.value"
      >
        <input
          id="endDate"
          placeholder="Date de fin de la période personnalisée"
          type="text"
          [min]="customPeriodForm.accountingPeriodId.value | map: mapToMinDate:(accountingPeriods$ | async)"
          [max]="customPeriodForm.accountingPeriodId.value | map: mapToMaxDate:(accountingPeriods$ | async)"
          [formControl]="customPeriodForm.endDate"
          [matDatepicker]="customAccountingPeriodEndDatePicker"
          autocomplete="off"
        />
        <mat-datepicker panelClass="tiime-material-datepicker" #customAccountingPeriodEndDatePicker></mat-datepicker>
      </tiime-input-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [disabled]="isLoading" type="button" tiime-button color="accent" strocked large (click)="cancel()">
      Annuler
    </button>
    <button [disabled]="isLoading" type="submit" tiime-button color="accent" large raised (click)="validate()">
      Valider
    </button>
  </mat-dialog-actions>
</tiime-dialog>
