import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { NgUtils } from 'tiime-expert-utils';
import {
  TIIME_OVERLAY_DATA,
  TiimeButtonModule,
  TiimeIconModule,
  TiimeOverlayRef,
  TiimeTooltipModule
} from 'tiime-material';

import { AppStoreState } from '@interfaces/app-store-state';
import { Notification } from '@models/notification';
import * as NotificationsActions from '@modules/core/store/notifications/notifications-actions';
import { NotificationComponent } from '@shared-components/notification/notification.component';

@Component({
  selector: 'app-notification-overlay',
  standalone: true,
  imports: [CommonModule, TiimeButtonModule, TiimeIconModule, NotificationComponent, TiimeTooltipModule],
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'onClick($event)'
  }
})
export class NotificationOverlayComponent {
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(
    @Inject(TIIME_OVERLAY_DATA) public notification: Notification,
    private overlayRef: TiimeOverlayRef,
    private store: Store<AppStoreState>
  ) {}

  close(): void {
    this.overlayRef.close();
  }

  onClick(): void {
    const clickAction = this.notification.clickAction;
    if (!clickAction) {
      return;
    }

    this.overlayRef.close(true);
  }

  downloadFile(notification: Notification): void {
    this.store.dispatch(NotificationsActions.downloadFile({ notification }));
    this.onClick();
  }
}
