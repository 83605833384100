import { MaritalStatusCode } from '@enums/marital-status-code.enum';
import { UserCapacity } from '@enums/user-capacity.enum';
import { UserType } from '@enums/user-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';

import { LegalPersonBase } from './legal-person-base';
import { HealthInsuranceOrganizationEnum } from '../enums/health-insurance-organization.enum';
import { SimultaneousActivityStatusExerciceEnum } from '../enums/simultaneous-activity-statusexercice.enum';
import { SpecialAffiliationPamEnum } from '../enums/special-affiliation-pam.enum';

export interface LegalPhysicalPersonJson {
  id: number;
  civility: string;
  firstname: string;
  lastname: string;
  director: boolean;
  beneficiary: boolean;
  salary: boolean;
  capacity: UserCapacity;
  email: string;
  phone: string;
  marital_status: MaritalStatusCode;
  founds_origin: string;
  birth_name: string;
  birth_date: string;
  nationality: string;
  birth_city: string;
  birth_postal_code: string;
  birth_country: string;
  address: string;
  city: string;
  postal_code: string;
  country: string;
  spouse_cannot_shareholder: boolean;
  spouse_is_subscriber: boolean;
  spouse_civility: string;
  spouse_last_name: string;
  spouse_first_name: string;
  spouse_id: number;
  mother_maiden_name: string;
  mother_first_name: string;
  father_last_name: string;
  father_first_name: string;
  cerfa_donor_fullname: string;
  cerfa_registration_date: string;
  cerfa_signing_date: string;
  cerfa_tax_city: string;
  notary_fullname: string;
  notary_city: string;
  notary_act_date: string;
  self_employed_status: boolean;
  dismissal_self_employed_date: string;
  acre: boolean;
  arce: boolean;
  job_center_registration_date: string;
  job_center_registration_end_date: string;
  self_employed_worker: boolean;
  self_employed_worker_other_company: boolean;
  social_nomination_other_company: string;
  siret_other_company: string;
  simultaneous_activity: boolean;
  social_security_number: string;
  capital_contribution: number;
  last_invitation_date: string;
  shares_number: number;
  shares_percentage: number;
  health_insurance_organization: HealthInsuranceOrganizationEnum;
  anterior_activity: boolean;
  anterior_activity_performed: string;
  simultaneous_activity_status_exercice: SimultaneousActivityStatusExerciceEnum;
  simultaneous_activity_other: string;
  anterior_activity_end_date: string;
  anterior_activity_country: string;
  special_affiliation: boolean;
  special_affiliation_pam: SpecialAffiliationPamEnum;
}

export class LegalPhysicalPerson extends LegalPersonBase {
  constructor(
    public id: number,
    public capacity: UserCapacity,
    public director: boolean,
    public beneficiary: boolean,
    public capitalContribution: number,
    public sharesNumber: number,
    public sharesPercentage: number,
    public civility: string,
    public firstname: string,
    public lastname: string,
    public salary: boolean,
    public email: string,
    public phone: string,
    public maritalStatus: MaritalStatusCode,
    public foundsOrigin: string,
    public birthName: string,
    public birthDate: Date,
    public nationality: string,
    public birthCity: string,
    public birthPostalCode: string,
    public birthCountry: string,
    public address: string,
    public city: string,
    public postalCode: string,
    public country: string,
    public spouseCannotShareholder: boolean,
    public spouseIsSubscriber: boolean,
    public spouseCivility: string,
    public spouseLastName: string,
    public spouseFirstName: string,
    public spouseId: number,
    public motherMaidenName: string,
    public motherFirstName: string,
    public fatherLastName: string,
    public fatherFirstName: string,
    public cerfaDonorFullname: string,
    public cerfaRegistrationDate: Date,
    public cerfaSigningDate: Date,
    public cerfaTaxCity: string,
    public notaryFullname: string,
    public notaryCity: string,
    public notaryActDate: Date,
    public selfEmployedStatus: boolean,
    public dismissalSelfEmployedDate: Date,
    public acre: boolean,
    public arce: boolean,
    public jobCenterRegistrationDate: Date,
    public jobCenterRegistrationEndDate: Date,
    public selfEmployedWorker: boolean,
    public selfEmployedWorkerOtherCompany: boolean,
    public socialNominationOtherCompany: string,
    public siretOtherCompany: string,
    public simultaneousActivity: boolean,
    public socialSecurityNumber: string,
    public lastInvitationDate?: Date,
    public healthInsuranceOrganization?: HealthInsuranceOrganizationEnum,
    public anteriorActivity?: boolean,
    public anteriorActivityPerformed?: string,
    public simultaneousActivityStatusExercice?: SimultaneousActivityStatusExerciceEnum,
    public simultaneousActivityOther?: string,
    public anteriorActivityEndDate?: Date,
    public anteriorActivityCountry?: string,
    public specialAffiliation?: boolean,
    public specialAffiliationPam?: SpecialAffiliationPamEnum
  ) {
    super(id, capacity, director, beneficiary, capitalContribution, sharesNumber, sharesPercentage);

    this.type = UserType.user;
  }

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  static fromJson(json: Partial<LegalPhysicalPersonJson>): LegalPhysicalPerson {
    return new LegalPhysicalPerson(
      json.id,
      json.capacity,
      json.director,
      json.beneficiary,
      json.capital_contribution,
      json.shares_number,
      json.shares_percentage,
      json.civility,
      json.firstname,
      json.lastname,
      json.salary,
      json.email,
      json.phone,
      json.marital_status,
      json.founds_origin,
      json.birth_name,
      json.birth_date ? DateHelper.fromUtc(json.birth_date) : null,
      json.nationality,
      json.birth_city,
      json.birth_postal_code,
      json.birth_country,
      json.address,
      json.city,
      json.postal_code,
      json.country,
      json.spouse_cannot_shareholder,
      json.spouse_is_subscriber,
      json.spouse_civility,
      json.spouse_last_name,
      json.spouse_first_name,
      json.spouse_id,
      json.mother_maiden_name,
      json.mother_first_name,
      json.father_last_name,
      json.father_first_name,
      json.cerfa_donor_fullname,
      json.cerfa_registration_date ? DateHelper.fromUtc(json.cerfa_registration_date) : null,
      json.cerfa_signing_date ? DateHelper.fromUtc(json.cerfa_signing_date) : null,
      json.cerfa_tax_city,
      json.notary_fullname,
      json.notary_city,
      json.notary_act_date ? DateHelper.fromUtc(json.notary_act_date) : null,
      json.self_employed_status,
      json.dismissal_self_employed_date ? DateHelper.fromUtc(json.dismissal_self_employed_date) : null,
      json.acre,
      json.arce,
      json.job_center_registration_date ? DateHelper.fromUtc(json.job_center_registration_date) : null,
      json.job_center_registration_end_date ? DateHelper.fromUtc(json.job_center_registration_end_date) : null,
      json.self_employed_worker,
      json.self_employed_worker_other_company,
      json.social_nomination_other_company,
      json.siret_other_company,
      json.simultaneous_activity,
      json.social_security_number,
      json.last_invitation_date ? DateHelper.fromUtc(json.last_invitation_date) : null,
      json.health_insurance_organization,
      json.anterior_activity,
      json.anterior_activity_performed,
      json.simultaneous_activity_status_exercice,
      json.simultaneous_activity_other,
      json.anterior_activity_end_date ? DateHelper.fromUtc(json.anterior_activity_end_date) : null,
      json.anterior_activity_country,
      json.special_affiliation,
      json.special_affiliation_pam
    );
  }
  static toJson(physicalPerson: LegalPhysicalPerson): Partial<LegalPhysicalPersonJson> {
    return {
      id: HttpHelper.toJsonProperty(physicalPerson.id),
      civility: HttpHelper.toJsonProperty(physicalPerson.civility),
      firstname: HttpHelper.toJsonProperty(physicalPerson.firstname),
      lastname: HttpHelper.toJsonProperty(physicalPerson.lastname),
      director: !!physicalPerson.director,
      beneficiary: !!physicalPerson.beneficiary,
      salary: !!physicalPerson.salary,
      capacity: HttpHelper.toJsonProperty(physicalPerson.capacity),
      email: HttpHelper.toJsonProperty(physicalPerson.email),
      phone: HttpHelper.toJsonProperty(physicalPerson.phone),
      marital_status: HttpHelper.toJsonProperty(physicalPerson.maritalStatus),
      founds_origin: HttpHelper.toJsonProperty(physicalPerson.foundsOrigin),
      birth_name: HttpHelper.toJsonProperty(physicalPerson.birthName),
      birth_date: physicalPerson.birthDate ? DateHelper.format(physicalPerson.birthDate) : null,
      nationality: HttpHelper.toJsonProperty(physicalPerson.nationality),
      birth_city: HttpHelper.toJsonProperty(physicalPerson.birthCity),
      birth_postal_code: HttpHelper.toJsonProperty(physicalPerson.birthPostalCode),
      birth_country: HttpHelper.toJsonProperty(physicalPerson.birthCountry),
      address: HttpHelper.toJsonProperty(physicalPerson.address),
      city: HttpHelper.toJsonProperty(physicalPerson.city),
      postal_code: HttpHelper.toJsonProperty(physicalPerson.postalCode),
      country: HttpHelper.toJsonProperty(physicalPerson.country),
      spouse_cannot_shareholder: !!physicalPerson.spouseCannotShareholder,
      spouse_is_subscriber: !!physicalPerson.spouseIsSubscriber,
      spouse_civility: HttpHelper.toJsonProperty(physicalPerson.spouseCivility),
      spouse_last_name: HttpHelper.toJsonProperty(physicalPerson.spouseLastName),
      spouse_first_name: HttpHelper.toJsonProperty(physicalPerson.spouseFirstName),
      spouse_id: HttpHelper.toJsonProperty(physicalPerson.spouseId),
      mother_maiden_name: HttpHelper.toJsonProperty(physicalPerson.motherMaidenName),
      mother_first_name: HttpHelper.toJsonProperty(physicalPerson.motherFirstName),
      father_last_name: HttpHelper.toJsonProperty(physicalPerson.fatherLastName),
      father_first_name: HttpHelper.toJsonProperty(physicalPerson.fatherFirstName),
      cerfa_donor_fullname: HttpHelper.toJsonProperty(physicalPerson.cerfaDonorFullname),
      cerfa_registration_date: physicalPerson.cerfaRegistrationDate
        ? DateHelper.format(physicalPerson.cerfaRegistrationDate)
        : null,
      cerfa_signing_date: physicalPerson.cerfaSigningDate ? DateHelper.format(physicalPerson.cerfaSigningDate) : null,
      cerfa_tax_city: HttpHelper.toJsonProperty(physicalPerson.cerfaTaxCity),
      notary_fullname: HttpHelper.toJsonProperty(physicalPerson.notaryFullname),
      notary_city: HttpHelper.toJsonProperty(physicalPerson.notaryCity),
      notary_act_date: physicalPerson.notaryActDate ? DateHelper.format(physicalPerson.notaryActDate) : null,
      self_employed_status: HttpHelper.toJsonProperty(physicalPerson.selfEmployedStatus),
      dismissal_self_employed_date: physicalPerson.dismissalSelfEmployedDate
        ? DateHelper.format(physicalPerson.dismissalSelfEmployedDate)
        : null,
      acre: HttpHelper.toJsonProperty(physicalPerson.acre),
      arce: HttpHelper.toJsonProperty(physicalPerson.arce),
      job_center_registration_date: physicalPerson.jobCenterRegistrationDate
        ? DateHelper.format(physicalPerson.jobCenterRegistrationDate)
        : null,
      job_center_registration_end_date: physicalPerson.jobCenterRegistrationEndDate
        ? DateHelper.format(physicalPerson.jobCenterRegistrationEndDate)
        : null,
      self_employed_worker: !!physicalPerson.selfEmployedWorker,
      self_employed_worker_other_company: HttpHelper.toJsonProperty(physicalPerson.selfEmployedWorkerOtherCompany),
      social_nomination_other_company: HttpHelper.toJsonProperty(physicalPerson.socialNominationOtherCompany),
      siret_other_company: HttpHelper.toJsonProperty(physicalPerson.siretOtherCompany),
      simultaneous_activity: HttpHelper.toJsonProperty(physicalPerson.simultaneousActivity),
      social_security_number: HttpHelper.toJsonProperty(physicalPerson.socialSecurityNumber),
      capital_contribution: HttpHelper.toJsonProperty(physicalPerson.capitalContribution),
      shares_number: HttpHelper.toJsonProperty(physicalPerson.sharesNumber),
      shares_percentage: HttpHelper.toJsonProperty(physicalPerson.sharesPercentage),
      health_insurance_organization: HttpHelper.toJsonProperty(physicalPerson.healthInsuranceOrganization),
      anterior_activity: HttpHelper.toJsonProperty(physicalPerson.anteriorActivity),
      anterior_activity_performed: HttpHelper.toJsonProperty(physicalPerson.anteriorActivityPerformed),
      simultaneous_activity_status_exercice: HttpHelper.toJsonProperty(
        physicalPerson.simultaneousActivityStatusExercice
      ),
      simultaneous_activity_other: HttpHelper.toJsonProperty(physicalPerson.simultaneousActivityOther),
      anterior_activity_end_date: physicalPerson.anteriorActivityEndDate
        ? DateHelper.format(physicalPerson.anteriorActivityEndDate)
        : null,
      anterior_activity_country: HttpHelper.toJsonProperty(physicalPerson.anteriorActivityCountry),
      special_affiliation: HttpHelper.toJsonProperty(physicalPerson.specialAffiliation),
      special_affiliation_pam: HttpHelper.toJsonProperty(physicalPerson.specialAffiliationPam)
    };
  }
}
