import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ErrorCode } from '@constants/error.constant';
import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';

export type OwnershipFormType = {
  capitalContribution: FormControl<number | null>;
  sharesNumber: FormControl<number | null>;
  entryDate: FormControl<string | null>;
  exitDate: FormControl<string | null>;
  sharesBareOwnershipNumber: FormControl<number | null>;
  sharesUsufructNumber: FormControl<number | null>;
};

export class OwnershipForm extends FormGroup<OwnershipFormType> {
  get capitalContribution(): FormControl<number | null> {
    return this.controls.capitalContribution;
  }

  get capitalContributionErrorMessage(): ErrorMessage | null {
    return FormHelper.getMinErrorMessage(
      this.capitalContribution,
      'Le capital souscrit doit être supérieur ou égal à #value#'
    );
  }

  get sharesNumber(): FormControl<number | null> {
    return this.controls.sharesNumber;
  }

  get sharesNumberErrorMessage(): ErrorMessage {
    return FormHelper.getMinErrorMessage(this.sharesNumber, 'Le nombre de parts doit être supérieur ou égal à #value#');
  }

  get entryDate(): FormControl<string | null> {
    return this.controls.entryDate;
  }

  get exitDate(): FormControl<string | null> {
    return this.controls.exitDate;
  }

  get exitDateErrorMessage(): ErrorMessage {
    return FormHelper.getDatePickerErrorMessage(this.exitDate, {
      checkMin: true,
      minError: ErrorCode.INVALID,
      minErrorMessage: `La date de sortie doit être postérieure à la date d'entrée`
    });
  }

  get sharesBareOwnershipNumber(): FormControl<number | null> {
    return this.controls.sharesBareOwnershipNumber;
  }

  get sharesBareOwnershipNumberErrorMessage(): ErrorMessage {
    return FormHelper.getMinErrorMessage(
      this.sharesBareOwnershipNumber,
      'Le nombre de parts doit être supérieur ou égal à #value#'
    );
  }

  get sharesUsufructNumber(): FormControl<number | null> {
    return this.controls.sharesUsufructNumber;
  }

  get sharesUsufructNumberErrorMessage(): ErrorMessage {
    return FormHelper.getMinErrorMessage(
      this.sharesUsufructNumber,
      'Le nombre de parts doit être supérieur ou égal à #value#'
    );
  }

  constructor() {
    super({
      capitalContribution: new FormControl<number | null>(null, [Validators.min(0)]),
      sharesNumber: new FormControl<number | null>(null, [Validators.min(0)]),
      entryDate: new FormControl<string | null>(null),
      exitDate: new FormControl<string | null>(null),
      sharesBareOwnershipNumber: new FormControl<number | null>(null, [Validators.min(0)]),
      sharesUsufructNumber: new FormControl<number | null>(null, [Validators.min(0)])
    });
  }
}
