import { TaxRegimeCode } from '@enums/tax-regime-code.enum';

export interface TaxRegimeJson {
  code: TaxRegimeCode;
  label: string;
  condensed_label: string;
}

export class TaxRegime {
  constructor(public code?: TaxRegimeCode, public label?: string, public condensedLabel?: string) {}

  static fromJson(json: Partial<TaxRegimeJson>): TaxRegime {
    return new TaxRegime(json.code, json.label, json.condensed_label);
  }
}
