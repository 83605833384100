export interface PayPeriodJson {
  id: number;
  period: string;
}

export class PayPeriod {
  constructor(public id?: number, public period?: string) {}

  static fromJson(json: Partial<PayPeriodJson>): PayPeriod {
    return new PayPeriod(json.id, json.period);
  }

  static toJson(payPeriod: PayPeriod): PayPeriodJson {
    return {
      id: payPeriod.id,
      period: payPeriod.period
    };
  }
}
