/* eslint-disable import/namespace */
import * as moment from 'moment';

import { PeriodicityEnum } from '@enums/periodicity.enum';

export type DateInput = moment.MomentInput;

export type DateRange = { start: DateInput; end: DateInput };

export class DateHelper {
  static add(
    date: DateInput,
    amount: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor
  ): moment.Moment {
    return moment(date).add(amount, unit);
  }

  static subtract(
    date: DateInput,
    amount: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor
  ): moment.Moment {
    return moment(date).subtract(amount, unit);
  }

  static daysInMonth(date: DateInput): number {
    return moment(date).daysInMonth();
  }

  static duration(duration: moment.DurationInputArg1): moment.Duration {
    return moment.duration(duration);
  }

  static diff(
    date1: DateInput,
    date2: DateInput,
    unitOfTime?: moment.unitOfTime.DurationConstructor,
    precise?: boolean
  ): number {
    return moment(date1).diff(date2, unitOfTime, precise);
  }

  static firstDayOfCurrentMonth(): moment.Moment {
    return moment().startOf('month');
  }

  static minutesToHHMM(minutes: number): string {
    const hh = Math.floor(minutes / 60);

    const mm = minutes % 60;

    return `${hh}h${mm > 9 ? mm : `0${mm}`}`;
  }

  static firstDayOfLastMonth(monthToSubstract = 1): moment.Moment {
    return moment().subtract(monthToSubstract, 'month').startOf('month');
  }

  static format(date: DateInput, format: string = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  static lastDayOfCurrentMonth(): moment.Moment {
    return moment().endOf('month');
  }

  static lastDayOfLastMonth(monthToSubstract = 1): moment.Moment {
    return moment().subtract(monthToSubstract, 'month').endOf('month');
  }

  static lastDayOfNextMonth(monthToAdd = 1): moment.Moment {
    return moment().add(monthToAdd, 'month').endOf('month');
  }

  static isAfter(date1: DateInput, date2: DateInput, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isAfter(date2, granularity);
  }

  static isBefore(date1: DateInput, date2: DateInput, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isBefore(date2, granularity);
  }

  static isBetween(
    date1: DateInput,
    date2: DateInput,
    date3: DateInput,
    granularity?: moment.unitOfTime.StartOf
  ): boolean {
    return moment(date1).isBetween(date2, date3, granularity, '[]');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static isMoment(date: any): boolean {
    return moment.isMoment(date);
  }

  static isSame(date1: DateInput, date2: DateInput, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isSame(date2, granularity);
  }

  static isSameOrAfter(date1: DateInput, date2: DateInput, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isSameOrAfter(date2, granularity);
  }

  static isSameOrBefore(date1: DateInput, date2: DateInput, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isSameOrBefore(date2, granularity);
  }

  static toDate(date: DateInput, format?: moment.MomentFormatSpecification): Date {
    return moment(date, format).toDate();
  }

  static updateLocale(language: string, localeSpec: moment.LocaleSpecification): void {
    moment.updateLocale(language, localeSpec);
  }

  static fromUtc(date: DateInput): Date {
    return moment.utc(date).toDate();
  }

  static toIsoWeek(date: DateInput): number {
    return moment(date).isoWeek();
  }

  static firstDayOfMonth(date: DateInput): Date {
    return moment(date).startOf('month').toDate();
  }

  static lastDayOfMonth(date: DateInput): Date {
    return moment(date).endOf('month').toDate();
  }

  static lastDayOfQuarter(date: DateInput): Date {
    return moment(date).endOf('quarter').toDate();
  }

  static max(dates: DateInput[]): Date {
    return moment.max(dates.map(date => moment(date))).toDate();
  }

  static min(dates: DateInput[]): Date {
    return moment.min(dates.map(date => moment(date))).toDate();
  }

  static currentDate(): moment.Moment {
    return moment();
  }

  static toMoment(date: DateInput): moment.Moment {
    return moment(date);
  }

  static startOf(date: DateInput, unitOfTime: moment.unitOfTime.StartOf): Date {
    return moment(date).startOf(unitOfTime).toDate();
  }

  static endOf(date: DateInput, unitOfTime: moment.unitOfTime.StartOf): Date {
    return moment(date).endOf(unitOfTime).toDate();
  }

  static getLastDate(initialDate: DateInput, periodCount: number, periodicity: PeriodicityEnum): string {
    if (!initialDate || !periodCount || !periodicity) {
      return '';
    }
    const monthMultiplier = DateHelper.getMonthMultiplier(periodicity);

    const result = DateHelper.add(initialDate, monthMultiplier * (periodCount - 1), 'month');

    return DateHelper.format(result);
  }

  static getMonthMultiplier(periodicity: PeriodicityEnum): number {
    switch (periodicity) {
      case PeriodicityEnum.quarterly:
        return 3;
      case PeriodicityEnum.halfYearly:
        return 6;
      case PeriodicityEnum.annual:
        return 12;
      case PeriodicityEnum.monthly:
      default:
        return 1;
    }
  }

  static rangesAreOverlapping(range1: DateRange, range2: DateRange): boolean {
    const someDatesAreInvalid = [range1?.start, range1?.end, range2?.start, range2?.end].some(date => {
      if (!date) {
        return true;
      }
      return !DateHelper.isValid(date);
    });

    if (someDatesAreInvalid) {
      return false;
    }
    return (
      DateHelper.isBetween(range1.start, range2.start, range2.end) ||
      DateHelper.isBetween(range2.start, range1.start, range1.end) ||
      DateHelper.isBetween(range2.end, range1.start, range1.end)
    );
  }

  static isValid(date: DateInput): boolean {
    return DateHelper.toMoment(date).isValid();
  }
}
