import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { DeclarationDownloadType } from '@enums/declaration-download-type.enum';
import { DeclarationStatus } from '@enums/declaration-status.enum';
import { FiscalDeclarationType } from '@enums/fiscal-declaration-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { FiscalDeclaration, FiscalDeclarationJson } from '@models/fiscal-declaration';
import { FileSaverData } from '@services/file.service';

/**
 * Fiscal declaration Service.
 */
@Injectable({
  providedIn: 'root'
})
export class FiscalDeclarationApiService extends ApiCommonService<FiscalDeclaration> {
  resourceUrl = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/fiscal_declarations`;

  fromJson(json: FiscalDeclarationJson): FiscalDeclaration {
    return FiscalDeclaration.fromJson(json);
  }

  toJson(_model: FiscalDeclaration): null {
    return null;
  }

  @ApiAlertError([403])
  getFiscalDeclarations(
    accountingPeriodId?: number,
    type?: FiscalDeclarationType,
    excludedType?: FiscalDeclarationType,
    companyId?: number
  ): Observable<FiscalDeclaration[]> {
    let url = this.resourceUrl;
    let params: HttpParams = new HttpParams();

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    if (accountingPeriodId) {
      params = params.set('accounting_period', String(accountingPeriodId));
    }

    if (type) {
      params = params.set('types', type);
    }
    if (excludedType) {
      params = params.set('excluded_types', excludedType);
    }

    return super.getAll({ params }, url);
  }

  @ApiAlertError([400])
  getFiscalDeclarationById(id: number): Observable<FiscalDeclaration> {
    const url = `${this.resourceUrl}/${id}`;
    const params: HttpParams = new HttpParams().set('expand', 'link');

    return this.http.get(url, { params }).pipe(map((json: FiscalDeclarationJson) => this.fromJson(json)));
  }

  @ApiAlertError()
  downloadFiscalDeclaration(fiscalDeclarationId: number, type: DeclarationDownloadType): Observable<FileSaverData> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/fiscal_declaration/${fiscalDeclarationId}/pdf`;
    const blobObservable = this.http.post(
      url,
      { type },
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  updateFiscalDeclarationStatus(
    fiscalDeclarationId: number,
    status: DeclarationStatus,
    companyId?: number
  ): Observable<FiscalDeclaration> {
    let url = `${this.resourceUrl}/${fiscalDeclarationId}`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }
    const body = {
      id: fiscalDeclarationId,
      status
    };

    return this.http.patch(url, body).pipe(map((json: FiscalDeclarationJson) => this.fromJson(json)));
  }

  @ApiAlertError()
  createFiscalDeclaration(accountingPeriodId: number, type: string): Observable<FiscalDeclaration> {
    const url = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/accounting_periods/${accountingPeriodId}/fiscal_declarations`;
    const params: HttpParams = new HttpParams().set('type', type);

    return this.http
      .post<FiscalDeclarationJson>(url, null, { params })
      .pipe(map(json => FiscalDeclaration.fromJson(json)));
  }
}
