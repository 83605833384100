import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tiimeMultilineEllipsis]'
})
export class MultilineEllipsisDirective implements AfterViewInit {
  private readonly ellipsis = '...';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.displayEllipsis();
  }

  private displayEllipsis(): void {
    const el: HTMLElement = this.el.nativeElement;
    let text: string[] = JSON.parse(JSON.stringify(el.innerText.split(' ')));
    while (el.scrollHeight > el.offsetHeight + 1) {
      text = text.slice(0, -1);
      this.renderer.setProperty(el, 'innerText', `${text.join(' ')}${this.ellipsis}`);
    }
    if (el.innerText.endsWith(this.ellipsis)) {
      this.renderer.removeAttribute(el, 'tooltipHideWithoutEllipsis');
    }
  }
}
