import { AccountingLabelGenerationType } from '@enums/accounting-label-generation-type.enum';
import { BusinessUnitConfig } from '@models/business-unit-config';
import { Logo, LogoJson } from '@models/logo';

export interface BusinessUnitJson {
  id: number;
  name: string;
  cfonb_restitution_email: string;
  generic_sender_email: string;
  label_generation_type: AccountingLabelGenerationType;
  brand: string;
  logo: LogoJson;
}

export class BusinessUnit {
  constructor(
    public id?: number,
    public name?: string,
    public cfonbRestitutionEmail?: string,
    public genericSenderEmail?: string,
    public labelGenerationType?: AccountingLabelGenerationType,
    public brand?: string,
    public logo?: Logo,
    public config?: BusinessUnitConfig
  ) {}

  static fromJson(json: Partial<BusinessUnitJson>): BusinessUnit {
    return new BusinessUnit(
      json.id,
      json.name,
      json.cfonb_restitution_email,
      json.generic_sender_email,
      json.label_generation_type,
      json.brand,
      json.logo ? Logo.fromJson(json.logo) : null
    );
  }

  static toJson(businessUnit: BusinessUnit): Partial<BusinessUnitJson> {
    return {
      id: businessUnit.id,
      name: businessUnit.name || null,
      cfonb_restitution_email: businessUnit.cfonbRestitutionEmail || null,
      generic_sender_email: businessUnit.genericSenderEmail || null,
      label_generation_type: businessUnit.labelGenerationType,
      brand: businessUnit.brand || null
    };
  }
}
