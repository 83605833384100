export interface TaskPlanningTotalJson {
  amount: number;
  discount: number;
  amount_after_discount: number;
}

export class TaskPlanningTotal {
  constructor(
    public amount?: number,
    public discount?: number,
    public amountAfterDiscount?: number
  ) {}

  static fromJson(json: Partial<TaskPlanningTotalJson>): TaskPlanningTotal {
    return new TaskPlanningTotal(json.amount, json.discount, json.amount_after_discount);
  }
}
