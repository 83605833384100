import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { FormUtils, NgUtils } from 'tiime-expert-utils';

import { AppStoreState } from '@interfaces/app-store-state';
import { CompanyContributor } from '@models/company-contributor';
import { Contributor } from '@models/contributor';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

@Directive()
export abstract class ContributorSelectBase implements OnChanges, AfterViewInit {
  @Input() contributors: Contributor[] = [];
  @Input() interlocutors?: CompanyContributor[];
  @Input() contributorFormControl: UntypedFormControl = new UntypedFormControl([]);
  @Input() showNullContributor = false;
  @Input() nullContributorLabel = 'Aucun intervenant';
  @Input() enableFiltering = true;

  @Output() readonly selectionChange: EventEmitter<Contributor[]> = new EventEmitter<Contributor[]>();
  @Output() readonly removeContributorChange: EventEmitter<Contributor> = new EventEmitter<Contributor>();
  @Output() readonly selectContributorChange: EventEmitter<Contributor> = new EventEmitter<Contributor>();
  @Output() readonly searchContributorsChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;

  filteredContributors$: Observable<Contributor[]>;
  readonly contributorsSubject: BehaviorSubject<Contributor[]> = new BehaviorSubject<Contributor[]>([]);

  readonly searchControl: FormControl<string> = new FormControl<string>('');

  readonly trackById = NgUtils.trackById;

  protected readonly subscription: Subscription = new Subscription();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected store: Store<AppStoreState>
  ) {
    this.filteredContributors$ = this.getFilteredContributors$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchControl?.updateValueAndValidity();

    if (changes.contributors) {
      this.contributorsSubject.next(changes.contributors.currentValue);
    }

    if (changes.enableFiltering) {
      this.filteredContributors$ = this.getFilteredContributors$();
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.contributorFormControl.valueChanges
        .pipe(
          tap((value: Contributor[]) => {
            if (value?.length === 0) {
              this.searchControl.patchValue('', FormUtils.shouldNotEmitEvent);
              this.searchControl.enable();
            }
          }),
          tap(value => this.selectionChange.emit(value))
        )
        .subscribe()
    );
  }

  selectContributor(contributor: Contributor): void {
    this.contributorFormControl.setValue([
      ...((this.contributorFormControl.value as Contributor[]) ?? []),
      contributor
    ]);
    this.resetSearch();
    this.selectContributorChange.emit(contributor);
  }

  selectContributors(contributors: Contributor[]): void {
    this.contributorFormControl.setValue([
      ...((this.contributorFormControl.value as Contributor[]) ?? []),
      ...contributors
    ]);
    this.resetSearch();
  }

  removeContributor(contributor: Contributor): void {
    this.contributorFormControl.setValue(
      (this.contributorFormControl.value ?? []).filter(c => c.id !== contributor.id)
    );
    this.resetSearch();
    this.removeContributorChange.emit(contributor);
  }

  openAutocomplete(): void {
    this.autoComplete?.openPanel();
  }

  resetSearch(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.searchControl?.setValue(null);
  }

  protected getFilteredContributors$(): Observable<Contributor[]> {
    return this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(''),
      switchMap((search: string) =>
        this.store.select(businessUserSelector).pipe(
          take(1),
          switchMap(() => this.filterDuplicatesAndCurrentUser()),
          map((contributors: Contributor[]) => {
            if (!search?.trim?.().length) {
              return contributors;
            }

            return this.searchContributors(search, contributors);
          })
        )
      ),
      // Gestion du contributor "Aucun intervenant"
      map((contributors: Contributor[]) => this.manageNullContributor(contributors)),
      tap(() => this.cdr.markForCheck()),
      shareReplay(1)
    );
  }

  protected filterDuplicatesAndCurrentUser(): Observable<Contributor[]> {
    return this.store.select(businessUserSelector).pipe(
      take(1),
      map(businessUser => {
        const contributors = [...(this.contributors ?? [])];
        const currentContributorIndex = contributors.findIndex(
          (contributor: Contributor) => contributor.id === businessUser?.id
        );

        if (currentContributorIndex !== -1) {
          contributors.splice(currentContributorIndex, 1);
          contributors.unshift(businessUser);
        } else if (!contributors.length) {
          contributors.unshift(businessUser);
        }

        const withoutSelected: Contributor[] = (contributors ?? []).filter(
          c => !(this.contributorFormControl?.value ?? []).find(cc => cc.id === c.id)
        );

        return withoutSelected;
      })
    );
  }

  protected manageNullContributor(contributors: Contributor[]): Contributor[] {
    if (this.contributorFormControl.value?.length || !this.showNullContributor) {
      return contributors;
    }
    return [new Contributor(null, this.nullContributorLabel), ...contributors];
  }

  protected searchContributors(search: string, contributors: Contributor[]): Contributor[] {
    const lowerCasedSearch = search.toLowerCase();
    return contributors.filter(
      contributor =>
        (contributor.email && contributor.email.toLowerCase().includes(lowerCasedSearch)) ||
        (contributor.firstName && contributor.firstName.toLowerCase().includes(lowerCasedSearch)) ||
        (contributor.lastName && contributor.lastName.toLowerCase().includes(lowerCasedSearch)) ||
        (contributor.acronym && contributor.acronym.toLowerCase().includes(lowerCasedSearch))
    );
  }
}
