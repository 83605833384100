import { Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgUtils } from 'tiime-expert-utils';
import { SelectComponent } from 'tiime-material';

import { ErrorMessage } from '@interfaces/error-message';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SelectBase<T> {
  @Input() control: FormControl;
  @Input() errorMessage: ErrorMessage;
  @Input() required = false;
  @Input() small = false;
  @Input() allowEmptyOption = true;
  @Input() emptyOptionLabel = '';
  @Input() errorMessageMode: 'label' | 'list' = 'label';
  @Input() multiple = false;
  @Input() fixedLabel = false;
  @Input() forceSelected = false;
  @Input() emptyValueTooltipContent: string = null;
  @Input() disableEmptyValue = false;

  @Input('selectData') set setSelectorData(selectData: T[]) {
    if (selectData) {
      this.selectorData = selectData;
    }
  }

  @Output() readonly selectionChange: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild(SelectComponent) selectComponent: SelectComponent;

  selectorData: T[] = [];

  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;
  readonly findOriginValueFromOptionsById = (value: { id?: number }, option: { id?: number }): boolean =>
    value?.id === option?.id;

  emitSelectionChange(selection: T): void {
    this.selectionChange.emit(selection);
  }
}
