import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { NotificationApiService } from '@api-services/notification-api.service';
import { HttpHelper } from '@helpers/http.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { Notification } from '@models/notification';
import { AuthService } from '@modules/core/auth/auth.service';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import { notificationsSelector } from '@modules/core/store/notifications/notifications-selector';
import { FileService } from '@services/file.service';
import { NotificationOverlayService } from '@shared-overlays/notification-overlay/notification-overlay.service';
import { FirebaseMessagePayload, FirebaseService } from '@third-parties/firebase/firebase.service';

import * as NotificationsActions from './notifications-actions';
import { NotificationsTokenService } from './notifications-token.service';

@Injectable()
export class NotificationsEffects {
  saveToken$ = createEffect(
    () =>
      this.firebaseService.token$.pipe(
        withLatestFrom(this.authService.isAuthenticated$),
        switchMap(([token, isAuthenticated]) => {
          const currentToken = this.notificationsTokenService.retrieve();

          // On supprime le token
          if ((!token || !isAuthenticated) && currentToken) {
            this.notificationsTokenService.remove();
          }

          // On ajoute le token
          if (token && isAuthenticated && currentToken !== token) {
            return this.notificationApiService.addDevice(token).pipe(
              tap(() => this.notificationsTokenService.store(token)),
              catchError(() => of(null))
            );
          }

          return of(null);
        })
      ),
    { dispatch: false }
  );

  getMessages$ = createEffect(() =>
    this.firebaseService.message$.pipe(
      tap(() => this.store.dispatch(NotificationsActions.loadCounter())),
      map((message: FirebaseMessagePayload) => Notification.fromFirebaseNotificationMessage(message)),
      switchMap(notification =>
        this.notificationOverlayService
          .open(notification)
          .afterClosed()
          .pipe(
            filter(navigate => !!navigate),
            tap(() => this.dialog.closeAll()),
            tap(() => this.navigationService.navigateFromNotificationLink(notification.clickAction)),
            map(() => NotificationsActions.markNotificationAsRead({ notification }))
          )
      )
    )
  );

  loadCounterAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.LOAD_COUNTER),
      switchMap(() => this.notificationApiService.countNotifications()),
      map(counter => NotificationsActions.updateCounter({ counter }))
    )
  );

  loadNotificationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.LOAD_NOTIFICATIONS),
      switchMap((actionData: { range: PaginationRange; read: boolean | null }) =>
        this.notificationApiService.getNotifications(actionData.range, actionData.read).pipe(
          map((paginationData: PaginationData<Notification>) =>
            NotificationsActions.updateNotifications({
              range: paginationData.paginationRange,
              notifications: paginationData.data,
              read: actionData.read
            })
          )
        )
      ),
      catchError(() =>
        of(
          NotificationsActions.setLoading({
            loading: false
          })
        )
      )
    )
  );

  markNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.MARK_NOTIFICATION_AS_READ),
      tap(() =>
        this.store.dispatch(
          NotificationsActions.setLoading({
            loading: false
          })
        )
      ),
      switchMap((actionData: { notification: Notification }) =>
        this.notificationApiService.markNotificationAsRead(actionData.notification.data.notificationUuid)
      ),
      map(notification => NotificationsActions.updateNotification({ notification })),
      tap(() => this.store.dispatch(NotificationsActions.loadCounter())),
      catchError(() =>
        of(
          NotificationsActions.setLoading({
            loading: false
          })
        )
      )
    )
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.DOWNLOAD_FILE),
        switchMap((actionData: { notification: Notification }) => {
          const url = actionData.notification?.data?.actions[0]?.action;

          if (!url) {
            return;
          }

          const blobObservable = this.http.get(url, {
            responseType: 'blob',
            observe: 'response'
          });

          return HttpHelper.getFileSaverData(blobObservable).pipe(tap(data => this.fileService.save(data)));
        })
      ),
    { dispatch: false }
  );

  markAllNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.MARK_ALL_NOTIFICATIONS_AS_READ),
      tap(() =>
        this.store.dispatch(
          NotificationsActions.setLoading({
            loading: true
          })
        )
      ),
      switchMap(() => this.notificationApiService.markAllNotificationsAsRead()),
      tap(() => this.store.dispatch(NotificationsActions.updateCounter({ counter: 0 }))),
      withLatestFrom(this.store.select(notificationsSelector)),
      map(([, state]) =>
        NotificationsActions.loadNotifications({
          range: PaginationRange.withPageSize(100),
          read: state.read
        })
      ),
      catchError(() =>
        of(
          NotificationsActions.setLoading({
            loading: false
          })
        )
      )
    )
  );

  constructor(
    private store: Store<AppStoreState>,
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private notificationApiService: NotificationApiService,
    private notificationOverlayService: NotificationOverlayService,
    private navigationService: NavigationService,
    private notificationsTokenService: NotificationsTokenService,
    private authService: AuthService,
    private dialog: MatDialog,
    private http: HttpClient,
    private fileService: FileService
  ) {}
}
