import { Injectable } from '@angular/core';


import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { TiimeSnackbarService } from 'tiime-material';

import { AppStoreState } from '@interfaces/app-store-state';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import { businessUserCanAccessToTimeTrackings } from '@modules/core/store/business-user/business-user-selector';

import { BusinessUserGuard } from './business-user.guard';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingsGuard  {
  constructor(
    private store: Store<AppStoreState>,
    private navigationService: NavigationService,
    private snackbar: TiimeSnackbarService,
    private businessUserGuard: BusinessUserGuard
  ) {}

  canActivate(): Observable<boolean> {
    return this.businessUserGuard.canActivate().pipe(switchMap(() => this.canAccessToTimeTrackings()));
  }

  canLoad(): Observable<boolean> {
    return this.businessUserGuard.canLoad().pipe(switchMap(() => this.canAccessToTimeTrackings()));
  }

  private canAccessToTimeTrackings(): Observable<boolean> {
    return this.store.pipe(
      select(businessUserCanAccessToTimeTrackings),
      take(1),
      map((canAccessToTimeTrackings: boolean) => {
        if (!canAccessToTimeTrackings) {
          this.snackbar.openError(`Accès refusé`);
          this.navigationService.navigateToUnauthorized();
        }
        return canAccessToTimeTrackings;
      })
    );
  }
}
