<div class="business-user-account-menu-overlay-header">
  <app-business-user-slug
    [businessUserAcronym]="businessUser.acronym"
    fontSize="30px"
    size="60px"
  ></app-business-user-slug>
  <button
    class="keyboard-button"
    tiime-button
    color="primary"
    strocked
    icon
    tiimeTooltip
    [tooltipContent]="keyboardButtonTemplate"
    (click)="openKeyboardShortcutsDialog()"
    data-cy="keyboard-shortcuts-btn"
  >
    <mat-icon inline="true" svgIcon="icon-keyboard"></mat-icon>
  </button>
  <ng-template #keyboardButtonTemplate>Raccourcis clavier</ng-template>
</div>
<div class="business-user-account-menu-overlay-content">
  <div class="business-user-container">
    <div class="business-user-account">Compte Tiime Expert</div>
    <div class="business-user-guest-account" *appHasRole="['ROLE_GUEST']">Intervenant externe</div>
    <div class="business-user-infos" [tiimeTooltip]="businessUser.fullName" tooltipHideWithoutEllipsis>
      {{ businessUser.fullName }}
    </div>
    <div class="business-user-infos" [tiimeTooltip]="businessUser.email" tooltipHideWithoutEllipsis>
      {{ businessUser.email }}
    </div>
  </div>
  <div class="menu-item" (click)="toggleHelpScoutBeacon()">
    <mat-icon inline="true" fontIcon="icon-rond-etat-intero-24"></mat-icon>
    <span>Aide</span>
  </div>
  <div class="menu-item" (click)="openCareContactDialog()">
    <mat-icon inline="true" fontIcon="icon-mail-1c-24"></mat-icon>
    <span>Contacter le support</span>
  </div>
  <div class="menu-item" (click)="changePassword()" data-cy="forgot-password-btn">
    <mat-icon inline="true" fontIcon="icon-lock-1c-24"></mat-icon>
    <span>Changer de mot de passe</span>
  </div>
  <div class="menu-item" (click)="logout()" data-cy="logout-btn">
    <mat-icon inline="true" fontIcon="icon-ic-logout"></mat-icon>
    <span>Déconnexion</span>
  </div>
</div>
