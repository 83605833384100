import { DecimalPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  numberAttribute,
  OnChanges,
  OnInit,
  Self,
  SimpleChanges
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NAVIGATION_KEYS } from '@constants/navigation-keys.constant';

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[appDigitOnly]',
  standalone: true
})
export class DigitOnlyDirective implements OnInit, OnChanges {
  @Input() decimal = false;
  @Input() decimalSeparator = '.';
  @Input() decimalDigits = '1.0-0';
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() canBeNegative = false;
  @Input() negativeSymbol = '-';
  @Input() roundDecimal = true;
  @Input({ transform: numberAttribute }) maxDecimalDigits: number = 2;

  private subscription = new Subscription();
  private hasDecimalPoint = false;
  private isFocused = false;

  private readonly navigationKeys = NAVIGATION_KEYS;
  private readonly decimalPipe = new DecimalPipe('fr');

  inputElement: HTMLInputElement;

  constructor(
    @Self() public ngControl: NgControl,
    public el: ElementRef
  ) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.ngControl?.control) {
      this.subscription.add(
        this.ngControl.valueChanges
          .pipe(
            tap(() => {
              if (!this.isFocused) {
                this.onFocusOut();
              }
            })
          )
          .subscribe()
      );
    }

    this.decimalTransform();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.min) {
      const maybeMin = Number(this.min);
      this.min = isNaN(maybeMin) ? -Infinity : maybeMin;
    }

    if (changes.max) {
      const maybeMax = Number(this.max);
      this.max = isNaN(maybeMax) ? Infinity : maybeMax;
    }
  }

  @HostListener('beforeinput', ['$event'])
  onBeforeInput(e: InputEvent): any {
    if (isNaN(Number(e.data))) {
      if (e.data === this.decimalSeparator || (this.canBeNegative && e.data === this.negativeSymbol)) {
        return; // go on
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    const newValue = this.forecastValue(e.key);

    if (this.decimal && e.key === this.decimalSeparator) {
      if (newValue.split(this.decimalSeparator).length > 2) {
        // has two or more decimal points
        e.preventDefault();
        return;
      } else {
        this.hasDecimalPoint = newValue.indexOf(this.decimalSeparator) > -1;
        return; // Allow: only one decimal point
      }
    }

    if (
      this.decimal &&
      newValue.indexOf(this.decimalSeparator) > -1 &&
      newValue.length - newValue.indexOf(this.decimalSeparator) === 2 + this.maxDecimalDigits
    ) {
      e.preventDefault();
      return;
    }

    if (this.canBeNegative && e.key === this.negativeSymbol) {
      // negative symbol is not in first position or has two or more negative symbol
      if (
        (newValue.indexOf(this.negativeSymbol) > -1 && newValue.indexOf(this.negativeSymbol) !== 0) ||
        newValue.split(this.negativeSymbol).length > 2
      ) {
        e.preventDefault();
        return;
      }
    }

    this.inputElement.value = this.sanitizeInput(this.inputElement.value); // intercept (¨^\) for mac

    // Ensure that it is a number and stop the keypress
    if (
      e.key === ' ' ||
      (!this.canBeNegative && e.key === this.negativeSymbol) ||
      (this.canBeNegative && e.key !== this.negativeSymbol && isNaN(Number(e.key)))
    ) {
      e.preventDefault();
      return;
    }

    if (newValue !== this.negativeSymbol) {
      const newNumber = Number(newValue);
      if (newNumber > this.max || newNumber < this.min) {
        e.preventDefault();
      }
    }
  }

  @HostListener('blur')
  onBlur(): void {
    this.inputElement.value = this.inputElement.value.replace(/\.$/, ''); // remove dot if last char
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    let pastedInput = '';
    if ((window as { [key: string]: any }).clipboardData) {
      // Browser is IE
      pastedInput = (window as { [key: string]: any }).clipboardData.getData('text');
    } else if (event.clipboardData && event.clipboardData.getData) {
      // Other browsers
      pastedInput = event.clipboardData.getData('text/plain');
    }

    this.pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    const textData = event.dataTransfer?.getData('text') ?? '';
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  @HostListener('focus')
  onFocus(): void {
    this.isFocused = true;
    this.inputElement.value = Number.isNaN(Number(this.ngControl.value)) ? null : this.ngControl.value;
    this.inputElement.setSelectionRange(0, this.inputElement.value.length);
  }

  @HostListener('focusout')
  onFocusOut(): void {
    this.isFocused = false;
    this.decimalTransform();
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      if (this.inputElement.setRangeText) {
        const { selectionStart: start, selectionEnd: end } = this.inputElement;
        this.inputElement.setRangeText(sanitizedContent, start ?? 0, end ?? 0, 'end');
        // Angular's Reactive Form relies on "input" event, but on Firefox, the setRangeText method doesn't trigger it
        // so we have to trigger it ourself.
        if (typeof (window as { [key: string]: any }).InstallTrigger !== 'undefined') {
          this.inputElement.dispatchEvent(new Event('input', { cancelable: true }));
        }
      } else {
        // Browser does not support setRangeText, e.g. IE
        this.insertAtCursor(this.inputElement, sanitizedContent);
      }
    }
    if (this.decimal) {
      this.hasDecimalPoint = this.inputElement.value.indexOf(this.decimalSeparator) > -1;
    }
  }

  // The following 2 methods were added from the below article for browsers that do not support setRangeText
  // https://stackoverflow.com/questions/11076975/how-to-insert-text-into-the-textarea-at-the-current-cursor-position
  private insertAtCursor(myField: HTMLInputElement, myValue: string): void {
    const startPos = myField.selectionStart ?? 0;
    const endPos = myField.selectionEnd ?? 0;

    myField.value =
      myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);

    const pos = startPos + myValue.length;
    myField.focus();
    myField.setSelectionRange(pos, pos);

    this.triggerEvent(myField, 'input');
  }

  private triggerEvent(el: HTMLInputElement, type: string): void {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      const e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    }
  }
  // end stack overflow code
  private sanitizeInput(input: string): string {
    const regExp = new RegExp(
      `[^0-9${this.canBeNegative ? '-' : ''}${
        this.decimal && this.isValidDecimal(input) ? this.decimalSeparator : ''
      }]`,
      'g'
    );
    let result = input.replace(regExp, '');

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }
    return result;
  }

  private isValidDecimal(decimalString: string): boolean {
    if (!this.hasDecimalPoint) {
      return decimalString.split(this.decimalSeparator).length <= 2;
    } else {
      // the input element already has a decimal separator
      const selectedText = this.getSelection();
      if (selectedText && selectedText.indexOf(this.decimalSeparator) > -1) {
        return decimalString.split(this.decimalSeparator).length <= 2;
      } else {
        return true;
      }
    }
  }

  private getSelection(): string {
    return this.inputElement.value.substring(
      this.inputElement.selectionStart ?? 0,
      this.inputElement.selectionEnd ?? 0
    );
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart ?? 0;
    const selectionEnd = this.inputElement.selectionEnd ?? 0;
    const oldValue = this.inputElement.value;
    const selection = oldValue.substring(selectionStart, selectionEnd);
    return selection
      ? oldValue.replace(selection, key)
      : oldValue.substring(0, selectionStart) + key + oldValue.substring(selectionStart);
  }

  private decimalTransform(): void {
    if (Number.isNaN(Number(this.ngControl.value))) {
      return;
    }

    if (this.roundDecimal) {
      this.inputElement.value = this.decimalPipe.transform(this.ngControl.value, this.decimalDigits);
    } else {
      this.inputElement.value = this.decimalPipe.transform(this.ngControl.value, '1.0-2');
    }
  }
}
