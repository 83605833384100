import { Pipe, PipeTransform } from '@angular/core';

import { THREAD_ENTITY_TYPE_COLOR } from '@constants/thread-entity-type-color.constant';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';

@Pipe({
  name: 'threadEntityTypeColor',
  standalone: true
})
export class ThreadEntityTypeColorPipe implements PipeTransform {
  transform(type: ThreadEntityType): string {
    return THREAD_ENTITY_TYPE_COLOR[type] ?? '';
  }
}
