export interface ApeCodeJson {
  id: number;
  code: string;
  label: string;
}

export class ApeCode {
  constructor(public id?: number, public code?: string, public label?: string) {}

  static fromJson(json: Partial<ApeCodeJson>): ApeCode {
    return new ApeCode(json.id, json.code, json.label);
  }

  static toJson(apeCode: ApeCode): ApeCodeJson {
    return {
      id: apeCode.id,
      code: apeCode.code,
      label: apeCode.label
    };
  }
}
