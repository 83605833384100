import { CompaniesApiFiltersEnum } from '@enums/companies-api-filters-enum';
import { FiltersParams } from '@helpers/http.helper';
import { CompaniesFilters, CompaniesStatusFilterEnum } from '@interfaces/companies-filters';

export class CompaniesFiltersHelper {
  static toParams(filters: CompaniesFilters): FiltersParams {
    const params = {
      [CompaniesApiFiltersEnum.taxRegimeCode]: filters.taxRegimeCode?.length
        ? filters.taxRegimeCode.join('|')
        : undefined,
      [CompaniesApiFiltersEnum.contributors]: filters.contributors.length
        ? filters.contributors.map(contributor => contributor.id).join('|')
        : undefined,
      [CompaniesApiFiltersEnum.businessUnits]: filters.businessUnits.length
        ? filters.businessUnits.map(businessUnit => businessUnit.id).join(',')
        : undefined,
      [CompaniesApiFiltersEnum.missionExitMotifs]:
        filters.missionExitMotif !== 'all' ? filters.missionExitMotif : undefined
    };

    switch (filters.status) {
      case CompaniesStatusFilterEnum.missionEnded:
        return {
          ...params,
          [CompaniesApiFiltersEnum.missionEnded]: 'true'
        };
      case CompaniesStatusFilterEnum.worksStopped:
        return {
          ...params,
          [CompaniesApiFiltersEnum.worksStopped]: 'true',
          [CompaniesApiFiltersEnum.missionEnded]: 'false'
        };
      case CompaniesStatusFilterEnum.active:
        return {
          ...params,
          [CompaniesApiFiltersEnum.missionEnded]: 'false',
          [CompaniesApiFiltersEnum.inCreationProcess]: 'false',
          [CompaniesApiFiltersEnum.initializationInProgress]: 'false',
          [CompaniesApiFiltersEnum.worksStopped]: 'false'
        };
      case CompaniesStatusFilterEnum.inCreationProcess:
        return {
          ...params,
          [CompaniesApiFiltersEnum.inCreationProcess]: 'true'
        };
      case CompaniesStatusFilterEnum.initializationInProgress:
        return {
          ...params,
          [CompaniesApiFiltersEnum.initializationInProgress]: 'true'
        };
    }

    return params;
  }

  static activeFiltersLength(filters: CompaniesFilters): number {
    let activeFiltersLength = 0;

    if (filters.taxRegimeCode?.length) {
      ++activeFiltersLength;
    }

    if (filters.status !== 'all') {
      ++activeFiltersLength;
    }

    if (filters.businessUnits?.length) {
      ++activeFiltersLength;
    }

    if (filters.contributors?.length) {
      ++activeFiltersLength;
    }

    return activeFiltersLength;
  }
}
