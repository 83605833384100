import { Pipe, PipeTransform } from '@angular/core';

import { THREAD_ENTITY_TYPE_LABEL } from '@constants/thread-entity-type-label.constant';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';

@Pipe({
  name: 'threadEntityTypeLabel',
  standalone: true
})
export class ThreadEntityTypeLabelPipe implements PipeTransform {
  transform(type: ThreadEntityType): string {
    return THREAD_ENTITY_TYPE_LABEL[type] ?? '';
  }
}
