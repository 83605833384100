export interface TransactionsTotalJson {
  total?: number;
  total_encaissements?: number;
  total_decaissements?: number;
}

export class TransactionsTotal {
  constructor(
    public total?: number,
    public totalEncaissements?: number,
    public totalDecaissements?: number
  ) {}

  public static fromJson(json: TransactionsTotalJson): TransactionsTotal {
    return new TransactionsTotal(json.total ?? 0, json.total_encaissements ?? 0, json.total_decaissements ?? 0);
  }
}
