import { createSelector } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { AppStoreState } from '@interfaces/app-store-state';
import { AccountingConfig } from '@models/accounting-config';

import { AccountingConfigState } from './accounting-config-reducer';

export const accountingConfigSelector = (state: AppStoreState): AccountingConfigState =>
  state && state.accountingConfig ? cloneDeep(state.accountingConfig) : null;

export const lockedByAccountantDateSelector = createSelector(
  accountingConfigSelector,
  (accountingConfig: AccountingConfig): string | null => accountingConfig?.lockedByAccountantDate ?? null
);

export const lockedByAccountantSelector = createSelector(lockedByAccountantDateSelector, (date): boolean => !!date);

export const customAccountingPeriodEndDateSelector = createSelector(
  accountingConfigSelector,
  (state: AccountingConfigState): string | null => state?.customAccountingPeriodEndDate ?? null
);
