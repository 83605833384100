export interface MissionMotifJson {
  code: string;
  label: string;
}

export class MissionMotif {
  constructor(public code?: string, public label?: string) {}

  static fromJson(json: Partial<MissionMotifJson>): MissionMotif {
    return new MissionMotif(json.code, json.label);
  }
}
