import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { AccountingEntry, AccountingEntryJson } from '@models/accounting-entry';
import { Balance, BalanceJson } from '@models/balance';
import { Lettering, LetteringJson } from '@models/lettering';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { User, UserJson } from '@models/user';

export interface MovementJson {
  id: number;
  wording: string;
  amount: number;
  pcg_entry: Partial<PcgEntryJson>;
  accounting_entry: Partial<AccountingEntryJson>;
  lettering: Partial<LetteringJson>;
  deleted: boolean;
  balance: Partial<BalanceJson>;
  progressive_balance: number;
  piece_reference: string;
  vat_regularization: boolean;
  validated: boolean;
  validated_at: string;
  validated_by: Partial<UserJson>;
}

export class Movement {
  constructor(
    public id?: number,
    public wording?: string,
    public debit?: number,
    public credit?: number,
    public pcgEntry?: PcgEntry,
    public accountingEntry?: AccountingEntry,
    public lettering?: Lettering,
    public deleted?: boolean,
    public balance?: Balance,
    public progressiveBalance?: number,
    public pieceReference?: string,
    public vatRegularization?: boolean,
    public validated?: boolean,
    public validatedAt?: Date,
    public validatedBy?: Partial<User>
  ) {}

  static amountFromCreditDebit(credit: number, debit: number): number {
    if (debit > 0) {
      return debit * -1;
    }

    return credit;
  }

  static creditFromAmount(amount: number): number {
    if (amount > 0) {
      return amount;
    }

    return 0;
  }

  static debitFromAmount(amount: number): number {
    if (amount < 0) {
      return amount * -1;
    }

    return 0;
  }

  static fromJson(json: Partial<MovementJson>): Movement {
    const result = new Movement(
      json.id,
      json.wording,
      Movement.debitFromAmount(json.amount),
      Movement.creditFromAmount(json.amount),
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.accounting_entry ? AccountingEntry.fromJson(json.accounting_entry) : null,
      json.lettering ? Lettering.fromJson(json.lettering) : null,
      null,
      null,
      null,
      null,
      json.vat_regularization,
      json.validated,
      json.validated_at ? DateHelper.fromUtc(json.validated_at) : null,
      json.validated_by ? User.fromJson(json.validated_by) : null
    );

    result.pieceReference = json.piece_reference;
    return result;
  }

  static toJson(
    movement: Movement
  ): Omit<
    MovementJson,
    'lettering' | 'deleted' | 'balance' | 'progressive_balance' | 'validated' | 'validated_at' | 'validated_by'
  > {
    return {
      id: movement.id,
      wording: movement.wording,
      amount: Movement.amountFromCreditDebit(movement.credit, movement.debit),
      pcg_entry: movement.pcgEntry?.id ? HttpHelper.toJsonId(movement.pcgEntry) : null,
      accounting_entry: AccountingEntry.toJson(movement.accountingEntry),
      piece_reference: movement.pieceReference,
      vat_regularization: movement.vatRegularization || undefined
    };
  }
}
