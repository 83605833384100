import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  TimelineComponent,
  TimelineItemComponent,
  TimelineItemDateDirective,
  TimelineItemDescriptionDirective,
  TimelineItemTitleDirective
} from './timeline';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TimelineComponent,
    TimelineItemComponent,
    TimelineItemDateDirective,
    TimelineItemTitleDirective,
    TimelineItemDescriptionDirective
  ],
  exports: [
    TimelineComponent,
    TimelineItemComponent,
    TimelineItemDateDirective,
    TimelineItemTitleDirective,
    TimelineItemDescriptionDirective
  ]
})
export class TiimeTimelineModule {}
