<tiime-input-container
  *ngIf="siretFormControl"
  tiimeFormInputContainer
  contentId="siret"
  label="Numéro de SIRET"
  [errorMessage]="errorMessage"
  [required]="required"
>
  <input
    id="siret"
    type="text"
    placeholder="Numéro de SIRET"
    [formControl]="siretFormControl"
    [inputMask]="siretMask"
    appFormUpdateOnBlur
    (formChanges)="emit()"
    data-cy="siret-input"
    autocomplete="off"
  />
</tiime-input-container>
