export interface LetteringJson {
  id: number;
  letter: string;
}

export class Lettering {
  constructor(public id?: number, public letter?: string) {}

  static fromJson(json: Partial<LetteringJson>): Lettering {
    return new Lettering(json.id, json.letter);
  }
}
