import { DateHelper } from '@helpers/date.helper';
import { BusinessUser, BusinessUserJson } from '@models/business-user';

export interface ContractReminderJson {
  date: string;
  created_by: Partial<BusinessUserJson>;
  external: boolean;
}

export class ContractReminder {
  constructor(
    public date?: Date,
    public createdBy?: BusinessUser,
    public external?: boolean
  ) {}

  static fromJson(json: Partial<ContractReminderJson>): ContractReminder {
    return new ContractReminder(
      json.date ? DateHelper.fromUtc(json.date) : null,
      json.created_by ? BusinessUser.fromJson(json.created_by) : null,
      json.external
    );
  }
}
