import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';

import { DatepickerHeaderComponent, TiimeInputContainerModule } from 'tiime-material';

import { DateInput } from '@helpers/date.helper';
import { ErrorMessage } from '@interfaces/error-message';

@Component({
  selector: 'app-export-datepicker',
  standalone: true,
  imports: [ReactiveFormsModule, MatDatepickerModule, MatIconModule, TiimeInputContainerModule],
  templateUrl: './export-datepicker.component.html',
  styleUrls: ['./export-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDatepickerComponent {
  @Input() dateControl: UntypedFormControl;
  @Input() min: DateInput;
  @Input() max: DateInput;
  @Input() errorMessage: ErrorMessage;

  @Input()
  set disabled(disabled: boolean) {
    if (!this.dateControl) {
      return;
    }
    if (disabled && this.dateControl.enabled) {
      this.dateControl.disable();
      return;
    }
    if (!disabled && this.dateControl.disabled) {
      this.dateControl.enable();
    }
  }

  @Output() readonly dateChange: EventEmitter<DateInput> = new EventEmitter<DateInput>();

  tiimeDatepickerHeader = DatepickerHeaderComponent;

  emitDateChange(event: MatDatepickerInputEvent<DateInput>): void {
    this.dateChange.emit(event.value);
  }
}
