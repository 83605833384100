const digitRegExp = new RegExp('^\\d$');
const letterRegExp = new RegExp('^[A-Za-z]$');
const alphaNumericCharacterRegExp = new RegExp('^[A-Z\\d]$');
const insensitiveAlphaNumericCharacterRegExp = new RegExp('^[A-Za-z\\d]$');

export class MaskHelper {
  public static siretMask: Array<string | RegExp> = [
    digitRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    digitRegExp,
    digitRegExp,
    digitRegExp
  ];

  public static apeMask: Array<string | RegExp> = [
    digitRegExp,
    digitRegExp,
    digitRegExp,
    digitRegExp,
    alphaNumericCharacterRegExp
  ];

  public static ibanMaskFR: Array<string | RegExp> = [
    new RegExp('F', 'i'),
    new RegExp('R', 'i'),
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    digitRegExp,
    digitRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    digitRegExp,
    digitRegExp
  ];

  public static ibanMask: Array<string | RegExp> = [
    letterRegExp,
    letterRegExp,
    digitRegExp,
    digitRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp,
    ' ',
    insensitiveAlphaNumericCharacterRegExp,
    insensitiveAlphaNumericCharacterRegExp
  ];
}

/**
 * @deprecated Use `MaskHelper` instead.
 */
export class MaskUtils extends MaskHelper {}
