<tiime-input-container
  class="select-chips-container flex center"
  [class.fulfilled]="contributorFormControl?.value?.length"
  [class.margin-top]="marginTop"
  [class.invalid]="contributorFormControl?.touched && contributorFormControl?.invalid"
  contentId="contributors"
  [errorMessage]="errorMessage"
  matAutocompleteOrigin
  #autocompleteOrigin="matAutocompleteOrigin"
>
  <label
    class="select-chips-label"
    [class.exposed]="contributorFormControl?.value?.length > 0 || searchControl.value"
    for="contributors"
  >
    {{ label }}
  </label>
  <mat-chip-list id="contributors" #contributorList aria-label="Selected contributors">
    <mat-chip
      *ngFor="let contributor of contributorFormControl?.value; trackBy: trackById"
      [removable]="true"
      (removed)="removeContributor(contributor)"
    >
      <app-business-user-slug
        [businessUserAcronym]="contributor.acronym"
        *ngIf="contributor.acronym"
      ></app-business-user-slug>
      <span tiimeTooltip [tooltipContent]="chipEmailTooltipTemplate" [tooltipHideWithoutEllipsis]="true">
        {{ contributor.fullName }}
      </span>
      <ng-template #chipEmailTooltipTemplate>{{ contributor.fullName }} - {{ contributor.email }}</ng-template>
      <mat-icon matChipRemove inline="true" fontIcon="icon-close-2c-r-24-fontastic"></mat-icon>
    </mat-chip>
    <input
      placeholder="Rechercher un intervenant ..."
      #searchInput
      [formControl]="searchControl"
      (focus)="openAutocomplete()"
      [matAutocomplete]="contributorAutocomplete.autocomplete"
      [matAutocompleteConnectedTo]="autocompleteOrigin"
      [matChipInputFor]="contributorList"
      *ngIf="searchControl.enabled"
    />
  </mat-chip-list>
  <app-contributor-autocomplete
    #contributorAutocomplete="ContributorAutocomplete"
    (selectRecord)="selectContributor($event)"
    [data]="filteredContributors$ | async"
    [interlocutors]="filteredInterlocutors"
    [displayInterlocutors]="
      (interlocutors?.length === 0 || (interlocutors?.length && filteredInterlocutors?.length > 0)) &&
      !searchControl.value?.length
    "
  ></app-contributor-autocomplete>
</tiime-input-container>
