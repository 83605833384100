import { Document, DocumentJson } from '@models/document';
import { Label } from '@models/label';

export interface CashRegisterCashFlowJson {
  id: number;
  date?: string;
  inflow?: number;
  outflow?: number;
  comment?: string;
  label?: Partial<Label>;
  documents?: DocumentJson[];
}

export class CashRegisterCashFlow {
  constructor(
    public id?: number,
    public date?: string,
    public inflow?: number,
    public outflow?: number,
    public comment?: string,
    public label?: Label,
    public documents?: Document[]
  ) {}

  static fromJson(json: Partial<CashRegisterCashFlowJson>): CashRegisterCashFlow {
    return new CashRegisterCashFlow(
      json.id,
      json.date,
      json.inflow,
      json.outflow,
      json.comment,
      json.label ? Label.fromJson(json.label) : null,
      json.documents ? json.documents.map(d => Document.fromJson(d)) : null
    );
  }
}
