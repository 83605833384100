<tiime-input-container
  tiimeFormInputContainer
  contentId="taxRegime"
  [attr.small]="small"
  [attr.fixed-label]="fixedLabel"
  [label]="label"
>
  <tiime-select
    id="taxRegime"
    [formControl]="control"
    [multiple]="multiple"
    (selectionChange)="emitSelectionChange($event)"
    (closeOptionsPanel)="closeOptionsPanel()"
  >
    <input tiimeSelectInput [formControl]="searchControl" appFormFocusObserve (formChanges)="searchControlChanges()" />
    <div tiimeSelectOptions>
      <mat-option [value]="null" *ngIf="allowEmptyOption">{{ emptyOptionLabel }}</mat-option>
      <mat-option *ngFor="let taxRegime of filteredSelectorData; trackBy: trackByCode" [value]="taxRegime.code">
        <ng-container *ngIf="condensedLabel; else labelTemplate">{{ taxRegime.condensedLabel }}</ng-container>
        <ng-template #labelTemplate>{{ taxRegime.label }}</ng-template>
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
