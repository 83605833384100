import { DateHelper } from '@helpers/date.helper';

import { CreationPublicationHelp, CreationPublicationHelpJson } from './creation-publication-help';

export interface CreationPublicationJournalJson {
  publication_journal_name: string;
  publication_journal_release_date: string;
  publication_journal_status: string;
  publication_journal_status_update_by: string;
  publication_journal_status_update_date: Date;
  parution_help: CreationPublicationHelpJson;
}

export class CreationPublicationJournal {
  constructor(
    public publicationJournalName?: string,
    public publicationJournalReleaseDate?: string,
    public publicationJournalStatus?: string,
    public publicationJournalStatusUpdateBy?: string,
    public publicationJournalStatusUpdateDate?: Date,
    public parutionHelp?: CreationPublicationHelp
  ) {}

  static fromJson(json: CreationPublicationJournalJson): CreationPublicationJournal {
    return new CreationPublicationJournal(
      json.publication_journal_name,
      json.publication_journal_release_date,
      json.publication_journal_status,
      json.publication_journal_status_update_by,
      json.publication_journal_status_update_date
        ? DateHelper.fromUtc(json.publication_journal_status_update_date)
        : null,
      json.parution_help
        ? {
            announcementTitle: json.parution_help.announcement_title,
            announcementReference: json.parution_help.announcement_reference,
            announcementContent: json.parution_help.announcement,
            departmentNumber: json.parution_help.department
          }
        : null
    );
  }

  static toJson(creationPublicationJournal: CreationPublicationJournal): CreationPublicationJournalJson {
    return {
      publication_journal_name: creationPublicationJournal.publicationJournalName,
      publication_journal_release_date: creationPublicationJournal.publicationJournalReleaseDate
        ? DateHelper.format(creationPublicationJournal.publicationJournalReleaseDate)
        : null,
      publication_journal_status: creationPublicationJournal.publicationJournalStatus,
      publication_journal_status_update_by: creationPublicationJournal.publicationJournalStatusUpdateBy
        ? DateHelper.format(creationPublicationJournal.publicationJournalStatusUpdateBy)
        : null,
      publication_journal_status_update_date: creationPublicationJournal.publicationJournalStatusUpdateDate,
      parution_help: {
        announcement_title: creationPublicationJournal.parutionHelp.announcementTitle,
        announcement_reference: creationPublicationJournal.parutionHelp.announcementReference,
        announcement: creationPublicationJournal.parutionHelp.announcementContent,
        department: creationPublicationJournal.parutionHelp.departmentNumber
      }
    };
  }

  static toPatchPublicationJournal(
    creationPublicationJournal: CreationPublicationJournal
  ): Pick<CreationPublicationJournalJson, 'publication_journal_name' | 'publication_journal_release_date'> {
    return {
      publication_journal_name: creationPublicationJournal.publicationJournalName,
      publication_journal_release_date: creationPublicationJournal.publicationJournalReleaseDate
        ? DateHelper.format(creationPublicationJournal.publicationJournalReleaseDate)
        : null
    };
  }

  static toPatchStatus(status: string): Pick<CreationPublicationJournalJson, 'publication_journal_status'> {
    return {
      publication_journal_status: status
    };
  }
}
