import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

import { NgUtils, StringUtils } from 'tiime-expert-utils';

import { ComplexSearchBarApiFilter } from './complex-search-bar-api-filter';
import { ComplexSearchBarAutocompleteFilter } from './complex-search-bar-autocomplete-filter';
import { ComplexSearchBarAutocompleteSection } from './complex-search-bar-autocomplete-section';
import { ComplexSearchBarFilter } from './complex-search-bar-filter';
import { HasAttributesBase } from '../core/index';

export interface Filter {
  displayedValue: string;
  apiFilter: ComplexSearchBarApiFilter;
}

@Component({
  selector: 'tiime-complex-search-bar',
  templateUrl: './complex-search-bar.component.html',
  styleUrls: ['./complex-search-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-complex-search-bar',
    '[class.tiime-complex-search-bar-disabled]': 'disabled',
    '[class.dark]': '_hasHostAttributes("dark")'
  }
})
export class ComplexSearchBarComponent extends HasAttributesBase implements OnInit {
  @Input() placeholder: string;
  @Input() complexSearchBarAutocompleteSections: ComplexSearchBarAutocompleteSection[];
  @Input() complexSearchBarFilters: ComplexSearchBarFilter[];
  @Input() disabled: boolean;
  @Input() viewportMargin = 2;
  @Input() offsetX: number;
  @Input() offsetY = 5;
  /**
   * Méthode permettant de transformer les filtres selon les besoins
   * On peut par exemple l'utiliser pour faire de la combinaison de filtre
   */
  @Input() transformFiltersFn?: (filters: Filter[]) => Filter[];

  @Output() readonly search: EventEmitter<ComplexSearchBarApiFilter[]> = new EventEmitter<
    ComplexSearchBarApiFilter[]
  >();

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;

  filterControl = new FormControl<string | null>(null);
  filters: Filter[] = [];
  filteredComplexSearchBarAutocompleteSections$: Observable<ComplexSearchBarAutocompleteSection[]>;

  readonly separatorKeysCodes: number[] = [ENTER];
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit(): void {
    this.filteredComplexSearchBarAutocompleteSections$ = this.filterControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string) => this.filteredAutocompleteSections(value || ''))
    );
  }

  matChipInputTokenEnd(event: MatChipInputEvent): void {
    if (this.autocompleteTrigger.activeOption) {
      return;
    }

    const value = event.value;

    this.addFilter(value);
  }

  addFilter(filter: string): void {
    if ((filter || '').trim()) {
      this.createFilter(filter);
      this.resetFilterInput();
      this.emitSearch();
    }
  }

  clearFilters(emitSearch = true): void {
    this.filters = [];
    this.resetFilterInput();
    if (emitSearch) {
      this.emitSearch();
    }
  }

  removeFilter(index: number): void {
    if (index !== -1) {
      this.filters.splice(index, 1);
      this.emitSearch();
    }
  }

  onSelectAutocompleteFilter(autocompleteFilter: ComplexSearchBarAutocompleteFilter): void {
    if (autocompleteFilter.needUserInteraction) {
      return;
    }

    this.addFilter(autocompleteFilter.filterValue);
    this.openAutocompletePanel();
  }

  private createFilter(value: string): void {
    let extractedValue: string;
    const complexSearchBarFilter = this.complexSearchBarFilters.find((filter: ComplexSearchBarFilter) => {
      extractedValue = value.substring(filter.identifier.length, value.length);
      return value.startsWith(filter.identifier) && extractedValue.match(filter.regex);
    });
    if (complexSearchBarFilter) {
      const filter: Filter = {
        displayedValue: complexSearchBarFilter.displayedValue(extractedValue),
        apiFilter: complexSearchBarFilter.apiFilter(extractedValue)
      };

      if (this.transformFiltersFn && typeof this.transformFiltersFn === 'function') {
        this.filters = this.transformFiltersFn([...this.filters, filter]);
      } else {
        this.filters.push(filter);
      }
    }
  }

  private emitSearch(): void {
    const complexSearchBarApiFilters: ComplexSearchBarApiFilter[] = this.filters.map(
      (filterValue: Filter) => filterValue.apiFilter
    );
    this.search.emit(complexSearchBarApiFilters);
  }

  private filteredAutocompleteSections(search: string): ComplexSearchBarAutocompleteSection[] {
    if (!search) {
      return this.complexSearchBarAutocompleteSections;
    }

    return this.complexSearchBarAutocompleteSections.map(section => {
      const filteredSection = { ...section };

      filteredSection.filters = section.filters.filter((autocompleteFilter: ComplexSearchBarAutocompleteFilter) =>
        StringUtils.normalizeToLowerCase(autocompleteFilter.filterValue).includes(
          StringUtils.normalizeToLowerCase(search)
        )
      );

      return filteredSection;
    });
  }

  private resetFilterInput(): void {
    setTimeout(() => this.filterControl.setValue(''));
  }

  private openAutocompletePanel(): void {
    setTimeout(() => this.autocompleteTrigger?.openPanel());
  }
}
