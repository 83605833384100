import { Injectable } from '@angular/core';

import { OverlayServiceBase, TiimeOverlayConfig } from 'tiime-material';

import { NotificationsOverlayComponent } from './notifications-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsOverlayService extends OverlayServiceBase<string, unknown> {
  open(data: unknown, config: TiimeOverlayConfig): this {
    this.overlayRef = this.overlayService.open(NotificationsOverlayComponent, data, {
      panelClass: ['tiime-overlay-panel', 'notifications-overlay-panel'],
      width: '380px',
      height: '500px',
      hasBackdrop: true,
      backdropClose: true,
      ...config
    });
    return this;
  }
}
