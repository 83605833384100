import { ChangeDetectionStrategy, Component, Directive, Input, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[tiimeTimelineItemTitle]',
  exportAs: 'tiime-timeline-item-title',
  host: {
    class: 'tiime-timeline-item-title'
  }
})
export class TimelineItemTitleDirective {}

@Directive({
  selector: '[tiimeTimelineItemDate]',
  exportAs: 'tiime-timeline-item-date',
  host: {
    class: 'tiime-timeline-item-date'
  }
})
export class TimelineItemDateDirective {}

@Directive({
  selector: '[tiimeTimelineItemDescription]',
  exportAs: 'tiime-timeline-item-description',
  host: {
    class: 'tiime-timeline-item-title-description'
  }
})
export class TimelineItemDescriptionDirective {}

@Component({
  selector: 'tiime-timeline-item',
  templateUrl: './timeline-item.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-timeline-item'
  }
})
export class TimelineItemComponent {
  @Input() color: string;
}

@Component({
  selector: 'tiime-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'tiime-timeline'
  }
})
export class TimelineComponent {}
