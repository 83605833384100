import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { Contributor, ContributorJson } from '@models/contributor';

export interface DataCollectionHomeJson {
  status?: string;
  last_status_by?: string;
  last_status_date?: string;
  mission_chief?: Partial<ContributorJson>;
  formalist?: Partial<ContributorJson>;
  mission_chief_custom_name?: string;
}

export class DataCollectionHome {
  constructor(
    public status?: string,
    public lastStatusBy?: string,
    public lastStatusDate?: Date,
    public missionChief?: Contributor,
    public formalist?: Contributor,
    public missionChiefCustomName?: string
  ) {}

  static fromJson(json: DataCollectionHomeJson): DataCollectionHome {
    return new DataCollectionHome(
      json.status,
      json.last_status_by,
      json.last_status_date ? DateHelper.fromUtc(json.last_status_date) : null,
      json.mission_chief ? Contributor.fromJson(json.mission_chief) : null,
      json.formalist ? Contributor.fromJson(json.formalist) : null,
      json.mission_chief_custom_name
    );
  }

  static toContributorJsonId(model: DataCollectionHome): Pick<DataCollectionHomeJson, 'formalist' | 'mission_chief'> {
    return {
      mission_chief: model.missionChief ? HttpHelper.toJsonId(model.missionChief) : null,
      formalist: model.formalist ? HttpHelper.toJsonId(model.formalist) : null
    };
  }

  static toJson(model: DataCollectionHome): DataCollectionHomeJson {
    return {
      status: model.status,
      last_status_by: model.lastStatusBy,
      last_status_date: model.lastStatusDate ? DateHelper.format(model.lastStatusDate) : null,
      mission_chief: model.missionChief ? HttpHelper.toJsonId(model.missionChief) : null,
      formalist: model.formalist ? HttpHelper.toJsonId(model.formalist) : null,
      mission_chief_custom_name: model.missionChiefCustomName
    };
  }
}
