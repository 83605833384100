import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { ThreadFilters, ThreadFiltersJson } from '@interfaces/thread-filters';
import { ThreadInfosFilters, ThreadInfosFiltersJson } from '@interfaces/thread-infos-filters';
import { Contributor } from '@models/contributor';
import { Message, MessageJson } from '@models/message';
import { Thread, ThreadJson } from '@models/thread';
import { ThreadTag, ThreadTagJson } from '@models/thread-tag';
import { ThreadsInfos, ThreadsInfosJson } from '@models/threads-infos';

@Injectable({
  providedIn: 'root'
})
export class ThreadApiService extends ApiCommonService<Thread> {
  readonly resourceUrl: string = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/threads`;

  fromJson(json: Partial<ThreadJson>): Thread {
    return Thread.fromJson(json);
  }

  toJson(model: Thread): Partial<ThreadJson> {
    return Thread.toJson(model);
  }

  threadFiltersJson(filters: ThreadFilters): ThreadFiltersJson {
    return {
      date:
        filters.startDate || filters.endDate ? HttpHelper.dateFilter(filters.startDate, filters.endDate) : undefined,
      contributor_ids: filters?.contributors?.length ? HttpHelper.idsFilter(filters?.contributors) : undefined,
      tags: filters?.tags?.length ? HttpHelper.idsFilter(filters?.tags) : undefined,
      status: filters?.status || undefined,
      types: filters?.type || undefined,
      accounting_period:
        filters?.type === ThreadEntityType.accountingPeriod && filters.entity?.id
          ? String(filters.entity.id)
          : undefined,
      vat_declaration:
        filters?.type === ThreadEntityType.vatDeclaration && filters.entity?.id ? String(filters.entity.id) : undefined,
      bank_transaction:
        filters?.type === ThreadEntityType.bankTransaction && filters.entity?.id
          ? String(filters.entity.id)
          : undefined,
      task: filters?.type === ThreadEntityType.task && filters.entity?.id ? String(filters.entity.id) : undefined,
      '2571':
        filters?.type === ThreadEntityType.corporateTaxInstalment && filters.entity?.id
          ? String(filters.entity.id)
          : undefined,
      category: filters?.category || undefined
    };
  }

  threadInfosFiltersJson(filters: ThreadInfosFilters): ThreadInfosFiltersJson {
    return {
      thread_types: filters?.threadTypes || undefined,
      vat_declaration: filters?.vatDeclaration ? String(filters?.vatDeclaration) : undefined,
      accounting_period: filters?.accountingPeriod ? String(filters?.accountingPeriod) : undefined,
      task: filters?.task ? String(filters?.task) : undefined
    };
  }

  @ApiAlertError()
  getUnpaginatedThreads(filters?: ThreadFilters, companyId?: number): Observable<Thread[]> {
    let url = this.resourceUrl;
    let params = HttpHelper.createHttpParams();

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    if (filters) {
      params = HttpHelper.setParams(this.threadFiltersJson(filters), params);
    }

    return super.getAll({ params }, url);
  }

  @ApiAlertError()
  getThreads(
    range: PaginationRange,
    searchTerms?: string,
    filters?: ThreadFilters,
    companyId?: number
  ): Observable<PaginationData<Thread>> {
    let url = this.resourceUrl;
    let params = HttpHelper.createHttpParams();

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    if (searchTerms) {
      params = HttpHelper.setQParam(searchTerms, params);
    }

    if (filters) {
      params = HttpHelper.setParams(this.threadFiltersJson(filters), params);
    }

    return super.getPaginated(range, params, url);
  }

  @ApiAlertError()
  createThread(thread: Thread, companyId?: number): Observable<Thread> {
    let url = this.resourceUrl;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.create(thread, url);
  }

  @ApiAlertError()
  closeThread(threadId: number, closed: boolean, companyId?: number): Observable<void> {
    let url = `${this.resourceUrl}/${threadId}/${closed ? 'close' : 'unclose'}`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<void>(url, null);
  }

  @ApiAlertError()
  archiveThread(threadId: number, archived: boolean, companyId?: number): Observable<void> {
    let url = `${this.resourceUrl}/${threadId}/${archived ? 'archive' : 'unarchive'}`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<void>(url, null);
  }

  @ApiAlertError()
  updateThreadInterlocutors(threadId: number, interlocutors: Contributor[], companyId?: number): Observable<Thread> {
    let url = `${this.resourceUrl}/${threadId}`;
    const body: Partial<ThreadJson> = {
      id: threadId,
      interlocutors: interlocutors.map(interlocutor => HttpHelper.toJsonId(interlocutor))
    };

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.patch(url, body).pipe(map(threadJson => Thread.fromJson(threadJson)));
  }

  @ApiAlertError()
  updateThreadTags(threadId: number, tags: ThreadTag[], companyId?: number): Observable<Thread> {
    let url = `${this.resourceUrl}/${threadId}`;
    const body: Partial<ThreadJson> = {
      id: threadId,
      tags: tags?.length ? tags.map((tag: ThreadTag) => ThreadTag.toJson(tag)) : []
    };

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.patch(url, body).pipe(map(threadJson => Thread.fromJson(threadJson)));
  }

  @ApiAlertError()
  createMessage(threadId: number, content: string, companyId?: number): Observable<Message> {
    let url = `${this.resourceUrl}/${threadId}/messages`;
    const body = { content };

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<MessageJson>(url, body).pipe(map(json => Message.fromJson(json)));
  }

  @ApiAlertError()
  updateMessage(threadId: number, messageId: number, content: string, companyId?: number): Observable<Message> {
    let url = `${this.resourceUrl}/${threadId}/messages/${messageId}`;
    const body = { content };

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.patch<MessageJson>(url, body).pipe(map(json => Message.fromJson(json)));
  }

  @ApiAlertError()
  getThreadsInfos(filters?: ThreadInfosFilters, companyId?: number): Observable<ThreadsInfos> {
    let url = `${this.resourceUrl}/infos`;
    let params = HttpHelper.createHttpParams();

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    if (filters) {
      params = HttpHelper.setParams(this.threadInfosFiltersJson(filters), params);
    }

    return this.http
      .get(url, { params })
      .pipe(map((threadsInfosJson: ThreadsInfosJson) => ThreadsInfos.fromJson(threadsInfosJson)));
  }

  @ApiAlertError()
  markThreadAsRead(threadId: number, companyId?: number): Observable<void> {
    let url = `${this.resourceUrl}/${threadId}/read`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<void>(url, null);
  }

  @ApiAlertError()
  getTags(companyId?: number): Observable<ThreadTag[]> {
    let url = `${this.resourceUrl}/tags`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .get<ThreadTagJson[]>(url)
      .pipe(map((jsons: ThreadTagJson[]) => jsons.map((json: ThreadTagJson) => ThreadTag.fromJson(json))));
  }

  @ApiAlertError()
  archiveTag(tagId: number, archived: boolean, companyId?: number): Observable<void> {
    let url = `${this.resourceUrl}/tags/${tagId}/${archived ? 'archive' : 'unarchive'}`;

    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http.post<void>(url, null);
  }
}
