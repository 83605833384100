import { VatDeclarationContentType } from '@enums/vat-declaration-content-type.enum';
import { VatDeclarationContentValue, VatDeclarationContentValueJson } from '@models/vat-declaration-content-value';

export interface VatDeclarationContentJson {
  type: VatDeclarationContentType;
  class: string;
  label: string;
  hidden: boolean;
  code: string;
  columns: Partial<VatDeclarationContentValueJson>[];
  lines: Partial<VatDeclarationContentValueJson>[];
  collapsed: boolean;
  total_label: string;
  metadata: Record<string, unknown>;
}

export class VatDeclarationContent {
  constructor(
    public type?: VatDeclarationContentType,
    public contentClass?: string,
    public label?: string,
    public hidden?: boolean,
    public code?: string,
    public columns?: VatDeclarationContentValue[],
    public lines?: VatDeclarationContentValue[],
    public collapsed?: boolean,
    public totalLabel?: string,
    public metadata?: Record<string, unknown>
  ) {}

  static fromJson(json: Partial<VatDeclarationContentJson>): VatDeclarationContent {
    return new VatDeclarationContent(
      json.type,
      json.class,
      json.label,
      json.hidden,
      json.code,
      json.columns ? json.columns.map(columnsJson => VatDeclarationContentValue.fromJson(columnsJson)) : [],
      json.lines ? json.lines.map(linesJson => VatDeclarationContentValue.fromJson(linesJson)) : [],
      json.collapsed,
      json.total_label,
      json.metadata
    );
  }

  static toJson(vatDeclarationContent: VatDeclarationContent): Partial<VatDeclarationContentJson> {
    return {
      type: vatDeclarationContent.type,
      class: vatDeclarationContent.contentClass,
      label: vatDeclarationContent.label,
      hidden: vatDeclarationContent.hidden,
      code: vatDeclarationContent.code,
      columns: vatDeclarationContent.columns,
      lines: vatDeclarationContent.lines.map(linesJson => VatDeclarationContentValue.toJson(linesJson)),
      collapsed: vatDeclarationContent.collapsed,
      total_label: vatDeclarationContent.totalLabel || undefined,
      metadata: vatDeclarationContent.metadata || undefined
    };
  }
}
