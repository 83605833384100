import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

import { UntilDestroy } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { TiimeIconModule, TiimeInputContainerModule, TiimeTooltipModule } from 'tiime-material';

import { ContributorSelectBase } from '@bases/contributor-select/contributor-select.base';
import { CompanyContributor } from '@models/company-contributor';
import { ContributorAutocompleteComponent } from '@modules/shared/autocompletes/contributor-autocomplete/contributor-autocomplete.component';
import { BusinessUserSlugComponent } from '@modules/shared/components/business-user-slug/business-user-slug.component';
import { ContributorOptionComponent } from '@modules/shared/options/contributor-option/contributor-option.component';

@UntilDestroy({ checkProperties: true })
@Component({
  standalone: true,
  selector: 'app-contributor-select-multiple',
  templateUrl: './contributor-select-multiple.component.html',
  styleUrls: ['../../../core/bases/selector/select-chips.base.scss', './contributor-select-multiple.component.scss'],
  imports: [
    ContributorAutocompleteComponent,
    TiimeInputContainerModule,
    MatChipsModule,
    CommonModule,
    TiimeTooltipModule,
    TiimeIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    BusinessUserSlugComponent,
    ContributorOptionComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContributorSelectMultipleComponent extends ContributorSelectBase implements OnInit {
  @Input() label = 'Intervenants';
  @Input() marginTop = true;
  @Input() errorMessage: string;
  @Input('searchDisabled') set setSearchDisabled(disabled: boolean) {
    if (disabled) {
      this.resetSearch();
      this.searchControl?.disable();
    } else {
      this.searchControl?.enable();
    }
  }

  filteredInterlocutors: CompanyContributor[] = [];

  ngOnInit(): void {
    this.filterInterlocutors(this.contributorFormControl.value as CompanyContributor[]);
    this.observeContributorFormControl();
  }

  observeContributorFormControl(): void {
    this.subscription.add(
      this.contributorFormControl.valueChanges
        .pipe(tap(() => this.filterInterlocutors(this.contributorFormControl.value as CompanyContributor[])))
        .subscribe()
    );
  }

  filterInterlocutors(interlocutors: CompanyContributor[]): void {
    this.filteredInterlocutors = this.interlocutors?.filter(
      interlocutor => !interlocutors?.some(contributor => contributor.id === interlocutor.user.id)
    );
  }
}
