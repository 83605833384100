import { OverlayRef } from '@angular/cdk/overlay';

import { Observable, Subject } from 'rxjs';

export class TiimeOverlayRef<T = any> {
  component: T;

  private readonly afterClosedSub = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  close(data: any = null): void {
    this.overlayRef.dispose();
    this.afterClosedSub.next(data);
    this.afterClosedSub.complete();
  }

  afterClosed(): Observable<any> {
    return this.afterClosedSub.asObservable();
  }
}
