export interface JournalTypeJson {
  type: string;
  label: string;
  needs_pcg_entry: string | boolean;
}

export class JournalType {
  constructor(public type: string, public label: string, public needsPcgEntry: string | boolean) {}

  static fromJson(json: Partial<JournalTypeJson>): JournalType {
    return new JournalType(json.type, json.label, json.needs_pcg_entry);
  }
}
