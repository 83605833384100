import { BeneficiaryAccount, BeneficiaryAccountJson } from './beneficiary-account';

export interface ConventionJson {
  beneficiaries_accounts: BeneficiaryAccountJson[];
}

export class Convention {
  constructor(public beneficiaryAccounts: BeneficiaryAccount[]) {}

  static fromJson(json: ConventionJson): Convention {
    return new Convention(
      json.beneficiaries_accounts
        ? json.beneficiaries_accounts.map((beneficiaryAccountJson: BeneficiaryAccountJson) =>
            BeneficiaryAccount.fromJson(beneficiaryAccountJson)
          )
        : null
    );
  }

  static toJson(convention: Convention): ConventionJson {
    return {
      beneficiaries_accounts: convention.beneficiaryAccounts
        ? convention.beneficiaryAccounts.map((beneficiaryAccount: BeneficiaryAccount) =>
            BeneficiaryAccount.toJson(beneficiaryAccount)
          )
        : null
    };
  }
}
