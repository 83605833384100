<tiime-input-container tiimeFormInputContainer contentId="apeCode" label="Code APE">
  <tiime-select
    id="apeCode"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
    data-cy="company-form-ape-code-input"
    (closeOptionsPanel)="closeOptionsPanel()"
  >
    <input
      tiimeSelectInput
      placeholder="Rechecher un code APE"
      [formControl]="searchControl"
      appFormFocusObserve
      (formChanges)="searchControlChanges()"
    />
    <div tiimeSelectOptions>
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let apeCode of filteredSelectorData; trackBy: trackByCode" [value]="apeCode.id">
        {{ apeCode.code }} - {{ apeCode.label }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
