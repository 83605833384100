import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { EXTERNAL_ENDPOINTS } from '@constants/external-endpoints.constant';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent {
  navigateToTiimePulse(): void {
    window.open(EXTERNAL_ENDPOINTS.tiimeExpertWebsiteUrl, '_blank');
  }
}
