import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban',
  standalone: true
})
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return '';
    }
    value = value.trim().replace(/[ \-_éèà]/g, '');

    let result = '';
    for (let i = 0; i < 7; i++) {
      if (value.length >= 4) {
        result += value.substring(0, 4);
        value = value.substring(4);
        if (value.length > 0) {
          result += ' ';
        }
      }
    }

    result += value;
    return result;
  }
}

/**
 * @deprecated Use `iban` instead.
 */
@Pipe({
  name: 't2UtilsIbanPipe',
  standalone: true
})
export class OldIbanPipe extends IbanPipe {}
