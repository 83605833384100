<tiime-input-container
  [attr.small]="small"
  [label]="label"
  contentId="label-category-plan-select"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="label-category-plan-select"
    [placeholder]="label"
    [class.selected]="ctrl?.value"
    [formControl]="ctrl"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null" [disabled]="emptyOptionDisabled" *ngIf="emptyOption">
        {{ emptyOptionLabel }}
      </mat-option>
      <mat-option
        *ngFor="let labelCategoryPlan of labelCategoryPlans; trackBy: trackById"
        [value]="labelCategoryPlan.id"
      >
        {{ labelCategoryPlan.label }}
      </mat-option>
      <mat-option (onSelectionChange)="openCreateLabelsCategoryPlanDialog($event)" *ngIf="createOption">
        <div class="create-option-content">
          <mat-icon fontIcon="icon-add-fontastic"></mat-icon>
          {{ createOptionLabel }}
        </div>
      </mat-option>
    </div>
    <button
      tiime-button
      icon
      color="primary"
      (click)="openUpdateLabelCategoryPlanDialog($event)"
      tiimeSelectSuffix
      *ngIf="updateOption"
    >
      <mat-icon fontIcon="icon-header-grey-n3-24px-edit"></mat-icon>
    </button>
  </tiime-select>
</tiime-input-container>
