import { LeaseContractPropertyTypeEnum } from '@enums/lease-contract-property-type.enum';
import { LeaseContractTypeEnum } from '@enums/lease-contract-type.enum';
import { LeaseContractVatProcessingTypeEnum } from '@enums/lease-contract-vat-processing-type.enum';
import { PaymentTypeEnum } from '@enums/payment-type.enum';
import { PeriodicityEnum } from '@enums/periodicity.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import {
  LeaseContractAccountingPeriodData,
  LeaseContractAccountingPeriodDataJson
} from '@models/lease-contract-accounting-period-data';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { Piece, PieceJson } from '@models/piece';

export interface LeaseContractJson {
  id: number;
  description: string;
  type: LeaseContractTypeEnum;
  period_count: number;
  periodicity: PeriodicityEnum;
  date: string;
  first_schedule_date: string;
  last_schedule_date: string;
  pcg_entry: Partial<PcgEntryJson>;
  payment_type: PaymentTypeEnum;
  original_value: number;
  schedule_amount_excluding_tax: number;
  schedule_insurance_amount: number;
  schedule_fees_amount: number;
  schedule_vat_amount: number;
  schedule_amount_including_tax: number;
  vat_processing: LeaseContractVatProcessingTypeEnum;
  first_schedule_increased: boolean;
  first_schedule_amount_excluding_tax: number;
  first_schedule_insurance_amount: number;
  first_schedule_fees_amount: number;
  first_schedule_vat_amount: number;
  first_schedule_amount_including_tax: number;
  reference: string;
  financial_organization: string;
  property_type: LeaseContractPropertyTypeEnum;
  piece: Partial<PieceJson>;
  comment: string;
  lease_contract_accounting_periods: Partial<LeaseContractAccountingPeriodDataJson>[];
}

export type SerializedLeaseContractJson = Omit<LeaseContractJson, 'piece' | 'lease_contract_accounting_periods'>;

export class LeaseContract {
  constructor(
    public id?: number,
    public description?: string,
    public type?: LeaseContractTypeEnum,
    public periodCount?: number,
    public periodicity?: PeriodicityEnum,
    public date?: string,
    public firstScheduleDate?: string,
    public lastScheduleDate?: string,
    public pcgEntry?: PcgEntry,
    public paymentType?: PaymentTypeEnum,
    public originalValue?: number,
    public scheduleAmountExcludingTax?: number,
    public scheduleInsuranceAmount?: number,
    public scheduleFeesAmount?: number,
    public scheduleVatAmount?: number,
    public scheduleAmountIncludingTax?: number,
    public vatProcessing?: LeaseContractVatProcessingTypeEnum,
    public firstScheduleIncreased?: boolean,
    public firstScheduleAmountExcludingTax?: number,
    public firstScheduleInsuranceAmount?: number,
    public firstScheduleFeesAmount?: number,
    public firstScheduleVatAmount?: number,
    public firstScheduleAmountIncludingTax?: number,
    public reference?: string,
    public financialOrganization?: string,
    public propertyType?: LeaseContractPropertyTypeEnum,
    public piece?: Piece,
    public comment?: string,
    public leaseContractAccountingPeriods?: LeaseContractAccountingPeriodData[]
  ) {}

  static fromJson(json: Partial<LeaseContractJson>): LeaseContract {
    return new LeaseContract(
      json.id,
      json.description,
      json.type,
      json.period_count,
      json.periodicity,
      json.date,
      json.first_schedule_date,
      json.last_schedule_date,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : undefined,
      json.payment_type,
      json.original_value,
      json.schedule_amount_excluding_tax,
      json.schedule_insurance_amount,
      json.schedule_fees_amount,
      json.schedule_vat_amount,
      json.schedule_amount_including_tax,
      json.vat_processing,
      json.first_schedule_increased,
      json.first_schedule_amount_excluding_tax,
      json.first_schedule_insurance_amount,
      json.first_schedule_fees_amount,
      json.first_schedule_vat_amount,
      json.first_schedule_amount_including_tax,
      json.reference,
      json.financial_organization,
      json.property_type,
      json.piece ? Piece.fromJson(json.piece) : undefined,
      json.comment,
      json.lease_contract_accounting_periods
        ? json.lease_contract_accounting_periods.map((a: Partial<LeaseContractAccountingPeriodDataJson>) =>
            LeaseContractAccountingPeriodData.fromJson(a)
          )
        : []
    );
  }

  static toJson(data: LeaseContract): SerializedLeaseContractJson {
    return {
      id: data.id,
      description: data.description,
      type: data.type,
      period_count: data.periodCount,
      periodicity: data.periodicity,
      date: data.date ? DateHelper.format(data.date) : null,
      first_schedule_date: data.firstScheduleDate ? DateHelper.format(data.firstScheduleDate) : null,
      last_schedule_date: data.lastScheduleDate ? DateHelper.format(data.lastScheduleDate) : null,
      pcg_entry: data.pcgEntry ? HttpHelper.toJsonId(data.pcgEntry) : null,
      payment_type: data.paymentType,
      original_value: data.originalValue,
      schedule_amount_excluding_tax: data.scheduleAmountExcludingTax,
      schedule_insurance_amount: data.scheduleInsuranceAmount,
      schedule_fees_amount: data.scheduleFeesAmount,
      schedule_vat_amount: data.scheduleVatAmount,
      schedule_amount_including_tax: data.scheduleAmountIncludingTax,
      vat_processing: data.vatProcessing,
      first_schedule_increased: data.firstScheduleIncreased,
      first_schedule_amount_excluding_tax: data.firstScheduleAmountExcludingTax,
      first_schedule_insurance_amount: data.firstScheduleInsuranceAmount,
      first_schedule_fees_amount: data.firstScheduleFeesAmount,
      first_schedule_vat_amount: data.firstScheduleVatAmount,
      first_schedule_amount_including_tax: data.firstScheduleAmountIncludingTax,
      reference: data.reference,
      financial_organization: data.financialOrganization,
      property_type: data.propertyType,
      comment: data.comment
    };
  }
}
