export interface LabelsPlanJson {
  id: number;
  name: string;
}

export class LabelsPlan {
  constructor(public id?: number, public name?: string) {}

  static fromJson(json: LabelsPlanJson): LabelsPlan {
    return new LabelsPlan(json.id, json.name);
  }
}
