<div class="invitation-container">
  <mat-slide-toggle [formControl]="showForm" color="primary">
    <span>Inviter mon client sur les applications Tiime ?</span>
  </mat-slide-toggle>
  <ng-container *ngIf="showForm.value">
    <span class="invitation-title">Sur quelle(s) application(s) souhaitez-vous inviter votre contact ?</span>
    <div class="features" [formGroup]="invitationForm.features">
      <div class="feature-wrapper">
        <tiime-checkbox-card formControlName="invoiceApp">
          <ng-container cardHeader>
            <tiime-twocolors-icon
              class="feature-icon invoice-icon"
              icon="ic_twocolors_invoice_logo"
              size="24"
            ></tiime-twocolors-icon>
            <div class="feature-title">Tiime Invoice</div>
          </ng-container>
          <div cardDescription>Pour créer factures et devis en un clin d’œil</div>
        </tiime-checkbox-card>
      </div>
      <div class="feature-wrapper">
        <tiime-checkbox-card formControlName="receiptApp">
          <ng-container cardHeader>
            <tiime-twocolors-icon
              class="feature-icon accounts-icon"
              icon="ic_twocolors_accounts_logo"
              size="24"
            ></tiime-twocolors-icon>
            <div class="feature-title">Tiime</div>
          </ng-container>
          <div cardDescription>Pour centraliser toutes les factures d’achats</div>
        </tiime-checkbox-card>
      </div>
    </div>
    <app-invitation-channel-select
      [userPhone]="userPhoneNumber"
      [formControl]="invitationForm.channel"
    ></app-invitation-channel-select>
    <div class="features">
      <div class="feature-wrapper formation-invite">
        <tiime-checkbox-card [formControl]="invitationForm.controls.withFormation">
          <ng-container cardHeader>
            <div class="feature-title">
              J’autorise Tiime à contacter le client pour le former sur les Applications Tiime
            </div>
          </ng-container>
        </tiime-checkbox-card>
      </div>
    </div>
  </ng-container>
</div>
