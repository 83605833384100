import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { TiimeFormModule, TiimeInputContainerModule } from 'tiime-material';

import { CommonDataApiService } from '@api-services/common-data-api.service';
import { AddressForm } from '@forms/user/address-form';
import { Country } from '@models/country';
import { CountrySelectComponent } from '@shared-selectors/country-select/country-select.component';

@Component({
  standalone: true,
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, TiimeFormModule, TiimeInputContainerModule, CountrySelectComponent]
})
export class AddressFormComponent {
  @Input() addressForm: AddressForm;
  @Input() tiimeFormExpanded: boolean;

  readonly countries$: Observable<Country[]> = this.commonDataApiService.getCountries();

  constructor(private commonDataApiService: CommonDataApiService) {}
}
