import { AccountingLabelGenerationType } from '@enums/accounting-label-generation-type.enum';
import { VatExigibility } from '@enums/vat-exigibility.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { Journal, JournalJson } from '@models/journal';
import { LabelCategoryPlan, LabelCategoryPlanJson } from '@models/label-category-plan';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { User, UserJson } from '@models/user';

export interface AccountingConfigJson {
  id?: number;
  label_generation_type: AccountingLabelGenerationType;
  temporary_account_number?: string;
  accounting_synchronization_start_date?: string | null;
  invoice_accounting_start_date: string | null;
  receipt_accounting_start_date: string | null;
  invoice_accounting: boolean | null;
  receipt_accounting: boolean | null;
  sales_account_centralizer_pcg_entry: Partial<PcgEntryJson>;
  purchases_account_centralizer_pcg_entry: Partial<PcgEntryJson>;
  expense_report_accounting: boolean | null;
  expense_report_accounting_start_date: string | null;
  locked_by_accountant_date?: string | null;
  vat_declaration_auto_process: boolean | null;
  vat_declaration_auto_process_updated_at?: string | null;
  vat_declaration_auto_process_updated_by?: Partial<UserJson>;
  sales_bank_account_number_autocomplete?: boolean | null;
  label_locked?: boolean | null;
  custom_accounting_period_end_date?: string | null;
  cash_register_accounting?: boolean | null;
  cash_register_accounting_start_date?: string | null;
  use_advance_ocr?: boolean;
  is_advance_ocr_option_locked?: boolean;
  advance_ocr_activated?: boolean | null;
  vat_declaration_auto_complete?: boolean | null;
  invoice_vat_exigibility?: VatExigibility;
  category_setup_plan?: LabelCategoryPlanJson;
  label_in_movement_wording?: boolean;
  receipt_label_in_movement_wording?: boolean;
  vat_declaration_auto_process_limits_activated?: boolean;
  vat_declaration_auto_process_limit_min?: number;
  vat_declaration_auto_process_limit_max?: number;
  external_invoice_accounting?: boolean | null;
  external_invoice_accounting_start_date?: string | null;
  do_not_follow_vat_planning?: boolean | null;
  do_not_follow_vat_planning_by?: Partial<UserJson>;
  do_not_follow_vat_planning_at?: string;
  vat_declaration_journal?: Partial<JournalJson>;
  vat_declaration_client_confirmation: boolean;
  vat_declaration_client_confirmation_contact: Partial<UserJson>;
  vat_declaration_client_confirmation_email: boolean;
  vat_declaration_client_confirmation_sms: boolean;
  generate_regularization_accounting_entry_on_supplier: boolean;
  generate_regularization_accounting_entry_on_client: boolean;
  immobilisation_journal?: Partial<JournalJson>;
}

export class AccountingConfig {
  constructor(
    public id?: number,
    public labelGenerationType?: AccountingLabelGenerationType,
    public temporaryAccountNumber?: string,
    public accountingSynchronizationStartDate?: string | null,
    public invoiceAccountingStartDate?: string | null,
    public receiptAccountingStartDate?: string | null,
    public invoiceAccounting?: boolean | null,
    public receiptAccounting?: boolean | null,
    public salesAccountCentralizerPcgEntry?: PcgEntry,
    public purchasesAccountCentralizerPcgEntry?: PcgEntry,
    public expenseReportAccounting?: boolean | null,
    public expenseReportAccountingStartDate?: string | null,
    public lockedByAccountantDate?: string | null,
    public vatDeclarationAutoProcess?: boolean | null,
    public vatDeclarationAutoProcessUpdatedAt?: string | null,
    public vatDeclarationAutoProcessUpdatedBy?: User | null,
    public salesBankAccountNumberAutocomplete?: boolean | null,
    public labelLocked?: boolean | null,
    public customAccountingPeriodEndDate?: string | null,
    public cashRegisterAccounting?: boolean | null,
    public cashRegisterAccountingStartDate?: string | null,
    public useAdvanceOcr?: boolean,
    public isAdvanceOcrOptionLocked?: boolean,
    public advanceOcrActivated?: boolean | null,
    public vatDeclarationAutoComplete?: boolean | null,
    public invoiceVatExigibility?: VatExigibility,
    public categorySetupPlan?: LabelCategoryPlan,
    public labelInMovementWording?: boolean,
    public receiptLabelInMovementWording?: boolean,
    public vatDeclarationAutoProcessLimitsActivated?: boolean,
    public vatDeclarationAutoProcessLimitMin?: number,
    public vatDeclarationAutoProcessLimitMax?: number,
    public externalInvoiceAccounting?: boolean | null,
    public externalInvoiceAccountingStartDate?: string | null,
    public doNotFollowVatPlanning?: boolean | null,
    public doNotFollowVatPlanningBy?: Partial<User>,
    public doNotFollowVatPlanningAt?: Date,
    public vatDeclarationJournal?: Journal,
    public vatDeclarationClientConfirmation?: boolean,
    public vatDeclarationClientConfirmationContact?: Partial<User>,
    public vatDeclarationClientConfirmationEmail?: boolean,
    public vatDeclarationClientConfirmationSms?: boolean,
    public generateRegularizationAccountingEntryOnSupplier?: boolean,
    public generateRegularizationAccountingEntryOnClient?: boolean,
    public immobilisationJournal?: Journal
  ) {}

  static fromJson(json: Partial<AccountingConfigJson>): AccountingConfig {
    return new AccountingConfig(
      json.id,
      json.label_generation_type,
      json.temporary_account_number,
      json.accounting_synchronization_start_date,
      json.invoice_accounting_start_date,
      json.receipt_accounting_start_date,
      json.invoice_accounting,
      json.receipt_accounting,
      json.sales_account_centralizer_pcg_entry ? PcgEntry.fromJson(json.sales_account_centralizer_pcg_entry) : null,
      json.purchases_account_centralizer_pcg_entry
        ? PcgEntry.fromJson(json.purchases_account_centralizer_pcg_entry)
        : null,
      json.expense_report_accounting,
      json.expense_report_accounting_start_date,
      json.locked_by_accountant_date,
      json.vat_declaration_auto_process,
      json.vat_declaration_auto_process_updated_at,
      json.vat_declaration_auto_process_updated_by ? User.fromJson(json.vat_declaration_auto_process_updated_by) : null,
      json.sales_bank_account_number_autocomplete,
      json.label_locked,
      json.custom_accounting_period_end_date,
      json.cash_register_accounting,
      json.cash_register_accounting_start_date,
      json.use_advance_ocr,
      json.is_advance_ocr_option_locked,
      json.advance_ocr_activated,
      json.vat_declaration_auto_complete,
      json.invoice_vat_exigibility,
      json.category_setup_plan ? LabelCategoryPlan.fromJson(json.category_setup_plan) : null,
      json.label_in_movement_wording,
      json.receipt_label_in_movement_wording,
      json.vat_declaration_auto_process_limits_activated,
      json.vat_declaration_auto_process_limit_min,
      json.vat_declaration_auto_process_limit_max,
      json.external_invoice_accounting,
      json.external_invoice_accounting_start_date,
      json.do_not_follow_vat_planning,
      json.do_not_follow_vat_planning_by ? User.fromJson(json.do_not_follow_vat_planning_by) : null,
      json.do_not_follow_vat_planning_at ? DateHelper.fromUtc(json.do_not_follow_vat_planning_at) : null,
      json.vat_declaration_journal,
      json.vat_declaration_client_confirmation,
      json.vat_declaration_client_confirmation_contact
        ? User.fromJson(json.vat_declaration_client_confirmation_contact)
        : null,
      json.vat_declaration_client_confirmation_email,
      json.vat_declaration_client_confirmation_sms,
      json.generate_regularization_accounting_entry_on_supplier,
      json.generate_regularization_accounting_entry_on_client,
      json.immobilisation_journal ? Journal.fromJson(json.immobilisation_journal) : null
    );
  }

  static toJson(accountingConfig: AccountingConfig): AccountingConfigJson {
    return {
      id: accountingConfig.id,
      label_generation_type: accountingConfig.labelGenerationType,
      temporary_account_number: accountingConfig.temporaryAccountNumber,
      accounting_synchronization_start_date: accountingConfig.accountingSynchronizationStartDate,
      invoice_accounting_start_date: accountingConfig.invoiceAccountingStartDate,
      receipt_accounting_start_date: accountingConfig.receiptAccountingStartDate,
      invoice_accounting: accountingConfig.invoiceAccounting,
      receipt_accounting: accountingConfig.receiptAccounting,
      sales_account_centralizer_pcg_entry: accountingConfig.salesAccountCentralizerPcgEntry
        ? PcgEntry.toJson(accountingConfig.salesAccountCentralizerPcgEntry)
        : accountingConfig.salesAccountCentralizerPcgEntry === null
        ? null
        : undefined,
      purchases_account_centralizer_pcg_entry: accountingConfig.purchasesAccountCentralizerPcgEntry
        ? PcgEntry.toJson(accountingConfig.purchasesAccountCentralizerPcgEntry)
        : accountingConfig.purchasesAccountCentralizerPcgEntry === null
        ? null
        : undefined,
      expense_report_accounting: accountingConfig.expenseReportAccounting,
      expense_report_accounting_start_date: accountingConfig.expenseReportAccountingStartDate,
      locked_by_accountant_date: accountingConfig.lockedByAccountantDate,
      vat_declaration_auto_process: accountingConfig.vatDeclarationAutoProcess,
      sales_bank_account_number_autocomplete: accountingConfig.salesBankAccountNumberAutocomplete,
      label_locked: accountingConfig.labelLocked,
      custom_accounting_period_end_date: accountingConfig.customAccountingPeriodEndDate,
      cash_register_accounting: accountingConfig.cashRegisterAccounting,
      cash_register_accounting_start_date: accountingConfig.cashRegisterAccountingStartDate,
      use_advance_ocr: accountingConfig.useAdvanceOcr,
      is_advance_ocr_option_locked: accountingConfig.isAdvanceOcrOptionLocked,
      advance_ocr_activated: accountingConfig.advanceOcrActivated,
      vat_declaration_auto_complete: accountingConfig.vatDeclarationAutoComplete,
      invoice_vat_exigibility: accountingConfig.invoiceVatExigibility,
      category_setup_plan: accountingConfig.categorySetupPlan
        ? LabelCategoryPlan.toJson(accountingConfig.categorySetupPlan)
        : accountingConfig.categorySetupPlan === null
        ? null
        : undefined,
      label_in_movement_wording: accountingConfig.labelInMovementWording,
      receipt_label_in_movement_wording: accountingConfig.receiptLabelInMovementWording,
      vat_declaration_auto_process_limits_activated: accountingConfig.vatDeclarationAutoProcessLimitsActivated,
      vat_declaration_auto_process_limit_min: accountingConfig.vatDeclarationAutoProcessLimitMin,
      vat_declaration_auto_process_limit_max: accountingConfig.vatDeclarationAutoProcessLimitMax,
      external_invoice_accounting: accountingConfig.externalInvoiceAccounting,
      external_invoice_accounting_start_date: accountingConfig.externalInvoiceAccountingStartDate,
      do_not_follow_vat_planning: accountingConfig.doNotFollowVatPlanning,
      vat_declaration_journal: accountingConfig.vatDeclarationJournal
        ? Journal.toJson(accountingConfig.vatDeclarationJournal)
        : null,
      vat_declaration_client_confirmation: accountingConfig.vatDeclarationClientConfirmation,
      vat_declaration_client_confirmation_contact: accountingConfig.vatDeclarationClientConfirmationContact
        ? HttpHelper.toJsonId(accountingConfig.vatDeclarationClientConfirmationContact)
        : null,
      vat_declaration_client_confirmation_email: accountingConfig.vatDeclarationClientConfirmationEmail,
      vat_declaration_client_confirmation_sms: accountingConfig.vatDeclarationClientConfirmationSms,
      generate_regularization_accounting_entry_on_supplier:
        accountingConfig.generateRegularizationAccountingEntryOnSupplier,
      generate_regularization_accounting_entry_on_client:
        accountingConfig.generateRegularizationAccountingEntryOnClient,
      immobilisation_journal: accountingConfig.immobilisationJournal
        ? HttpHelper.toJsonId(accountingConfig.immobilisationJournal)
        : null
    };
  }
}
