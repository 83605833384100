import { DateHelper } from '@helpers/date.helper';

import { LegalInvitationChannelEnum } from '../../accounts-approval-core/enums/legal-invitation-channel.enum';

export interface GeneralAssemblyJson {
  date: string;
  address: string;
  is_head_office_address: boolean;
  send_invitation: boolean;
  beneficiaries_conflict: boolean;
  general_assembly_will_go: boolean;
  audited_account: boolean;
  invitation_date: string;
  invitation_channel: LegalInvitationChannelEnum;
}

export class GeneralAssembly {
  constructor(
    public date?: Date,
    public address?: string,
    public isHeadOfficeAddress?: boolean,
    public sendInvitation?: boolean,
    public beneficiariesConflict?: boolean,
    public generalAssemblyWillGo?: boolean,
    public auditedAccount?: boolean,
    public invitationDate?: Date,
    public invitationChannel?: LegalInvitationChannelEnum
  ) {}

  static fromJson(json: GeneralAssemblyJson): GeneralAssembly {
    return new GeneralAssembly(
      json.date ? new Date(json.date) : null,
      json.address,
      json.is_head_office_address,
      json.send_invitation,
      json.beneficiaries_conflict,
      json.general_assembly_will_go,
      json.audited_account,
      json.invitation_date ? DateHelper.fromUtc(json.invitation_date) : null,
      json.invitation_channel
    );
  }

  static toJson(generalAssembly: GeneralAssembly): GeneralAssemblyJson {
    return {
      date: generalAssembly.date ? DateHelper.format(generalAssembly.date, 'YYYY-MM-DD HH:mm:ss') : null,
      address: generalAssembly.address,
      is_head_office_address: generalAssembly.isHeadOfficeAddress,
      send_invitation: generalAssembly.sendInvitation,
      beneficiaries_conflict: generalAssembly.beneficiariesConflict,
      general_assembly_will_go: generalAssembly.generalAssemblyWillGo,
      audited_account: generalAssembly.auditedAccount,
      invitation_date: generalAssembly.invitationDate ? DateHelper.format(generalAssembly.invitationDate) : null,
      invitation_channel: generalAssembly.invitationChannel
    };
  }
}
