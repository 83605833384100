import { type AbstractControl } from '@angular/forms';

export function MarkFormAsTouchedIfInvalid(formKey: string): MethodDecorator {
  return (target: Object, key: string | symbol, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const form: AbstractControl = Reflect.get(this, formKey);

      if (!form) {
        throw new Error('Invalid property formKey in MarkFormAsTouchedIfInvalid Decorator');
      }

      if (!form.valid) {
        form.markAllAsTouched();
        return;
      }

      return original.apply(this, args);
    };

    return descriptor;
  };
}
