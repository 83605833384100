import { Directive, Input } from '@angular/core';

import { TableCellBase } from './table-cell.base';

@Directive({
  selector: '[tiimeTableCell]',
  host: {
    class: 'cdk-cell'
  }
})
export class TableCellDirective extends TableCellBase {
  @Input('tiimeTableCell')
  set name(name: string) {
    this.setColumnClass(name);
  }
}
