import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TiimeSnackbarService } from 'tiime-material';

import { sharedInjector } from '@modules/shared/shared-injector';

function escapeHtmlEntities(value: string): string {
  return String(value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function ApiAlertError(statusBlackList = [HttpStatusCode.NotFound]): MethodDecorator {
  return (_target: () => void, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      return original.apply(this, args).pipe(
        catchError(error => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            error.error.error_description &&
            !statusBlackList.includes(error.status)
          ) {
            sharedInjector.get(TiimeSnackbarService).openError(escapeHtmlEntities(error.error.error_description));
          } else if (error.error instanceof Blob && error.error.type === 'application/json') {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (reader.result) {
                const fileError = JSON.parse(reader.result as string);
                if (fileError.error_description) {
                  sharedInjector.get(TiimeSnackbarService).openError(fileError.error_description);
                }
              }
            };
            reader.readAsText(error.error);
          }

          return throwError(error);
        })
      );
    };
    return descriptor;
  };
}
