import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

export class SnackbarConfig {
  static success: MatSnackBarConfig = {
    panelClass: ['snackbar', 'success']
  };

  static error: MatSnackBarConfig = {
    duration: 7000,
    panelClass: ['snackbar', 'error']
  };

  static primary: MatSnackBarConfig = {
    panelClass: ['snackbar', 'primary']
  };
}
