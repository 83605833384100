import { Pipe, PipeTransform } from '@angular/core';

export type Mapper<T, Result> = (value: T, ...args: any[]) => Result;

@Pipe({
  name: 'map',
  standalone: true
})
export class MapperPipe implements PipeTransform {
  transform<T, Result>(value: T, map: Mapper<T, Result>, ...args: any[]): Result {
    return map(value, ...args);
  }
}
