import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { Observable } from 'rxjs';

import { Mapper, TiimePipesModule } from 'tiime-expert-utils';
import { TiimeInputContainerModule, TiimeSelectModule, TiimeTooltipModule } from 'tiime-material';

import { TransactionApiService } from '@api-services/transaction-api.service';
import { ApiFilteredSelectBase } from '@bases/selector/api-filtered-select.base';
import { Company } from '@models/company';
import { Transaction } from '@models/transaction';
import { TransactionOptionComponent } from '@modules/shared/options/transaction-option/transaction-option.component';
import { FormFocusObserveDirective } from '@shared-directives/form-focus-observe/form-focus-observe.directive';
import { VirtualScrollViewportSizeDirective } from '@shared-directives/virtual-scroll-viewport-size/virtual-scroll-viewport-size.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    TiimePipesModule,
    MatOptionModule,
    FormFocusObserveDirective,
    VirtualScrollViewportSizeDirective,
    ScrollingModule,
    FormsModule,
    TiimeTooltipModule,
    TransactionOptionComponent
  ],
  selector: 'app-transaction-select',
  templateUrl: './transaction-select.component.html',
  styleUrls: ['./transaction-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionSelectComponent extends ApiFilteredSelectBase<Transaction> {
  @Input() company: Company;
  @Input() label = 'Transaction';
  @Input() searchPlaceholder = 'Rechercher une transaction';

  readonly virtualScrollItemSize = 35;

  readonly mapToTransactionCustomValue: Mapper<Transaction, string> = (transaction: Transaction) =>
    transaction?.wording || '';

  constructor(private transactionApiService: TransactionApiService) {
    super();
  }

  filterFunction(search: string): Observable<Transaction[]> {
    return this.transactionApiService.searchTransactions(search, 100, this.company?.id ?? null);
  }
}
