export interface DepartmentJson {
  id: number;
  name: string;
  code: string;
}

export class Department {
  constructor(public id?: number, public name?: string, public code?: string) {}

  static fromJson(json: Partial<DepartmentJson>): Department {
    return new Department(json.id, json.name, json.code);
  }
}
