<a
  [routerLink]="homeRouterLink"
  routerLinkActive="active"
  tiimeTooltip="Accueil"
  tooltipPosition="right"
  data-cy="home-link"
>
  <img class="tiime-icon" src="assets/tiime.svg" />
</a>
<ng-container *ngIf="(isAuthenticated$ | async) && (businessUser$ | async)">
  <a
    [routerLink]="companiesRouterLink"
    routerLinkActive="active"
    tiimeTooltip="Dossiers"
    tooltipPosition="right"
    data-cy="companies-link"
  >
    <mat-icon svgIcon="icon-dossier"></mat-icon>
  </a>
  <ng-container *appHasRole>
    <a
      [routerLink]="planningRouterLink"
      routerLinkActive="active"
      tiimeTooltip="Planning"
      tooltipPosition="right"
      data-cy="planning-link"
    >
      <mat-icon svgIcon="icon-planning"></mat-icon>
    </a>
    <a
      [routerLink]="timeTrackingsRouterLink"
      routerLinkActive="active"
      tiimeTooltip="Saisie des temps"
      tooltipPosition="right"
      data-cy="time-trackings-link"
      *ngIf="canAccessToTimeTrackings$ | async"
    >
      <mat-icon svgIcon="icon-temps"></mat-icon>
    </a>
    <a
      [routerLink]="opportunitiesRouterLink"
      routerLinkActive="active"
      tiimeTooltip="Opportunités"
      tooltipPosition="right"
      data-cy="opportunities-link"
      *ngIf="canAccessToOpportunities$ | async"
    >
      <mat-icon svgIcon="icon-contrats"></mat-icon>
    </a>
    <a
      [routerLink]="adminRouterLink"
      routerLinkActive="active"
      tiimeTooltip="Administration"
      tooltipPosition="right"
      data-cy="administration-link"
      *ngIf="canAccessToAdministration$ | async"
    >
      <mat-icon svgIcon="icon-admin"></mat-icon>
    </a>
  </ng-container>
  <div class="spacer"></div>
  <div class="flex column gap-10 align-center">
    <button
      #notificationOverlayAnchor
      tiime-button
      color="primary"
      icon
      [matBadge]="notificationsCounter$ | async"
      matBadgeSize="small"
      matBadgePosition="after"
      matBadgeColor="warn"
      [matBadgeHidden]="(notificationsCounter$ | async) === 0"
      data-cy="notifications-action"
      (click)="openNotificationsOverlay()"
      tiimeTooltip="Notifications"
      tooltipPosition="right"
    >
      <mat-icon svgIcon="icon-notifications"></mat-icon>
    </button>
    <div id="beamer-menu-item">
      <button
        tiime-button
        color="primary"
        icon
        data-cy="new-features-action"
        tiimeTooltip="Nouveautés"
        tooltipPosition="right"
      >
        <mat-icon svgIcon="icon-nouveautes"></mat-icon>
      </button>
    </div>
    <app-business-user-account-menu tooltipPosition="right"></app-business-user-account-menu>
  </div>
</ng-container>
