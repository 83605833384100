import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-form-container'
  }
})
export class FormComponent {
  @Input() tiimeFormExpanded = true;
}
