<tiime-input-container
  contentId="thread-entity-type-select"
  [label]="label"
  [attr.small]="small"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select id="thread-entity-type-select" [formControl]="control" (selectionChange)="emitSelectionChange($event)">
    <div tiimeSelectOptions>
      <mat-option [value]="null" *ngIf="allowEmptyOption">{{ emptyOptionLabel }}</mat-option>
      <mat-option [value]="type" *ngFor="let type of selectorData; trackBy: trackByIndex">
        {{ type | threadEntityTypeLabel }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
