import { Injectable } from '@angular/core';

import { FavoriteServiceBase } from '@bases/favorite-service/favorite-service.base';
import { Contributor } from '@models/contributor';

export interface FavoriteContributors {
  contributors: number[];
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteContributorsService extends FavoriteServiceBase<Contributor> {
  constructor() {
    super('favorite_contributors');
  }

  protected getValueFromLocalStorage(): number[] {
    /**
     * Migration temporaire pour gérer les favoris existants
     */
    try {
      const parsed: number[] | FavoriteContributors = JSON.parse(this.localStorage.getItem(this.key) ?? '[]');
      if (Array.isArray(parsed)) {
        return parsed;
      }
      return parsed?.contributors ?? [];
    } catch {
      return [];
    }
  }
}
