import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { UntilDestroy } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Mapper, MarkFormAsTouchedIfInvalid } from 'tiime-expert-utils';
import { DialogBase, TiimeSnackbarService } from 'tiime-material';

import { CompanyApiService } from '@api-services/company-api.service';
import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { Company } from '@models/company';
import { businessUserSelector } from '@modules/core/store/business-user/business-user-selector';

import { CreateCreationRequestDialogData } from './create-creation-request-dialog-data';
import { NUMBR_PARTNER_ID } from '../../../../legal-core/constants/numbr-partner-id.constant';
import { FormalitySupervisorEnum } from '../../../../legal-core/enums/formality-supervisor.enum';
import { Formality } from '../../../../legal-core/models/formality';
import { FormalityApiService } from '../../../../legal-core/services/formality-api.service';
import { CreateCreationRequestForm } from '../../../creation-core/forms/create-creation-request-form';
import { CreationRequest } from '../../../creation-core/models/creation-request';
import { CreationRequestApiService } from '../../../creation-core/services/creation-request-api.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-create-creation-request-dialog',
  templateUrl: './create-creation-request-dialog.component.html',
  styleUrls: ['./create-creation-request-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCreationRequestDialogComponent
  extends DialogBase<CreateCreationRequestDialogComponent>
  implements OnInit, AfterViewInit
{
  @ViewChild('userFirstNameInput') userFirstNameInput: ElementRef;
  readonly Validators = Validators;

  createCreationRequestForm = new CreateCreationRequestForm();
  submitLoading = false;
  userPartnerId: number;
  supportedLegalForms = ['SASU', 'EURL', 'SARL', 'SAS'];
  showCreationSupervisor: boolean;
  createdCreationRequest: CreationRequest;
  companyReferenceMandatory: boolean;
  formalitySupervisor: FormalitySupervisorEnum;
  monFormalisteDisabled: boolean;

  readonly numbrPartnerId = NUMBR_PARTNER_ID;
  readonly formalitySupervisorEnum = FormalitySupervisorEnum;

  private subscription = new Subscription();

  readonly mapToDisableGenerateButton: Mapper<string, boolean> = (userLastName: string, userFirstName: string) =>
    !userLastName || !userFirstName;
  readonly mapToUserIsNotNumbr: Mapper<number, boolean> = (userPartnerId: number) =>
    userPartnerId !== this.numbrPartnerId;
  readonly mapToDisableMonFormaliste: Mapper<string, boolean> = (legalForm: string, hasLegalPerson: boolean) => {
    this.observeCreationSupervisor();
    return (this.monFormalisteDisabled = legalForm === 'SCI' || hasLegalPerson);
  };

  constructor(
    dialogRef: MatDialogRef<CreateCreationRequestDialogComponent>,
    private creationRequestApiService: CreationRequestApiService,
    private snackbarService: TiimeSnackbarService,
    private companyApiService: CompanyApiService,
    private formalityApiService: FormalityApiService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppStoreState>,
    @Inject(MAT_DIALOG_DATA) public data: CreateCreationRequestDialogData
  ) {
    super(dialogRef);

    if (data.businessUnits?.length === 1) {
      setTimeout(() => this.createCreationRequestForm.businessUnit.setValue(data.businessUnits[0]));
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(
          select(businessUserSelector),
          take(1),
          tap((businessUser: BusinessUser) => (this.userPartnerId = businessUser?.partner?.id)),
          tap(() => {
            if (this.userPartnerId !== this.numbrPartnerId) {
              this.createCreationRequestForm.legalForm.addValidators(Validators.required);
              this.createCreationRequestForm.hasLegalPerson.addValidators(Validators.required);
              this.createCreationRequestForm.updateValueAndValidity();
              this.supportedLegalForms.push('SCI');
            }
          }),
          filter(() => !!this.userPartnerId),
          tap((businessUser: BusinessUser) => {
            this.companyReferenceMandatory = businessUser.partner.config.companyReferenceMandatory;

            if (this.companyReferenceMandatory) {
              this.createCreationRequestForm.companyReference.addValidators(Validators.required);
              this.createCreationRequestForm.companyReference.updateValueAndValidity();
            }
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.userFirstNameInput.nativeElement.focus());
  }

  closeCreateCreationRequestDialog(): void {
    if (this.submitLoading) {
      return;
    }

    this.cancel();
  }

  generateCompanyName(): void {
    this.createCreationRequestForm.companyName.setValue(
      `${this.createCreationRequestForm.userLastName.value} ${this.createCreationRequestForm.userFirstName.value} (à changer)`
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @MarkFormAsTouchedIfInvalid('createCreationRequestForm')
  submitForm(): void {
    if (this.userPartnerId === this.numbrPartnerId) {
      this.createCompanyCreation(FormalitySupervisorEnum.interne);
    } else if (!this.showCreationSupervisor) {
      this.showCreationSupervisor = true;
    }
    this.observeCreationSupervisor();
  }

  createCompanyCreation(formalitySupervisor: FormalitySupervisorEnum): void {
    this.submitLoading = true;

    if (!formalitySupervisor) {
      this.snackbarService.openError('Veuillez sélectionner un formaliste');
      this.submitLoading = false;
      return;
    } else {
      this.creationRequestApiService
        .postCreationRequest(this.createCreationRequestForm.toCreationRequest())
        .pipe(
          switchMap((creationRequest: CreationRequest) =>
            this.creationRequestApiService.getCreationRequest(creationRequest.id)
          ),
          tap((creationRequest: CreationRequest) => (this.createdCreationRequest = creationRequest)),
          map((creationRequest: CreationRequest) => ({
            id: creationRequest.formalityId,
            formalitySupervisor
          })),
          switchMap((formality: Formality) =>
            this.formalityApiService.patchFormality(formality, this.createdCreationRequest.companyId)
          ),
          switchMap(() => this.companyApiService.getCompany(this.createdCreationRequest.companyId)),
          tap((createdCompany: Company) => this.dialogRef.close(createdCompany)),
          tap(() => this.snackbarService.openSuccess('Le dossier a bien été ajouté')),
          catchError(err => {
            this.submitLoading = false;
            this.showCreationSupervisor = false;
            this.cdr.markForCheck();
            return of(err);
          })
        )
        .subscribe();
    }
  }

  observeCreationSupervisor(): void {
    this.monFormalisteDisabled
      ? (this.formalitySupervisor = FormalitySupervisorEnum.alexLegal)
      : (this.formalitySupervisor = FormalitySupervisorEnum.monFormaliste);
  }

  selectFormalitySupervisor(formalitySupervisorEnum: FormalitySupervisorEnum): FormalitySupervisorEnum {
    return (this.formalitySupervisor =
      this.formalitySupervisor === formalitySupervisorEnum ? undefined : formalitySupervisorEnum);
  }
}
