import { ThreadCategory } from '@enums/thread-category.enum';
import { DateHelper } from '@helpers/date.helper';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { Message, MessageJson } from '@models/message';
import { ThreadEntity, ThreadEntityJson } from '@models/thread-entity';
import { ThreadTag, ThreadTagJson } from '@models/thread-tag';

export interface ThreadJson {
  id: number;
  entity: Partial<ThreadEntityJson>;
  creator: BusinessUserJson;
  interlocutors: Partial<BusinessUserJson>[];
  messages: Partial<MessageJson>[];
  created_at: string;
  archived_at: string;
  archived_by: BusinessUserJson;
  closed_at: string;
  closed_by: BusinessUserJson;
  read_by_user: boolean;
  category: ThreadCategory;
  last_answer_by: ThreadCategory;
  tags: Partial<ThreadTagJson>[];
}

export class Thread {
  constructor(
    public id?: number,
    public entity?: ThreadEntity,
    public creator?: BusinessUser,
    public interlocutors?: BusinessUser[],
    public messages?: Message[],
    public createdAt?: Date,
    public archivedAt?: Date,
    public archivedBy?: BusinessUser,
    public closedAt?: Date,
    public closedBy?: BusinessUser,
    public readByCurrentUser?: boolean,
    public category?: ThreadCategory,
    public lastAnswerBy?: ThreadCategory,
    public tags?: ThreadTag[]
  ) {}

  static fromJson(json: Partial<ThreadJson>): Thread {
    return new Thread(
      json.id,
      json.entity ? ThreadEntity.fromJson(json.entity) : null,
      json.creator ? BusinessUser.fromJson(json.creator) : null,
      json.interlocutors
        ? json.interlocutors.map((interlocutorJson: BusinessUserJson) => BusinessUser.fromJson(interlocutorJson))
        : [],
      json.messages ? json.messages.map((messageJson: MessageJson) => Message.fromJson(messageJson)) : [],
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.archived_at ? DateHelper.fromUtc(json.archived_at) : null,
      json.archived_by ? BusinessUser.fromJson(json.archived_by) : null,
      json.closed_at ? DateHelper.fromUtc(json.closed_at) : null,
      json.closed_by ? BusinessUser.fromJson(json.closed_by) : null,
      json.read_by_user,
      json.category,
      json.last_answer_by,
      json.tags ? json.tags.map((tagJson: ThreadTagJson) => ThreadTag.fromJson(tagJson)) : []
    );
  }

  static toJson(thread: Thread): Partial<ThreadJson> {
    return {
      entity: thread.entity ? ThreadEntity.toJson(thread.entity) : undefined,
      interlocutors: thread.interlocutors?.length
        ? thread.interlocutors.map(interlocutor => BusinessUser.toJson(interlocutor))
        : [],
      messages: thread.messages?.length ? thread.messages.map(message => Message.toJson(message)) : undefined,
      category: thread.category ?? undefined,
      tags: thread.tags?.length ? thread.tags.map((tag: ThreadTag) => ThreadTag.toJson(tag)) : []
    };
  }
}
