import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { USER_CAPACITIES } from '@constants/user-capacities.constant';
import { UserCapacity } from '@enums/user-capacity.enum';

@Component({
  standalone: true,
  selector: 'app-user-capacity-select',
  templateUrl: './user-capacity-select.component.html',
  styleUrls: ['./user-capacity-select.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    MatOptionModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCapacitySelectComponent extends SelectBase<UserCapacity> implements OnInit {
  ngOnInit(): void {
    if (this.selectorData?.length === 0) {
      this.selectorData = USER_CAPACITIES;
    }
  }
}
