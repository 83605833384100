import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { ThreadEntityTypeColorPipe } from '@shared-pipes/thread-entity-type-color/thread-entity-type-color.pipe';
import { ThreadEntityTypeLabelPipe } from '@shared-pipes/thread-entity-type-label/thread-entity-type-label.pipe';

@Component({
  selector: 'app-thread-entity-type',
  standalone: true,
  imports: [CommonModule, ThreadEntityTypeLabelPipe, ThreadEntityTypeColorPipe],
  templateUrl: './thread-entity-type.component.html',
  styleUrls: ['./thread-entity-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadEntityTypeComponent {
  @Input({ required: true }) type: ThreadEntityType;
}
