<button
  title="Actualiser"
  tiime-button
  icon
  color="primary"
  [attr.raised]="raised"
  [disabled]="disabled"
  (click)="emitRefresh()"
>
  <mat-icon fontIcon="icon-synchro-1c-20"></mat-icon>
</button>
