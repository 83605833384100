import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { API_ROUTE_REPLACE_COMPANY_KEY } from '@constants/api-routes.constant';
import { HttpHelper } from '@helpers/http.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { Environment } from '@interfaces/environment';
import { Company } from '@models/company';
import { companySelector } from '@modules/company/company-core/store/company/company-selector';
import { AuthService } from '@modules/core/auth/auth.service';
import { AppConfigService } from '@services/app-config.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private companyId: number;

  get env(): Environment {
    return this.appConfigService.environment;
  }

  get version(): string {
    return this.appConfigService.version;
  }

  constructor(
    private store: Store<AppStoreState>,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    this.observeSelectedCompany();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('api/') && !request.url.startsWith(this.env.API_URL)) {
      return next.handle(request);
    }

    let url = this.replaceApi(request.url);
    if (url.includes(API_ROUTE_REPLACE_COMPANY_KEY)) {
      url = HttpHelper.replaceCompanyUrl(url, this.companyId);
    }

    request = request.clone({
      url,
      setHeaders: {
        'tiime-app': 'tiime-pro',
        'tiime-app-version': this.version
      }
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          this.logout();
        }
        return throwError(() => error);
      })
    );
  }

  private observeSelectedCompany(): void {
    this.store
      .pipe(
        select(companySelector),
        filter((company: Company) => !!company),
        tap((company: Company) => (this.companyId = company.id))
      )
      .subscribe();
  }

  private replaceApi(url: string): string {
    return url.replace('api/', `${this.env.API_URL}/`);
  }

  private logout(): void {
    this.authService.logout().subscribe();
  }
}
