import { Action, createReducer, on } from '@ngrx/store';

import { ContainerState, PaginationRange } from 'tiime-material';

import { Notification } from '@models/notification';

import * as NotificationsActions from './notifications-actions';

export interface NotificationsState {
  counter: number;
  notifications: Notification[];
  loadingNotifications: boolean;
  containerState: ContainerState;
  pagination: PaginationRange;
  read: boolean | null;
}

export const initialState: NotificationsState = {
  counter: null,
  read: false,
  notifications: [],
  loadingNotifications: true,
  containerState: ContainerState.contentPlaceholder,
  pagination: PaginationRange.withPageSize(100)
};

const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.updateCounter, (state, { counter }) => ({
    ...state,
    counter
  })),
  on(NotificationsActions.loadNotifications, state => ({
    ...state,
    containerState: ContainerState.contentPlaceholder,
    loadingNotifications: true
  })),
  on(NotificationsActions.updateNotifications, (state, { range, notifications, read }) => ({
    ...state,
    notifications,
    read,
    pagination: range,
    containerState: notifications?.length === 0 ? ContainerState.noResult : ContainerState.done,
    loadingNotifications: false
  })),
  on(NotificationsActions.updateNotification, (state, { notification }) => ({
    ...state,
    notifications: state.notifications.map(elt => {
      if (elt.id !== notification.id) {
        return elt;
      }
      return notification;
    })
  })),
  on(NotificationsActions.setLoading, (state, { loading }) => ({
    ...state,
    loadingNotifications: loading
  }))
);

export function reducer(state: NotificationsState | undefined, action: Action): NotificationsState {
  return notificationsReducer(state, action);
}
