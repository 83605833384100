export abstract class LocalStorageService<T = any> {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  store(value: T): void {
    localStorage.setItem(this.key, this.getSettable(this.getGettable(value)));
  }

  retrieve(): T {
    return this.getGettable(localStorage.getItem(this.key));
  }

  isStored(): boolean {
    return !!this.retrieve();
  }

  remove(): void {
    localStorage.removeItem(this.key);
  }

  private getSettable(value: T): string {
    return typeof value === 'string' ? value : JSON.stringify(value);
  }

  private getGettable(value: string | null | T): T {
    try {
      return JSON.parse((value as string) || '') as T;
    } catch (e) {
      return value as T;
    }
  }
}
