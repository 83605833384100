import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { BeamerService } from '@third-parties/beamer/beamer.service';
import { SentryService } from '@third-parties/sentry/sentry.service';

import * as BusinessUserActions from './business-user-actions';
import { businessUserSelector } from './business-user-selector';

@Injectable()
export class BusinessUserEffects {
  constructor(
    private store: Store<AppStoreState>,
    private actions$: Actions,
    private sentryService: SentryService,
    private beamerService: BeamerService
  ) {}

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessUserActions.UPDATE),
        withLatestFrom(this.store.select(businessUserSelector)),
        tap(([, businessUser]: [any, BusinessUser]) => this.sentryService.setUserFromBusinessUser(businessUser)),
        tap(([, businessUser]: [any, BusinessUser]) => this.beamerService.update(businessUser))
      ),
    { dispatch: false }
  );
}
