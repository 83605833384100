import { HttpHelper } from '@helpers/http.helper';
import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';
import { PcgEntry, PcgEntryJson } from '@models/pcg-entry';
import { User, UserJson } from '@models/user';

export interface LeaseContractAccountingPeriodDataJson {
  id: number;
  accounting_period: Partial<AccountingPeriodJson>;
  schedules_amount_including_tax: number;
  schedules_amount_excluding_tax: number;
  schedules_insurance_amount: number;
  schedules_fees_amount: number;
  schedules_vat_amount: number;
  pcg_entry: Partial<PcgEntryJson>;
  manual_updated: boolean;
  prepaid_expenses: number;
  accrued_expenses: number;
  first_schedule_prepaid_amount: number;
  manual_updated_by: Partial<UserJson>;
  schedule_accountable: boolean;
  first_schedule_increased_accountable: boolean;
  regulation_accountable: boolean;
  schedules_non_depreciable_amount: number | null;
}

export type SerializedLeaseContractAccountingPeriodDataJson = Omit<
  LeaseContractAccountingPeriodDataJson,
  'schedules_amount_including_tax' | 'manual_updated_by'
>;

export class LeaseContractAccountingPeriodData {
  constructor(
    public id?: number,
    public accountingPeriod?: AccountingPeriod,
    public schedulesAmountIncludingTax?: number,
    public schedulesAmountExcludingTax?: number,
    public schedulesInsuranceAmount?: number,
    public schedulesFeesAmount?: number,
    public schedulesVatAmount?: number,
    public pcgEntry?: PcgEntry,
    public manualUpdated?: boolean,
    public prepaidExpenses?: number,
    public accruedExpenses?: number,
    public firstSchedulePrepaidAmount?: number,
    public manualUpdatedBy?: User,
    public scheduleAccountable?: boolean,
    public firstScheduleIncreasedAccountable?: boolean,
    public regulationAccountable?: boolean,
    public schedulesNonDepreciableAmount?: number | null
  ) {}

  static fromJson(json: Partial<LeaseContractAccountingPeriodDataJson>): LeaseContractAccountingPeriodData {
    return new LeaseContractAccountingPeriodData(
      json.id,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.schedules_amount_including_tax,
      json.schedules_amount_excluding_tax,
      json.schedules_insurance_amount,
      json.schedules_fees_amount,
      json.schedules_vat_amount,
      json.pcg_entry ? PcgEntry.fromJson(json.pcg_entry) : null,
      json.manual_updated,
      json.prepaid_expenses,
      json.accrued_expenses,
      json.first_schedule_prepaid_amount,
      json.manual_updated_by ? User.fromJson(json.manual_updated_by) : null,
      json.schedule_accountable,
      json.first_schedule_increased_accountable,
      json.regulation_accountable,
      json.schedules_non_depreciable_amount
    );
  }

  static toJson(data: LeaseContractAccountingPeriodData): SerializedLeaseContractAccountingPeriodDataJson {
    return {
      id: data.id,
      accounting_period: data.accountingPeriod ? HttpHelper.toJsonId(data.accountingPeriod) : null,
      schedules_amount_excluding_tax: data.schedulesAmountExcludingTax,
      schedules_insurance_amount: data.schedulesInsuranceAmount,
      schedules_fees_amount: data.schedulesFeesAmount,
      schedules_vat_amount: data.schedulesVatAmount,
      pcg_entry: data.pcgEntry ? HttpHelper.toJsonId(data.pcgEntry) : null,
      manual_updated: data.manualUpdated,
      prepaid_expenses: data.prepaidExpenses,
      accrued_expenses: data.accruedExpenses,
      first_schedule_prepaid_amount: data.firstSchedulePrepaidAmount,
      schedule_accountable: data.scheduleAccountable,
      first_schedule_increased_accountable: data.firstScheduleIncreasedAccountable,
      regulation_accountable: data.regulationAccountable,
      schedules_non_depreciable_amount: data.schedulesNonDepreciableAmount
    };
  }
}
