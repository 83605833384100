import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppStoreState } from '@interfaces/app-store-state';
import { Company } from '@models/company';
import { companySelector } from '@modules/company/company-core/store/company/company-selector';
import { AppRoute } from '@modules/core/navigation/app-route.enum';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService extends TitleStrategy {
  private readonly companyMaxLength = 14;

  constructor(protected readonly title: Title, private store: Store<AppStoreState>) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const menuName = this.buildTitle(routerState);
    const suffix = 'Tiime Expert';

    if (!menuName) {
      this.title.setTitle(suffix);
      return;
    }

    if (!routerState.url.includes(`${AppRoute.companies}/`)) {
      this.setPageTitle(menuName, suffix);
      return;
    }

    this.setCompanyPageTitle(menuName, suffix);
  }

  private setPageTitle(menuName: string, suffix: string): void {
    this.title.setTitle(this.joinTitleParts([menuName, suffix]));
  }

  private setCompanyPageTitle(menuName: string, suffix: string): void {
    this.getCurrentCompany().subscribe(({ name }) =>
      this.title.setTitle(this.joinTitleParts([this.truncateCompanyTitle(name, menuName), suffix]))
    );
  }

  private getCurrentCompany(): Observable<Company> {
    return this.store.pipe(select(companySelector), take(1));
  }

  private truncateCompanyTitle(companyName: string, menuName: string): string {
    const ellipsis = '...';
    const companyNameLengh = companyName.length;
    const formatedCompanyName =
      companyNameLengh > this.companyMaxLength ? companyName.slice(0, this.companyMaxLength) + ellipsis : companyName;

    return this.joinTitleParts([formatedCompanyName, menuName]);
  }

  private joinTitleParts(data: string[]): string {
    return data.join(' - ');
  }
}
