<div class="app-list-error">
  <mat-icon
    fontIcon="icon-ic-menu-alert"
    tiimeTooltip
    [tooltipContent]="errorTooltipTemplate"
    tooltipClass="white-tooltip"
  ></mat-icon>
  <ng-template #errorTooltipTemplate>
    <div class="tooltip-error">
      <span class="error">{{ errorMessage.error }} :</span>
      <span class="message">{{ errorMessage.message }}</span>
    </div>
  </ng-template>
</div>
