import { VatSystemCode } from '@enums/vat-system-code.enum';

export interface VatSystemJson {
  id: number;
  code: VatSystemCode;
  label: string;
  type: string;
  cerfa: string;
}

export class VatSystem {
  constructor(
    public id?: number,
    public code?: VatSystemCode,
    public label?: string,
    public type?: string,
    public cerfa?: string
  ) {}

  static fromJson(json: Partial<VatSystemJson>): VatSystem {
    return new VatSystem(json.id, json.code, json.label, json.type, json.cerfa);
  }

  static toJson(vatSystem: VatSystem): VatSystemJson {
    return {
      id: vatSystem.id,
      code: vatSystem.code,
      label: vatSystem.label,
      type: vatSystem.type
    } as VatSystemJson;
  }
}
