import { Injectable } from '@angular/core';

import { OverlayServiceBase } from 'tiime-material';

import { Notification } from '@models/notification';

import { NotificationOverlayComponent } from './notification-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationOverlayService extends OverlayServiceBase<boolean, Notification> {
  open(data: Notification): this {
    this.overlayRef = this.overlayService.open(NotificationOverlayComponent, data, {
      panelClass: ['tiime-overlay-panel', 'notification-overlay-panel'],
      width: '320px',
      height: '80px',
      positionBottomEnd: true
    });
    return this;
  }
}
