import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { TiimeButtonModule, TiimeIconModule, TiimeTooltipModule } from 'tiime-material';

import { shareReplay1 } from '@helpers/rxjs.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { BusinessUser } from '@models/business-user';
import { AuthService } from '@modules/core/auth/auth.service';
import { AppRoute } from '@modules/core/navigation/app-route.enum';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import {
  businessUserCanAccessToAdministrationSelector,
  businessUserCanAccessToContracts,
  businessUserCanAccessToTimeTrackings,
  businessUserSelector
} from '@modules/core/store/business-user/business-user-selector';
import * as NotificationsActions from '@modules/core/store/notifications/notifications-actions';
import { notificationsCounterSelector } from '@modules/core/store/notifications/notifications-selector';
import { NotificationsOverlayService } from '@modules/shared/overlays/notifications-overlay/notifications-overlay.service';
import { BusinessUserAccountMenuModule } from '@shared-components/business-user-account-menu/business-user-account-menu.module';
import { HasRoleDirective } from '@shared-directives/has-role/has-role.directive';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TiimeTooltipModule,
    TiimeButtonModule,
    TiimeIconModule,
    MatBadgeModule,
    BusinessUserAccountMenuModule,
    HasRoleDirective
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements AfterViewInit {
  @ViewChild('notificationOverlayAnchor', { read: ElementRef }) notificationOverlayAnchor: ElementRef;

  readonly isAuthenticated$: Observable<boolean> = this.authService.isAuthenticated$;
  readonly businessUser$: Observable<BusinessUser> = this.store.pipe(select(businessUserSelector));
  readonly canAccessToTimeTrackings$: Observable<boolean> = this.store.pipe(
    select(businessUserCanAccessToTimeTrackings)
  );
  readonly canAccessToOpportunities$: Observable<boolean> = this.store.pipe(select(businessUserCanAccessToContracts));
  readonly canAccessToAdministration$: Observable<boolean> = this.store.pipe(
    select(businessUserCanAccessToAdministrationSelector)
  );
  readonly notificationsCounter$: Observable<number> = this.store.pipe(
    select(notificationsCounterSelector),
    shareReplay1()
  );
  readonly homeRouterLink = `${AppRoute.home}`;
  readonly companiesRouterLink = `${AppRoute.companies}`;
  readonly planningRouterLink = `${AppRoute.planning}`;
  readonly timeTrackingsRouterLink = `${AppRoute.timeTrackings}`;
  readonly opportunitiesRouterLink = `${AppRoute.opportunities}`;
  readonly adminRouterLink = `${AppRoute.admin}`;

  private subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly notificationsOverlayService: NotificationsOverlayService,
    private readonly navigationService: NavigationService,
    private readonly store: Store<AppStoreState>
  ) {}

  ngAfterViewInit(): void {
    this.loadNotificationsCounter();
  }

  openNotificationsOverlay(): void {
    this.notificationsOverlayService
      .open(null, {
        connectTo: {
          origin: this.notificationOverlayAnchor,
          positions: [
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'bottom',
              offsetX: 10
            }
          ]
        }
      })
      .afterClosed()
      .pipe(
        filter(link => !!link),
        tap((link: string) => this.navigationService.navigateFromNotificationLink(link))
      )
      .subscribe();
  }

  private loadNotificationsCounter(): void {
    this.subscription.add(
      this.isAuthenticated$
        .pipe(
          filter(isAuthenticated => isAuthenticated),
          tap(() => this.store.dispatch(NotificationsActions.loadCounter()))
        )
        .subscribe()
    );
  }
}
