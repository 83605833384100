import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { Civility } from '@models/civility';

@Component({
  standalone: true,
  selector: 'app-user-civility-select',
  templateUrl: './user-civility-select.component.html',
  styleUrls: ['./user-civility-select.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeInputContainerModule,
    TiimeSelectModule,
    MatOptionModule,
    TiimeFormModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCivilitySelectComponent extends SelectBase<Civility> {
  @Input() label = 'Civilité';

  trackByCode(_index: number, civility: Civility): string {
    return civility.code;
  }
}
