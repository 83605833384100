export interface DocumentMetadataAmountValueJson {
  value?: number;
  currency?: string;
}

export class DocumentMetadataAmountValue {
  constructor(
    public value?: number,
    public currency?: string
  ) {}

  public static fromJson(json: DocumentMetadataAmountValueJson): DocumentMetadataAmountValue {
    return new DocumentMetadataAmountValue(json.value, json.currency);
  }

  public static toJson(documentMetadataValue: DocumentMetadataAmountValue): DocumentMetadataAmountValueJson {
    return {
      value: documentMetadataValue.value,
      currency: documentMetadataValue.currency
    };
  }
}
