export interface UserInvitationJson {
  id: number;
  date: string;
  channel: string;
  status: string;
  feature: string;
  features: string[];
  with_formation: boolean;
}

export class UserInvitation {
  constructor(
    public id?: number,
    public date?: string,
    public channel?: string,
    public status?: string,
    public feature?: string,
    public features?: string[],
    public withFormation?: boolean
  ) {}

  public static fromJson(json: UserInvitationJson): UserInvitation {
    return new UserInvitation(
      json.id,
      json.date,
      json.channel,
      json.status,
      json.feature,
      json.features ? json.features : [],
      json.with_formation
    );
  }

  public static toJson(userInvitation: UserInvitation): UserInvitationJson {
    return {
      id: userInvitation.id,
      date: userInvitation.date,
      channel: userInvitation.channel,
      status: userInvitation.status,
      feature: userInvitation.feature,
      features: userInvitation.features,
      with_formation: userInvitation.withFormation
    };
  }
}
