export interface VatRateJson {
  start_date: string;
  end_date: string;
  rate: number;
}

export class VatRate {
  constructor(public startDate?: string, public endDate?: string, public rate?: number) {}

  public static fromJson(json: VatRateJson): VatRate {
    return new VatRate(json.start_date, json.end_date, json.rate);
  }
}
