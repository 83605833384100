import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocalStorageService } from 'tiime-expert-utils';

import { HubConnectionToken, HubConnectionTokenJson } from '@models/hub-connection-token';

const HUB_TOKEN_KEY = 'hub_token';

@Injectable({
  providedIn: 'root'
})
export class HubAuthService extends LocalStorageService {
  static HUB_TOKEN_KEY = HUB_TOKEN_KEY;

  get token(): HubConnectionToken | string {
    return this.retrieve();
  }

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(HUB_TOKEN_KEY);
  }

  isAllowedDomain(request: HttpRequest<any>, allowedDomains: Array<string | RegExp>): boolean {
    const requestUrl: URL = new URL(request.url, this.document.location.origin);
    return allowedDomains.some(allowedDomain => {
      if (allowedDomain instanceof RegExp) {
        return allowedDomain.test(request.url);
      }
      const domainWithProtocol = allowedDomain.replace(/https?:\/\//, '');
      return (requestUrl.hostname + requestUrl.pathname).startsWith(domainWithProtocol);
    });
  }

  isTokenExpired(): boolean {
    if (!this.token || this.token === '') {
      return true;
    }
    const tokenExpireAt = new Date((this.token as HubConnectionToken).expiresAt) || new Date();
    return !(tokenExpireAt.valueOf() > new Date().valueOf());
  }

  retrieveToken(): Observable<HubConnectionToken> {
    const url = 'api/v1/expert/hub/token';
    return this.http.get(url).pipe(
      map((hubConnectionTokenJson: HubConnectionTokenJson) => HubConnectionToken.fromJson(hubConnectionTokenJson)),
      tap((hubConnectionToken: HubConnectionToken) => {
        this.store(hubConnectionToken);
      })
    );
  }
}
