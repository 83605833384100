import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Transaction } from '@models/transaction';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-transaction-option',
  templateUrl: './transaction-option.component.html',
  styleUrls: ['./transaction-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-option'
  }
})
export class TransactionOptionComponent {
  @Input({ required: true }) transaction: Transaction;
}
