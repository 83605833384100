export enum OperationType {
  card = 'card',
  transfer = 'transfer',
  levy = 'levy',
  check = 'check',
  cash = 'cash',
  aNouveau = 'a_nouveau',
  // eslint-disable-next-line id-blacklist
  undefined = 'undefined'
}
