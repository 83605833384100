<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="cancel()" data-cy="import-labels-category-plan-dialog">
  <span mat-dialog-title>
    <ng-container *ngIf="mode === 'Create'; else updateModeLabelTitle">Ajouter un plan de catégorie</ng-container>
    <ng-template #updateModeLabelTitle>Modifier un plan de catégorie</ng-template>
  </span>
  <mat-dialog-content mat-dialog-content>
    <form [formGroup]="labelsCategoryPlanForm">
      <tiime-input-container
        label="Nom"
        required="true"
        contentId="name"
        [errorMessage]="labelsCategoryPlanForm.labelErrorMessage?.message"
      >
        <input id="name" type="text" formControlName="label" placeholder="Nom" autocomplete="off" />
      </tiime-input-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      data-cy="dialog-cancel-button"
      type="button"
      tiime-button
      color="accent"
      strocked
      large
      (keyup.enter)="cancel()"
      (click)="cancel()"
    >
      Annuler
    </button>
    <button
      data-cy="dialog-validate-button"
      type="button"
      tiime-button
      color="accent"
      large
      raised
      [disabled]="isLoading"
      (click)="submitForm()"
    >
      <ng-container *ngIf="mode === 'Create'; else updateModeLabelAction">Ajouter</ng-container>
      <ng-template #updateModeLabelAction>Modifier</ng-template>
    </button>
  </mat-dialog-actions>
</tiime-dialog>
