import { DateHelper } from '@helpers/date.helper';

import { BeneficiaryCapitalDepositStatusEnum } from '../enums/beneficiary-capital-deposit-status.enum';
import { CapitalDepositBeneficiaryBase } from './capital-deposit-beneficiary-base';
import { UserType } from '@modules/core/enums/user-type.enum';

export interface CapitalDepositMoralBeneficiaryJson {
  id: number;
  name: string;
  email: string;
  capital_contribution: number;
  capital_deposit_date: string;
  capital_deposit: boolean;
  capital_deposit_status: BeneficiaryCapitalDepositStatusEnum;
  beneficiary_name: string;
  amount: number;
}

export class CapitalDepositMoralBeneficiary extends CapitalDepositBeneficiaryBase {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public capitalContribution: number,
    public capitalDepositDate: string,
    public capitalDeposit: boolean,
    public capitalDepositStatus: BeneficiaryCapitalDepositStatusEnum,
    public beneficiaryName: string,
    public amount: number
  ) {
    super();

    this.type = UserType.moral;
  }

  get fullName(): string {
    return this.name;
  }

  static fromJson(json: CapitalDepositMoralBeneficiaryJson): CapitalDepositMoralBeneficiary {
    return new CapitalDepositMoralBeneficiary(
      json.id,
      json.name,
      json.email,
      json.capital_contribution,
      json.capital_deposit_date ? DateHelper.format(json.capital_deposit_date) : null,
      json.capital_deposit,
      json.capital_deposit_status,
      json.beneficiary_name,
      json.amount
    );
  }
  static toJson(beneficiary: CapitalDepositMoralBeneficiary): CapitalDepositMoralBeneficiaryJson {
    return {
      id: beneficiary.id,
      name: beneficiary.name,
      email: beneficiary.email,
      capital_contribution: beneficiary.capitalContribution,
      capital_deposit_date: beneficiary.capitalDepositDate ? DateHelper.format(beneficiary.capitalDepositDate) : null,
      capital_deposit: beneficiary.capitalDeposit,
      capital_deposit_status: beneficiary.capitalDepositStatus,
      beneficiary_name: beneficiary.beneficiaryName,
      amount: beneficiary.amount
    };
  }
}
