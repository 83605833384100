import { Role } from '@enums/role.enum';

export interface UserRoleJson {
  id: number;
  name: Role;
}

export class UserRole {
  constructor(public id?: number, public name?: Role) {}

  static fromJson(json: UserRoleJson): UserRole {
    return new UserRole(json.id, json.name);
  }
}
