import { DocumentOpeningMode } from '@enums/document-opening-mode.enum';
import { DocumentType } from '@enums/document-type.enum';
import { DateHelper } from '@helpers/date.helper';
import { DocumentCategory, DocumentCategoryJson } from '@models/document-category';
import { DocumentMetadata, DocumentMetadataJson } from '@models/document-metadata';
import { DocumentMetadataAmountValue } from '@models/document-metadata-amount-value';
import { Label, LabelJson } from '@models/label';
import { LinkedTransaction, LinkedTransactionJson } from '@models/linked-transaction';
import { Transaction } from '@models/transaction';

import { ExpenseReport, ExpenseReportJson } from './expense-report';

export interface TagJson {
  name: string;
}

export interface DocumentJson {
  id: number;
  name: string;
  comment: string;
  tags: TagJson[];
  mime_type: string;
  type: DocumentType;
  category: DocumentCategoryJson;
  created_at: string;
  metadata: DocumentMetadataJson[];
  label: Partial<LabelJson>;
  bank_transactions: Partial<LinkedTransactionJson>[];
  accountable: boolean;
  opening_mode?: DocumentOpeningMode;
  expense_report?: ExpenseReportJson;
}

export class Document {
  get amount(): number | string {
    const amountMetadata = this.metadatas?.find(metadata => metadata?.key === 'amount');
    return (amountMetadata?.value as DocumentMetadataAmountValue)?.value;
  }

  get date(): string {
    const dateMetadata = this.metadatas?.find(metadata => metadata?.key === 'date');
    return dateMetadata?.value as string;
  }

  get vatAmount(): number | string {
    const vatAmountMetadata = this.metadatas?.find(metadata => metadata?.key === 'vat_amount');

    if (Array.isArray(vatAmountMetadata?.value)) {
      return (vatAmountMetadata.value?.find(m => m.key === 'total')?.value as DocumentMetadataAmountValue)?.value;
    }

    return (vatAmountMetadata?.value as DocumentMetadataAmountValue)?.value;
  }

  constructor(
    public id?: number,
    public name?: string,
    public comment?: string,
    public tags?: string[],
    public mimeType?: string,
    public type?: DocumentType,
    public category?: DocumentCategory,
    public createdAt?: Date,
    public metadatas?: DocumentMetadata[],
    public label?: Label,
    public bankTransactions?: LinkedTransaction[],
    public accountable?: boolean,
    public openingMode?: DocumentOpeningMode,
    public expenseReport?: ExpenseReport
  ) {}

  public static fromJson(json: Partial<DocumentJson>): Document {
    return new Document(
      json.id,
      json.name,
      json.comment,
      json.tags ? json.tags.map((tagJson: TagJson) => (tagJson.name ? `#${tagJson.name}` : '')) : [],
      json.mime_type,
      json.type,
      json.category ? DocumentCategory.fromJson(json.category) : null,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.metadata?.length
        ? json.metadata.map((metadataJson: DocumentMetadataJson) => DocumentMetadata.fromJson(metadataJson))
        : [],
      json.label ? Label.fromJson(json.label) : null,
      json.bank_transactions?.length
        ? json.bank_transactions.map(bankTransactionJson => LinkedTransaction.fromJson(bankTransactionJson))
        : [],
      json.accountable,
      json.opening_mode,
      json.expense_report ? ExpenseReport.fromJson(json.expense_report) : null
    );
  }

  static toJson(document: Document): Omit<DocumentJson, 'created_at' | 'opening_mode'> {
    return {
      id: document.id,
      name: document.name,
      comment: document.comment,
      tags: document.tags ? document.tags.map(t => ({ name: t.replace('#', '') })) : null,
      mime_type: document.mimeType,
      type: document.type,
      category: document.category ? DocumentCategory.toJson(document.category) : null,
      metadata: document.metadatas ? document.metadatas.map(m => DocumentMetadata.toJson(m)) : null,
      label: document.label ? Label.toJsonId(document.label) : null,
      bank_transactions: document.bankTransactions ? document.bankTransactions.map(t => Transaction.toJson(t)) : null,
      accountable: document.accountable
    };
  }
}
