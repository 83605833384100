import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[tiimeFilesDropZone]',
  exportAs: 'tiime-files-drop-zone'
})
export class FilesDropZoneDirective {
  @Input() acceptedTypes: string[];
  @Input() maximumSize: number;
  @Input() multiple: boolean;

  @Output() dropped = new EventEmitter<FileList>();
  @Output() hovered = new EventEmitter<boolean>();
  @Output() unauthorizedType = new EventEmitter<boolean>();
  @Output() incorrectSize = new EventEmitter<boolean>();

  @HostListener('drop', ['$event'])
  onDrop($event: DragEvent): void {
    $event.preventDefault();
    if (this.multiple) {
      this.dropped.emit($event.dataTransfer.files);
      this.unauthorizedType.emit(false);
      this.incorrectSize.emit(false);
      this.hovered.emit(false);
      return;
    }
    const file = $event.dataTransfer.files.item(0);
    if (!this.isAcceptedType(file)) {
      this.hovered.emit(false);
      return this.unauthorizedType.emit(true);
    }
    if (this.hasIncorrectSize(file)) {
      this.hovered.emit(false);
      return this.incorrectSize.emit(true);
    }
    this.dropped.emit($event.dataTransfer.files);
    this.unauthorizedType.emit(false);
    this.incorrectSize.emit(false);
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: DragEvent): void {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: DragEvent): void {
    $event.preventDefault();
    this.hovered.emit(false);
  }

  private hasIncorrectSize(file: File): boolean {
    return this.maximumSize && file.size > this.maximumSize * 1000000;
  }

  private isAcceptedType(file: File): boolean {
    if (this.acceptedTypes.length === 0) {
      return true;
    }
    return this.acceptedTypes.some((type: string) => file.type.endsWith(type.replace('.', '')));
  }
}
