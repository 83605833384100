<div class="snackbar-content">
  <span [innerHTML]="message" data-cy="snackbar-message"></span>
  <button
    type="button"
    class="action-button"
    tiime-button
    color="primary"
    strocked
    data-cy="snackbar-action-btn"
    (click)="dismissWithAction()"
    *ngIf="action"
  >
    {{ action }}
  </button>
  <button
    type="button"
    class="dismiss-button"
    tiime-button
    color="primary"
    strocked
    icon
    data-cy="snackbar-dismiss-btn"
    (click)="dismiss()"
  >
    <mat-icon fontIcon="icon-ic-remove-1"></mat-icon>
  </button>
</div>
