import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { ConfirmDialogData } from './confirm-dialog-data';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService extends createDialogServiceBase<boolean, ConfirmDialogData>(ConfirmDialogComponent) {}
