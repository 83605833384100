import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { AuthCallbackComponent } from './core/auth/auth-callback/auth-callback.component';
import { CreatePasswordComponent } from './core/auth/create-password/create-password.component';
import { SigninComponent } from './core/auth/signin/signin.component';
import { AdministrationGuard } from './core/guards/administration.guard';
import { BusinessUserGuard } from './core/guards/business-user.guard';
import { PasswordCreatedGuard } from './core/guards/password-created.guard';
import { PasswordNotCreatedGuard } from './core/guards/password-not-created.guard';
import { TimeTrackingsGuard } from './core/guards/time-trackings.guard';
import { AppRoute } from './core/navigation/app-route.enum';

const routes: Routes = [
  { path: AppRoute.empty, redirectTo: AppRoute.home, pathMatch: 'full' },
  { path: AppRoute.signin, component: SigninComponent },
  {
    path: AppRoute.createPassword,
    component: CreatePasswordComponent,
    canActivate: [AuthGuard, BusinessUserGuard, PasswordNotCreatedGuard]
  },
  { path: AppRoute.authCallback, component: AuthCallbackComponent },
  {
    path: AppRoute.home,
    loadChildren: () => import('./home/home.routes').then(m => m.HOME_ROUTES),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    title: 'Accueil'
  },
  {
    path: AppRoute.companies,
    loadChildren: () => import('./companies/companies.routes').then(m => m.COMPANIES_ROUTES),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    title: 'Liste des dossiers'
  },
  {
    path: AppRoute.companies + '/:id',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard]
  },
  {
    path: AppRoute.planning,
    loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    title: 'Planning',
    data: { preload: false }
  },
  {
    path: AppRoute.admin,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    canLoad: [AdministrationGuard],
    title: 'Administration'
  },
  {
    path: AppRoute.timeTrackings,
    loadChildren: () => import('./time-trackings/time-trackings.module').then(m => m.TimeTrackingsModule),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    canLoad: [TimeTrackingsGuard],
    title: 'Saisie des temps'
  },
  {
    path: AppRoute.opportunities,
    loadChildren: () => import('./opportunities/opportunities.routes').then(r => r.OPPORTUNITIES_ROUTES),
    canActivate: [AuthGuard, BusinessUserGuard, PasswordCreatedGuard],
    title: 'Opportunités'
  },
  {
    path: AppRoute.ebicsBanks,
    canActivate: [AuthGuard],
    loadChildren: () => import('./ebics-banks/ebics-banks.module').then(m => m.EbicsBanksModule),
    data: { preload: false }
  },
  {
    path: AppRoute.unauthorized,
    loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
    data: { preload: false }
  },
  {
    path: AppRoute.notFound,
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    data: { preload: false }
  },
  { path: '**', redirectTo: AppRoute.notFound }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
