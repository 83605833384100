import { FormControl, FormGroup } from '@angular/forms';

import { ErrorCode } from '@constants/error.constant';
import { SocialRegimeCode } from '@enums/social-regime-code.enum';
import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';

export type DirectorFormType = {
  socialRegime: FormControl<SocialRegimeCode | null>;
  startJobDate: FormControl<string | null>;
  endJobDate: FormControl<string | null>;
  acre: FormControl<boolean | null>;
};

export class DirectorForm extends FormGroup<DirectorFormType> {
  get socialRegime(): FormControl<SocialRegimeCode | null> {
    return this.controls.socialRegime;
  }

  get startJobDate(): FormControl<string | null> {
    return this.controls.startJobDate;
  }

  get endJobDate(): FormControl<string | null> {
    return this.controls.endJobDate;
  }

  get endJobDateErrorMessage(): ErrorMessage {
    return FormHelper.getDatePickerErrorMessage(this.endJobDate, {
      checkMin: true,
      minError: ErrorCode.INVALID,
      minErrorMessage: 'La date de fin doit être postérieure à la date de début'
    });
  }

  get acre(): FormControl<boolean | null> {
    return this.controls.acre;
  }

  constructor() {
    super({
      socialRegime: new FormControl<SocialRegimeCode | null>(null),
      startJobDate: new FormControl<string | null>(null),
      endJobDate: new FormControl<string | null>(null),
      acre: new FormControl<boolean | null>(null)
    });
  }
}
