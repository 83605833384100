import { AccountingPeriod, AccountingPeriodJson } from './accounting-period';

export interface StockJson {
  id: number;
  amount: number;
  accounting_period: Partial<AccountingPeriodJson>;
  stock_type: string;
  wording: string;
}

export class Stock {
  constructor(
    public id?: number,
    public amount?: number,
    public accountingPeriod?: AccountingPeriod,
    public stockType?: string,
    public wording?: string
  ) {}

  static fromJson(json: Partial<StockJson>): Stock {
    return new Stock(
      json.id,
      json.amount,
      json.accounting_period ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.stock_type,
      json.wording
    );
  }

  static toJson(stock: Stock): StockJson {
    return {
      id: stock.id,
      amount: stock.amount,
      accounting_period: AccountingPeriod.toJson(stock.accountingPeriod),
      stock_type: stock.stockType,
      wording: stock.wording || null
    };
  }
}
