import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { HotKeysHelper } from '@helpers/hotkeys';
import { Company } from '@models/company';
import { NavigationService } from '@modules/core/navigation/navigation.service';
import { HotKeysService } from '@services/hotkeys.service';
import { ChooseCreationTypeDialogService } from '@shared-dialogs/choose-company-creation-type-dialog/choose-company-creation-type-dialog.service';

import { openCreateCompanyDialog, openCreateCompanyDialogAfterClose } from './app.actions';

@Injectable()
export class AppEffects {
  companiesShortcut$ = createEffect(
    () =>
      this.hotKeysService
        .addShortcut({ keys: `${HotKeysHelper.getCmdKey()}.shift.l` })
        .pipe(tap(() => this.navigationService.navigateToCompanies())),
    { dispatch: false }
  );

  planningShortcut$ = createEffect(
    () =>
      this.hotKeysService
        .addShortcut({ keys: `${HotKeysHelper.getCmdKey()}.shift.p` })
        .pipe(tap(() => this.navigationService.navigateToPlanning())),
    { dispatch: false }
  );

  searchCompanyShortcut$ = createEffect(
    () =>
      this.hotKeysService
        .addShortcut({ keys: `${HotKeysHelper.getCmdKey()}.shift.f` })
        .pipe(tap(() => this.navigationService.navigateToHome())),
    { dispatch: false }
  );

  createCompanyShortcut$ = createEffect(() =>
    this.hotKeysService
      .addShortcut({ keys: `${HotKeysHelper.getCmdKey()}.shift.a` })
      .pipe(map(() => openCreateCompanyDialog()))
  );

  openCreateCompanyDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCreateCompanyDialog),
      switchMap(() => this.openCreateCompanyDialog()),
      tap((company: Company) => {
        if (company) {
          this.navigateToCompany(company);
        }
      }),
      map((company: Company) => openCreateCompanyDialogAfterClose({ company }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly chooseCreationTypeDialogService: ChooseCreationTypeDialogService,
    private readonly navigationService: NavigationService,
    private readonly hotKeysService: HotKeysService
  ) {}

  private openCreateCompanyDialog(): Observable<Company> {
    return this.chooseCreationTypeDialogService.open().afterClosed();
  }

  private navigateToCompany(company: Company): void {
    this.navigationService.navigateToCreationRequestIfNeeded(company, () =>
      this.navigationService.navigateToCompanyIdentification(company.id)
    );
  }
}
