import { HttpHelper } from '@helpers/http.helper';
import { AdminCompany } from '@models/admin-company';
import { AdminCompanyLinkBase, AdminCompanyLinkBaseJson } from '@models/admin-company-link.base';
import { BusinessUnit } from '@models/business-unit';
import { LegalStructure, LegalStructureJson } from '@models/legal-structure';
import { Signatory, SignatoryJson } from '@models/signatory';

export interface AdminCompanyLegalStructureAndSignatoryLinkJson extends AdminCompanyLinkBaseJson {
  legal_structure: Partial<LegalStructureJson>;
  signatory: Partial<SignatoryJson>;
}

export class AdminCompanyLegalStructureAndSignatoryLink extends AdminCompanyLinkBase {
  constructor(
    public selectedCompanies: AdminCompany[],
    public businessUnit: BusinessUnit,
    public legalStructure: LegalStructure,
    public signatory: Signatory
  ) {
    super(selectedCompanies, businessUnit);
  }

  static override toJson(
    data: AdminCompanyLegalStructureAndSignatoryLink
  ): AdminCompanyLegalStructureAndSignatoryLinkJson {
    return {
      selected_companies: (data.selectedCompanies ?? []).map(c => HttpHelper.toJsonId(c)),
      business_unit: HttpHelper.toJsonId(data.businessUnit),
      legal_structure:
        data.legalStructure?.id === undefined
          ? undefined
          : data.legalStructure?.id
          ? HttpHelper.toJsonId(data.legalStructure)
          : null,
      signatory:
        data.legalStructure?.id === undefined
          ? undefined
          : data.signatory?.id
          ? HttpHelper.toJsonId(data.signatory)
          : null
    };
  }
}
