import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppConfigService } from '@services/app-config.service';

import { FirebaseService } from './firebase.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService, firebaseService: FirebaseService): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => firebaseService.init()),
      deps: [AppConfigService, FirebaseService],
      multi: true
    }
  ]
})
export class FirebaseModule {}
