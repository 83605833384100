export interface DeclarationStatusErrorJson {
  code: number;
  label: string;
}

export class DeclarationStatusError {
  constructor(public code?: number, public label?: string) {}

  static fromJson(json: DeclarationStatusErrorJson): DeclarationStatusError {
    return new DeclarationStatusError(json.code, json.label);
  }
}
