import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { Environment } from '@interfaces/environment';
import { BusinessUser } from '@models/business-user';
import { AppConfigService } from '@services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  get env(): Environment {
    return this.appConfigService.environment;
  }

  get version(): string {
    return this.appConfigService.version;
  }

  constructor(private appConfigService: AppConfigService) {}

  captureException(error: Error & { originalError?: Error }): void {
    if (this.env.SENTRY_DSN) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }

  cleanUser(): void {
    if (this.env.SENTRY_DSN) {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }

  init(): void {
    if (this.env.SENTRY_DSN) {
      Sentry.init({
        integrations: [
          new TracingIntegrations.BrowserTracing({
            tracingOrigins: [this.env.API_URL],
            // https://docs.sentry.io/platforms/javascript/guides/angular/performance/instrumentation/automatic-instrumentation/#beforenavigate
            beforeNavigate(context) {
              return {
                ...context,
                name: context.name
                  .replace(/\?.*/g, '?[queryParams]') // On remplace les queryParams (/module?myQuery=param -> /module?[queryParams])
                  .replace(/\/\d+/g, '/{id}') // On remplace les ids (/company/1234/module -> /company/{id}/module)
              };
            },
            routingInstrumentation: Sentry.routingInstrumentation
          }),
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false
          })
        ],
        tracesSampleRate: 0.15,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        dsn: this.env.SENTRY_DSN,
        environment: this.env.ENV_NAME,
        release: this.version,
        beforeSend(event, hint) {
          const error = hint.originalException;
          if (error && error instanceof HttpErrorResponse) {
            return null;
          }
          return event;
        }
      });
    }
  }

  setUserFromBusinessUser(businessUser: BusinessUser): void {
    if (this.env.SENTRY_DSN) {
      Sentry.configureScope(scope =>
        scope.setUser({
          id: String(businessUser.id),
          username: businessUser.fullName,
          email: businessUser.email
        })
      );
    }
  }

  addBreadcrumb(message: string, data: any = undefined, category: string = 'User action'): void {
    if (!this.env.SENTRY_DSN) {
      return;
    }
    Sentry.addBreadcrumb({
      message,
      data,
      category,
      level: 'info'
    });
  }
}
