import { Directionality } from '@angular/cdk/bidi';
import { _DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY, _ViewRepeater } from '@angular/cdk/collections';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import {
  _COALESCED_STYLE_SCHEDULER,
  _CoalescedStyleScheduler,
  CDK_TABLE,
  CDK_TABLE_TEMPLATE,
  CdkTable,
  RenderRow,
  RowContext,
  STICKY_POSITIONING_LISTENER,
  StickyPositioningListener
} from '@angular/cdk/table';
import { DOCUMENT } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  IterableDiffers,
  Optional,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';

import { StickyTableDirective } from './sticky-table.directive';

@Component({
  selector: 'tiime-table, table[tiime-table]',
  template: `
    <div class="columns-selector-container" *ngIf="columnsSelector">
      <tiime-columns-selector-button
        [minSelected]="minSelectedColumns"
        (columnsChanged)="handleColumnChanged()"
      ></tiime-columns-selector-button>
    </div>
    ${CDK_TABLE_TEMPLATE}
  `,
  styleUrls: ['./table.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // https://github.com/angular/components/blob/69029b4b5988b8502821aa8688c1a9ac71f39950/src/cdk/table/table.ts#L220
    { provide: CDK_TABLE, useExisting: TableComponent },
    {
      provide: _VIEW_REPEATER_STRATEGY,
      useClass: _DisposeViewRepeaterStrategy
    },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
    // Prevent nested tables from seeing this table's StickyPositioningListener.
    { provide: STICKY_POSITIONING_LISTENER, useValue: null }
  ]
})
export class TableComponent<T> extends CdkTable<T> {
  @Input() columnsSelector: boolean;
  @Input() minSelectedColumns = 1;

  constructor(
    _differs: IterableDiffers,
    _changeDetectorRef: ChangeDetectorRef,
    public readonly _elementRef: ElementRef,
    @Attribute('role') role: string,
    @Optional() _dir: Directionality,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(DOCUMENT) _document: any,
    _platform: Platform,
    @Inject(_VIEW_REPEATER_STRATEGY)
    _viewRepeater: _ViewRepeater<T, RenderRow<T>, RowContext<T>>,
    @Inject(_COALESCED_STYLE_SCHEDULER)
    _coalescedStyleScheduler: _CoalescedStyleScheduler,
    _viewportRuler: ViewportRuler,
    @Optional()
    @SkipSelf()
    @Inject(STICKY_POSITIONING_LISTENER)
    _stickyPositioningListener: StickyPositioningListener,
    // On récupère la directive StickyTable qui est potentiellement appliquée sur la table
    @Optional() private stickyTable: StickyTableDirective
  ) {
    super(
      _differs,
      _changeDetectorRef,
      _elementRef,
      role,
      _dir,
      _document,
      _platform,
      _viewRepeater,
      _coalescedStyleScheduler,
      _viewportRuler,
      _stickyPositioningListener
    );
  }

  handleColumnChanged(): void {
    if (this.stickyTable) {
      // On est obligé de mettre un timeout ici car lors du changement des colonnes l'intégralité du DOM est recréé donc la référence vers la sticky table n'est plus bonne
      setTimeout(() => {
        this.stickyTable.updateObserversAndSentinels();
      });
    }
  }
}
