import { UserType } from '@modules/core/enums/user-type.enum';
import { BeneficiaryCapitalDepositStatusEnum } from '../enums/beneficiary-capital-deposit-status.enum';

export abstract class CapitalDepositBeneficiaryBase {
  public type: string;

  constructor(
    public id?: number,
    public capitalContribution?: number,
    public capitalDepositDate?: string,
    public capitalDeposit?: boolean,
    public capitalDepositStatus?: BeneficiaryCapitalDepositStatusEnum,
    public beneficiaryName?: string,
    public amount?: number
  ) {}

  abstract get fullName(): string;

  get isMoral(): boolean {
    return this.type === UserType.moral;
  }
}
