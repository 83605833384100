<app-page-loader *ngIf="authIsLoading$ | async; else authIsLoaded"></app-page-loader>
<ng-template #authIsLoaded>
  <div class="app-content">
    <app-sidebar></app-sidebar>
    <div class="app-router-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-company-threads @openCloseThreads *ngIf="companyThreadsOpened$ | async"></app-company-threads>
</ng-template>
