import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DialogBase } from 'tiime-material';

import { User } from '@models/user';

@Component({
  selector: 'app-existing-user-dialog',
  templateUrl: './existing-user-dialog.component.html',
  styleUrls: ['./existing-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExistingUserDialogComponent extends DialogBase<ExistingUserDialogComponent> {
  constructor(dialogRef: MatDialogRef<ExistingUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public user: User) {
    super(dialogRef);
  }

  selectExistingUser(): void {
    this.close(this.user);
  }
}
