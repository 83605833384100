<tiime-input-container
  contentId="vat-declaration-select"
  [label]="label"
  [attr.small]="small"
  [required]="required"
  [errorMessage]="errorMessage?.message"
>
  <tiime-select
    id="vat-declaration-select"
    [class.selected]="control.value"
    [customFindOriginValueFromOptions]="findOriginValueFromOptionsById"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
  >
    <div tiimeSelectOptions>
      <mat-option *ngFor="let vatDeclaration of selectorData; trackBy: trackById" [value]="vatDeclaration">
        du {{ vatDeclaration.startDate | date : 'shortDate' }} au {{ vatDeclaration.endDate | date : 'shortDate' }} |
        {{ vatDeclaration.cerfa }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
