import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tiime-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TiimeOverlayComponent {
  @Input() isLoading = false;
}
