import { HttpHelper } from '@helpers/http.helper';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { LegalStructure, LegalStructureJson } from '@models/legal-structure';
import { User } from '@models/user';

export interface SignatoryJson {
  id: number;
  user: Partial<User>;
  has_signature_file?: boolean;
  legal_structures?: Partial<LegalStructureJson[]>;
  legal_structures_allowed_for_business_unit?: Partial<LegalStructureJson[]>;
  archived?: boolean;
  archive_status_at?: string;
  archive_status_by?: BusinessUserJson;
}

export type SignatorySerialized = Omit<
  SignatoryJson,
  | 'id'
  | 'has_signature_file'
  | 'legal_structures'
  | 'legal_structures_allowed_for_business_unit'
  | 'archive_status_at'
  | 'archive_status_by'
>;

export class Signatory {
  constructor(
    public id?: number,
    public user?: User,
    public hasSignatureFile?: boolean,
    public legalStructures?: LegalStructure[],
    public legalStructuresAllowedForBusinessUnit?: LegalStructure[],
    public archived?: boolean,
    public archivedAt?: string,
    public archivedBy?: BusinessUser
  ) {}

  static fromJson(json: Partial<SignatoryJson>): Signatory {
    return new Signatory(
      json.id,
      json.user ? User.fromJson(json.user) : null,
      json.has_signature_file,
      json.legal_structures
        ? json.legal_structures.map((legalStructureJson: LegalStructureJson) =>
            LegalStructure.fromJson(legalStructureJson)
          )
        : null,
      json.legal_structures_allowed_for_business_unit
        ? json.legal_structures_allowed_for_business_unit.map((legalStructureJson: LegalStructureJson) =>
            LegalStructure.fromJson(legalStructureJson)
          )
        : null,
      json.archived,
      json.archive_status_at,
      json.archive_status_by ? BusinessUser.fromJson(json.archive_status_by) : null
    );
  }

  static toJson(signatory: Signatory): SignatorySerialized {
    return {
      user: signatory.user ? HttpHelper.toJsonId(signatory.user) : undefined,
      archived: signatory.archived
    };
  }
}
