export enum DeclarationStatus {
  preDraft = 'pre_draft',
  draft = 'draft',
  sent = 'sent',
  accepted = 'accepted',
  declined = 'declined',
  ignored = 'ignored',
  readyToBeSent = 'ready_to_be_sent',
  sentWithoutTeletransmission = 'sent_without_teletransmission',
  waitingClient = 'waiting_client',
  toCheck = 'to_check',
  toCorrect = 'to_correct',
  done = 'done',
  toSupervise = 'to_supervise',
  toBeReturned = 'to_be_returned',
  clientValidationRequested = 'client_validation_requested',
  docRequested = 'doc_requested'
}
