import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PasswordCreatedGuard } from '@modules/core/guards/password-created.guard';
import { AppRoute } from '@modules/core/navigation/app-route.enum';

@Injectable({
  providedIn: 'root'
})
export class PasswordNotCreatedGuard  {
  private readonly passwordCreatedGuard = inject(PasswordCreatedGuard);
  private readonly router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> {
    return this.passwordCreatedGuard.canActivate().pipe(
      map(isPasswordCreated => {
        if (isPasswordCreated === true) {
          return this.router.createUrlTree([AppRoute.home]);
        }
        return true;
      })
    );
  }
}
