import { AdminIntegrationStateType } from '@enums/admin-integration-state-type.enum';
import { Logo, LogoJson } from '@models/logo';
import { PartnerConfig } from '@models/partner-config';

export interface PartnerJson {
  id: number;
  name: string;
  city: string;
  postal_code: string;
  siren: string;
  street: string;
  brand: string;
  logo: LogoJson;
  advance_ocr_running_mode: AdminIntegrationStateType;
  legal_access: boolean;
}

export class Partner {
  constructor(
    public id?: number,
    public name?: string,
    public city?: string,
    public postalCode?: string,
    public siren?: string,
    public street?: string,
    public brand?: string,
    public logo?: Logo,
    public advanceOcrRunningMode?: AdminIntegrationStateType,
    public legalAccess?: boolean,
    public config?: PartnerConfig
  ) {}

  static fromJson(json: Partial<PartnerJson>): Partner {
    return new Partner(
      json.id,
      json.name,
      json.city,
      json.postal_code,
      json.siren,
      json.street,
      json.brand,
      json.logo ? Logo.fromJson(json.logo) : null,
      json.advance_ocr_running_mode,
      json.legal_access,
    );
  }

  static toJson(partner: Partner): Partial<PartnerJson> {
    return {
      id: partner.id,
      name: partner.name || null,
      city: partner.city || null,
      postal_code: partner.postalCode || null,
      siren: partner.siren || null,
      street: partner.street || null,
      brand: partner.brand || null,
      advance_ocr_running_mode: partner.advanceOcrRunningMode || undefined
    };
  }
}
