export class StringHelper {
  static format(value: string, mask: string): string {
    let formatedValue = '';
    for (let im = 0, is = 0; im < mask.length && is < value.length; im++) {
      formatedValue +=
        mask.charAt(im) === 'X' ? value.charAt(is++) : mask.charAt(im);
    }
    return formatedValue;
  }
}
