<div class="mat-sticky-table-no-results" data-cy="sticky-table-no-results">
  <div class="mat-sticky-table-no-results-content">
    <img
      src="dist/tiime-material/assets/illu-no-results.svg"
      draggable="false"
      alt="Illustration aucun résultat"
    />
    <span class="mat-sticky-table-no-results-label">Aucun Résultat</span>
  </div>
</div>
