import { AggregationBankConnectionStatus } from '../enums/aggregation-bank-connection-status.enum';
import { EbicsBankConnectionStatus } from '../enums/ebics-bank-connection-status.enum';
import { GlobalBankConnectionStatus } from '../enums/global-bank-connection-status.enum';

export interface BankConnectionStatusJson {
  aggregation: AggregationBankConnectionStatus;
  ebics: EbicsBankConnectionStatus;
  global: GlobalBankConnectionStatus;
}

export class BankConnectionStatus {
  constructor(
    public aggregation?: AggregationBankConnectionStatus,
    public ebics?: EbicsBankConnectionStatus,
    public global?: GlobalBankConnectionStatus
  ) {}

  static fromJson(json: Partial<BankConnectionStatusJson>): BankConnectionStatus {
    return new BankConnectionStatus(json.aggregation, json.ebics, json.global);
  }

  static toJson(bankConnectionStatus: BankConnectionStatus): BankConnectionStatusJson {
    return {
      aggregation: bankConnectionStatus.aggregation,
      ebics: bankConnectionStatus.ebics,
      global: bankConnectionStatus.global
    };
  }
}
