import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { DialogBase } from 'tiime-material';

import { ConfirmDialogData } from './confirm-dialog-data';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['../../../core/bases/simple-dialog/simple-dialog.base.scss', './confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent extends DialogBase<ConfirmDialogComponent> {
  constructor(
    dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData = new ConfirmDialogData()
  ) {
    super(dialogRef);
  }

  confirm(): void {
    this.close(true);
  }

  cancelAction(): void {
    if (this.data.emitOnCancel) {
      this.close(false);
    } else {
      this.close(null);
    }
  }

  cancel(): void {
    this.close(null);
  }
}
