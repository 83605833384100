import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  OriginConnectionPosition,
  Overlay,
  OverlayConfig,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollDispatcher
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { TooltipComponent } from './tooltip.component';
import { HasAttributesBase } from '../core';

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

type TooltipClassType = string | string[] | Set<string> | { [key: string]: any };

@UntilDestroy({ checkProperties: true })
@Directive({
  selector: '[tiimeTooltip]',
  exportAs: 'tiime-tooltip'
})
export class TooltipDirective extends HasAttributesBase implements OnDestroy {
  @Input() tiimeTooltip?: string;
  @Input() tooltipContent: TemplateRef<any>;
  @Input() tooltipHideWithoutEllipsis = false;
  @Input('tooltipClass')
  get class(): TooltipClassType {
    return this.tooltipClass;
  }
  set class(value: TooltipClassType) {
    this.tooltipClass = value;
    if (this.tooltipInstance) {
      this.setTooltipClass(this.tooltipClass);
    }
  }
  @Input('tooltipDisabled')
  get disabled(): boolean {
    return this.tooltipDisabled;
  }
  set disabled(value: boolean) {
    this.tooltipDisabled = value;

    if (this.tooltipDisabled) {
      this.hide();
    }
  }
  @Input('tooltipPosition')
  get position(): TooltipPosition {
    return this.tooltipPosition;
  }
  set position(value: TooltipPosition) {
    if (value !== this.tooltipPosition) {
      this.tooltipPosition = value;

      if (this.overlayRef) {
        const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
        this.setPosition(positionStrategy);
        this.overlayRef.updatePosition();
      }
    }
  }

  tooltipInstance: TooltipComponent;

  private overlayRef: OverlayRef;
  private tooltipDisabled = false;
  private tooltipClass: TooltipClassType;
  private tooltipPortal: ComponentPortal<TooltipComponent>;
  private tooltipPosition: TooltipPosition = 'bottom';

  constructor(
    elementRef: ElementRef,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef
  ) {
    super(elementRef);
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (
      (this._hasHostAttributes('tooltipHideWithoutEllipsis') &&
        !this.hasEllipsis() &&
        !this.tooltipHideWithoutEllipsis) ||
      this.tooltipDisabled
    ) {
      return;
    }
    this.show();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hide();
  }

  /**
   * Dispose the tooltip when destroyed.
   */
  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  hide(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide();
    }
  }

  show(): void {
    const overlayRef = this.createOverlay();

    // Create ComponentPortal that can be attached to a PortalHost
    this.tooltipPortal = this.tooltipPortal || new ComponentPortal(TooltipComponent, this.viewContainerRef);

    // Attach ComponentPortal to PortalHost
    if (!overlayRef.hasAttached()) {
      this.tooltipInstance = overlayRef.attach(this.tooltipPortal).instance;
    }

    this.tooltipInstance
      .afterHidden()
      .pipe(tap(() => this.detach()))
      .subscribe();
    this.updateTooltipContent();
    const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    this.setPosition(positionStrategy);
    this.setTooltipClass(this.tooltipClass);

    this.tooltipInstance.show();
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('.tiime-tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(2);

    this.setPosition(positionStrategy);

    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    positionStrategy.withScrollableContainers(scrollableAncestors);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      panelClass: 'tooltip-overlay',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef
      .detachments()
      .pipe(tap(() => this.detach()))
      .subscribe();
    return this.overlayRef;
  }

  /** Detaches the currently-attached tooltip. */
  private detach(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
    if (this.tooltipInstance) {
      this.tooltipInstance = null;
    }
  }

  private getOriginConnectionPosition(position: TooltipPosition): OriginConnectionPosition {
    let originConnectionPosition: OriginConnectionPosition;

    switch (position) {
      case 'top':
        originConnectionPosition = {
          originX: 'center',
          originY: 'top'
        };
        break;
      case 'bottom':
        originConnectionPosition = {
          originX: 'center',
          originY: 'bottom'
        };
        break;
      case 'left':
        originConnectionPosition = { originX: 'start', originY: 'center' };
        break;
      case 'right':
        originConnectionPosition = { originX: 'end', originY: 'center' };
        break;
    }

    return originConnectionPosition;
  }

  private getOverlayConnectionPosition(position: TooltipPosition): OverlayConnectionPosition {
    let overlayConnectionPosition: OverlayConnectionPosition;

    switch (position) {
      case 'top':
        overlayConnectionPosition = { overlayX: 'center', overlayY: 'bottom' };
        break;
      case 'bottom':
        overlayConnectionPosition = { overlayX: 'center', overlayY: 'top' };
        break;
      case 'left':
        overlayConnectionPosition = { overlayX: 'end', overlayY: 'center' };
        break;
      case 'right':
        overlayConnectionPosition = { overlayX: 'start', overlayY: 'center' };
        break;
    }

    return overlayConnectionPosition;
  }

  private hasEllipsis(): boolean {
    return (
      this.elementRef.nativeElement.offsetWidth < this.elementRef.nativeElement.scrollWidth ||
      this.elementRef.nativeElement.offsetHeight < this.elementRef.nativeElement.scrollHeight
    );
  }

  /** Set the position of the current tooltip. */
  private setPosition(positionStrategy: FlexibleConnectedPositionStrategy): void {
    const position: ConnectedPosition = {
      ...this.getOriginConnectionPosition(this.tooltipPosition),
      ...this.getOverlayConnectionPosition(this.tooltipPosition)
    };

    let invertedPosition: TooltipPosition;
    switch (this.position) {
      case 'right':
        invertedPosition = 'left';
        break;
      case 'left':
        invertedPosition = 'right';
        break;
      case 'bottom':
        invertedPosition = 'top';
        break;
      case 'top':
        invertedPosition = 'bottom';
        break;
    }

    const fallbackPosition: ConnectedPosition = {
      ...this.getOriginConnectionPosition(invertedPosition),
      ...this.getOverlayConnectionPosition(invertedPosition)
    };

    positionStrategy.withPositions([position, fallbackPosition]);
  }

  private setTooltipClass(tooltipClass: TooltipClassType): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipClass = tooltipClass;
      this.tooltipInstance.markForCheck();
    }
  }

  private updateTooltipContent(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.simpleText = this.tiimeTooltip;
      this.tooltipInstance.tooltipContent = this.tooltipContent;
      this.tooltipInstance.markForCheck();
    }
  }
}
