import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ErrorCode } from '@constants/error.constant';
import { SIRET_MASK } from '@constants/mask.constant';
import { TaxRegimeCode } from '@enums/tax-regime-code.enum';
import { ApeCodeForm } from '@forms/ape-code-form';
import { SiretFormControl } from '@forms/siret-form-control';
import { VatSystemForm } from '@forms/vat-system-form';
import { ArithmeticHelper } from '@helpers/arithmetic.helper';
import { FormHelper } from '@helpers/form.helper';
import { StringHelper } from '@helpers/string.helper';
import { ErrorMessage } from '@interfaces/error-message';
import { LegalStructureAndSignatory } from '@interfaces/legal-structure-and-signatory';
import { ApeCode } from '@models/ape-code';
import { BusinessUnit } from '@models/business-unit';
import { Company } from '@models/company';
import { VatSystem } from '@models/vat-system';

export type CompanyFormType = {
  id: FormControl<number | undefined>;
  businessUnit: FormControl<BusinessUnit | undefined>;
  name: FormControl<string | null>;
  legalForm: FormControl<string | undefined>;
  vatSystem: VatSystemForm;
  siret: SiretFormControl;
  apeCode: ApeCodeForm;
  shareCapital: FormControl<number | undefined>;
  street: FormControl<string | undefined>;
  postalCode: FormControl<string | undefined>;
  city: FormControl<string | undefined>;
  taxRegime: FormControl<TaxRegimeCode | undefined>;
  rcsCity: FormControl<string | undefined>;
  reference: FormControl<string | undefined>;
  vatDeclarationDay: FormControl<number | undefined>;
  codeRofTaxRegime: FormControl<string | undefined>;
  codeRofCvae: FormControl<string | undefined>;
  codeRofVat: FormControl<string | undefined>;
  sharesNumber: FormControl<number | null | undefined>;
  shareValue: FormControl<number | null | undefined>;
  activityStartDate: FormControl<Date | undefined>;
  legalStructureAndSignatory: FormControl<LegalStructureAndSignatory | undefined>;
};

export class CompanyForm extends FormGroup<CompanyFormType> {
  static sharesNumberIsIntegerError = 'sharesNumberIsIntegerError';

  get id(): FormControl<number | undefined> {
    return this.controls.id;
  }

  get businessUnit(): FormControl<BusinessUnit> {
    return this.controls.businessUnit;
  }

  get businessUnitErrorMessage(): ErrorMessage | null {
    return FormHelper.getEmptyErrorMessage(this.businessUnit, 'Groupe requis');
  }

  get name(): FormControl<string | null> {
    return this.controls.name;
  }

  get nameErrorMessage(): string {
    return this.name.touched && this.name.hasError('required') ? 'Nom requis' : null;
  }

  get legalForm(): FormControl<string | undefined> {
    return this.controls.legalForm;
  }

  get vatSystem(): VatSystemForm {
    return this.controls.vatSystem;
  }

  get siret(): SiretFormControl {
    return this.controls.siret;
  }

  get siretErrorMessage(): string {
    return this.siret.errorMessage;
  }

  get apeCode(): ApeCodeForm {
    return this.controls.apeCode;
  }

  get shareCapital(): FormControl<number | undefined> {
    return this.controls.shareCapital;
  }

  get street(): FormControl<string | undefined> {
    return this.controls.street;
  }

  get postalCode(): FormControl<string | undefined> {
    return this.controls.postalCode;
  }

  get city(): FormControl<string | undefined> {
    return this.controls.city;
  }

  get taxRegime(): FormControl<TaxRegimeCode | undefined> {
    return this.controls.taxRegime;
  }

  get rcsCity(): FormControl<string | undefined> {
    return this.controls.rcsCity;
  }

  get reference(): FormControl<string | undefined> {
    return this.controls.reference;
  }

  get referenceErrorMessage(): string {
    if (!this.reference.touched) {
      return null;
    }

    if (this.reference.hasError('maxlength')) {
      return 'Le numéro doit avoir 20 caractères maximum';
    }

    if (this.reference.hasError('pattern')) {
      return 'Le numéro ne doit pas contenir de caractères spéciaux';
    }

    return null;
  }

  get vatDeclarationDay(): FormControl<number | undefined> {
    return this.controls.vatDeclarationDay;
  }

  get codeRofTaxRegime(): FormControl<string | undefined> {
    return this.controls.codeRofTaxRegime;
  }

  get codeRofCvae(): FormControl<string | undefined> {
    return this.controls.codeRofCvae;
  }

  get codeRofVat(): FormControl<string | undefined> {
    return this.controls.codeRofVat;
  }

  get shareValue(): FormControl<number | null | undefined> {
    return this.controls.shareValue;
  }

  get shareValueErrorMessage(): ErrorMessage | null {
    return FormHelper.getMinErrorMessage(this.controls.shareValue, `La valeur d'une part doit être supérieur à 0`);
  }

  get sharesNumber(): FormControl<number | null | undefined> {
    return this.controls.sharesNumber;
  }

  get sharesNumberErrorMessage(): ErrorMessage | null {
    if (this.controls.sharesNumber.hasError(CompanyForm.sharesNumberIsIntegerError)) {
      return {
        error: ErrorCode.INVALID,
        message: 'Le nombre de parts doit être un entier'
      };
    }
    return null;
  }

  get activityStartDate(): FormControl<Date | undefined> {
    return this.controls.activityStartDate;
  }

  get legalStructureAndSignatory(): FormControl<LegalStructureAndSignatory | undefined> {
    return this.controls.legalStructureAndSignatory;
  }

  constructor() {
    super({
      id: new FormControl<number | undefined>(undefined),
      businessUnit: new FormControl<BusinessUnit | undefined>(undefined, Validators.required),
      name: new FormControl<string | null>(null, Validators.required),
      legalForm: new FormControl<string | undefined>(undefined),
      vatSystem: new VatSystemForm(),
      siret: new SiretFormControl(),
      apeCode: new ApeCodeForm(),
      shareCapital: new FormControl<number | undefined>(undefined),
      street: new FormControl<string | undefined>(undefined),
      postalCode: new FormControl<string | undefined>(undefined),
      city: new FormControl<string | undefined>(undefined),
      taxRegime: new FormControl<TaxRegimeCode | undefined>(undefined),
      rcsCity: new FormControl<string | undefined>(undefined),
      reference: new FormControl<string | undefined>(null, [
        Validators.maxLength(20),
        Validators.pattern(/^[A-Z0-9]*$/)
      ]),
      vatDeclarationDay: new FormControl<number | undefined>(undefined),
      codeRofTaxRegime: new FormControl<string | undefined>(undefined),
      codeRofCvae: new FormControl<string | undefined>(undefined),
      codeRofVat: new FormControl<string | undefined>(undefined),
      sharesNumber: new FormControl<number | null | undefined>(undefined, [
        CompanyForm.sharesNumberIsIntegerValidator()
      ]),
      shareValue: new FormControl<number | null | undefined>(undefined, [Validators.min(0)]),
      activityStartDate: new FormControl<Date | undefined>(undefined),
      legalStructureAndSignatory: new FormControl<LegalStructureAndSignatory | undefined>(undefined)
    });
  }

  fromCompany(company: Company): void {
    const companyToInject: Parameters<typeof CompanyForm.prototype.patchValue>[0] = { ...company };

    if (companyToInject.siret) {
      companyToInject.siret = StringHelper.format(companyToInject.siret, SIRET_MASK);
    }

    if (!companyToInject.businessUnit) {
      companyToInject.businessUnit = new BusinessUnit();
    }

    if (!companyToInject.apeCode) {
      companyToInject.apeCode = new ApeCode(null);
    }

    if (!companyToInject.vatSystem) {
      companyToInject.vatSystem = new VatSystem(null);
    }

    if (company.legalStructure && company.signatory) {
      const legalStructureAndSignatory: LegalStructureAndSignatory = {
        legalStructure: company.legalStructure,
        signatory: company.signatory
      };
      companyToInject.legalStructureAndSignatory = legalStructureAndSignatory;
    }

    this.patchValue(companyToInject);
  }

  toCompany(): Company {
    const company = this.getRawValue() as Company;
    company.siret = company.siret ? company.siret.replace(/\s/g, '') : null;
    company.legalStructure = this.legalStructureAndSignatory.value?.legalStructure;
    company.signatory = this.legalStructureAndSignatory.value?.signatory;
    return company;
  }

  computeSharesNumber(): void {
    try {
      if (this.controls.shareValue.value && this.controls.shareCapital.value) {
        this.controls.sharesNumber.setValue(
          ArithmeticHelper.div(this.controls.shareCapital.value, this.controls.shareValue.value, 6)
        );
      } else {
        this.controls.sharesNumber.patchValue(null);
      }
    } catch {
      this.controls.sharesNumber.patchValue(null);
    }
  }

  static sharesNumberIsIntegerValidator(): ValidatorFn {
    return (control: FormControl<number | null | undefined>) => {
      if (typeof control.value !== 'number') {
        return null;
      }
      if (control.value % 1 !== 0) {
        return {
          [CompanyForm.sharesNumberIsIntegerError]: true
        };
      }

      return null;
    };
  }
}
