export interface SubPcgEntryJson {
  id: number;
  account_number: string;
  label: string;
}

export class SubPcgEntry {
  constructor(
    public id?: number,
    public accountNumber?: string,
    public label?: string
  ) {}

  static fromJson(json: Partial<SubPcgEntryJson>): SubPcgEntry {
    return new SubPcgEntry(json.id, json.account_number, json.label);
  }

  static toJson(subPcgEntry: SubPcgEntry): SubPcgEntryJson {
    return {
      id: subPcgEntry.id,
      account_number: subPcgEntry.accountNumber,
      label: subPcgEntry.label
    };
  }
}
