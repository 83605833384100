import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyOptionModule as MatOptionModule,
  MatLegacyOptionSelectionChange as MatOptionSelectionChange
} from '@angular/material/legacy-core';

import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { NgUtils } from 'tiime-expert-utils';
import { TiimeButtonModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { ErrorMessage } from '@interfaces/error-message';
import { LabelCategoryPlan } from '@models/label-category-plan';
import { CreateOrUpdateLabelsCategoryPlanDialogData } from '@modules/shared/dialogs/create-or-update-labels-category-plan-dialog/create-or-update-labels-category-plan-dialog-data';
import { CreateOrUpdateLabelsCategoryPlanDialogModule } from '@modules/shared/dialogs/create-or-update-labels-category-plan-dialog/create-or-update-labels-category-plan-dialog.module';
import { CreateOrUpdateLabelsCategoryPlanDialogService } from '@modules/shared/dialogs/create-or-update-labels-category-plan-dialog/create-or-update-labels-category-plan-dialog.service';

@Component({
  standalone: true,
  selector: 'app-label-category-plan-select',
  templateUrl: './label-category-plan-select.component.html',
  styleUrls: ['./label-category-plan-select.component.scss'],
  imports: [
    CommonModule,
    TiimeInputContainerModule,
    TiimeButtonModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatIconModule,
    CreateOrUpdateLabelsCategoryPlanDialogModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelCategoryPlanSelectComponent {
  @Input() ctrl: FormControl<number | null>;
  @Input() labelCategoryPlans: LabelCategoryPlan[];
  @Input() small = false;
  @Input() partnerId: number;
  @Input() updateOption = false;
  @Input() emptyOption = true;
  @Input() emptyOptionDisabled = false;
  @Input() emptyOptionLabel = '';
  @Input() createOption = true;
  @Input() createOptionLabel = 'Ajouter un plan de catégorie';
  @Input() label = 'Plan de catégorie';
  @Input() required = false;
  @Input() errorMessage: ErrorMessage;

  @Output() readonly createdLabelCategoryPlan: EventEmitter<LabelCategoryPlan> = new EventEmitter<LabelCategoryPlan>();
  @Output() readonly updatedLabelCategoryPlan: EventEmitter<LabelCategoryPlan> = new EventEmitter<LabelCategoryPlan>();

  readonly trackById = NgUtils.trackById;

  constructor(private createOrUpdateLabelsCategoryPlanDialogService: CreateOrUpdateLabelsCategoryPlanDialogService) {}

  openCreateLabelsCategoryPlanDialog(event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }

    const data: CreateOrUpdateLabelsCategoryPlanDialogData = {
      partnerId: this.partnerId
    };

    this.openCreateOrUpdateLabelsCategoryPlanDialog(data)
      .pipe(
        filter((createdLabelCategoryPlan: LabelCategoryPlan) => !!createdLabelCategoryPlan),
        tap((createdLabelCategoryPlan: LabelCategoryPlan) =>
          this.createdLabelCategoryPlan.emit(createdLabelCategoryPlan)
        )
      )
      .subscribe();
  }

  openUpdateLabelCategoryPlanDialog(event: PointerEvent): void {
    if (!this.ctrl.value) {
      return;
    }

    event.stopPropagation();

    const data: CreateOrUpdateLabelsCategoryPlanDialogData = {
      partnerId: this.partnerId,
      labelCategoryPlan: this.labelCategoryPlans?.find(category => category.id === this.ctrl.value)
    };

    this.openCreateOrUpdateLabelsCategoryPlanDialog(data)
      .pipe(
        filter((updatedLabelCategoryPlan: LabelCategoryPlan) => !!updatedLabelCategoryPlan),
        tap((updatedLabelCategoryPlan: LabelCategoryPlan) =>
          this.updatedLabelCategoryPlan.emit(updatedLabelCategoryPlan)
        )
      )
      .subscribe();
  }

  private openCreateOrUpdateLabelsCategoryPlanDialog(
    data: CreateOrUpdateLabelsCategoryPlanDialogData
  ): Observable<LabelCategoryPlan> {
    return this.createOrUpdateLabelsCategoryPlanDialogService.open({ data }).afterClosed();
  }
}
