export class HotKeysHelper {
  static isMac(): boolean {
    return navigator.platform.includes('Mac');
  }

  static getCmd(): string {
    return HotKeysHelper.isMac() ? 'CMD' : 'CTRL';
  }

  static getCmdKey(): string {
    return HotKeysHelper.isMac() ? 'meta' : 'control';
  }
}
