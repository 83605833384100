import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { ContainerState } from './container-state.enum';

@Component({
  selector: 'tiime-simple-container',
  templateUrl: './simple-container.component.html',
  styleUrls: ['./simple-container.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tiime-simple-container'
  }
})
export class SimpleContainerComponent {
  @Input() containerState: ContainerState;

  readonly ContainerState = ContainerState;
}
