import { AutoProcessStatus } from '@enums/auto-process-status.enum';

export interface AutoProcessMetadataJson {
  code: string;
  description: string;
  status: AutoProcessStatus;
}

export class AutoProcessMetadata {
  constructor(public code?: string, public description?: string, public status?: AutoProcessStatus) {}

  static fromJson(json: AutoProcessMetadataJson): AutoProcessMetadata {
    return new AutoProcessMetadata(json.code, json.description, json.status);
  }
}
