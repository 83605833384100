import { DateHelper } from '@helpers/date.helper';

import { LegalFormalityStatusNameEnum } from '../../legal-core/enums/legal-formality-status-name.enum';

export interface LegalFormalityStatusJson {
  name?: LegalFormalityStatusNameEnum;
  update_by?: string;
  updated_at?: string;
  value: string;
  id?: number;
}

export class LegalFormalityStatus {
  constructor(
    public name: LegalFormalityStatusNameEnum,
    public updateBy: string,
    public updatedAt: Date,
    public value: string,
    public id?: number
  ) {}

  static fromJson(json: LegalFormalityStatusJson): LegalFormalityStatus {
    return new LegalFormalityStatus(
      json.name,
      json.update_by,
      json.updated_at ? DateHelper.fromUtc(json.updated_at) : null,
      json.value,
      json.id
    );
  }

  static toJson(model: LegalFormalityStatus): LegalFormalityStatusJson {
    return {
      value: model.value,
      id: model.id,
      name: model.name
    };
  }
}
