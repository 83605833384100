import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { retryWhen, shareReplay } from 'rxjs/operators';

export function remainSubscribedOnError<T>(): MonoTypeOperatorFunction<T> {
  return retryWhen((errors: Observable<any>) => errors);
}

export function shareReplay1<T>(): MonoTypeOperatorFunction<T> {
  return shareReplay<T>({ refCount: true, bufferSize: 1 });
}
