import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule, TiimeTooltipModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { LegalStructureAndSignatory } from '@interfaces/legal-structure-and-signatory';
import { LegalStructure } from '@models/legal-structure';
import { FormFocusObserveDirective } from '@shared-directives/form-focus-observe/form-focus-observe.directive';
import { VirtualScrollViewportSizeDirective } from '@shared-directives/virtual-scroll-viewport-size/virtual-scroll-viewport-size.directive';
import { LegalStructureAndSignatoryPipe } from '@shared-pipes/legal-structure-and-signatory/legal-structure-and-signatory.pipe';

export const KEEP_LEGAL_STRUCTURE_AND_SIGNATORY_OPTION: LegalStructureAndSignatory = {
  legalStructure: new LegalStructure(undefined, null, 'Conserver')
};

export const REMOVE_LEGAL_STRUCTURE_AND_SIGNATORY_OPTION: LegalStructureAndSignatory = {
  legalStructure: new LegalStructure(null, null, 'Retirer')
};

@Component({
  selector: 'app-company-legal-structure-and-signatory-select',
  standalone: true,
  imports: [
    CommonModule,
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeSelectModule,
    TiimeFormModule,
    ReactiveFormsModule,
    ScrollingModule,
    VirtualScrollViewportSizeDirective,
    MatOptionModule,
    LegalStructureAndSignatoryPipe,
    MatIconModule,
    TiimeTooltipModule
  ],
  templateUrl: './company-legal-structure-and-signatory-select.component.html',
  styleUrls: ['./company-legal-structure-and-signatory-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLegalStructureAndSignatorySelectComponent extends FilteredSelectBase<LegalStructureAndSignatory> {
  @Input() label = 'Structure juridique';
  @Input() displayKeepAndRemoveOption = false;

  readonly virtualScrollItemSize = 30;
  readonly keepLegalStructureAndSignatoryOption: LegalStructureAndSignatory = KEEP_LEGAL_STRUCTURE_AND_SIGNATORY_OPTION;
  readonly removeLegalStructureAndSignatoryOption: LegalStructureAndSignatory =
    REMOVE_LEGAL_STRUCTURE_AND_SIGNATORY_OPTION;

  filterFunction(search: string): LegalStructureAndSignatory[] {
    return this.selectorData.filter(
      (legalStructureAndSignatory: LegalStructureAndSignatory) =>
        StringUtils.normalizeToLowerCase(legalStructureAndSignatory.legalStructure.name).includes(
          StringUtils.normalizeToLowerCase(search)
        ) ||
        StringUtils.normalizeToLowerCase(legalStructureAndSignatory.signatory.user.fullName).includes(
          StringUtils.normalizeToLowerCase(search)
        )
    );
  }
}
