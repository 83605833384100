import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonComponent {
  @Input() disabled = false;
}
