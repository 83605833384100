import { ThreadEntityType } from '@enums/thread-entity-type.enum';

const DEFAULT_COLOR = '#173563';

export const THREAD_ENTITY_TYPE_COLOR: Record<ThreadEntityType, string> = {
  [ThreadEntityType.accountingPeriod]: DEFAULT_COLOR,
  [ThreadEntityType.vatDeclaration]: DEFAULT_COLOR,
  [ThreadEntityType.task]: DEFAULT_COLOR,
  [ThreadEntityType.bankTransaction]: '#54CEFF',
  [ThreadEntityType.corporateTaxInstalment]: DEFAULT_COLOR
};
