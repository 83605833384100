import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { TiimeTooltipModule } from '../tooltip';
import { AutoSaveStatusComponent } from './auto-save-status.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, TiimeTooltipModule],
  declarations: [AutoSaveStatusComponent],
  exports: [AutoSaveStatusComponent]
})
export class TiimeAutoSaveStatusModule {}
