export interface PieceFileJson {
  id: number;
  name: string;
  mime_type: string;
  created_at: string;
}

export class PieceFile {
  /**
   * Champ contenant le fichier au format binaire pour les fichiers qui n'ont pas encore été upload
   */
  file: File;
  constructor(public id: number, public name: string, public mimeType: string, public createdAt: string) {}

  static fromJson(json: PieceFileJson): PieceFile {
    return new PieceFile(json.id, json.name, json.mime_type, json.created_at);
  }
}
