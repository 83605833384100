import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { KeyboardShortcutsDialogComponent } from './keyboard-shortcuts-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutsDialogService extends createDialogServiceBase(KeyboardShortcutsDialogComponent) {}
