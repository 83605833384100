<div class="contributor-option">
  <app-business-user-slug [businessUserAcronym]="contributor.acronym"></app-business-user-slug>
  <span class="contributor-name">{{ contributor.fullName }} - {{ contributor.email }}</span>
  <app-favorite-icon-button
    *ngIf="withFavorites"
    [favorite]="favorite"
    (addToFavorites)="emitAddToFavorites()"
    (removeFromFavorites)="emitRemoveFromFavorites()"
  />
</div>
