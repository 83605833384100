import { createAction, props } from '@ngrx/store';

import { BusinessUnit } from '@models/business-unit';
import { BusinessUser } from '@models/business-user';
import { Partner } from '@models/partner';
import { PartnerConfig } from '@models/partner-config';

export const UPDATE = '[BusinessUser] Update';
export const UPDATE_PARTIAL = '[BusinessUser] Update partial';
export const UPDATE_BUSINESS_UNIT = '[BusinessUser] Update Business Unit';
export const UPDATE_PARTNER = '[BusinessUser] Update Partner';
export const UPDATE_PARTNER_CONFIG = '[BusinessUser] Update Partner Config';

export const update = createAction(UPDATE, props<{ businessUser: BusinessUser }>());
export const updatePartial = createAction(UPDATE_PARTIAL, props<{ businessUser: Partial<BusinessUser> }>());
export const updateBusinessUnit = createAction(UPDATE_BUSINESS_UNIT, props<{ businessUnit: BusinessUnit }>());
export const updatePartner = createAction(UPDATE_PARTNER, props<{ partner: Partner }>());
export const updatePartnerConfig = createAction(UPDATE_PARTNER_CONFIG, props<{ config: PartnerConfig }>());
