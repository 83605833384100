import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StatusColor } from '@enums/status-color.enum';

@Component({
  selector: 'app-status',
  standalone: true,
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'color'
  }
})
export class StatusComponent {
  @Input() color: StatusColor = StatusColor.accent;
}
