import { FoundsOriginCode } from '@enums/founds-origin-code.enum';

export interface FoundsOriginJson {
  code: FoundsOriginCode;
  label: string;
}

export class FoundsOrigin {
  constructor(public code?: FoundsOriginCode, public label?: string) {}

  static fromJson(json: Partial<FoundsOriginJson>): FoundsOrigin {
    return new FoundsOrigin(json.code, json.label);
  }

  static toJson(foundsOrigin: FoundsOrigin): FoundsOriginJson {
    return {
      code: foundsOrigin.code,
      label: foundsOrigin.label
    };
  }
}
