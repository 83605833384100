import { AccountantDetailRequestType } from '@enums/accountant-detail-request-type.enum';
import { AccountantDetailRequest, AccountantDetailRequestJson } from '@models/accountant-detail-request';
import { Document, DocumentJson } from '@models/document';
import { Invoice, InvoiceJson } from '@models/invoice';
import { Label, LabelJson } from '@models/label';
import { ImputationUpdatedBy } from '@modules/core/types/imputation-updated-by.type';

export interface ImputationJson {
  id: number;
  amount: number;
  label: Partial<LabelJson>;
  updated_by: ImputationUpdatedBy;
  comment: string;
  invoices?: Partial<InvoiceJson>[];
  documents?: Partial<DocumentJson>[];
  count_invoices?: number;
  count_documents?: number;
  accountant_detail_requests: Partial<AccountantDetailRequestJson>[];
}

export type SerializedImputationJson = Omit<
  ImputationJson,
  'count_documents' | 'count_invoices' | 'invoices' | 'documents' | 'accountant_detail_requests'
>;

export class Imputation {
  get receiptAccountantDetailRequests(): AccountantDetailRequest[] {
    const receiptAccountantDetailRequests = this.accountantDetailRequests?.filter(
      (accountantDetailRequest: AccountantDetailRequest) =>
        accountantDetailRequest.type === AccountantDetailRequestType.receipt
    );
    return receiptAccountantDetailRequests || [];
  }

  constructor(
    public id?: number,
    public amount?: number,
    public label?: Label,
    public updatedBy?: ImputationUpdatedBy,
    public comment?: string,
    public invoices?: Invoice[],
    public documents?: Document[],
    public countInvoices?: number,
    public countDocuments?: number,
    public accountantDetailRequests?: AccountantDetailRequest[]
  ) {}

  getAccountantDetailRequest(accountantDetailRequestType: AccountantDetailRequestType): AccountantDetailRequest {
    return this.accountantDetailRequests?.find(
      accountantDetailRequest => accountantDetailRequest.type === accountantDetailRequestType
    );
  }

  static fromJson(json: Partial<ImputationJson>): Imputation {
    return new Imputation(
      json.id,
      json.amount,
      json.label ? Label.fromJson(json.label) : null,
      json.updated_by,
      json.comment,
      json.invoices?.map(invoice => Invoice.fromJson(invoice)) ?? [],
      json.documents?.map(document => Document.fromJson(document)) ?? [],
      json.count_invoices,
      json.count_documents,
      json.accountant_detail_requests
        ? json.accountant_detail_requests.map(accountantDetailRequest =>
            AccountantDetailRequest.fromJson(accountantDetailRequest)
          )
        : []
    );
  }

  static toJson(imputation: Imputation): SerializedImputationJson {
    return {
      id: imputation.id || undefined,
      amount: imputation.amount,
      label: imputation.label && imputation.label.id ? Label.toJsonId(imputation.label) : null,
      updated_by: imputation.updatedBy,
      comment: imputation.comment
    };
  }
}
