<div class="sidenav" [class.closed]="!opened">
  <div class="sidenav-toogle" (click)="onToggleSidenav()" *ngIf="!disableToogle">
    <img src="assets/arrow.svg" alt="Logo du bouton pour ouvrir/fermer la sidebar" />
  </div>
  <ng-content select="[sidenav]"></ng-content>
</div>
<div class="shadow-container" [class.closed]="!opened">
  <div class="app-container">
    <ng-content></ng-content>
  </div>
</div>
