import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TiimePipesModule } from 'tiime-expert-utils';

import { Notification } from '@models/notification';
import { StatusComponent } from '@modules/shared/components/status/status.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule, StatusComponent, TiimePipesModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input() notification: Notification;
}
