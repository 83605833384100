import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrackByHelper } from 'tiime-expert-utils';
import {
  ContainerState,
  PaginationRange,
  TiimeButtonModule,
  TiimeContainerModule,
  TiimeIconModule,
  TiimeOverlayRef,
  TiimePaginatorModule,
  TiimeTableModule,
  TiimeTooltipModule
} from 'tiime-material';

import { AppStoreState } from '@interfaces/app-store-state';
import { Notification } from '@models/notification';
import * as NotificationsActions from '@modules/core/store/notifications/notifications-actions';
import {
  notificationsContainerStateSelector,
  notificationsCounterSelector,
  notificationsDataSelector,
  notificationsLoadingSelector,
  notificationsPaginationSelector
} from '@modules/core/store/notifications/notifications-selector';
import { NotificationComponent } from '@modules/shared/components/notification/notification.component';
import { NotificationReadActionComponent } from '@shared-components/notification-read-action/notification-read-action.component';

@Component({
  selector: 'app-notifications-overlay',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimeContainerModule,
    TiimeTooltipModule,
    TiimeTableModule,
    TiimePaginatorModule,
    NotificationComponent,
    MatButtonToggleModule,
    ScrollingModule,
    NotificationReadActionComponent
  ],
  templateUrl: './notifications-overlay.component.html',
  styleUrls: ['./notifications-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsOverlayComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) private virtualScrollViewport: CdkVirtualScrollViewport;

  readonly notifications$ = this.store.select(notificationsDataSelector).pipe(
    // Corrige un problème d'affichage lorsqu'on change d'onglet
    tap(() => this.virtualScrollViewport?.checkViewportSize())
  );
  readonly containerState$ = this.store.select(notificationsContainerStateSelector).pipe(
    tap(containerState => {
      if (containerState === ContainerState.done) {
        this.virtualScrollViewport?.scrollToIndex(0);
      }
    })
  );
  readonly disableMarkAllAsReadButton$: Observable<boolean> = this.store
    .select(notificationsCounterSelector)
    .pipe(map((counter: number) => counter === 0));

  readonly isLoading$ = this.store.select(notificationsLoadingSelector);
  readonly pagination$ = this.store.select(notificationsPaginationSelector);
  readonly trackById = TrackByHelper.trackById;
  readonly virtualScrollItemSize = 85;
  readonly readControl = new FormControl<boolean | null>(false);
  readonly trackByIndex = TrackByHelper.trackByIndex;

  constructor(
    private overlayRef: TiimeOverlayRef,
    private store: Store<AppStoreState>
  ) {}

  ngOnInit(): void {
    this.loadNotifications(new PaginationRange(), this.readControl.value);
  }

  close(): void {
    this.overlayRef.close();
  }

  setPagination(pagination: PaginationRange): void {
    this.loadNotifications(pagination, this.readControl.value);
  }

  notificationClicked(notification: Notification): void {
    const link = notification?.clickAction;
    if (!link) {
      return;
    }

    this.markNotificationAsRead(notification);
    this.overlayRef.close(link);
  }

  readControlChange(read: boolean): void {
    this.loadNotifications(new PaginationRange(), read);
  }

  markNotificationAsRead(notification: Notification): void {
    if (notification.read) {
      return;
    }

    this.store.dispatch(NotificationsActions.markNotificationAsRead({ notification }));
  }

  downloadFile(notification: Notification): void {
    this.store.dispatch(NotificationsActions.downloadFile({ notification }));
  }

  markAllAsRead(): void {
    this.store.dispatch(NotificationsActions.markAllNotificationsAsRead());
  }

  private loadNotifications(range: PaginationRange, read: boolean | null): void {
    this.store.dispatch(NotificationsActions.loadNotifications({ range, read }));
  }
}
