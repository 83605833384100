import { FormControl, FormGroup } from '@angular/forms';

import { ApeCode } from '@models/ape-code';

export type ApeCodeFormType = {
  id: FormControl<number | undefined>;
};

export class ApeCodeForm extends FormGroup<ApeCodeFormType> {
  get id(): FormControl<number | undefined> {
    return this.controls.id;
  }

  constructor() {
    super({
      id: new FormControl<number | undefined>(undefined)
    });
  }

  fromApeCode(apeCode: ApeCode): void {
    this.patchValue(apeCode);
  }

  toApeCode(): ApeCode {
    return this.getRawValue() as ApeCode;
  }
}
