export interface BeneficiaryJson {
  user_id: number;
  moral_person_id: number;
  moral_person_name: string;
  firstname: string;
  lastname: string;
  capital_contribution: number;
  shares_number: number;
  shares_percentage: number;
}

export class Beneficiary {
  constructor(
    public userId?: number,
    public moralPersonId?: number,
    public moralPersonName?: string,
    public firstname?: string,
    public lastname?: string,
    public capitalContribution?: number,
    public sharesNumber?: number,
    public sharesPercentage?: number
  ) {}

  static fromJson(json: Partial<BeneficiaryJson>): Beneficiary {
    return new Beneficiary(
      json.user_id,
      json.moral_person_id,
      json.moral_person_name,
      json.firstname,
      json.lastname,
      json.capital_contribution,
      json.shares_number,
      json.shares_percentage
    );
  }
  static toJson(beneficiary: Beneficiary): BeneficiaryJson {
    return {
      user_id: beneficiary.userId,
      moral_person_id: beneficiary.moralPersonId,
      moral_person_name: beneficiary.moralPersonName,
      firstname: beneficiary.firstname,
      lastname: beneficiary.lastname,
      capital_contribution: beneficiary.capitalContribution,
      shares_number: beneficiary.sharesNumber,
      shares_percentage: beneficiary.sharesPercentage
    };
  }
}
