import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive()
export abstract class InputCellBase<T> {
  @Input() placeholder: string | null = null;
  @Input('data') set setData(data: T) {
    this.ctrl.patchValue(data);
  }

  @Output() save: EventEmitter<T> = new EventEmitter<T>();

  ctrl: FormControl<T> = new FormControl<T | undefined>(undefined);

  emitSave(): void {
    if (this.ctrl.valid) {
      this.save.emit(this.ctrl.value);
    }
  }
}
