import { createAction, props } from '@ngrx/store';

import { PaginationRange } from 'tiime-material';

import { Notification } from '@models/notification';

export const LOAD_COUNTER = '[Notifications] Load counter';
export const LOAD_NOTIFICATIONS = '[Notifications] Load notifications';
export const UPDATE_COUNTER = '[Notifications] Update counter';
export const UPDATE_NOTIFICATIONS = '[Notifications] Update notifications';
export const UPDATE_NOTIFICATION = '[Notifications] Update notification';
export const MARK_NOTIFICATION_AS_READ = '[Notifications] Mark notification as read';
export const SET_LOADING = '[Notifications] Set loading';
export const DOWNLOAD_FILE = '[Notifications] download file';
export const MARK_ALL_NOTIFICATIONS_AS_READ = '[Notifications] Mark all notifications as read';

export const loadCounter = createAction(LOAD_COUNTER);
export const updateCounter = createAction(UPDATE_COUNTER, props<{ counter: number }>());
export const loadNotifications = createAction(
  LOAD_NOTIFICATIONS,
  props<{ range: PaginationRange; read: boolean | null }>()
);
export const updateNotifications = createAction(
  UPDATE_NOTIFICATIONS,
  props<{ range: PaginationRange; notifications: Notification[]; read: boolean | null }>()
);
export const updateNotification = createAction(UPDATE_NOTIFICATION, props<{ notification: Notification }>());
export const markNotificationAsRead = createAction(MARK_NOTIFICATION_AS_READ, props<{ notification: Notification }>());
export const setLoading = createAction(SET_LOADING, props<{ loading: boolean }>());
export const downloadFile = createAction(DOWNLOAD_FILE, props<{ notification: Notification }>());
export const markAllNotificationsAsRead = createAction(MARK_ALL_NOTIFICATIONS_AS_READ);
