import { ContainerState } from './container-state.enum';

export class ContainerStateHelper {
  static getContainerState(nbOfData: number, shouldOnBoard?: boolean): ContainerState {
    const hasData = nbOfData > 0;

    let containerState: ContainerState = ContainerState.noResult;
    if (hasData) {
      containerState = ContainerState.done;
    } else if (shouldOnBoard) {
      containerState = ContainerState.onboard;
    }

    return containerState;
  }
}
