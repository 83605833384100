import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TiimeOverlayService } from '../overlay';
import { ColumnData, ColumnsSelectorComponent, ColumnsSelectorData } from './columns-selector.component';
import { ColumnsSelectorService } from './columns-selector.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tiime-columns-selector-button',
  templateUrl: './columns-selector-button.component.html',
  styleUrls: ['./columns-selector-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsSelectorButtonComponent implements OnInit {
  @Input() minSelected = 1;

  @Output() readonly columnsChanged: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('columnsSelector') columnsSelectorRef: ElementRef;

  private subscription: Subscription = new Subscription();

  constructor(
    private overlayService: TiimeOverlayService,
    private columnSelectorService: ColumnsSelectorService<ColumnData>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.columnSelectorService
        .getColumnChanged()
        .pipe(tap(() => this.columnsChanged.emit()))
        .subscribe()
    );
  }

  openColumnsSelectorOverlay(): void {
    const data: ColumnsSelectorData = { minSelected: this.minSelected };
    this.overlayService
      .open(ColumnsSelectorComponent, data, {
        height: 'auto',
        width: 'auto',
        hasBackdrop: true,
        backdropClose: true,
        connectTo: {
          origin: this.columnsSelectorRef,
          positions: [
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetY: 5
            }
          ]
        }
      })
      .afterClosed()
      .subscribe();
  }
}
