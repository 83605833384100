import { Inject, Injectable } from '@angular/core';

import { BusinessUser } from '@models/business-user';
import { AppConfigService } from '@services/app-config.service';
import { BEAMER, Beamer } from '@third-parties/beamer/beamer.provider';

@Injectable({
  providedIn: 'root'
})
export class BeamerService {
  private inited = false;

  constructor(
    private appConfigService: AppConfigService,
    @Inject(BEAMER) private beamer: Beamer
  ) {}

  init(): void {
    if (!this.beamer || this.inited) {
      return;
    }
    this.beamer.init();
    this.inited = true;
  }

  update(user: BusinessUser): void {
    if (!this.beamer) {
      return;
    }

    if (!this.inited) {
      this.init();
    }

    this.beamer.update({
      user_firstname: user.firstName,
      user_lastname: user.lastName,
      user_email: user.email,
      user_id: user.id,
      filter: [this.getEnvFilter(), this.getPartnerFilter(user.partner?.name)].filter(Boolean).join(';')
    });
  }

  private getPartnerFilter(partnerName: string | undefined): string | null {
    if (!partnerName?.length) {
      return null;
    }
    return `partner-${partnerName.replace(/\s/gm, '_').replace(/'/gm, '_')}`;
  }

  private getEnvFilter(): string {
    return `environment-${this.appConfigService.environment.ENV_NAME}`;
  }
}
