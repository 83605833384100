<div
  class="business-user-account-menu-container"
  (click)="openBusinessUserAccountMenuOverlay()"
  #businessUserAccountMenu
  data-cy="user-account-action"
  tiimeTooltip
  [tooltipContent]="tooltipTemplate"
>
  <app-business-user-slug size="30px" [businessUserAcronym]="businessUser.acronym"></app-business-user-slug>
  <div class="guest-icon-contaier" *appHasRole="['ROLE_GUEST']">
    <mat-icon class="guest-icon" fontIcon="icon-unlock-1c-teint"></mat-icon>
  </div>
</div>
<ng-template #tooltipTemplate>
  Mon compte
  <ng-container *appHasRole="['ROLE_GUEST']">: Intervenant externe</ng-container>
</ng-template>
