<tiime-input-container
  tiimeFormInputContainer
  [contentId]="contentId"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessage"
>
  <tiime-select
    [id]="contentId"
    [class.selected]="value"
    [disabled]="disabled"
    [required]="required"
    [(ngModel)]="value"
    (selectionChange)="onChangeFn($event)"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null" *ngIf="allowEmptyOption"></mat-option>
      <mat-option *ngFor="let day of days; trackBy: trackByValue" [value]="day">
        {{ day }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
