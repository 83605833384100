import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { MarkFormAsTouchedIfInvalid, TiimePipesModule } from 'tiime-expert-utils';
import { TiimeButtonModule, TiimeInputContainerModule, TiimeTooltipModule } from 'tiime-material';

import { Message } from '@models/message';
import { BusinessUserSlugComponent } from '@modules/shared/components/business-user-slug/business-user-slug.component';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeButtonModule,
    TiimeTooltipModule,
    TiimeInputContainerModule,
    MatIconModule,
    TextFieldModule,
    TiimePipesModule,
    BusinessUserSlugComponent
  ],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent {
  @Input() message: Message;
  @Input() editDisabled: boolean;
  @Input() displaySlug = true;
  @Input() displayMe = false;

  @Output() readonly updateMessageContent: EventEmitter<string> = new EventEmitter<string>();

  hovered = false;
  editMode = false;

  readonly messageControl = new FormControl<string | undefined>(undefined, Validators.required);

  setEditMode(): void {
    if (this.editMode) {
      return;
    }

    this.editMode = true;
    this.messageControl.setValue(this.message.content);
  }

  cancelEditMode(): void {
    this.editMode = false;
  }

  @MarkFormAsTouchedIfInvalid('messageControl')
  emitUpdateMessageContent(): void {
    this.updateMessageContent.emit(this.messageControl.value);
    this.editMode = false;
  }
}
