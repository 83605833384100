<app-auth-layout>
  <form class="auth-form" [formGroup]="createPasswordForm" (ngSubmit)="submitCreatePasswordForm()">
    <div tiimeForm>
      <div tiimeFormColumn>
        <div class="auth-form-header">
          <div class="auth-form-header-illustration"></div>
          <span class="auth-form-header-title">Bienvenue !</span>
          <span class="auth-form-header-subtitle">Pour utiliser Tiime Expert veuillez définir votre mot de passe.</span>
        </div>
        <div class="auth-form-content">
          <tiime-input-container
            class="input-container"
            contentId="password"
            [errorMessage]="createPasswordForm.passwordErrorMessage?.message"
          >
            <input id="password" type="password" placeholder="Mot de passe" formControlName="password" />
          </tiime-input-container>
          <tiime-input-container
            class="input-container"
            contentId="password-confirm"
            [errorMessage]="createPasswordForm.passwordConfirmationErrorMessage?.message"
          >
            <input
              id="password-confirm"
              type="password"
              placeholder="Confirmer le mot de passe"
              formControlName="passwordConfirmation"
            />
          </tiime-input-container>
          <div class="different-password-error-message-container">
            <span
              *ngIf="
                this.createPasswordForm.controls.passwordConfirmation.touched &&
                !this.createPasswordForm.passwordConfirmationErrorMessage &&
                this.createPasswordForm.differentPasswordsErrorMessage
              "
              class="error-label"
            >
              {{ createPasswordForm.differentPasswordsErrorMessage.message }}
            </span>
          </div>
          <button tiime-button color="accent" large raised>Valider</button>
        </div>
      </div>
    </div>
  </form>
</app-auth-layout>
