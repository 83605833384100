import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { NgUtils, StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { FormFocusObserveDirective } from '@modules/shared/directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-legal-form-select',
  templateUrl: './legal-form-select.component.html',
  styleUrls: ['./legal-form-select.component.scss'],
  imports: [
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalFormSelectComponent extends FilteredSelectBase<string> {
  readonly trackByIndex = NgUtils.trackByIndex;

  filterFunction(search: string): string[] {
    return this.selectorData.filter((legalForm: string) =>
      StringUtils.normalizeToLowerCase(legalForm).includes(StringUtils.normalizeToLowerCase(search))
    );
  }
}
