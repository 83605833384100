<mat-autocomplete autoActiveFirstOption class="tiime-autocomplete-panel">
  <mat-option
    *ngFor="let tag of data | sortByKey : 'name'; trackBy: trackByIndex"
    [value]="tag"
    (onSelectionChange)="emitSelect($event, tag)"
  >
    <div class="tiime-option">
      <span class="ellipsis">{{ tag.name }}</span>
    </div>
  </mat-option>
</mat-autocomplete>
