import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { Mapper, MarkFormAsTouchedIfInvalid, NgUtils } from 'tiime-expert-utils';
import { DialogBase } from 'tiime-material';

import { AccountingConfigApiService } from '@api-services/accounting-config-api.service';
import { DateHelper } from '@helpers/date.helper';
import { AppStoreState } from '@interfaces/app-store-state';
import { AccountingConfig } from '@models/accounting-config';
import { AccountingPeriod } from '@models/accounting-period';
import * as AccountingConfigActions from '@modules/company/company-core/store/accounting-config/accounting-config-actions';
import { accountingConfigSelector } from '@modules/company/company-core/store/accounting-config/accounting-config-selector';
import { openedAccountingPeriodSelector } from '@modules/company/company-core/store/accounting-periods/accounting-periods-selector';
import { CustomAccountingPeriodForm } from '@modules/core/forms/custom-accounting-period-form';
import { EditCustomPeriodDialogData } from '@modules/shared/dialogs/edit-custom-period-dialog/edit-custom-period-dialog-data';

@Component({
  selector: 'app-edit-custom-period-dialog',
  templateUrl: './edit-custom-period-dialog.component.html',
  styleUrls: ['./edit-custom-period-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCustomPeriodDialogComponent extends DialogBase<EditCustomPeriodDialogComponent> implements OnInit {
  accountingPeriods$ = this.store.pipe(select(openedAccountingPeriodSelector));

  readonly trackById = NgUtils.trackById;

  readonly customPeriodForm = new CustomAccountingPeriodForm();

  private readonly accountingConfig$: Observable<AccountingConfig> = this.store.select(accountingConfigSelector);

  readonly mapToMinDate: Mapper<number, Date> = (accountingPeriodId: number, accountingPeriods: AccountingPeriod[]) => {
    const selectedAccountinPeriod = accountingPeriods?.find(
      (accountingPeriod: AccountingPeriod) => accountingPeriod.id === accountingPeriodId
    );
    return DateHelper.toDate(DateHelper.add(selectedAccountinPeriod.startDate, 1, 'day'));
  };
  readonly mapToMaxDate: Mapper<number, Date> = (accountingPeriodId: number, accountingPeriods: AccountingPeriod[]) => {
    const selectedAccountinPeriod = accountingPeriods?.find(
      (accountingPeriod: AccountingPeriod) => accountingPeriod.id === accountingPeriodId
    );
    return DateHelper.toDate(DateHelper.add(selectedAccountinPeriod.endDate, -1, 'day'));
  };

  constructor(
    private store: Store<AppStoreState>,
    private accountingConfigApiService: AccountingConfigApiService,
    private cdr: ChangeDetectorRef,
    protected dialogRef: MatDialogRef<EditCustomPeriodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: EditCustomPeriodDialogData
  ) {
    super(dialogRef);
    this.customPeriodForm.accountingPeriodId.setValue(data.accountingPeriod?.id);
  }

  ngOnInit(): void {
    this.initFormValue();
  }

  @MarkFormAsTouchedIfInvalid('customPeriodForm')
  validate(): void {
    this.isLoading = true;
    this.customPeriodForm.disable();
    this.accountingConfig$
      .pipe(
        take(1),
        switchMap(accountingConfig =>
          this.accountingConfigApiService.updatePartialAccountingConfig({
            id: accountingConfig.id,
            custom_accounting_period_end_date: DateHelper.format(this.customPeriodForm.endDate.value)
          })
        ),
        tap((updatedAccountingConfig: AccountingConfig) => {
          this.store.dispatch(
            AccountingConfigActions.updatePartial({
              accountingConfig: {
                customAccountingPeriodEndDate: updatedAccountingConfig.customAccountingPeriodEndDate
              }
            })
          );
          this.close(this.customPeriodForm.accountingPeriodId.value);
        }),
        finalize(() => {
          this.isLoading = false;
          this.customPeriodForm.enable();
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  private initFormValue(): void {
    this.accountingConfig$
      .pipe(
        skipWhile(accountingConfig => !accountingConfig),
        take(1),
        tap(accountingConfig => {
          if (!accountingConfig.customAccountingPeriodEndDate) {
            return;
          }

          setTimeout(() => this.customPeriodForm.endDate.setValue(accountingConfig.customAccountingPeriodEndDate));
        })
      )
      .subscribe();
  }
}
