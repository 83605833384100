<ng-container [ngSwitch]="containerState">
  <ng-container *ngSwitchCase="ContainerState.contentPlaceholder">
    <ng-content select="[tiimeContainerContentPlaceholder]"></ng-content>
  </ng-container>
  <ng-container *ngSwitchCase="ContainerState.onboard">
    <ng-content select="[tiimeContainerOnboard]"></ng-content>
  </ng-container>
  <ng-container *ngSwitchCase="ContainerState.noResult">
    <ng-content select="[tiimeContainerNoResult]"></ng-content>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-content select="[tiimeContainerContent]"></ng-content>
  </ng-container>
</ng-container>
