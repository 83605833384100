import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { TiimePipesModule } from 'tiime-expert-utils';
import { TiimeCheckboxCardModule, TiimeSlideToggleModule, TiimeTwocolorsIconModule } from 'tiime-material';

import { UserInvitationChannelType } from '@enums/user-invitation-channel-type.enum';
import { UserInvitationConfigurationForm } from '@forms/user-invitation-configuration-form';
import { InvitationChannelSelectComponent } from '@modules/shared/selectors/invitation-channel-select/invitation-channel-select.component';

@UntilDestroy({ checkProperties: true })
@Component({
  standalone: true,
  selector: 'app-invitation-form',
  templateUrl: './invitation-form.component.html',
  styleUrls: ['./invitation-form.component.scss'],
  imports: [
    TiimePipesModule,
    TiimeCheckboxCardModule,
    ReactiveFormsModule,
    TiimeTwocolorsIconModule,
    CommonModule,
    InvitationChannelSelectComponent,
    TiimeSlideToggleModule,
    FormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationFormComponent implements AfterViewInit {
  @Input() invitationForm: UserInvitationConfigurationForm;
  @Input() userPhoneNumber: string | null;

  showForm = new FormControl<boolean>(true);

  private subscription = new Subscription();

  ngAfterViewInit(): void {
    this.observeFormChanges();
  }

  observeFormChanges(): void {
    this.subscription.add(
      this.invitationForm.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(() => this.invitationForm.invalid),
          tap(value => {
            if (!value.features.receiptApp && !value.features.invoiceApp) {
              this.showForm.patchValue(false);
              this.invitationForm.patchValue({
                withFormation: false,
                channel: null
              });
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.showForm.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap((showForm: boolean) => {
            if (showForm) {
              this.invitationForm.patchValue({
                features: {
                  invoiceApp: true,
                  receiptApp: true
                },
                channel: UserInvitationChannelType.email,
                withFormation: true
              });
            } else {
              this.invitationForm.patchValue({
                features: {
                  invoiceApp: false,
                  receiptApp: false
                },
                channel: null,
                withFormation: false
              });
            }
          })
        )
        .subscribe()
    );
  }
}
