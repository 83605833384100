import { NgModule } from '@angular/core';

import { SharedModule } from '@modules/shared/shared.module';
import { CareContactDialogComponent } from './care-contact-dialog.component';

@NgModule({
  declarations: [CareContactDialogComponent],
  imports: [SharedModule]
})
export class CareContactDialogModule {}
