import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ThreadCategory } from '@enums/thread-category.enum';
import { ThreadEntityType } from '@enums/thread-entity-type.enum';
import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';
import { AccountingPeriod } from '@models/accounting-period';
import { Contributor } from '@models/contributor';
import { CorporateTaxInstalment } from '@models/corporate-tax-instalment';
import { Message } from '@models/message';
import { TaskPlanning } from '@models/task-planning';
import { Thread } from '@models/thread';
import { ThreadEntity } from '@models/thread-entity';
import { ThreadTag } from '@models/thread-tag';
import { Transaction } from '@models/transaction';
import { VatDeclarationConfiguration } from '@models/vat-declaration-configuration';

export type EntityTypeThreadFormType = ThreadEntityType | null;
export type EntityThreadFormType =
  | AccountingPeriod
  | VatDeclarationConfiguration
  | TaskPlanning
  | Transaction
  | CorporateTaxInstalment
  | null;
export type MessageThreadFormType = string | null;
export type InterlocutorsThreadFormType = Contributor[] | null;
export type CategoryThreadFormType = ThreadCategory | null;
export type TagsThreadFormType = ThreadTag[] | null;

export type ThreadFormType = {
  entityType: FormControl<EntityTypeThreadFormType>;
  entity: FormControl<EntityThreadFormType>;
  message: FormControl<MessageThreadFormType>;
  interlocutors: FormControl<InterlocutorsThreadFormType>;
  category: FormControl<CategoryThreadFormType>;
  tags: FormControl<TagsThreadFormType>;
};

export class ThreadForm extends FormGroup<ThreadFormType> {
  get entityTypeErrorMessage(): ErrorMessage {
    return FormHelper.getEmptyErrorMessage(this.controls.entityType, `L'objet est requis.`);
  }

  get entityErrorMessage(): ErrorMessage {
    let message = 'Champ requis';
    switch (this.controls.entityType?.value) {
      case ThreadEntityType.accountingPeriod:
        message = `L'exercice est requis.`;
        break;
      case ThreadEntityType.vatDeclaration:
        message = 'La déclaration de TVA est requise.';
        break;
      case ThreadEntityType.task:
        message = 'La mission complémentaire est requise';
        break;
      case ThreadEntityType.bankTransaction:
        message = 'La transaction est requise';
        break;
      case ThreadEntityType.corporateTaxInstalment:
        message = "L'acompte d'IS est requis";
        break;
    }
    return FormHelper.getEmptyErrorMessage(this.controls.entity, message);
  }

  get messageErrorMessage(): ErrorMessage {
    return FormHelper.getEmptyErrorMessage(this.controls.message, 'Le message est requis.');
  }

  constructor() {
    super({
      entityType: new FormControl<EntityTypeThreadFormType>(ThreadEntityType.accountingPeriod, Validators.required),
      entity: new FormControl<EntityThreadFormType>(null, Validators.required),
      message: new FormControl<MessageThreadFormType>(null, Validators.required),
      interlocutors: new FormControl<InterlocutorsThreadFormType>(null),
      category: new FormControl<CategoryThreadFormType>(null),
      tags: new FormControl<TagsThreadFormType>(null)
    });
  }

  toThread(): Thread {
    const thread = new Thread();
    thread.entity = new ThreadEntity();
    thread.entity.type = this.controls.entityType.value;

    switch (thread.entity.type) {
      case ThreadEntityType.accountingPeriod:
        thread.entity.accountingPeriod = this.controls.entity.value as AccountingPeriod;
        break;
      case ThreadEntityType.bankTransaction:
        thread.entity.bankTransaction = this.controls.entity.value as Transaction;
        break;
      case ThreadEntityType.corporateTaxInstalment:
        thread.entity.corporateTaxInstalment = this.controls.entity.value as CorporateTaxInstalment;
        break;
      case ThreadEntityType.task:
        thread.entity.task = this.controls.entity.value as TaskPlanning;
        break;
      case ThreadEntityType.vatDeclaration:
        thread.entity.vatDeclaration = this.controls.entity.value as VatDeclarationConfiguration;
        break;
    }

    thread.messages = [new Message(null, this.controls.message.value)];
    thread.interlocutors = this.controls.interlocutors.value;
    thread.category = this.controls.category.value;
    thread.tags = this.controls.tags.value;

    return thread;
  }
}
