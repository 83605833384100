import { UserBase } from './user-base';

export interface ContributorJson {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  acronym: string;
}

export class Contributor extends UserBase {
  constructor(id?: number, firstName?: string, lastName?: string, email?: string, acronym?: string) {
    super(id, firstName, lastName, email, null, acronym);
  }

  static fromJson(json: Partial<ContributorJson>): Contributor {
    return new Contributor(json.id, json.firstname, json.lastname, json.email, json.acronym);
  }

  static toJson(contributor: Contributor): ContributorJson {
    return {
      id: contributor.id,
      firstname: contributor.firstName,
      lastname: contributor.lastName,
      email: contributor.email,
      acronym: contributor.acronym
    };
  }
}
