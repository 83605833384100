import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { FormUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeIconModule, TiimeInputContainerModule } from 'tiime-material';

import { CommonDataApiService } from '@api-services/common-data-api.service';
import { UserApiService } from '@api-services/user-api.service';
import { UserFormBase } from '@bases/user/user-form.base';
import { UserForm } from '@forms/user/user-form';
import { remainSubscribedOnError } from '@helpers/rxjs.helper';
import { Country } from '@models/country';
import { User } from '@models/user';
import { UserAutocompleteComponent } from '@modules/shared/autocompletes/user-autocomplete/user-autocomplete.component';
import { AddressFormComponent } from '@modules/shared/forms/address-form/address-form.component';
import { DirectorFormComponent } from '@modules/shared/forms/director-form/director-form.component';
import { LegalInformationFormComponent } from '@modules/shared/forms/legal-information-form/legal-information-form.component';
import { OwnershipFormComponent } from '@modules/shared/forms/ownership-form/ownership-form.component';
import { UserCapacitySelectComponent } from '@modules/shared/selectors/user-capacity-select/user-capacity-select.component';
import { UserCivilitySelectComponent } from '@modules/shared/selectors/user-civility-select/user-civility-select.component';
import { DirectorPipe } from '@shared-pipes/director/director.pipe';
import { IncludesPipe } from '@shared-pipes/includes/includes.pipe';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeFormModule,
    TiimeInputContainerModule,
    TiimeIconModule,
    MatAutocompleteModule,
    IncludesPipe,
    UserAutocompleteComponent,
    UserCapacitySelectComponent,
    UserCivilitySelectComponent,
    AddressFormComponent,
    OwnershipFormComponent,
    LegalInformationFormComponent,
    DirectorFormComponent,
    DirectorPipe
  ],
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends UserFormBase<UserForm> implements OnInit {
  @Input() expandedForms = ['user', 'address', 'ownership', 'director', 'legal'];
  @Input() withSeparators = true;
  @Input() mode: 'update' | 'create' = 'create';

  @ViewChild('userLastNameInput') userLastNameInput: ElementRef;

  users$: Observable<User[]>;

  readonly civilities$: Observable<Country[]> = this.commonDataApiService.getCivilities();

  constructor(
    private userApiService: UserApiService,
    private commonDataApiService: CommonDataApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.observeLastNameValueChanges();
    this.observeCapacityValueChanges();
  }

  deselectUserSuggestion(): void {
    if (this.mode === 'update') {
      return;
    }

    setTimeout(() => {
      this.userForm.reset();
      this.userForm.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.updateValueAndValidity();
    });
  }

  focusUserLastName(): void {
    setTimeout(() => this.userLastNameInput.nativeElement.focus());
  }

  selectUserSuggestion(user: User): void {
    if (this.mode === 'update') {
      return;
    }

    user.capacity = null;
    user.capitalContribution = this.userForm.ownership.capitalContribution.value;
    user.entryDate = this.userForm.ownership.entryDate.value;
    user.exitDate = this.userForm.ownership.exitDate.value;
    user.sharesNumber = this.userForm.ownership.sharesNumber.value;
    user.sharesUsufructNumber = this.userForm.ownership.sharesUsufructNumber.value;
    user.sharesBareOwnershipNumber = this.userForm.ownership.sharesBareOwnershipNumber.value;
    user.socialRegime = this.userForm.director.socialRegime.value;
    user.startJobDate = this.userForm.director.startJobDate.value;
    user.endJobDate = this.userForm.director.endJobDate.value;
    user.acre = this.userForm.director.acre.value;

    setTimeout(() => {
      this.userForm.fromUser(user);
      this.userForm.disable(FormUtils.shouldNotEmitEvent);
      this.userForm.id.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.email.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.phone.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.capacity.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.ownership.enable(FormUtils.shouldNotEmitEvent);
      this.userForm.director.enable(FormUtils.shouldNotEmitEvent);
      if (this.userForm.email.valid) {
        this.userForm.email.disable(FormUtils.shouldNotEmitEvent);
      }
      if (this.userForm.phone.valid) {
        this.userForm.phone.disable(FormUtils.shouldNotEmitEvent);
      }
      this.userForm.updateValueAndValidity();
    });
  }

  private observeLastNameValueChanges(): void {
    if (this.mode === 'update') {
      return;
    }

    this.users$ = this.userForm.lastName.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((search: string) => {
        const isValidSearch = !!search && search.length > 1;
        return isValidSearch ? this.userApiService.searchUsers(search) : of([]);
      }),
      remainSubscribedOnError()
    );
  }
}
