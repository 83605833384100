export interface TimeTrackingsInvoicingTypeJson {
  id: number;
  wording: string;
  default: boolean;
}

export class TimeTrackingsInvoicingType {
  constructor(
    public id?: number,
    public wording?: string,
    public isDefault?: boolean
  ) {}

  static fromJson(json: Partial<TimeTrackingsInvoicingTypeJson>): TimeTrackingsInvoicingType {
    return new TimeTrackingsInvoicingType(json.id, json.wording, json.default);
  }
}
