<tiime-input-container tiimeFormInputContainer label="Qualité" contentId="capacity">
  <tiime-select id="capacity" [formControl]="control" data-cy="user-quality-select" [class.selected]="control.value">
    <div tiimeSelectOptions data-cy="user-quality-select-tab">
      <mat-option [value]="null"></mat-option>
      <mat-option [value]="capacity" *ngFor="let capacity of selectorData; trackBy: trackByIndex">
        {{ capacity }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
