import { FormControl, ValidatorFn, Validators } from '@angular/forms';

import { RegexUtils, ValidatorsUtils } from 'tiime-expert-utils';

export class SiretFormControl extends FormControl<string | null | undefined> {
  get errorMessage(): string {
    if (!this.touched) {
      return null;
    }
    if (this.hasError('pattern')) {
      return 'Un numéro de SIRET doit avoir 14 chiffres';
    }
    if (this.hasError('validateSiret')) {
      return 'Numéro de SIRET invalide';
    }
    if (this.hasError('required')) {
      return 'Le numéro de SIRET est requis';
    }
    return null;
  }

  constructor(value: string | null | undefined = null, additionalValidators: ValidatorFn[] = []) {
    super(value, [
      ...additionalValidators,
      Validators.pattern(RegexUtils.siretRegex),
      ValidatorsUtils.siretValidator()
    ]);
  }
}
