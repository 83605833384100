<tiime-dialog (cancel)="cancel()">
  <div class="dialog-container-content">
    <tiime-sticky-table-container>
      <div tiimeContainerHeader>
        <div tiimeContainerHeaderLeft>
          Paramétrage plan de catégorie
          <app-label-category-plan-select
            [small]="true"
            [ctrl]="categoryIdFilter"
            [labelCategoryPlans]="labelCategoryPlans"
            [emptyOption]="false"
            [createOption]="false"
            [label]="false"
          ></app-label-category-plan-select>
        </div>
        <div tiimeContainerHeaderRight>
          <tiime-table-loader [isLoading]="isLoading$ | async"></tiime-table-loader>
        </div>
      </div>
      <app-label-category-plan-setup-table
        mode="text"
        [displayedColumns]="displayedColumns"
        [data]="labelCategoryPlanSetupForms"
        [vatTypeRequiringVatExonerationType]="vatTypeRequiringVatExonerationType$ | async"
        [vatTypes]="vatTypes$ | async"
        [vatExonerationTypes]="vatExonerationTypes$ | async"
        tiimeContainerContent
      ></app-label-category-plan-setup-table>
    </tiime-sticky-table-container>
  </div>
</tiime-dialog>
