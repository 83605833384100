import { Directive, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { UserCapacity } from '@enums/user-capacity.enum';
import { DirectorForm } from '@forms/user/director-form';
import { DirectorPipe } from '@shared-pipes/director/director.pipe';

interface UserFormTypeInterface extends FormGroup {
  capacity: FormControl<UserCapacity | undefined>;
  director: DirectorForm;
}

@Directive()
export abstract class UserFormBase<FormType extends UserFormTypeInterface> {
  @Input() userForm: FormType;

  protected subscription = new Subscription();

  private directorPipe = new DirectorPipe();

  protected observeCapacityValueChanges(): void {
    this.subscription.add(
      this.userForm.capacity.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(capacity => !this.directorPipe.transform(capacity)),
          tap(() => this.userForm.director.reset())
        )
        .subscribe()
    );
  }
}
