import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ErrorCode } from '@constants/error.constant';
import { DateHelper } from '@helpers/date.helper';
import { FormHelper, FormHelperDatePickerErrorMessageInterface } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';

export interface EbicsDownloadDates {
  start_date: string;
  end_date: string;
}

export type EbicsDownloadDatesFormType = {
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
};

export class EbicsDownloadDatesForm extends FormGroup<EbicsDownloadDatesFormType> {
  constructor() {
    super({
      startDate: new FormControl<string | null>(null, [Validators.required]),
      endDate: new FormControl<string | null>(null, [Validators.required])
    });
  }

  get startDate(): FormControl<string | null> {
    return this.controls.startDate;
  }

  get startDateErrorMessage(): ErrorMessage | null {
    const formHelperDatePickerErrorMessageInterface: FormHelperDatePickerErrorMessageInterface = {
      checkMin: true,
      minError: ErrorCode.INVALID,
      minErrorMessage: 'La date de début doit être au maximum 30 jours avant la date de fin',
      checkMax: true,
      maxError: ErrorCode.INVALID,
      maxErrorMessage: 'La date de début doit être inférieure à la date de fin'
    };

    return FormHelper.composeErrorMessages(this.startDate, [
      {
        method: 'getEmptyErrorMessage',
        args: ['La date de début est requise']
      },
      {
        method: 'getDatePickerErrorMessage',
        args: [formHelperDatePickerErrorMessageInterface]
      }
    ]);
  }

  get endDate(): FormControl<string | null> {
    return this.controls.endDate;
  }

  get endDateErrorMessage(): ErrorMessage | null {
    const formHelperDatePickerErrorMessageInterface: FormHelperDatePickerErrorMessageInterface = {
      checkMin: true,
      minError: ErrorCode.INVALID,
      minErrorMessage: 'La date de fin doit être supérieure à la date de début',
      checkMax: true,
      maxError: ErrorCode.INVALID,
      maxErrorMessage: 'La date de fin doit être au maximum 30 jours après la date de début'
    };

    return FormHelper.composeErrorMessages(this.endDate, [
      {
        method: 'getEmptyErrorMessage',
        args: ['La date de fin est requise']
      },
      {
        method: 'getDatePickerErrorMessage',
        args: [formHelperDatePickerErrorMessageInterface]
      }
    ]);
  }

  toEbicsDownloadDates(): EbicsDownloadDates {
    return {
      start_date: DateHelper.format(this.startDate.value),
      end_date: DateHelper.format(this.endDate.value)
    };
  }
}
