import { UserCapacity } from '@enums/user-capacity.enum';

export const USER_CAPACITIES: UserCapacity[] = [
  UserCapacity.Manager,
  UserCapacity.President,
  UserCapacity.GeneralDirector,
  UserCapacity.Independent,
  UserCapacity.Employee,
  UserCapacity.Other
];

export const MORAL_USER_CAPACITIES: UserCapacity[] = [
  UserCapacity.Manager,
  UserCapacity.President,
  UserCapacity.GeneralDirector,
  UserCapacity.Other
];
