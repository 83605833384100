import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { HubAuthInterceptor } from './hub-auth.interceptor';

@NgModule()
export class HubAuthModule {
  constructor(@Optional() @SkipSelf() parentModule: HubAuthModule) {
    if (parentModule) {
      throw new Error(`HubAuthModule is already loaded. It should only be imported in your application's main module.`);
    }
  }

  static forRoot(): ModuleWithProviders<HubAuthModule> {
    return {
      ngModule: HubAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HubAuthInterceptor,
          multi: true
        }
      ]
    };
  }
}
