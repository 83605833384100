<tiime-dialog (cancel)="cancel()">
  <span mat-dialog-title>Ajouter un dossier</span>
  <mat-dialog-content class="dialog-content" mat-dialog-content>
    <ng-container *ngIf="isLoading$ | async; else availableBusinessUnitsTemplate">
      <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
    </ng-container>
    <ng-template #availableBusinessUnitsTemplate>
      <ng-container *ngIf="canCreateCompany(); else cannotCreateCompanyTemplate">
        <div class="choice-card" (click)="openCreateCreationRequestDialog()" data-cy="legal-create-company">
          <span class="product">
            <img src="assets/logo_company_creation.svg" alt="logo tiime legal" />
            Tiime Legal
          </span>
          <span class="choice-action">Créer une société</span>
        </div>
        <div class="choice-card" (click)="openCreateCompanyDialog()">
          <span class="product">
            <img src="assets/logo_expert_nav.svg" alt="logo tiime expert" />
            Tiime Expert
          </span>
          <span class="choice-action">Ajouter un dossier</span>
        </div>
      </ng-container>
      <ng-template #cannotCreateCompanyTemplate>
        <tiime-alert warn>
          La création de dossier depuis l’interface Tiime n’est pas autorisée pour vos groupes
        </tiime-alert>
      </ng-template>
    </ng-template>
  </mat-dialog-content>
</tiime-dialog>
