import { Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { createDialogServiceBase } from 'tiime-material';

import { LabelCategoryPlanSetupDialogComponent } from './label-category-plan-setup-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LabelCategoryPlanSetupDialogService extends createDialogServiceBase<void, number>(
  LabelCategoryPlanSetupDialogComponent
) {
  override open(config: MatDialogConfig<number> = {}): this {
    if (this.dialogRef) {
      this.dialogRef.close(null);
    }
    this.dialogRef = this.dialog.open(LabelCategoryPlanSetupDialogComponent, {
      panelClass: ['tiime-dialog-panel', 'label-category-plan-setup-panel'],
      height: 'calc(100% - 150px)',
      width: '950px',
      ...config
    });
    this.dialogRef.updatePosition({
      top: '75px'
    });
    return this;
  }
}
