import { MaritalStatusCode } from '@enums/marital-status-code.enum';

import { LegalFiscalReferenceIncomeEnum } from '../enums/legal-fiscal-reference-income.enum';

export interface DividendJson {
  user_id: number;
  moral_person_id: number;
  firstname: string;
  lastname: string;
  shares_percentage: number;
  gross_dividends: number;
  tax_residence: string;
  fiscal_reference_income_n_2: LegalFiscalReferenceIncomeEnum;
  marital_status_n_2: MaritalStatusCode;
  pfu: boolean;
  moral_person_name?: string;
}

export class Dividend {
  constructor(
    public userId?: number,
    public moralPersonId?: number,
    public firstname?: string,
    public lastname?: string,
    public sharesPercentage?: number,
    public grossDividends?: number,
    public taxResidence?: string,
    public fiscalReferenceIncomeN2?: LegalFiscalReferenceIncomeEnum,
    public maritalStatusN2?: MaritalStatusCode,
    public pfu?: boolean,
    public moralPersonName?: string
  ) {}

  get id(): number {
    return this.userId || this.moralPersonId;
  }

  get fullName(): string {
    return this.userId ? `${this.firstname} ${this.lastname}` : this.moralPersonName;
  }

  static fromJson(json: DividendJson): Dividend {
    return new Dividend(
      json.user_id,
      json.moral_person_id,
      json.firstname,
      json.lastname,
      json.shares_percentage,
      json.gross_dividends,
      json.tax_residence,
      json.fiscal_reference_income_n_2,
      json.marital_status_n_2,
      json.pfu,
      json.moral_person_name
    );
  }

  static toJson(dividend: Dividend): DividendJson {
    return {
      user_id: dividend.userId,
      moral_person_id: dividend.moralPersonId,
      firstname: dividend.firstname,
      lastname: dividend.lastname,
      shares_percentage: dividend.sharesPercentage,
      gross_dividends: dividend.grossDividends,
      tax_residence: dividend.taxResidence,
      fiscal_reference_income_n_2: dividend.fiscalReferenceIncomeN2,
      marital_status_n_2: dividend.maritalStatusN2,
      pfu: dividend.pfu,
      moral_person_name: dividend.moralPersonName
    };
  }
}
