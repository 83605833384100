<tiime-container [containerState]="containerState$ | async">
  <div tiimeContainerHeader>
    <div tiimeContainerHeaderLeft>Notifications</div>
    <div tiimeContainerHeaderRight>
      <tiime-table-loader [isLoading]="isLoading$ | async"></tiime-table-loader>
      <button tiime-button icon color="primary" [disabled]="disableMarkAllAsReadButton$ | async" (click)="markAllAsRead()" tiimeTooltip="Tout marquer comme lu">
        <mat-icon inline color="primary" svgIcon="icon-done-all"></mat-icon>
      </button>
      <button class="close-btn" tiime-button color="primary" icon (click)="close()">
        <mat-icon inline="true" fontIcon="icon-ic-close"></mat-icon>
      </button>
    </div>
  </div>
  <div tiimeContainerHeader>
    <mat-button-toggle-group [formControl]="readControl" (change)="readControlChange($event.value)">
      <mat-button-toggle [value]="null">Toutes</mat-button-toggle>
      <mat-button-toggle [value]="true">Lues</mat-button-toggle>
      <mat-button-toggle [value]="false">Non lues</mat-button-toggle>
    </mat-button-toggle-group>
    <div tiimeContainerHeaderRight>
      <tiime-paginator [range]="pagination$ | async" (rangeEvent)="setPagination($event)"></tiime-paginator>
    </div>
  </div>
  <cdk-virtual-scroll-viewport [itemSize]="virtualScrollItemSize" tiimeContainerContent>
    <app-notification
      [class.readed]="notification.read"
      [notification]="notification"
      *cdkVirtualFor="let notification of notifications$ | async; trackBy: trackById"
      (click)="notificationClicked(notification)"
    >
      <div notification-actions>
        <ng-container *ngFor="let action of notification.data?.actions; trackBy: trackByIndex">
          <button
            tiime-button
            color="primary"
            icon
            strocked
            tiimeTooltip
            [tooltipContent]="downloadTooltipTemplate"
            (click)="$event.stopPropagation(); downloadFile(notification)"
            *ngIf="action.type === 'download'"
          >
            <mat-icon fontIcon="icon-download-24"></mat-icon>
          </button>
          <ng-template #downloadTooltipTemplate>Télécharger</ng-template>
        </ng-container>
      </div>
      <app-notification-read-action
        notification-top-action
        [notification]="notification"
        (markNotificationAsRead)="markNotificationAsRead(notification)"
      ></app-notification-read-action>
    </app-notification>
  </cdk-virtual-scroll-viewport>
  <tiime-sticky-table-no-results tiimeContainerNoResult></tiime-sticky-table-no-results>
  <tiime-sticky-table-content-placeholder tiimeContainerContentPlaceholder></tiime-sticky-table-content-placeholder>
</tiime-container>
