import { FormControl, FormGroup } from '@angular/forms';

import { ErrorCode } from '@constants/error.constant';
import { MaritalStatusCode } from '@enums/marital-status-code.enum';
import { FormHelper } from '@helpers/form.helper';
import { ErrorMessage } from '@interfaces/error-message';

export type LegalInformationFormType = {
  birthDate: FormControl<string | null>;
  birthPlace: FormControl<string | null>;
  birthDepartment: FormControl<string | null>;
  birthCountry: FormControl<string | null>;
  nationality: FormControl<string | null>;
  maritalStatus: FormControl<MaritalStatusCode | null>;
  taxNumber: FormControl<number | null>;
  socialSecurityNumber: FormControl<number | null>;
};

export class LegalInformationForm extends FormGroup<LegalInformationFormType> {
  get birthDate(): FormControl<string | null> {
    return this.controls.birthDate;
  }

  get birthDateErrorMessage(): ErrorMessage {
    return FormHelper.getDatePickerErrorMessage(this.birthDate, {
      checkMax: true,
      maxError: ErrorCode.INVALID,
      maxErrorMessage: 'La date de naissance doit être antérieure à la date du jour'
    });
  }

  get birthPlace(): FormControl<string | null> {
    return this.controls.birthPlace;
  }

  get birthDepartment(): FormControl<string | null> {
    return this.controls.birthDepartment;
  }

  get birthCountry(): FormControl<string | null> {
    return this.controls.birthCountry;
  }

  get nationality(): FormControl<string | null> {
    return this.controls.nationality;
  }

  get maritalStatus(): FormControl<MaritalStatusCode | null> {
    return this.controls.maritalStatus;
  }

  get taxNumber(): FormControl<number | null> {
    return this.controls.taxNumber;
  }

  get socialSecurityNumber(): FormControl<number | null> {
    return this.controls.socialSecurityNumber;
  }

  constructor() {
    super({
      birthDate: new FormControl<string | null>(null),
      birthPlace: new FormControl<string | null>(null),
      birthDepartment: new FormControl<string | null>(null),
      birthCountry: new FormControl<string | null>(null),
      nationality: new FormControl<string | null>(null),
      maritalStatus: new FormControl<MaritalStatusCode | null>(null),
      taxNumber: new FormControl<number | null>(null),
      socialSecurityNumber: new FormControl<number | null>(null)
    });
  }
}
