import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { API_ROUTE_REPLACE_COMPANY_KEY } from '@constants/api-routes.constant';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { AccountingConfig, AccountingConfigJson } from '@models/accounting-config';

@Injectable({
  providedIn: 'root'
})
export class AccountingConfigApiService extends ApiCommonService<AccountingConfig, AccountingConfigJson> {
  resourceUrl = `api/v1/expert/companies/${API_ROUTE_REPLACE_COMPANY_KEY}/accounting_config`;

  fromJson(json: AccountingConfigJson): AccountingConfig {
    return AccountingConfig.fromJson(json);
  }

  toJson(model: AccountingConfig): AccountingConfigJson {
    return AccountingConfig.toJson(model);
  }

  @ApiAlertError()
  getAccountingConfig(companyId?: number): Observable<AccountingConfig> {
    let url = this.resourceUrl;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .get<AccountingConfigJson>(url)
      .pipe(map(accountingConfigJson => AccountingConfig.fromJson(accountingConfigJson)));
  }

  @ApiAlertError()
  updateAccountingConfig(accountingConfig: AccountingConfig, companyId?: number): Observable<AccountingConfig> {
    let url = this.resourceUrl;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .patch<AccountingConfigJson>(`${url}/${accountingConfig.id}`, this.toJson(accountingConfig))
      .pipe(map(accountingConfigJson => AccountingConfig.fromJson(accountingConfigJson)));
  }

  // On laisse la gestion des erreurs à l'appelant
  updatePartialAccountingConfig(
    accountingConfig: Partial<AccountingConfigJson>,
    companyId?: number
  ): Observable<AccountingConfig> {
    let url = `${this.resourceUrl}/${accountingConfig.id}`;
    if (companyId) {
      url = HttpHelper.replaceCompanyUrl(url, companyId);
    }

    return this.http
      .patch<AccountingConfigJson>(url, accountingConfig)
      .pipe(map(accountingConfigJson => AccountingConfig.fromJson(accountingConfigJson)));
  }
}
