<tiime-dialog [isLoading]="isLoading$ | async" (cancel)="closeCreateCompanyDialog()">
  <span mat-dialog-title>Ajouter un dossier</span>
  <mat-dialog-content class="dialog-content" mat-dialog-content>
    <ng-container *ngIf="!submitLoading; else submitLoadingTemplate">
      <tiime-table-anchor></tiime-table-anchor>
      <tiime-stepper linear>
        <tiime-step [stepControl]="createCompanyForm.userStep" label="Contact">
          <app-user-form
            [userForm]="createCompanyForm.userStep.user"
            [expandedForms]="userExpandedForms"
          ></app-user-form>
          <div tiimeStepperActions>
            <button tiime-button large color="accent" raised (click)="checkIfUserExist()">Suivant</button>
          </div>
        </tiime-step>
        <tiime-step [stepControl]="createCompanyForm.companyStep" label="Dossier">
          <ng-container *ngIf="existingCompanies().length; else companyFormTemplate">
            <div class="existing-companies-label">
              Il existe un ou plusieurs dossiers associés au dirigeant sélectionné
            </div>
            <app-duplicated-users-container
              [duplicatedUsers]="existingCompanies()"
              [displayAction]="data.prospect"
              (actionClicked)="selectExistingCompany($event)"
            ></app-duplicated-users-container>
            <button
              class="existing-companies-action"
              large
              tiime-button
              color="accent"
              raised
              (click)="resetExistingCompanies()"
            >
              Ajouter un nouveau dossier
            </button>
          </ng-container>
          <ng-template #companyFormTemplate>
            <app-company-form
              [companyForm]="createCompanyForm.companyStep.company"
              [businessUnits]="data.businessUnits"
              [expandedForms]="companyExpandedForms"
            ></app-company-form>
          </ng-template>
          <div tiimeStepperActions>
            <button tiime-button large color="accent" strocked tiimeStepperPrevious>Précédent</button>
            <button
              tiime-button
              large
              color="accent"
              raised
              [disabled]="existingCompanies().length"
              (click)="createCompanyForm.companyStep.markAllAsTouched()"
              tiimeStepperNext
            >
              Suivant
            </button>
          </div>
        </tiime-step>
        <tiime-step [stepControl]="createCompanyForm.invitationStep" label="Invitation">
          <app-invitation-form
            [userPhoneNumber]="createCompanyForm.userStep.user.phone.value"
            [invitationForm]="createCompanyForm.invitationStep.invitation"
          ></app-invitation-form>
          <div
            *ngIf="createCompanyForm.invitationStep.touched && createCompanyForm.invitationStep.invalid"
            class="step-error-container"
          >
            <span>Veuillez sélectionner au moins une application et un type d'envoi</span>
          </div>
          <div tiimeStepperActions>
            <button tiime-button large color="accent" strocked tiimeStepperPrevious>Précédent</button>
            <button
              tiime-button
              large
              color="accent"
              raised
              (click)="createCompanyForm.invitationStep.markAllAsTouched()"
              tiimeStepperNext
            >
              Suivant
            </button>
          </div>
        </tiime-step>
        <tiime-step [stepControl]="createCompanyForm.parametersStep" label="Paramétrage">
          <app-company-initialization-form
            [businessUnitId]="createCompanyForm.companyStep.company.businessUnit.value?.id"
            [parametersStepForm]="createCompanyForm.parametersStep"
            [labelCategoryPlans]="labelCategoryPlans$ | async"
          ></app-company-initialization-form>
          <div class="step-error-container">
            <span *ngIf="createCompanyForm.parametersStep.touched && createCompanyForm.parametersStep.invalid">
              Veuillez importer un fichier FEC ou sélectionner un plan de labels.
            </span>
          </div>
          <div tiimeStepperActions>
            <button tiime-button large color="accent" strocked tiimeStepperPrevious>Précédent</button>
            <button tiime-button large color="accent" raised (click)="submitForm()">Ajouter le dossier</button>
          </div>
        </tiime-step>
      </tiime-stepper>
    </ng-container>
    <ng-template #submitLoadingTemplate>
      <div class="submit-loader">
        <span>Création du dossier en cours...</span>
        <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-template>
  </mat-dialog-content>
</tiime-dialog>
