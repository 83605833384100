import { NgModule } from '@angular/core';
import { MatLegacyMenuDefaultOptions as MatMenuDefaultOptions, MatLegacyMenuModule as MatMenuModule, MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/legacy-menu';

const menuDefaultOptions: MatMenuDefaultOptions = {
  backdropClass: '',
  hasBackdrop: true,
  overlapTrigger: true,
  yPosition: 'below',
  xPosition: 'after'
};

@NgModule({
  imports: [MatMenuModule],
  exports: [MatMenuModule],
  providers: [
    { provide: MAT_MENU_DEFAULT_OPTIONS, useValue: menuDefaultOptions }
  ]
})
export class TiimeMenuModule {}
