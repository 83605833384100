<div class="export-section">
  <div class="export-period">
    {{ label }}
    <app-export-datepicker [dateControl]="startDate" [min]="minStartDate" [max]="endDate.value"></app-export-datepicker>
    <app-list-error *ngIf="startDateErrorMessage" [errorMessage]="startDateErrorMessage"></app-list-error>
    au
    <app-export-datepicker [dateControl]="endDate" [min]="startDate.value" [max]="maxEndDate"></app-export-datepicker>
    <app-list-error *ngIf="endDateErrorMessage" [errorMessage]="endDateErrorMessage"></app-list-error>
  </div>
</div>
