const MODULE_EQUAL_NET_GAIN = 'module_equal_net_gain';
const MODULE_EQUAL_RECOGNIZED_CHARGES = 'module_equal_recognized_charges';
const PCG_ENTRIES_UNSETTLED_OR_UNCONFIGURED = 'pcg_entries_unsettled_or_unconfigured';
const MODULE_EQUAL_INCOME_TAXES = 'module_equal_income_taxes';

export const TNS_CONSISTENCY_CHECKS_CODES: string[] = [
  MODULE_EQUAL_NET_GAIN,
  MODULE_EQUAL_RECOGNIZED_CHARGES,
  PCG_ENTRIES_UNSETTLED_OR_UNCONFIGURED,
  MODULE_EQUAL_INCOME_TAXES
];
