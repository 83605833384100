import { Company, CompanyJson } from '@models/company';

import { LegalPhysicalPerson, LegalPhysicalPersonJson } from './legal-physical-person';
import { Activities, ActivitiesJson } from '../../creation/creation-core/models/activities';
import { Capital, CapitalJson } from '../../creation/creation-core/models/capital';
import { CapitalDeposit, CapitalDepositJson } from '../../creation/creation-core/models/capital-deposit';
import {
  CreationPublicationJournal,
  CreationPublicationJournalJson
} from '../../creation/creation-core/models/creation-publication-journal';
import { DataCollectionHome, DataCollectionHomeJson } from '../../creation/creation-core/models/data-collection-home';
import { FiscalOptions, FiscalOptionsJson } from '../../creation/creation-core/models/fiscal-options';
import { HeadOffice, HeadOfficeJson } from '../../creation/creation-core/models/head-office';
import { InpiDeposit, InpiDepositJson } from '../../creation/creation-core/models/inpi-deposit';
import { LegalForm, LegalFormJson } from '../../creation/creation-core/models/legal-form';

export interface ClosedCreationJson {
  id?: number;
  activities?: ActivitiesJson;
  capital?: CapitalJson;
  company?: Partial<CompanyJson>;
  fiscal?: FiscalOptionsJson;
  head_office?: HeadOfficeJson;
  legal_form?: LegalFormJson;
  users?: Partial<LegalPhysicalPersonJson>[];
  home?: DataCollectionHomeJson;
  capital_deposit?: CapitalDepositJson;
  publication_journal?: CreationPublicationJournalJson;
  inpi?: InpiDepositJson;
}

export class ClosedCreation {
  constructor(
    public id?: number,
    public activities?: Activities,
    public capital?: Capital,
    public company?: Partial<Company>,
    public fiscalOptions?: FiscalOptions,
    public headOffice?: HeadOffice,
    public legalForm?: LegalForm,
    public physicalPersons?: LegalPhysicalPerson[],
    public home?: DataCollectionHome,
    public capitalDeposit?: CapitalDeposit,
    public publicationJournal?: CreationPublicationJournal,
    public inpiDeposit?: InpiDeposit
  ) {}

  static fromJson(json: Partial<ClosedCreationJson>): ClosedCreation {
    return new ClosedCreation(
      json.id,
      json.activities ? Activities.fromJson(json.activities) : undefined,
      json.capital ? Capital.fromJson(json.capital) : undefined,
      json.company ? Company.fromJson(json.company) : undefined,
      json.fiscal ? FiscalOptions.fromJson(json.fiscal) : undefined,
      json.head_office ? HeadOffice.fromJson(json.head_office) : undefined,
      json.legal_form ? LegalForm.fromJson(json.legal_form) : undefined,
      json.users ? json.users.map((user: LegalPhysicalPersonJson) => LegalPhysicalPerson.fromJson(user)) : undefined,
      json.home ? DataCollectionHome.fromJson(json.home) : undefined,
      json.capital_deposit ? CapitalDeposit.fromJson(json.capital_deposit) : undefined,
      json.publication_journal ? CreationPublicationJournal.fromJson(json.publication_journal) : undefined,
      json.inpi ? InpiDeposit.fromJson(json.inpi) : undefined
    );
  }

  static toJson(model: Partial<ClosedCreation>): ClosedCreationJson {
    return {
      id: model.id,
      activities: model.activities ? Activities.toJson(model.activities) : null,
      capital: model.capital ? Capital.toJson(model.capital) : null,
      company: model.company ? Company.toJson(model.company) : null,
      fiscal: model.fiscalOptions ? FiscalOptions.toJson(model.fiscalOptions) : null,
      head_office: model.headOffice ? HeadOffice.toJson(model.headOffice) : null,
      legal_form: model.legalForm ? LegalForm.toJson(model.legalForm) : null,
      users: model.physicalPersons
        ? model.physicalPersons.map((physicalPerson: LegalPhysicalPerson) => LegalPhysicalPerson.toJson(physicalPerson))
        : null,
      home: model.home ? DataCollectionHome.toJson(model.home) : null,
      capital_deposit: model.capitalDeposit ? CapitalDeposit.toJson(model.capitalDeposit) : null,
      publication_journal: model.publicationJournal
        ? CreationPublicationJournal.toJson(model.publicationJournal)
        : null,
      inpi: model.inpiDeposit ? InpiDeposit.toJson(model.inpiDeposit) : null
    };
  }
}
