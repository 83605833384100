import { DateHelper } from '@helpers/date.helper';
import { AccountingPeriod, AccountingPeriodJson } from '@models/accounting-period';
import { Company, CompanyJson } from '@models/company';
import { Contributor, ContributorJson } from '@models/contributor';

import { AccountsApprovalDeposit, AccountsApprovalDepositJson } from './accounts-approval-deposit';
import { ClosedCreation, ClosedCreationJson } from './closed-creation';
import { LegalMoralPerson, LegalMoralPersonJson } from './legal-moral-person';
import { LegalPhysicalPerson, LegalPhysicalPersonJson } from './legal-physical-person';
import { Convention, ConventionJson } from '../../accounts-approval/accounts-approval-core/models/convention';
import { Dividend, DividendJson } from '../../accounts-approval/accounts-approval-core/models/dividend';
import {
  GeneralAssembly,
  GeneralAssemblyJson
} from '../../accounts-approval/accounts-approval-core/models/general-assembly';
import { Remuneration, RemunerationJson } from '../../accounts-approval/accounts-approval-core/models/remuneration';
import { Result } from '../../accounts-approval/accounts-approval-core/models/result';
import { Scope, ScopeJson } from '../../accounts-approval/accounts-approval-core/models/scope';
import { LegalFormalityStatus, LegalFormalityStatusJson } from '../../legal-core/models/legal-formality-status';
import { FormalitySupervisorEnum } from '../enums/formality-supervisor.enum';
import { FormalityStatusEnum } from '../enums/formality-status.enum';
import { FormalityTypeEnum } from '../enums/formality-type.enum';

export interface FormalityJson {
  id?: number;
  formality_type?: string;
  code_name?: string;
  accounting_period?: Partial<AccountingPeriodJson>;
  status?: string;
  formality_supervisor?: FormalitySupervisorEnum;
  company_creation?: ClosedCreationJson;
  company?: Partial<CompanyJson>;
  beneficiaries?: Partial<LegalPhysicalPersonJson>[];
  validated_at?: string;
  refreshed_at?: string;
  scope?: ScopeJson;
  general_assembly?: GeneralAssemblyJson;
  result?: Result;
  convention?: ConventionJson;
  remunerations?: RemunerationJson[];
  dividends?: DividendJson[];
  delivery_date?: string;
  statuses?: LegalFormalityStatusJson[];
  business_unit_id?: number;
  moral_beneficiaries?: Partial<LegalMoralPersonJson>[];
  production?: Partial<ContributorJson>;
  account_approval_deposit?: AccountsApprovalDepositJson;
  last_reopen_date?: string;
}

export class Formality {
  constructor(
    public id?: number,
    public formalityType?: string,
    public codeName?: string,
    public accountingPeriod?: AccountingPeriod,
    public status?: string,
    public formalitySupervisor?: FormalitySupervisorEnum,
    public closedCreation?: ClosedCreation,
    public company?: Company,
    public beneficiaries?: LegalPhysicalPerson[],
    public validatedAt?: Date,
    public refreshedAt?: Date,
    public scope?: Scope,
    public generalAssembly?: GeneralAssembly,
    public result?: Result,
    public convention?: Convention,
    public remunerations?: Remuneration[],
    public dividends?: Dividend[],
    public deliveryDate?: Date,
    public statuses?: LegalFormalityStatus[],
    public businessUnitId?: number,
    public moralBeneficiaries?: LegalMoralPerson[],
    public production?: Contributor,
    public accountsApprovalDeposit?: AccountsApprovalDepositJson,
    public lastReopenDate?: Date
  ) {}

  static isOutsourcedOrLiquidation(formality: Formality): boolean {
    return (
      formality.formalityType === FormalityTypeEnum.annual &&
      (formality.status === FormalityStatusEnum.outsourced || formality.status === FormalityStatusEnum.liquidation)
    );
  }

  static fromJson(json: Partial<FormalityJson>): Formality {
    return new Formality(
      json.id,
      json.formality_type,
      json.code_name,
      json.accounting_period?.id ? AccountingPeriod.fromJson(json.accounting_period) : null,
      json.status,
      json.formality_supervisor,
      json.company_creation ? ClosedCreation.fromJson(json.company_creation) : null,
      json.company ? Company.fromJson(json.company) : null,
      json.beneficiaries
        ? json.beneficiaries.map((beneficiaryJson: LegalPhysicalPersonJson) =>
            LegalPhysicalPerson.fromJson(beneficiaryJson)
          )
        : null,
      json.validated_at ? DateHelper.fromUtc(json.validated_at) : null,
      json.refreshed_at ? DateHelper.fromUtc(json.refreshed_at) : null,
      json.scope ? Scope.fromJson(json.scope) : null,
      json.general_assembly ? GeneralAssembly.fromJson(json.general_assembly) : null,
      json.result ? Result.fromJson(json.result) : null,
      json.convention ? Convention.fromJson(json.convention) : null,
      json.remunerations
        ? json.remunerations.map((remunerationJson: RemunerationJson) => Remuneration.fromJson(remunerationJson))
        : null,
      json.dividends ? json.dividends.map((dividendJson: DividendJson) => Dividend.fromJson(dividendJson)) : null,
      json.delivery_date ? DateHelper.fromUtc(json.delivery_date) : null,
      json.statuses
        ? json.statuses.map((legalFormalityStatusJson: LegalFormalityStatusJson) =>
            LegalFormalityStatus.fromJson(legalFormalityStatusJson)
          )
        : null,
      json.business_unit_id,
      json.moral_beneficiaries
        ? json.moral_beneficiaries.map((moralBeneficiaryJson: LegalMoralPersonJson) =>
            LegalMoralPerson.fromJson(moralBeneficiaryJson)
          )
        : null,
      json.production ? Contributor.fromJson(json.production) : null,
      json.account_approval_deposit ? AccountsApprovalDeposit.fromJson(json.account_approval_deposit) : null,
      json.last_reopen_date ? DateHelper.fromUtc(json.last_reopen_date) : null
    );
  }

  static toJson(formality: Formality): Partial<FormalityJson> {
    return {
      id: formality.id,
      formality_type: formality.formalityType,
      code_name: formality.codeName,
      accounting_period: formality.accountingPeriod?.id ? AccountingPeriod.toJson(formality.accountingPeriod) : null,
      status: formality.status,
      formality_supervisor: formality.formalitySupervisor,
      company: formality.company ? Company.toJson(formality.company) : null,
      beneficiaries: formality.beneficiaries
        ? formality.beneficiaries.map((beneficiary: LegalPhysicalPerson) => LegalPhysicalPerson.toJson(beneficiary))
        : null,
      validated_at: formality.validatedAt ? DateHelper.format(formality.validatedAt, 'YYYY-MM-DD HH:mm:ss') : null,
      refreshed_at: formality.refreshedAt ? DateHelper.format(formality.refreshedAt, 'YYYY-MM-DD HH:mm:ss') : null,
      scope: formality.scope ? Scope.toJson(formality.scope) : null,
      general_assembly: formality.generalAssembly ? GeneralAssembly.toJson(formality.generalAssembly) : null,
      result: formality.result ? Result.toJson(formality.result) : null,
      convention: formality.convention ? Convention.toJson(formality.convention) : null,
      remunerations: formality.remunerations
        ? formality.remunerations.map((remuneration: Remuneration) => Remuneration.toJson(remuneration))
        : null,
      dividends: formality.dividends
        ? formality.dividends.map((dividend: Dividend) => Dividend.toJson(dividend))
        : null,
      delivery_date: formality.deliveryDate ? DateHelper.format(formality.deliveryDate, 'YYYY-MM-DD') : null,
      statuses: formality.statuses
        ? formality.statuses.map((legalFormalityStatus: LegalFormalityStatus) =>
            LegalFormalityStatus.toJson(legalFormalityStatus)
          )
        : [],
      business_unit_id: formality.businessUnitId,
      moral_beneficiaries: formality.moralBeneficiaries
        ? formality.moralBeneficiaries.map((moralBeneficiary: LegalMoralPerson) =>
            LegalMoralPerson.toJson(moralBeneficiary)
          )
        : null,
      production: formality.production ? Contributor.toJson(formality.production) : null,
      account_approval_deposit: formality.accountsApprovalDeposit
        ? AccountsApprovalDeposit.toJson(formality.accountsApprovalDeposit)
        : null,
      last_reopen_date: formality.lastReopenDate
        ? DateHelper.format(formality.lastReopenDate, 'YYYY-MM-DD HH:mm:ss')
        : null
    };
  }

  static toCreateFormalityJson(formality: Formality): Partial<FormalityJson> {
    return {
      formality_type: formality.formalityType,
      code_name: formality.codeName,
      accounting_period: formality.accountingPeriod
    };
  }

  static toDeliveryDateFormalityJson(formality: Partial<Formality>): Partial<FormalityJson> {
    return {
      id: formality.id,
      delivery_date: formality.deliveryDate ? DateHelper.format(formality.deliveryDate, 'YYYY-MM-DD') : null
    };
  }

  static toProductionFormalityJson(formality: Partial<Formality>): Partial<FormalityJson> {
    return {
      id: formality.id,
      production: formality.production ? Contributor.toJson(formality.production) : null
    };
  }
}
