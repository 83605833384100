import { createMask, InputmaskOptions } from '@ngneat/input-mask';

import { MaskUtils } from 'tiime-expert-utils';

import { RegexHelper } from '@helpers/regex.helper';

export class MaskHelper {
  static getIbanMask(): InputmaskOptions<null> {
    return MaskHelper.generateMask([`[A-Za-z]{2}\\d{2}( [A-Za-z\\d]{4}){5} [A-Za-z\\d]{3}`]);
  }

  static getSiretMask(): InputmaskOptions<null> {
    return MaskHelper.generateMask(MaskUtils.siretMask);
  }

  static getSirenMask(): InputmaskOptions<null> {
    return MaskHelper.generateMask([RegexHelper.sirenRegex]);
  }

  static generateMask(regex: (string | RegExp)[]): InputmaskOptions<null> {
    return createMask({
      regex: String.raw`${regex.join('').replace(/\/\^|\$\//gm, '')}`,
      showMaskOnFocus: false,
      showMaskOnHover: false,
      placeholder: ''
    });
  }

  static generatePostalCodeMask(): InputmaskOptions<string> {
    return MaskHelper.generateMask([`^[0-9]{0,5}`]);
  }
}
