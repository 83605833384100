import { ElementRef, inject } from '@angular/core';

export abstract class TableCellBase {
  protected elementRef = inject(ElementRef);

  protected setColumnClass(name: string): void {
    if (!name) {
      return;
    }

    this.elementRef.nativeElement.classList.add(`column-${name}`);
  }
}
