import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCommonService } from '@api-services/api-common.service';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { BusinessUserPartner, BusinessUserPartnerJson } from '@models/business-user-parner';
import { LabelCategoryPlan, LabelCategoryPlanJson } from '@models/label-category-plan';
import { LabelCategoryPlanSetup, LabelCategoryPlanSetupJson } from '@models/label-category-plan-setup';

@Injectable({
  providedIn: 'root'
})
export class BusinessUserApiService extends ApiCommonService<BusinessUser> {
  readonly resourceUrl: string = 'api/v1/expert/users/me';

  fromJson(json: BusinessUserJson): BusinessUser {
    return BusinessUser.fromJson(json);
  }

  toJson(model: BusinessUser): Partial<BusinessUserJson> {
    return BusinessUser.toJson(model);
  }

  @ApiAlertError()
  getBusinessUser(): Observable<BusinessUser> {
    return this.http
      .get(this.resourceUrl)
      .pipe(map((businessUserJson: BusinessUserJson) => BusinessUser.fromJson(businessUserJson)));
  }

  @ApiAlertError()
  createBusinessUser(businessUnitId: number, businessUser: BusinessUser, isAdmin: boolean): Observable<BusinessUser> {
    const url = `api/v1/expert/business_units/${businessUnitId}/business_users`;

    const body = {
      firstname: businessUser.firstName,
      lastname: businessUser.lastName,
      email: businessUser.email,
      phone: HttpHelper.toJsonProperty(businessUser.phone),
      is_admin: isAdmin,
      acronym: businessUser.acronym
    };

    return this.http
      .post(url, body)
      .pipe(map((createdBusinessUserJson: BusinessUserJson) => BusinessUser.fromJson(createdBusinessUserJson)));
  }

  @ApiAlertError()
  getBusinessUserPartner(companyId?: number): Observable<BusinessUserPartner[]> {
    const url = `${this.resourceUrl}/partners`;
    const params = companyId ? HttpHelper.setParam('company', companyId.toString()) : null;
    return this.http
      .get(url, { params })
      .pipe(
        map((businessUserPartnersJson: any) =>
          businessUserPartnersJson.map((businessUserPartnerJson: BusinessUserPartnerJson) =>
            BusinessUserPartner.fromJson(businessUserPartnerJson)
          )
        )
      );
  }

  @ApiAlertError([403])
  updateBusinessUserPassword(password: string): Observable<any> {
    const url = 'api/v1/users/me/update_password';
    const body = { password };
    return this.http.post(url, body);
  }

  @ApiAlertError()
  getBusinessUserLabelCategoryPlans(): Observable<LabelCategoryPlan[]> {
    const url = `${this.resourceUrl}/category_setup_plans`;

    return this.http
      .get(url)
      .pipe(
        map((labelCategoryPlansJson: LabelCategoryPlanJson[]) =>
          labelCategoryPlansJson.map((labelCategoryPlanJson: LabelCategoryPlanJson) =>
            LabelCategoryPlan.fromJson(labelCategoryPlanJson)
          )
        )
      );
  }

  @ApiAlertError()
  getBusinessUserLabelCategoryPlanSetup(categoryId: number): Observable<LabelCategoryPlanSetup[]> {
    const url = `${this.resourceUrl}/category_setup_plans/${categoryId}/category_setups`;

    return this.http
      .get(url)
      .pipe(
        map((labelCategoryPlanSetupsJson: LabelCategoryPlanSetupJson[]) =>
          labelCategoryPlanSetupsJson.map((labelCategoryPlanSetupJson: LabelCategoryPlanSetupJson) =>
            LabelCategoryPlanSetup.fromJson(labelCategoryPlanSetupJson)
          )
        )
      );
  }

  @ApiAlertError()
  getBusinessUserBusinessUnits(companyCreationAllowed?: boolean): Observable<BusinessUnit[]> {
    const url = `${this.resourceUrl}/business_units`;
    const params = !!companyCreationAllowed
      ? HttpHelper.setParam('company_creation_allowed', companyCreationAllowed.toString())
      : null;
    return this.http
      .get(url, { params })
      .pipe(
        map((businessUnitsJson: BusinessUnitJson[]) =>
          businessUnitsJson.map((businessUnitJson: BusinessUnitJson) => BusinessUnit.fromJson(businessUnitJson))
        )
      );
  }

  @ApiAlertError([403])
  renewBusinessUserPassword(): Observable<void> {
    const url = 'api/v1/users/me/renew_password';

    return this.http.post<void>(url, null);
  }

  @ApiAlertError()
  getBusinessUserPartnerBusinessUnits(): Observable<BusinessUnit[]> {
    const url = `${this.resourceUrl}/partner/business_units`;

    return this.http
      .get(url)
      .pipe(
        map((businessUnitJsons: BusinessUnitJson[]) =>
          businessUnitJsons.map((businessUnitJson: BusinessUnitJson) => BusinessUnit.fromJson(businessUnitJson))
        )
      );
  }

  @ApiAlertError()
  getBusinessUserAttestationsAllowedBusinessUnits(): Observable<BusinessUnit[]> {
    const url = `${this.resourceUrl}/attestations/allowed_business_units`;

    return this.http
      .get<BusinessUnitJson[]>(url)
      .pipe(
        map((businessUnitJsons: BusinessUnitJson[]) =>
          businessUnitJsons.map((businessUnitJson: BusinessUnitJson) => BusinessUnit.fromJson(businessUnitJson))
        )
      );
  }
}
