<app-notification [notification]="notification">
  <div notification-actions>
    <ng-container *ngFor="let action of notification.data?.actions; trackBy: trackByIndex">
      <button
        tiime-button
        color="primary"
        icon
        strocked
        tiimeTooltip
        [tooltipContent]="downloadTooltipTemplate"
        (click)="$event.stopPropagation(); downloadFile(notification)"
        *ngIf="action.type === 'download'"
      >
        <mat-icon fontIcon="icon-download-24"></mat-icon>
      </button>
      <ng-template #downloadTooltipTemplate>Télécharger</ng-template>
    </ng-container>
  </div>
  <button
    class="close-button"
    notification-top-action
    tiime-button
    color="primary"
    icon
    (click)="$event.stopPropagation(); close()"
  >
    <mat-icon fontIcon="icon-ic-close"></mat-icon>
  </button>
</app-notification>
