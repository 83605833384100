<mat-autocomplete class="complex-search-bar-autocomplete tiime-autocomplete-panel" panelWidth="auto">
  <ng-container *ngFor="let section of sections; trackBy: trackByIndex">
    <div class="divider sticky" *ngIf="section.filters.length">{{ section.description }}</div>
    <mat-option
      *ngFor="let filter of section.filters; trackBy: trackByIndex"
      [value]="filter.filterValue"
      (onSelectionChange)="onSelectFilter($event, filter)"
    >
      <div class="complex-search-autocomplete-option-filter">
        <span class="complex-search-autocomplete-option-filter-filter-type">{{ filter.filterValue }}</span>
        <span
          class="complex-search-autocomplete-option-filter-filter"
          [class.need-user-intercation]="filter.needUserInteraction"
          tiimeTooltip
          [tooltipContent]="userInteractionFilterExampleTooltipTemplate"
          tooltipHideWithoutEllipsis
          *ngIf="filter.userInteractionFilterExample"
        >
          {{ filter.userInteractionFilterExample }}
        </span>
        <ng-template #userInteractionFilterExampleTooltipTemplate>
          {{ filter.userInteractionFilterExample }}
        </ng-template>
      </div>
      <div class="complex-search-autocomplete-option-infos">
        <span class="complex-search-autocomplete-option-description">{{ filter.description }}</span>
        <div class="complex-search-autocomplete-option-accepted-format">
          <span [ngPlural]="filter.acceptedFormat.length">
            <ng-template ngPluralCase="=1">Format accepté:</ng-template>
            <ng-template ngPluralCase="other">Formats acceptés:</ng-template>
          </span>
          <span>{{ filter.acceptedFormat }}</span>
        </div>
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
