import { Pipe, PipeTransform } from '@angular/core';

import { UNDEFINED_LABEL } from '@constants/undefined-label.contant';
import { LegalStructureAndSignatory } from '@interfaces/legal-structure-and-signatory';

@Pipe({
  name: 'legalStructureAndSignatory',
  standalone: true
})
export class LegalStructureAndSignatoryPipe implements PipeTransform {
  transform(legalStructureAndSignatory: LegalStructureAndSignatory, undefinedLabel: string = UNDEFINED_LABEL): string {
    if (!legalStructureAndSignatory?.legalStructure) {
      return undefinedLabel;
    }

    let result = legalStructureAndSignatory.legalStructure.name;
    if (legalStructureAndSignatory.signatory?.user) {
      result = result.concat(' • ', legalStructureAndSignatory.signatory.user?.fullName);
    }

    return result;
  }
}
