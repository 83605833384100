import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { ApiAlertError } from '@decorators/api-alert-error';
import { CompaniesApiFiltersEnum } from '@enums/companies-api-filters-enum';
import { HttpHelper } from '@helpers/http.helper';
import { AdminCompaniesFilters } from '@interfaces/admin-companies-filters';
import { JsonId } from '@interfaces/json-id';
import { AdminCompany, AdminCompanyJson } from '@models/admin-company';
import { AnnualBooklet, AnnualBookletJson } from '@models/annual-booklet';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import {
  BusinessUnitConnectionProvider,
  BusinessUnitConnectionProviderJson
} from '@models/business-unit-connection-provider';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { ConnectionProviderCredential } from '@models/connection-provider-credential';
import { ContributorJob, ContributorJobJson } from '@models/contributor-job';
import { CoverPage, CoverPageJson } from '@models/cover-page';
import {
  CoverPageBackgroundImage,
  CoverPageBackgroundImageJson,
  CoverPageBackgroundImageSerialized
} from '@models/cover-page-background-image';
import { LabelsPlan, LabelsPlanJson } from '@models/labels-plan';
import { Logo, LogoJson } from '@models/logo';
import { FileSaverData } from '@services/file.service';
import { ConnectionProviderEbicsDownloadForm } from '@shared-dialogs/ebics-download-dialog/connection-provider-ebics-download-dialog/connection-provider-ebics-download-form';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitApiService extends ApiCommonService<BusinessUnit> {
  readonly resourceUrl: string = 'api/v1/expert/business_units';

  fromJson(json: BusinessUnitJson): BusinessUnit {
    return BusinessUnit.fromJson(json);
  }

  toJson(model: BusinessUnit): Partial<BusinessUnitJson> {
    return BusinessUnit.toJson(model);
  }

  @ApiAlertError()
  getBusinessUnit(businessUnitId: number): Observable<BusinessUnit> {
    return super.getById(businessUnitId);
  }

  @ApiAlertError()
  updateBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    return super.update(businessUnit.id, businessUnit);
  }

  @ApiAlertError()
  getBusinessUsers(businessUnitId: number, roleId: number): Observable<BusinessUser[]> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_users?business_users_role_id=${roleId}`;
    return this.http
      .get(url)
      .pipe(
        map((businessUsersJson: BusinessUserJson[]) =>
          businessUsersJson.map((businessUserJson: BusinessUserJson) => BusinessUser.fromJson(businessUserJson))
        )
      );
  }

  @ApiAlertError()
  updateBusinessUser(businessUnitId: number, businessUser: BusinessUser): Observable<BusinessUser> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_users/${businessUser.id}`;
    const body = BusinessUser.toJson(businessUser);
    return this.http
      .patch(url, body)
      .pipe(map((businessUserJson: BusinessUserJson) => BusinessUser.fromJson(businessUserJson)));
  }

  @ApiAlertError()
  deleteBusinessUser(businessUnitId: number, businessUserId: number): Observable<unknown> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_users/${businessUserId}`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  getLabelsPlans(businessUnitId: number): Observable<LabelsPlan[]> {
    const url = `${this.resourceUrl}/${businessUnitId}/labels_plans`;

    return this.http
      .get(url)
      .pipe(
        map((LabelsPlansJson: LabelsPlanJson[]) =>
          LabelsPlansJson.map((labelsPlanJson: LabelsPlanJson) => LabelsPlan.fromJson(labelsPlanJson))
        )
      );
  }

  @ApiAlertError([403])
  uploadCSVLabelsPlan(businessUnitId: number, file: File, name: string, header: boolean): Observable<void> {
    const url = `${this.resourceUrl}/${businessUnitId}/labels_plans`;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('header', String(header));
    formData.append('file', file);
    return this.http.post<void>(url, formData);
  }

  @ApiAlertError()
  downloadLabelsPlan(businessUnitId: number, labelsPlanId: number): Observable<FileSaverData> {
    const url = `${this.resourceUrl}/${businessUnitId}/labels_plans/${labelsPlanId}/file`;
    const blobObservable = this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    });
    return HttpHelper.getFileSaverData(blobObservable);
  }

  @ApiAlertError()
  deleteLabelsPlan(businessUnitId: number, labelsPlanId: number): Observable<unknown> {
    const url = `${this.resourceUrl}/${businessUnitId}/labels_plans/${labelsPlanId}`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  getConnectionProviders(businessUnitId: number): Observable<BusinessUnitConnectionProvider[]> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_unit_ebics_connection_providers`;
    return this.http
      .get(url)
      .pipe(
        map((businessUnitConnectionProvidersJson: BusinessUnitConnectionProviderJson[]) =>
          businessUnitConnectionProvidersJson.map(
            (businessUnitConnectionProviderJson: BusinessUnitConnectionProviderJson) =>
              BusinessUnitConnectionProvider.fromJson(businessUnitConnectionProviderJson)
          )
        )
      );
  }

  @ApiAlertError()
  updateConnectionProvider(
    businessUnitId: number,
    businessUnitConnectionProvider: BusinessUnitConnectionProvider
  ): Observable<BusinessUnitConnectionProvider> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_unit_ebics_connection_providers/${businessUnitConnectionProvider.id}`;
    const body = BusinessUnitConnectionProvider.toJson(businessUnitConnectionProvider);
    return this.http
      .patch(url, body)
      .pipe(map((businessUserJson: BusinessUserJson) => BusinessUser.fromJson(businessUserJson)));
  }

  @ApiAlertError()
  postConnectionProviderCredential(
    businessUnitId: number,
    businessUnitConnectionProviderId: number,
    connectionProviderCredentials: ConnectionProviderCredential
  ): Observable<ConnectionProviderCredential> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_unit_ebics_connection_providers/${businessUnitConnectionProviderId}/connection`;
    const body = ConnectionProviderCredential.toJson(connectionProviderCredentials);
    return this.http
      .post(url, body)
      .pipe(map((connection: ConnectionProviderCredential) => ConnectionProviderCredential.fromJson(connection)));
  }

  @ApiAlertError()
  deleteConnectionProviderCredential(
    businessUnitId: number,
    businessUnitConnectionProviderId: number,
    connectionProviderCredentialId: number
  ): Observable<unknown> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_unit_ebics_connection_providers/${businessUnitConnectionProviderId}/connection/${connectionProviderCredentialId}`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  downloadConnectionProviderEbicsPush(
    businessUnitId: number,
    businessUnitConnectionProviderId: number,
    connectionProviderEbicsDownloadForm: ConnectionProviderEbicsDownloadForm
  ): Observable<BusinessUnitConnectionProvider> {
    const url = `${this.resourceUrl}/${businessUnitId}/business_unit_ebics_connection_providers/${businessUnitConnectionProviderId}/ebics_push/recovery`;
    const body = connectionProviderEbicsDownloadForm.toConnectionProviderEbicsDownload();
    return this.http.post(url, body);
  }

  @ApiAlertError()
  uploadLogo(businessUnitId: number, file: File): Observable<Logo> {
    const url = `${this.resourceUrl}/${businessUnitId}/logos`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(map((logoJson: LogoJson) => Logo.fromJson(logoJson)));
  }

  @ApiAlertError()
  uploadCoverPageBackgroundImage(
    businessUnitId: number,
    file: File,
    imageName: string
  ): Observable<CoverPageBackgroundImage> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page_background_images`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', imageName);
    return this.http
      .post<CoverPageBackgroundImageJson>(url, formData)
      .pipe(
        map((coverPageBackgroundImageJson: CoverPageBackgroundImageJson) =>
          CoverPageBackgroundImage.fromJson(coverPageBackgroundImageJson)
        )
      );
  }

  @ApiAlertError()
  deleteThenUploadCoverPageBackgroundImage(
    businessUnitId: number,
    coverBackgroundImage: CoverPageBackgroundImageSerialized
  ): Observable<CoverPageBackgroundImage> {
    return this.deleteCoverPageBackgroundImage(businessUnitId, coverBackgroundImage.id).pipe(
      switchMap(() =>
        this.uploadCoverPageBackgroundImage(businessUnitId, coverBackgroundImage.image, coverBackgroundImage.imageName)
      )
    );
  }

  @ApiAlertError()
  getCoverPageBackgroundImages(businessUnitId: number): Observable<CoverPageBackgroundImage[]> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page_background_images`;
    return this.http
      .get<CoverPageBackgroundImageJson[]>(url)
      .pipe(
        map((coverPageBackgroundImagesJson: CoverPageBackgroundImageJson[]) =>
          coverPageBackgroundImagesJson.map((coverPageBackgroundImageJson: CoverPageBackgroundImageJson) =>
            CoverPageBackgroundImage.fromJson(coverPageBackgroundImageJson)
          )
        )
      );
  }

  @ApiAlertError()
  deleteCoverPageBackgroundImage(businessUnitId: number, coverBackgroundImageId: number): Observable<void> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page_background_images/${coverBackgroundImageId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  getLogoPreview(businessUnitId: number, logoId: number): Observable<Blob> {
    const url = `${this.resourceUrl}/${businessUnitId}/logos/${logoId}/preview`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError([403])
  deleteLogo(businessUnitId: number, logoId: number): Observable<void> {
    const url = `${this.resourceUrl}/${businessUnitId}/logos/${logoId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  getCoverPage(businessUnitId: number): Observable<CoverPage> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page`;
    return this.http.get(url).pipe(map((covePageJson: CoverPageJson) => CoverPage.fromJson(covePageJson)));
  }

  @ApiAlertError()
  getCoverPagePreview(businessUnitId: number): Observable<Blob> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page/preview`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError([403])
  saveCoverPage(businessUnitId: number, coverPage: CoverPage): Observable<CoverPage> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page`;
    const body = CoverPage.toJson(coverPage);
    return this.http.post(url, body).pipe(map((coverPageJson: CoverPageJson) => CoverPage.fromJson(coverPageJson)));
  }

  @ApiAlertError([403])
  updateCoverPage(businessUnitId: number, coverPage: CoverPage): Observable<CoverPage> {
    const url = `${this.resourceUrl}/${businessUnitId}/cover_page/${coverPage.id}`;
    const body = CoverPage.toJson(coverPage);
    return this.http.patch(url, body).pipe(map((coverPageJson: CoverPageJson) => CoverPage.fromJson(coverPageJson)));
  }

  @ApiAlertError([403])
  getAnnualBooklet(businessUnitId: number): Observable<AnnualBooklet> {
    const url = `${this.resourceUrl}/${businessUnitId}/annual_booklet`;
    return this.http
      .get(url)
      .pipe(map((annualBookletJson: AnnualBookletJson) => AnnualBooklet.fromJson(annualBookletJson)));
  }

  @ApiAlertError([403])
  saveAnnualBooklet(businessUnitId: number, annualBooklet: AnnualBooklet): Observable<AnnualBooklet> {
    const url = `${this.resourceUrl}/${businessUnitId}/annual_booklet`;
    const body = AnnualBooklet.toJson(annualBooklet);
    return this.http
      .post(url, body)
      .pipe(map((annualBookletJson: AnnualBookletJson) => AnnualBooklet.fromJson(annualBookletJson)));
  }

  @ApiAlertError([403])
  updateAnnualBooklet(businessUnitId: number, annualBooklet: AnnualBooklet): Observable<AnnualBooklet> {
    const url = `${this.resourceUrl}/${businessUnitId}/annual_booklet/${annualBooklet.id}`;
    const body = AnnualBooklet.toJson(annualBooklet);
    return this.http
      .patch(url, body)
      .pipe(map((annualBookletJson: AnnualBooklet) => AnnualBooklet.fromJson(annualBookletJson)));
  }

  @ApiAlertError()
  getContributorJobs(businessUnitId: number): Observable<ContributorJob[]> {
    const url = `${this.resourceUrl}/${businessUnitId}/contributor_jobs`;
    return this.http
      .get(url)
      .pipe(
        map((contributorJobsJson: ContributorJobJson[]) =>
          contributorJobsJson.map((contributorJobJson: ContributorJobJson) =>
            ContributorJob.fromJson(contributorJobJson)
          )
        )
      );
  }

  @ApiAlertError()
  getCompanies(
    partnerId: number,
    range: PaginationRange,
    searchTerms?: string,
    sort?: Sort,
    filters?: AdminCompaniesFilters
  ): Observable<PaginationData<AdminCompany>> {
    const url = `${this.resourceUrl}/${partnerId}/companies`;

    let params: HttpParams = HttpHelper.setQParam(searchTerms);

    if (sort) {
      params = HttpHelper.setSortParam(sort, params);
    }

    if (filters) {
      const isWithoutContributors = filters?.contributors?.length === 1 && filters.contributors[0].id === null;

      params = HttpHelper.setParams(
        {
          [CompaniesApiFiltersEnum.taxRegimeCode]: filters.taxRegimeCode?.length
            ? filters.taxRegimeCode.join('|')
            : undefined,
          [CompaniesApiFiltersEnum.withoutContributors]: isWithoutContributors ? true : undefined,
          [CompaniesApiFiltersEnum.contributors]: isWithoutContributors
            ? undefined
            : filters.contributors.length
            ? filters.contributors.map(contributor => contributor.id).join('|')
            : undefined,
          [CompaniesApiFiltersEnum.contributor_jobs]: filters.contributorJobId,
          [CompaniesApiFiltersEnum.businessUnits]: filters.businessUnits.length
            ? filters.businessUnits.map(businessUnit => businessUnit.id).join(',')
            : undefined
        },
        params
      );
    }

    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    const options = { params, headers };

    return this.http.get(url, { ...options, observe: 'response' }).pipe(
      filter((response: HttpResponse<AdminCompanyJson[]>) => response.status !== 204),
      HttpHelper.mapToPaginationData(range, json => AdminCompany.fromJson(json))
    );
  }

  @ApiAlertError()
  inviteMultipleContributors(businessUnitId: number, contributors: BusinessUser[]): Observable<void> {
    const url = `${this.resourceUrl}/${businessUnitId}/invitations`;
    const body: { contributors: JsonId[] } = { contributors: contributors.map(c => HttpHelper.toJsonId(c)) };
    return this.http.post<void>(url, body);
  }
}
