import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { iif, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { MarkFormAsTouchedIfInvalid } from 'tiime-expert-utils';
import { DialogBase, TiimeSnackbarService } from 'tiime-material';

import { PartnerApiService } from '@api-services/partner-api.service';
import { LabelCategoryPlanForm } from '@forms/label-category-plan-form';
import { LabelCategoryPlan } from '@models/label-category-plan';

import { CreateOrUpdateLabelsCategoryPlanDialogData } from './create-or-update-labels-category-plan-dialog-data';

@Component({
  selector: 'app-create-or-update-labels-category-plan-dialog',
  templateUrl: './create-or-update-labels-category-plan-dialog.component.html',
  styleUrls: ['./create-or-update-labels-category-plan-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrUpdateLabelsCategoryPlanDialogComponent extends DialogBase<CreateOrUpdateLabelsCategoryPlanDialogComponent> {
  labelsCategoryPlanForm = new LabelCategoryPlanForm();
  mode: 'Create' | 'Update';

  private partnerId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CreateOrUpdateLabelsCategoryPlanDialogData,
    dialogRef: MatDialogRef<CreateOrUpdateLabelsCategoryPlanDialogComponent>,
    private partnerApiService: PartnerApiService,
    private cdr: ChangeDetectorRef,
    private snackbarService: TiimeSnackbarService
  ) {
    super(dialogRef);
    this.partnerId = data.partnerId;

    if (data.labelCategoryPlan) {
      this.mode = 'Update';
      this.labelsCategoryPlanForm.fromLabelCategoryPlan(data.labelCategoryPlan);
    } else {
      this.mode = 'Create';
    }
  }

  @MarkFormAsTouchedIfInvalid('labelsCategoryPlanForm')
  submitForm(): void {
    this.isLoading = true;

    iif(
      () => !!this.labelsCategoryPlanForm.controls.id.value,
      this.updateLabelCategoryPlan(),
      this.createLabelCategoryPlan()
    )
      .pipe(
        tap((labelCategoryPlan: LabelCategoryPlan) => this.dialogRef.close(labelCategoryPlan)),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private createLabelCategoryPlan(): Observable<LabelCategoryPlan> {
    return this.partnerApiService
      .createLabelCategoryPlan(this.partnerId, this.labelsCategoryPlanForm.controls.label.value)
      .pipe(tap(() => this.snackbarService.openSuccess('Le plan de catégorie a bien été ajouté')));
  }

  private updateLabelCategoryPlan(): Observable<LabelCategoryPlan> {
    return this.partnerApiService
      .updateLabelCategoryPlan(this.partnerId, this.labelsCategoryPlanForm.toLabelCategoryPlan())
      .pipe(tap(() => this.snackbarService.openSuccess('Le plan de catégorie a bien été modifié')));
  }
}
