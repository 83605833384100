export enum CompanyRoute {
  empty = '',
  accounting = 'accounting',
  balanceSheets = 'balance-sheets',
  declarations = 'declarations',
  parameters = 'parameters',
  notInitialized = 'not-initialized',
  workbook = 'workbook',
  preAccounting = 'pre-accounting',
  documentsAndEditions = 'documents-and-editions',
  legal = 'legal'
}
