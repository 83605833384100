<tiime-form [tiimeFormExpanded]="tiimeFormExpanded">
  <div tiimeFormTitle>Détention et capital</div>
  <div class="ownership-form" [formGroup]="ownershipForm">
    <tiime-input-container
      contentId="capitalContribution"
      label="Capital souscrit"
      [errorMessage]="ownershipForm.capitalContributionErrorMessage?.message"
    >
      <input
        id="capitalContribution"
        type="text"
        placeholder="Capital souscrit"
        appDigitOnly
        [decimal]="true"
        [roundDecimal]="false"
        [formControl]="ownershipForm.capitalContribution"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container contentId="entryDate" label="Date d'entrée">
      <input
        id="entryDate"
        placeholder="Date d'entrée"
        type="text"
        [matDatepicker]="entryDatePicker"
        [formControl]="ownershipForm.entryDate"
        autocomplete="off"
      />
      <mat-icon
        color="primary"
        class="calendar-icon"
        fontIcon="icon-calendar"
        tiimeSuffix
        (click)="entryDatePicker.open()"
      ></mat-icon>
      <mat-datepicker
        panelClass="tiime-material-datepicker"
        [calendarHeaderComponent]="tiimeDatepickerHeader"
        #entryDatePicker
      ></mat-datepicker>
    </tiime-input-container>
    <tiime-input-container
      contentId="exitDate"
      label="Date de sortie"
      [errorMessage]="ownershipForm.exitDateErrorMessage?.message"
    >
      <input
        id="exitDate"
        placeholder="Date de sortie"
        type="text"
        [formControl]="ownershipForm.exitDate"
        [matDatepicker]="exitDatePicker"
        [min]="ownershipForm.entryDate.value"
        autocomplete="off"
      />
      <mat-icon
        color="primary"
        class="calendar-icon"
        fontIcon="icon-calendar"
        tiimeSuffix
        (click)="exitDatePicker.open()"
      ></mat-icon>
      <mat-datepicker
        panelClass="tiime-material-datepicker"
        [calendarHeaderComponent]="tiimeDatepickerHeader"
        #exitDatePicker
      ></mat-datepicker>
    </tiime-input-container>
    <tiime-input-container
      contentId="sharesNumber"
      label="Nb de parts pleine propriété"
      [errorMessage]="ownershipForm.sharesNumberErrorMessage?.message"
    >
      <input
        id="sharesNumber"
        type="text"
        placeholder="Nb pleine propriété"
        appDigitOnly
        [decimalSeparator]="null"
        [formControl]="ownershipForm.sharesNumber"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container
      contentId="sharesUsufructNumber"
      label="Nb de parts en usufruit"
      [errorMessage]="ownershipForm.sharesUsufructNumberErrorMessage?.message"
    >
      <input
        id="sharesUsufructNumber"
        type="text"
        placeholder="Nb de parts en usufruit"
        appDigitOnly
        [decimalSeparator]="null"
        [formControl]="ownershipForm.sharesUsufructNumber"
        autocomplete="off"
      />
    </tiime-input-container>
    <tiime-input-container
      contentId="sharesBareOwnership"
      label="Nb de parts en nue propriété"
      [errorMessage]="ownershipForm.sharesBareOwnershipNumberErrorMessage?.message"
    >
      <input
        id="sharesBareOwnership"
        type="text"
        placeholder="Nb de parts en nue propriété"
        appDigitOnly
        [decimalSeparator]="null"
        [formControl]="ownershipForm.sharesBareOwnershipNumber"
        autocomplete="off"
      />
    </tiime-input-container>
  </div>
</tiime-form>
