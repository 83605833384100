import { ProspectAcquisitionChannel } from '@enums/prospect-acquisition-channel.enum';
import { ProspectSource } from '@enums/prospect-source.enum';
import { DateHelper } from '@helpers/date.helper';
import { HttpHelper } from '@helpers/http.helper';
import { BusinessUnit, BusinessUnitJson } from '@models/business-unit';
import { BusinessUser, BusinessUserJson } from '@models/business-user';
import { Company } from '@models/company';
import { Contract } from '@models/contract';
import { Contributor, ContributorJson } from '@models/contributor';
import { UserBase } from '@models/user-base';

export interface ProspectJson {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  civility: string;
  mailing_street: string;
  mailing_city: string;
  mailing_postal_code: string;
  sales_representative_user: Partial<ContributorJson>;
  business_unit: Partial<BusinessUnitJson>;
  internal_comment: string;
  appointment_date: string;
  company_legal_form: string;
  company_name: string;
  created_at: string;
  contracts: Partial<Contract>[];
  company: Partial<Company>;
  source: ProspectSource;
  created_by?: Partial<BusinessUserJson>;
  acquisition_channel: ProspectAcquisitionChannel;
}

export class Prospect extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    public civility?: string,
    public mailingStreet?: string,
    public mailingCity?: string,
    public mailingPostalCode?: string,
    public salesRepresentativeUser?: Contributor,
    public businessUnit?: BusinessUnit,
    public internalComment?: string,
    public appointmentDate?: string,
    public companyLegalForm?: string,
    public companyName?: string,
    public createdAt?: Date,
    public contracts?: Contract[],
    public company?: Company,
    public source?: ProspectSource,
    public createdBy?: BusinessUser,
    public acquisitionChannel?: ProspectAcquisitionChannel
  ) {
    super(id, firstName, lastName, email, phone);
  }

  static fromJson(json: Partial<ProspectJson>): Prospect {
    return new Prospect(
      json.id,
      json.first_name,
      json.last_name,
      json.email,
      json.phone,
      json.civility,
      json.mailing_street,
      json.mailing_city,
      json.mailing_postal_code,
      json.sales_representative_user ? Contributor.fromJson(json.sales_representative_user) : null,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.internal_comment,
      json.appointment_date,
      json.company_legal_form,
      json.company_name,
      json.created_at ? DateHelper.fromUtc(json.created_at) : null,
      json.contracts ? json.contracts.map((contract: Contract) => Contract.fromJson(contract)) : [],
      json.company ? Company.fromJson(json.company) : null,
      json.source,
      json.created_by ? BusinessUser.fromJson(json.created_by) : null,
      json.acquisition_channel
    );
  }

  static toJson(prospect: Prospect): Partial<ProspectJson> {
    return {
      id: prospect.id ? HttpHelper.toJsonProperty(prospect.id) : undefined,
      first_name: HttpHelper.toJsonProperty(prospect.firstName),
      last_name: HttpHelper.toJsonProperty(prospect.lastName),
      email: HttpHelper.toJsonProperty(prospect.email),
      phone: HttpHelper.toJsonProperty(prospect.phone),
      civility: HttpHelper.toJsonProperty(prospect.civility),
      mailing_street: HttpHelper.toJsonProperty(prospect.mailingStreet),
      mailing_city: HttpHelper.toJsonProperty(prospect.mailingCity),
      mailing_postal_code: HttpHelper.toJsonProperty(prospect.mailingPostalCode),
      sales_representative_user: prospect.salesRepresentativeUser
        ? HttpHelper.toJsonId(prospect.salesRepresentativeUser)
        : null,
      business_unit: prospect.businessUnit ? HttpHelper.toJsonId(prospect.businessUnit) : null,
      internal_comment: HttpHelper.toJsonProperty(prospect.internalComment),
      appointment_date: prospect.appointmentDate
        ? DateHelper.format(prospect.appointmentDate, 'YYYY-MM-DD HH:mm:ss')
        : null,
      company_legal_form: HttpHelper.toJsonProperty(prospect.companyLegalForm),
      company_name: HttpHelper.toJsonProperty(prospect.companyName),
      acquisition_channel: HttpHelper.toJsonProperty(prospect.acquisitionChannel)
    };
  }
}
