import { createSelector } from '@ngrx/store';

import { NotificationsState } from './notifications-reducer';

export const notificationsSelector = (state: { notifications?: NotificationsState }): NotificationsState =>
  state?.notifications ?? null;
export const notificationsCounterSelector = createSelector(
  notificationsSelector,
  (notificationsState: NotificationsState) => notificationsState?.counter ?? null
);
export const notificationsDataSelector = createSelector(
  notificationsSelector,
  (notificationsState: NotificationsState) => notificationsState?.notifications ?? []
);
export const notificationsLoadingSelector = createSelector(
  notificationsSelector,
  (notificationsState: NotificationsState) => notificationsState?.loadingNotifications ?? null
);
export const notificationsContainerStateSelector = createSelector(
  notificationsSelector,
  (notificationsState: NotificationsState) => notificationsState?.containerState ?? null
);
export const notificationsPaginationSelector = createSelector(
  notificationsSelector,
  (notificationsState: NotificationsState) => notificationsState?.pagination ?? null
);
