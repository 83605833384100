import { Directive } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[tiimeProperties]',
  exportAs: 'tiime-properties',
  host: {
    class: 'tiime-properties'
  }
})
export class TiimePropertiesDirective {}

@Directive({
  standalone: true,
  selector: '[tiimeProperty]',
  exportAs: 'tiime-property',
  host: {
    class: 'tiime-property'
  }
})
export class TiimePropertyDirective {}

@Directive({
  standalone: true,
  selector: '[tiimeLargeProperty]',
  exportAs: 'tiime-large-property',
  host: {
    class: 'tiime-large-property'
  }
})
export class TiimeLargePropertyDirective {}

@Directive({
  standalone: true,
  selector: '[tiimeIconProperty]',
  exportAs: 'tiime-icon-property',
  host: {
    class: 'tiime-icon-property'
  }
})
export class TiimeIconPropertyDirective {}
