import { JqpaOptionEnum } from '../../creation-core/enums/jqpa-option.enum';
import { ExerciceFormEnum } from '../enums/exercice-form.enum';
import { ValidationOrganizationEnum } from '../enums/validation-organization.enum';

export interface ActivityJson {
  id?: number;
  main?: boolean;
  jqpa_option?: JqpaOptionEnum;
  name?: string;
  category_code_level_1?: string;
  category_code_level_2?: string;
  category_code_level_3?: string;
  category_code_level_4?: string;
  help_comment?: string;
  validation_organization?: ValidationOrganizationEnum;
  exercice_form?: ExerciceFormEnum;
}

export class Activity {
  constructor(
    public id?: number,
    public main?: boolean,
    public jqpaOption?: JqpaOptionEnum,
    public name?: string,
    public categoryCodeLevel1?: string,
    public categoryCodeLevel2?: string,
    public categoryCodeLevel3?: string,
    public categoryCodeLevel4?: string,
    public helpComment?: string,
    public validationOrganization?: ValidationOrganizationEnum,
    public exerciceForm?: ExerciceFormEnum
  ) {}

  static fromJson(json: Partial<ActivityJson>): Activity {
    return new Activity(
      json.id,
      json.main,
      json.jqpa_option,
      json.name,
      json.category_code_level_1,
      json.category_code_level_2,
      json.category_code_level_3,
      json.category_code_level_4,
      json.help_comment,
      json.validation_organization,
      json.exercice_form
    );
  }
  static toJson(activityOptions: Activity): ActivityJson {
    return {
      id: activityOptions.id,
      main: activityOptions.main,
      jqpa_option: activityOptions.jqpaOption,
      name: activityOptions.name,
      category_code_level_1: activityOptions.categoryCodeLevel1,
      category_code_level_2: activityOptions.categoryCodeLevel2,
      category_code_level_3: activityOptions.categoryCodeLevel3,
      category_code_level_4: activityOptions.categoryCodeLevel4,
      help_comment: activityOptions.helpComment,
      validation_organization: activityOptions.validationOrganization,
      exercice_form: activityOptions.exerciceForm
    };
  }
}
