export interface TiimeBusinessInvitationJson {
  is_company_creation: boolean;
  business_appointment_done: boolean;
}

export class TiimeBusinessInvitation {
  constructor(
    public isCompanyCreation: boolean,
    public businessAppointmentDone: boolean
  ) {}

  static fromJson(json: Partial<TiimeBusinessInvitationJson>): TiimeBusinessInvitation {
    return new TiimeBusinessInvitation(json.is_company_creation, json.business_appointment_done);
  }

  static toJson(model: Partial<TiimeBusinessInvitation>): TiimeBusinessInvitationJson {
    return {
      is_company_creation: model.isCompanyCreation,
      business_appointment_done: model.businessAppointmentDone
    };
  }
}
