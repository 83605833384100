export interface BankAccountTypeJson {
  id: number;
  code: string;
  description: string;
}

export class BankAccountType {
  constructor(
    public id?: number,
    public code?: string,
    public description?: string
  ) {}

  static fromJson(json: Partial<BankAccountTypeJson>): BankAccountType {
    return new BankAccountType(json.id, json.code, json.description);
  }
}
