import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeInput]',
  standalone: true
})
export class CapitalizeInputDirective {
  private pattern = new RegExp(/^[a-zA-Z]+$/);

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement): void {
    const caretPos = input.selectionStart;
    const splittedString = input.value.split(/(?=[ -])|(?<=[ -])/g);

    splittedString.forEach((word, index) => {
      if (this.pattern.test(word)) {
        splittedString[index] = word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    this.control.control.setValue(splittedString.join(''));
    input.setSelectionRange(caretPos, caretPos);
  }
}
