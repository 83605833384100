import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { Company } from '@models/company';

import { ChooseCreationTypeDialogComponent } from './choose-company-creation-type-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ChooseCreationTypeDialogService extends createDialogServiceBase<Company, void>(
  ChooseCreationTypeDialogComponent
) {}
