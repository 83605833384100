import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DatepickerHeaderComponent } from './datepicker-header.component';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';

@NgModule({
  declarations: [DatepickerHeaderComponent],
  imports: [CommonModule, MatDatepickerModule, TiimeIconModule, TiimeTooltipModule],
  exports: [DatepickerHeaderComponent, MatDatepickerModule]
})
export class TiimeDatepickerModule {}
