import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { PaginationData, PaginationRange } from 'tiime-material';

import { ApiCommonService } from '@api-services/api-common.service';
import { HttpHelper } from '@helpers/http.helper';
import { Notification, NotificationJson } from '@models/notification';
import { ApiAlertError } from '@modules/core/decorators/api-alert-error';

/**
 * Notification Api Service.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationApiService extends ApiCommonService<Notification> {
  resourceUrl = 'api/v1/expert/users/me/notifications';

  fromJson(notificationJson: NotificationJson): Notification {
    return Notification.fromJson(notificationJson);
  }

  toJson(_model: unknown): unknown {
    throw new Error('Unusable');
  }

  addDevice(token: string): Observable<void> {
    const url = 'api/v1/devices';
    const body = {
      token
    };

    return this.http.put<void>(url, body);
  }

  deleteDevice(token: string): Observable<void> {
    const url = `api/v1/device_apps/token/${token}`;

    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  countNotifications(): Observable<number> {
    const url = `${this.resourceUrl}/count`;

    return this.http.get(url).pipe(map((json: any) => json?.not_read));
  }

  @ApiAlertError()
  getNotifications(range: PaginationRange, read: boolean | null): Observable<PaginationData<Notification>> {
    let params = new HttpParams();
    if (read !== null) {
      params = HttpHelper.setParam('read', String(read), params);
    }

    return super.getPaginated(range, params);
  }

  @ApiAlertError()
  markNotificationAsRead(notificationUuid: string): Observable<Notification> {
    const url = `${this.resourceUrl}/${notificationUuid}`;
    const body = {
      read: true
    };

    return this.http
      .patch(url, body)
      .pipe(map((notificationJson: NotificationJson) => this.fromJson(notificationJson)));
  }

  @ApiAlertError()
  markAllNotificationsAsRead(): Observable<void> {
    const url = `${this.resourceUrl}/mark_all_as_read`;

    return this.http.post<void>(url, {});
  }
}
