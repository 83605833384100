import { MathHelper } from '../math/math.helper';
import { RegexHelper } from '../regex/regex.helper';

const alphabeticRankPlusNine: Record<string, number> = {
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
  U: 30,
  V: 31,
  W: 32,
  X: 33,
  Y: 34,
  Z: 35
};

export class IbanHelper {
  static isValid(iban: string): boolean | null {
    // Step 0 Enlever les espaces
    let value = iban.replace(/\s/g, '').toUpperCase();

    if (RegexHelper.ibanRegex.test(value)) {
      // Step 1 Récupérer la clé
      const expectedKey = Number(value.substr(2, 2));

      // Step 2: Supprimer les 4 premiers caractères et les replacer à la fin
      value = value.substr(4) + value.substr(0, 4);

      // Step 3: Mettre la clé à 00
      value = value.substring(0, value.length - 2) + '00';

      // Step 4: Remplacer les lettres par des chiffres au moyen d'une table de conversion (A=10, B=11, C=12 etc.)
      value = value.replace(/[A-Z]/g, char => `${alphabeticRankPlusNine[char]}`);

      // Step 5: Calculer alors la valeur de ce grand nombre modulo 97 et soustraire
      // le résultat à 98 pour obtenir la clé de controle.
      const obtainedKey = 98 - MathHelper.modulo(value, 97);

      return obtainedKey === expectedKey;
    } else {
      return null;
    }
  }
}

/**
 * @deprecated Use `IbanHelper` instead.
 */
export class IbanUtils extends IbanHelper {}
