import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tiime-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubheaderComponent {
  @Input()
  loading: boolean;
}
