<ng-container *ngIf="mode === 'icon'; else textMode">
  <ng-container [ngSwitch]="autoSaveStatus">
    <mat-progress-spinner
      data-cy="autosave-status-begin"
      diameter="20"
      color="primary"
      mode="indeterminate"
      *ngSwitchCase="AutoSaveStatus.BeginSave"
    ></mat-progress-spinner>
    <img
      data-cy="autosave-status-success"
      class="success"
      src="dist/tiime-material/assets/ic_check.svg"
      alt="Icône sauvegarde réussie"
      *ngSwitchCase="AutoSaveStatus.SaveSuccess"
    />
    <img
      data-cy="autosave-status-error"
      class="error"
      src="dist/tiime-material/assets/ic_warn.svg"
      alt="Icône sauvegarde échouée"
      tiimeTooltip
      [tooltipDisabled]="!errorMessage"
      [tooltipContent]="errorTooltip"
      *ngSwitchCase="AutoSaveStatus.SaveError"
    />
    <ng-template #errorTooltip>
      {{ errorMessage }}
    </ng-template>
  </ng-container>
</ng-container>
<ng-template #textMode>
  <ng-container [ngSwitch]="autoSaveStatus">
    <span data-cy="autosave-status-text-begin" class="begin" *ngSwitchCase="AutoSaveStatus.BeginSave">
      Enregistrement en cours...
    </span>
    <span data-cy="autosave-status-text-success" class="success" *ngSwitchCase="AutoSaveStatus.SaveSuccess">
      Enregistré !
    </span>
    <span data-cy="autosave-status-text-error" class="error" *ngSwitchCase="AutoSaveStatus.SaveError">
      Erreur lors de l'enregistrement
    </span>
  </ng-container>
</ng-template>
