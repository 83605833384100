import { PeriodicityEnum } from '@enums/periodicity.enum';
import { HttpHelper } from '@helpers/http.helper';
import { ContractOffer, ContractOfferJson } from '@models/contract-offer';
import { TimeTrackingsTask, TimeTrackingsTaskJson } from '@models/time-trackings-task';

export interface PrestationJson {
  id: number;
  code: string;
  label: string;
  amount_excluded_tax: number;
  periodicity: PeriodicityEnum;
  gi_task: Partial<TimeTrackingsTaskJson>;
  contract_offer: Partial<ContractOfferJson>;
  discount_internal_label: string;
  discount_printed_label: string;
  discount_amount: number;
  discount_occurrence: number;
}

export class Prestation {
  constructor(
    public id?: number,
    public code?: string,
    public label?: string,
    public amountExcludedTax?: number,
    public periodicity?: PeriodicityEnum,
    public task?: TimeTrackingsTask,
    public contractOffer?: ContractOffer,
    public discountInternalLabel?: string,
    public discountPrintedLabel?: string,
    public discountAmount?: number,
    public discountOccurrence?: number
  ) {}

  static fromJson(json: Partial<PrestationJson>): Prestation {
    return new Prestation(
      json.id,
      json.code,
      json.label,
      json.amount_excluded_tax,
      json.periodicity,
      json.gi_task ? TimeTrackingsTask.fromJson(json.gi_task) : null,
      json.contract_offer ? ContractOffer.fromJson(json.contract_offer) : null,
      json.discount_internal_label,
      json.discount_printed_label,
      json.discount_amount,
      json.discount_occurrence
    );
  }

  static toJson(model: Prestation): PrestationJson {
    return {
      id: model.id ? HttpHelper.toJsonProperty(model.id) : undefined,
      code: HttpHelper.toJsonProperty(model.code),
      label: HttpHelper.toJsonProperty(model.label),
      amount_excluded_tax: HttpHelper.toJsonProperty(model.amountExcludedTax),
      periodicity: HttpHelper.toJsonProperty(model.periodicity),
      gi_task: model.task ? HttpHelper.toJsonId(model.task) : null,
      contract_offer: model.contractOffer ? ContractOffer.toJson(model.contractOffer) : null,
      discount_internal_label: HttpHelper.toJsonProperty(model.discountInternalLabel),
      discount_printed_label: HttpHelper.toJsonProperty(model.discountPrintedLabel),
      discount_amount: HttpHelper.toJsonProperty(model.discountAmount),
      discount_occurrence: HttpHelper.toJsonProperty(model.discountOccurrence)
    };
  }
}
