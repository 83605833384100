<div class="invitation-channel-warning" *ngIf="!(userPhone | isMobilePhoneNumber)">
  Vous devez d'abord ajouter un numéro de téléphone mobile à
  <br />
  votre contact pour l'inviter par SMS.
</div>
<div class="invitation-channel-heading">Envoyer l’invitation par</div>
<div class="invitation-channel-container">
  <div class="tiime-toggle-group">
    <div
      class="tiime-toggle"
      [class.selected]="value === UserInvitationChannelType.email"
      [class.disabled]="disabled"
      (click)="selectChannel(UserInvitationChannelType.email)"
    >
      <tiime-twocolors-icon class="tiime-toggle-icon" icon="ic_twocolors_mail" size="24"></tiime-twocolors-icon>
      Email
    </div>
    <div class="tiime-toggle-separator"></div>
    <div
      class="tiime-toggle"
      [class.selected]="value === UserInvitationChannelType.sms"
      [class.disabled]="disabled || !(userPhone | isMobilePhoneNumber)"
      (click)="selectChannel(UserInvitationChannelType.sms)"
    >
      <tiime-twocolors-icon class="tiime-toggle-icon" icon="ic_twocolors_smartphone" size="24"></tiime-twocolors-icon>
      SMS
    </div>
  </div>
</div>
