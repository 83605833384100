import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TiimeButtonModule } from 'tiime-material';

@Component({
  selector: 'app-refresh-button',
  standalone: true,
  imports: [CommonModule, TiimeButtonModule, MatIconModule],
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshButtonComponent {
  @Input() disabled = false;
  @Input() refreshText = 'Rafraichir la liste';
  @Input() raised = false;

  @Output() readonly refresh: EventEmitter<void> = new EventEmitter<void>();

  emitRefresh(): void {
    this.refresh.emit();
  }
}
