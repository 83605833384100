import { Component, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { provideComponentStore } from '@ngrx/component-store';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@modules/core/auth/auth.service';
import { CompanyThreadsStore } from '@modules/core/component-stores/company-threads.store';
import { openCloseCompanyThreads } from '@modules/shared/animations/open-close-company-threads';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [provideComponentStore(CompanyThreadsStore)],
  animations: [openCloseCompanyThreads]
})
export class AppComponent implements OnInit {
  readonly companyThreadsOpened$: Observable<boolean> = this.companyThreadsStore.opened$;
  readonly authIsLoading$: Observable<boolean> = this.authService.isLoading$;

  private subscription = new Subscription();

  constructor(
    private readonly companyThreadsStore: CompanyThreadsStore,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginRequired();
  }

  private loginRequired(): void {
    this.subscription.add(
      this.authService
        .loginRequired()
        .pipe(switchMap(() => this.authService.login()))
        .subscribe()
    );
  }
}
