import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { TaxRegime } from '@models/tax-regime';
import { FormFocusObserveDirective } from '@modules/shared/directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-tax-regime-select',
  templateUrl: './tax-regime-select.component.html',
  styleUrls: ['./tax-regime-select.component.scss'],
  imports: [
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxRegimeSelectComponent extends FilteredSelectBase<TaxRegime> {
  @Input() label = `Régime d'imposition`;
  @Input() condensedLabel = false;

  trackByCode(_index: number, taxRegime: TaxRegime): string {
    return taxRegime.code;
  }

  filterFunction(search: string): TaxRegime[] {
    return this.selectorData.filter((taxRegime: TaxRegime) =>
      StringUtils.normalizeToLowerCase(taxRegime.label).includes(StringUtils.normalizeToLowerCase(search))
    );
  }
}
