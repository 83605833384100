import { VatDeclarationContent, VatDeclarationContentJson } from './vat-declaration-content';

export interface VatDeclarationSectionJson {
  label: string;
  content: Partial<VatDeclarationContentJson>[];
  metadata: Record<string, unknown>;
}

export class VatDeclarationSection {
  constructor(
    public label?: string,
    public content?: VatDeclarationContent[],
    public metadata?: Record<string, unknown>
  ) {}

  static fromJson(json: Partial<VatDeclarationSectionJson>): VatDeclarationSection {
    return new VatDeclarationSection(
      json.label,
      json.content ? json.content.map(contentJson => VatDeclarationContent.fromJson(contentJson)) : [],
      json.metadata
    );
  }

  static toJson(vatDeclarationSection: VatDeclarationSection): Partial<VatDeclarationSectionJson> {
    return {
      label: vatDeclarationSection.label,
      content: vatDeclarationSection.content
        ? vatDeclarationSection.content.map(content => VatDeclarationContent.toJson(content))
        : []
    };
  }
}
