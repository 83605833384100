import { DocumentMetadataType } from '@enums/document-metadata-type.enum';
import { DocumentMetadataAmountValue, DocumentMetadataAmountValueJson } from '@models/document-metadata-amount-value';

export interface DocumentMetadataJson {
  key?: string;
  label?: string;
  value?: string | DocumentMetadataAmountValueJson | DocumentMetadataJson[];
  display_value?: string;
  type?: DocumentMetadataType;
}

export class DocumentMetadata {
  constructor(
    public key?: string,
    public label?: string,
    public value?: string | DocumentMetadataAmountValue | DocumentMetadata[],
    public displayValue?: string,
    public type?: DocumentMetadataType
  ) {}

  public static fromJson(json: DocumentMetadataJson): DocumentMetadata {
    let documentMetadataValue = null;

    if (json.value) {
      switch (json.type) {
        case DocumentMetadataType.amounts:
          documentMetadataValue = (json.value as DocumentMetadataJson[]).map(v => DocumentMetadata.fromJson(v));
          break;
        case DocumentMetadataType.amount:
        case DocumentMetadataType.amount_editable_currency:
          documentMetadataValue = DocumentMetadataAmountValue.fromJson(json.value as DocumentMetadataAmountValueJson);
          break;
        default:
          documentMetadataValue = json.value;
      }
    }

    return new DocumentMetadata(json.key, json.label, documentMetadataValue, json.display_value, json.type);
  }

  public static toJson(documentMetadata: DocumentMetadata): DocumentMetadataJson {
    let documentMetadataValue = null;

    if (documentMetadata.value) {
      switch (documentMetadata.type) {
        case DocumentMetadataType.amounts:
          documentMetadataValue = (documentMetadata.value as DocumentMetadata[]).map(v => DocumentMetadata.toJson(v));
          break;
        case DocumentMetadataType.amount:
        case DocumentMetadataType.amount_editable_currency:
          documentMetadataValue = DocumentMetadataAmountValue.toJson(
            documentMetadata.value as DocumentMetadataAmountValue
          );
          break;
        default:
          documentMetadataValue = documentMetadata.value;
      }
    }

    return {
      key: documentMetadata.key,
      label: documentMetadata.label,
      value: documentMetadataValue,
      type: documentMetadata.type
    };
  }
}
