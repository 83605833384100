import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { StringUtils } from 'tiime-expert-utils';
import { TiimeFormModule, TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { FilteredSelectBase } from '@bases/selector/filtered-select.base';
import { Department } from '@models/department';
import { FormFocusObserveDirective } from '@modules/shared/directives/form-focus-observe/form-focus-observe.directive';

@Component({
  standalone: true,
  selector: 'app-department-select',
  templateUrl: './department-select.component.html',
  styleUrls: ['./department-select.component.scss'],
  imports: [
    FormFocusObserveDirective,
    TiimeInputContainerModule,
    TiimeFormModule,
    TiimeSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepartmentSelectComponent extends FilteredSelectBase<Department> {
  @Input() label = 'Dépt. de naissance';

  trackByCode(_index: number, department: Department): string {
    return department.code;
  }

  filterFunction(search: string): Department[] {
    const lowerCaseSearch = StringUtils.normalizeToLowerCase(search);
    return this.selectorData.filter(
      department =>
        department.code.includes(lowerCaseSearch) ||
        StringUtils.normalizeToLowerCase(department.name).includes(lowerCaseSearch)
    );
  }
}
