import { GIFileType } from '@enums/gi-file-type.enum';
import { HttpHelper } from '@helpers/http.helper';
import { PieceFile, PieceFileJson } from '@models/piece-file';

export interface GIFileJson extends PieceFileJson {
  title: string;
  type: GIFileType;
}

export class GIFile extends PieceFile {
  constructor(
    id?: number,
    name?: string,
    mimeType?: string,
    createdAt?: string,
    public title?: string,
    public type?: GIFileType
  ) {
    super(id, name, mimeType, createdAt);
  }

  static fromJson(json: Partial<GIFileJson>): GIFile {
    return new GIFile(json.id, json.name, json.mime_type, json.created_at, json.title, json.type);
  }

  static toJson(file: GIFile): Partial<GIFileJson> {
    return {
      id: HttpHelper.toJsonProperty(file.id),
      title: HttpHelper.toJsonProperty(file.title),
      type: HttpHelper.toJsonProperty(file.type)
    };
  }
}
