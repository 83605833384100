import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tiime-checkbox-card',
  templateUrl: './checkbox-card.component.html',
  styleUrls: ['./checkbox-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxCardComponent,
      multi: true
    }
  ]
})
export class CheckboxCardComponent implements ControlValueAccessor {
  value: boolean = null;
  disabled: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: (value: boolean) => void = () => {};
  onTouched: () => void = () => {};

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(value: boolean | null): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  select(event: MouseEvent): void {
    this.value = !this.value;
    this.onChange(this.value);
    event.preventDefault();
  }
}
