import { ContributorJobType } from '@enums/contributor-job-type.enum';

export interface ContributorJobJson {
  id: number;
  type: ContributorJobType;
  custom_name: string;
  enabled: boolean;
}

export class ContributorJob {
  constructor(
    public id?: number,
    public type?: ContributorJobType,
    public customName?: string,
    public enabled?: boolean
  ) {}

  static fromJson(json: Partial<ContributorJobJson>): ContributorJob {
    return new ContributorJob(json.id, json.type, json.custom_name, json.enabled);
  }

  static toJson(job: ContributorJob): ContributorJobJson {
    return {
      id: job.id || undefined,
      type: job.type,
      custom_name: job.customName,
      enabled: job.enabled
    };
  }
}
