import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Inject, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { MarkFormAsTouchedIfInvalid, StringUtils } from 'tiime-expert-utils';
import { DialogBase, TableAnchorComponent, TiimeSnackbarService, TiimeStepperComponent } from 'tiime-material';

import { AccountingConfigApiService } from '@api-services/accounting-config-api.service';
import { BusinessUserApiService } from '@api-services/business-user-api.service';
import { CompanyApiService } from '@api-services/company-api.service';
import { ProspectApiService } from '@api-services/prospect-api.service';
import { UserApiService } from '@api-services/user-api.service';
import { UserInvitationApiService } from '@api-services/user-invitation-api.service';
import { TIIME_LABEL_PLAN_ID } from '@constants/tiime-label-plan.constant';
import { CreateCompanyForm } from '@forms/create-company-form';
import { BusinessUnit } from '@models/business-unit';
import { Company } from '@models/company';
import { LabelCategoryPlan } from '@models/label-category-plan';
import { Prospect } from '@models/prospect';
import { SearchUser } from '@models/search-user';
import { User } from '@models/user';
import { ConfirmDialogData } from '@shared-dialogs/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogService } from '@shared-dialogs/confirm-dialog/confirm-dialog.service';
import { ExistingUserDialogService } from '@shared-dialogs/existing-user-dialog/existing-user-dialog.service';
import { CompanyFormComponent } from '@shared-forms/company-form/company-form.component';
import { UserFormComponent } from '@shared-forms/user-form/user-form.component';

import { CreateCompanyDialogData } from './create-company-dialog-data';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.scss']
})
export class CreateCompanyDialogComponent extends DialogBase<CreateCompanyDialogComponent> implements AfterViewInit {
  @ViewChild(TiimeStepperComponent) tiimeStepperComponent: TiimeStepperComponent;
  @ViewChild(CompanyFormComponent) companyFormComponent: CompanyFormComponent;
  @ViewChild(UserFormComponent) userFormComponent: UserFormComponent;
  @ViewChild(TableAnchorComponent) tableAnchorComponent: TableAnchorComponent;

  createCompanyForm = new CreateCompanyForm();
  submitLoading = false;

  readonly labelCategoryPlans$ = this.businessUserApiService.getBusinessUserLabelCategoryPlans();
  readonly companyExpandedForms = ['company'];
  readonly userExpandedForms = ['user'];
  readonly existingCompanies: WritableSignal<SearchUser[]> = signal([]);

  private subscription = new Subscription();

  constructor(
    dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    private readonly userApiService: UserApiService,
    private readonly companyApiService: CompanyApiService,
    private readonly accountingConfigApiService: AccountingConfigApiService,
    private readonly userInvitationApiService: UserInvitationApiService,
    private readonly snackbarService: TiimeSnackbarService,
    private readonly existingUserDialogService: ExistingUserDialogService,
    private readonly businessUserApiService: BusinessUserApiService,
    private readonly prospectApiService: ProspectApiService,
    private readonly confirmDialogService: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: CreateCompanyDialogData
  ) {
    super(dialogRef);

    if (data.businessUnits?.length === 1) {
      setTimeout(() => this.createCompanyForm.companyStep.company.businessUnit.setValue(data.businessUnits[0]));
    } else {
      this.createCompanyForm.companyStep.company.legalStructureAndSignatory.disable();
    }

    if (data.prospect) {
      this.initUserFromProspect(data.prospect);
      this.initCompanyFromProspect(data.prospect, data.businessUnits);
    }
  }

  ngAfterViewInit(): void {
    this.createCompanyForm.invitationStep.invitation.patchValue({
      features: {
        invoiceApp: true,
        receiptApp: true
      },
      withFormation: true
    });
    this.userFormComponent.focusUserLastName();
    this.subscription.add(
      this.tiimeStepperComponent.selectionChange
        .pipe(
          tap(() => this.tableAnchorComponent.scrollTo()),
          tap((stepperSelectionEvent: StepperSelectionEvent) => {
            switch (stepperSelectionEvent.selectedIndex) {
              case 0:
                this.userFormComponent.focusUserLastName();
                break;
              case 1:
                this.companyFormComponent.focusCompanyName();
                break;
            }
          })
        )
        .subscribe()
    );
  }

  closeCreateCompanyDialog(): void {
    if (this.submitLoading) {
      return;
    }

    this.cancel();
  }

  @MarkFormAsTouchedIfInvalid('createCompanyForm')
  submitForm(): void {
    this.submitLoading = true;
    const businessUnitId = this.createCompanyForm.companyStep.company.businessUnit.value.id;
    const company = this.createCompanyForm.companyStep.company.toCompany();
    const user = this.createCompanyForm.userStep.user.toUser();
    const invitation = this.createCompanyForm.invitationStep.invitation.toUserInvitationConfiguration();
    this.createCompanyForm.disable();

    this.companyApiService
      .createCompany(businessUnitId, company)
      .pipe(
        switchMap((createdCompany: Company) => {
          const userObs: Observable<User> = user.id
            ? this.userApiService
                .createRelation(user.id, createdCompany.id)
                .pipe(switchMap(() => this.userApiService.updateUser(user, createdCompany.id)))
            : this.userApiService.createUser(user, createdCompany.id);

          return userObs.pipe(
            switchMap((createdUser: User) => {
              const prospectId = this.data?.prospect?.id;

              // On peut skip cette étape si on n'a pas besoin de lier le dossier à un prospect
              if (!prospectId) {
                return of(createdUser);
              }

              return this.linkProspectToCompany(prospectId, createdCompany.id, createdUser.id).pipe(
                map(() => createdUser)
              );
            }),
            switchMap((createdUser: User) => {
              // On peut skip cette étape si on n'a pas d'invitation
              if (invitation.features.length === 0 && !invitation.withFormation) {
                return of(null);
              }

              return this.userInvitationApiService.createUserInvitation(createdUser.id, invitation, createdCompany.id);
            }),
            switchMap(() =>
              this.createCompanyForm.parametersStep.fecFile.value
                ? this.companyApiService.uploadFEC(
                    this.createCompanyForm.parametersStep.fecFile.value,
                    createdCompany.id
                  )
                : of(true)
            ),
            switchMap(() =>
              this.createCompanyForm.parametersStep.labelPlan.value &&
              this.createCompanyForm.parametersStep.labelPlan.value !== TIIME_LABEL_PLAN_ID
                ? this.companyApiService.importLabelsPlans(
                    this.createCompanyForm.parametersStep.labelPlan.value,
                    createdCompany.id
                  )
                : of(true)
            ),
            switchMap(() =>
              !this.createCompanyForm.parametersStep.fecFile.value &&
              this.createCompanyForm.parametersStep.labelPlan.value &&
              this.createCompanyForm.parametersStep.labelPlan.value !== TIIME_LABEL_PLAN_ID
                ? this.companyApiService.validateCompany(createdCompany.id)
                : of(true)
            ),
            switchMap(() =>
              this.createCompanyForm.parametersStep.controls.categorySetupPlan.value
                ? this.accountingConfigApiService.getAccountingConfig(createdCompany.id).pipe(
                    switchMap(accountingConfig => {
                      accountingConfig.categorySetupPlan = new LabelCategoryPlan(
                        this.createCompanyForm.parametersStep.controls.categorySetupPlan.value
                      );
                      return this.accountingConfigApiService.updateAccountingConfig(
                        accountingConfig,
                        createdCompany.id
                      );
                    })
                  )
                : of(true)
            ),
            tap(() => this.dialogRef.close(createdCompany)),
            tap(() => this.snackbarService.openSuccess('Le dossier a bien été ajouté'))
          );
        }),
        finalize(() => {
          this.submitLoading = false;
          this.createCompanyForm.enable();
        })
      )
      .subscribe();
  }

  checkIfUserExist(): void {
    this.createCompanyForm.userStep.userDoesntExist.setValue(false);

    if (!this.createCompanyForm.userStep.user.valid) {
      this.createCompanyForm.userStep.user.markAllAsTouched();
      return;
    }

    if (this.createCompanyForm.userStep.user.id.value && this.createCompanyForm.userStep.user.email.disabled) {
      this.goToCompanyStep();
      return;
    }

    this.isLoading = true;
    const user = this.createCompanyForm.userStep.user.toUser();

    this.userApiService
      .checkIfEmailOrPhoneExist(user)
      .pipe(
        switchMap((existingUsers: User[]) => {
          const userWithSameEmail = existingUsers
            .filter((existingUser: User) => existingUser.email)
            .find(
              (existingUser: User) =>
                StringUtils.normalizeToLowerCase(existingUser.email) === StringUtils.normalizeToLowerCase(user.email)
            );

          if (userWithSameEmail) {
            return this.existingUserDialogService
              .open({ data: userWithSameEmail })
              .afterClosed()
              .pipe(
                filter((selectedUser: User) => !!selectedUser),
                tap((selectedUser: User) => this.userFormComponent.selectUserSuggestion(selectedUser))
              );
          } else {
            this.goToCompanyStep();
            return of(true);
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  resetExistingCompanies(): void {
    this.existingCompanies.set([]);
  }

  selectExistingCompany(searchUser: SearchUser): void {
    if (!this.data.prospect) {
      return;
    }

    this.confirmDialogService
      .open({
        data: new ConfirmDialogData(
          'Attention',
          `Êtes-vous sûr de vouloir sélectionner le dossier ${searchUser.company.name} ?`,
          'Confirmer',
          'Annuler',
          'Le prospect ainsi que ses contrats seront définitivement liés à ce dossier',
          'accent'
        )
      })
      .afterClosed()
      .pipe(
        filter((confirm: boolean) => confirm),
        tap(() => (this.submitLoading = true)),
        switchMap(() => this.linkProspectToCompany(this.data.prospect.id, searchUser.company.id, searchUser.id)),
        tap(() => {
          this.dialogRef.close(searchUser.company);
          this.snackbarService.openSuccess('Le dossier a bien été ajouté');
        }),
        finalize(() => (this.submitLoading = false))
      )
      .subscribe();
  }

  private goToCompanyStep(): void {
    this.createCompanyForm.userStep.userDoesntExist.setValue(true);
    this.existingCompanies.set([]);

    this.isLoading = true;

    const userEmail = this.createCompanyForm.userStep.controls.user.email.value;
    const userPhone = this.createCompanyForm.userStep.controls.user.phone.value;

    this.getUserExistingCompaniesFromBusinessUserPartner(userEmail, userPhone)
      .pipe(
        tap((searchUser: SearchUser[]) => {
          this.existingCompanies.set(searchUser);
          this.tiimeStepperComponent.next();
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private getUserExistingCompaniesFromBusinessUserPartner(email: string, phone: string): Observable<SearchUser[]> {
    return this.userApiService.searchUsersFromBusinessUserPartner({ email, phone });
  }

  private initUserFromProspect(prospect: Prospect): void {
    this.createCompanyForm.userStep.user.patchValue({
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      phone: prospect.phone,
      email: prospect.email,
      civility: prospect.civility
    });

    this.createCompanyForm.userStep.user.address.patchValue({
      mailingStreet: prospect.mailingStreet,
      mailingPostalCode: prospect.mailingPostalCode,
      mailingCity: prospect.mailingCity
    });
  }

  private initCompanyFromProspect(prospect: Prospect, businessUnits: BusinessUnit[]): void {
    const businessUnit = businessUnits.find(
      (businessUnit: BusinessUnit) => businessUnit.id === prospect.businessUnit.id
    );

    this.createCompanyForm.companyStep.company.patchValue({
      name: prospect.companyName,
      legalForm: prospect.companyLegalForm,
      businessUnit
    });
  }

  private linkProspectToCompany(prospectId: number, companyId: number, userId: number): Observable<void> {
    return this.prospectApiService.linkToCompany(prospectId, companyId, userId);
  }
}
