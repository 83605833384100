import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tiime-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerHeaderComponent<D> {
  private readonly yearsPerPage = 24;
  private subscription: Subscription;

  constructor(
    public calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    this.subscription = calendar.stateChanges.pipe(tap(() => cdr.markForCheck())).subscribe();
  }

  get periodButtonText(): string {
    if (this.calendar.currentView === 'month') {
      return this.dateAdapter
        .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
        .toLocaleLowerCase();
    }
    if (this.calendar.currentView === 'year') {
      return this.dateAdapter.getYearName(this.calendar.activeDate);
    }
    const activeYear = this.dateAdapter.getYear(this.calendar.activeDate);
    const firstYearInView = this.dateAdapter.getYearName(
      this.dateAdapter.createDate(activeYear - (activeYear % this.yearsPerPage), 0, 1)
    );
    const lastYearInView = this.dateAdapter.getYearName(
      this.dateAdapter.createDate(activeYear + this.yearsPerPage - 1 - (activeYear % this.yearsPerPage), 0, 1)
    );
    return `${firstYearInView} \u2013 ${lastYearInView}`;
  }

  previousClicked(): void {
    if (!this.previousEnabled()) {
      return;
    }
    this.calendar.activeDate =
      this.calendar.currentView === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView === 'year' ? -1 : -this.yearsPerPage
          );
  }

  nextClicked(): void {
    if (!this.nextEnabled()) {
      return;
    }
    this.calendar.activeDate =
      this.calendar.currentView === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView === 'year' ? 1 : this.yearsPerPage
          );
  }

  currentPeriodClicked(): void {
    this.calendar.currentView = this.calendar.currentView === 'month' ? 'multi-year' : 'month';
  }

  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }
    return !this.calendar.minDate || !this.isSameView(this.calendar.activeDate, this.calendar.minDate);
  }

  nextEnabled(): boolean {
    return !this.calendar.maxDate || !this.isSameView(this.calendar.activeDate, this.calendar.maxDate);
  }

  private isSameView(date1: D, date2: D): boolean {
    if (this.calendar.currentView === 'month') {
      return (
        this.dateAdapter.getYear(date1) === this.dateAdapter.getYear(date2) &&
        this.dateAdapter.getMonth(date1) === this.dateAdapter.getMonth(date2)
      );
    }
    if (this.calendar.currentView === 'year') {
      return this.dateAdapter.getYear(date1) === this.dateAdapter.getYear(date2);
    }
    return (
      Math.floor(this.dateAdapter.getYear(date1) / this.yearsPerPage) ===
      Math.floor(this.dateAdapter.getYear(date2) / this.yearsPerPage)
    );
  }
}
