import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppRoute } from '@modules/core/navigation/app-route.enum';
import { sharedInjector } from '@modules/shared/shared-injector';

export function RedirectIfResourceNotAvailable(): MethodDecorator {
  return (_target: () => void, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      return original.apply(this, args).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            const router: Router = sharedInjector.get(Router);
            if (error.status === HttpStatusCode.NotFound) {
              router.navigate([AppRoute.notFound]);
            } else if (error.status === HttpStatusCode.Forbidden) {
              router.navigate([AppRoute.unauthorized]);
            }
          }

          return throwError(error);
        })
      );
    };
    return descriptor;
  };
}
