import { VatRate, VatRateJson } from './vat-rate';

export interface VatTypeJson {
  id: number;
  code: string;
  name: string;
  main: boolean;
  area: string;
  vat_rates: Partial<VatRateJson>[];
  invoice_legal_notice: string;
}

export class VatType {
  get hasName(): boolean {
    return this.name && this.name !== '{{rate}}';
  }

  constructor(
    public id?: number,
    public code?: string,
    public name?: string,
    public main?: boolean,
    public area?: string,
    public vatRates?: VatRate[],
    public invoiceLegalNotice?: string
  ) {}

  static fromJson(json: Partial<VatTypeJson>): VatType {
    return new VatType(
      json.id,
      json.code,
      json.name,
      json.main,
      json.area,
      json.vat_rates ? json.vat_rates.map((vatRateJson: any) => VatRate.fromJson(vatRateJson)) : [],
      json.invoice_legal_notice
    );
  }
}
