<tiime-input-container
  tiimeFormInputContainer
  contentId="vatType"
  [label]="label"
  [required]="required"
  [errorMessage]="errorMessageMode === 'label' && errorMessage?.message"
  [attr.small]="small"
>
  <tiime-select
    id="vatType"
    [class.selected]="forceSelected"
    [formControl]="control"
    (selectionChange)="emitSelectionChange($event)"
  >
    <div tiimeSelectOptions>
      <mat-option [value]="null" *ngIf="allowEmptyOption"></mat-option>
      <mat-option [value]="null" *ngIf="allowInvoiceOption">Invoice</mat-option>
      <mat-option [value]="vatType.id" *ngFor="let vatType of selectorData; trackBy: trackById">
        {{ vatType.id | vatTypeRate: selectorData }}
      </mat-option>
    </div>
  </tiime-select>
</tiime-input-container>
<app-list-error *ngIf="errorMessageMode === 'list' && errorMessage" [errorMessage]="errorMessage"></app-list-error>
