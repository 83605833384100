import { ConnectionProvider, ConnectionProviderJson } from '@models/connection-provider';
import { ConnectionProviderCredential, ConnectionProviderCredentialJson } from '@models/connection-provider-credential';

export interface BusinessUnitConnectionProviderJson {
  id: number;
  ebics_connection_provider: ConnectionProviderJson;
  enabled: boolean;
  connections: Partial<ConnectionProviderCredentialJson>[];
  default: boolean;
}

export class BusinessUnitConnectionProvider {
  constructor(
    public id?: number,
    public connectionProvider?: ConnectionProvider,
    public enabled?: boolean,
    public connections?: ConnectionProviderCredential[],
    public isDefault?: boolean // On est obligé de renommé ici car default est un mot clé réservé
  ) {}

  static fromJson(json: BusinessUnitConnectionProviderJson): BusinessUnitConnectionProvider {
    return new BusinessUnitConnectionProvider(
      json.id,
      json.ebics_connection_provider ? ConnectionProvider.fromJson(json.ebics_connection_provider) : null,
      json.enabled,
      json.connections
        ? json.connections.map((connection: ConnectionProviderCredential) =>
            ConnectionProviderCredential.fromJson(connection)
          )
        : null,
      json.default
    );
  }

  static toJson(
    businessUnitConnectionProvider: BusinessUnitConnectionProvider
  ): Partial<BusinessUnitConnectionProviderJson> {
    return {
      id: businessUnitConnectionProvider.id,
      enabled: businessUnitConnectionProvider.enabled,
      default: businessUnitConnectionProvider.isDefault,
      connections: businessUnitConnectionProvider.connections
        ? businessUnitConnectionProvider.connections.map((connection: ConnectionProviderCredential) =>
            ConnectionProviderCredential.toJson(connection)
          )
        : []
    };
  }
}
