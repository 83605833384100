import { AdminIntegrationStateType } from '@enums/admin-integration-state-type.enum';

export interface PartnerConfigJson {
  id: number;
  vat_declaration_auto_process_running_mode: AdminIntegrationStateType;
  time_tracking: boolean;
  company_creation_allowed: boolean;
  change_business_unit_allowed: boolean;
  company_reference_mandatory: boolean;
  delegate_formality_allowed: boolean;
  company_reference_update_allowed: boolean;
  visa_auto_allowed: boolean;
  risk_level_allowed: boolean;
  manual_risk_level_allowed: boolean;
  company_legal_structure_update_allowed: boolean;
  contract_module_allowed: boolean;
  category_setup_plan_mandatory_for_accounting_period: boolean;
}

export class PartnerConfig {
  constructor(
    public id: number,
    public vatDeclarationAutoProcessRunningMode: AdminIntegrationStateType,
    public timeTrackings: boolean,
    public companyCreationAllowed: boolean,
    public changeBusinessUnitAllowed: boolean,
    public companyReferenceMandatory: boolean,
    public delegateFormalityAllowed: boolean,
    public companyReferenceUpdateAllowed: boolean,
    public visaAutoAllowed: boolean,
    public riskLevelAllowed: boolean,
    public manualRiskLevelAllowed: boolean,
    public companyLegalStructureUpdateAllowed: boolean,
    public contractModuleAllowed: boolean,
    public categorySetupPlanMandatoryForAccountingPeriod: boolean
  ) {}

  static fromJson(json: Partial<PartnerConfigJson>): PartnerConfig {
    return new PartnerConfig(
      json.id,
      json.vat_declaration_auto_process_running_mode,
      json.time_tracking,
      json.company_creation_allowed,
      json.change_business_unit_allowed,
      json.company_reference_mandatory,
      json.delegate_formality_allowed,
      json.company_reference_update_allowed,
      json.visa_auto_allowed,
      json.risk_level_allowed,
      json.manual_risk_level_allowed,
      json.company_legal_structure_update_allowed,
      json.contract_module_allowed,
      json.category_setup_plan_mandatory_for_accounting_period
    );
  }

  static toJson(data: Partial<PartnerConfig>): Partial<PartnerConfigJson> {
    return {
      id: data.id,
      vat_declaration_auto_process_running_mode: data.vatDeclarationAutoProcessRunningMode,
      company_creation_allowed: data.companyCreationAllowed,
      change_business_unit_allowed: data.changeBusinessUnitAllowed,
      company_reference_mandatory: data.companyReferenceMandatory,
      company_reference_update_allowed: data.companyReferenceUpdateAllowed,
      company_legal_structure_update_allowed: data.companyLegalStructureUpdateAllowed,
      category_setup_plan_mandatory_for_accounting_period: data.categorySetupPlanMandatoryForAccountingPeriod
    };
  }
}
