export interface TimeTrackingsCategoryJson {
  id: number;
  wording: string;
  company_mandatory: boolean;
}

export class TimeTrackingsCategory {
  constructor(public id?: number, public wording?: string, public companyMandatory?: boolean) {}

  static fromJson(json: Partial<TimeTrackingsCategoryJson>): TimeTrackingsCategory {
    return new TimeTrackingsCategory(json.id, json.wording, json.company_mandatory);
  }
}
