import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserInvitationChannelType } from '@enums/user-invitation-channel-type.enum';
import { UserInvitationFeatureForm } from '@forms/user-invitation-feature-form';
import { UserInvitationConfiguration } from '@models/user-invitation-configuration';

export type UserInvitationConfigurationFormType = {
  features: UserInvitationFeatureForm;
  channel: FormControl<UserInvitationChannelType | null>;
  withFormation: FormControl<boolean>;
};

export class UserInvitationConfigurationForm extends FormGroup<UserInvitationConfigurationFormType> {
  get channel(): FormControl<UserInvitationChannelType | null> {
    return this.controls.channel;
  }

  get features(): UserInvitationFeatureForm {
    return this.controls.features;
  }

  get invoiceApp(): FormControl<boolean> {
    return this.controls.features.controls.invoiceApp;
  }

  get hasAllFeaturesSelected(): boolean {
    return this.invoiceApp.value && this.receiptApp.value;
  }

  get receiptApp(): FormControl<boolean> {
    return this.controls.features.controls.receiptApp;
  }

  constructor(featuresRequired = true) {
    super({
      features: new UserInvitationFeatureForm(featuresRequired),
      channel: new FormControl<UserInvitationChannelType | null>(null, featuresRequired ? Validators.required : null),
      withFormation: new FormControl<boolean>(false)
    });
    // Dans le cas où la partie features n'est pas obligatoire, le champ channel le devient quand même si on sélectionne au moins une features
    if (!featuresRequired) {
      this.addValidators((form: UserInvitationConfigurationForm) => {
        if (
          ((form.features.controls.receiptApp.value ||
            form.features.controls.invoiceApp.value ||
            form.controls.withFormation.value) &&
            form.channel.value === null) ||
          (form.channel.value !== null &&
            !form.features.controls.receiptApp.value &&
            !form.features.controls.invoiceApp.value)
        ) {
          return { required: true };
        }

        return null;
      });
    }
  }

  toUserInvitationConfiguration(): UserInvitationConfiguration {
    return new UserInvitationConfiguration(
      this.features.toUserInvitationFeatures(),
      this.channel.value,
      this.value.withFormation
    );
  }
}
