import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';

import { TiimeInputContainerModule, TiimeSelectModule } from 'tiime-material';

import { SelectBase } from '@bases/selector/select.base';
import { TaskPlanning } from '@models/task-planning';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TiimeInputContainerModule, TiimeSelectModule, MatOptionModule],
  selector: 'app-task-select',
  templateUrl: './task-select.component.html',
  styleUrls: ['./task-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSelectComponent extends SelectBase<TaskPlanning> {}
