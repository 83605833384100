import { FormControl, FormGroup } from '@angular/forms';

export type SearchBarFormType = {
  search: FormControl<string | null>;
};

export class SearchBarForm extends FormGroup<SearchBarFormType> {
  get search(): FormControl<string | null> {
    return this.controls.search;
  }

  constructor() {
    super({
      search: new FormControl<string | null>(null)
    });
  }
}
