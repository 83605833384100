import { VariousAccountParametersType } from '@enums/various-account-parameters-type.enum';

export interface VariousAccountParametersJson {
  id: number;
  account_label: string;
  type: VariousAccountParametersType;
  account_number: string;
}

export class VariousAccountParameters {
  constructor(
    public id: number,
    public accountLabel: string,
    public type: VariousAccountParametersType,
    public accountNumber: string
  ) {}

  static fromJson(json: VariousAccountParametersJson): VariousAccountParameters {
    return new VariousAccountParameters(json.id, json.account_label, json.type, json.account_number);
  }

  public toJson(): Partial<VariousAccountParametersJson> {
    return {
      id: this.id,
      account_number: this.accountNumber
    };
  }
}
