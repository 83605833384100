import { Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';

import { NgUtils } from 'tiime-expert-utils';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AutocompleteBaseComponent<T> {
  @Input() createOption = true;
  @Input() currentInputValue: string;
  @Input() data: T[];
  @Input() nullOptionLabel?: string;

  @Output() readonly selectRecord: EventEmitter<T> = new EventEmitter<T>();
  @Output() readonly selectRecords: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() readonly createRecord: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;

  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;

  emitSelect(event: MatOptionSelectionChange, data: T): void {
    if (!event.isUserInput) {
      return;
    }

    this.selectRecord.emit(data);
  }

  emitSelectMultiple(event: MatOptionSelectionChange, data: T[]): void {
    if (!event.isUserInput) {
      return;
    }

    this.selectRecords.emit(data);
  }

  emitCreate(event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }

    this.createRecord.emit(this.currentInputValue);
  }
}
