import { Injectable } from '@angular/core';

import { createDialogServiceBase } from 'tiime-material';

import { Company } from '@models/company';

import { CreateCompanyDialogData } from './create-company-dialog-data';
import { CreateCompanyDialogComponent } from './create-company-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CreateCompanyDialogService extends createDialogServiceBase<Company, CreateCompanyDialogData>(
  CreateCompanyDialogComponent
) {}
