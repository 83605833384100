<tiime-input-container
  class="select-chips-container"
  [class.margin-top]="marginTop"
  [class.fulfilled]="control?.value?.length"
  contentId="tags"
  matAutocompleteOrigin
  #autocompleteOrigin="matAutocompleteOrigin"
>
  <label class="select-chips-label" [class.exposed]="control.value?.length || searchTagsControl.value" for="tags">
    {{ label }}
  </label>
  <mat-chip-list id="tags" #tagList aria-label="Selected tags">
    <mat-chip
      *ngFor="let tag of control.value; trackBy: trackByIndex; let i = index"
      [removable]="true"
      (removed)="removeTag(i)"
    >
      <mat-icon class="tag-icon" svgIcon="icon-tag"></mat-icon>
      <span class="chip-content">
        {{ tag.name }}
      </span>
      <mat-icon matChipRemove inline="true" fontIcon="icon-close-2c-r-24-fontastic"></mat-icon>
    </mat-chip>
    <input
      #searchInput
      type="text"
      placeholder="Rechercher un tag"
      [formControl]="searchTagsControl"
      [matAutocomplete]="threadTagAutocomplete.autocomplete"
      [matAutocompleteConnectedTo]="autocompleteOrigin"
      [matChipInputFor]="tagList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addNewTag($event)"
    />
  </mat-chip-list>
  <app-thread-tag-autocomplete
    #threadTagAutocomplete="ThreadTagAutocomplete"
    [data]="filteredThreadTags$ | async"
    (selectRecord)="addTag($event)"
  ></app-thread-tag-autocomplete>
</tiime-input-container>
