import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA
} from '@angular/material/legacy-snack-bar';

import { SnackBarData, SnackBarDirection } from './snackbar-data';

@Component({
  selector: 'tiime-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
  action: string;
  message: string;
  isPrimary = false;
  isSuccess = false;
  isError = false;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: string | SnackBarData
  ) {
    if (typeof data === 'string') {
      this.message = data;
    } else {
      this.message = data.message || 'Une erreur est survenue.';
      this.action = data.action || null;
    }
    this.isPrimary = this.snackBarRef.containerInstance.snackBarConfig.panelClass.includes('primary');
    this.isSuccess = this.snackBarRef.containerInstance.snackBarConfig.panelClass.includes('success');
    this.isError = this.snackBarRef.containerInstance.snackBarConfig.panelClass.includes('error');
  }

  dismissWithAction(): void {
    this.snackBarRef.dismissWithAction();
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
